import { styled, Theme } from '@mui/material/styles';

interface ChipProps {
  theme?: Theme
  fontSize: number
}

const Chip = styled("div")(({ theme, fontSize }: ChipProps) => ({
  display: "flex",
  gap: theme?.spacing(1),
  backgroundColor: theme?.palette.neutral?.[500],
  padding: `${theme?.spacing(1)} ${theme?.spacing(2)}`,
  borderRadius: 4,
  fontFamily: "Be Vietnam",
  fontSize: fontSize,
}))

export {
  Chip
}