import * as yup from "yup";

export default yup.object().shape({
  type: yup.string(),
  checkbox: yup.object().shape({
    qualityCheck: yup.object().shape({
      selected: yup.boolean()
    })
  }),
  files: yup.array().when(
    "type",
    {
      is: (type: string) => type === "file",
      then: (schema) => schema.of(
        yup.object().shape({
          firstImage: yup.object().shape({
            base64: yup.string().required("Não foi possível gerar o base64 desse arquivo")
          }).required("É necessário inserir um arquivo para prosseguir"),
          secondImage: yup.object().shape({
            base64: yup.string().required("Não foi possível gerar o base64 desse arquivo")
          }).required("É necessário inserir um arquivo para prosseguir")
        })
      ),
      otherwise: (schema) => schema.of(
        yup.object().shape({
          firstImage: yup.object().shape({
            inserted: yup.boolean().required("É necessário inserir um link para prosseguir").oneOf([true], "É necessário inserir um link para prosseguir"),
            link: yup.string().required(),
          }).test("required", "É necessário inserir um link para prosseguir", (values) => { return !!values.link || !!values.inserted }),
          secondImage: yup.object().shape({
            inserted: yup.boolean().required("É necessário inserir um link para prosseguir").oneOf([true], "É necessário inserir um link para prosseguir"),
            link: yup.string().required(),
          }).test("required", "É necessário inserir um link para prosseguir", (values) => { return !!values.link || !!values.inserted })
        })
      )
    }
  )
})
