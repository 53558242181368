import { useState, useEffect } from 'react';

const useUnsavedChanges = () => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  
    const enableUnsavedChanges = () => {
      setHasUnsavedChanges(true);
    };
  
    const disableUnsavedChanges = () => {
      setHasUnsavedChanges(false);
    };
  
    useEffect(() => {
      const handleBeforeUnload = (event: any) => {
        if (hasUnsavedChanges) {
          event.preventDefault();
        }
      };
  
      window.addEventListener('beforeunload', handleBeforeUnload);
  
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, [hasUnsavedChanges]);
  

    return {
        hasUnsavedChanges,
        enableUnsavedChanges,
        disableUnsavedChanges,
    };
};

export default useUnsavedChanges;