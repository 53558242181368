import React from "react";

import { Box } from "@mui/material";
import { Notification, Title, Description } from "./styles";

import { BiInfoCircle } from 'react-icons/bi';

interface NotificationProps {
  title: string,
  description: string | React.ReactElement,
  variant: "information" | "success" | "warning" | "danger",
}

const Component = ({
  title,
  description,
  variant
}: NotificationProps) => {
  return (
    <Notification variant={variant}>
      <Box pt={.5}>
        <BiInfoCircle size={20} />
      </Box>
      <Box>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Box>
    </Notification>
  )
}

export default Component;