import { Box, Theme, styled } from "@mui/material";

const Container = styled(Box)(({ theme }: { theme?: Theme }) => ({
    maxWidth: '100%',
    border: `1px solid ${theme?.palette.neutral[400]}`,
    borderRadius: '8px'
}))

const Header = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme?.palette.neutral[500]
}))

const TicketInfo = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme?.spacing(2),
    padding: `${theme?.spacing(4)} ${theme?.spacing(3)}`
}))

const Body = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme?.spacing(4),
    padding: `${theme?.spacing(4)} ${theme?.spacing(3)}`
}))

const ImagesWrapper = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    gap: theme?.spacing(4),
    flexWrap: 'wrap'
}))

const Image = styled(Box)(({ theme, selected }: { theme?: Theme, selected: boolean }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme?.spacing(2),
    alignItems: 'center',
    width: 'calc(50% - 8px)',
    height: 'auto',
    overflow: 'hidden',
   
    '& img':{
        borderRadius: '12px',
        height: 'auto',
        width: 'calc(100% - 4px)',
        cursor: 'pointer',
        transition: 'filter .5s ease, opacity .5s ease',
        '&:hover':{
            border: `2px solid ${theme?.palette.primary.main}`
        },
        ...!!selected && ({
            border: `2px solid ${theme?.palette.primary.main}`,
            transition: 'filter .5s ease, opacity .5s ease',
        }),
        ...!!!selected && ({
            filter: 'grayscale(100%)',
            transition: 'filter .5s ease, opacity .5s ease',
            opacity: 0.5
        }),
        '@media print': {
            border: 'unset',
            filter: 'unset',
            opacity: 1
        }
    }   
}))

export {
    Container,
    Header,
    TicketInfo,
    Body,
    ImagesWrapper,
    Image
}