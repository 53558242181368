import { Button } from '@mui/material';
import { styled } from '@mui/system';

const UserProfileButton = styled(Button)`
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #006;
    background-color: white;
    border-radius: 8px;
    text-transform: none;
    padding: 8px 12px;
    line-height: 170%;

    :hover{
        background-color: white;
    }

    :click{
        background-color: white;
    }
`;

export default UserProfileButton;