import axios from 'axios';
import * as Billing from "./Billing";
import * as BigBrother from "./BigBrother";
import { sendToQueue } from './SQS';
import * as Platform from "./Platform";
import * as FileTransferCenter from "./FileTransfer";
import * as Plugins from './Plugins';
import * as BigID from './BigId';

const apiBillingJean = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_BILLINGJEAN
})

const apiBillingJeanHml = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_BILLINGJEAN_HML
})

const apiBigBrother = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_BIGBROTHER,
    headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0"
    }
})

const apiBigBrotherHml = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_BIGBROTHER_HML
})

const apiPlatformDev = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_PLATFORM_DEV
})

const apiPlatform = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_PLATFORM
})

const apiDocUpdateGuard = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_DOC_UPDATE_GUARD
})

const apiDocUpdateGuardDev = axios.create({
    baseURL: process.env.REACT_APP_API_ROUTE_DOC_UPDATE_GUARD_DEV
})

export {
    apiBillingJean,
    apiBigBrother,
    apiBillingJeanHml,
    apiBigBrotherHml,
    apiPlatform,
    Platform,
    FileTransferCenter,
    Billing,
    BigBrother,
    apiPlatformDev,
    sendToQueue,
    apiDocUpdateGuard,
    apiDocUpdateGuardDev,
    Plugins,
    BigID,
}
