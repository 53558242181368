import { Tab } from '@mui/material';
import { styled } from '@mui/material/styles';

const Component = styled(Tab)(({ theme }) => ({
  display: "flex",
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
  borderRadius: '8px', 
  backgroundColor: 'transparent',
  fontFamily: "Be Vietnam",
  fontSize: "14px",
  color: "#555866",
  fontWeight: 700,
  textTransform: 'none',
  '&:hover': {
    color: '#0068FF',
  },
  '&.Mui-selected': {
    color: '#1890ff',
    backgroundColor: '#FFF',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff',
  },
  minHeight: 'auto', 
}))

export default Component;