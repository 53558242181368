import {
  GridPagination,
} from '@mui/x-data-grid';
import MuiPagination from '@mui/material/Pagination';
import { TablePaginationProps } from '@mui/material/TablePagination';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from "@store";

const paginationCustomStyle = {
  '& .Mui-selected': {
    color: 'white !important',
    backgroundColor: '#0069ff !important',
    transition: 'background-color 1s ease !important',
    '&:hover': {
      backgroundColor: '#006 !important',
      transition: 'background-color 1s ease !important'
    },
  },
  '& .MuiPaginationItem-root:not(.Mui-selected):hover': {
    transition: 'background-color .5s ease !important',
    backgroundColor: '#f0f8fa !important',
  },
  '& .MuiPaginationItem-page:not(.Mui-selected) !important': {
    transition: 'background-color .5s ease !important',
    color: '#006 !important',
    fontFamily: "'Be vietnam', sans-serif !important"
  },

};
const gridPaginationCustomStyle = {
  width: '100%',
  '& .MuiToolbar-root': {
    width: '100%',
    padding: '0px !important'
  },
  '& .MuiTablePagination-actions': {
    marginLeft: 'auto !important'
  },
  '& .MuiInputBase-root': {
    border: '2px solid #B1B9BD',
    borderRadius: '8px',
    fontFamily: "'Be vietnam', sans-serif",
    fontSize: '14px'
  },
  '& .MuiTablePagination-spacer': {
    display: 'none !important'
  },
  '& .MuiTablePagination-displayedRows': {
    display: 'none !important'
  },
  '& .MuiTablePagination-selectLabel': {
    fontFamily: "'Be vietnam', sans-serif",
    fontSize: '12px'
  }
}

function Pagination({
  className
}: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className' | 'count'>) {

  const paginationInfo = useSelector((state: reducers.RootState) => state.invoicesPaginationReducer)
  const dispatch = useDispatch()

  const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    event?.stopPropagation()
    dispatch(slices.invoicesPagination.setInvoicesPaginationInfo({ ...paginationInfo, Page: newPage }))
  };

  
  useEffect(() => {

  }, [paginationInfo])

  return (
    <MuiPagination
      sx={paginationCustomStyle}
      variant='text'
      shape='rounded'
      className={className}
      count={paginationInfo.TotalPages}
      page={paginationInfo.Page}
      onChange={(event, newPage) => {
        onPageChange(event as any, newPage);
      }}

    />
  );
}


function InvoicesPagination(props: any) {
  return <GridPagination
    sx={gridPaginationCustomStyle}
    rowsPerPageOptions={[]}
    ActionsComponent={Pagination}
  />
}

export default InvoicesPagination 
