import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';


const IconStyle: any = {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2px 0px 0px',
    gap: '8px',
    width: '20px',
    height: '22px'
}

const CreatedTokenNote = () => {
    return <div className='created-token-note-div'>
        <AiOutlineInfoCircle color='#0048B2' style={IconStyle}/>
        <div className='created-token-note-div-content'>
            <span className='created-token-note-span'>Guarde sua chave token</span>
            <p className='created-token-note-paragraph'>Mantenha sua chave token salva para utilizá-la quando precisar. Lembre-se de copiá-la e salvá-la antes de sair dessa página.</p>
        </div>
    </div>;
}

export default CreatedTokenNote;