import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { reducers, slices } from "@store";

import { showToastCustomCreateUserSucessMessage, showToastCustomErrorMessage, handleAxiosError, isFrom } from '@utils';

import { BigBrother, FileTransferCenter } from '@services';

import './createUser.css'

import { GeneralComponents } from "@components";

import selectedButtonStyle from './sxStyles';
import { useUnsavedChanges } from '@hooks';
import CreateUserNote from './CreateUserNote';
import { HiPlus } from 'react-icons/hi';


interface CreateUserSectionProps {
  className: string;
  closeCreateUserSection: () => void
  resetLocalOutsideClickStatus: () => void
  outsideClickOcurred: boolean
}

const CreateUser: React.FC<CreateUserSectionProps> = ({ className, closeCreateUserSection, outsideClickOcurred, resetLocalOutsideClickStatus }: CreateUserSectionProps) => {

  const { group } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoginFieldBlank, setIsLoginFieldBlank] = useState<boolean>(false)
  const [isEmailFieldBlank, setIsEmailFieldBlank] = useState<boolean>(false)
  const [isNameFieldBlank, setIsNameFieldBlank] = useState<boolean>(false)
  const [loginAlreadyExists, setLoginAlreadyExists] = useState<boolean>(false)
  const [isInvalidEmail, setIsInvalidEmail] = useState<boolean>(false)
  const [hasCreationError, setHasCreationError] = useState<boolean>(false)
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false)

  const { hasUnsavedChanges, enableUnsavedChanges, disableUnsavedChanges } = useUnsavedChanges()
  const [unsavedDialogConfirmHandlers, setUnsavedDialogConfirmHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });

  const missingParametersStatus = 1
  const invalidSessionStatus = 3
  const loginAlreadyExistsStatus = 6

  const navigate = useNavigate()

  const [localUserInfo, setLocalUserInfo] = useState({
    login: "",
    email: "",
    name: "",
    group,
    isEnabled: true,
    isSystemAdmin: false,
    "2fa": false,
    // fileTransfer: false,
  })

  const resetLocalUserInfo = () => {
    setLocalUserInfo({
      login: "",
      email: "",
      name: "",
      group,
      isEnabled: true,
      isSystemAdmin: false,
      "2fa": false,
      // fileTransfer: false,
    })
  }

  const resetFieldStatusOnCancelCreation = () => {
    setIsLoginFieldBlank(false)
    setIsEmailFieldBlank(false)
    setIsNameFieldBlank(false)
    setLoginAlreadyExists(false)
    setIsInvalidEmail(false)
  }

  const resetFieldStatusOnChangeFields = (targetName: string) => {
    if (targetName === 'login') {
      setIsLoginFieldBlank(false)
      setLoginAlreadyExists(false)
    } else if (targetName === 'email') {
      setIsEmailFieldBlank(false)
      setIsInvalidEmail(false)
    } else {
      setIsNameFieldBlank(false)
    }
  }

  const returnIsLoginFieldBlank = () => localUserInfo.login === ''
  const returnIsEmailFieldBlank = () => localUserInfo.email === ''
  const returnIsNameFieldBlank = () => localUserInfo.name === ''

  const checkInvalidFields = () => {
    setIsLoginFieldBlank(returnIsLoginFieldBlank())
    setIsEmailFieldBlank(returnIsEmailFieldBlank())
    setIsNameFieldBlank(returnIsNameFieldBlank())
  }

  const returnLoginHelperText = () => {
    if (isLoginFieldBlank) {
      return 'Campo obrigatório'
    } else if (loginAlreadyExists) {
      return 'Login indisponível'
    } else {
      return ''
    }
  }

  const returnEmailHelperText = () => {
    if (isEmailFieldBlank) {
      return 'Campo obrigatório'
    } else if (isInvalidEmail) {
      return 'Insira um endereço de e-mail válido'
    } else {
      return ''
    }
  }

  const createUser = async () => await BigBrother.createUser({ isFrom: isFrom(), ...localUserInfo })

  const tryToCreateUser = async () => {
    try {
      setIsLoading(true)
      const { data: apiBigBrotherResponseData } = await createUser()
      handleBigBrotherResponse(apiBigBrotherResponseData)
      setIsLoading(false)
    } catch (error: any) {
      handleAxiosError(error)
      setIsLoading(false)
    }
  }

  // const createFileTransferTemplate = async (userLogin: string) => await FileTransferCenter.generateFolderTemplate({
  //   login: userLogin
  // })

  // const tryCreateFileTransferTemplate = async (userLogin: string) => {
  //   try {
  //     await createFileTransferTemplate(userLogin)
  //   } catch (err: any) {
  //     handleAxiosError(err)
  //   }
  // }

  const handleBigBrotherResponse = (apiBigBrotherResponseData: any) => {
    if (apiBigBrotherResponseData.result === false) {
      setHasCreationError(true)
      handleBigBrotherError(apiBigBrotherResponseData)
    } else {
      setHasCreationError(false)
      showToastCustomCreateUserSucessMessage(localUserInfo.name)
      // if (!!localUserInfo.fileTransfer) {
      //   tryCreateFileTransferTemplate(localUserInfo.login)
      // }
      closeCreateUserSection()
      resetLocalUserInfo()
    }
  }

  const handleBigBrotherError = (apiBigBrotherResponseData: any) => {
    const { status, message } = apiBigBrotherResponseData

    if (status === invalidSessionStatus) {
      showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.")
      slices.user.setUserInitialState()
      navigate('/login')
    } else if (status === loginAlreadyExistsStatus) {
      setLoginAlreadyExists(true)
      showToastCustomErrorMessage('Erro na criação de usuário.', 'O login fornecido está em uso.')
    } else if (status === missingParametersStatus) {
      checkInvalidFields()
      showToastCustomErrorMessage('Erro na criação de usuário.', 'Verifique se todos os campos necessários foram preenchidos corretamente.')
    } else if (message === 'To register a user, it is necessary to inform a valid e-mail in the login field or in the e-mail field.') {
      setIsInvalidEmail(true)
      showToastCustomErrorMessage('Erro na criação de usuário.', 'Verifique se o campo de login ou e-mail possuem um e-mail válido.')
    } else if (message === 'To register a user in BIGDATA domain, it is necessary to inform a e-mail with @BIGDATACORP.COM.BR in the login or e-mail field.') {
      setIsInvalidEmail(true)
      showToastCustomErrorMessage('Erro na criação de usuário.', 'Verifique se o campo de login ou e-mail possuem um e-mail válido.')
    }
  }

  const handleLocalUserInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableUnsavedChanges()
    resetFieldStatusOnChangeFields(event.target.name)
    setLocalUserInfo({ ...localUserInfo, [event.target.name]: event.target.value })
  }

  const handleTwoFactorChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    enableUnsavedChanges()
    if (localUserInfo.isSystemAdmin) {
      setLocalUserInfo({ ...localUserInfo, "2fa": true })
    } else {
      event.currentTarget.name === 'enabled' ? setLocalUserInfo({ ...localUserInfo, "2fa": true }) : setLocalUserInfo({ ...localUserInfo, "2fa": false })
    }
  };

  const handleEnabledStatusChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    enableUnsavedChanges()
    event.currentTarget.name === 'enabled' ? setLocalUserInfo({ ...localUserInfo, isEnabled: true }) : setLocalUserInfo({ ...localUserInfo, isEnabled: false })
  }

  const handleAdminChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    enableUnsavedChanges()
    if (event.currentTarget.name === 'admin') {
      setLocalUserInfo({ ...localUserInfo, isSystemAdmin: true, "2fa": true })
    } else {
      setLocalUserInfo({ ...localUserInfo, isSystemAdmin: false })
    }
  }

  // const handleFileTransferChange = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   enableUnsavedChanges()
  //   if (event.currentTarget.name === 'enabled') {
  //     setLocalUserInfo({ ...localUserInfo, fileTransfer: true })
  //   } else {
  //     setLocalUserInfo({ ...localUserInfo, fileTransfer: false })
  //   }
  // }

  const unsavedChangesDialogResponse = async () => {
    return new Promise((resolve) => {
      setShowUnsavedChangesDialog(true);

      const handleConfirm = async () => {
        resolve(true);
        await tryToCreateUser()
        setShowUnsavedChangesDialog(false);
      };

      const handleCancel = () => {
        resolve(false);
        setShowUnsavedChangesDialog(false);
      };

      setUnsavedDialogConfirmHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });

    });
  }

  const handleCancelButtonClick = async () => {
    if (hasUnsavedChanges) {
      const wasUnsavedChangesConfirmed = await unsavedChangesDialogResponse()
      if (wasUnsavedChangesConfirmed && hasCreationError) {
        resetLocalOutsideClickStatus()
      } else if (wasUnsavedChangesConfirmed && !hasCreationError) {
        closeCreateUserSection()
        resetLocalOutsideClickStatus()
        disableUnsavedChanges()
      }
      else if (!wasUnsavedChangesConfirmed && !hasCreationError) {
        resetLocalUserInfo()
        resetFieldStatusOnCancelCreation()
        resetLocalOutsideClickStatus()
        closeCreateUserSection()
        disableUnsavedChanges()
      } else {
        resetLocalUserInfo()
        resetFieldStatusOnCancelCreation()
        resetLocalOutsideClickStatus()
        closeCreateUserSection()
        disableUnsavedChanges()
      }
    } else {
      resetLocalUserInfo()
      resetFieldStatusOnCancelCreation()
      resetLocalOutsideClickStatus()
      closeCreateUserSection()
    }
  }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

  }

  const handleCreateUserContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const { confirmHandler, cancelHandler } = unsavedDialogConfirmHandlers

  useEffect(() => {
    if (outsideClickOcurred) {
      handleCancelButtonClick()
    }
  }, [outsideClickOcurred])

  return <div className={className} onMouseDown={handleCreateUserContainerClick}>
    <div className='create-user-main-header-container'>
      <h2 className='create-user-title'>Cadastrar usuário</h2>
    </div>
    <hr className='create-user-divider'></hr>
    <form className='create-user-form-container' onSubmit={handleFormSubmit}>
      <div className='create-user-data-container'>
        <h4 className='create-user-data-title'>Dados</h4>
        <div className='create-user-data-row-container'>
          <div className='create-user-data-fields'>
            <label>Login</label>
            <GeneralComponents.CustomTextField className='create-user-form-login' variant="outlined" placeholder='Insira o login do usuário' value={localUserInfo.login} onChange={handleLocalUserInfoChange} name='login' helperText={returnLoginHelperText()} />
          </div>
          <div className='create-user-data-fields'>
            <label>Nome</label>
            <GeneralComponents.CustomTextField className='create-user-form-name' variant="outlined" placeholder='Insira o nome do usuário' value={localUserInfo.name} onChange={handleLocalUserInfoChange} name='name' helperText={isNameFieldBlank ? 'Campo obrigatório' : ''} />
          </div>
        </div>
        <div className='create-user-data-fields'>
          <label>E-mail</label>
          <GeneralComponents.CustomTextField className='create-user-form-email' variant="outlined" placeholder='Insira o e-mail do usuário' value={localUserInfo.email} onChange={handleLocalUserInfoChange} name='email' helperText={returnEmailHelperText()} />
        </div>
        <CreateUserNote />
      </div>
      <div className='create-user-switch-container'>
        <h4 className='create-user-switch-container-title'>Condições</h4>
        <div className='create-user-switch-content'>
          <div className='create-user-switch-content-row'>
            <div className='switch-container-admin'>
              <label>Perfil de acesso</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.isSystemAdmin && selectedButtonStyle }} name='admin' onClick={handleAdminChange}>Administrador</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.isSystemAdmin && selectedButtonStyle }} name='non-admin' onClick={handleAdminChange}>Colaborador</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div>
            <div className='switch-container-2fa'>
              <label>Autenticação em dois fatores</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo['2fa'] && selectedButtonStyle }} name='enabled' onClick={handleTwoFactorChange}>Ativo</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo['2fa'] && selectedButtonStyle }} name='disabled' onClick={handleTwoFactorChange}>Inativo</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div>
          </div>
          <div className='create-user-switch-content-row'>
            <div className='switch-container-status'>
              <label>Status</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.isEnabled && selectedButtonStyle }} onClick={handleEnabledStatusChange} name='enabled'>Ativo</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.isEnabled && selectedButtonStyle }} onClick={handleEnabledStatusChange} name='disabled'>Inativo</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div>
            {/* <div className='switch-container-file-transfer'>
              <label>Transferência de arquivos</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.fileTransfer && selectedButtonStyle }} onClick={handleFileTransferChange} name='enabled'>Ativa</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.fileTransfer && selectedButtonStyle }} onClick={handleFileTransferChange} name='disabled'>Inativa</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div> */}
          </div>
        </div>
      </div>
      <div className='create-user-button-container'>
        <GeneralComponents.CancelButton type='button' size='small' variant='text' disableElevation disableRipple onClick={handleCancelButtonClick}>Cancelar</GeneralComponents.CancelButton>
        <GeneralComponents.CustomDefaultButton type='submit' size='small' variant='contained' disableElevation disableRipple startIcon={<HiPlus color='white'  size={20}/>} onClick={tryToCreateUser} disabled={!!isLoading}>{!!isLoading ? 'Cadastrando...' : 'Cadastrar'}</GeneralComponents.CustomDefaultButton>
      </div>
    </form>
    <GeneralComponents.ConfirmationDialog open={showUnsavedChangesDialog} onConfirm={confirmHandler} onLeave={cancelHandler} />
  </div>
}

export default CreateUser;