import { FormControl } from '@mui/material';
import React from 'react';

interface ContainerProps {
    children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({
    children

}) => {


    return (
        <FormControl
            fullWidth>
            {children}
        </FormControl>

    );
}

export default Container;