import { Step } from "react-joyride";

const steps: Step[] = [
    {
        target: '.token-management-main-container',
        title: 'Aprenda a criar e gerenciar tokens',
        content: 'Inicie o tour para conhecer o módulo de gestão de tokens',
        disableBeacon: true,
        floaterProps: { placement: 'center' },
    },
    {
        target: '.token-management-main-container',
        title: 'O que é um token',
        content: 'Um token é uma espécie de senha especial que permite que você, como usuário do BDC Center, se identifique e tenha acesso a funcionalidades específicas. Não há limite de tokens por usuário.',
        disableBeacon: true,
        floaterProps: { placement: 'center' }
    },
    {
        target: '.token-management-main-container',
        title: 'A composição do token',
        content: (<div>
            <p>Todo token possui:</p>
            <ul style={{ paddingLeft: '24px' }}>
                <li><b>Token ID</b>: é a identificação dada ao token em sua criação</li>
                <li><b>Chave token</b>: é um código único que concede acesso às funcionalidades do BDC Center.</li>
            </ul>
        </div>),
        disableBeacon: true,
        floaterProps: { placement: 'center' }
    },
    {
        target: '.create-token-button',
        title: 'Criação de tokens',
        content: (<div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
            <p>Crie tokens aqui.</p>
            <p><b>Atenção:</b> a chave token será exibida somente durante a criação do token, então é importante <b>guardá-la em um local seguro para uso futuro</b>.</p>
            <img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/tokenOnboardingImage.svg' alt='Explaination that Token ID is created in order to identification, while Token Key is used to allow access to BDC Center features'></img>
        </div>),
        disableBeacon: true,
        floaterProps: { placement: 'left' }
    },
    {
        target: '.token-filter-section-container',
        title: 'Encontre tokens facilmente',
        content: 'Utilize os filtros para buscar por tokens específicos. Lembre-se que você só pode encontrá-los através de seu Token ID.',
        disableBeacon: true,
        floaterProps: { placement: 'bottom' }
    },
    {
        target: '.token-management-main-container',
        title: 'Tour finalizado',
        content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
        disableBeacon: true,
        showProgress: false,
        floaterProps: { placement: 'center' }
    },
];

export default steps;