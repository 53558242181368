import { Button } from '@mui/material';
import { styled } from '@mui/system';

const ClearFilterButton = styled(Button)`
    background-color: none;
    border: none;
    text-transform: none;
    color: #006;
    text-decoration: underline;
    transition: all .5s ease;
    font-family: 'Picadilly', sans-serif;
    font-weight: bold;
    padding: 0;

    :hover{
        background-color: transparent;
        text-decoration: underline;
    }

`;

export default ClearFilterButton;