import React from 'react';
import { Box, BoxProps, Typography } from '@mui/material';

import { isDesktopWidth } from '@constants';

import { Category, Content, LinksContainer, MobileCategory } from './styles';
import Icon from '../Icon';

interface CategoryProps extends BoxProps {
    open: boolean,
    onClick: any,
    isAdmin: boolean,
    category: any,
    children?: any,
}

const Component: React.FC<CategoryProps> = ({
    open,
    onClick,
    isAdmin,
    category,
    children,
}) => {

    if (!!!isAdmin && category?.id === 'financial') {
        return <></>
    }

    return !!isDesktopWidth ? <Category
        id={category?.id}
        active={category?.active}
        onClick={onClick}
    >
        <Box
            id='firstChild'
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            width={'100%'}
        >
            <Icon
                Icon={category?.categoryIcon}
                active={category?.active}
            />
            <Typography
                variant='body1'
                fontSize={10}
                color={!!category?.active ? '#006' : '#FFF'}
                width={'100%'}
                textAlign={'center'}
            >
                {category?.name}
            </Typography>
        </Box>
        {
            category?.active && category?.id !== 'home' && open && (
                <Content>
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                    >
                        <category.categoryIcon size={36} />
                        <Typography variant='h2' fontSize={16}>
                            {category?.name}
                        </Typography>

                    </Box>


                    {!!isDesktopWidth && (
                        <LinksContainer>
                            {children}
                        </LinksContainer>
                    )}
                </Content>
            )
        }
    </Category>
        :
        <>
            <MobileCategory
                id={category?.id}
                active={category?.active}
                onClick={onClick}
            >
                <Box
                    id='firstChild'
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    width={'100%'}
                    height={'100%'}
                >
                    {
                        !!category?.categoryIcon && (
                            <category.categoryIcon color={'#1D203A'} size={48} />
                        )
                    }
                    <Typography
                        variant='body1'
                        fontSize={16}
                        fontWeight={600}
                        color={!!category.active ? "#FFF" : '#1D203A'}
                        width={'100%'}
                        textAlign={'center'}
                    >
                        {category?.name}
                    </Typography>
                </Box>

            </MobileCategory>
            {children}
        </>
}

export default Component;