import React from 'react';
import { useNavigate } from "react-router-dom";

import { Box } from "@mui/material";
import { TextField, Button, Paper, GeneralComponents } from "@components";
import { BiSearch, BiCalendarAlt, BiPlus } from 'react-icons/bi';
import { Title, Subtitle } from "./styles";
import { FormProvider, useForm } from 'react-hook-form';

import data from "./data.json";
import { columns } from "@constants";

const Page: React.FC = () => {
  const navigate = useNavigate();
  const formFilter = useForm();

  const handleClickNewConsult = () => {
    navigate("/platform/pontual-consult/new");
  }

  return (
    <Box display="flex" flexDirection="column" px={6} py={8} gap={10}>
      <Box display="flex" flexDirection="column" gap={4}>
        <Title>Consulta pontual</Title>
        <Subtitle>Consulte suas consultas rápidas salvas ou crie novas.</Subtitle>
      </Box>
      <Box display="flex" alignItems="flex-end" justifyContent="space-between">
        <FormProvider {...formFilter}>
          <form>
            <Box display="flex" alignItems="center" gap={4}>
              <TextField name="search" label="Buscar" placeholder="Busque por ID da consulta" endIcon={<BiSearch />} />
              <TextField name="date" label="Data da consulta" placeholder="Selecione uma data" endIcon={<BiCalendarAlt />} />
              <GeneralComponents.CustomSelect
                variant="outlined"
                LabelText='API'
                value="all"
                onChange={() => { }}
                fullWidth
              >
                <GeneralComponents.CustomMenuItem value='all'>Todas as APIs</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='people'>Pessoas</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='company'>Empresas</GeneralComponents.CustomMenuItem>
              </GeneralComponents.CustomSelect>
              <Box display="flex" alignItems="center" alignSelf="end" height={55} gap={4}>
                <Button onClick={() => { }} color="secondary" size="small">Filtrar</Button>
                <GeneralComponents.ClearFilterButton variant='text' onClick={() => { }}>Limpar Filtros</GeneralComponents.ClearFilterButton>
              </Box>
            </Box>
          </form>
        </FormProvider>

        <Box>
          <Button
            StartIcon={<BiPlus size={24} />}
            size="small"
            onClick={handleClickNewConsult}
          >
            Nova consulta
          </Button>
        </Box>
      </Box>

      <Paper>
        <Box p={6}>
          <GeneralComponents.StyledDataGridWithPagination
            rows={data.list}
            columns={columns.pontualConsultColumns.list}
            pagination
          />
        </Box>
      </Paper>
    </Box>
  )
}

export default Page;