import React from 'react';
import { Container } from './styles';
import { Box, Typography } from '@mui/material';

interface ComponentProps {
  children: any,
  description: string | undefined,
  title: string,
}

const Component: React.FC<ComponentProps> = ({
  children,
  description,
  title
}) => {
  return <Container>
    <Typography variant='cardTitle'>{title}</Typography>
    <Typography variant='body1'>{description}</Typography>

    <Box
      display={'flex'}
      flexDirection={'column'}
      gap={4}
    >
      {children}
    </Box>
  </Container>
}

export default Component;
