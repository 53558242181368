import { GridColDef } from "@mui/x-data-grid";

const PartialColumns: GridColDef[] = [
    {
        field: 'Product',
        headerName: '',
        cellClassName: (params) => {
            const productId = params.value;
            return `${productId}-border product-tag`;
        }
        ,
        width: 120
    },
    { field: 'FreeRequests', headerName: 'Requisições gratuitas', width: 190, align: 'center', headerAlign: 'center'},
    { field: 'PaidRequests', headerName: 'Requisições pagas', width: 175, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR'), align: 'center', headerAlign: 'center' },
    {
        field: 'Price',
        headerName: 'Valor',
        width: 175,
        valueFormatter: 
        (params) => 
        parseFloat(params.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        align: 'center',
        headerAlign: 'center'
    },
];

export default PartialColumns