import { GridValidRowModel } from "@mui/x-data-grid";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface installmentsInfo {
    installmentsList: GridValidRowModel[],
    showInstallmentsSection: boolean
}

const initialState: installmentsInfo = {
    installmentsList: [],
    showInstallmentsSection: false
}

const installmentsReducer = createSlice({
    name: 'bankSlipInfo',
    initialState,
    reducers: {
        setInstallmentsInfo: (state, action: PayloadAction<installmentsInfo>) => {
            return {...state, ...action.payload}
        },
        setShowInstallmentsSection: (state, action: PayloadAction<boolean>) => {
            state.showInstallmentsSection = action.payload
        }
    }
});

export const { setInstallmentsInfo, setShowInstallmentsSection } = installmentsReducer.actions;

export default installmentsReducer.reducer;