import { GridValidRowModel } from "@mui/x-data-grid";
import { BillingTypes } from "@types";
import dayjs from "dayjs";
require('dayjs/locale/pt-br')


const transformPartialToValidRows = (billingData: BillingTypes.ListPartialsApiResponse | undefined) => {
  const PartialRows: GridValidRowModel[] | undefined = billingData?.Partials ? billingData?.Partials.map((partial, index) => ({
    id: index,
    Product: partial.Product,
    Price: partial.Price.toFixed(2),
    TotalRequests: partial.TotalRequests,
    PaidRequests: partial.PaidRequests,
    FreeRequests: partial.FreeRequests,
    AveragePrice: partial.AveragePrice.toFixed(2),
    VolumeDiscount: partial.VolumeDiscount.toFixed(2),
    PriceProjection: partial.PriceProjection.toFixed(2),
    PartialLastDay: partial.PartialLastDay
  })) : []

  return PartialRows
}

const transformPartialDetailToValidRows = (Consumption: BillingTypes.ConsumptionInfoApiResponse[] | undefined) => {
  const PartialRows: GridValidRowModel[] | undefined = Consumption ? Consumption.map((element, index) => ({
    id: index,
    Dataset: element.Dataset,
    Scope: element.Scope,
    Price: element.Price.toFixed(2),
    ListPrice: element.ListPrice,
    TotalRequests: element.TotalRequests,
    PaidRequests: element.PaidRequests,
    FreeRequests: element.FreeRequests,
    AveragePrice: element.AveragePrice.toFixed(2),
    VolumeDiscount: element.VolumeDiscount.toFixed(2),
  })) : []

  return PartialRows
}

const isThereAnyOverdueInvoice = (invoiceRows: any) => {

  

  if (!!invoiceRows?.length) {

    const filteredInvoices = invoiceRows?.filter((invoice: any) => {

      if (!!invoice?.Installments?.length) {
        return !!invoice?.Installments?.filter((installment: any) => dayjs(installment?.PaymentDueDate).isBefore(dayjs()) && invoice?.Status === 'PENDING')?.length
      }

      return false
    })

    return filteredInvoices
  }

  return []
}


const isInvoiceOverdue = (params: any) => {
  const hasAnyOverdueInstallment = () => params.row.Installments ? params.row.Installments
    .filter((installment: any) => dayjs(installment.PaymentDueDate).isBefore(dayjs())) : []

  if (hasAnyOverdueInstallment().length > 0) {
    return true
  } else {
    return false
  }
}

const IsBankSlipOverdue = (params: any) => dayjs(params.row.PaymentDueDate).isBefore(dayjs()) && params.row.Status === 'PENDING'

const transformInvoiceToValidRows = (billingData: BillingTypes.ListInvoicesApiResponse | undefined) => {
  const InvoiceRows: GridValidRowModel[] | undefined = billingData ? billingData.Invoices.map((element, index) => ({
    id: index,
    ReferenceMonth: element.ReferenceMonth,
    InvoiceNumber: element.InvoiceNumber,
    InvoiceVerificationCode: element.InvoiceVerificationCode,
    Status: element.Status,
    InvoicePaid: element.InvoicePaid,
    InvoiceCancelled: element.InvoiceCancelled,
    Installments: element.Installments
  })) : []

  return InvoiceRows
}


const transformInstallmentsToValidRows = (billingData: BillingTypes.Installments | undefined) => {
  const InstallmentRows: GridValidRowModel[] | undefined = billingData ? billingData.Installments.map((installment) => ({
    id: installment.BankSlipId,
    url: installment.BankSlipUrl,
    Status: installment.Status,
    PaymentDueDate: installment.PaymentDueDate,
    Installment: installment.Installment,
    InstallmentValue: installment.InstallmentValue,
    EntryCode: installment.EntryCode
  })) : []

  return InstallmentRows
}

const transformConsolidatedReportsToValidRows = (billingData: BillingTypes.ListConsolidatedReportsApiResponse | undefined) => {
  const ConsolidatedReportRows: GridValidRowModel[] | undefined = billingData?.Reports ? billingData.Reports.map((element) => ({
    id: element,
    ReferenceMonth: element
  })) : []

  return ConsolidatedReportRows
}

const transformConsumptionListToValidRows = (billingData: any) => {
  const ConsumptionRows: GridValidRowModel[] | undefined = billingData?.Consumptions ? Object.entries(billingData.Consumptions)
    .flatMap((prop: any) => {
      const [month, monthObj] = prop
      return Object.entries(monthObj).map((product: any, index) => {
        const [productName, productObj] = product
        return {
          id: month + productName,
          Month: month,
          Product: productName,
          Price: productObj.Summary.Price.toFixed(2),
          TotalRequests: productObj.Summary.TotalRequests,
          PaidRequests: productObj.Summary.PaidRequests,
          FreeRequests: productObj.Summary.FreeRequests,
          AveragePrice: productObj.Summary.AveragePrice.toFixed(2),
          VolumeDiscount: productObj.Summary.VolumeDiscount.toFixed(2),
          PriceProjection: productObj.Summary.PriceProjection.toFixed(2),
          PartialLastDay: productObj.Summary.PartialLastDay
        }
      }
      )

    }
    ) : []

  return ConsumptionRows
}

const transformConsumptionListToValidSerie = (data: any) => {

  var detailedUsageSummary: BillingTypes.detailedUsageSummarySeries[] = []

  const productAlreadyPushed = (productId: string) => detailedUsageSummary.filter((product) => product.id === productId)

  Object.entries(data.Consumptions).forEach((entry) => {
    const [month, monthValue] = entry;
    Object.entries(monthValue as Object).forEach((product) => {
      let [productName, productValue] = product

      if (productName === 'BigBoost') {
        productName = 'Plataforma'
      }


      const productPrice = productValue.Summary.Price
      if (productAlreadyPushed(productName).length > 0) {
        detailedUsageSummary.find((product) => product.id === productName)?.data.push({ x: dayjs(month, 'YYYY_MM', 'pt-br').toDate(), y: productPrice })
      } else {
        detailedUsageSummary.push({ id: productName, data: [{ x: dayjs(month, 'YYYY_MM', 'pt-br').toDate(), y: productPrice }] })
      }
    })
  })

  detailedUsageSummary.map((product) => product.data.sort().reverse())

  return detailedUsageSummary
}

const formatToLocaleString = (value: number) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })

const groupBySelectedKey = (list: any, validationTest: any) => {

  const resultList = [[], []]

  return list?.reduce(
    ([matchedItems, otherItems]: any, item: any) =>
      validationTest(item) ?
        [[...matchedItems, item], otherItems]
        :
        [matchedItems, [...otherItems, item]]
    , resultList);
}


export {
  transformConsumptionListToValidSerie,
  transformPartialToValidRows,
  transformPartialDetailToValidRows,
  transformConsumptionListToValidRows,
  transformInvoiceToValidRows,
  transformInstallmentsToValidRows,
  transformConsolidatedReportsToValidRows,
  isInvoiceOverdue,
  isThereAnyOverdueInvoice,
  IsBankSlipOverdue,
  formatToLocaleString,
  groupBySelectedKey
}