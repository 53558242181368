import {
  Box,
  Dialog
} from '@mui/material';
import React from 'react';
import {
  Actions,
  Content,
  ContentText,
  DialogDivider,
  Title
} from './styles';
import {  Button } from '@components';



interface DialogContainerProps {
  open: boolean;
  title: string;
  description: string;
  handleCancel: any;
  handleConfirm: any;
  children?: React.ReactNode | undefined
  negativeText?: string
  positiveText?: string
}

const DialogContainer: React.FC<DialogContainerProps> = (
  {
    open,
    title,
    description,
    handleCancel,
    handleConfirm,
    children,
    negativeText = 'Cancelar',
    positiveText = 'Confirmar'
  }) => {

  return <Dialog open={open}>
    <Box
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      gap='16px'
      maxWidth='360px'
      minWidth='280px'
      padding='32px'
    >
      <Title>{title}</Title>
      <DialogDivider />
      <Content>
        <ContentText>
          {description}
        </ContentText>
         {children}
      </Content>
      <Actions>
        <Button
          size='small'
          color='negative'
          type='button'
          onClick={handleCancel}
        >{negativeText}</Button>
        <Button
          size='small'
          color='primary'
          type='button'
          onClick={handleConfirm}
        >{positiveText}</Button>
      </Actions>
    </Box>
  </Dialog>;
}

export default DialogContainer;