import React from "react";

import { Box } from "@mui/material";
import { Paper, RadioGroup, Onboarding, Datepicker } from "@components";
import { Header } from "../../components";
import { Card, Title, Description, Icon } from "./styles";
import { BiCog, BiBookmarks } from "react-icons/bi";

import { useFormContext } from 'react-hook-form';
import { steps } from "@constants";

const types = [
  {
    value: "new",
    title: "Configurar uma consulta do início",
    description: "Escolha quais dados serão incluídos na consulta.",
    icon: BiCog
  },
  {
    value: "saved",
    title: "Utilizar uma configuração de consulta salva",
    description: "Execute a consulta rapidamente com uma configuração já salva.",
    icon: BiBookmarks
  }
];

const Openned = () => {
  const { watch } = useFormContext();

  const checked = watch().typeConsult;
  
  return (
    <>
      <Paper>
        <Box p={6}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Header title="Escolha como vai iniciar uma consulta" />
            <RadioGroup.Container name="typeConsult">
              <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} width="100%" gap={4} id="type-options">
                {
                  types.map(({ value, title, description, icon: IconType }) => (
                    <RadioGroup.Item value={value} key={value}>
                      <Card variant="outlined" clickable checked={checked === value}>
                        <Box display="flex" alignItems="center" gap={6} px={4} py={6}>
                          <Icon>
                            <IconType size={40} />
                          </Icon>
                          <Box display="flex" flexDirection="column" gap={4}>
                            <Box display="flex" alignItems="center">
                              <Title>{title}</Title>
                            </Box>
                            <Description>{description}</Description>
                          </Box>
                        </Box>
                      </Card>
                    </RadioGroup.Item>
                  ))
                }
              </Box>
            </RadioGroup.Container>
          </Box>
        </Box>
      </Paper>
      <Onboarding
        steps={steps.PontualConsult.Type}
        controlPath='PontualConsult.Type'
      />
    </>
  )
}

export default Openned;