import React from 'react';
import { CheckboxLabel, DialogCheckbox } from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import { CheckboxProps } from '@mui/material';
import { pontualConsult } from '@utils';

interface ItemProps {
    label: string | undefined;
    name: string;
}

const Item: React.FC<ItemProps> = (
    {
        label,
        name
    }
) => {

    const { control, watch, setValue } = useFormContext()
    const availableData = watch()
    
    return <>
        <CheckboxLabel
            label={label}
            name={name}
            control={
                <Controller
                    name={name}
                    control={control}
                    shouldUnregister={true}
                    render={({ field }) => {

                        const nameSplitted = name.split('.')
                        const checked = name.split('.').length > 1 ? (!!availableData[nameSplitted[0]] && !!availableData[nameSplitted[0]][nameSplitted[1]]) : !!availableData[name]

                        const onChange: CheckboxProps['onChange'] = (event, checked) => {
                            if (event.target.name === 'all') {
                                field.onChange(checked)

                                if (availableData) {
                                    Object.entries(availableData).map(([keyParent, value]) => {
                                        if (pontualConsult.isObject(value)) {
                                            Object.entries(value).map(([keyChild, value]) => setValue(`${keyParent}.${keyChild}`, checked))
                                        } else {
                                            setValue(keyParent, checked)
                                        }
                                    })
                                    return
                                }
                            } else {

                                field.onChange(checked)
                                const allChecked = !!!Object.entries(availableData).some(([keyParent, value]) => {
                                    if(keyParent === 'all'){
                                        return false
                                    }
                                    if (pontualConsult.isObject(value)) {
                                        return Object.entries(value).some(([keyChild, value]) => !!!value)
                                    }
                                    return !!!value
                                })
                                setValue('all', allChecked)
                                return
                            }
                        }

                        return <DialogCheckbox
                            name={name}
                            checked={checked}
                            onChange={(event, checked) => onChange(event, checked)}
                        />
                    }}
                />
            }
        />
    </>;
}

export default Item;