import React, { ReactNode } from "react";

import { Collapse, Box, Divider } from "@mui/material";
import { Paper } from "@components";
import { Title } from "./styles";

import { BiCaretDown, BiCaretUp } from "react-icons/bi";

interface CollapseProps {
  children: ReactNode | string,
  title: string,
  collapsed?: boolean
}
const Component: React.FC<CollapseProps> = ({
  children,
  title,
  collapsed = false
}) => {
  const [expanded, setExpanded] = React.useState(collapsed);

  const CollapseIcon = expanded ? BiCaretUp : BiCaretDown;

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <>
      <Paper variant="outlined">
        <Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            onClick={handleExpand} p={5} sx={{ cursor: "pointer" }}
          >
            <Title>
              {title}
            </Title>
            <CollapseIcon size={18} />
          </Box>
          <Collapse in={expanded} timeout="auto">
            <Divider />
            {children}
          </Collapse>
        </Box>
      </Paper>
    </>
  )
}
export default Component;