import { styled } from '@mui/system';
import { Button } from '@mui/material';

const ToggleSeeChartButton = styled(Button)`
    border-radius: 8px;
    text-transform: none;
    height: 40px;
    width: fit-content;
    white-space: nowrap;
    background-color: transparent;
    padding: 0;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    color: #555866;
    transition: all .5s ease;

    :hover{
        font-size: 12px;
        font-weight: 700;
        box-shadow: none;
        background-color: transparent;
        transition: all .5s ease;
    }
`;

export default ToggleSeeChartButton;