import { Button, FormWizard } from '@components';
import React from 'react';
import { SchemaFeatures } from '../../schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Features, FileSelection } from '../../Steps';
import { BiRightArrowAlt } from 'react-icons/bi';

interface FormProps {
    handleSubmit: (data?: any, event?: any) => any
}


const GoButton = <Button
    EndIcon={
        <BiRightArrowAlt size={24} />
    }
    size='small'
    type='submit'>
    Avançar
</Button>

const Form: React.FC<FormProps> = ({
    handleSubmit
}) => {

    const formFeatures = useForm({ resolver: yupResolver(SchemaFeatures) });
    const formSelection = useForm({ resolver: yupResolver(SchemaFeatures) });
    const formResult = useForm({ resolver: yupResolver(SchemaFeatures) });

    return <FormWizard.Container>
        <FormWizard.Steps handleSubmit={handleSubmit}>
            <FormWizard.Step
                label={'Escolha da verificação'}
                renderButton={GoButton}
                {...formFeatures}
            >
                <Features />
            </FormWizard.Step>
            <FormWizard.Step
                label={'Envio de imagens'}
                renderButton={<Button
                    EndIcon={
                        <BiRightArrowAlt size={24} />
                    }
                    size='small'
                    type='submit'>
                    Comparar rostos
                </Button>}
                {...formSelection}
            >
                <FileSelection />
            </FormWizard.Step>
            <FormWizard.Step
                label={'Resultado'}
                renderButton={GoButton}
                {...formResult}
            >
                Resultado
            </FormWizard.Step>
        </FormWizard.Steps>
    </FormWizard.Container>;
}

export default Form;