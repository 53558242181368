import React from "react";

import { Box, useTheme } from "@mui/material";
import { Button, Paper } from "@components";
import { Modal, Title, StepContent, StepTitle, StepDescription, StepImage, IconContent, Divider, MenuItem, Image } from "./styles";

import { BiX } from "react-icons/bi";
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";

import SwipeableViews from 'react-swipeable-views';

import Guide from "../../../guide.json";

type ComponentProps = {
  open: boolean,
  onClose?: () => void
}

const Component: React.FC<ComponentProps> = ({ open, onClose }) => {
  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (

    <Modal
      open={open}
      onClose={onClose}
    >
      <Box
        width="75%"
      >
        <Paper>
          <Box display="flex" flexDirection="column" gap={6} p={8}>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Title>Guia de captura de imagens</Title>
                {
                  !!onClose && (
                    <Button
                      size="small"
                      color="secondary"
                      StartIcon={<BiX size={28} />}
                      onClick={onClose}
                    />
                  )
                }
              </Box>
              <Divider />
            </Box>
            <Box display="flex" gap={6}>
              <Box display="flex" justifyContent="space-between" width="25%">
                <Box display="flex" flexDirection="column" gap={2} width="100%">
                  {
                    Guide.map((step, index) => (
                      <MenuItem
                        key={index}
                        active={index === activeStep}
                        onClick={() => handleStepChange(index)}>
                        {step.section}
                      </MenuItem>
                    ))
                  }
                </Box>
                <Divider direction="vertical" />
              </Box>
              <Box width="75%">
                <SwipeableViews index={activeStep}>
                  {
                    Guide.map((step, index) => (
                      <StepContent key={index}>
                        <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} gap={2}>
                          <StepImage>
                            <Image
                              src={step.wrongImage}
                            />
                            <IconContent>
                              <FaRegTimesCircle
                                color={theme.palette.secondary.red}
                                size={24}
                              />
                            </IconContent>
                          </StepImage>
                          <StepImage>
                            <Image
                              src={step.rightImage}
                            />
                            <IconContent>
                              <FaRegCheckCircle
                                color={theme.palette.success.pure}
                                size={24}
                              />
                            </IconContent>
                          </StepImage>
                        </Box>
                        <StepTitle>{step?.title}</StepTitle>
                        <StepDescription>{step?.description}</StepDescription>
                      </StepContent>
                    ))
                  }
                </SwipeableViews>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>

  )
}

export default Component;