
import { styled } from '@mui/system';
import { Tabs } from '@mui/material';

const StyledTabs = styled(Tabs)`
    
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 20px 0px;
    width: fit-content;
    grid-column: 2;
    justify-self: flex-end;
    align-self: center;

    .MuiTab-root{
        font-family: 'Picadilly', sans-serif;
        text-transform: none;
    }

    .Mui-selected{
        color: #0068ff;
        border-color: #0068ff;
    }
`;

export default StyledTabs;