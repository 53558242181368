import { DialogActions, DialogContent, DialogContentText, DialogTitle, Divider} from "@mui/material";
import { styled } from '@mui/material/styles';

const Title = styled(DialogTitle)(({ theme }) => ({
    color: '#000066',
    fontFamily: 'Be vietnam',
    fontSize: '18px',
    fontWeight: 700,
    padding: 0
}))

const DialogDivider = styled(Divider)(({ theme }) => ({
    width: '100%',
    border: 'none',
    borderBottom: '1px solid #DDE7EA'
}))

const Content = styled(DialogContent)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: 0
}))

const ContentText = styled(DialogContentText)(({ theme }) => ({
    color: '#555866',
    fontFamily: 'Be vietnam',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0
}))

const Actions = styled(DialogActions)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    padding: 0
}))



export {
    Title,
    DialogDivider,
    Content,
    ContentText,
    Actions
}