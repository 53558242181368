import { formatToLocaleString } from '@utils';
import React from 'react';


interface BillingCardsProps {
  product: string;
  productValue: number | undefined;
}

const BillingCards: React.FC<BillingCardsProps> = ({ product, productValue }: BillingCardsProps) => {
  return <div className={`billing-summary-card-content billing-summary-card-${product}-border`}>
    <span className='billing-summary-product-title'>{product}</span>
    <div className='billing-summary-card-content-values'>
      <span className='billing-summary-current-usage-title'>Consumo atual</span>
      <span className='billing-summary-card-value'>{formatToLocaleString(productValue ?? 0)}</span>
    </div>
  </div>;
}

export default BillingCards;