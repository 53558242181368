import { SxProps } from "@mui/material";

const PaperContainer: SxProps = {
    minHeight: 400,
    height: 'fit-content',
    width: '100%',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '16px',
    gap: '32px'
}

const DashedContainer: SxProps = {
    border: '3px dashed #BED0D7',
    borderRadius: '8px',
    height: '400px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all .5s ease'
}

const DraggingDashedContainer: SxProps = {
    border: '3px dashed #909EA3',
    borderRadius: '8px',
    background: '#BED0D750',
    height: '400px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'all .5s ease'
}

const ContentContainer: SxProps = {
    fontSize: 20,
    color: '#55586670',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '280px',
    maxWidth: '800px',
    textAlign: 'center',
    gap: '16px'
}

const UploadedFileContainer: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
    color: '#555866',
    textTransform: 'uppercase',
    textAnchor: 'middle',
    fontSize: 20
}

const ProgressBarContainer: SxProps = {
    width: '100%',
    height: '10px',
    borderRadius: '8px',
    background: '#e0e0e010',
    boxShadow: 'inset 2px 2px 3px #bebebe, inset -2px -2px 3px #ffffff'
}

const FileInfoContainer: SxProps = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px'
}

const UploadOptionsContainer: SxProps = {
    display: 'flex',
    padding: '0px 16px',
    gap: '16px'
}

const UploadMessageContainer: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px'
}

const UploadMessageDivider: SxProps = {
    width: '50%'
}

export {
    PaperContainer,
    DashedContainer,
    DraggingDashedContainer,
    ContentContainer,
    FileInfoContainer,
    UploadedFileContainer,
    UploadOptionsContainer,
    ProgressBarContainer,
    UploadMessageContainer,
    UploadMessageDivider
}