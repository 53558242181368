import { styled } from '@mui/material/styles';

const Title = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.secondary.blue,
  whiteSpace: "nowrap"
}))

const Hr = styled("hr")(({ theme }) => ({
  width: "100%",
  borderBottom: theme.palette.neutral?.[300]
}))

export {
  Title,
  Hr
}