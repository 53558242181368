import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface ViewUserData {
    login: string;
    name: string;
    email: string;
    isSystemAdmin: boolean;
    isEnabled: boolean;
    group: string;
    "2fa": boolean;
    fileTransfer?: boolean;
    showViewUserSection?: boolean;

}
  
const initialState: ViewUserData = {
    login: '',
    name: '',
    email: '',
    isSystemAdmin: false,
    isEnabled: false,
    "2fa": false,
    group: '',
    fileTransfer: false,
    showViewUserSection: false,
}

const viewUserSlice = createSlice({
    name: 'viewUser',
    initialState,
    reducers: {
      setViewUserInfo: (state, action: PayloadAction<ViewUserData>) => {
        return {...state, ...action.payload}
      },
      setShowViewUserSection: (state, action: PayloadAction<boolean>) => {
        state.showViewUserSection = action.payload
      }
    }
});

export const { setViewUserInfo, setShowViewUserSection} = viewUserSlice.actions;

export default viewUserSlice.reducer;