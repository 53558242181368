import { styled } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';

const StyledBillingTable = styled(DataGrid)`
    border: none;
    
    .MuiDataGrid-row:hover {
        background-color: transparent;
    }

    .MuiDataGrid-cell{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #555866;
    }
    
    .MuiDataGrid-cell,
    .MuiDataGrid-columnSeparator,
    .MuiDataGrid-columnHeader,
    .MuiDataGrid-columnHeaders,
    .MuiDataGrid-columnHeaderTitle,
    .MuiDataGrid-columnHeaderWrapper {
        border: none;
    }

    .MuiDataGrid-columnSeparator{
        display: none;
    }

    .MuiDataGrid-menuIconButton{
        display: none;
    }
    

    .MuiDataGrid-cell:not(:first-child):not(:last-child){
        border-right: 2px solid #f0f8fa;
    }

    .MuiDataGrid-footerContainer{
        display: none;
    }

    .MuiDataGrid-columnHeaderTitle{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: 0.5px;
        color: #000066;
    }

`;

export default StyledBillingTable;