import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#api-options',
    title: 'Seleção de API',
    content: 'Escolha a API desejada para sua consulta.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: true
  },
  {
    target: '#option-pessoas',
    title: 'Seleção de API',
    content: 'Para prosseguir, selecione a API que deseja consultar.',
    disableBeacon: true,
    floaterProps: { placement: 'right' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#btn-next',
    title: 'Próxima etapa',
    content: 'Após a escolha da API clique no botão para avançar para a próxima etapa.',
    disableBeacon: true,
    floaterProps: { placement: 'left' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;