import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '.financial-management-main-container',
    title: 'Acompanhe o consumo parcial do mês',
    content: 'Inicie o tour para aprender como acompanhar o consumo parcial do mês no BDC Center',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
  },
  {
    target: '.current-usage-chart-container',
    title: 'Veja o consumo por produto',
    content: 'Utilize o gráfico para acompanhar como cada produto está sendo consumido no mês corrente. Passe o mouse sobre o gráfico para ver mais detalhes.',
    disableBeacon: true,
    floaterProps: { placement: 'top-end' },
  },
  {
    target: '.current-usage-table-container',
    title: 'Detalhes do consumo',
    content: 'Use esta tabela para verificar em detalhes o consumo do produtos no mês corrente.',
    disableBeacon: true,
    floaterProps: { placement: 'top-end' },
  },
  {
    target: '.financial-management-main-container',
    title: 'Tour finalizado',
    content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'center' }
  },
];

export default steps;