import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { GeneralComponents, BillingComponents, CustomPagination, Onboarding } from "@components";

import { useDispatch, useSelector } from 'react-redux';
import { BillingTypes } from '@types';
import { showToastCustomErrorMessage, handleAxiosError, transformPartialDetailToValidRows, transformPartialToValidRows } from '@utils';
import {  Billing } from '@services';

import { currentUsageFinancialManagementGridColumns, steps } from "@constants";

import SeeDetailsSection from './SeeDetailsSection';

import '../financialManagement.css'
import './currentUsage.css'

import { reducers, slices } from "@store";

import { IoMdArrowDropleft } from 'react-icons/io';
import CurrentUsageOnboarding from './CurrentUsageOnboarding';
import CurrentUsageDetails from '../CurrentUsageDetails';
import { SelectChangeEvent } from '@mui/material';

const datagridSlots = {
  pagination: CustomPagination,
  noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}
const pageSizeOptions: number[] = []
const PartialLastDay = parseInt(dayjs().subtract(1, 'day').format('DD'))

const FinancialManagement: React.FC = () => {

  const [showChart, setShowChart] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [platformValue, setPlatformValue] = useState<string>('Price')
  const [bigIdValue, setBigIdValue] = useState<string>('Price')
  const [platformData, setPlatformData] = useState<[]>()
  const [bigIdData, setBigIdData] = useState<[]>()
  const [partialsListData, setPartialListData] = useState<BillingTypes.ListPartialsApiResponse>()
  const { showModal, Consumption } = useSelector((state: reducers.RootState) => state.seeDetailsReducer)
  const dispatch = useDispatch()

  const handleCloseSeeDetailsSection = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch(slices.seeDetails.setSeeDetailsInfo({ Consumption, showModal: false }))
  }

  const getlistPartialsData = async () => await Billing.listPartials()
  
  const getDetailedPlatformPartialData = async () => await Billing.getPartialDetail({
    Product: 'BigBoost',
    PartialLastDay
  })

  const getDetailedBigIdPartialData = async () => await Billing.getPartialDetail({
    Product: 'BigId', 
    PartialLastDay 
  })

  const checkIfisInvalidSession = (billingApiResponse: any) => billingApiResponse.Message === "INVALID_SESSION_ID"

  const handleBillingApiResponse = (billingApiResponse: any, isFrom: string) => {
    if (checkIfisInvalidSession(billingApiResponse)) {
      showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
      dispatch(slices.user.setUserInitialState())
    } else if (isFrom === 'listpartials') {
      setPartialListData(billingApiResponse)
      setIsLoading(false)
    } else if (isFrom === 'PlatformData') {
      const PlaformData = billingApiResponse.Consumption
      setPlatformData(PlaformData)
      setIsLoading(false)
    } else if (isFrom === 'BigIdData') {
      const BigIdData = billingApiResponse.Consumption
      setBigIdData(BigIdData)
      setIsLoading(false)
    }
  }

  const tryGetlistPartialsData = async () => {
    try {
      const { data: apiBillingManagementApiResponse } = await getlistPartialsData()
      handleBillingApiResponse(apiBillingManagementApiResponse, 'listpartials')
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const tryGetDetailedPlatformPartialData = async () => {
    try {
      const { data: apiBillingManagementApiResponse } = await getDetailedPlatformPartialData()
      handleBillingApiResponse(apiBillingManagementApiResponse, 'PlatformData')
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const tryGetDetailedBigIdPartialData = async () => {
    try {
      const { data: apiBillingManagementApiResponse } = await getDetailedBigIdPartialData()
      handleBillingApiResponse(apiBillingManagementApiResponse, 'BigIdData')
    } catch (error: any) {
      handleAxiosError(error)
    }
  }


  const toggleShowChart = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setShowChart(!showChart)
  }

  const handlePlatformValueChange = (event: SelectChangeEvent<unknown>) => {
    setPlatformValue(event.target.value as string)
  }

  const handleBigIdValueChange = (event: SelectChangeEvent<unknown>) => {
    setBigIdValue(event.target.value as string)
  }

  useEffect(() => {
    tryGetlistPartialsData()
    tryGetDetailedPlatformPartialData()
    tryGetDetailedBigIdPartialData()
  }, [])

  const PartialDetails = transformPartialDetailToValidRows(Consumption)
  const PartialRows = transformPartialToValidRows(partialsListData)

  return <main className='financial-management-main-container'>

      {!showModal ?
        <>

          <div className='financial-management-presentation-container'>
            <h2 className='financial-management-title'>Consumo do mês</h2>
            <p className='financial-management-paragraph'>Visualize o consumo atual do mês</p>
          </div>
          <section className='current-usage-container'>
            <div className={showChart ? 'current-usage-chart-container' : 'current-usage-chart-container-collpsed'}>

              <div className='current-usage-platform-chart'>
                <div className='current-usage-platform-header'>
                  <h3>Plataforma</h3>
                  <GeneralComponents.CustomSelect
                  LabelText=''
                  onChange={handlePlatformValueChange}
                  value={platformValue}
                  variant='standard'

                  >
                    <GeneralComponents.CustomMenuItem value='Price'>Preço</GeneralComponents.CustomMenuItem>
                    <GeneralComponents.CustomMenuItem value='TotalRequests'>Total de req.</GeneralComponents.CustomMenuItem>
                  </GeneralComponents.CustomSelect>
                </div>
                <GeneralComponents.ResponsivePieChart
                data={platformData ?? []}
                loading={isLoading}
                showChart={showChart}
                product={'Platform'}
                productValue={platformValue}
                />
              </div>
              <hr></hr>
              <div className='current-usage-bigId-chart'>
                <div className='current-usage-bigId-header'>
                  <h3>BigID</h3>
                  <GeneralComponents.CustomSelect
                  variant='standard'
                  LabelText=''
                  onChange={handleBigIdValueChange}
                  value={bigIdValue}
                 
                  >
                    <GeneralComponents.CustomMenuItem value='Price'>Preço</GeneralComponents.CustomMenuItem>
                    <GeneralComponents.CustomMenuItem value='TotalRequests'>Total de req.</GeneralComponents.CustomMenuItem>
                  </GeneralComponents.CustomSelect>
                </div>
                <GeneralComponents.ResponsivePieChart
                data={bigIdData ?? []}
                loading={isLoading}
                showChart={showChart}
                product={'BigId'}
                productValue={bigIdValue}
                />
              </div>
            </div>
            <div className='current-usage-chart-options'>
              <BillingComponents.ToggleSeeChartButton variant='text' onClick={toggleShowChart}>{showChart ? 'Esconder gráfico' : 'Visualizar gráfico'}</BillingComponents.ToggleSeeChartButton>
            </div>
            <div className='current-usage-table-container'>
              <GeneralComponents.StyledDataGridWithPagination
                rows={PartialRows}
                columns={currentUsageFinancialManagementGridColumns}
                loading={isLoading}
                pagination
                slots={datagridSlots}
                pageSizeOptions={pageSizeOptions}
              />
            </div>
            <Onboarding
              steps={steps.Financial.CurrentUsage}
              controlPath="CurrentUsage"
            />
            <CurrentUsageDetails />
          </section>
        </>
        :
        <>
          <div className='financial-management-presentation-details-container'>
            <GeneralComponents.DefaultWhiteButton sx={{ width: '32px !important', padding: '0px !important' }} variant='contained' onClick={handleCloseSeeDetailsSection}><IoMdArrowDropleft size={24} /></GeneralComponents.DefaultWhiteButton>
            <h2 className='financial-management-title'>Detalhes do consumo atual</h2>
          </div>
          <SeeDetailsSection partialDetails={PartialDetails} />
        </>
      }
    </main>
}

export default FinancialManagement;