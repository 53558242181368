import React from "react";

import { FormControl } from "@mui/material";

import { useFormContext, Controller } from "react-hook-form";

import CheckboxProvider from "../provider";

interface ComponentProps {
  children: React.ReactNode,
  name: string,
  fullWidth?: boolean
}

const Component: React.FC<ComponentProps> = ({ children, name, fullWidth = true }) => {
  const {
    control
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={false}
      render={({ field }) => (
        <FormControl fullWidth={fullWidth}>
          <CheckboxProvider field={field}>
            {children}
          </CheckboxProvider>
        </FormControl>
      )
      }
    />
  )
}

export default Component