// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.positive-tag-status{
    padding: 6px 8px;
    gap: 4px;
    background: #EAFBF0;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 100%;
    color: #14562C;
    width: -moz-fit-content;
    width: fit-content;
}

.neutral-tag-status{
    padding: 6px 8px;
    gap: 4px;
    background: #F3F8FA;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 100%;
    color: #555866;
}

.negative-tag-status{
    padding: 6px 8px;
    gap: 4px;
    background: #FFE5EB;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 100%;
    color: #FF003C;
}

.warning-tag-status{
    padding: 6px 8px;
    gap: 4px;
    background: #FFFAE5;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 100%;
    color: #A47F22;
}
`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/StatusTag/statusTag.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,0BAA0B;IAC1B,iBAAiB;IACjB,cAAc;IACd,uBAAkB;IAAlB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,0BAA0B;IAC1B,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,0BAA0B;IAC1B,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,2BAA2B;IAC3B,0BAA0B;IAC1B,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".positive-tag-status{\n    padding: 6px 8px;\n    gap: 4px;\n    background: #EAFBF0;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600 !important;\n    font-size: 12px !important;\n    line-height: 100%;\n    color: #14562C;\n    width: fit-content;\n}\n\n.neutral-tag-status{\n    padding: 6px 8px;\n    gap: 4px;\n    background: #F3F8FA;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600 !important;\n    font-size: 12px !important;\n    line-height: 100%;\n    color: #555866;\n}\n\n.negative-tag-status{\n    padding: 6px 8px;\n    gap: 4px;\n    background: #FFE5EB;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600 !important;\n    font-size: 12px !important;\n    line-height: 100%;\n    color: #FF003C;\n}\n\n.warning-tag-status{\n    padding: 6px 8px;\n    gap: 4px;\n    background: #FFFAE5;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600 !important;\n    font-size: 12px !important;\n    line-height: 100%;\n    color: #A47F22;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
