import { reducers } from '@store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function AdminRoute() {
  const isAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin);
  if (!isAdmin) {
    return <Navigate to="/dashboard" />
  }
  return <Outlet />
}

