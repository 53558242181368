import React, { useCallback, useEffect, useState } from 'react';

import { GeneralComponents, BillingComponents, Onboarding } from "@components";

import { useDispatch } from 'react-redux';
import { slices } from "@store";
import { Billing } from '@services';
import {
  transformConsumptionListToValidRows,
  transformConsumptionListToValidSerie,
  handleAxiosError,
  showToastCustomErrorMessage,
  showToastCustomInfoMessage
} from "@utils";
import { BillingTypes } from '@types';
import dayjs, { Dayjs } from 'dayjs';

import { consumptionColumns, isDesktopWidth, steps } from "@constants";

import DetailedUsageSummaryOnboarding from './DetailedUsageSummaryOnboarding';
import detailedUsageSummaryPagination from './DetailedUsageSummaryPagination';

import './detailedUsageSummary.css'
import { BsFilter } from 'react-icons/bs';
import FilterSection from './FilterSection';
import ConsumptionDetails from './ConsumptionDetails';
import { Box, CircularProgress } from '@mui/material';


const datagridSlots = {
  toolbar: isDesktopWidth ? GeneralComponents.ExportToolbar : null,
  pagination: detailedUsageSummaryPagination,
  noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não consumiu nenhum produto. Em breve você poderá visualizar o seu consumo histórico.' />
}
const pageSizeOptions: number[] = []

const DetailedUsageSummary: React.FC = () => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showChart, setShowChart] = useState<boolean>(true)
  const [isMobileFilterSectionOpen, setIsMobileFilterSectionOpen] = useState<boolean>(false)
  const [listConsumption, setListConsumption] = useState()
  const [detailedUsageSummarySeries, setDetailedUsageSummarySeries] = useState<BillingTypes.detailedUsageSummarySeries[]>()
  const [localFilterInfo, setLocalFilterInfo] = useState({
    ReferenceMonthStart: '',
    ReferenceMonthEnd: ''
  })

  const dispatch = useDispatch()

  const listConsumptions = async () => await Billing.listConsumptions({
    ReferenceMonthStart: localFilterInfo.ReferenceMonthStart === '' ? dayjs().subtract(2, 'year').format('YYYY_MM') : localFilterInfo.ReferenceMonthStart,
    ReferenceMonthEnd: localFilterInfo.ReferenceMonthEnd === '' ? dayjs().format('YYYY_MM') : localFilterInfo.ReferenceMonthEnd
  })

  const isSessionValid = (listConsumptionResponseData: any) => listConsumptionResponseData.Message !== 'INVALID_SESSION_ID'

  const toggleShowChart = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setShowChart(!showChart)
  }

  const tryListConsumption = useCallback(async () => {
    try {
      if (areFiltersValid()) {
        const { data: listConsumptionResponseData } = await listConsumptions()
        handleListConsumptionApiResponse(listConsumptionResponseData)
      }
    } catch (error: any) {
      handleAxiosError(error)
    }
  }, [localFilterInfo.ReferenceMonthStart, localFilterInfo.ReferenceMonthEnd])

  const areFiltersValid = () => {
    if (dayjs(localFilterInfo.ReferenceMonthStart, 'YYYY_MM', 'pt-br').isAfter(dayjs(localFilterInfo.ReferenceMonthEnd, 'YYYY_MM', 'pt-br'))) {
      showToastCustomErrorMessage('Erro ao filtrar notas fiscais', 'A data inicial precisa ser anterior à data limite.')
      setIsLoading(false)
      return false
    }
    return true
  }

  const handleReferenceMonthStartChange = (ReferenceMonthStart: Dayjs | null) => {
    if (ReferenceMonthStart) {
      setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthStart: dayjs(ReferenceMonthStart).format('YYYY_MM') })
    } else {
      setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthStart: '' })
    }
  }

  const handleReferenceMonthEndChange = (ReferenceMonthEnd: Dayjs | null) => {
    if (ReferenceMonthEnd) {
      setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthEnd: dayjs(ReferenceMonthEnd).format('YYYY_MM') })
    } else {
      setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthEnd: '' })
    }
  }

  const handleFilterRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDesktopWidth) {
      setIsMobileFilterSectionOpen(false)
    }

    event.stopPropagation()
    setIsLoading(true)
    tryListConsumption()
  }

  const handleClearFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDesktopWidth) {
      setIsMobileFilterSectionOpen(false)
    }

    event.stopPropagation()
    setLocalFilterInfo({ ReferenceMonthEnd: '', ReferenceMonthStart: '' })
  }

  const handleListConsumptionApiResponse = (listConsumptionResponseData: any) => {
    if (!isSessionValid(listConsumptionResponseData)) {
      showToastCustomErrorMessage('Sua sessão expirou.', 'Por favor, faça o login novamente.', 'session-timeout')
      dispatch(slices.user.setUserInitialState())
    } else if (listConsumptionResponseData.Message === 'NO_CONSUMPTIONS_FOUND') {
      showToastCustomInfoMessage('Nada encontrado', 'Nenhum consumo foi encontrado. Cheque seus filtros.')
      setIsLoading(false)
    } else if (listConsumptionResponseData.Message === 'NO_PARTIALS_FOUND') {
      showToastCustomInfoMessage('Nada encontrado', 'Nenhum consumo foi encontrado. Cheque seus filtros.')
      setIsLoading(false)
    } else if (!listConsumptionResponseData.Success) {
      showToastCustomErrorMessage('Erro na requisição', listConsumptionResponseData.Message)
      setIsLoading(false)
    } else {
      setListConsumption(listConsumptionResponseData)
      const consumptionSeries = transformConsumptionListToValidSerie(listConsumptionResponseData)
      setDetailedUsageSummarySeries(consumptionSeries)
      setIsLoading(false)
    }
  }

  const toggleMobileFilterSection = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsMobileFilterSectionOpen(!isMobileFilterSectionOpen)
  }

  const ConsumptionRows = transformConsumptionListToValidRows(listConsumption)

  useEffect(() => {
    if (localFilterInfo.ReferenceMonthEnd === '' && localFilterInfo.ReferenceMonthStart === '') {
      setIsLoading(true)
      tryListConsumption()
    }
  }, [localFilterInfo])

  return <main className='financial-management-main-container'>
    <div className='financial-management-presentation-container'>
      <h2 className='financial-management-title'>Consumo histórico</h2>
      <p className='financial-management-paragraph'>Visualize o histórico de consumo da sua corporação.</p>
    </div>
    {!isDesktopWidth ? <div className='usage-summary-options-container'>
      <GeneralComponents.CustomDefaultTextButton onClick={toggleMobileFilterSection} className='detailed-usage-summary-filter-button' startIcon={<BsFilter color='#0068ff' size={24} />}>Filtros</GeneralComponents.CustomDefaultTextButton>
    </div>
      :
      <></>
    }
    <FilterSection
      localFilterInfo={localFilterInfo}
      handleReferenceMonthStartChange={handleReferenceMonthStartChange}
      handleReferenceMonthEndChange={handleReferenceMonthEndChange}
      handleFilterRequest={handleFilterRequest}
      handleClearFilters={handleClearFilters}
      isMobile={!isDesktopWidth}
      open={isMobileFilterSectionOpen}
      toggleMobileFilterSection={toggleMobileFilterSection}
    />
    <section className='detailed-usage-summary-container'>
      <div id='historical-usage-chart-container' className={showChart ? 'detailed-usage-summary-chart-container' : 'detailed-usage-summary-chart-container-collpsed'}>
        {isLoading ?
          <Box
            width={'100%'}
            height={'100%'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <CircularProgress />
          </Box>
          :
          <GeneralComponents.ErrorBoundary>
            <GeneralComponents.ResponsiveLineChart
              data={detailedUsageSummarySeries ?? []}
              loading={isLoading}
              showChart={showChart}
            />
          </GeneralComponents.ErrorBoundary>
        }

      </div>
      <div className='detailed-usage-summary-chart-options'>
        <BillingComponents.ToggleSeeChartButton variant='text' onClick={toggleShowChart}>{showChart ? 'Esconder gráfico' : 'Visualizar gráfico'}</BillingComponents.ToggleSeeChartButton>
      </div>
      <div id='historical-usage-table-container' className='detailed-usage-summary-table-container'>
        <GeneralComponents.StyledDataGridWithPagination
          rows={ConsumptionRows ?? []}
          columns={consumptionColumns}
          loading={isLoading}
          pagination
          slots={datagridSlots}
          pageSizeOptions={pageSizeOptions}
        />
      </div>
      <Onboarding
        steps={steps.Financial.DetailedUsageSummary}
        controlPath="DetailedUsageSummary"
      />
      <ConsumptionDetails />
    </section>
  </main>
}

export default DetailedUsageSummary;