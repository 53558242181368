import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface FileTransferDetailsInfo {
    open: boolean;
    params: any;
}


const initialState: FileTransferDetailsInfo = {
   open: false,
   params: undefined
}

const fileTransferDetailsSlice = createSlice({
  name: 'fileTransferDetails',
  initialState: initialState,
  reducers: {
    setShowFileTransferDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowFileTransferDetailsSection, setParams } = fileTransferDetailsSlice.actions;

export default fileTransferDetailsSlice.reducer;