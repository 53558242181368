import React, { useEffect, useState } from 'react';
import { GeneralComponents } from '@components';
import { Box, Paper, SelectChangeEvent } from '@mui/material';
import { BsFilter, BsPlus } from 'react-icons/bs';
import { FileTransferCenter } from '@services';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import { handleAxiosError, showToastCustomErrorMessage, tranformFileListToValidRows } from '@utils';
import { GridValidRowModel } from '@mui/x-data-grid';
import { fileTransferGridColumns, isDesktopWidth } from '@constants';
import { AxiosError } from 'axios';
import UploadFiles from './components/UploadFiles';
import FileTransferOnboarding from './components/FileTransferOnboarding';
import FileTransferDetails from './components/FileTransferDetails';
import MobileFilterSection from './components/MobileFilterSection';

const datagridSlots = {
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui arquivos. Em breve você poderá visualizar os arquivos enviados/recebidos aqui.' />
}

const FileTransfer: React.FC = () => {

    const dispatch = useDispatch()
    const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)
    const deleteFileInfo = useSelector((state: reducers.RootState) => state.deleteFile)
    const [data, setData] = useState<GridValidRowModel[] | undefined>()
    const [mobileFilterSectionOpen, setMobileFilterSectionOpen] = useState<boolean>(false)
    const [selectState, setSelectState] = useState<{
        folder: string,
        origin: string,
    }>({

        folder: 'none',
        origin: 'none',
    })

    const [pageState, setPageState] = useState<{
        isLoading: boolean
        uploadFilesOpen: boolean
    }>({
        isLoading: false,
        uploadFilesOpen: false,
    })

    const listFiles = async (isFromClearFilters: boolean) =>
        await FileTransferCenter.listFiles(
            {
                login,
                folder: (selectState.folder === 'none' || !!isFromClearFilters) ? '' : selectState.folder,
                origin: (selectState.origin === 'none' || !!isFromClearFilters) ? '' : selectState.origin
            }
        )

    const tryListFiles = async (isFromClearFilters = false) => {
        try {
            setPageState({ ...pageState, isLoading: true })
            const { data: apiResponse } = await listFiles(isFromClearFilters)
            setData(tranformFileListToValidRows(apiResponse?.content))
            setPageState({ ...pageState, isLoading: false })

        } catch (err: any) {
            handleListFilesErrors(err)
            setPageState({ ...pageState, isLoading: false })
        }
    }

    const handleRequestFilters = () => {
        setMobileFilterSectionOpen(false)
        tryListFiles()
    }

    const handleListFilesErrors = (err: any) => {

        if (err instanceof AxiosError && !!!err?.response?.data) {
            handleAxiosError(err)
            return
        }

        if (err?.response?.data?.status === -1) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
            return
        }

        if (err?.response?.data?.status === -2) {
            showToastCustomErrorMessage('Erro ao fazer upload', 'Não foi possível obter todos os parâmetros necessários. Contate o suporte.')
            return
        }
        if (err?.response?.data?.status === -3) {
            showToastCustomErrorMessage('Erro ao fazer upload', "Não foi possível validar sua sessão. Contate o suporte.")
            return
        }

    }

    const handleToggleUploadFiles = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setPageState({ ...pageState, uploadFilesOpen: !pageState.uploadFilesOpen })
        setSelectState({ ...selectState, folder: !pageState.uploadFilesOpen ? 'none' : selectState.folder })
    }

    const handleToggleMobileFilterSection = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setMobileFilterSectionOpen(!mobileFilterSectionOpen)
    }


    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const { name } = event.target
        setSelectState({ ...selectState, [name]: event.target.value as string })
    }

    const handleClearFilters = () => {
        setSelectState({ ...selectState, origin: 'none', folder: 'none' })
        setMobileFilterSectionOpen(false)
        tryListFiles(true)
    }

    useEffect(() => {
        if (!!deleteFileInfo.wasConfirmed) {
            tryListFiles(true)
            dispatch(slices.deleteFile.setDeleteFileInfo({ ...deleteFileInfo, wasConfirmed: false }))
            return
        }

        if (!!!pageState.uploadFilesOpen) {
            tryListFiles()
        }
    }, [pageState.uploadFilesOpen, deleteFileInfo.lastDelete])

    return <>
        {!!!pageState.uploadFilesOpen && (<GeneralComponents.InternalPages
            title='Transferência de arquivos'
            description='Visualize, armazene e baixe arquivos'

        >
            <Box

                display='flex'
                justifyContent='space-between'
                flexDirection={!!!isDesktopWidth ? 'row-reverse' : 'row'}
                alignItems={'flex-end'}
            >
                {!!isDesktopWidth ?
                    <Box
                        className='file-transfer-filter-container'
                        display='flex'
                        gap='16px'
                        alignItems='flex-end'
                    >
                        <GeneralComponents.CustomSelect
                            variant="outlined"
                            LabelText='Selecione a pasta'
                            name='folder'
                            value={selectState?.folder}
                            onChange={handleSelectChange}
                            sx={{ width: '100%' }}
                            fullWidth
                        >
                            <GeneralComponents.CustomMenuItem value='none'>Todas</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='dataplatform'>Plataforma de dados</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='bigid'>BigID</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='bigweb'>BigWeb</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='blabs'>B-LABS</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='parceria'>Parceria</GeneralComponents.CustomMenuItem>
                        </GeneralComponents.CustomSelect>
                        <GeneralComponents.CustomSelect
                            variant="outlined"
                            LabelText='Tipo de arquivo'
                            name='origin'
                            value={selectState?.origin}
                            onChange={handleSelectChange}
                            sx={{ width: '100%' }}
                            fullWidth
                        >
                            <GeneralComponents.CustomMenuItem value='none'>Todos</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='out'>Entrada</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='in'>Saída</GeneralComponents.CustomMenuItem>
                        </GeneralComponents.CustomSelect>
                        <Box
                            display='flex'
                            gap='8px'
                            alignItems='center'
                            height='55px'
                        >


                            <GeneralComponents.FilterButton onClick={handleRequestFilters}>Filtrar</GeneralComponents.FilterButton>
                            <GeneralComponents.ClearFilterButton onClick={handleClearFilters}>Limpar filtros</GeneralComponents.ClearFilterButton>


                        </Box>
                    </Box>
                    :
                    <>
                        <GeneralComponents.CustomDefaultTextButton
                            className='file-transfer-filter-container'
                            startIcon={<BsFilter color='#0068FF' size={20} />}
                            onClick={handleToggleMobileFilterSection}
                        >
                            Filtros
                        </GeneralComponents.CustomDefaultTextButton>
                        <MobileFilterSection
                            open={mobileFilterSectionOpen}
                            closeAction={handleToggleMobileFilterSection}
                            selectState={selectState}
                            setSelectState={setSelectState}
                            clearFilters={handleClearFilters}
                            requestFilters={handleRequestFilters}
                        />
                    </>
                }
                <GeneralComponents.CustomDefaultButton
                    className='file-transfer-upload-button'
                    startIcon={<BsPlus color='#FFF' size={20} />}
                    onClick={handleToggleUploadFiles}
                >
                    Subir arquivo
                </GeneralComponents.CustomDefaultButton>
            </Box>
            <Paper
                className='file-transfer-table-container'
                sx={{
                    minHeight: '500px',
                    maxHeight: '600px',
                    padding: '16px 8px',
                    borderRadius: '16px'
                }}
            >
                <GeneralComponents.StyledDataGridWithoutPagination
                    rows={data ?? []}
                    columns={fileTransferGridColumns}
                    loading={pageState.isLoading || deleteFileInfo.wasConfirmed}
                    slots={datagridSlots}
                />
            </Paper>
            <FileTransferOnboarding />
        </GeneralComponents.InternalPages>)}
        <FileTransferDetails />
        <UploadFiles open={pageState.uploadFilesOpen} handleClose={handleToggleUploadFiles} />
    </>;
}

export default FileTransfer;
