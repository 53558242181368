import './productTag.css'

const ProductTag = ({ params }: any) => {

    const getProductName = () => {
        switch (params) {
            case 'BigId':
                return <span id='BigId-tag'>BigID</span>
            case 'BigMarket':
                return <span id='BigMarket-tag'>Big Market</span>
            case 'BigBoost':
                return <span id='BigBoost-tag'>Plataforma</span>
        }
    }

    return <div id='product-tag-container'>{getProductName()}</div>;
}

export default ProductTag;