import api from "./interceptors/headerSession";
import APIS from "./apis";
import { AxiosResponse } from "axios";

const API = APIS.find(API => API.NAME === "BIGID");

type ENVIROMENT_TYPES = "HOMOLOGATION" | "PRODUCTION" | "DEVELOPMENT" | string

const ENVIROMENT: ENVIROMENT_TYPES = process.env.REACT_APP_ENVIROMENT || "PRODUCTION";

const BASE_URL = API?.ENVIROMENTS[ENVIROMENT];

const getSimilarity = async (data: any): Promise<AxiosResponse> => api.post(`${BASE_URL}/biometrias/facematch`, data);

const verifyDocument = async (data: any): Promise<AxiosResponse> => api.post(`https://bigid.bigdatacorp.com.br/VerifyID`, data);

export {
    getSimilarity,
    verifyDocument
}