import { useFetch } from '@hooks';
import { Plugins } from '@services';
import { getOnlyDataBase64, showToastCustomErrorMessage } from '@utils';
import React from 'react';
import View from './components/View';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import { CircularProgress } from '@mui/material';

interface ImageQualityProps {
    index?: any,
    type?: any,
    value?: any,
}

const ImageQuality: React.FC<ImageQualityProps> = ({
    index,
    type,
    value,
}) => {

    const { qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfFacematch)
    const file = type === "file" ? getOnlyDataBase64(value?.base64) : value?.link

    const dispatch = useDispatch()

    const { data, isLoading, error, isFetched } = useFetch(Plugins.GetImageQuality, {
        image: file
    }
    )

    React.useEffect(() => {
        const isAvailable = !!!isLoading && !!isFetched
        
        dispatch(slices.exportPdfFacematch.setQualityImage({
            ...qualityImage,
            [index]: {
                isLoading,
                isAvailable,
                data: data || error
            }
        }))

    }, [data, error, isLoading])

    if (!!!data && !!isLoading)
        return <CircularProgress color='primary' thickness={4} />

    return <View data={data} error={error}/>;
}

export default ImageQuality;