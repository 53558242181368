import { Theme, styled } from "@mui/material/styles";
import { NavLink, NavLinkProps } from "react-router-dom";

interface LinkProps extends NavLinkProps {
    theme?: Theme,
    disabled: boolean;
}

const Link = styled(NavLink)(({ theme, disabled }: LinkProps) => {

    const disabledStyle = !!disabled ? {
        cursor: 'not-allowed',
        opacity: 0.5
    }
        :
        ""

    return ({

        display: 'flex',
        gap: theme?.spacing(2),
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: '14px',
        fontFamily: "Be Vietnam",
        fontWeight: 600,
        color: theme?.palette.secondary.main,
        padding: theme?.spacing(2.5),
        width: '100%',
        cursor: 'pointer',
        transition: 'color .5s ease',
        '&:hover': {
            backgroundColor: '#d3d6f250',
            borderRadius: '4px',
        },
        '&.active':{
            color: theme?.palette.primary.main,
        },
        ...disabledStyle
    })
})

export {
    Link
}