import { Box } from "@mui/material";
import { Theme, styled } from "@mui/material/styles"

const Container = styled(Box)(({theme, breakpoint}: {theme?: Theme, breakpoint: boolean}) => ({
    display: 'flex',
    gap: theme?.spacing(8),
    width: !!!breakpoint ? '40%' : '100%',
    flexDirection: !!!breakpoint ? 'row' : 'column'
}))

export default Container