import { styled } from '@mui/system';
import { Button } from '@mui/material';

const CancelButton = styled(Button)`
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #FF003C;
    text-transform: none;
    display: flex;
    align-items: center;

    :hover{
        background: transparent;
    }
`;

export default CancelButton;