import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { slices } from "@store";

import { styled } from '@mui/material';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { MdOutlineLogout } from 'react-icons/md';
import { isDesktopWidth } from '@constants'
import { GeneralComponents } from '@components';


const StyledLogoutButton = styled(Button)`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    gap: 8px;
    background-color: transparent;
    font-family: 'Picadilly';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: #FFF;
    text-transform: none;
    width: 56px;
    height: 30px;

    .MuiButton-endIcon{
        position: absolute;
        right: 16px;
    }
`;


const LogoutButton = () => {
    const dispatch = useDispatch()
    const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false)
    const [logoutDialogHandlers, setLogoutDialogHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });

    const handleButtonClick = async () => {
        setShowLogoutDialog(true)
        const logoutConfirmed = await getLogoutDialogResponse()
        if (logoutConfirmed) {
            dispatch(slices.user.setUserInitialState())
            return
        }
    }

    const getLogoutDialogResponse = async () => {
        return new Promise((resolve) => {

            const handleConfirm = () => {
                resolve(true);
                setShowLogoutDialog(false)

            };

            const handleCancel = () => {
                resolve(false);
                setShowLogoutDialog(false);
            };

            setLogoutDialogHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });
        });
    };

    const { confirmHandler, cancelHandler } = logoutDialogHandlers;

    return (
        <div>
            {isDesktopWidth ? <StyledLogoutButton variant='text' className='logout-button' onClick={handleButtonClick} endIcon={<MdOutlineLogout color='#FFF' size={20} />}>
                Sair
            </StyledLogoutButton> :
                <Box display='flex'
                    flexDirection='column'
                    alignItems='center'
                    justifyContent='center'
                    gap={'2px'}
                >
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={handleButtonClick}><MdOutlineLogout color='#FFF' size={24} /></IconButton>
                    <Typography
                        variant='caption'
                        margin={0}
                        padding={0}
                        color={'#fff'}
                        fontSize={10}
                    >Sair</Typography>
                    <GeneralComponents.ConfirmationDialog
                        open={showLogoutDialog}
                        onConfirm={confirmHandler}
                        onLeave={cancelHandler}
                        title={'Você está prestes a sair do Center'}
                        content={'Tem certeza de que deseja deslogar?'}
                        confirmText={'Sim, sair'}
                        cancelText={'Cancelar'}
                    />
                </Box>
            }

        </div>
    )
}

export default LogoutButton