import React from "react";

import { Box } from "@mui/material";
import { Button } from "@components";
import { BiDownload } from "react-icons/bi";

import { useReactToPrint } from "react-to-print";

interface ComponentProps {
    printRef: any
}

const Component: React.FC<ComponentProps> = ({ printRef }) => {



    const [isPrinting, setIsPrinting] = React.useState(false);

    const promiseResolveRef = React.useRef<any>(null);

    React.useEffect(() => {

        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onBeforeGetContent: () => {
            return new Promise((resolve) => {
                promiseResolveRef.current = resolve;
                setIsPrinting(true);
            });
        },
        onAfterPrint: () => {
            promiseResolveRef.current = null;
            setIsPrinting(false);
        },
        bodyClass: 'print'
    });

    return (
        <Button
            color="primary"
            size="small"
            StartIcon={<BiDownload size={24} />}
            onClick={handlePrint}
        >
            Exportar PDF
        </Button>
    )
}

export default Component;