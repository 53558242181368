import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ConsumptionDetailsInfo {
    open: boolean;
    params: any;
}


const initialState: ConsumptionDetailsInfo = {
   open: false,
   params: undefined
}

const consumptionDetailsSlice = createSlice({
  name: 'consumptionDetails',
  initialState: initialState,
  reducers: {
    setShowConsumptionDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowConsumptionDetailsSection, setParams } = consumptionDetailsSlice.actions;

export default consumptionDetailsSlice.reducer;