import { useDispatch } from 'react-redux';

import { transformInstallmentsToValidRows } from '@utils';
import { slices } from "@store";
import { GeneralComponents } from "@components";


const InstallmentsButton = ({ params }: any) => {

    const dispatch = useDispatch()

    const handleClick = () => {
        const installmentsList = transformInstallmentsToValidRows(params.row ? params.row : params ?? undefined)
        dispatch(slices.bankSlips.setInstallmentsInfo({ installmentsList, showInstallmentsSection: true }))
    }

    return <>
        <GeneralComponents.CustomDefaultButton variant='contained' onClick={handleClick}>Ver boletos bancários</GeneralComponents.CustomDefaultButton>
    </>;
}

export default InstallmentsButton;