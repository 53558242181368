import { Box, Button, IconButton, Typography } from "@mui/material";
import { styled } from '@mui/system';
import { isDesktopWidth } from '@constants'

interface RedoOnboardingButtonProps {
    handleRedoOnboarding: (event: React.MouseEvent<HTMLButtonElement>) => void
}


const CustomRedoOnboardingButton = styled(Button)`
    && {
        display: flex;
        align-items: center;
        padding: 0px;
        gap: 8px;
        height: 30px;
        font-family: 'Be vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 30px;
        color: #006;
        text-transform: none;

        :hover{
            background: transparent;
        }
    }
`

const RedoOnboardingButton = ({ handleRedoOnboarding }: RedoOnboardingButtonProps) => isDesktopWidth ?
    <CustomRedoOnboardingButton id="redo-onboarding-button-dash" variant='text' onClick={handleRedoOnboarding}>Fazer tour</CustomRedoOnboardingButton>
    :
    <Box display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        gap={'2px'}
    >
        <IconButton
        sx={{padding: 0}}
        onClick={handleRedoOnboarding}>
            <img src="https://bdcenter-files.bigdatacorp.com.br/Imagens/redoOnboarding.svg" id="redo-onboarding-button-dash" className="redo-onboarding-button redo-onboarding-image" alt="Re-do onboarding simbol. Contains a icon that represents a cicle." />
        </IconButton>
        <Typography
            variant='caption'
            margin={0}
            padding={0}
            color={'#fff'}
            fontSize={10}
        >Tour</Typography>
    </Box>




export default RedoOnboardingButton