import React, { useEffect, useMemo, useState } from 'react';
import { GeneralComponents, Tooltip, Onboarding } from '@components';

import { CustomToggleButton, CustomToggleButtonGroup, Label, PaperContainer } from './styles';
import { Box, useMediaQuery } from '@mui/material';
import { BsFillBuildingsFill, BsFillPersonLinesFill } from 'react-icons/bs';
import dayjs, { Dayjs } from 'dayjs';
import { cnpjMask, cpfMask, dateMask, getDaysUntilNextMonday, getDaysUntilSecondMonday, handleAxiosError, removeMasks, showToastCustomErrorMessage, showToastCustomWarningMessage } from '@utils';
import { apiDocUpdateGuard } from '@services';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import { steps } from '@constants';

const thursdayNumber = 4
const cpfLenghtLimit = 14
const cnpjLenghtLimit = 18
const dateLenghtLimit = 10

const DocUpdate: React.FC = () => {

  const { sessionId } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)

  const narrowView = useMediaQuery('(max-width: 860px)');

  const [activeCategory, setActiveCategory] = useState<'PF' | 'PJ'>('PF')
  const [isAfterThursdayMorning, setIsAfterThursdayMorning] = useState<boolean>(false)
  const [currentDatetime, setCurrentDatetime] = useState<Dayjs>(dayjs().locale('pt-br'))

  const [responseData, setResponseData] = useState<any>({ PF: undefined, PJ: undefined })
  const [isLoading, setIsLoading] = useState({ PF: false, PJ: false })
  const [hasError, setHasError] = useState({ PF: { value: false, codes: [] as any }, PJ: { value: false, codes: [] as any } })
  const [fieldValues, setFieldValues] = useState({ cpf: '', cnpj: '', birthdate: '' })

  const dispatch = useDispatch()

  const daysUntilNextMonday = getDaysUntilNextMonday(currentDatetime)
  const daysUntilSecondMonday = getDaysUntilSecondMonday(daysUntilNextMonday)

  const selectedDataset = useMemo(() => activeCategory === 'PF' ? 'ondemand_rf_status_person' : 'ondemand_rf_status_company', [activeCategory])

  const requestRfUpdate = async () => await apiDocUpdateGuard.post('/updates/updatedoc',
    {
      sessionId,
      Datasets: selectedDataset,
      q: `doc{${activeCategory === 'PF' ? removeMasks(fieldValues.cpf) : removeMasks(fieldValues.cnpj)}}`,
      birthdate: !!fieldValues.birthdate ? dayjs(fieldValues.birthdate, "DD/MM/YYYY").format('YYYY-MM-DD') : '',
      dateformat: !!fieldValues.birthdate ? "yyyy-MM-dd" : ""
    }

  )

  const tryRequestUpdate = async () => {
    try {
      const requestTimestamp = dayjs().locale('pt-br')
      setIsLoading({ ...isLoading, [activeCategory]: true })

      if (isDocInputFilled()) {
        const { data: updateResponse } = await requestRfUpdate()
        setCurrentDatetime(requestTimestamp)
        handleUpdateResponse(updateResponse)
        return
      }

      showToastCustomErrorMessage('Falha na solicitação', 'O campo de documento é obrigatório.')
      setIsLoading({ ...isLoading, [activeCategory]: false })

    } catch (error: any) {
      handleErrors(error)
    }
  }

  const handleUpdateResponse = (updateResponse: any) => {
    setResponseData({ ...responseData, [activeCategory]: updateResponse })
    const negativeResponses = findAnyNegativeResponseCode(updateResponse)

    if (negativeResponses.length > 0) {
      setHasError({ ...hasError, [activeCategory]: { value: true, codes: negativeResponses } })
      setIsLoading({ ...isLoading, [activeCategory]: false })
      return
    }
    setHasError({ ...hasError, [activeCategory]: { value: false, codes: [] } })
    setIsLoading({ ...isLoading, [activeCategory]: false })
  }

  const handleErrors = (error: any) => {


    if (error?.response?.data?.status === -1) {
      showToastCustomErrorMessage('Sessão expirada.', 'Sua sessão expirou. Por favor, faça o login novamente.')
      dispatch(slices.user.setUserInitialState())
      return
    }

    if (error?.response?.data?.status === -2) {
      showToastCustomErrorMessage('Erro na atualização', 'O campo de documento é obrigatório')
      return
    }

    if (error?.response?.data?.status === -3) {
      showToastCustomErrorMessage('Erro na atualização', error?.message)
      return
    }

    if (error?.response?.status) {
      setHasError({ ...hasError, [activeCategory]: { value: true, codes: [{ Code: error?.response?.status, Message: error?.code }] } })
      setIsLoading({ ...isLoading, [activeCategory]: false })
      handleAxiosError(error)
      return
    }

    handleAxiosError(error)
  }


  const findAnyNegativeResponseCode = (updateResponse: any) => Object.values(updateResponse?.Status).flat().filter((response: any) => {

    if (response?.Message !== 'OK') {

      if (response?.Code === -111) {
        showToastCustomErrorMessage('Sessão expirada.', 'Sua sessão expirou. Por favor, faça o login novamente.')
        dispatch(slices.user.setUserInitialState())
      }

      if (response?.Code === -123) {
        showToastCustomWarningMessage('Data de nascimento necessária.', 'Sua consulta necessita de uma data de nascimento para continuarmos.')
      }

      if (response?.Code === -1200) {
        showToastCustomErrorMessage('Erro na atualização.', 'Ocorreu um erro inesperado. Por favor, contate o suporte.')
      }

      return response
    }
    return
  }
  )

  const isDocInputFilled = () => {
    return fieldValues.cpf.length > 0 || fieldValues.cnpj.length > 0
  }

  const handleDocumentValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target

    if (name === 'cpf') {
      setFieldValues({ ...fieldValues, [name]: cpfMask(value) })
      return
    }

    if (name === 'cnpj') {
      setFieldValues({ ...fieldValues, [name]: cnpjMask(value) })
      return
    }

    setFieldValues({ ...fieldValues, [name]: dateMask(value) })
    return
  }

  const handleActiveCategory = (
    event: React.MouseEvent<HTMLElement>,
    newActiveCategory: 'PF' | 'PJ',
  ) => {
    if (newActiveCategory === null) {
      setActiveCategory(event.currentTarget.id as 'PF' | 'PJ')
    } else {
      setActiveCategory(newActiveCategory)
    }
  };

  const checkIfIsAfterThursdayMorning = () => {
    if (
      (
        currentDatetime.day() === thursdayNumber
        &&
        currentDatetime.hour() >= 12
      )
      ||
      currentDatetime.day() >= thursdayNumber
    ) {
      setIsAfterThursdayMorning(true)
      return
    }
    return
  }


  const PFData = useMemo(() => !!responseData.PF || !!hasError?.[activeCategory].value ? [
    {
      label: 'Documento consultado',
      content: !!responseData.PF?.Result ? `CPF: ${cpfMask(responseData?.PF?.Result[0]?.MatchKeys)}` : 'CPF',
    },
    {
      label: 'Status',
      content: hasError.PF.value ? 'Falha na atualização' : 'OK',
      variant: hasError.PF.value ? 'negative' : 'positive'
    },
    {
      label: 'Estimativa de atualização',
      content: hasError.PF.value ? 'N/A' : currentDatetime
        .add(isAfterThursdayMorning ? daysUntilSecondMonday : daysUntilNextMonday, 'day')
        .format('DD/MM/YYYY'),
    },
    {
      label: 'Erros retornados',
      content: hasError.PF.value ? hasError.PF.codes.map((item: any) => `${item.Code}: ${item.Message}`) : 'N/A',
      variant: hasError.PF.value ? 'negative' : 'neutral'
    }
  ]
    :
    [], [responseData.PF, hasError.PF.value])

  const PJdata = useMemo(() => !!responseData.PJ || !!hasError?.[activeCategory].value ? [
    {
      label: 'Documento consultado',
      content: !!responseData.PJ?.Result ? `CNPJ: ${cnpjMask(responseData?.PJ?.Result[0]?.MatchKeys)}` : 'CNPJ',
    },
    {
      label: 'Status',
      content: hasError.PJ.value ? 'Falha na atualização' : 'OK',
      variant: hasError.PJ.value ? 'negative' : 'positive'
    },
    {
      label: 'Estimativa de atualização',
      content: hasError.PJ.value ? 'N/A' : currentDatetime
        .add(isAfterThursdayMorning ? daysUntilSecondMonday : daysUntilNextMonday, 'day')
        .format('DD/MM/YYYY'),
    },
    {
      label: 'Erros retornados',
      content: hasError.PJ.value ? hasError.PJ.codes.map((item: any) => `${item.Code}: ${item.Message}`) : 'N/A',
      variant: hasError.PJ.value ? 'negative' : 'neutral'
    }
  ]
    :
    [], [responseData.PJ, hasError.PJ.value])

  useEffect(() => {
    checkIfIsAfterThursdayMorning()
  }, [])

  return <>
    <GeneralComponents.InternalPages
      title='Atualização de status'
      description='Atualize os dados de uma pessoa física ou jurídica com base na Receita Federal'
    >
      <PaperContainer
        className='doc-update-container'
      >

        <CustomToggleButtonGroup
          value={activeCategory}
          exclusive
          unselectable='off'
          onChange={handleActiveCategory}
          className='toggle-button-group'
          fullWidth
        >
          <CustomToggleButton
            value="PF"
            id="PF"
            aria-label="Pessoa física"
            selected={activeCategory === 'PF'}
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <BsFillPersonLinesFill size={20} />
            Pessoa física
          </CustomToggleButton>
          <CustomToggleButton
            value="PJ"
            id="PJ"
            selected={activeCategory === 'PJ'}
            aria-label="Pessoa jurídiaca"
            disableFocusRipple
            disableTouchRipple
            disableRipple
          >
            <BsFillBuildingsFill size={20} />
            Pessoa jurídica
          </CustomToggleButton>
        </CustomToggleButtonGroup>

        <Box
          width={'100%'}
          display={'flex'}
          flexDirection={!narrowView ? 'row' : 'column'}
          gap={!narrowView ? '2%' : '24px'}
        >
          <Box width={!narrowView ? '49%' : '100%'}
            display={'flex'}
            flexDirection={'column'}
            gap={'24px'}
            alignItems={'flex-end'}
          >
            <Box
              display={'flex'}
              flexDirection={!narrowView ? 'row' : 'column'}
              gap={'8px'}
              width={'100%'}
              component={'div'}
              className='doc-update-content'

            >
              {activeCategory === 'PF' && (
                <>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={!narrowView ? '50%' : '100%'}
                    gap={'4px'}
                  >
                    <Label>
                      CPF
                      <span style={{ color: '#FF003C' }}> *</span>
                    </Label>
                    <GeneralComponents.CustomTextField
                      inputProps={{ maxLength: cpfLenghtLimit }}
                      value={fieldValues.cpf}
                      placeholder={'000.000.000-00'}
                      fullWidth
                      onChange={handleDocumentValueChange}
                      name={'cpf'}
                    />
                  </Box>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={!narrowView ? '50%' : '100%'}
                    gap={'4px'}
                  >
                    <Box
                      display={'flex'}
                      gap={'4px'}
                      alignItems={'center'}
                    >
                      <Label>Data de nascimento (opcional)</Label>
                      <Tooltip
                        description='Em alguns casos será necessário informar a data de nascimento vinculada ao documento inserido. O preenchimento desse campo é recomendado para evitar transtornos.'
                        placement={narrowView ? 'top' : 'right'}
                      ></Tooltip>
                    </Box>
                    <GeneralComponents.CustomTextField
                      inputProps={{ maxLength: dateLenghtLimit }}
                      value={fieldValues.birthdate}
                      placeholder={'DD/MM/AAAA'}
                      fullWidth
                      onChange={handleDocumentValueChange}
                      name={'birthdate'}
                    />
                  </Box>
                </>
              )}


              {activeCategory === 'PJ' && (

                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  width={'100%'}
                  gap={'4px'}
                >
                  <Label>
                    CNPJ
                    <span style={{ color: '#FF003C' }}> *</span>
                  </Label>
                  <GeneralComponents.CustomTextField
                    inputProps={{ maxLength: cnpjLenghtLimit }}
                    value={fieldValues.cnpj}
                    placeholder={'00.000.000/0000-00'}
                    fullWidth
                    onChange={handleDocumentValueChange}
                    name={'cnpj'}
                  />
                </Box>


              )}
            </Box>
            <GeneralComponents.CustomDefaultButton onClick={tryRequestUpdate}>Solicitar atualização</GeneralComponents.CustomDefaultButton>
          </Box>
          <Box width={!narrowView ? '49%' : '100%'} component={'div'} className='doc-update-info'>
            <GeneralComponents.InfoNote
              noteTitle='Importante!'
              noteDescription={
                `Sua atualização será realizada no dataset on-demand da Receita Federal. A resposta deste dataset depende da disponibilidade da fonte consultada no momento da solicitação. A data estimada para a conclusão do processo, caso a consulta seja efetuada com sucesso, depende da data e hora em que foi realizada a consulta. Para solicitações realizadas antes de quinta-feira às 12 horas, a data estimada é dia ${currentDatetime.add(daysUntilNextMonday, 'day').format('DD/MM/YYYY')}. Para as demais solicitações, a data estimada é dia ${currentDatetime.add(daysUntilSecondMonday, 'day').format('DD/MM/YYYY')}.`
              }
            />

          </Box>
        </Box>

        {
          activeCategory === 'PF' && (

            <GeneralComponents.ResultBox
              data={PFData}
              mainLineContent={responseData?.PF?.QueryId ?? 'N/A'}
              isLoading={isLoading.PF}
            />
          )
        }

        {
          activeCategory === 'PJ' && (

            <GeneralComponents.ResultBox
              data={PJdata}
              mainLineContent={responseData?.PJ?.QueryId ?? 'N/A'}
              isLoading={isLoading.PJ}
            />
          )
        }
      </PaperContainer>
      <Onboarding
        steps={steps.DocUpdate}
        controlPath='DocUpdate'
      />
    </GeneralComponents.InternalPages>
  </>;
}

export default DocUpdate;
