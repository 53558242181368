import {GridColDef} from "@mui/x-data-grid";
import { isDesktopWidth } from "./screenWidth";
import { BillingComponents } from "@components";

const getPartialDetailColumns: GridColDef[] = isDesktopWidth ? [
    { field: 'Scope', headerName: 'Escopo', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'Dataset', headerName:'Dataset', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'FreeRequests', headerName: 'Requisições gratuitas', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'PaidRequests', headerName: 'Requisições pagas', flex: 12.5,valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR'), headerAlign:'left', align: 'left'},
    { field: 'TotalRequests', headerName: 'Total de requisições', flex: 12.5,valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR'), headerAlign:'left', align: 'left'},
    { field: 'ListPrice', headerName: 'Valor sem desconto', flex: 12.5,valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}), headerAlign:'left', align: 'left'},
    { field: 'VolumeDiscount', headerName: 'Descontos', flex: 12.5,valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}), headerAlign:'left', align: 'left'},
    { field: 'Price', headerName: 'Valor', flex: 12.5,valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}),  headerAlign:'left', align: 'left'},
]
:
[
    { field: 'Scope', headerName: 'Escopo', width: 145, headerAlign:'left', align: 'left'},
    { field: 'Dataset', headerName:'Dataset', width: 145, headerAlign:'left', align: 'left'},
    { 
        field: 'partialDetails',
        type: 'actions',
        headerName: 'Detalhes',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => (<BillingComponents.CurrentUsageSummaryDetailsCell params={params}/>),
        width: 80
    }
]


export default getPartialDetailColumns