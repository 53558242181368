import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { MdEdit } from 'react-icons/md';

import { reducers, slices } from '@store';

import { Button, GeneralComponents, SideSection} from "@components";

import ChangePassword from './ChangePassword';
import EditUser from './EditUser';

const UserProfile: React.FC = () => {

    const sideSectionOpen = useSelector((state: reducers.RootState) => state.sideSection.open)
    const {login, name, email, group, isSystemAdmin, isEnabled,
        parameters: { TwoFactorAuthentication, FileTransfer }
    } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)

    const [showEditUser, setShowEditUser] = useState<boolean>(false)
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false)

    const dispatch = useDispatch()

    const userInfo = useMemo(() => [
        {
            label: "Login: ",
            value: login
        },
        {
            label: "Nome: ",
            value: name
        },
        {
            label: "E-mail: ",
            value: email
        },
        {
            label: "Domínio: ",
            value: group
        },
    ], [login, name, email, group])

    const userConditions = useMemo(() => [
        {
            label: "Perfil de acesso: ",
            value: isSystemAdmin ? 'Administrador(a)' : "Colaborador"
        },
        {
            label: "Autenticação em dois fatores: ",
            value: <GeneralComponents.StatusTag value={TwoFactorAuthentication} />
        },
        {
            label: "Status: ",
            value: <GeneralComponents.StatusTag value={isEnabled} />
        },
        // {
        //     label: "Transferência de arquivos: ",
        //     value: <GeneralComponents.StatusTag value={!!FileTransfer ? FileTransfer : 'False'} />
        // },
    ], [isSystemAdmin, TwoFactorAuthentication, isEnabled])

    const handleActionButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        const { name } = event.currentTarget

        if (name === 'editUser') {
            setShowEditUser(true)
            return
        }

        setShowEditUser(false)
        setShowChangePassword(true)
        return
    }

    const ActionButton = useMemo(() => {

        if (!!isSystemAdmin && !!!showEditUser) {
            return <Button
                name='editUser'
                size='small'
                StartIcon={<MdEdit color='white' size={18} />}
                onClick={handleActionButtonClick}
            >
                Editar usuário
            </Button>
        }

        if (!!!showChangePassword) {
            return <Button
                name='changePassword'
                size='small'
                StartIcon={<MdEdit color='white' size={18} />}
                onClick={handleActionButtonClick}
            >
                Alterar senha
            </Button>
        }

        return undefined

    }, [isSystemAdmin, showEditUser])

    useEffect(() => {
        if (!!!sideSectionOpen) {
            setShowEditUser(false)
            setShowChangePassword(false)
            dispatch(slices.userProfile.setOutsideClickOccurred(false))
        }
    }, [sideSectionOpen])

    const handleCloseUserProfile = () => {
        dispatch(slices.sideSection.setOpen(false));
    }

    return <SideSection.Container
        open={sideSectionOpen}
        onClose={handleCloseUserProfile}
        title={"Perfil do usuário"}
        ActionButton={!!!showChangePassword && (ActionButton)}
        hideCloseButton={(!!showChangePassword || !!showEditUser) && !!ActionButton}
    >
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            gap={5}
            pt={6}
        >
            {
                !!!showChangePassword && !!!showEditUser && (
                    <>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            p={0}
                            gap={6}
                        >
                            <Typography variant='h3'>Dados</Typography>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                            >
                                {userInfo.map((field) => <SideSection.Field label={field?.label} value={field?.value} />)}
                            </Box>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            padding={0}
                            gap={6}
                        >

                            <Typography variant='h3'>Condições</Typography>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                gap={2}
                            >
                                {userConditions.map((field) => <SideSection.Field label={field?.label} value={field?.value} />)}
                            </Box>
                        </Box>
                    </>
                )
            }
            {!!showEditUser && (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'flex-start'}
                    gap={6}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'flex-start'}
                        gap={2}
                    >
                        <Typography variant='h3'>Dados</Typography>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                        >
                            <SideSection.Field label={"Login: "} value={login} />
                        </Box>
                    </Box>
                    <EditUser />
                </Box>)}
            {!!showChangePassword && (<ChangePassword />)}
        </Box>
    </SideSection.Container>;
}

export default UserProfile;