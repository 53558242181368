const cnpjMask = (value: string) => {
    return value
        .replace(/\D+/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1')
}

const cpfMask = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d{1,2})/, '$1-$2')
}

const removeMasks = (value: string) => {
    return value.replace(/[./-]/g, '')
}

const dateMask = (value: string) => {

    return value.replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1/$2")
        .replace(/(\d{2})(\d)/, "$1/$2")

}

const getDaysUntilNextMonday = (currentDateTime: any) => {
    const daysUntilNextMonday = (8 - currentDateTime.day()) % 7;
    const oneWeek = 7

    if(daysUntilNextMonday === 0){
        return oneWeek
    }

    return ((8 - currentDateTime.day()) % 7)
}

const getDaysUntilSecondMonday = (daysUntilNextMonday: any) => {
    return daysUntilNextMonday + 7;
}

export { cnpjMask, cpfMask, removeMasks, dateMask, getDaysUntilNextMonday, getDaysUntilSecondMonday}
