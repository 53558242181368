import React from "react";

import { Box, Tooltip } from "@mui/material";
import { Progress } from "@components";
import { Title } from "./styles"
import { BiHelpCircle } from "react-icons/bi";

type ComponentProps = {
  percentage: number,
  title: string,
  description: string
}

const Component: React.FC<ComponentProps> = ({ percentage, title, description }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" gap={4}>
      <Box display="flex" gap={2}>
        <Title>{title}</Title>
        <Tooltip title={description}>
          <Box>
            <BiHelpCircle size={20} />
          </Box>
        </Tooltip>
      </Box>
      <Box>
        <Progress value={percentage} thickness={8} size={75}/>
      </Box>
    </Box>
  )
}

export default Component;