const apis = {
  BILLINGJEAN: {
    DEVELOPMENT: "https://billingjean-hml.bigdatacorp.com.br/api",
    HML: "https://billingjean-hml.bigdatacorp.com.br/api",
    PRODUCTION: "https://billingjean-prd.bigdatacorp.com.br/api"
  },
  BIGBROTHER: {
    DEVELOPMENT: "https://bigbrother-hml.bigdatacorp.com.br/api",
    HML: "https://bigbrother-hml.bigdatacorp.com.br/api",
    PRODUCTION: "https://bigbrother.bigdatacorp.com.br/api"
  },
  FILETRANSFER: {
    DEVELOPMENT: "https://filetransfer.bigdatacorp.info",
    HML: "https://filetransfer.bigdatacorp.info",
    PRODUCTION: "https://filetransfer.bigdatacorp.com.br"
  },
  PLATAFORMA: {
    DEVELOPMENT: "https://plataforma-dev.bigdatacorp.com.br",
    HML: "https://plataforma-dev.bigdatacorp.com.br",
    PRODUCTION: "https://plataforma.bigdatacorp.com.br"
  },
  BIGID: {
    DEVELOPMENT: "https://app-dev.bigdatacorp.com.br/bigid",
    HML: "https://app-dev.bigdatacorp.com.br/bigid",
    PRODUCTION: "https://app.bigdatacorp.com.br/bigid"
  },
  PLUGIN: {
    DEVELOPMENT: "https://plugin-dev.bigdatacorp.com.br/api",
    HML: "https://plugin-dev.bigdatacorp.com.br/api",
    PRODUCTION: "https://plugin.bigdatacorp.com.br/api"
  }
}

export default apis;