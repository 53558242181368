import { CircularProgress, Modal } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import { Document, Page, pdfjs } from 'react-pdf';
import './pdfviewer.css'
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { CiFileOff } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { reducers } from '@store';
import { GeneralComponents, BillingComponents, ButtonDropdown, Button } from "@components";
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { isDesktopWidth } from '@constants';
import { Billing } from '@services';
import { handleAxiosError, showToastCustomErrorMessage } from '@utils';


pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

interface PDFViewerProps {
  open: boolean;
  setIsPdfModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ open, setIsPdfModalOpen }) => {
  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const pdfBytes = useSelector((state: reducers.RootState) => state.seePdfReducer.pdfBytes)
  const ReferenceMonth = useSelector((state: reducers.RootState) => state.seePdfReducer.referenceMonth)


  const handleDownload = (bytes: string, type: string) => {

    const formattedReferenceMonth = dayjs(ReferenceMonth, 'MMMM [de] YYYY', 'pt-br').format('YYYY_MM')

    if (!!bytes) {
      const url = bytes;
      saveAs(url, `Usage_Summary_Reference_${formattedReferenceMonth}.${type}`,);
    }
  };

  const handleDownloadCsv = async (data: any) => {

    if (!!data.ReportBytes) {
      handleDownload(`data:application/octet-stream;base64,${data.ReportBytes}`, 'csv')
      return
    }

    if (!!data.Message || !!data.ExceptionMessage) {
      showToastCustomErrorMessage('Erro ao exportar csv', `Código do erro: ${data.Message}. Por favor contate o suporte.`)
      return
    }

  }

  const tryGetConsolidatedCsvReport = async () => {
    try {
      const { data: response } = await Billing.getConsolidatedCsvReport({
        ReferenceMonth: dayjs(ReferenceMonth, 'MMMM [de] YYYY', 'pt-br').format('YYYY_MM')
      })
      handleDownloadCsv(response)

    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const handleDownloadPdf = () => {
    handleDownload(pdfBytes, 'pdf')
  }

  const handleCloseModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsPdfModalOpen(false)
  }

  useEffect(() => {
    setPageNumber(1)
  }, [pdfBytes])

  const handleNextPage = () => {
    if (numPages) {
      if (pageNumber < numPages) {
        setPageNumber((prevPage) => prevPage + 1);
      }
    }
  };

  const handlePreviousPage = () => {
    setPageNumber((prevPage) => prevPage - 1);
  };

  const handleZoomIn = () => setScale((prevState) => prevState + 0.1)

  const handleZoomOut = () => setScale((prevState) => prevState - 0.1)

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  const MemoizedDesktopDocument = useMemo(() =>
    <Document className='pdf-document-container'
      loading={
        <div className='loading-div'>
          <CircularProgress color='primary' thickness={4} />
        </div>
      }
      file={pdfBytes ? { url: pdfBytes } : ''}
      noData={
        <div className='pdf-document-no-data'>
          <CiFileOff size={100} color='#999' />
          <span>Nenhum arquivo selecionado. Visualize aqui o seu arquivo .pdf antes de baixá-lo.</span></div>
      }
      onLoadSuccess={onDocumentLoadSuccess}
    >
      <div className='pdf-controls'>
        <BillingComponents.PDFControls onClick={handleZoomOut} disabled={scale === 1}>
          <AiOutlineZoomOut size={24} color='white' />
        </BillingComponents.PDFControls>
        <p>
          Zoom {scale.toFixed(1)}x
        </p>
        <BillingComponents.PDFControls onClick={handleZoomIn}>
          <AiOutlineZoomIn size={24} color='white' />
        </BillingComponents.PDFControls>
        <BillingComponents.PDFControls onClick={handlePreviousPage} disabled={pageNumber === 1}>
          <BsArrowLeftShort size={24} color='white' />
        </BillingComponents.PDFControls>
        <p>
          Página {pageNumber} de {numPages}
        </p>
        <BillingComponents.PDFControls onClick={handleNextPage} disabled={pageNumber === numPages}>
          <BsArrowRightShort size={24} color='white' />
        </BillingComponents.PDFControls>
      </div>
      <div className='pdf-document-info'>
        <span className='pdf-document-referenceMonth'>{ReferenceMonth}</span>
        <ButtonDropdown
          color='secondary'
          openOnClick={true}
          options={[
            {
              label: 'Baixar PDF',
              onClick: handleDownloadPdf
            },
            {
              label: 'Baixar CSV',
              onClick: tryGetConsolidatedCsvReport
            }
          ]}>
          Baixar
        </ButtonDropdown>
      </div>
      <Page
        className='pdf-document-page'
        scale={scale}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        key={pageNumber} pageNumber={pageNumber} />
    </Document>, [pdfBytes, pageNumber, numPages, scale])

  const MemoizedMobileDocument = useMemo(() => <div className='pdf-container'>
    <Document
      className='pdf-document-container'
      loading={
        <div className='loading-div'>
          <CircularProgress color='primary' thickness={4} />
        </div>
      }
      file={pdfBytes ? { url: pdfBytes } : ''}
      noData={
        <div className='pdf-document-no-data'>
          <CiFileOff size={100} color='#999' />
          <span>Nenhum arquivo selecionado. Visualize aqui o seu arquivo .pdf antes de baixá-lo.</span>
        </div>
      }
      onLoadSuccess={onDocumentLoadSuccess}
    >

      <div className='pdf-controls'>
        <BillingComponents.PDFControls onClick={handleZoomOut} disabled={scale === 1}>
          <AiOutlineZoomOut size={20} color='white' />
        </BillingComponents.PDFControls>
        <p>
          Zoom {scale.toFixed(1)}x
        </p>
        <BillingComponents.PDFControls onClick={handleZoomIn}>
          <AiOutlineZoomIn size={20} color='white' />
        </BillingComponents.PDFControls>
        <BillingComponents.PDFControls onClick={handlePreviousPage} disabled={pageNumber === 1}>
          <BsArrowLeftShort size={20} color='white' />
        </BillingComponents.PDFControls>
        <p>
          Página {pageNumber} de {numPages}
        </p>
        <BillingComponents.PDFControls onClick={handleNextPage} disabled={pageNumber === numPages}>
          <BsArrowRightShort size={20} color='white' />
        </BillingComponents.PDFControls>
      </div>
      <div className='pdf-document-info'>
        <div>
          <span className='pdf-document-referenceMonth'>{ReferenceMonth}</span>
          <div className='pdf-document-info-options'>
            <ButtonDropdown
              color='secondary'
              className='pdf-download-button'
              openOnClick={true}
              options={[
                {
                  label: 'Baixar PDF',
                  onClick: handleDownloadPdf
                },
                {
                  label: 'Baixar CSV',
                  onClick: tryGetConsolidatedCsvReport
                }
              ]}

            >
              Baixar
            </ButtonDropdown>
            <GeneralComponents.CancelButton onClick={handleCloseModal}>Fechar</GeneralComponents.CancelButton>
          </div>
        </div>
      </div>
      <Page
        className='pdf-document-page'
        scale={scale}
        renderTextLayer={false}
        renderAnnotationLayer={false}
        key={pageNumber}
        pageNumber={pageNumber}
      />

    </Document>
  </div>, [pdfBytes, pageNumber, numPages, scale])

  return isDesktopWidth ?
    <div className='pdf-container'>
      {MemoizedDesktopDocument}
    </div>
    :

    <Modal open={open}>
      {MemoizedMobileDocument}
    </Modal>

};

export default PDFViewer;