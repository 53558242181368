import { Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

const Component = styled(Tabs)(({ theme }) => ({
  display: "flex",
  borderRadius: '8px',
  padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
  gap: theme.spacing(1),
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.neutral?.[500],
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent',
  },
}))

export default Component