import { ReactNode } from "react";

import { Tr } from "./styles";

type RowProps = {
  children: ReactNode,
  onClick?: () => any,
  sx?: any
}

const Row: React.FC<RowProps> = ({ children, onClick, sx }) => {
  return (
    <Tr onClick={onClick} sx={sx}>
      {children}
    </Tr>
  )
}

export default Row