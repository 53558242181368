import React, { useCallback, useEffect, useState } from 'react';
import { GeneralComponents, Onboarding } from "@components";
import PDFViewer from './PDFViewer';
import { isDesktopWidth, usageSummaryGridColumns, steps } from "@constants";
import { transformConsolidatedReportsToValidRows, showToastCustomErrorMessage, handleAxiosError, showToastCustomInfoMessage } from '@utils';
import { BillingTypes } from '@types';
import { useDispatch, useSelector } from 'react-redux';

import { reducers, slices } from "@store";
import { Billing } from '@services';

import dayjs, { Dayjs } from 'dayjs';


import './usageSummary.css'
import '../financialManagement.css'
import { GridRowParams } from '@mui/x-data-grid';
import UsageSummaryPagination from './UsageSummaryPagination';
import FilterSection from './FilterSection';
import { BsFilter } from 'react-icons/bs';

const datagridSlots = {
    pagination: UsageSummaryPagination,
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}

const UsageSummary: React.FC = () => {

    const paginationInfo = useSelector((state: reducers.RootState) => state.usageSummaryPagination)
    const [consolidatedReportsList, setConsolidatedReportsListData] = useState<BillingTypes.ListConsolidatedReportsApiResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isPdfModalOpen, setIsPdfModalOpen] = useState<boolean>(false)
    const [isMobileFilterSectionOpen, setIsMobileFilterSectionOpen] = useState<boolean>(false)
    const [localUsageSummaryFilterInfo, setLocalUsageSummaryFilterInfo] = useState({
        referenceMonthStart: '',
        referenceMonthEnd: '',
    })

    const dispatch = useDispatch()

    const listConsolidatedReports = async () => await Billing.listConsolidatedReports({
        ...localUsageSummaryFilterInfo,
        Pagination: { Page: paginationInfo.Page === 0 ? 1 : paginationInfo.Page }
    })

    const getConsolidatedReport = async (ReferenceMonth: string) => await Billing.getConsolidatedReport({
        ReferenceMonth
    })

    const isInvalidSession = (billingApiResponse: any) => billingApiResponse.Message === "INVALID_SESSION_ID"

    const tryListConsolidatedReports = async () => {
        try {
            if (areFiltersValid()) {
                const { data: apiBillingManagementApiResponse } = await listConsolidatedReports()
                handleListConsolidatedReportResponse(apiBillingManagementApiResponse)
            }
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleListConsolidatedReportResponse = (billingApiResponse: any) => {

        if (isInvalidSession(billingApiResponse)) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else if (!billingApiResponse.Success && billingApiResponse.Message === 'NO_CONSOLIDATED_REPORTS_FOUND') {
            showToastCustomInfoMessage('Nada encontrado', 'Nenhum relatório foi encontrado. Caso tenha filtrado, verifique as datas.')
        } else if (!billingApiResponse.Success && billingApiResponse.Message === 'INVALID_PARAMETERS') {
            showToastCustomErrorMessage('Parâmetros inválidos', 'Verifique seus filtros. As datas de início e fim precisam ser válidas.')
        }
        else {
            const { Page, Records, TotalPages, TotalRecords } = billingApiResponse.Pagination
            setConsolidatedReportsListData(billingApiResponse)
            dispatch(slices.usageSummaryPagination.setUsageSummaryPaginationInfo({ Page, Records, TotalPages, TotalRecords }))
        }
        setIsLoading(false)
    }

    const handleReferenceMonthStartChange = (referenceMonthStart: Dayjs | null) => {

        if (referenceMonthStart) {
            setLocalUsageSummaryFilterInfo({ ...localUsageSummaryFilterInfo, referenceMonthStart: dayjs(referenceMonthStart).format('YYYY_MM') })
        } else {
            setLocalUsageSummaryFilterInfo({ ...localUsageSummaryFilterInfo, referenceMonthStart: '' })
        }
    }

    const handleReferenceMonthEndChange = (referenceMonthEnd: Dayjs | null) => {


        if (referenceMonthEnd) {
            setLocalUsageSummaryFilterInfo({ ...localUsageSummaryFilterInfo, referenceMonthEnd: dayjs(referenceMonthEnd).format('YYYY_MM') })
        } else {
            setLocalUsageSummaryFilterInfo({ ...localUsageSummaryFilterInfo, referenceMonthEnd: '' })
        }
    }


    const handleFilterRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (!isDesktopWidth) {
            setIsMobileFilterSectionOpen(false)
        }

        dispatch(slices.usageSummaryPagination.setUsageSummaryPaginationInfo({ ...paginationInfo, Page: 0, Records: 12, }))
    }

    const areFiltersValid = () => {
        if (dayjs(localUsageSummaryFilterInfo.referenceMonthStart, 'YYYY_MM', 'pt-br').isAfter(dayjs(localUsageSummaryFilterInfo.referenceMonthEnd, 'YYYY_MM', 'pt-br'))) {
            showToastCustomErrorMessage('Erro ao filtrar notas fiscais', 'A data inicial precisa ser anterior à data limite.')
            setIsLoading(false)
            return false
        }
        return true
    }

    const handleReferenceMonthRowClick = useCallback((params: GridRowParams<any>) => {
        const ReferenceMonth = dayjs(params.row.ReferenceMonth, 'MM/YYYY', 'pt-br').format('YYYY_MM')
        tryGetConsolidatedReportToSeePdf(ReferenceMonth)
    }, [paginationInfo.Page])

    const tryGetConsolidatedReportToSeePdf = async (ReferenceMonth: string) => {

        try {
            const { data: getConsolidatedReportApiResponse } = await getConsolidatedReport(ReferenceMonth)
            handleGetConsolidatedReportApiResponse(getConsolidatedReportApiResponse, ReferenceMonth)
        } catch (error: any) {
            handleAxiosError(error)

        }
    }


    const handleGetConsolidatedReportApiResponse = (getConsolidatedReportApiResponse: any, ReferenceMonth: string) => {
        if (getConsolidatedReportApiResponse.Message === 'INVALID_SESSION_ID') {
            showToastCustomErrorMessage('Sessão expirada', 'Sua sessão expirou. Por favor, faça login novamente.', 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else {
            handleSeePdf(getConsolidatedReportApiResponse.PdfBytes, ReferenceMonth)
        }
    }


    const toggleMobileFilterSection = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        setIsMobileFilterSectionOpen(!isMobileFilterSectionOpen)
    }

    const handleSeePdf = (PdfBytes: string, ReferenceMonth: string) => {
        if (PdfBytes) {
            if (!isDesktopWidth) {
                setIsPdfModalOpen(true)
            }

            const FormattedReferenceMonth = dayjs(ReferenceMonth, 'YYYY_MM', 'pt-br').format('MMMM [de] YYYY')

            const url = `data:application/octet-stream;base64,${PdfBytes}`;
            dispatch(slices.seePdf.setSelectedPdfBytes(url))
            dispatch(slices.seePdf.setReferenceMonth(FormattedReferenceMonth))

        }
    }

    const handleClearFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        if (!isDesktopWidth) {
            setIsMobileFilterSectionOpen(false)
        }

        setLocalUsageSummaryFilterInfo({
            referenceMonthStart: '',
            referenceMonthEnd: ''
        })
        dispatch(slices.usageSummaryPagination.setUsageSummaryPaginationInfo({ ...paginationInfo, Page: 0, Records: 12, }))
    }

    useEffect(() => {
        setIsLoading(true)
        tryListConsolidatedReports()
    }, [paginationInfo.Page])

    const ConsolidatedReportsRows = transformConsolidatedReportsToValidRows(consolidatedReportsList)

    return <main className='financial-management-main-container'>
        <div className='financial-management-main-header-container'>
            <div className='financial-management-main-header-presentation-container'>
                <h2 className='financial-management-title'>Relatórios de consumo</h2>
                <p className='financial-management-paragraph'>Consulte e baixe o resumo de consumo de sua corporação</p>
            </div>
        </div>
        <section className='usage-summary-container'>
            {!isDesktopWidth ? <div className='usage-summary-options-container'>
                <GeneralComponents.CustomDefaultTextButton onClick={toggleMobileFilterSection} className='usage-summary-filter-button' startIcon={<BsFilter color='#0068ff' size={24} />}>Filtros</GeneralComponents.CustomDefaultTextButton>
            </div>
                :
                <></>
            }
            <FilterSection
                localUsageSummaryFilterInfo={localUsageSummaryFilterInfo}
                handleReferenceMonthStartChange={handleReferenceMonthStartChange}
                handleReferenceMonthEndChange={handleReferenceMonthEndChange}
                handleFilterRequest={handleFilterRequest}
                handleClearFilters={handleClearFilters}
                isMobile={!isDesktopWidth}
                open={isMobileFilterSectionOpen}
                toggleMobileFilterSection={toggleMobileFilterSection}
            />
            <div className='usage-summary-content-container'>
                <div className='usage-summary-table-container'>
                    <GeneralComponents.StyledDataGridWithPagination
                        className='usage-summary-table'
                        rows={ConsolidatedReportsRows}
                        columns={usageSummaryGridColumns}
                        loading={isLoading}
                        slots={datagridSlots}
                        onRowClick={handleReferenceMonthRowClick}
                        pagination
                    />
                </div>
                <PDFViewer open={isPdfModalOpen} setIsPdfModalOpen={setIsPdfModalOpen} />
                <Onboarding
                    steps={steps.Financial.UsageSummary}
                    controlPath="UsageSummary"
                />
            </div>
        </section>
    </main>

}

export default UsageSummary;