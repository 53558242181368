import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#choose-consult',
    title: 'Consultas salvas',
    content: 'Acesse consultas pré-configuradas.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#option-0',
    title: 'Escolha uma consulta salva',
    content: 'Selecione uma consulta previamente configurada.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#search-consult',
    title: 'Busque consultas salvas',
    content: 'Realize uma busca para achar a consulta salva desejada de maneira mais simples.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#btn-next',
    title: 'Próxima etapa',
    content: 'Após a escolha da consulta salva clique no botão para avançar para a próxima etapa.',
    disableBeacon: true,
    floaterProps: { placement: 'left' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;