import { TabsProps } from '@mui/material';
import React from 'react';

import Container from './styles'
import { Controller, useFormContext } from 'react-hook-form';

interface CustomTabsProps extends TabsProps {
  name?: string,
  fieldToReset?: string,
  isFormContext?: boolean,
}

const Component: React.FC<CustomTabsProps> = ({
  children,
  name = '',
  fieldToReset,
  isFormContext = true,
  ...props
}) => {

  const formContext = useFormContext()


  if (!!isFormContext && !!formContext.control) {

    return <Controller
      control={formContext.control}
      name={name}
      render={({ field }) => (
        <Container
          {...props}
          {...field}
          onChange={(event, value) => {

            if (props.onChange)
              props.onChange(event, value);

            if (!!fieldToReset) {
              formContext.resetField(fieldToReset)
            }

            return field.onChange(value)
          }
          }
        >
          {children}
        </Container>
      )}
    />
  }

  return <Container {...props}>
    {children}
  </Container>
}

export default Component;