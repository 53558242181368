import Container from "./Container";
import Head from "./Head";
import Body from "./Body";
import Row from "./Row";
import RowCollapse from "./RowCollapse";
import Cell from "./Cell";

export default {
  Container,
  Head,
  Body,
  Row,
  RowCollapse,
  Cell
}