import { GridValidRowModel } from "@mui/x-data-grid";
import { BigBrotherTypes } from "@types";
import dayjs from "dayjs";

const transformTokenListToValidRows = (tokenListData: BigBrotherTypes.ListTokenApiResponse | undefined) => {
  const tokenList: GridValidRowModel[] | undefined = tokenListData?.tokensUser ? tokenListData?.tokensUser.map((user) => ({
    id: user._id,
    token: user.token,
    issuedAt: user.issuedAt,
    domain: user.domain,
    login: user.login,
    expirationDate: user.expirationDate,
    disable: user.disable,
    products: user.products,
  })) : []

  return tokenList
}




const returnIfTokenIsDisabled = (params: any) => {
  const isDisabled = params.value
  const expirationDate = params.row.expirationDate
  const currentDate = dayjs().format()

  if (isDisabled || dayjs(expirationDate).isBefore(currentDate)) {
    return false
  } else {
    return true
  }
}

const isDateValid = (date: string) => {
  return dayjs(date).isValid()
}

const removeAllExceptNumbers = (stringToBeReplaced: string) => stringToBeReplaced.replace(/\D/g, '')

export { transformTokenListToValidRows, removeAllExceptNumbers, returnIfTokenIsDisabled, isDateValid }