import { Switch } from '@mui/material';
import { styled } from '@mui/system';

const CustomSwitch = styled(Switch)`
 
    &&{
        width: 44px;
        height: 24px; 
        background-color: transparent;
        padding: 0;
        border-radius: 12px;
        
        .MuiButtonBase-root{
            padding-left: 4px;
            height: 24px;
            
        }
    }

    .MuiSwitch-track{
        background-color: #B1B9BD;
        opacity: 1;
    }

    .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
        background-color: #0068ff;
        opacity: 1;
    }
    
    .MuiSwitch-thumb {
        background-color: ${props => props.checked ? '#FFFFFF' : '#FFFFFF'};
        margin: ${props => props.checked ? '0 0 0 auto' : '0 auto 0 0'};
        width: 16px;
        height: 16px;
        border-color: none;
        border-width: 0;
    }
    
    .Mui-disabled+.MuiSwitch-track{
        background-color: #F3F8FA;
        opacity: 1;
    }

    .MuiSwitch-switchBase.Mui-checked.Mui-disabled+.MuiSwitch-track{
        background-color: #B1B9BD;
        opacity: 1;
    }

    .Mui-disabled{

        .MuiSwitch-track{
            background-color: #F3F8FA;
            opacity: 1;
        }
        
        .MuiSwitch-thumb {
            background-color: ${props => props.checked ? '#FFFFFF' : '#B1B9BD'};
            margin: ${props => props.checked ? '0 0 0 auto' : '0 auto 0 0'};
            width: 16px;
            height: 16px;
            border-color: none;
            border-width: 0;
        }

    }
`;


export default CustomSwitch;