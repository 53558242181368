import { GeneralComponents } from '@components';
import React, { useEffect } from 'react';

import './mobileSideSection.css'

interface MobileSideSectionProps {
    sideSectionTitle: string;
    mainButtonType?: string;
    mainButtonColor?: string;
    mainButtonText?: string;
    mainButtonAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
    mainButtonStartIcon?: React.ReactNode;
    mainButtonEndIcon?: React.ReactNode;
    secondaryButtonType?: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
    secondaryButtonStartIcon?: React.ReactNode;
    secondaryButtonEndIcon?: React.ReactNode;
    children: React.ReactNode;
    open: boolean,
    closeAction: (event: React.MouseEvent<HTMLButtonElement>) => void
    sideSectionRef: React.MutableRefObject<any>
}


const MobileSideSection: React.FC<MobileSideSectionProps> = (
    {
        sideSectionTitle,
        sideSectionRef,
        mainButtonType,
        mainButtonColor,
        mainButtonText,
        mainButtonAction,
        mainButtonStartIcon,
        mainButtonEndIcon,
        secondaryButtonType,
        secondaryButtonText,
        secondaryButtonAction,
        secondaryButtonStartIcon,
        secondaryButtonEndIcon,
        children,
        open,
        closeAction
    }) => {

    const waitUntilEndOfAnimation = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    const scrollToContent = () => {
        if (sideSectionRef.current) {
            sideSectionRef.current.scrollIntoView({
                behavior: 'auto',
                block: 'start',
            });
        }
    };


    useEffect(() => {
        if (open && sideSectionRef.current.className === 'mobile-side-section-opened') {
            waitUntilEndOfAnimation(100).then(() => scrollToContent())

        }
    }, [open, sideSectionRef.current])

    return <section className={!!open ? 'mobile-side-section-opened' : 'mobile-side-section-closed'} ref={sideSectionRef}>
        <div className='mobile-side-section-content'>
            <div className='mobile-side-section-header'>
                <h3 className='mobile-side-section-title'>{sideSectionTitle}</h3>
                <div className='view-user-button-container'>
                    {mainButtonType === 'default' ?
                        <GeneralComponents.CustomDefaultButton
                            type='button'
                            size='small'
                            variant='contained'
                            startIcon={mainButtonStartIcon}
                            endIcon={mainButtonEndIcon}
                            onClick={mainButtonAction}
                            sx={[
                                {color: mainButtonColor},
                                {"&:hover": {color: mainButtonColor}}
                            ]}
                            >
                            {mainButtonText}
                        </GeneralComponents.CustomDefaultButton>
                        :
                        mainButtonType === 'text' ?
                        <GeneralComponents.CustomDefaultTextButton
                            type='button'
                            size='small'
                            variant='contained'
                            startIcon={mainButtonStartIcon}
                            endIcon={mainButtonEndIcon}
                            onClick={mainButtonAction}
                            sx={[
                                {color: mainButtonColor},
                                {"&:hover": {color: mainButtonColor}}
                            ]}
                            >   
                            {mainButtonText}
                        </GeneralComponents.CustomDefaultTextButton>
                        :
                        <></>
                    }
                </div>
            </div>
            <hr className='mobile-side-section-divider'></hr>
            <div className='mobile-side-section-data'>
                {children}
            </div>
            <div className='mobile-side-section-options'>
                <GeneralComponents.CancelButton onClick={closeAction}>Fechar</GeneralComponents.CancelButton>
                {secondaryButtonType ? secondaryButtonType === 'default' ?
                    <GeneralComponents.CustomDefaultButton
                        type='button'
                        size='small'
                        variant='contained'
                        startIcon={secondaryButtonStartIcon}
                        endIcon={secondaryButtonEndIcon}
                        onClick={secondaryButtonAction}>
                        {secondaryButtonText}
                    </GeneralComponents.CustomDefaultButton>
                    :
                    <GeneralComponents.CustomDefaultTextButton
                        type='button'
                        size='small'
                        variant='contained'
                        startIcon={secondaryButtonStartIcon}
                        endIcon={secondaryButtonEndIcon}
                        onClick={secondaryButtonAction}>
                        {secondaryButtonText}
                    </GeneralComponents.CustomDefaultTextButton>

                    :
                    <></>
                }
            </div>
        </div>
    </section>;
}

export default MobileSideSection;