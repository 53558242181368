import React from 'react';

import './statusCell.css'

const StatusCell = ({ params }: any) => {

    const getStatusValue = () => {
        switch (params.value) {
            case 'Em andamento':
                return <span className='neutral-status'>Em andamento</span>
            case 'Cancelado':
                return <span className='negative-status'>Cancelado</span>
            case 'Concluído':
                return <span className='positive-status'>Concluído</span>
            case 'PAID':
                return <span className='positive-status'>Pago</span>
            case 'CANCELLED':
                return <span className='neutral-status'>Cancelado</span>
            case 'PENDING':
                return <span className='warning-status'>Pendente</span>
            case 'OVERDUE':
                return <span className='negative-status'>Atrasado</span>
            case true:
                return <span className='positive-status'>Ativo</span>
            case false:
                return <span className='negative-status'>Inativo</span>
        }
    }

    return <div className='status-cell-container'>{getStatusValue()}</div>;
}

export default StatusCell;