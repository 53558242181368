import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InvoicesDetailsInfo {
    open: boolean;
    params: any;
}


const initialState: InvoicesDetailsInfo = {
   open: false,
   params: undefined
}

const invoicesDetailsSlice = createSlice({
  name: 'invoicesDetails',
  initialState: initialState,
  reducers: {
    setShowInvoiceDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowInvoiceDetailsSection, setParams } = invoicesDetailsSlice.actions;

export default invoicesDetailsSlice.reducer;