import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface qualityImageDialogInfo {
    dismissDialog: boolean;
    user: string;
}

const initialState: qualityImageDialogInfo = {
    dismissDialog: false,
    user: ''
}

const qualityImagesultDialog = createSlice({
    name: 'qualityImagesultDialog',
    initialState,
    reducers: {
        setDismissDialog: (state, action: PayloadAction<qualityImageDialogInfo>) => {
            return { ...state, ...action.payload }
        }
    }
});

export const {  setDismissDialog } = qualityImagesultDialog.actions;

export default qualityImagesultDialog.reducer;