import React from "react";

import { Box } from "@mui/material";
import { Collapse } from "@components";
import { Data } from "./components";

import { getOnlyDataBase64 } from "@utils";

type ComponentProps = {
  type: "link" | "file",
  files: Array<any>,
  documentIndex: number
};

const Component: React.FC<ComponentProps> = ({ type, files, documentIndex }) => {
  const hasTwoFiles = !!(files?.length === 2);

  if (!!!hasTwoFiles) {

    const file = type === "file" ? getOnlyDataBase64(files?.[0]?.base64) : files?.[0]?.link

    return (
      <Data file={file} index={0} documentIndex={documentIndex} />
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={3}>
        {
          files.map((file, index) => {
            const fileValue = type === "file" ? getOnlyDataBase64(file?.base64) : file?.link
            const title = index === 0 ? "Documento frente" : "Documento verso";
            
            return (
              <Collapse title={title} collapsed>
                <Box p={5}>
                  <Data file={fileValue} index={index} documentIndex={documentIndex} />
                </Box>
              </Collapse>
            )
          })
        }
      </Box>
    </>
  )
}

export default Component;