import { string } from "yup"

interface Document {
  [key: string]: Document | any | {
    name: string,
    label: string
  }
}

const documents: Document = {
  name: {
    name: "documents.name",
    label: "Nome"
  },
  email: {
    name: "documents.email",
    label: "E-mail"
  },
  phone: {
    name: "documents.phone",
    label: "Telefone"
  },
  motherName: {
    name: "documents.mother_name",
    label: "Nome da mãe"
  },
  birthDate: {
    name: "documents.birth_date",
    label: "Data de nascimento"
  },
  state: {
    name: "documents.state",
    label: "Estado"
  },
  city: {
    name: "documents.city",
    label: "Cidade"
  },
  cep: {
    name: "documents.cep",
    label: "CEP"
  },
  default: {
    pessoas: {
      name: "documents.doc",
      label: "CPF"
    },
    empresas: {
      name: "documents.doc",
      label: "CNPJ"
    },
    veiculos: {
      name: "documents.licenseplate",
      label: "Placa"
    },
    enderecos: {
      name: "documents.polygon",
      label: "Coordenadas"
    },
    processos: {
      name: "documents.processnumber",
      label: "Número do processo"
    },
    produtos: {
      name: "documents.ean",
      label: "Número do código de barras"
    },
    ondemand: {
      name: "documents.doc",
      label: "Documento"
    },
    marketplace: {
      name: "documents.doc",
      label: "Documento"
    },
  }
}

export {
  documents
}