import { Theme, styled } from "@mui/material/styles";
import { StandardCSSProperties } from "@mui/system";

import { Chip as ChipComponent } from "@components";

type ElementWithStatusProps = {
  theme?: Theme,
  status?: "success" | "warning" | "danger" | "information"
}

interface IStyles {
  [key: string]: StandardCSSProperties | any
}

const Title = styled("div")<ElementWithStatusProps>(({ theme, status }) => {
  const titleStyles: IStyles = {
    color: {
      success: theme.palette.success.medium,
      warning: theme.palette.warning.medium,
      danger: theme.palette.negative.medium,
      information: theme.palette.lighterBigBlue?.medium
    }
  }

  return {
    fontFamily: "Be Vietnam",
    fontWeight: 700,
    fontSize: 14,
    color: titleStyles.color[status || ""],
  }
});

const Description = styled("div")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100]
}));

const Chip = styled(ChipComponent)<ElementWithStatusProps>(({ theme, status }) => {
  const chipStyles: IStyles = {
    color: {
      success: theme.palette.success.dark,
      warning: theme.palette.warning.dark,
      danger: theme.palette.negative.dark,
      information: theme.palette.lighterBigBlue?.dark
    },
    backgroundColor: {
      success: theme.palette.success.lightest,
      warning: theme.palette.warning.lightest,
      danger: theme.palette.negative.lightest,
      information: theme.palette.lighterBigBlue?.lightest
    }
  }

  return {
    fontWeight: 700,
    backgroundColor: chipStyles.backgroundColor[status || ""],
    color: chipStyles.color[status || ""]
  }
});

export {
  Title,
  Description,
  Chip
}