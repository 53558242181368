import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GeneralComponents, TokenComponents, Onboarding } from "@components";

import { reducers, slices } from "@store";

import { showToastCustomErrorMessage, transformTokenListToValidRows, handleAxiosError } from '@utils';

import { commonUserTokenListColumns, tokenListColumns, steps } from "@constants";

import { BigBrother } from '@services';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';


import CreateTokenSection from './CreateTokenSection';

import { TokenPagination } from './TokenPagination';

import './tokenManagement.css'
import TokenDetails from './TokenDetails';

const datagridPaginationOptions = [12]
const datagridSlots = {
    pagination: TokenPagination,
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}

const TokenManagement: React.FC = () => {

    const isSystemAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin)
    const sessionId = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.sessionId)
    const showDialog = useSelector((state: reducers.RootState) => state.disableTokenDialogReducer.showDialog)
    const wasConfirmed = useSelector((state: reducers.RootState) => state.disableTokenReducer.wasConfirmed)
    const reloadTokenList = useSelector((state: reducers.RootState) => state.createTokenReducer.reloadTokenList)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [tokenListData, setTokenListData] = useState()
    const [showCreateTokenSection, setShowCreateTokenSection] = useState<boolean>(false)

    const dispatch = useDispatch()

    const getTokenList = async () => await BigBrother.listActiveTokens({ sessionId })
    const checkIfisInvalidSession = (tokenListData: any) => tokenListData.message === "Erro ao realizar a operação"

    const closeCreateTokenSection = () => {
        setShowCreateTokenSection(false)
    }

    const handleBigBrotherApiResponse = (tokenListData: any) => {

        if (checkIfisInvalidSession(tokenListData)) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else {
            setTokenListData(tokenListData)
            setIsLoading(false)
        }
    }

    const tryGetTokenListData = async () => {
        try {
            const { data: apiBigBrotherResponse } = await getTokenList()
            handleBigBrotherApiResponse(apiBigBrotherResponse)

        } catch (error: any) {
            handleAxiosError(error)
            setIsLoading(false)
        }
    }

    const handleNewTokenClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setShowCreateTokenSection(!showCreateTokenSection)
    }

    const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setShowCreateTokenSection(false)
    }

    useEffect(() => {
        setIsLoading(true)
        tryGetTokenListData()
    }, [wasConfirmed, reloadTokenList])

    const tokenListRows = transformTokenListToValidRows(tokenListData)

    return (
        <main className='token-management-main-container' onMouseDown={handleOutsideClick}>
            <div className='token-management-presentation-container'>
                <h2 className='token-management-title'>Gestão de tokens</h2>
                <p className='token-management-description'>Gerencie e consulte seus tokens</p>
            </div>
            <div className='token-management-options-container'>
                <GeneralComponents.CustomDefaultButton variant="contained" className='create-token-button' startIcon={<FontAwesomeIcon icon={faPlus} />} onClick={handleNewTokenClick}>Novo token</GeneralComponents.CustomDefaultButton>
            </div>
            {showCreateTokenSection ? <CreateTokenSection className="create-token-container-opened" closeCreateTokenSection={closeCreateTokenSection} /> : <CreateTokenSection className="create-token-container-closed" closeCreateTokenSection={closeCreateTokenSection} />}
            <section className='token-management-list-container'>
                {showDialog ? <TokenComponents.DisableTokenDialog /> : null}
                <GeneralComponents.StyledDataGridWithPagination
                    rows={tokenListRows}
                    loading={isLoading}
                    columns={isSystemAdmin ? tokenListColumns : commonUserTokenListColumns}
                    pageSizeOptions={datagridPaginationOptions}
                    slots={datagridSlots}
                ></GeneralComponents.StyledDataGridWithPagination>
            </section>
            <Onboarding
                steps={steps.TokenManagement}
                controlPath="TokenManagement"
            />
            <TokenDetails />
        </main>
    )
}

export default TokenManagement;