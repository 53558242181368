import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from "@store";
import { Billing } from '@services';
import { BillingTypes } from '@types';
import { handleAxiosError, showToastCustomErrorMessage } from '@utils';
import { GeneralComponents } from "@components";

const SeeDetailsCell = ({ params }: any) => {

  const dispatch = useDispatch();

  const handleSeeDetailsButtonClick = async () => {
    tryToSeePartialDetails()
  };

  const getPartialDetail = async () => await Billing.getPartialDetail({
    Product: params.row.Product,
    PartialLastDay: parseInt(params.row.PartialLastDay)
  }
  )

  const tryToSeePartialDetails = async () => {
    try {
      const { data: getPartialDetailResponse } = await getPartialDetail()
      handleGetPartialDetailResponse(getPartialDetailResponse)
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const handleGetPartialDetailResponse = (getPartialDetailResponse: BillingTypes.GetPartialDetailApiResponse) => {
    if (!getPartialDetailResponse.Sucess && getPartialDetailResponse.Message === "INVALID_SESSION_ID") {
      showToastCustomErrorMessage('Sessão expirada', 'Sua sessão expirou. Por favor, faça o login novamente.', 'session-timeout')
      dispatch(slices.user.setUserInitialState())
    } else {
      dispatch(slices.seeDetails.setSeeDetailsInfo({ Consumption: getPartialDetailResponse.Consumption, showModal: true }))
    }
  }


  return (
    <GeneralComponents.CustomDefaultButton
      variant='contained'
      onClick={handleSeeDetailsButtonClick}
    >Ver detalhes</GeneralComponents.CustomDefaultButton>
  );
};

export default SeeDetailsCell;