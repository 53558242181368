import { combineReducers } from 'redux';
import persistedUserInfoReducer from '../persistConfig';
import navbarReducer from '../slices/navbar'
import userManagementFilterReducer from '../slices/userManagementFilterSection'
import tokenManagementFilterReducer from '../slices/tokenManagementFilterSection'
import deleteFile from '../slices/deleteFile';
import disableTokenDialogReducer from '../slices/disableTokenDialog';
import disableTokenReducer from '../slices/disableToken';
import seeDetailsReducer from '../slices/seeDetails'; 
import seePdfReducer from '../slices/seePdf'; 
import createTokenReducer from '../slices/createToken';
import consumptionDetailsReducer from '../slices/consumptionDetails';
import currentUsageDetailsReducer from '../slices/currentUsageDetails';
import currentUsageSummaryDetailsReducer from '../slices/currentUsageSummaryDetails';
import editUserReducer from '../slices/editUser'
import exportPdf from '../slices/exportPdf';
import viewUserReducer from '../slices/viewUser'
import userProfileReducer from '../slices/userProfile'
import onboardingTooltipReducer from '../slices/onboardingTooltip'
import installmentDetailsSectionReducer from '../slices/installmentsDetails'
import invoiceDetailsSectionReducer from '../slices/invoiceDetails'
import invoicesPaginationReducer from '../slices/invoicesPagination'
import notificationCenterReducer from '../slices/notificationCenter'
import installmentsReducer from '../slices/installments'
import tokenDetailsReducer from '../slices/tokenDetails';
import userPaginationReducer from '../slices/userPagination'
import sideSection from "../slices/sideSection"
import usageSummaryPagination from '../slices/usageSummaryPagination'
import formWizard from "../slices/formWizard"
import quickConsultDialog from "../slices/quickConsultDialog";
import qualityImageDialog from "../slices/qualityImageDialog";
import fileTransferDetails from '../slices/fileTransferDetails';
import exportPdfDocumentoscopia from "../slices/exportPdfDocumentoscopia";
import exportPdfFacematch from '../slices/exportPdfFacematch';


const rootReducer = combineReducers({
    persistedUserInfoReducer,
    navbarReducer,
    userManagementFilterReducer,
    tokenManagementFilterReducer,
    deleteFile,
    disableTokenDialogReducer,
    disableTokenReducer,
    createTokenReducer,
    consumptionDetailsReducer,
    currentUsageDetailsReducer,
    currentUsageSummaryDetailsReducer,
    editUserReducer,
    exportPdf,
    fileTransferDetails,
    viewUserReducer,
    seeDetailsReducer,
    seePdfReducer,
    userProfileReducer,
    onboardingTooltipReducer,
    installmentDetailsSectionReducer,
    invoiceDetailsSectionReducer,
    invoicesPaginationReducer,
    notificationCenterReducer,
    installmentsReducer,
    tokenDetailsReducer,
    userPaginationReducer,
    sideSection,
    usageSummaryPagination,
    formWizard,
    quickConsultDialog,
    qualityImageDialog,
    exportPdfDocumentoscopia,
    exportPdfFacematch
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
