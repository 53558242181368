import React from 'react';
import { GeneralComponents } from '@components';
import { useDispatch } from 'react-redux';
import { slices } from '@store';
import { AiOutlineFileSearch } from 'react-icons/ai';

const InvoiceDetailsCell = ({ params }: any) => {

    const dispatch = useDispatch()

    const handleInvoiceDetailsButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.invoiceDetails.setParams(params.row))
        dispatch(slices.invoiceDetails.setShowInvoiceDetailsSection(true))
    }

    return <GeneralComponents.StyledGridActionsCellItem
        icon={<AiOutlineFileSearch color="#0068ff" size={24} />}
        label="Detalhes da fatura"
        className='invoices-details-cell'
        onClick={handleInvoiceDetailsButtonClick}
    />;
}

export default InvoiceDetailsCell;