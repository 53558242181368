import React from "react";

import { RadioGroup, FormControl, Typography } from "@mui/material";

import { useFormContext, Controller } from "react-hook-form";

interface ComponentProps {
  children: React.ReactNode,
  name: string
}

const Component: React.FC<ComponentProps> = ({ children, name }) => {

  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl fullWidth>
            <RadioGroup
              {...field}
              row
              onChange={() => { }}
              value={field.value}
            >
              {children}
            </RadioGroup>
          </FormControl>
        )}
      />
      <Typography color="red">
        <>
          {errors?.[name]?.message && errors?.[name]?.message}
        </>
      </Typography>
    </>
  )
}

export default Component