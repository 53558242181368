import { GeneralComponents } from '@components';
import { reducers, slices } from '@store';
import dayjs from 'dayjs';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const TokenDetails: React.FC = () => {

    const dispatch = useDispatch()
    const { open, params } = useSelector((state: reducers.RootState) => state.tokenDetailsReducer)
    const sideSectionRef = useRef(null)

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.tokenDetails.setShowTokenDetailsSection(false))
    }

    const handleDisableButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.disableToken.setDisableTokenIdInfo({ login: params?.login, tokenId: params?.id, wasConfirmed: false }))
        dispatch(slices.disableTokenDialog.setShowDialog(true))
    };

    return <GeneralComponents.MobileSideSection
        sideSectionTitle='Detalhes do token'
        mainButtonType='text'
        mainButtonColor='#FF003C'
        mainButtonText='Desabilitar'
        mainButtonAction={handleDisableButtonClick}
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
        <div className='invoice-details-content'>
            <div className='invoice-details-data'>
                <div className='mobile-side-section-group'>
                    <label>Token ID:</label>
                    <span>{params?.id}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Login:</label>
                    <span>{params?.login}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Domínio:</label>
                    <span>{params?.domain}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Criado em:</label>
                    <span>{dayjs(params?.issuedAt).format('DD/MM/YYYY - HH:MM')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Expira em:</label>
                    <span>{dayjs(params?.expirationDate).format('DD/MM/YYYY - HH:MM')}</span>
                </div>
            </div>
        </div>
    </GeneralComponents.MobileSideSection>
}

export default TokenDetails;