import React from "react";

import { MenuItem } from "@mui/material"

interface ComponentProps {
  children: string | React.ReactNode,
  value: string | number
}

const Component: React.FC<ComponentProps> = ({ children, value, ...props }) => {

  return (
    <MenuItem {...props}>{children}</MenuItem>
  )
}

export default Component;