import { Theme, styled } from "@mui/material/styles"

import { Chip as ChipComponent } from "@components";

const Title = styled("h1")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

type ChipProps = {
  theme?: Theme,
  percentage: number
}

const Chip = styled(ChipComponent)<ChipProps>(({ theme, percentage }) => {
  const red = theme.palette.success.lightest;
  const yellow = theme.palette.success.lightest;
  const green = theme.palette.success.lightest;

  const backgroundColor = percentage <= 33 ? red : percentage <= 66 ? yellow : green;

  return ({
    fontWeight: 700,
    backgroundColor: backgroundColor,
    color: theme.palette.success.dark
  })
});

export {
  Title,
  Chip
}