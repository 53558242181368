import React from 'react';

import Container from './styles';
import { useMediaQuery } from '@mui/material';

interface LinkContainerProps {
    children: any
}

const LinkContainer: React.FC<LinkContainerProps> = ({
    children
}) => {

    const breakpoint = useMediaQuery('(max-width: 1000px)')

    return <Container breakpoint={breakpoint}>
        {children}
    </Container>;
}

export default LinkContainer;