// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-consumption-container-list{
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 24px;
    isolation: isolate;
    background: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.get-partial-detail-table-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    border-radius: 8px;
    height: 696px;
    overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/pages/FinancialManagement/CurrentUsage/SeeDetailsSection/seeDetailsSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,wCAAwC;AAC5C;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".product-consumption-container-list{\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n    gap: 24px;\n    isolation: isolate;\n    background: #FFFFFF;\n    border-radius: 16px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);\n}\n\n.get-partial-detail-table-container{\n    display: flex;\n    justify-content: center;\n    align-items: flex-start;\n    padding: 0px;\n    border-radius: 8px;\n    height: 696px;\n    overflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
