import { Box, Skeleton, Stack } from '@mui/material';
import React from 'react';


const LoadingSkeleton: React.FC = () => {

  return  <Stack spacing={1} width={'100%'} height={'100%'}>
  <Skeleton
    sx={{ bgcolor: 'rgba(229, 239, 255, .9)', fontSize: '1rem'  }}
    animation='wave'
    variant="text"
    width={'70%'}
  />
  <Box
    width={'100%'}
    display={'flex'}
    height={'100%'}
    minHeight={175}
    gap={4}
    flexDirection={window.innerWidth > 700 ? 'row' : 'column'}
    >
    
    <Skeleton
      sx={{ bgcolor: 'rgba(229, 239, 255, .9)' }}
      animation='wave'
      variant="rounded"
      width={window.innerWidth > 700 ? '50%' : '100%'}
      height={window.innerWidth > 700 ? '100%' : '175px'} 
    />
    <Skeleton
      sx={{ bgcolor: 'rgba(229, 239, 255, .9)' }}
      animation='wave'
      variant="rounded"
      width={window.innerWidth > 700 ? '50%' : '100%'}
      height={window.innerWidth > 700 ? '100%' : '175px'} 
    />
  </Box>
  <Box width={'100%'} display={'flex'} justifyContent={'space-between'}>
    <Skeleton
      sx={{ bgcolor: 'rgba(229, 239, 255, .9)', fontSize: '1rem' }}
      animation='wave'
      variant="text"
      width={'100px'}
    />
    <Skeleton
      sx={{ bgcolor: 'rgba(229, 239, 255, .9)', fontSize: '1rem' }}
      animation='wave'
      variant="text"
      width={'100px'}
    />
  </Box>
  <Skeleton
    sx={{ bgcolor: 'rgba(229, 239, 255, .7)', fontSize: '1rem' }}
    animation='wave'
    variant="text"
  />
</Stack>;
}

export default LoadingSkeleton;