import { Container } from "./styles";

const NoResults = () => {
  return (
    <Container>
      Nenhum resultado encontrado
    </Container>
  )
}

export default NoResults;