import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { reducers } from '@store';

import './viewUser.css'

import { Button, GeneralComponents, SideSection } from "@components";
import { MdEdit, MdOutlineClose } from 'react-icons/md';

import { slices } from "@store";
import { Box, Divider, Typography } from '@mui/material';

interface ViewUserSectionProps {
  className: string;
}

const ViewUser: React.FC<ViewUserSectionProps> = ({ className }: ViewUserSectionProps) => {

  const viewUserInfo = useSelector((state: reducers.RootState) => state.viewUserReducer)
  const dispatch = useDispatch()

  const handleEditButtonClick = () => {
    const { name, login, email, group, isEnabled, isSystemAdmin } = viewUserInfo
    const twoFa = viewUserInfo['2fa']
    dispatch(slices.viewUser.setShowViewUserSection(false))
    dispatch(slices.editUser.setEditUserInfo({ name, login, email, group, isEnabled, isSystemAdmin, '2fa': twoFa, showEditUserSection: true }))
  }

  const handleViewUserContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
  }

  const handleCloseButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch(slices.viewUser.setShowViewUserSection(false))
  }

  return <div className={className} onMouseDown={handleViewUserContainerClick}>
    <Box
      width={'100%'}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'center'}
      gap={8}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
      >
        <Box
          display={'flex'}
          width={'100%'}
          alignItems={'center'}
          justifyContent={'space-between'}
          p={`32px 30px 24px 30px`}
        >
          <Typography
            variant='h2'
          >
            Perfil do usuário
          </Typography>
          <Box>
          </Box>
          <Button
            color='primary'
            size='small'
            StartIcon={<MdEdit size={20} />}
            onClick={handleEditButtonClick}
          >
            Editar usuário
          </Button>
        </Box>
        <Divider variant='fullWidth' />
      </Box>
      <Box
        display={'flex'}
        flexDirection={'column'}
        width={'100%'}
        paddingX={7.5}
        gap={8}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          width={'100%'}
          gap={4}
        >
          <Typography
            variant='h3'
          >
            Dados
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            gap={2}
          >
            <SideSection.Field label='Login:' value={viewUserInfo?.login} />
            <SideSection.Field label='Nome:' value={viewUserInfo?.name} />
            {
              !!viewUserInfo.email && (
                <SideSection.Field label='E-mail:' value={viewUserInfo?.email} />
              )
            }
            <SideSection.Field label='Domínio:' value={viewUserInfo?.group} />
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          width={'100%'}
          gap={4}
        >
          <Typography
            variant='h3'
          >
            Condições
          </Typography>
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={'100%'}
            gap={2}
          >
            <SideSection.Field
              label='Perfil de acesso:'
              value={!!viewUserInfo?.isSystemAdmin ? 'Administrador(a)' : "Colaborador"}
            />
            <SideSection.Field
              label='Autenticação em dois fatores:'
              value={<GeneralComponents.StatusTag value={viewUserInfo?.['2fa']}></GeneralComponents.StatusTag>}
            />
            <SideSection.Field
              label='Status:'
              value={<GeneralComponents.StatusTag value={viewUserInfo?.isEnabled}></GeneralComponents.StatusTag>}
            />
          </Box>
        </Box>
        <Box
          alignSelf={'flex-end'}
        >
          <Button
            color='secondary'
            size='small'
            StartIcon={<MdOutlineClose size={20} />}
            onClick={handleCloseButtonClick}
          >
            Fechar
          </Button>
        </Box>
      </Box>
    </Box>
  </div>
}

export default ViewUser;