import { GeneralComponents } from '@components';
import { Box, SelectChangeEvent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { reducers } from '@store';
import { Dayjs } from 'dayjs';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Label, selectedButtonStyle } from '../SideSectionContent/styles';

interface FilterSectionProps {
    localFilterInfo: {
        ReferenceMonthStart: string;
        ReferenceMonthEnd: string;
        reportName: string;
        userName: string;
        filterBy: string;
        defaultRange: string;
    },
    handleTextfieldChanges: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleReferenceMonthStartChange: (referenceMonthStart: Dayjs | null) => void;
    handleReferenceMonthEndChange: (referenceMonthEnd: Dayjs | null) => void;
    handleFilterRequest: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClearFilters: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleSelectChanges: (event: SelectChangeEvent<unknown>) => void;
    isMobile: boolean;
    open: boolean;
    toggleMobileFilterSection: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleButtonGroupChanges: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const FilterSection: React.FC<FilterSectionProps> = (
    {
        localFilterInfo,
        handleTextfieldChanges,
        handleSelectChanges,
        handleReferenceMonthStartChange,
        handleReferenceMonthEndChange,
        handleFilterRequest,
        handleClearFilters,
        isMobile,
        open,
        toggleMobileFilterSection,
        handleButtonGroupChanges
    }
) => {

    const { isSystemAdmin } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)
    const mobileFiltersRef = useRef(null)

    return !isMobile ? <Box
        display={'flex'}
        flexWrap={'wrap'}
        gap={'8px'}
    >
        <GeneralComponents.CustomFilterTextField
            LabelText='Nome do relatório'
            placeholder='Busque pelo nome'
            name='reportName'
            value={localFilterInfo.reportName}
            onChange={handleTextfieldChanges}
        />
        {isSystemAdmin && <>
            <GeneralComponents.CustomFilterTextField
                LabelText='Nome do usuário'
                placeholder='Busque por usuário'
                name='userName'
                value={localFilterInfo.userName}
                onChange={handleTextfieldChanges}
            />
            <GeneralComponents.CustomSelect
                variant="outlined"
                LabelText='Filtrar por:'
                onChange={handleSelectChanges}
                value={localFilterInfo.filterBy}
                name='filterBy'
            >
                <GeneralComponents.CustomMenuItem value={'user'}>Usuário</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value={'domain'}>Dominío</GeneralComponents.CustomMenuItem>
            </GeneralComponents.CustomSelect>
        </>
        }
        <GeneralComponents.CustomSelect
            variant="outlined"
            LabelText='Período de referência'
            onChange={handleSelectChanges}
            value={localFilterInfo.defaultRange}
            name='defaultRange'
        >
            <GeneralComponents.CustomMenuItem value={'none'}>Nenhum</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value={'lastDay'}>Último dia</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value={'lastWeek'}>Última semana</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value={'lastMonth'}>Último mês</GeneralComponents.CustomMenuItem>
        </GeneralComponents.CustomSelect>
        <LocalizationProvider adapterLocale={'pt-br'} dateAdapter={AdapterDayjs}>
            <GeneralComponents.CustomDatePickers
                LabelText='Período personalizado'
                startValue={localFilterInfo.ReferenceMonthStart}
                endValue={localFilterInfo.ReferenceMonthEnd}
                handleStartChange={handleReferenceMonthStartChange}
                handleEndChange={handleReferenceMonthEndChange}
                handleFieldErrors={() => { }}
                views={['day', 'month', 'year']}
                format='DD/MM/YYYY'
            />
        </LocalizationProvider>
        <Box
            display={'flex'}
            alignItems={'flex-end'}
            marginBottom={'2px'}
            gap={'8px'}
        >
            <GeneralComponents.FilterButton onClick={handleFilterRequest}>Filtrar</GeneralComponents.FilterButton>
            <GeneralComponents.ClearFilterButton onClick={handleClearFilters}>Limpar filtros</GeneralComponents.ClearFilterButton>
        </Box>
    </Box>
        :
        <GeneralComponents.MobileSideSection
            sideSectionTitle='Filtros'
            sideSectionRef={mobileFiltersRef}
            mainButtonType='text'
            mainButtonText='Limpar filtros'
            mainButtonAction={handleClearFilters}
            closeAction={toggleMobileFilterSection}
            open={open}
            secondaryButtonType='default'
            secondaryButtonText='Filtrar'
            secondaryButtonAction={handleFilterRequest}
        >
            <GeneralComponents.CustomFilterTextField
                LabelText='Nome do relatório'
                placeholder='Busque pelo nome'
                fullWidth
            />
            {isSystemAdmin && <>
                <GeneralComponents.CustomFilterTextField
                    LabelText='Nome do usuário'
                    placeholder='Busque por usuário'
                    fullWidth

                />
                <Box
                    display='flex'
                    flexDirection={'column'}
                    gap={'4px'}
                >
                    <Label>Filtrar por</Label>
                    <GeneralComponents.CustomButtonGroup
                        sx={{
                            width: 'fit-content',
                            height: 'fit-content',
                        }}
                        id='filterBy'
                    >
                        <GeneralComponents.CustomGroupedButton
                            sx={{ ...localFilterInfo.filterBy === 'user' && selectedButtonStyle }}
                            name={'user'}
                            onClick={handleButtonGroupChanges}
                        >
                            Usuário
                        </GeneralComponents.CustomGroupedButton>
                        <GeneralComponents.CustomGroupedButton
                            sx={{ ...localFilterInfo.filterBy === 'domain' && selectedButtonStyle }}
                            name={'domain'}
                            onClick={handleButtonGroupChanges}
                        >
                            Dominío
                        </GeneralComponents.CustomGroupedButton>
                    </GeneralComponents.CustomButtonGroup>
                </Box>
            </>

            }


            <Box
                display='flex'
                flexDirection={'column'}
                gap={'4px'}
            >
                <Label>Intervalo de interesse</Label>
                <GeneralComponents.CustomButtonGroup
                    sx={{
                        width: 'fit-content',
                        height: 'auto',
                    }}
                    id='defaultRange'
                >
                    <GeneralComponents.CustomGroupedButton
                        sx={{ ...localFilterInfo.defaultRange === 'none' && selectedButtonStyle }}
                        name={'none'}
                        onClick={handleButtonGroupChanges}
                    >
                        Nenhum
                    </GeneralComponents.CustomGroupedButton>
                    <GeneralComponents.CustomGroupedButton
                        sx={{ ...localFilterInfo.defaultRange === 'lastDay' && selectedButtonStyle }}
                        name={'lastDay'}
                        onClick={handleButtonGroupChanges}
                    >
                        Último dia
                    </GeneralComponents.CustomGroupedButton>
                    <GeneralComponents.CustomGroupedButton
                        sx={{ ...localFilterInfo.defaultRange === 'lastWeek' && selectedButtonStyle }}
                        name={'lastWeek'}
                        onClick={handleButtonGroupChanges}
                    >
                        Última semana
                    </GeneralComponents.CustomGroupedButton>
                    <GeneralComponents.CustomGroupedButton
                        sx={{ ...localFilterInfo.defaultRange === 'lastMonth' && selectedButtonStyle }}
                        name={'lastMonth'}
                        onClick={handleButtonGroupChanges}
                    >
                        Último mês
                    </GeneralComponents.CustomGroupedButton>
                </GeneralComponents.CustomButtonGroup>
            </Box>
            <LocalizationProvider adapterLocale={'pt-br'} dateAdapter={AdapterDayjs}>
                <GeneralComponents.CustomDatePickers
                    LabelText='Período personalizado'
                    startValue={localFilterInfo.ReferenceMonthStart}
                    endValue={localFilterInfo.ReferenceMonthEnd}
                    handleStartChange={handleReferenceMonthStartChange}
                    handleEndChange={handleReferenceMonthEndChange}
                    handleFieldErrors={() => { }}
                    views={['day', 'month', 'year']}
                    format='DD/MM/YYYY'
                />
            </LocalizationProvider>
        </GeneralComponents.MobileSideSection>
        ;
}

export default FilterSection;