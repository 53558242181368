import { Box, Skeleton, Tooltip, Typography } from "@mui/material";
import { Paper, Table, Notification } from "@components";
import Row from "../Row";
import NoResults from "../NoResults";

import { Title, Identification, IdentificationLabel, IdentificationValue } from "./styles";

import { BiHelpCircle } from "react-icons/bi";
import { useFetch } from "@hooks";
import { Platform, apiPlatformDev } from "@services";
import { handleString, pontualConsult } from "@utils";
import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";
import { useCallback } from "react";
import React from "react";

interface CardProps {
  api: any,
  dataset: any,
  attributes: any,
  params: any,
  print: boolean,
  datasetName: any
}

const Card: React.FC<CardProps> = ({
  api,
  dataset,
  attributes,
  params,
  print = false,
  datasetName
}) => {
  const dispatch = useDispatch();
  const title = datasetName;
  const datasetWithAttributes = pontualConsult.transformDatasetsToValidData({ [dataset]: attributes });

  const { data, error, isLoading } = useFetch(
    Platform.Consult,
    {
      api,
      Datasets: datasetWithAttributes,
      q: pontualConsult.transformDocumentsToValidApi({ ...params, dateformat: "dd/MM/yyyy" })
    });

  const matchedResult = Object.entries(data?.Result?.[0] || {}).find(([key]) => key !== "MatchKeys")?.[1] || []

  const hasManyResults = Array.isArray(matchedResult);

  const preparedData = hasManyResults ?
    matchedResult.map((result: any) => pontualConsult.handleData(result)) :
    pontualConsult.handleData(matchedResult);
  const errors = data?.Status?.[dataset]?.filter((error: any) => error.Code !== 0) || [];

  const sendDatasetErrorToIgnoreList = useCallback(() => {
    if (!!errors.length) {
      dispatch(slices.exportPdf.pushToIgnoreList(dataset))
      return
    }

    dispatch(slices.exportPdf.removeFromIgnoreList(dataset))
  }, [dataset, !!errors?.length])


  React.useEffect(() => {
    sendDatasetErrorToIgnoreList()
  }, [sendDatasetErrorToIgnoreList])

  return (
    <Paper variant="outlined" className={print ? 'print' : 'no-print'}>
      <Box display="flex" flexDirection="column" gap={8} p={6}>
        <Title>{title}</Title>
        <Box display="flex" flexDirection="column" gap={4}>
          <Identification display="flex" alignItems="center" gap={4} p={4} id="id-consult">
            <IdentificationLabel>
              <Typography variant="body2" color="secondary" fontWeight={700}>
                Identificação
              </Typography>
              <Tooltip title="Query ID">
                <BiHelpCircle size={20} />
              </Tooltip>
            </IdentificationLabel>
            <IdentificationValue>
              {
                (!!!isLoading) ?
                  (
                    data?.QueryId
                  ) : (
                    <Skeleton animation="wave" width="50%" height={30} />
                  )
              }
            </IdentificationValue>
          </Identification>
          {
            (
              !!!isLoading && !!preparedData && errors?.length <= 0
            ) ? (
              preparedData?.length > 0 ?
                (
                  hasManyResults ?
                    preparedData.map((data: any, index: any) => <CardData key={index} data={data} />) :
                    <CardData data={preparedData} />
                ) : (
                  <NoResults />
                )
            ) : (
              !!!isLoading && !!errors?.length
            ) ? (
              <>
                {
                  errors.map((error: any) =>
                  (
                    <Notification title={error?.Message} description={`Código: ${error?.Code}`} variant="danger" />
                  ))
                }
              </>
            ) : (
              <Box display="flex" flexDirection="column">
                <Skeleton animation="wave" width="100%" height={50} />
                <Skeleton animation="wave" width="100%" height={50} />
                <Skeleton animation="wave" width="100%" height={50} />
              </Box>
            )
          }
        </Box>
      </Box>
    </Paper>
  )
}

const CardData = ({ data }: any) => {
  return (
    <Table.Container elevation>
      <Table.Head>
        <Table.Row>
          <Table.Cell>
            <Box display="flex" justifyContent="flex-start">
              <Typography color="secondary" variant="body2" fontWeight={700}>
                Atributo
              </Typography>
            </Box>
          </Table.Cell>
          <Table.Cell colSpan={2}>
            <Box display="flex" justifyContent="flex-start">
              <Typography color="secondary" variant="body2" fontWeight={700}>
                Resultado
              </Typography>
            </Box>
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {
          data.map((item: any) => {
            if (!!!item?.key) return;

            return (
              <Row label={item?.key} value={item?.value} />
            )
          })
        }
      </Table.Body>
    </Table.Container>
  )
}

export default Card;