import { Theme, styled } from "@mui/material/styles"

import { Box, Modal as ModalMaterial } from "@mui/material";

const Modal = styled(ModalMaterial)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

type DividerProps = {
  theme?: Theme,
  direction?: "horizontal" | "vertical"
}

const Divider = styled("hr")<DividerProps>(({ theme, direction = "horizontal" }) => {
  const dividerStyles: any = {
    base: {
      position: "relative",
      right: "2px",
      zIndex: 0,
      border: `1px solid ${theme?.palette?.neutral?.[400]}`,
    },
    direction: {
      horizontal: {
        width: "100%"
      },
      vertical: {
        height: "100%"
      }
    }
  }

  return {
    ...dividerStyles.base,
    ...dividerStyles.direction[direction]
  }
});

type MenuItemProps = {
  theme?: Theme,
  active?: boolean
}

const MenuItem = styled("span")<MenuItemProps>(({ theme, active = false }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: active ? 700 : 400,
  fontSize: 14,
  color: active ? theme.palette.primary.blue : theme.palette.neutral?.[100],
  cursor: "pointer",
  borderRight: active ? `2px solid ${theme.palette.primary.blue}` : "none",
  zIndex: 1
}));

const Title = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue,
}));

const StepContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(6),
  borderRadius: 8,
  padding: theme.spacing(2)
}));

const StepImage = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: "relative",
  borderRadius: 12,
  backgroundColor: theme.palette.neutral?.[500],
  padding: theme.spacing(15)
}));

const StepTitle = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.primary.blue
}));

const StepDescription = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}));

const IconContent = styled(Box)(({ theme }) => ({
  position: "absolute",
  right: 10,
  bottom: 10,
  zIndex: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(1),
  backgroundColor: theme.palette.neutral?.white,
  borderRadius: "100%"
}));

const Image = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  margin: 'auto',
  filter: `drop-shadow(5px 3px 4px ${theme.palette.neutral?.[100]})`
}));

export {
  Modal,
  Divider,
  MenuItem,
  Title,
  StepContent,
  StepImage,
  StepTitle,
  StepDescription,
  IconContent,
  Image
}