import React from 'react';

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// import { Container } from './styles';

interface IAutoPlay {
  children: React.ReactElement | React.ReactElement[]
}

const AutoPlay: React.FC<IAutoPlay> = ({ children }) => {
  const [index, setIndex] = React.useState<number>(0);

  const handleChangeIndex = (value: number) => {
    setIndex(value);
  };

  return (
    <AutoPlaySwipeableViews
      index={index}
      onChangeIndex={handleChangeIndex}
    >
      {children}
    </AutoPlaySwipeableViews>
  );
}

export default AutoPlay;