import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
    login: string,
    email: string,
    name: string,
    group: string,
    sessionId: string,
    isEnabled: boolean,
    isSystemAdmin: boolean,
    parameters: {
        TrocarSenha: string,
        TwoFactorAuthentication: string;
        ShowWelcomeBigCenter: string,
        FileTransfer?: string,
    }
}

const initialState: UserState = {
    login: "",
    email: "",
    name: "",
    group: "",
    sessionId: "",
    isEnabled: false,
    isSystemAdmin: false,
    parameters: {
        TrocarSenha: "",
        TwoFactorAuthentication: "",
        ShowWelcomeBigCenter: "",
        FileTransfer: "",
    }
}


const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoginUserInfo: (state, action: PayloadAction<UserState>) => {
            return { ...state, ...action.payload }
        },
        
        setUserSessionId: (state, action: PayloadAction<string>) => {
            state.sessionId = action.payload;
        },
        setUserInitialState: (state) => {
            Object.assign(state, initialState);
        },
    }
});

export const {
    setLoginUserInfo,
    setUserSessionId,
    setUserInitialState
} = userSlice.actions;

export default userSlice.reducer;