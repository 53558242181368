import { Box, Tooltip } from "@mui/material";
import { Title, Hr } from "./styles";

import { BiHelpCircle } from "react-icons/bi";

interface ComponentProps {
  title: string,
  helpText: string
}

const Component: React.FC<ComponentProps> = ({
  title, helpText
}) => {
  return (
    <Box display="flex" alignItems="center" gap={5} py={4}>
      <Box display="flex" alignItems="center" gap={2}>
        <Title>{title}</Title>
        <Tooltip title={helpText}>
          <Box>
            <BiHelpCircle size={20} />
          </Box>
        </Tooltip>
      </Box>
      <Hr />
    </Box>
  )
}

export default Component