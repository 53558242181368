// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.view-user-container-opened{
    position: fixed;
    top: 0;
    right: 0;
    width: 530px;
    height: 100%;
    background-color: white;
    border-left: 1px solid #B1B9BD;
    transform: translateX(0);
    transition: transform .5s ease;
    z-index: 10000;
}

.view-user-container-closed{
    position: fixed;
    top: 0;
    right: 0;
    width: 530px;
    height: 100%;
    background-color: white;
    border-left: 1px solid #B1B9BD;
    transform: translateX(100%);
    transition: transform .5s ease;
    z-index: 10000;
}

@media screen and (max-width: 500px) {
    .view-user-container-opened{
        width: 100%;
    }
    
    .view-user-container-closed{
        width: 100%;
    }

}`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/ViewUser/viewUser.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,8BAA8B;IAC9B,wBAAwB;IACxB,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,QAAQ;IACR,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,8BAA8B;IAC9B,2BAA2B;IAC3B,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".view-user-container-opened{\n    position: fixed;\n    top: 0;\n    right: 0;\n    width: 530px;\n    height: 100%;\n    background-color: white;\n    border-left: 1px solid #B1B9BD;\n    transform: translateX(0);\n    transition: transform .5s ease;\n    z-index: 10000;\n}\n\n.view-user-container-closed{\n    position: fixed;\n    top: 0;\n    right: 0;\n    width: 530px;\n    height: 100%;\n    background-color: white;\n    border-left: 1px solid #B1B9BD;\n    transform: translateX(100%);\n    transition: transform .5s ease;\n    z-index: 10000;\n}\n\n@media screen and (max-width: 500px) {\n    .view-user-container-opened{\n        width: 100%;\n    }\n    \n    .view-user-container-closed{\n        width: 100%;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
