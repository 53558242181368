import { styled } from '@mui/system';
import ListItem from '@mui/material/ListItem';

const LastUsedItem = styled(ListItem)`

    
    
    &&{
        padding: 0;
        border: 1px solid #DDE7EA;
        border-radius: 8px;
        height: 100%;
        width: 100%;
        transition: all .5s ease-in-out;

        :hover{
            cursor: pointer;
            background: rgba(229, 239, 255, .2);
            border-radius: 16px;
            border: 1px solid rgba(229, 239, 255, 0.3);
            transition: all .5s ease;
        }
    }


    .MuiButtonBase-root{
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        align-self: stretch;
    }


    .MuiButtonBase-root:hover{
        background-color: transparent;
    }

   

`;

export default LastUsedItem;