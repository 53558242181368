import { Button } from '@mui/material';
import { styled } from '@mui/system';

const GoToSectionButton = styled(Button)`
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #0068FF;
    text-transform: none;
    transition: background-color .5s ease;
    padding: 8px 12px;

    :hover{
        padding: 8px 12px;
        background: #EDF2F4;
        border-radius: 8px;
        transition: background-color .5s ease;
    }
`;

export default GoToSectionButton;