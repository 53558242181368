import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface exportPdfInfo {
    open: boolean;
    isPrinting?: boolean;
    datasets: {};
    ignoreList: string[];
}


const initialState: exportPdfInfo = {
   open: false,
   isPrinting: false,
   datasets: {},
   ignoreList: [],
}

const exportPdf = createSlice({
  name: 'exportPdf',
  initialState: initialState,
  reducers: {
    setExportPdfInfo: (state, action: PayloadAction<exportPdfInfo>) => {
        return {...state, ...action.payload}
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setDatasets: (state, action: PayloadAction<{}>) => {
        state.datasets = action.payload
    },
    setIsPrinting: (state, action: PayloadAction<boolean>) => {
      state.isPrinting = action.payload
    },
    pushToIgnoreList: (state, action: PayloadAction<string>) => {
      state.ignoreList.push(action.payload)
    },
    removeFromIgnoreList: (state, action: PayloadAction<string>) => {
      state.ignoreList = state.ignoreList.filter((dataset: any) => dataset !== action.payload)
    }
  },
});

export const { setExportPdfInfo, setOpen, setDatasets, setIsPrinting, pushToIgnoreList, removeFromIgnoreList} = exportPdf.actions;

export default exportPdf.reducer;