import { Box } from "@mui/material"
import { Theme, styled } from "@mui/material/styles"

import { isDesktopWidth } from "@constants"

const Template = styled(Box)(({ theme }: { theme: Theme }) => {

    const mobileStyle = !!!isDesktopWidth ? {
        gridTemplateAreas: undefined,
        gridTemplateRows: undefined,
        gridTemplateColumns: '100vw',
        backgroundColor: theme.palette.neutral?.[500]
    }: ""

    return  ({
        position: 'relative',
        display: 'grid',
        gridTemplateAreas: `"nav header"
        "nav main"
        "nav footer"`,
        gridTemplateColumns: '94px calc(100% - 94px)',
        gridTemplateRows: '62px minmax(auto, 100%) 83px',
        width: '100vw',
        minHeight: '100vh',
        backgroundColor: theme.palette.neutral?.[500],
        ...mobileStyle
    })
    
})

export default Template