import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';


const CustomTokenKeyTextField = styled(({ ...props }: TextFieldProps) => (
    <TextField {...props} />
))`

    
   input{
        width: 421px;
        height: 24px;
        padding: 0;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        color: #7D8285;
        flex: none;
        order: 1;
        flex-grow: 0;
     }


     fieldset{ 
        display: none;
     }


`;

export default CustomTokenKeyTextField;