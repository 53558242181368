import { navbarCategories } from "@constants";

const findActiveCategory = () => {
    navbarCategories.find(category => category.active === true)
}

const setManagementCategoryActive = () => {
    const managementCategory = navbarCategories.find(category => category.name === 'Gestão')
    if(managementCategory){
        managementCategory.active = true
    }
}

export {findActiveCategory, setManagementCategoryActive}