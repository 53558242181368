import React from 'react';

import { Container } from './styles';
import { IconType } from 'react-icons';
import { IconButtonProps } from '@mui/material';

interface ComponentProps extends IconButtonProps {
    Icon: IconType,
    active: boolean,
}

const Component: React.FC<ComponentProps> = ({
    Icon,
    active
}) => {
    return <Container
        active={active}
        disableFocusRipple
        disableTouchRipple
        disableRipple
    >   
        <Icon
            className='icon'
            color={active ? '#006' : '#FFF'}
            size={24}
        />
    </Container>;
}

export default Component;