import { GeneralComponents, TextField } from '@components';
import { Box, SelectChangeEvent } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react';
import { Label, selectedButtonStyle } from './styles';

const SideSectionContent: React.FC = () => {

    const [fieldValues, setFieldValues] = useState({
        reportName: '',
        otherEmails: '',
        userName: '',
        defaultRange: 'none',
        filterBy: 'user',
        ReferenceMonthStart: '',
        ReferenceMonthEnd: '',
    })

    const handleReferenceMonthStartChange = (ReferenceMonthStart: Dayjs | null) => {
        if (ReferenceMonthStart) {
            setFieldValues({ ...fieldValues, ReferenceMonthStart: dayjs(ReferenceMonthStart).format('DD/MM/YYYY') })
        } else {
            setFieldValues({ ...fieldValues, ReferenceMonthStart: '' })
        }
    }

    const handleReferenceMonthEndChange = (ReferenceMonthEnd: Dayjs | null) => {
        if (ReferenceMonthEnd) {
            setFieldValues({ ...fieldValues, ReferenceMonthEnd: dayjs(ReferenceMonthEnd).format('DD/MM/YYYY') })
        } else {
            setFieldValues({ ...fieldValues, ReferenceMonthEnd: '' })
        }
    }

    const handleButtonGroupChanges = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name } = event.currentTarget
        const parentId = event.currentTarget.parentElement?.id

        if (parentId) {
            setFieldValues({ ...fieldValues, [parentId]: name })
        }
    }

    const handleTextfieldChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target
        setFieldValues({ ...fieldValues, [name]: value })
    }

    return <Box
        display={'flex'}
        flexDirection={'column'}
        gap={'16px'}
    >

        <GeneralComponents.CustomTextField
            labelText='Nome do relatório'
            placeholder='Insira o nome do relatório'
            name='reportName'
            value={fieldValues.reportName}
            onChange={handleTextfieldChanges}
            fullWidth
        />
        <GeneralComponents.CustomTextField
            labelText='Emails para recebimento'
            placeholder='Insira os emails separados por ;'
            name='otherEmails'
            value={fieldValues.otherEmails}
            onChange={handleTextfieldChanges}
            fullWidth
        />
        <GeneralComponents.CustomTextField
            labelText='Usuário ou domínio do relatório'
            placeholder='Insira o usuário ou domínio'
            name='userName'
            value={fieldValues.userName}
            onChange={handleTextfieldChanges}
            fullWidth
        />
        <Box
            display='flex'
            flexDirection={'column'}
            gap={'4px'}
        >
            <Label>Filtrar por</Label>
            <GeneralComponents.CustomButtonGroup
                sx={{
                    width: 'fit-content'
                }}
                id='filterBy'
            >
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.filterBy === 'user' && selectedButtonStyle }}
                    name={'user'}
                    onClick={handleButtonGroupChanges}
                >
                    Usuário
                </GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.filterBy === 'domain' && selectedButtonStyle }}
                    name={'domain'}
                    onClick={handleButtonGroupChanges}
                >
                    Dominío
                </GeneralComponents.CustomGroupedButton>
            </GeneralComponents.CustomButtonGroup>
        </Box>
        <Box
            display='flex'
            flexDirection={'column'}
            gap={'4px'}
        >
            <Label>Intervalo de interesse</Label>
            <GeneralComponents.CustomButtonGroup
                sx={{
                    width: 'fit-content'
                }}
                id='defaultRange'
            >
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.defaultRange === 'none' && selectedButtonStyle }}
                    name={'none'}
                    onClick={handleButtonGroupChanges}
                >
                    Nenhum
                </GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.defaultRange === 'lastDay' && selectedButtonStyle }}
                    name={'lastDay'}
                    onClick={handleButtonGroupChanges}
                >
                    Último dia
                </GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.defaultRange === 'lastWeek' && selectedButtonStyle }}
                    name={'lastWeek'}
                    onClick={handleButtonGroupChanges}
                >
                    Última semana
                </GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton
                    sx={{ ...fieldValues.defaultRange === 'lastMonth' && selectedButtonStyle }}
                    name={'lastMonth'}
                    onClick={handleButtonGroupChanges}
                >
                    Último mês
                </GeneralComponents.CustomGroupedButton>
            </GeneralComponents.CustomButtonGroup>
        </Box>
        <LocalizationProvider adapterLocale={'pt-br'} dateAdapter={AdapterDayjs}>
            <GeneralComponents.CustomDatePickers
                LabelText='Intervalo de interesse personalizado'
                startValue={fieldValues.ReferenceMonthStart}
                endValue={fieldValues.ReferenceMonthEnd}
                handleStartChange={handleReferenceMonthStartChange}
                handleEndChange={handleReferenceMonthEndChange}
                handleFieldErrors={() => { }}
                views={['day', 'month', 'year']}
                format='DD/MM/YYYY'
                variant='default'
            />
        </LocalizationProvider>
    </Box>;
}

export default SideSectionContent;