import { GridColDef } from "@mui/x-data-grid";
import { BankSlips, BillingComponents, GeneralComponents } from "@components"
import dayjs from "dayjs";
import { isInvoiceOverdue } from "@utils";
import { isDesktopWidth } from "./screenWidth";
import { width } from "@mui/system";

const listInvoicesColumns: GridColDef[] =

    isDesktopWidth ? [
        {
            field: 'ReferenceMonth',
            headerName: 'Mês de referência',
            flex: 12.5,
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-br').format('MMMM [de] YYYY')
        },
        {
            field: 'InvoiceNumber',
            headerName: 'Número', flex: 12.5,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value,
            flex: 12.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'BankSlips',
            headerName: 'Boleto bancário',
            flex: 12.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => {
                if (params.row.Status !== 'PAID' && params.row.Status !== 'CANCELLED') {
                    return <BankSlips.InstallmentsButton params={params} />
                } else if (params.row.Status === 'PAID') {
                    return 'Regularizado'
                } else if (params.row.Status === 'CANCELLED') {
                    return 'Cancelado'
                }
            }
        },
        {
            field: 'InvoiceFile',
            headerName: 'Nota fiscal',
            flex: 12.5, headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDownloadCell params={params} />
        },
    ]

    :
    window.screen.width < 390 ?
    [
        {
            field: 'ReferenceMonth',
            headerName: 'Data',
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-br').format('MMM/YY'),
            width: 124,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value,
            headerAlign: 'center',
            width: 75,
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'invoiceDetails',
            type: 'actions',
            headerName: 'Detalhes',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDetailsCell params={params} />,
            width: 80
        }
    ]
    :
    [
        {
            field: 'ReferenceMonth',
            headerName: 'Data',
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-br').format('MMM/YY'),
            flex: 33
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value,
            headerAlign: 'center',
            flex: 33,
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'invoiceDetails',
            type: 'actions',
            headerName: 'Detalhes',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDetailsCell params={params} />,
            flex: 33
        }
    ]

const listInvoicesColumnsWithoutInstallments: GridColDef[] =

    isDesktopWidth ? [
        {
            field: 'ReferenceMonth',
            headerName: 'Data',
            flex: 12.5,
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-BR').format('MMMM [de] YYYY'),
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'InvoiceNumber',
            headerName: 'Número',
            flex: 12.5,
            headerAlign: 'center',
            align: 'center'
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value, flex: 12.5,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'InvoiceFile',
            headerName: 'Nota fiscal',
            flex: 12.5, headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDownloadCell params={params} />
        },
    ]
    :
    window.screen.width < 390 ?
    [
        {
            field: 'ReferenceMonth',
            headerName: 'Data',
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-br').format('MMM/YY'),
            width: 124,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value,
            headerAlign: 'center',
            width: 70,
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'invoiceDetails',
            type: 'actions',
            headerName: 'Detalhes',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDetailsCell params={params} />
        }
    ]
    :
    [
        {
            field: 'ReferenceMonth',
            headerName: 'Data',
            valueFormatter: (params) => dayjs(params.value, 'MM/YYYY', 'pt-br').format('MMM/YY'),
            flex: 33,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'Status',
            headerName: 'Status',
            valueGetter: (params) => isInvoiceOverdue(params) ? 'OVERDUE' : params.value,
            headerAlign: 'center',
            flex: 33,
            align: 'center',
            renderCell: (params) => <GeneralComponents.StatusCell params={params} />
        },
        {
            field: 'invoiceDetails',
            type: 'actions',
            headerName: 'Detalhes',
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => <BillingComponents.InvoiceDetailsCell params={params} />,
            flex: 33
        }
    ]




export { listInvoicesColumns, listInvoicesColumnsWithoutInstallments }