import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#type-options',
    title: 'Bem-vindo à consulta pontual!',
    content: 'Nesta área é possível gerar uma nova consulta, além de selecionar os datasets, customizar os atributos e exportar seu resultado.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: true
  },
  {
    target: '#option-new',
    title: 'Nova consulta',
    content: 'Inicie uma nova consulta do zero passando por todos os passos de configurações.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-end' },
    spotlightPadding: 10,
    disableScrolling: true
  },
  {
    target: '#option-saved',
    title: 'Consulta salva',
    content: 'Utilize uma consulta previamente salva.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-end' },
    spotlightPadding: 10,
    disableScrolling: true
  }
];

export default steps;