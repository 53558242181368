// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.internal-page-container{
    display: flex;
    flex-direction: column;
    padding: 32px 24px 0px 24px;
    gap: 32px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    
}

.internal-page-presentation-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.internal-page-title{
    font-family: 'Picadilly';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 54px;
    color: #000066;
}

.internal-page-paragraph{
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #555866;
}

@media screen and (max-width: 500px) {
    .internal-page-container{
        width: 100vw;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/InternalPages/internalPages.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,kBAAkB;;AAEtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".internal-page-container{\n    display: flex;\n    flex-direction: column;\n    padding: 32px 24px 0px 24px;\n    gap: 32px;\n    width: 100%;\n    overflow-y: auto;\n    overflow-x: hidden;\n    \n}\n\n.internal-page-presentation-container{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 100%;\n}\n\n.internal-page-title{\n    font-family: 'Picadilly';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 31px;\n    line-height: 54px;\n    color: #000066;\n}\n\n.internal-page-paragraph{\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    color: #555866;\n}\n\n@media screen and (max-width: 500px) {\n    .internal-page-container{\n        width: 100vw;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
