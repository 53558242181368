import { handleErrors } from "@utils";
import { apis } from "@constants";

interface APISPROPS {
  NAME: string,
  ENVIROMENTS: {
    [key: string]: string
  },
  HANDLE_ERRORS: (response: any) => void,
  SEND_LOGS?: (data: any) => void
}

const APIS: Array<APISPROPS> = [
  {
    NAME: "BILLINGJEAN",
    ENVIROMENTS: {
      DEVELOPMENT: apis.BILLINGJEAN.DEVELOPMENT,
      HML: apis.BILLINGJEAN.HML,
      PRODUCTION: apis.BILLINGJEAN.PRODUCTION,
    },
    HANDLE_ERRORS: (apiResponse: any) => {
      handleErrors.BillingError(apiResponse)
    },
    SEND_LOGS: async (data: any) => {
      console.log("LOGS", data)
    }
  },
  {
    NAME: "BIGBROTHER",
    ENVIROMENTS: {
      DEVELOPMENT: apis.BIGBROTHER.DEVELOPMENT,
      HOMOLOGATION: apis.BIGBROTHER.PRODUCTION,
      PRODUCTION: apis.BIGBROTHER.PRODUCTION,
    },
    HANDLE_ERRORS: (apiResponse: any) => {
      handleErrors.BigBrotherError(apiResponse)
    },
    SEND_LOGS: async (data: any) => {
    }
  },
  {
    NAME: "FILETRANSFER",
    ENVIROMENTS: {
      DEVELOPMENT: apis.FILETRANSFER.DEVELOPMENT,
      HOMOLOGATION: apis.FILETRANSFER.HML,
      PRODUCTION: apis.FILETRANSFER.PRODUCTION,
    },
    HANDLE_ERRORS: () => console.log("HANDLE ERROR"),
    SEND_LOGS: async (data: any) => {
      console.log("LOGS", data)
    }
  },
  {
    NAME: "PLATAFORMA",
    ENVIROMENTS: {
      DEVELOPMENT: apis.PLATAFORMA.DEVELOPMENT,
      HOMOLOGATION: apis.PLATAFORMA.HML,
      PRODUCTION: apis.PLATAFORMA.PRODUCTION,
    },
    HANDLE_ERRORS: (apiResponse: any) => {
      handleErrors.PlatformError(apiResponse)
    }
  },
  {
    NAME: "BIGID",
    ENVIROMENTS: {
      DEVELOPMENT: apis.BIGID.DEVELOPMENT,
      HOMOLOGATION: apis.BIGID.HML,
      PRODUCTION: apis.BIGID.PRODUCTION,
    },
    HANDLE_ERRORS: (apiResponse: any) => handleErrors.BigIdError(apiResponse),
  },
  {
    NAME: "PLUGIN",
    ENVIROMENTS: {
      DEVELOPMENT: apis.PLUGIN.DEVELOPMENT,
      HOMOLOGATION: apis.PLUGIN.HML,
      PRODUCTION: apis.PLUGIN.PRODUCTION,
    },
    HANDLE_ERRORS: (apiResponse: any) => handleErrors.PluginError(apiResponse),
  }
]

export default APIS;