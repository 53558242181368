import { Grid } from '@mui/material';
import React from 'react';

interface ComponentProps {
  children: any
  ref?: any
}

const Component: React.FC<ComponentProps> = ({
  children,
  ref
}) => {
  return <Grid
    container
    item
    columnGap={4}
    rowGap={2}
    ref={ref}
    height={'150px'}
  >
    {children}
  </Grid>;
}

export default Component;