import api from "./interceptors/bodySession";
import APIS from "./apis";

const API = APIS.find(API => API.NAME === "FILETRANSFER");

const BASE_URL = API?.ENVIROMENTS;

const uploadFile = async (data: any) => api.post(`${BASE_URL?.HML}/file/uploadfile`, data);

const listFiles = async (data: any) => api.post(`${BASE_URL?.HML}/file/listfiles`, data);

const getFile = async (data: any) => api.post(`${BASE_URL?.HML}/file/getfile`, data);

const deleteFile = async (data: any) => api.post(`${BASE_URL?.HML}/file/deletefile`, data);

const generateFolderTemplate = async (data: any) => api.post(`${BASE_URL?.HML}/user/generatefoldertemplate`, data);

export {
    uploadFile,
    listFiles,
    getFile,
    deleteFile,
    generateFolderTemplate
}