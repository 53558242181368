import { reducers } from '@store';
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const Auth: React.FC = () => {
  const { parameters, sessionId } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user);
  const location = useLocation();

  const isAuthenticated = !!!parameters?.TrocarSenha && !!sessionId;

  if(!!isAuthenticated && !!location?.state?.key) {
    return <Navigate to={location?.state?.key} state={{key: ""}}/>
  }

  if(!!isAuthenticated){
    return <Navigate to='/dashboard'/>
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_API_RECAPTCHA_SITE_KEY ?? ""}>
      <Outlet />
    </GoogleReCaptchaProvider>
  )
}

export default Auth;