const nextButtonStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 12px',
    background: '#FFFFFF',
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
    border: 'none',
    color: '#0068FF',
    borderRadius: '8px',
    outline: 'none'
}

const backButtonStyle: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#FFFFFF',
    background: 'none',
    outline: 'none'
}


const skipButtonStyle: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#FFFFFF',
    background: 'none',
    outline: 'none'
}

const tooltipBody: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '24px',
    background: '#1D203A',
    borderRadius: '8px',
    minWidth: '530px',
    maxWidth: 'fit-content'
}

const tooltipBodyMobile: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    gap: '16px',
    background: '#1D203A',
    borderRadius: '8px',
    minWidth: '340px',
    maxWidth: 'fit-content'
}

const tooltipTitle: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    textAlign: 'left',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '140%',
    color: '#FFFFFF'
}

const tooltipContent: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    textAlign: 'left',
    padding: '0',
    paddingTop: '24px',
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '140%',
    color: '#DDE7EA'
}

const tooltipFooter: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px',
    gap: '32px',
  };

const closeButton: React.CSSProperties = {
    color: '#DADEE0'
}

export { nextButtonStyle, skipButtonStyle, tooltipBody, tooltipBodyMobile, tooltipTitle, tooltipContent, backButtonStyle, tooltipFooter, closeButton }