import React, { ReactNode } from "react"

interface ContainerProps {
  children: ReactNode
}

const Component: React.FC<ContainerProps> = ({ children }) => {
  return (
    <>
      {children}
    </>
  )
}

export default Component