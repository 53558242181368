import { Player } from '@lottiefiles/react-lottie-player';
import { Box, Typography, Grow } from '@mui/material';
import React from 'react';

import Animation from './animation.json'
import { Spinner } from '@components';
// import { Container } from './styles';

const subtitles = [
    "Lendo as imagens",
    "Checando a qualidade",
    "Identificando possível similaridade",
    "Estruturando resultados",
    "Organizando informações"
]

const Loading: React.FC = () => {

    const [subtitle, setSubtitle] = React.useState(subtitles[0]);
    const [rotate, setRotate] = React.useState(0);

    React.useEffect(() => {
        updateLoading();
    }, [subtitle]);

    const updateLoading = () => {
        setTimeout(() => {
            const index = subtitles.findIndex((element) => element === subtitle);
            const nextIndex = index === subtitles.length - 1 ? 0 : index + 1;
            const nextRotate = rotate === 0 ? 90 : 0;

            setSubtitle(subtitles[nextIndex]);
            setRotate(nextRotate)
        }, 2000);
    }



    return <Box
        width={'100%'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
    >
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            gap={2}
            width={'100%'}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                gap={4}
                width={'100%'}
            >
                <Typography variant='h2'>Verificando imagens</Typography>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={4} width={'100%'}>
                    <Spinner rotate={rotate} />
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        gap={4}
                        position={'relative'}
                        width={'100%'}
                    >
                        {subtitles.map((text, index) => {

                            const show = text === subtitle

                            return <Grow
                                in={show}
                                style={{ transformOrigin: '0 0 0', width: 'fit-content' }}
                                {...(show ? { timeout: 1000 } : {})}

                            >
                                <Box position={'absolute'}>
                                    <Typography variant='subtitle1'>{subtitle}</Typography>
                                </Box>
                            </Grow>

                        }
                        )}

                    </Box>
                </Box>
            </Box>
            <Box width={'400px'} height={'400px'}>
                <Player
                    src={Animation}
                    autoplay
                    speed={1}
                    loop
                />
            </Box>
        </Box>
    </Box>;
}

export default Loading;