import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface TokenManagementFilter {
  tokenId: string,
  dtCreateStart: string,
  dtCreateEnd: string,
  dtExpirationStart: string,
  dtExpirationEnd: string,
  status: string,
  limit: number,
  skip: number,
  seeDomain?: string
  domain?: string
}
  
const initialState: TokenManagementFilter = {
  tokenId: '',
  dtCreateStart: '',
  dtCreateEnd: '',
  dtExpirationStart: '',
  dtExpirationEnd: '',
  status: 'all',
  limit: 10,
  skip: 0,
  seeDomain: 'false',
  domain: ''
}

const tokenManagementFilterSlice = createSlice({
    name: 'tokenManagementFilter',
    initialState,
    reducers: {
      setFilterInfo: (state, action: PayloadAction<TokenManagementFilter>) => {
        return { ...state, ...action.payload } 
      },
      resetFilterInfo: (state) =>{
        Object.assign(state, initialState)
      }
    }
});

export const { setFilterInfo,  resetFilterInfo } = tokenManagementFilterSlice.actions;

export default tokenManagementFilterSlice.reducer;