import { ReactNode } from "react";

import { TBody } from "./styles";

type BodyProps = {
  children: ReactNode
}

const Body: React.FC<BodyProps> = ({ children }) => {
  return (
    <TBody>
      {children}
    </TBody>
  )
}

export default Body