import React, { useEffect, useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import { backButtonStyle, closeButton, nextButtonStyle, skipButtonStyle, tooltipBody, tooltipBodyMobile, tooltipContent, tooltipFooter, tooltipTitle } from '../../../../../styles/onboardingTooltip'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { reducers, slices } from "@store";
import { isDesktopWidth } from '@constants';

const tourSteps: Step[] = [
    {
        target: '.file-transfer-table-container',
        title: 'Tranferência de arquivos',
        content: 'Visualize, envie e baixe seus arquivos de forma simples, fácil e rápida.',
        disableBeacon: true,
        floaterProps: { placement: 'center' },
    },
    {
        target: '.file-transfer-table-container',
        title: 'Visualize seus arquivos de saída',
        content: 'Utilize a tabela para visualizar, ordenar e baixar seus arquivos.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
    {   
        target: '.file-transfer-filter-container',
        title: 'Filtre pelo que interessa',  
        content: 'Utilize a barra de filtros para selecionar os arquivos do seu interesse.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
   
    {
        target: '.file-transfer-upload-button',
        title: 'Faça o upload dos seus arquivos',
        content: 'Utilize a seção de upload de arquivos para enviá-los à pasta que deseja. Para a sua segurança, todos os dados são temporários e excluídos em até 30 dias.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
    
    {
        target: '.file-transfer-table-container',
        title: 'Tour finalizado',
        content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
        disableBeacon: true,
        showProgress: false,
        floaterProps: { placement: 'center' }
    },
];

const FileTransferOnboarding: React.FC = () => {
    const { showOnboardingTooltip, onboardingLocation } = useSelector((state: reducers.RootState) => state.onboardingTooltipReducer)
    const [runTour, setRunTour] = useState<boolean>(showOnboardingTooltip)
    const [firstTimeAtFileTransfer, setFirstTimeAtFileTransfer] = useState<boolean>()
    const firstOnboardingControl = localStorage.getItem('onboardingControl')
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        if(firstOnboardingControl){
            const firstOnboardingControlObj = JSON.parse(firstOnboardingControl)
            setFirstTimeAtFileTransfer(firstOnboardingControlObj['FileTransfer'])
        }
    }, [firstOnboardingControl])


    useEffect(() => {
        if (showOnboardingTooltip && onboardingLocation === location.pathname) {
            setRunTour(true)
        }
    }, [showOnboardingTooltip]);

    const handleFirstOnboardingTourEnd = () => {
        if(firstOnboardingControl){
            var firstOnboardingControlObj = JSON.parse(firstOnboardingControl)
            firstOnboardingControlObj['FileTransfer'] = false
            localStorage.setItem('onboardingControl', JSON.stringify(firstOnboardingControlObj))
        }
    }

    const handleTourEnd = (data: CallBackProps) => {
        if (data.index === tourSteps.length - 1 || data.action === 'reset') {
            handleFirstOnboardingTourEnd()
            setRunTour(false)
            dispatch(slices.onboardingTooltip.setOnboardingTooltipInfo({ showOnboardingTooltip: false, onboardingLocation: '' }))
        }
    };

    return <Joyride
        styles={{
            buttonNext: nextButtonStyle,
            buttonSkip: skipButtonStyle,
            tooltip: isDesktopWidth ? tooltipBody : tooltipBodyMobile,
            tooltipTitle: tooltipTitle,
            tooltipContent: tooltipContent,
            buttonBack: backButtonStyle,
            tooltipFooter: tooltipFooter,
            buttonClose: closeButton,
            options: {arrowColor: '#1D203A'}
        }}
        
        scrollToFirstStep={false}
        showSkipButton
        steps={tourSteps}
        floaterProps={{ placement: 'bottom-start' }}
        run={(runTour || firstTimeAtFileTransfer) ?? false}
        continuous={true}
        locale={{ back: 'Voltar', close: 'Fechar', last: 'Finalizar tour', next: 'Continuar', skip: 'Pular tour' }}
        callback={handleTourEnd}
    ></Joyride>;
}

export default FileTransferOnboarding;

