import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DisableTokenInfo {
    tokenId: string,
    login: string,
    wasConfirmed: boolean
}

const initialState: DisableTokenInfo = {
    tokenId: '',
    login: '',
    wasConfirmed: false
}

const disableTokenId = createSlice({
    name: 'disableTokenId',
    initialState,
    reducers: {
        setDisableTokenIdInfo: (state, action: PayloadAction<DisableTokenInfo>) => {
            return { ...state, ...action.payload }
        }
    }
});

export const { setDisableTokenIdInfo } = disableTokenId.actions;

export default disableTokenId.reducer;