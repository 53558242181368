import React from 'react';
import './form.css';

type FormProps = {
    children: React.ReactNode;
};

export default function FormBox(props: FormProps) {
  return (
    <div className="form-container">
      <div className="form-content">
        {props.children}
      </div>
    </div>
  );
}
