import * as yup from "yup";

export default yup.object().shape({
  datasets: yup.object()
    .test(
      "datasets",
      "Selecione ao menos 1 dataset",
      (value) => {
        const isValid = Object.values(value || {}).some((dataset: any) => !!dataset.selected);

        return isValid;
      })
})