import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface OnboardingTooltipInfo {
    showOnboardingTooltip: boolean,
    onboardingLocation: string
}

const initialState: OnboardingTooltipInfo = {
    showOnboardingTooltip: false,
    onboardingLocation: ''
}

const onboardingTooltipInfo = createSlice({
    name: 'onboardingTooltipInfo',
    initialState,
    reducers: {
        setOnboardingTooltipInfo: (state, action: PayloadAction<OnboardingTooltipInfo>) => {
           return {...state, ...action.payload}
        }
    }
});

export const { setOnboardingTooltipInfo } = onboardingTooltipInfo.actions;

export default onboardingTooltipInfo.reducer;