import { Theme, ToggleButtonGroup, styled } from "@mui/material";



const Container = styled(ToggleButtonGroup)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    gap: theme?.spacing(2),
    '&.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type)': {
        borderLeft: 'unset',
        marginLeft: 'unset'
    }
}))

export default Container