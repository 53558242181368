import React from "react";

import { Notification } from "@components";
import { useFetch } from "@hooks";
import { Plugins } from "@services";
import View from "../View";

import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";

type ComponentProps = {
  file: string,
  index: number,
  documentIndex: number
};

const Component: React.FC<ComponentProps> = ({ file, index, documentIndex }) => {

  const dispatch = useDispatch();

  const { qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfDocumentoscopia);

  const { data, isLoading, error, isFetched } = useFetch(
    Plugins.GetImageQuality,
    {
      image: file
    }
  );

  React.useEffect(() => {
    const isAvailable = !!data && !!!isLoading;

    const qualityImageFound = !!(qualityImage.find((item: any) => item.documentIndex === documentIndex && item.index === index));

    if (qualityImageFound) {
      const newQualityImage = qualityImage.map((item: any) => (
        item.documentIndex === documentIndex && item.index === index ?
          {
            ...item,
            data,
            error,
            isLoading,
            isFetched,
            isAvailable
          }
          : item
      ))

      dispatch(slices.exportPdfDocumentoscopia.setQualityImage(newQualityImage));

      return
    }

    dispatch(slices.exportPdfDocumentoscopia.setQualityImage([...qualityImage, { documentIndex, index, data, isLoading, isFetched, isAvailable, error }]));

  }, [data, isLoading]);

  if (!!error) {

    return (
      <Notification
        variant="danger"
        title="Ocorreu um problema com a verificação da qualidade da imagem."
        description=" Por favor, contate nossa equipe de suporte."
      />
    )
  }

  return (
    <View data={data} />
  )
}

export default Component;