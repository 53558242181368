import React, { ReactNode, useMemo } from "react"

import { Box } from "@mui/material";

import { FormProvider } from "react-hook-form";

import { reducers, slices } from "@store";
import { useSelector, useDispatch } from 'react-redux';
import { Stepper } from "@components";

interface StepsProps {
  children: ReactNode[],
  handleSubmit: (data?: any, event?: any) => any,
  stepper?: boolean
}

interface ChildProps {
  index: Number
}

interface onSubmitProps {
  (data?: any, event?: any): void
}

const Component: React.FC<StepsProps> = ({ children, handleSubmit, stepper = false }) => {
  const dispatch = useDispatch();

  const { step: actualStep, data: prevData } = useSelector((state: reducers.RootState) => state.formWizard);
  const lastStep = actualStep === (children?.length - 1);

  dispatch(slices.formWizard.isLastStep(lastStep))


  const onSubmit: onSubmitProps = (data?, event?) => {
    if (lastStep) {
      handleSubmit(event, { ...prevData, ...data })
      return
    }

    dispatch(slices.formWizard.next({ ...data }))
  }



  const steps = children.map((child, index) => {

    const step: any = child as React.ReactElement<React.PropsWithChildren<ChildProps>>;

    const label = step?.props?.label ?? ''
    const completed = !!(actualStep > (index + 1))

    return ({
      label,
      completed
    })

  })


  const handleStep = (index: number) => {
    if (actualStep !== index && index < actualStep) {
      return dispatch(slices.formWizard.goStep(index))
    }

    return
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6}>
        <Box>
          {
            !!stepper && (
              <Stepper
                activeStep={actualStep}
                steps={steps}
                handleStep={handleStep}
              />
            )
          }
        </Box>
        {
          React.Children.map(children, (child, index) => {

            if (actualStep < index) return;

            if (actualStep > index) return child;

            const step: any = child as React.ReactElement<React.PropsWithChildren<ChildProps>>;

            if (actualStep === index && !!!step) {
              dispatch(slices.formWizard.next({}));
              return;
            }

            const { handleSubmit, submit, quickSubmit, review, keyToAgroupValues, ...form } = step?.props;

            return (
              <FormProvider {...form}>
                <form
                  onSubmit={
                    review ?
                      (event) => onSubmit(null, event)
                      :
                      handleSubmit((data: any, event: any) => {

                        onSubmit(data, event);

                        if (!!submit)
                          submit(data, event);

                      })}
                >
                  <Box display="flex" flexDirection="column" gap={6}>
                    {step.props.children && React.cloneElement(step, { index: index })}
                  </Box>
                </form>
              </FormProvider>
            )
          })
        }
      </Box>
    </>
  )
}

export default Component