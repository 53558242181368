// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.financial-management-main-container{
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    gap: 24px;
    overflow-y: auto;
}

.financial-management-presentation-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
}

.financial-management-presentation-details-container{
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 16px;
}

.financial-management-title{
    font-family: 'Picadilly';
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 54px;
    color: #000066;
}

.financial-management-paragraph{
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #555866;
}

@media screen and (max-width: 500px){
    .financial-management-main-container{
        grid-column: 1;
        width: 100vw;
        overflow-y: unset;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/FinancialManagement/financialManagement.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,wBAAwB;IACxB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,cAAc;QACd,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".financial-management-main-container{\n    display: flex;\n    flex-direction: column;\n    padding: 32px 24px;\n    gap: 24px;\n    overflow-y: auto;\n}\n\n.financial-management-presentation-container{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 0px;\n    gap: 12px;\n}\n\n.financial-management-presentation-details-container{\n    display: flex;\n    align-items: center;\n    padding: 0px;\n    gap: 16px;\n}\n\n.financial-management-title{\n    font-family: 'Picadilly';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 31px;\n    line-height: 54px;\n    color: #000066;\n}\n\n.financial-management-paragraph{\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    color: #555866;\n}\n\n@media screen and (max-width: 500px){\n    .financial-management-main-container{\n        grid-column: 1;\n        width: 100vw;\n        overflow-y: unset;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
