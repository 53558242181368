import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';

const StyledDataGridWithoutPagination = styled(DataGrid)`
    border: none;
    font-family: 'Be Vietnam', sans-serif;
    
    .MuiDataGrid-columnHeaders{
      background-color: #f0f8fa;
      min-height: 45px;
      max-height: 45px;
    }

    .MuiDataGrid-row{
      min-height: 40px;
      max-height: 60px;
      border-bottom: 1px solid #f0f8fa;
      color: #666;
    }

    .MuiDataGrid-columnHeaderTitle{
      font-family: 'Be Vietnam';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 140%;
      color: #000066;
    }

    .MuiDataGrid-cell{
      border-bottom: none;
    }

    .MuiDataGrid-row:hover{
      background-color: #f0f8fa;
    }

    .MuiDataGrid-footerContainer{
      display: grid,
      grid-template-columns: 30% 30% 40%;
      justify-sontent: flex-start;
    }

    .MuiDataGrid-selectedRowCount{
      grid-column: 2;
      justify-self: flex-start;
    }

    .MuiDataGrid-footerContainer{
      display: none;
    }
`


export { StyledDataGridWithoutPagination };