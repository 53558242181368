import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#informations-consult',
    title: 'Revise os dados',
    content: 'Tenha uma visão clara da configuração selecionada e edite os dados da sua consulta.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-end' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#save-consult',
    title: 'Salve configurações',
    content: 'Guarde a configuração selecionada para consultas futuras.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#cost-consult',
    title: 'Revise o preço da consulta',
    content: 'Estime o custo com base na API e nos datasets selecionados.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;