import { styled } from "@mui/material/styles"

import { Chip as ChipComponent } from "@components";

const Title = styled("h1")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

const Chip = styled(ChipComponent)(({ theme }) => ({
  fontWeight: 700,
  backgroundColor: theme.palette.success.lightest,
  color: theme.palette.success.dark
}));

export {
  Title,
  Chip
}