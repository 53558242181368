import { ReactNode } from "react";
import { Chip } from "./styles";

interface ChipProps {
  children: ReactNode | string,
  fontSize?: number
}

const Component = ({
  children,
  fontSize = 12,
  ...props
}: ChipProps) => {
  return (
    <Chip
      fontSize={fontSize}
      {...props}
    >
      {children}
    </Chip>
  )
}

export default Component;