import React from "react";

import { Box, Collapse } from "@mui/material";
import { CheckboxTest } from "@components";

import { BiCaretDown, BiCaretRight } from "react-icons/bi";

interface ComponentProps {
  value: string,
  label: string,
  name: string,
  isSelectable: boolean,
  attributes: Record<string, any>
}

const Component: React.FC<ComponentProps> = ({
  value,
  label,
  name,
  isSelectable = false,
  attributes = {}
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const attributesTransformed: [string, any][] = Object.entries(attributes).filter(([, value]) => value.isFieldSelectable);

  const CollapseIcon = expanded ? BiCaretDown : BiCaretRight;

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  if (!isSelectable) {
    return <></>
  };

  return (
    <Box pl={2}>
      <Box display="flex" alignItems="center" gap={1}>
        {
          !!attributesTransformed.length ? (
            <CollapseIcon size={12} onClick={handleExpand} />
          ) : (
            <Box width={12} />
          )
        }
        {
          (
            <CheckboxTest.Item
              label={label}
              value={value}
            />
          )
        }
      </Box>
      <Collapse in={expanded} timeout="auto">
        {
          attributesTransformed.map(([key, value]) => {
            return (
              <CheckboxTest.Container name={name}>
                <Component
                  value={key}
                  label={value?.Name?.["pt-br"]}
                  name={`${name}.${key}.content`}
                  isSelectable={value?.IsFieldSelectable}
                  attributes={value?.InnerObjects}
                />
              </CheckboxTest.Container>
            )
          })
        }
      </Collapse>
    </Box>
  )
}

export default Component