import React from "react";

import { Box } from "@mui/material";
import { CheckboxTest, Dialog } from "@components";

import { FormProvider, useForm, useWatch } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";

interface ComponentProps {
  openDialog: boolean,
  setOpenDialog: any,
  onCancelDialog?: () => void,
  onConfirmDialog?: () => void
}

const Component: React.FC<ComponentProps> = ({ openDialog, onCancelDialog, onConfirmDialog }) => {
  const dispatch = useDispatch();

  const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user);

  const { control, ...form } = useForm({
    defaultValues: {
      askAgain: {
        active: { selected: false }
      },
    }
  });

  const askAgain = useWatch({
    control,
    name: "askAgain"
  });

  const handleCancelDialog = () => {
    onCancelDialog && onCancelDialog();
  }

  const handleConfirmDialog = () => {
    dispatch(slices.qualityImageDialog.setDismissDialog({ dismissDialog: askAgain.active.selected, user: login }));

    onConfirmDialog && onConfirmDialog();
  }

  return (
    <Dialog
      open={openDialog}
      title="Desativar qualidade de imagem"
      confirmText="Desativar"
      cancelText="Cancelar"
      onCancel={handleCancelDialog}
      onConfirm={handleConfirmDialog}
    >
      <Box display="flex" flexDirection="column" gap={5}>
        <span>
          Deseja <b>realmente</b> desativar a qualidade de imagem?
        </span>
        <Box display="flex" justifyContent="flex-end">
          <FormProvider
            control={control}
            {...form}
          >
            <CheckboxTest.Container name={`askAgain`} fullWidth={false}>
              <CheckboxTest.Item value="active" label="Não perguntar novamente" />
            </CheckboxTest.Container>
          </FormProvider>
        </Box>
      </Box>
    </Dialog>
  );
}

export default Component;