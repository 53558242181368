import Login from "./Login";
import HealthCheck from "./HealthCheck";
import Home from "./Dashboard";
import CreatePassword from "./CreatePassword";
import TokenManagement from "./TokenManagement";
import UserManagement from "./UserManagement";
import {
    CurrentUsage,
    Invoices,
    UsageSummary,
    DetailedUsageSummary
} from "./FinancialManagement"
import {
    PlatformReports,
    PontualConsult
} from "./Platform";
import NotFound from "./NotFound";
import FileTransfer from "./Tools/FileTransfer";
import DocUpdate from "./Tools/DocUpdate";
import Help from "./Help";
import {
    Facematch,
    Documentoscopia
} from "./Apps";

export {
    Login,
    HealthCheck,
    Home,
    CreatePassword,
    TokenManagement,
    UserManagement,
    CurrentUsage,
    Invoices,
    UsageSummary,
    PontualConsult,
    PlatformReports,
    DetailedUsageSummary,
    NotFound,
    FileTransfer,
    DocUpdate,
    Facematch,
    Help,
    Documentoscopia,
}
