import api from "./interceptors/bodySession";
import APIS from "./apis";

const API = APIS.find(API => API.NAME === "BIGBROTHER");

const BASE_URL = API?.ENVIROMENTS;

const signIn = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/signinasync`, data)

const validate2FA = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/validatesecuritycode2faasync`, data)

const generateRecoveryToken = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/generatepasswordrecoverytokenasync`, data)

const validateRecoveryToken = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/validaterecoverypwstokenasync`, data)

const forceUserChangePassword = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/forceuserchangepasswordasync`, data)

const changePassword = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/changepasswordasync`, data)

const newPassword = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/login/newpasswordasync`, data)

const getUserInfo = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/getuserinfoasync`, data)

const getUserList = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/getallusersasync`, data)

const listActiveTokens = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/token/listactivetokensbyuserasync`, data)

const createToken = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/token/generatetokenbysessionidasync`, data)

const disableToken = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/token/disabletokenidbysessionidasync`, data)

const disableTokenById = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/token/disabletokenbyidasync`, data)

const createUser = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/createuserasync`, data)

const updateUser = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/updateuserasync`, data)

const updateUserAdminState = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/updateuserasync`, data)

const updateUserEnabledState = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/changeuserstateasync`, data)

const batchEdit = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/user/batcheditenabledadministratorasync`, data)

export {
    signIn,
    validate2FA,
    generateRecoveryToken,
    validateRecoveryToken,
    getUserInfo,
    updateUserAdminState,
    updateUserEnabledState,
    forceUserChangePassword,
    changePassword,
    newPassword,
    listActiveTokens,
    createToken,
    disableToken,
    disableTokenById,
    createUser,
    updateUser,
    getUserList,
    batchEdit
}