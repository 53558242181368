import {GridColDef} from "@mui/x-data-grid";
import { GeneralComponents, BankSlips, BillingComponents } from "@components"
import { IsBankSlipOverdue, formatToValidDate } from "@utils";
import { isDesktopWidth } from "./screenWidth";

const installmentListGridColumns: GridColDef[] = 


isDesktopWidth ? [
    { field: 'id', headerName: 'ID', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'EntryCode', headerName:'Código de entrada', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'Installment', headerName: 'Parcela', flex: 12.5, headerAlign:'left', align: 'left'},
    { field: 'InstallmentValue', headerName: 'Valor da parcela', flex: 12.5, headerAlign:'left', align: 'left', valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })},
    { field: 'Status', headerName: 'Status', flex: 12.5, headerAlign:'left', align: 'left', valueGetter: (params) => IsBankSlipOverdue(params) ? 'OVERDUE' : params.value, renderCell: (params) => <GeneralComponents.StatusCell params={params}/>},
    { field: 'PaymentDueDate', headerName: 'Vencimento', flex: 12.5, headerAlign:'left', align: 'left', valueGetter: (params) => formatToValidDate(params.value)},
    { field: 'url', headerName: 'Boleto bancário', flex: 12.5, headerAlign:'left', align: 'left', renderCell: (params) => {return params.value ? <BankSlips.BankSlipDownloadCell params={params}/> : params.row.Status === 'PAID' ? 'Regularizado' : 'Cancelado'}},
]

:

[
    { field: 'PaymentDueDate', headerName: 'Vencimento', flex: 33, headerAlign:'center', align: 'center', valueGetter: (params) => formatToValidDate(params.value)},
    { field: 'Status', headerName: 'Status', flex: 33, headerAlign:'center', align: 'center', valueGetter: (params) => IsBankSlipOverdue(params) ? 'OVERDUE' : params.value, renderCell: (params) => <GeneralComponents.StatusCell params={params}/>},
    {
        field: 'installmentsDetails',
        type: 'actions',
        headerName: 'Detalhes',
        align: 'center',
        headerAlign: 'center',
        renderCell: (params) => <BillingComponents.InstallmentsDetailsCell params={params}/>,
        flex: 33
    }
]


export default installmentListGridColumns