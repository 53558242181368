import { GeneralComponents } from '@components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FileTransferCenter } from '@services';
import { reducers, slices } from '@store';
import React, { useState } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { handleAxiosError } from '@utils';
import dayjs from 'dayjs';

interface DeleteFileProps {
    params: GridRenderCellParams<any>
}
const DeleteFile: React.FC<DeleteFileProps> = ({ params }) => {

    const dispatch = useDispatch()
    const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)
    const deleteFileInfo = useSelector((state: reducers.RootState) => state.deleteFile)

    const [showDeleteFileDialog, setShowDeleteFileDialog] = useState<boolean>(false)
    const [deleteFileDialogHandlers, setDeleteFileDialogHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });

    const splittedKey = params?.row?.Key.split('/')
    // const bucketFolder = splittedKey.shift()
    const key = splittedKey.join('/')

    const handleButtonClick = async () => {
        setShowDeleteFileDialog(true)

        const deleteConfirmed = await getLogoutDialogResponse()
        if (deleteConfirmed) {
            dispatch(slices.deleteFile.setDeleteFileInfo({ ...deleteFileInfo, wasConfirmed: true }))
            tryDeleteFile()
            return
        }

        return
    }

    const getLogoutDialogResponse = async () => {
        return new Promise((resolve) => {

            const handleConfirm = () => {
                resolve(true);
                setShowDeleteFileDialog(false)

            };

            const handleCancel = () => {
                resolve(false);
                setShowDeleteFileDialog(false);
            };

            setDeleteFileDialogHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });
        });
    };

    const { confirmHandler, cancelHandler } = deleteFileDialogHandlers;


    const deleteFile = async () =>
        await FileTransferCenter.deleteFile({
            login,
            key
        })

    const tryDeleteFile = async () => {
        try {
            await deleteFile()
            dispatch(slices.deleteFile.setDeleteFileInfo({ ...deleteFileInfo, lastDelete: dayjs().format() }))
        } catch (err: any) {
            handleAxiosError(err)
        }
    }

    return <><GeneralComponents.StyledGridActionsCellItem
        label='Deletar arquivo'
        icon={<MdDeleteForever size={20} color="#FF003C" />}
        onClick={handleButtonClick}
    />
        <GeneralComponents.ConfirmationDialog
            open={showDeleteFileDialog}
            onConfirm={confirmHandler}
            onLeave={cancelHandler}
            title={'Você está prestes a deletar esse arquivo'}
            content={'Tem certeza de que deseja deletá-lo?'}
            confirmText={'Sim, por favor'}
            cancelText={'Não, cancele'}
        />
    </>;
}

export default DeleteFile;