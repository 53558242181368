import React from 'react';
import { AiOutlineInfoCircle } from 'react-icons/ai';


const IconStyle: any = {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '2px 0px 0px',
    gap: '8px',
    width: '20px',
    height: '22px'
}

const CreateUserNote = () => {
    return <div className='create-user-note-div'>
        <AiOutlineInfoCircle color='#0048B2' style={IconStyle}/>
        <div className='create-user-note-div-content'>
            <span className='create-user-note-span'>Senha automática</span>
            <p className='create-user-note-paragraph'>Não se preocupe! A senha é gerada automaticamente e enviada para o e-mail inserido acima. É possível alterá-la depois.</p>
        </div>
    </div>;
}

export default CreateUserNote;