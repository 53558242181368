import React from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Button, Paper } from "@components";
import ImageQuality from "../ImageQuality";
import DocumentVerification from "../DocumentVerification";
import DataComparison from "../DataComparison";
import DataExtraction from "../DataExtraction";
import Loading from "../Loading";
import { DocumentContent, TicketContent, TicketTitle, TicketValue, Tabs, Tab } from "./styles";

import { useFetch } from "@hooks";
import { BigID } from "@services";
import SwipeableViews from "react-swipeable-views";
import { getOnlyDataBase64 } from "@utils";

import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

type ComponentProps = {
  type: "link" | "file",
  document: any,
  qualityImage: boolean,
  index: number
}

const Component: React.FC<ComponentProps> = ({ type, document, qualityImage, index }) => {

  const [qualityImageIsLoading, setQualityImageIsLoading] = React.useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true
  });

  const dispatch = useDispatch();

  const { consult, qualityImage: qualityImageData } = useSelector((state: reducers.RootState) => state.exportPdfDocumentoscopia);

  const [tabActive, setTabActive] = React.useState<number>(0);

  const handleMountParameters = (parameters: any, type: string) => {
    if (type === "file") {

      return parameters?.type === "frontAndBack" ?
        parameters?.files?.map((file: any, index: number) => `${index === 0 ? "DOC_IMG_A" : "DOC_IMG_B"}=${getOnlyDataBase64(file?.base64)}`) :
        [`DOC_IMG=${getOnlyDataBase64(parameters?.files[0]?.base64)}`]
    }

    return parameters?.type === "frontAndBack" ?
      parameters?.files?.map((file: any, index: number) => `${index === 0 ? "DOC_IMG_URL_A" : "DOC_IMG_URL_B"}=${file?.link}`) :
      [`DOC_IMG_URL=${parameters?.files[0]?.link}`]
  }

  const preparedParameters = handleMountParameters(document, type);

  const { data, error, isLoading } = useFetch(
    BigID.verifyDocument,
    {
      ForensicValidations: true,
      Parameters: preparedParameters
    }
  );

  const handleChangeTab = (event: React.SyntheticEvent, value: number) => {
    setTabActive(value);
  }

  React.useEffect(() => {

    const isAvailable = !!data && !!!isLoading;

    const consultFound = !!(consult.find((item: any) => item.index === index));

    if (consultFound) {
      const newConsult = consult.map((item: any) => (
        item.index === index ?
          {
            ...item,
            data,
            isLoading,
            isAvailable
          }
          : item
      ))

      dispatch(slices.exportPdfDocumentoscopia.setConsult(newConsult));

      return
    }

    dispatch(slices.exportPdfDocumentoscopia.setConsult([...consult, { index, data, error, isLoading, isAvailable }]));
  }, [data, isLoading]);

  React.useEffect(() => {
    const availableQualityImage = (
      qualityImageData.filter((quality: any) => quality.documentIndex === index)
        .every((quality: any) => !!quality.isFetched)
    );

    setQualityImageIsLoading(!availableQualityImage);

  }, [consult, qualityImageData]);

  const allIsLoading = (!!!data && isLoading) || qualityImageIsLoading;

  const tabs = qualityImage ?
    [
      {
        desktop: "Qualidade da imagem",
        mobile: "Qualidade"
      },
      {
        desktop: "Extração de dados",
        mobile: "Extração"
      },
      {
        desktop: "Comparativo de dados",
        mobile: "Comparativo"
      },
      {
        desktop: "Verificações do documento",
        mobile: "Verificação"
      }
    ] :
    [
      {
        desktop: "Extração de dados",
        mobile: "Extração"
      },
      {
        desktop: "Comparativo de dados",
        mobile: "Comparativo"
      },
      {
        desktop: "Verificações do documento",
        mobile: "Verificação"
      }
    ]

  const handleNext = () => {

    if (tabActive < tabs.length - 1) {
      setTabActive((prevActiveTab: any) => prevActiveTab + 1);

      return;
    }

    setTabActive(0);
  };

  const handleBack = () => {

    if (tabActive >= 1) {
      setTabActive((prevActiveTab: any) => prevActiveTab - 1);

      return;
    }

    setTabActive(tabs.length - 1);
  };

  return (
    <>
      <Box display={allIsLoading ? "block" : "none"}>
        <Loading />
      </Box>

      <Box display={allIsLoading ? "none" : "block"}>
        <Paper>
          <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={5} p={{ xs: 2, md: 6 }}>
            <DocumentContent width={{ xs: "100%", md: "35%" }}>
              <TicketContent p={4}>
                <Box display="flex" gap={2}>
                  <TicketTitle>Ticket ID:</TicketTitle>
                  <TicketValue>{data?.TicketId}</TicketValue>
                </Box>
              </TicketContent>
              <Box display="flex" flexDirection="column" gap={4} p={4}>
                {
                  document.files.map((file: any) => (
                    <img src={file[type === "link" ? "link" : "base64"]} width="100%" />
                  ))
                }
              </Box>
            </DocumentContent>
            <Box width={{ xs: "100%", md: "65%" }}>
              {
                isMobile ? (
                  <Box display={{ xs: "flex", md: "none" }} alignItems="center" justifyContent="space-between" mt={0}>
                    <Button
                      size="small"
                      StartIcon={<BiLeftArrowAlt size={20} />}
                      onClick={handleBack}
                    />
                    <Typography
                      variant="body2"
                      color="lighterBigBlue.pure"
                      fontWeight={700}
                    >
                      {tabs[tabActive]?.desktop}
                    </Typography>
                    <Button
                      size="small"
                      StartIcon={<BiRightArrowAlt size={20} />}
                      onClick={handleNext}
                    />
                  </Box>
                ) : (
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                      value={tabActive}
                      onChange={handleChangeTab}
                      variant="fullWidth"
                    >
                      {
                        tabs.map((tab, index) => (
                          <Tab
                            value={index}
                            label={tab.desktop}
                            wrapped
                          />
                        ))
                      }
                    </Tabs>
                  </Box>
                )
              }
              <Box p={{ xs: 1, md: 4 }}>
                {
                  qualityImage ? (
                    <>
                      <SwipeableViews index={tabActive}>
                        <ImageQuality type={type} files={document.files} documentIndex={index} />
                        <DataExtraction docData={data?.DocInfo} hasError={!!error} />
                        <DataComparison officialData={data?.OfficialInfo} docData={data?.DocInfo} hasError={!!error} />
                        <DocumentVerification forensicValidations={data?.ForensicValidations} hasError={!!error} />
                      </SwipeableViews>
                    </>
                  ) : (
                    <>
                      <SwipeableViews index={tabActive}>
                        <DataExtraction docData={data?.DocInfo} hasError={!!error} />
                        <DataComparison officialData={data?.OfficialInfo} docData={data?.DocInfo} hasError={!!error} />
                        <DocumentVerification forensicValidations={data?.ForensicValidations} hasError={!!error} />
                      </SwipeableViews>
                    </>
                  )
                }
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  )
};

type TabPanelProps = {
  index: string,
  value: string,
  children: React.ReactElement
}

const CustomTabPanel: React.FC<TabPanelProps> = ({ index, value, children }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {
        value === index && (
          children
        )
      }
    </div>
  );
}

export default Component;