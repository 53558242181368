import { GridValidRowModel } from "@mui/x-data-grid";
import { BigBrotherTypes } from "@types";

const transformUserListToValidRows = (usersData: BigBrotherTypes.ListAllUsersApiResponse | undefined) => {
    const userList: GridValidRowModel[] | undefined = usersData?.users ? usersData?.users.map((user, index) => ( {
        id: user.login,
        login: user.login,
        name: user.name,
        group: user.group,
        email: user.email,
        company: user.company,
        isSystemAdmin: user.isSystemAdmin,
        isEnabled: user.isEnabled
      })) : []

      return userList 
}

export default transformUserListToValidRows