import React, { useRef } from 'react';
import { MobileSideSection } from '../../../../components/GeneralComponents';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import {  GeneralComponents } from '@components';
import { IsBankSlipOverdue, formatToValidDate, showToastCustomErrorMessage } from '@utils';
import dayjs from 'dayjs';



const InstallmentDetails: React.FC = () => {

    const dispatch = useDispatch()
    const { open, params } = useSelector((state: reducers.RootState) => state.installmentDetailsSectionReducer)
    const sideSectionRef = useRef(null);

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.installmentDetails.setShowInstallmentDetailsSection(false))
    }

    const getInstallment = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (params.url) {
            const url = params.url;
            window.open(url, '_blank');
        }else{
            showToastCustomErrorMessage('Erro no boleto', 'Não foi possível visualizar o boleto.')
        }
    };


    const isInstallmentOverdue = () => dayjs(params?.PaymentDueDate).isBefore(dayjs()) && params?.Status === 'PENDING'
    

    return <MobileSideSection
        sideSectionTitle='Detalhes do boleto'
        mainButtonType='default'
        mainButtonText='Ver boleto'
        mainButtonAction={getInstallment}
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
        <div className='invoice-details-content'>
            <h4 className='invoice-details-content-title'>Detalhes</h4>
            <div className='invoice-details-data'>
                <div className='user-profile-fields'>
                    <label>ID do boleto: </label>
                    <span>{params?.id}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Código de entrada: </label>
                    <span>{params?.EntryCode}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Parcela: </label>
                    <span>{params?.Installment}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Valor da parcela: </label>
                    <span>{params?.InstallmentValue?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Status: </label>
                    <GeneralComponents.StatusTag value={isInstallmentOverdue() ? 'OVERDUE' : params?.Status}></GeneralComponents.StatusTag>
                </div>
                <div className='user-profile-fields'>
                    <label>Vencimento: </label>
                    <span>{formatToValidDate(params?.PaymentDueDate)}</span>
                </div>
            </div>
        </div>
    </MobileSideSection>

}

export default InstallmentDetails;