import { Button, ButtonGroup } from '@mui/material';
import { styled } from '@mui/system';


const TokenHourButtonGroup = styled(ButtonGroup)`
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    border-radius: 0;
`

const TokenHoursButton = styled(Button)`
 &&{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    gap: 2px;
    background: #FFFFFF;
    border: 1px solid;
    border-color: #EDF2F4;
    border-radius: 4px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: #555866;
    text-transform: none;
}

&.MuiButtonGroup-grouped:not(:last-of-type){
    border: 1px solid;
    border-color: #EDF2F4;
    border-radius: 4px;
}

& .MuiButton-startIcon{
    display: flex;
    margin-right: 0;
    margin-left: 0;
    align-items: center;
    justify-content: center;
}

:hover{
    background-color: #F3F8FA;
    color: #1D203A;
    transition: all .5s ease;
}
`

export {TokenHoursButton, TokenHourButtonGroup};