import { styled } from '@mui/material/styles';
import { Button as ButtonComponent, IButtonProps } from "@components";

const Button = styled(ButtonComponent)<IButtonProps & { theme?: any, extended: boolean }>(({ theme, extended }) => (
  !!!extended && {
    borderRadius: "100%",
    padding: theme.spacing(3)
  }
))

export {
  Button
}