import { Theme, styled } from '@mui/material/styles';
import { Box } from "@mui/material";
import { Paper } from "@components";

interface CustomPaperProps extends Omit<typeof Paper, 'checked'> {
  theme?: Theme,
  checked: boolean
}

const Card = styled(Paper)(({ theme, checked }: CustomPaperProps) => {

  const borderStyle = !!checked ? {
    borderWidth: "2px",
    borderColor: theme?.palette.lighterBigBlue?.pure
  } : ""

  return {
    "&:hover": {
      backgroundColor: '#0068FF05 '
    },
    display: 'flex',
    flex: 1,
    borderWidth: "2px",
    transition: "border-color .4s ease",
    height: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    ...borderStyle
  }
})

const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#0068FF1A",
  color: theme.palette.lighterBigBlue?.dark,
  padding: theme.spacing(4),
  borderRadius: 100
}))

export {
  Card,
  Icon
}