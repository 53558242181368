import { styled } from '@mui/material/styles';
import { Box, Table as TableMaterial } from "@mui/material";

const Title = styled("h3")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}))

const LabelSwitch = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100]
}))

const Identification = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral?.[500],
  borderRadius: 8
}))

const IdentificationLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.secondary.blue
}))

const IdentificationValue = styled("span")(({ theme }) => ({
  width: "100%",
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}))

const Table = styled(TableMaterial)(({ theme }) => ({
  borderRadius: 8,
  '& thead th:nth-child(1)': {
    width: '50%'
  }
}))

const THead = styled("thead")(({ theme }) => ({
  backgroundColor: theme.palette.neutral?.[500]
}))

const Th = styled("th")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.secondary.blue
}))

const Tr = styled("tr")(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral?.[400]}`
}))

const Td = styled("td")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}))

export {
  Title,
  LabelSwitch,
  Identification,
  IdentificationLabel,
  IdentificationValue,
  Table,
  THead,
  Th,
  Tr,
  Td
}