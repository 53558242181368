import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ImageQuality, ImageInfo, Loading, Similarity } from './components';
import { useFetch } from '@hooks';
import { BigID } from '@services';
import { getOnlyDataBase64, showToastCustomErrorMessage } from '@utils';
import { Collapse, Paper, Tabs } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import Print from './components/Print';
import { Bs1Circle, Bs2Circle } from 'react-icons/bs';
import SwipeableViews from 'react-swipeable-views';

const Result: React.FC = () => {

    const { qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfFacematch)

    const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState<number>(0)
    const [locationState, setLocationState] = useState<any>(location?.state)

    const { type = '', files = [], checkbox = {} } = locationState || {}

    const handleActiveTab = (event: any, value: any) => setActiveTab(value)

    const firstFile = type === "file" ? getOnlyDataBase64(files?.[0]?.firstImage.base64) : files?.[0]?.firstImage.link
    const secondFile = type === "file" ? getOnlyDataBase64(files?.[0]?.secondImage.base64) : files?.[0]?.secondImage.link

    const handleMountParameters = () => {
        if (type === "file")
            return [`BASE_FACE_IMG=${firstFile}`, `MATCH_IMG=${secondFile}`]

        return [`BASE_FACE_IMG_URL=${firstFile}`, `MATCH_IMG_URL=${secondFile}`]
    }

    const { data, error, isFetched, fetch } = useFetch(BigID.getSimilarity, {
        Parameters: handleMountParameters()
    }, {
        shouldAutoStart: false
    })

    const result = data ?? (error?.response?.data || error)
    const percentage = !!result?.EstimatedInfo?.Similarity ? (result?.EstimatedInfo?.Similarity / 100) : -1
    dispatch(slices.exportPdfFacematch.setConsult(result))

    const stillLoading = !!!isFetched || !!!qualityImage?.firstImage?.isAvailable || !!!qualityImage?.secondImage?.isAvailable

    useEffect(() => {

        if (!!!stillLoading) {
            navigate(location.pathname, { replace: true });
            return
        }

    }, [stillLoading])

    useEffect(() => {

        if (!!!location?.state) {
            navigate('/dashboard/apps/bigid/facematch')
            return
        }
    
        fetch()
    }, [])

    return <>
        <Paper>
            <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'flex-start'}
                width={'100%'}
                gap={8}
                px={6}
                py={8}
                displayPrint={'none'}
            >
                <Box display={stillLoading ? 'block' : 'none'} width={'100%'}>
                    <Loading />
                </Box>
                <Box display={stillLoading ? 'none' : 'block'} width={'100%'}>
                    <Grid container gap={8}>
                        <Grid item xs={4}>
                            <ImageInfo
                                ticketId={result?.TicketId}
                                data={{ type, files }}
                                handleSetActiveTab={handleActiveTab}
                                activeTab={activeTab}
                            />
                        </Grid>
                        <Grid container item xs direction={'column'} gap={8}>
                            <Grid item>
                                <Similarity
                                    value={percentage}
                                    ResultCode={result?.ResultCode}
                                    ResultMessage={result?.ResultMessage}
                                    error={error}
                                />
                            </Grid>
                            {
                                !!checkbox?.qualityCheck?.selected && (
                                    <Grid container item gap={3} direction={'column'}>
                                        <Tabs.Container
                                            value={activeTab}
                                            isFormContext={false}
                                            onChange={handleActiveTab}
                                            variant='fullWidth'
                                        >
                                            <Tabs.Item
                                                value={0}
                                                label='Primeira imagem'
                                                icon={<Bs1Circle size={18} color='primary' />}
                                                iconPosition='start'
                                            />
                                            <Tabs.Item
                                                value={1}
                                                label='Segunda imagem'
                                                icon={<Bs2Circle size={18} color='primary' />}
                                                iconPosition='start'

                                            />
                                        </Tabs.Container>
                                        <SwipeableViews index={activeTab}>
                                            <ImageQuality index={'firstImage'} value={files?.[0]?.firstImage} type={type} />
                                            <ImageQuality index={'secondImage'} value={files?.[0]?.secondImage} type={type} />
                                        </SwipeableViews>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Paper>
        <Print state={{ type, files, checkbox }} />
    </>

}

export default Result;