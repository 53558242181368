import React from 'react';

interface BillingSummaryChartProps {
    platformPercentage: number;
    bigIdPercentage: number;

}

const BillingSummaryChart: React.FC<BillingSummaryChartProps> = ({ platformPercentage, bigIdPercentage }: BillingSummaryChartProps) => {
    return <div className='billing-summary-chart-container'>
        <div className='billing-summary-chart-legends'>
            <div className='billing-summary-metrics'>
                <span className='billing-summary-percentage'>{isNaN(platformPercentage) ? '0%' : platformPercentage.toFixed(2) + '%'}</span>
                <span className='billing-summary-percentage-product'>Plataforma</span>
            </div>
            <div className='billing-summary-metrics'>
                <span className='billing-summary-percentage'>{isNaN(bigIdPercentage) ? '0%' : bigIdPercentage.toFixed(2) + '%'}</span>
                <span className='billing-summary-percentage-product'>BigID</span>
            </div>
        </div>
        <div className='billing-summary-product-slices'>
            <div className='billing-summary-platform-slice'
                style={{ width: isNaN(platformPercentage)  ? '0%' : platformPercentage > 99.99 ? '99%' : platformPercentage < 0.01 ? '1%' : platformPercentage.toString() + '%' }}
            ></div>
            <div className='billing-summary-big-id-slice'
                style={{ width: isNaN(bigIdPercentage) ? '0%' :  bigIdPercentage > 99.99 ? '99%' : bigIdPercentage < 0.01 ? '1%' : bigIdPercentage.toString() + '%' }}>
            </div>
        </div>
    </div>;
}

export default BillingSummaryChart;