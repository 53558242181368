import { styled } from "@mui/material/styles"
import { ButtonBase as IconButtonMaterial } from "@mui/material"

const IconButton = styled(IconButtonMaterial)(({ theme, color }) => {
  return {
    backgroundColor: theme.palette.neutral?.white,
    padding: theme.spacing(2),
    borderRadius: 8,
    "&:hover": {
      backgroundColor: theme.palette.neutral?.white
    },
    width: "fit-content",
    height: "fit-content"
  }
});

export {
  IconButton
};