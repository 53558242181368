import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface NotificationCenterInfo {
  showNotificationCenter: boolean;
  outsideClickOccurred: boolean;
}

const initialState: NotificationCenterInfo = {
  showNotificationCenter: false,
  outsideClickOccurred: false,
}

const notificationCenter = createSlice({
  name: 'notificationCenter',
  initialState,
  reducers: {
    setShowNotificationCenter: (state, action: PayloadAction<boolean>) => {
      state.showNotificationCenter = action.payload
    }
  }
});

export const { setShowNotificationCenter } = notificationCenter.actions;

export default notificationCenter.reducer;