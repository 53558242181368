import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import OTPInput from 'react-otp-input';
import { FiClock } from 'react-icons/fi'

import { GeneralComponents, AuthComponents } from "@components";

import { reducers, slices } from "@store";

import { BigBrother } from '@services';

import './auth.css';

import { handleAxiosError, showToastCustomErrorMessage } from '@utils';


interface AuthProps {
    handleReturnToLogin: () => void
}

const otpNumInputs = 6

export default function Auth({ handleReturnToLogin }: AuthProps) {


    const isChangePasswordNeeded = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.parameters.TrocarSenha);
    const userLogin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.login);
    const userEmail = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.email);
    const [twoFactorAuthToken, setTwoFactorAuthToken] = useState<string>("")
    const [tokenInputError, setTokenInputError] = useState<boolean>(false)
    const [remainingAttempts, setRemainingAttempts] = useState<number>(3)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleTokenInputChange = (value: string) => {
        setTwoFactorAuthToken(value)
        setTokenInputError(false)
    }

    const handleApiResponse = (apiResponse: any) => {
        const { result, message } = apiResponse.data

        if (!!!result) {
            setTokenInputError(true)
            setRemainingAttempts(remainingAttempts - 1)
            return
        } 
        
        dispatch(slices.user.setUserSessionId(message))

        if (isChangePasswordNeeded === "True") {
            localStorage.setItem('loginForForcedPasswordChange', userLogin)
            navigate('/auth/RecoverPassword')
            return

        }             
    }

    const handleFormSubmit = () => {
        return async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            try {
                const response = await BigBrother.validate2FA({ login: userLogin, code: twoFactorAuthToken })
                handleApiResponse(response)
                setIsLoading(false)
            }
            catch (error: any) {
                handleAxiosError(error)
                setIsLoading(false)
            }
        }
    }

    const transformUserEmail = (userEmail: string) => {
        try {
            var emailParts = userEmail?.split('@');
            const wasSplitted = emailParts?.length === 2

            if (wasSplitted) {
                const username = emailParts[0];
                const domainParts = emailParts[1].split('.');

                if (domainParts.length > 1) {
                    const transformedDomain = "...";
                    domainParts.shift();
                    const transformedUserEmail = username + '@' + transformedDomain + '.' + domainParts.join('.');
                    return transformedUserEmail;
                }
            }
            return userEmail;
        }
        catch (err: any) {
            console.log(err)
        }
    }

    transformUserEmail(userEmail)

    useEffect(() => {
        if (remainingAttempts === 0) {
            handleReturnToLogin()
            showToastCustomErrorMessage('Falha ao autenticar.', 'Suas tentativas esgotaram. Por favor, faça o login novamente.')
        }
    }, [remainingAttempts])

    return (
        <div className='auth-main-container'>
            <div className='auth-presentation-and-time-container'>
                <div className="pre-auth-presentation-content">
                    <h1 className='pre-auth-presentation-title'>Confirme sua identidade</h1>
                    <p className="pre-auth-presentation-paragraph">Insira o código enviado ao e-mail {transformUserEmail(userEmail)} para concluir o seu processo de login.</p>
                </div>
                <div className='auth-counter-container'>
                    <span className='auth-counter'>Seu código expira em:</span>
                    <div className='auth-countdown-container'>
                        <FiClock color='#006' size={16} />
                        <AuthComponents.Countdown handleReturnToLogin={handleReturnToLogin} />
                    </div>
                </div>
            </div>
            <form onSubmit={handleFormSubmit()} className='auth-form-container'>
                <div className='auth-inputs'>
                    <div>
                        <OTPInput
                            value={twoFactorAuthToken}
                            onChange={handleTokenInputChange}
                            numInputs={otpNumInputs}
                            placeholder='000000'
                            renderInput={(props) => <input {...props} />}
                            containerStyle={'otp-input-container'}
                            inputStyle={tokenInputError ? 'otp-inputs-with-error' : 'otp-inputs'}
                        />
                        {tokenInputError && <p className='otp-supporting-text'>Código incorreto. {`Resta(m) ${remainingAttempts} tentativa(s).`}</p>}
                    </div>
                    <div className='auth-options-container'>
                        <GeneralComponents.ReturnLoginButton handleReturnToLogin={handleReturnToLogin} />
                        <button type='submit' className='validate-button' disabled={twoFactorAuthToken.length !== 6 || !!isLoading}>
                            {!!isLoading ? 'Verificando...' : 'Verificar'}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}