import React from 'react';
import { partialsDetailGridColumns } from "@constants";
import { GeneralComponents } from '@components';

import './seeDetailsSection.css'
import { GridValidRowModel } from '@mui/x-data-grid';
import SeeDetailsSummarySection from './SeeDetailsSummarySection';

interface SeeDetailsSectionProps {
  partialDetails: GridValidRowModel[]
}

const SeeDetailsSection: React.FC<SeeDetailsSectionProps> = ({partialDetails} : SeeDetailsSectionProps) => {

  return <section>
    <div className='product-consumption-container-list'>
      <div className='get-partial-detail-table-container'>
        <GeneralComponents.StyledDataGridWithoutPagination rows={partialDetails} columns={partialsDetailGridColumns}></GeneralComponents.StyledDataGridWithoutPagination>
      </div>
      <SeeDetailsSummarySection/>
    </div>
  </section>
}

export default SeeDetailsSection;