import { Box } from "@mui/material";
import { Button, FormWizard } from "@components";
import { TypeConsult, Api, Condition, Document, Datasets, Review, SavedConsult } from "../../Steps";

import { BiRightArrowAlt } from "react-icons/bi"
import { HiOutlineLightningBolt } from "react-icons/hi"

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaApi, SchemaCondition, SchemaDocument, SchemaDatasets, SchemaType, SchemaSavedConsult } from "../../schemas";

import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from "@store";
import { pontualConsult, showToastCustomErrorMessage } from "@utils";

import React, { useState } from "react";
import DialogContainer from "../DialogContainer";
import DialogContent from "./Components/DialogContent";

interface FormProps {
  handleSubmit: (event: any, data: any) => any
}

const Form: React.FC<FormProps> = ({ handleSubmit }) => {
  const { dismissDialog, user } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.quickConsultDialog);
  const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user);
  const { data: actualData } = useSelector((state: reducers.RootState) => state.formWizard);
  const [showQuickConsultDialog, setShowQuickConsultDialog] = useState<boolean>(false)
  const [checkboxDismissDialog, setCheckboxDismissDialog] = useState<boolean>(false)
  const dispatch = useDispatch();
  const requiredDocuments = actualData?.requiredDocuments || [];

  const formTypeConsult = useForm({ resolver: yupResolver(SchemaType) });
  const formSavedConsult = useForm({ resolver: yupResolver(SchemaSavedConsult) });
  const formApi = useForm({ resolver: yupResolver(SchemaApi) });
  const formCondition = useForm({ resolver: yupResolver(SchemaCondition) });

  const useYupValidationResolver = (validationSchema: any) =>
    React.useCallback(
      async (data: any) => {
        try {
          const isOneOf = actualData?.condition === "alternative_keys";

          const values = await validationSchema(requiredDocuments, isOneOf).validate(data, {
            abortEarly: false,
          });

          return {
            values,
            errors: {},
          }
        } catch (errors: any) {

          return {
            values: {},
            errors: errors.inner.reduce(
              (allErrors: any, currentError: any) => ({
                ...allErrors,
                [currentError.path]: {
                  type: currentError.type ?? "validation",
                  message: currentError.message,
                },
              }),
              {}
            ),
          }
        }
      },
      [validationSchema, actualData]
    )

  const resolver = useYupValidationResolver(SchemaDocument)
  const formDocument = useForm({ resolver });

  const formDatasets = useForm({ resolver: yupResolver(SchemaDatasets) });

  const submitSavedConsult = ({ savedConsult }: any) => {
    const apiName = pontualConsult.getDataOption("apis", savedConsult?.TargetApi, "englishName")?.value || ""

    formApi.setValue("api", apiName)
    dispatch(slices.formWizard.addData({ api: apiName }))

    formCondition.setValue("condition", savedConsult?.ViewCondition)
    dispatch(slices.formWizard.addData({ condition: savedConsult?.ViewCondition }))

    const datasets = JSON.parse(savedConsult?.TargetDatasets);
    formDatasets.setValue("datasets", datasets)
    dispatch(slices.formWizard.addData({ datasets: datasets }))

    dispatch(slices.formWizard.goStep(5))
  }

  const submitDocument = () => {
    dispatch(slices.formWizard.goStep(6))
  }

  const handleQuickConsult = async () => {
    const isValid = await formDocument.trigger();

    if (!isValid) {
      showToastCustomErrorMessage('Erro ao avançar na consulta', 'Preencha no mínimo um documento para continuar a consulta')
      return
    }

    handleSubmit(null, { ...actualData, ...formDocument.getValues() })
  }

  const handleCancelQuickConsult = () => {
    setShowQuickConsultDialog(false)
  }

  const handleShowQuickConsultDialog = () => {
    if (!dismissDialog || user !== login) {
      setShowQuickConsultDialog(true)
    } else {
      handleQuickConsult()
    }
  }

  const handleConfirmQuickConsultDialog = () => {
    setShowQuickConsultDialog(false)
    dispatch(slices.quickConsultDialog.setDismissDialog({ dismissDialog: checkboxDismissDialog, user: login }))
    handleQuickConsult()
  }

  return (
    <FormWizard.Container>
      <FormWizard.Steps handleSubmit={handleSubmit}>
        <FormWizard.Step
          label={'Tipo da consulta'}
          renderButton={
            <Button
              EndIcon={
                <BiRightArrowAlt size={24} />
              }
              size="small"
              type="submit"
              id="btn-next"
            >
              Avançar
            </Button>
          }
          {...formTypeConsult}
        >
          <TypeConsult.Openned />
        </FormWizard.Step>
        {
          actualData.typeConsult === "saved" && (
            <FormWizard.Step
              label={'Consulta salva'}
              submit={submitSavedConsult}
              renderButton={
                <Button
                  EndIcon={
                    <BiRightArrowAlt size={24} />
                  }
                  size="small"
                  type="submit"
                  id="btn-next"
                >
                  Avançar
                </Button>
              }
              {...formSavedConsult}
            >
              <SavedConsult.Openned />
            </FormWizard.Step>
          )
        }
        <FormWizard.Step
          label={'Seleção de api'}
          renderStepClosed={actualData.typeConsult === "new" && <Api.Closed />}
          renderButton={
            <Button
              EndIcon={
                <BiRightArrowAlt size={24} />
              }
              size="small"
              type="submit"
              id="btn-next"
            >
              Avançar
            </Button>
          }
          {...formApi}
        >
          <Api.Openned />
        </FormWizard.Step>
        <FormWizard.Step
          label={'Seleção de datasets'}
          renderButton={
            <Button
              EndIcon={
                <BiRightArrowAlt size={24} />
              }
              size="small"
              type="submit"
              id="btn-next"
            >
              Avançar
            </Button>
          }
          {...formDatasets}
        >
          <Datasets.Openned />
        </FormWizard.Step>
        <FormWizard.Step
          label={'Seleção de condição'}
          renderButton={
            <Button
              EndIcon={
                <BiRightArrowAlt size={24} />
              }
              size="small"
              type="submit"
              id="btn-next"
            >
              Avançar
            </Button>
          }
          {...formCondition}
        >
          <Condition.Openned />
        </FormWizard.Step>
        <FormWizard.Step
          label={'Preencher campos'}
          submit={actualData.typeConsult === "saved" ? submitDocument : () => { }}
          renderButton={
            <>
              {
                actualData.typeConsult === "new" ? (
                  <Button
                    EndIcon={
                      <BiRightArrowAlt size={24} />
                    }
                    size="small"
                    type="submit"
                    id="btn-next"
                  >
                    Revisar configuração da consulta
                  </Button>
                ) : (

                  <Box display="flex" gap={6}>
                    <DialogContainer
                      open={showQuickConsultDialog}
                      title="Consulta rápida"
                      description="Ao prosseguir, a consulta será efetuada imediatamente."
                      handleConfirm={handleConfirmQuickConsultDialog}
                      handleCancel={handleCancelQuickConsult}
                    >
                      <DialogContent toggleCheckbox={setCheckboxDismissDialog} />
                    </DialogContainer>
                    <Button
                      onClick={handleShowQuickConsultDialog}
                      StartIcon={
                        <HiOutlineLightningBolt size={24} />
                      }
                      color="secondary"
                      size="small"
                    >
                      Consulta rápida
                    </Button>
                    <Button
                      EndIcon={
                        <BiRightArrowAlt size={24} />
                      }
                      size="small"
                      type="submit"
                      id="btn-next"
                    >
                      Revisar configuração da consulta
                    </Button>
                  </Box>
                )
              }
            </>
          }
          {...formDocument}
        >
          <Document.Openned />
        </FormWizard.Step>
        <FormWizard.Step
          label={'Revisão da consulta'}
          review={true}
        >
          <Review />
        </FormWizard.Step>
      </FormWizard.Steps>
    </FormWizard.Container>
  )
}

export default Form