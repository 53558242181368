import './footer.css'
import dayjs from 'dayjs'

const Footer = () => {

  const currentYear = dayjs().year()

  return <footer className='footer-container'>
    <div className='footer-content'>
        <div className='footer-first-column'>
            <img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/LOGO-BDC-AZUL-01.svg' alt='Bigdatacorp new brand' className='footer-image'></img>
            <span>{currentYear} Bigdatacorp. Todos os direitos reservados</span>
        </div>
        <div className='footer-last-column'>
            <a href='https://bigdatacorp.com.br/politica-de-privacidade' target='_blank' className='privacy-policy'>Política de privacidade</a>
            <a href='https://docs.bigdatacorp.com.br/plataforma/reference/autenticacao-e-seguranca' target='_blank' className='information-security'>Segurança da informação</a>
        </div>
    </div>
  </footer>;
}

export default Footer;