import { Box } from "@mui/material";
import { Title, Subtitle, Observation } from "./styles";

interface HeaderProps {
  title: string,
  subtitle?: string | React.ReactNode,
  observation?: string
}

const Header: React.FC<HeaderProps> = ({ title, subtitle, observation }) => {
  return (
    <Box display="flex" flexDirection="column" gap={4}>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      {
        observation && <Observation>{observation}</Observation>
      }
    </Box>
  )
}

export default Header