import React, { MouseEventHandler } from "react";
import { ButtonGroupProps, Button, Grow, ClickAwayListener, MenuItem } from "@mui/material";
import { ButtonGroup, Popper, MenuList, Paper } from "./styles";
import { ArrowDropDownIcon } from "@mui/x-date-pickers/icons";

interface option {
  label: string,
  onClick: MouseEventHandler
}

interface ButtonDropdownProps extends Omit<ButtonGroupProps, 'options'> {
  options: Array<option>,
  handleClick?: () => void,
  openOnClick?: boolean 
}

const Component = ({
  children,
  variant = "contained",
  color,
  options,
  handleClick,
  openOnClick = false
}: ButtonDropdownProps) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        disableElevation
        disableRipple
        size="large"
        variant={variant}
        color={color}
        ref={anchorRef} aria-label="split button"
      >
        <Button
          onClick={!!!openOnClick ? handleClick : handleToggle}
        >
          {children}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={option.onClick}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  )
}

export default Component;