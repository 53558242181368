import React from 'react';

import Container from './styles';
import { Divider, useMediaQuery } from '@mui/material';

interface ContactContainerProps {
    children: any
}

const ContactContainer: React.FC<ContactContainerProps> = ({
    children
}) => {
    const breakpoint = useMediaQuery('(max-width: 1000px)')

    return <Container breakpoint={breakpoint}>
        <Divider
            sx={{ height: 'auto', width: 'auto'}}
            orientation={!!!breakpoint ? 'vertical' : 'horizontal'}
        />
        {children}
    </Container>;
}

export default ContactContainer;