import React, { useState } from 'react';
import { GeneralComponents } from "@components"
import { BigBrother } from '@services';
import { showToastCustomErrorMessage, showToastCustomSuccessMessage, handleAxiosError } from '@utils';
import PasswordChecklist from "react-password-checklist"
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';

import './createPassword.css'
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { reducers } from '@store';
import { useNavigate } from 'react-router-dom';
import { setUserInitialState } from '../../store/slices/user';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AuthTypes } from '@types';


const CreatePassword: React.FC = () => {

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const login = searchParams.get('email')
    const loginForForcedPasswordChange = localStorage.getItem('loginForForcedPasswordChange')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { errors },
      } = useForm<AuthTypes.ChangePasswordData>()


    const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false)
    const sessionId = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.sessionId)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const loginBase64ToString = login ? atob(login) : ''

    const handleReturnToLogin = () => {
        dispatch(setUserInitialState())
        localStorage.removeItem('userLoginForForcedPasswordChange')
        reset()
        navigate('/login')
    }

    const isRecoveryPwsTokenValid = async () => await BigBrother.validateRecoveryToken(
        {
            token,
            login: loginBase64ToString 
        })

    const handleRecoveryPwsTokenResponse = () => showToastCustomErrorMessage('Erro ao criar nova senha', 'Token de recuperação de senha inválido.')

    const handleGenerateNewPasswordSubmit: SubmitHandler<AuthTypes.ChangePasswordData> = async (data, event) => {
        event?.preventDefault()
        const {newPassword, passwordConfirmation} = data

        try {
            setIsLoading(true)
            const { data: isRecoveryPwsTokenValidResponse } = await isRecoveryPwsTokenValid()
            if (isNewPasswordValid) {
                if (isRecoveryPwsTokenValidResponse.result) {
                    const newPasswordApiResponse = await BigBrother.newPassword({
                        token,
                        login: loginBase64ToString,
                        newPassword
                    })
                    const { data: newPasswordApiResponseData } = newPasswordApiResponse
                    handleNewPasswordApiResponse(newPasswordApiResponseData)
                    setIsLoading(false)

                } else if (loginForForcedPasswordChange !== null && loginForForcedPasswordChange !== '') {
                    const newPasswordApiResponse = await BigBrother.forceUserChangePassword({
                        sessionId,
                        newPassword
                    })
                    const { data: newPasswordApiResponseData } = newPasswordApiResponse
                    handleNewPasswordApiResponse(newPasswordApiResponseData)
                    setIsLoading(false)
                } else {
                    handleRecoveryPwsTokenResponse()
                    setIsLoading(false)
                }
            }else{
                showToastCustomErrorMessage('As senhas não coincidem', 'Por favor, verifique sua senha.')
                setIsLoading(false)
            }

        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleNewPasswordApiResponse = (newPasswordApiResponse: any) => {
        if (!newPasswordApiResponse.result && newPasswordApiResponse.message === '') {
            showToastCustomErrorMessage('Erro ao criar nova senha', 'Não foi possível alterar a sua senha.')
        } else if (!newPasswordApiResponse.result) {
            showToastCustomErrorMessage('Erro ao criar nova senha', 'Não foi possível alterar a sua senha.')
        }
        else {
            showToastCustomSuccessMessage('Senha alterada com sucesso!', 'Faça o login com a sua nova senha.')
            reset()
            dispatch(setUserInitialState())
            navigate('/login')
            localStorage.removeItem('loginForForcedPasswordChange')
        }
    }

    return <main className="create-password-page-container">
        <div className="pre-auth-image-container"></div>
        <div className="pre-auth-grouped-container">
            <img src="https://bdcenter-files.bigdatacorp.com.br/Imagens/Layer_1-2.svg" className="dotnetwork-image"></img>
            <section className='pre-auth-container'>
                <div className='pre-auth-create-password-container'>
                    <div className="pre-auth-presentation-content">
                        <h1 className="pre-auth-presentation-title">Criar nova senha</h1>
                        <p className="pre-auth-presentation-paragraph">Sua nova senha deve conter:</p>
                    </div>

                    <PasswordChecklist
                        className='password-checklist'
                        rules={["minLength", "specialChar", "capital", 'lowercase', "match"]}
                        minLength={8}
                        value={watch().newPassword ?? ''}
                        valueAgain={watch().passwordConfirmation ?? ''}
                        onChange={(isValid) => isValid ? setIsNewPasswordValid(true) : setIsNewPasswordValid(false)}
                        style={{ fontSize: '14px !important', color: '#555866', fontWeight: 400, fontFamily: "'Be Vietnam' !important" }}
                        messages={{
                            capital: "Uma letra maiúscula",
                            lowercase: "Uma letra minúscula",
                            specialChar: "Um caractere especial",
                            minLength: "Ao menos 8 caracteres",
                            match: "Coincidirem entre si",
                        }}
                        iconComponents={{ ValidIcon: <AiFillCheckCircle color='#28AD58' size='16px' />, InvalidIcon: <AiOutlineCheckCircle color='#555866' size='16px' /> }}
                    />
                    <form className='create-password-form' onSubmit={handleSubmit(handleGenerateNewPasswordSubmit)}>
                        <div className='create-password-fields'>
                            <label className='email-label'>Nova senha</label>
                            <Controller
                            name="newPassword"
                            control={control}
                            rules={{required: true}}
                            render={({ field }) => (
                                <GeneralComponents.CustomTextField
                                {...field}
                                variant="outlined"
                                type='password'
                                fullWidth
                                placeholder='Insira a sua nova senha'
                                />
                            )}/>

                            
                        </div>
                        <div className='create-password-fields'>
                            <label className='email-label'>Confirmar nova senha</label>
                            <Controller
                            name="passwordConfirmation"
                            control={control}
                            rules={{required: true}}
                            render={({ field }) => (
                                <GeneralComponents.CustomTextField
                                {...field}
                                variant="outlined"
                                type='password'
                                fullWidth
                                placeholder='Confirme a sua nova senha'
                                />
                            )}/>
                        </div>
                        <div className='create-password-options'>
                            <GeneralComponents.ReturnLoginButton handleReturnToLogin={handleReturnToLogin} />
                            <button type='submit' className='email-options' disabled={!!!isNewPasswordValid || !!isLoading}>{!!isLoading ? 'Alterando...' : 'Alterar senha'}</button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    </main >
}

export default CreatePassword;