import React from "react";

import { Box, CircularProgress, useTheme } from "@mui/material";
import { Button, Chip, Dialog, Paper, TextField, InputFile } from "@components";
import {
  FileContent,
  FileImage,
  Error
} from "./styles";

import { useFieldArray, useFormContext } from "react-hook-form";
import { RiFolderUploadLine } from "react-icons/ri";

import getValueFromPath from "lodash/get";
import { FaTrash } from "react-icons/fa";

type ComponentType = {
  type: "file" | "link",
  name: string | any,
  identifier?: string
}

const Component: React.FC<ComponentType> = ({ type, name, identifier }) => {
  const theme = useTheme();
  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const [isDragging, setIsDragging] = React.useState<boolean>(false)

  const {
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    resetField,
    formState: { errors }
  } = useFormContext();

  const { ref, ...inputProps } = register(name);
  const currentWatch = watch();
  const currentData = getValueFromPath(currentWatch, name) || {};
  const error: any = getValueFromPath(errors, name);

  const handleSelectFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }

  const convertToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const validateFile = (file: any) => {
    return (file.size / (1024 ** 2)) <= 20;
  }

  const validateLink = (link: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(link);
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput && fileInput.files && fileInput.files.length > 0) {

      const file = fileInput.files[0];
      handleInsertFile(file);
    }
  }

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false)
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
    setIsDragging(true)
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const file = event.dataTransfer.files[0];
    handleInsertFile(file);
  };

  const handleInsertFile = async (file: any) => {
    const isValidFile = validateFile(file);

    if (isValidFile) {
      const base64 = await convertToBase64(file);

      const fileName = file.name;
      const fileType = file.type.split("/")[1].toLocaleUpperCase();

      setValue(
        name,
        {
          file,
          base64,
          fileName,
          fileType
        }
      );

      clearErrors(name);
    } else {
      setError(name, { type: "custom", message: "Tamanho do arquivo é superior a 10MB" });
    }
  }

  const handleInsertLink = () => {
    const isValidLink = validateLink(currentData?.link);

    if (isValidLink) {

      setValue(
        name,
        {
          ...currentData,
          inserted: true
        }
      )

      clearErrors(name)
    } else {
      setError(name, { type: "custom", message: "Insira um link de imagem válido" })
    }
  }

  const handleDelete = () => {
    setOpenDialog(true);
  }

  const onCancelDialog = () => {
    setOpenDialog(false);
  }

  const onConfirmDialog = () => {
    resetField(name);
    onCancelDialog();
  }

  if (type === "file") {
    return (
      <InputFile
        name={name}
        identifier={identifier}
        maximumSize={5}
        formats={["jpg", "jpeg", "png", "pdf", "tiff"]}
      />
    )
  }

  return (
    <>
      <Dialog
        open={openDialog}
        title="Excluir documento"
        confirmText="Excluir"
        cancelText="Cancelar"
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
      >
        Deseja <b>realmente</b> excluir o documento?
      </Dialog>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          alignItems={'center'}
          display='flex'
          width="fit-content"
          fontWeight="bold"
          gap={4}
        >
          <Chip>{identifier}</Chip>
          <Button
            onClick={handleDelete}
            color="secondary"
            size="small"
            StartIcon={
              <FaTrash
                size={18}
                color={!!currentData?.inserted ? theme.palette.negative?.pure : theme.palette.neutral[100]}
              />
            }
            disabled={!!!currentData?.inserted}
          />
        </Box>
        {

          currentData?.inserted ? (
            <FileContent>
              <FileImage src={currentData?.link} />
            </FileContent>
          ) : (
            <Paper variant="outlined">
              <Box display="flex" flexDirection="column" gap={2} p={4}>
                <Box display="flex" alignItems="center" justifyContent="center" gap={4}>
                  <TextField placeholder="https://imagem.png" name={`${name}.link`} fullWidth />
                  <Button
                    color="secondary"
                    onClick={handleInsertLink}
                  >
                    Inserir
                  </Button>
                </Box>
                <Error>
                  <>
                    {error?.message || error?.inserted?.message}
                  </>
                </Error>
              </Box>
            </Paper>
          )
        }
      </Box>
    </>
  )
}

export default Component;