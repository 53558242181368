import { GridColDef } from "@mui/x-data-grid";
import { BillingComponents, GeneralComponents } from "@components";
import { isDesktopWidth } from "./screenWidth";
import { FileDownload } from "../pages/Tools/FileTransfer/components";
import FileTransferDetailsCell from "../pages/Tools/FileTransfer/components/FileTransferDetailsCell";
import { Box } from "@mui/material";
import DeleteFile from "../pages/Tools/FileTransfer/components/DeleteFile";

const FileColumns: GridColDef[] = isDesktopWidth ? [
    {
        field: "FileName",
        headerName: "Nome do arquivo",
        flex: 20,
    },
    {
        field: "FileExtension",
        headerName: "Extensão do arquivo",
        renderCell: (params) => <GeneralComponents.Tag>{params.value}</GeneralComponents.Tag>,
        flex: 20,
    },
    {
        field: "Origin",
        headerName: "Entrada/Saída",
        renderCell: (params) => <GeneralComponents.Tag variant={params?.value === 'Entrada' ? 'neutral' : 'positive'}>{params.value}</GeneralComponents.Tag>,
        flex: 20,
    },
    {
        field: "Folder",
        headerName: "Pasta",
        renderCell: (params) => <GeneralComponents.Tag>{params.value}</GeneralComponents.Tag>,
        flex: 20,
    },
    {
        field: "Size",
        headerName: "Tamanho do arquivo",
        renderCell: (params) => <GeneralComponents.Tag>{params.value}</GeneralComponents.Tag>,
        flex: 20,

    },
    {
        field: "LastModified",
        headerName: "Data de upload",
        flex: 20,
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        renderCell: (params) => <Box
            display={'flex'}
            gap={'4px'}
        >
            <FileDownload params={params} />
            <DeleteFile params={params}/>
        </Box>,
        flex: 20,
    }
]
    :
    [
        {
            field: "FileName",
            headerName: "Nome do arquivo",
            flex: 20,
        },
        {
            field: "details",
            type: "actions",
            headerName: "Detalhes",
            renderCell: (params) => <FileTransferDetailsCell params={params} />,
            flex: 20,
        },
    ]

export default FileColumns;
