import React from "react";

import { Box, Grow } from "@mui/material";
import { Paper } from "@components";
import { Title, Subtitle, BigScan, SmallScan, Spinner } from "./styles";

import { Player } from "@lottiefiles/react-lottie-player";
import Animation from "./animation.json";

const subtitles = [
  "Lendo o documento",
  "Classificando o documento",
  "Extraindo os dados",
  "Analisando consistência dos dados",
  "Comparando com dados públicos",
  "Estruturando resultados",
  "Organizando informações"
]

const Component = () => {
  const [subtitle, setSubtitle] = React.useState(subtitles[0]);
  const [rotate, setRotate] = React.useState(0);

  React.useEffect(() => {
    updateLoading();
  }, [subtitle]);

  const updateLoading = () => {
    setTimeout(() => {
      const index = subtitles.findIndex((element) => element === subtitle);
      const nextIndex = index === subtitles.length - 1 ? 0 : index + 1;
      const nextRotate = rotate === 0 ? 90 : 0;

      setSubtitle(subtitles[nextIndex]);
      setRotate(nextRotate)
    }, 2000);
  }

  return (
    <Paper>
      <Box display="flex" alignItems="center" justifyContent="center" px={{ xs: 0, md: 18 }} py={{ xs: 4, md: 18 }} textAlign="center">
        <Box display="flex" alignItems="center" flexDirection="column" gap={6}>
          <Title>Lendo informações</Title>
          <Box position="relative" display="flex" alignItems="center" justifyContent="center" width="100%">
            {
              subtitles.map((text) => {
                const show = text === subtitle;

                return (
                  <Grow
                    in={show}
                    style={{ transformOrigin: '0 0 0' }}
                    {...(show ? { timeout: 1000 } : {})}
                  >
                    <Subtitle>{text}</Subtitle>
                  </Grow>
                )
              })
            }
          </Box>
          <Spinner rotate={rotate} />
          <Box display="flex" alignItems="center" justifyContent="center">
            <SmallScan mr={-25}>
              <Player
                autoplay
                loop
                src={Animation}
                direction={-1}
              />
            </SmallScan>
            <BigScan>
              <Player
                autoplay
                loop
                src={Animation}
                direction={-1}
              />
            </BigScan>
            <SmallScan ml={-25}>
              <Player
                autoplay
                loop
                src={Animation}
                direction={-1}
              />
            </SmallScan>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Component;