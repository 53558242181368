import { styled } from '@mui/system'
import { Button, Dialog, DialogActions, DialogTitle, DialogProps } from '@mui/material';

interface ConfirmationDialogProps extends DialogProps {
    onConfirm: () => void
    onCancel: () => void
}

const DialogContainer = styled(Dialog)`
    
    .MuiPaper-root{
        padding: 16px 32px 32px 32px;
        heigth: 220px;
        width: 400px;
    }

    .MuiDialogTitle-root{
        color: #006;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid #DDE7EA;
        padding-left: 0;
    }

    .MuiDialogContent-root{
        font-family: 'Be Vietnam';
        font-style: normal;
        color: #555866;
        font-weight: 400;
        font-size: 14px;
        padding-top: 16px;
        padding-left: 0;
    }

    .MuiDialogActions-root{
        padding: 0;
        padding-top: 16px;
    }

    .confirmation-dialog-leave-button{
        background-color: transparent;
        font-family: 'Picadilly';
        font-style: normal;
        font-weigth: 600;
        font-size: 14px;
        color: #FF003C;
        text-transform: none;

        :hover{
            font-weight: 700;
        }
    }

    .confirmation-dialog-save-button{
        background: #0068FF;
        border-radius: 8px;
        padding: 8px 12px;
        font-family: 'Picadilly';
        font-style: normal;
        font-weigth: 600;
        font-size: 14px;
        text-transform: none;
    }
`



const ConfirmationDialog = styled(({ open, onClose, onCancel, onConfirm }: ConfirmationDialogProps) => (
    <DialogContainer open={open} onClose={onClose}>
        <DialogTitle>Tem certeza que deseja alterar este usuário?</DialogTitle>
        <DialogActions>
            <Button onClick={onCancel} className='confirmation-dialog-leave-button' variant='text'>
                Cancelar
            </Button>
            <Button onClick={onConfirm} className='confirmation-dialog-save-button' variant="contained">
                Confirmar
            </Button>
        </DialogActions>
    </DialogContainer>
))` 
    background-color: #006;

`;


export default ConfirmationDialog;