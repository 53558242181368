import { GridColDef } from "@mui/x-data-grid";
import ProductTag from "../components/GeneralComponents/ProductTag";
import dayjs from "dayjs";
import { isDesktopWidth } from "./screenWidth";
import { BillingComponents } from "@components";
require('dayjs/locale/pt-br')


const ConsumptionColumns: GridColDef[] = isDesktopWidth ? [
    {
        field: 'Product',
        headerName: 'Produto',
        renderCell: (params) => <ProductTag params={params.value} />,
        width: 100
    },
    { field: 'Month', headerName: 'Mês de referência', flex: 22, valueFormatter: (params) => dayjs(params.value, 'YYYY_MM').format('MMMM [de] YYYY') },
    { field: 'FreeRequests', headerName: 'Requisições gratuitas', flex: 22, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR') },
    { field: 'PaidRequests', headerName: 'Requisições pagas', flex: 20, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR') },
    { field: 'TotalRequests', headerName: 'Total de requisições', flex: 22, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR') },
    { field: 'AveragePrice', headerName: 'Preço médio', flex: 14, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    { field: 'VolumeDiscount', headerName: 'Descontos', flex: 20, valueFormatter: (params) => parseFloat(params.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) },
    {
        field: 'Price',
        headerName: 'Valor',
        flex: 20,
        valueFormatter:
            (params) =>
                parseFloat(params.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
    }
]
    :
    window.screen.width < 390 ?

    [
         {
            field: 'Product',
            headerName: 'Produto',
            renderCell: (params) => <ProductTag params={params.value} />,
            
            width: 85,
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'Month',
            headerName: 'Mês',
            width: 100, valueFormatter: (params) => dayjs(params.value, 'YYYY_MM').format('MMM [de] YYYY'),
            align: 'center',
            headerAlign: 'center'
        },
        {
            field: 'consumptionDetails',
            headerName: 'Detalhes',
            type: 'actions',
            align: 'center',
            headerAlign: 'center',
            width: 80,
            renderCell: (params) => <BillingComponents.ConsumptionDetailsCell params={params} />
        }
    ]

    :

    [
        {
           field: 'Product',
           headerName: 'Produto',
           renderCell: (params) => <ProductTag params={params.value} />,
           
           flex: 33,
           align: 'center',
           headerAlign: 'center'
       },
       {
           field: 'Month',
           headerName: 'Mês',
           flex: 33,
           valueFormatter: (params) => dayjs(params.value, 'YYYY_MM').format('MMM [de] YYYY'),
           align: 'center',
           headerAlign: 'center'
       },
       {
           field: 'consumptionDetails',
           headerName: 'Detalhes',
           type: 'actions',
           align: 'center',
           headerAlign: 'center',
           flex: 33,
           renderCell: (params) => <BillingComponents.ConsumptionDetailsCell params={params} />
       }
   ]
    ;

export default ConsumptionColumns