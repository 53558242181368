import React from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

interface ContextProps {
  field: ControllerRenderProps<FieldValues, string> | any
}

const CheckboxContext = React.createContext<ContextProps>({
  field: {}
});

export default CheckboxContext;