import { styled } from '@mui/material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogProps } from '@mui/material';

interface ConfirmationDialogProps extends DialogProps {
    title?: string,
    content?: string,
    confirmText?: string,
    cancelText?: string,
    onConfirm: any
    onLeave: any
}

const DialogContainer = styled(Dialog)`
    
    .MuiPaper-root{
        padding: 16px 32px 32px 32px;
        heigth: 220px;
        min-width: 280px;
        max-width: 400px;
    }

    .MuiDialogTitle-root{
        color: #006;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid #DDE7EA;
        padding-left: 0;
    }

    .MuiDialogContent-root{
        font-family: 'Be Vietnam';
        font-style: normal;
        color: #555866;
        font-weight: 400;
        font-size: 14px;
        padding-top: 16px;
        padding-left: 0;
    }

    .MuiDialogActions-root{
        padding: 0;
        padding-top: 16px;
    }

    .unsaved-dialog-leave-button{
        background-color: transparent;
        font-family: 'Picadilly';
        font-style: normal;
        font-weigth: 600;
        font-size: 14px;
        color: #FF003C;
        text-transform: none;

        :hover{
            font-weight: 700;
        }
    }

    .unsaved-dialog-save-button{
        background: #0068FF;
        border-radius: 8px;
        padding: 8px 12px;
        font-family: 'Picadilly';
        font-style: normal;
        font-weigth: 600;
        font-size: 14px;
        text-transform: none;
    }
`



const ConfirmationDialog = styled((
    {
        open,
        onClose,
        onLeave,
        onConfirm,
        title = 'Suas alterações não estão salvas',
        content = 'Tem certeza que deseja sair sem salvar?',
        cancelText = 'Sair sem salvar',
        confirmText = 'Salvar mudanças'
    }: ConfirmationDialogProps) => (
    <DialogContainer open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
            <Button onClick={onLeave} className='unsaved-dialog-leave-button' variant='text' disableElevation disableTouchRipple disableFocusRipple disableRipple>
               {cancelText}
            </Button>
            <Button onClick={onConfirm} className='unsaved-dialog-save-button' variant="contained" disableElevation disableTouchRipple disableFocusRipple disableRipple>
                {confirmText}
            </Button>
        </DialogActions>
    </DialogContainer>
))` 
    background-color: #006;

`;


export default ConfirmationDialog;