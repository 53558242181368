import React, { Children, cloneElement } from "react";

import { Box, useFormControl, useRadioGroup } from "@mui/material";
import { Radio } from "./styles";
import { useController, useFormContext } from "react-hook-form";

interface ComponentProps {
  children: React.ReactNode,
  value: string | any,
  id?: string,
  disabled?: boolean
}

const Component: React.FC<ComponentProps> = ({ children, value, id, disabled = false }) => {
  const radioGroup = useRadioGroup()

  const { field } = useController({ name: radioGroup?.name || "" })

  const handleChange = () => {
    field.onChange(value)
  }

  return (
    <>
      {
        Children.map(children, (child: any) => {
          return (
            <>
              <Box component="label" htmlFor={id || value} id={`option-${id || value}`} sx={{ opacity: disabled ? 0.5 : 1 }}>
                <Radio
                  id={id || value}
                  value={value}
                  onChange={handleChange}
                  disabled={disabled}
                />
                {cloneElement(child)}
              </Box>
            </>
          )
        })
      }
    </>
  )
}

export default Component