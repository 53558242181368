import { Button } from '@mui/material';
import { styled } from '@mui/system';

const FilterButton = styled(Button)`
    background-color: #FFF;
    border: none;
    text-transform: none;
    padding: 8px 12px;
    color: #0068ff;
    transition: all .5s ease;
    font-family: Be Vietnam;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    border-radius: 8px;
    height: 40px;

    :hover{
        background-color: #006;
        color: #fff;
        transition: all .3s ease;
    }

`;

export default FilterButton;