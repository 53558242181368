import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DisableTokenIdDialog {
    showDialog: boolean
}
  
const initialState: DisableTokenIdDialog = {
    showDialog: false
}

const disableTokenIdDialogSlice = createSlice({
    name: 'disableTokenIdDialog',
    initialState,
    reducers: {
      setShowDialog: (state, action: PayloadAction<boolean>) => {
        state.showDialog = action.payload
      }
    }
});

export const { setShowDialog } = disableTokenIdDialogSlice.actions;

export default disableTokenIdDialogSlice.reducer;