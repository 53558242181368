import { IconType } from "react-icons";

import { Box, RadioGroup, FormControl } from "@mui/material";

import { Radio, Item, Checked, Unchecked, Title, Description, Icon } from "./styles";

import { useFormContext, Controller } from "react-hook-form";

interface RadioCardProps {
  name: string,
  options: ItemRadioCardProps[],
  perRow?: number
}

interface ItemRadioCardProps {
  value: string,
  title: string,
  description: string,
  icon?: IconType
}

const RadioCard: React.FC<RadioCardProps> = ({ name, options, perRow = 3 }) => {
  const {
    control
  } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <RadioGroup
            {...field}
            row
            onChange={(value) => field.onChange(value)}
            value={field.value}
          >
            <Box display="grid" gridTemplateColumns={`repeat(${perRow}, 1fr)`} width="100%" gap={4}>
              {options.map((option, index) => (
                <>
                  <Radio
                    id={option.value}
                    value={option.value}
                    icon={<Unchecked />}
                    checkedIcon={<Checked />}
                    disableRipple
                  />
                  <Item variant="outlined" checked={field.value === option.value} clickable>
                    <Box component="label" htmlFor={option.value} id={`option-${option.value}`}>
                      <Box display="flex" alignItems="center" gap={6} px={4} py={6}>
                        {
                          option.icon && (
                            <Icon>
                              <option.icon size={40} />
                            </Icon>
                          )
                        }
                        <Box display="flex" flexDirection="column" gap={4}>
                          <Box display="flex" alignItems="center">
                            <Title>{option.title}</Title>
                          </Box>
                          <Description>{option.description}</Description>
                        </Box>
                      </Box>
                    </Box>
                  </Item>
                </>
              ))}
            </Box>
          </RadioGroup>
        </FormControl>
      )}
    />
  )
}

export default RadioCard