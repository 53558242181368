import { Step } from "react-joyride";
import { isDesktopWidth } from '@constants';

const steps: Step[] = [
  {
    target: '.user-management-main-container',
    title: 'Gerencie seus usuários com facilidade',
    content: 'Descubra o que pode ser feito aqui com nosso tour pela gestão de usuários.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    disableScrolling: isDesktopWidth
  },
  {
    target: '.create-user-button',
    title: 'Adicione membros à sua equipe',
    content: 'Crie usuários para incorporar novos membros à seu domínio no BDC Center.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-start' },
    disableScrolling: isDesktopWidth,

  },
  {
    target: isDesktopWidth ? '.filter-section-container' : '.mobile-filter-button',
    title: 'Encontre o que precisa mais rápido',
    content: 'Utilize os filtros para buscar por usuários de acordo com suas preferências.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-start' },
    disableScrolling: isDesktopWidth
  },
  {
    target: isDesktopWidth ? '.user-management-list-container' : '.view-user-cell',
    title: 'Controle de usuários',
    content: 'Visualize um perfil de usuário ou edite através das ações rápidas.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'top-end' : 'bottom-start' }
  },
  {
    target: '.user-management-main-container',
    title: 'Tour finalizado',
    content: `Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra ${isDesktopWidth ? 'superior' : 'inferior'}. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.`,
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'center' }
  },
];

export default steps;