import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface quickConsultDialogInfo {
    dismissDialog: boolean;
    user: string;
}

const initialState: quickConsultDialogInfo = {
    dismissDialog: false,
    user: ''
}

const quickConsultDialog = createSlice({
    name: 'quickConsultDialog',
    initialState,
    reducers: {
        setDismissDialog: (state, action: PayloadAction<quickConsultDialogInfo>) => {
            return { ...state, ...action.payload }
        }
    }
});

export const {  setDismissDialog } = quickConsultDialog.actions;

export default quickConsultDialog.reducer;