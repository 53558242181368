import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { DashboardComponents } from '@components';
import LastUsedItem from './LastUsedItem';
import LastUsedText from './LastUsedText';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';

import { reducers } from '@store';

import './lastUsed.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BiInfoCircle } from 'react-icons/bi';
import { Typography } from '@mui/material';


export default function LastUsed() {

    const isSystemAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin)
    const lastUsedFeatures = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.lastUsed.features)

    return <section className={isSystemAdmin ? 'last-used-container' : 'non-admin-last-used-container'}>
        <div className='last-used-content'>
            <Typography
                variant='h2'
                component={'h2'}
            >Recentes</Typography>

            {lastUsedFeatures.length > 0 ?
                <DashboardComponents.SliderContainer >
                    {lastUsedFeatures.map(feature => {

                        return <LastUsedItem key={feature.name}>
                            <ListItemButton component={Link} to={feature.link}>
                                <div className='list-item-header'>
                                    <ListItemIcon>
                                        <span className='list-item-icon-background'>
                                            <FontAwesomeIcon icon={feature.icon} color='#00347F' size='1x' />
                                        </span>
                                    </ListItemIcon>
                                    <LastUsedText primary={feature.name} />
                                </div>
                                <div className='list-item-texts'>
                                    <LastUsedText secondary={feature.description} />
                                </div>
                            </ListItemButton>
                        </LastUsedItem>
                    })
                    }
                </DashboardComponents.SliderContainer>
                :
                <div className='last-used-note-container'>
                    <div className='last-used-note-content'>
                        <BiInfoCircle color='#0048B2' size={20} />
                        <div className='last-used-note-texts'>
                            <span>Acesse os recursos utilizados!</span>
                            <p>Ao acessar um recurso ele ficará disponível aqui para que você possa acessá-lo de forma fácil e rápida.</p>
                        </div>
                    </div>
                </div>
            }

        </div>
    </section>
}