import React, { useEffect, useRef } from 'react';
import { MobileSideSection } from '../../../../components/GeneralComponents';
import './invoiceDetails.css'
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import { BankSlips, GeneralComponents } from '@components';
import { isInvoiceOverdue } from '@utils'
import dayjs from 'dayjs';

interface InvoiceDetailsProps {
    groupIssuesBankSlips: boolean
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({ groupIssuesBankSlips }) => {

    const dispatch = useDispatch()
    const { open, params } = useSelector((state: reducers.RootState) => state.invoiceDetailsSectionReducer)
    const sideSectionRef = useRef(null);

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.invoiceDetails.setShowInvoiceDetailsSection(false))
    }

    const getPDFInvoice = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        const url = `https://notacarioca.rio.gov.br/contribuinte/notaprint.aspx?ccm=04062540&nf=${params?.InvoiceNumber}&cod=${params?.InvoiceVerificationCode.replace('-', '')}`;
        window.open(url, '_blank');
    };

    const renderStatus = (params: any) => {

        const hasAnyOverdueInstallment = () => params?.Installments ? params.Installments
        .filter((installment: any) => dayjs(installment.PaymentDueDate).isBefore(dayjs())) : []
      
        if (hasAnyOverdueInstallment().length > 0) {
          return 'OVERDUE'
        } else {
          return params?.Status
        }
    }

    const renderBankSlipButton = (params: any) => {

        if (params?.Status !== 'PAID' && params?.Status !== 'CANCELLED') {
            return <BankSlips.InstallmentsButton params={params} />
        } else if (params?.Status === 'PAID') {
            return <span>Regularizado</span>
        } else if (params?.Status === 'CANCELLED') {
            return <span>Cancelado</span>
        }
    }

    return <MobileSideSection
        sideSectionTitle='Detalhes da fatura'
        mainButtonType='default'
        mainButtonText='Ver nota fiscal'
        mainButtonAction={getPDFInvoice}
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
        <div className='invoice-details-content'>
            <h4 className='invoice-details-content-title'>Detalhes</h4>
            <div className='invoice-details-data'>
                <div className='user-profile-fields'>
                    <label>Mês de referência: </label>
                    <span>{params?.ReferenceMonth}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Número da fatura: </label>
                    <span>{params?.InvoiceNumber}</span>
                </div>
                <div className='user-profile-fields'>
                    <label>Status: </label>
                    <GeneralComponents.StatusTag value={renderStatus(params)}></GeneralComponents.StatusTag>
                </div>
                {groupIssuesBankSlips ?
                    <div className='user-profile-fields'>
                        <label>Boleto bancário: </label>
                        {renderBankSlipButton(params)}
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    </MobileSideSection>

}

export default InvoiceDetails;