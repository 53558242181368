import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import News from './News'
import LastUsed from './LastUsed'
import BillingSummary from './BillingSummary'
import { useNotificationCenterContext } from '../../components/NotificationCenterProvider'
import './dashboard.css'
import { Billing } from '@services'
import { showToastCustomErrorMessage, showToastCustomInfoMessage, isThereAnyOverdueInvoice, transformInvoiceToValidRows, handleAxiosError } from '@utils'

import { reducers, slices } from "@store";
import QuickOptions from './QuickOptions'

import OnboardingBanner from './OnboardingBanner'
import { GridRowModel } from '@mui/x-data-grid'
import dayjs from 'dayjs'

import { steps } from "@constants";
import { Onboarding } from "@components";

const Dashboard = () => {

    const { isSystemAdmin, login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)
    const { add, notifications } = useNotificationCenterContext()
    const [isWideScreen, setIsWideScreen] = useState(false);
    const [invoiceRows, setInvoiceRows] = useState<GridRowModel[]>();

    const listInvoices = async () => await Billing.listInvoices({
        Pagination: { Page: 1, PageSize: 100 }
    })

    const handleListInvoicesApiResponse = (billingApiResponse: any) => {

        if (billingApiResponse?.Message === 'NO_INVOICES_FOUND') {
            return
        }

        setInvoiceRows(transformInvoiceToValidRows(billingApiResponse))
        return
    }

    const notificationAlreadyExists = (invoice: any) => {
        if (!!notifications?.length) {
            return !!notifications.filter((notification) => notification.id === `overdue-invoice-${invoice.InvoiceNumber}`).length
        } 

        return false
    }

    const handleOverdueInvoicesPossibility = useCallback((invoiceRows: any) => {
        if (!!invoiceRows) {
            const overdueInvoices = isThereAnyOverdueInvoice(invoiceRows ?? [])

            if (!!overdueInvoices?.length) {
                overdueInvoices.map((invoice: any) => {
                    if (notificationAlreadyExists(invoice)) {
                        return
                    }

                    add({
                        id: `overdue-invoice-${invoice?.InvoiceNumber}`,
                        read: false,
                        data: {
                            user: login,
                            message: 'Faturas em atraso!',
                            description: `Regularize a situação do pagamento da fatura de ${dayjs(invoice?.ReferenceMonth, 'MM/YYYY', 'pt-br').format('MMMM [de] YYYY')}.`
                        }
                    })
                })

                handleUnreadNotificationPossibility()
                return
            }

            return
        }
    }, [invoiceRows])

    const handleUnreadNotificationPossibility = () => {
        if (!!notifications?.length) {

            const unreadNotifications = !!notifications.filter((notification) => !!!notification?.read)?.length

            if (!!unreadNotifications) {
                showToastCustomInfoMessage('Você tem notificações não-lidas.', 'Cheque a sua central de notificações para saber mais.')
                return
            } 

            return
        }
    }

    const tryListInvoices = async () => {
        try {
            const { data: apiBillingManagementApiResponse } = await listInvoices()
            handleListInvoicesApiResponse(apiBillingManagementApiResponse)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    useEffect(() => {

        if (isSystemAdmin) {
            tryListInvoices()
        }

        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 1167);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        handleOverdueInvoicesPossibility(invoiceRows)
    }, [handleOverdueInvoicesPossibility])


    return (
        <main className='dashboard-main-container'>
            {isSystemAdmin ?
                <>
                    <div className='dashboard-chart-and-quick-options-container'>
                        <BillingSummary />
                        <QuickOptions />
                    </div>
                    <div className='dashboard-last-used-and-onboarding-container'>
                        <LastUsed />
                        <OnboardingBanner />
                    </div>
                </>
                :
                isWideScreen ?
                    <>
                        <div className='dashboard-chart-and-quick-options-container'>
                            <QuickOptions />
                            <OnboardingBanner />
                        </div>
                        <div className='dashboard-last-used-and-onboarding-container'>
                            <LastUsed />
                        </div>
                    </>
                    :
                    <>
                        <div className='dashboard-chart-and-quick-options-container'>
                            <QuickOptions />
                        </div>
                        <div className='dashboard-last-used-and-onboarding-container'>
                            <LastUsed />
                            <OnboardingBanner />
                        </div>
                    </>
            }
            <div className={'dashboard-news-container'}>
                <News />
            </div>
            <Onboarding
                steps={steps.Dashboard}
                controlPath="Dashboard"
            />
        </main>
    )
}

export default Dashboard