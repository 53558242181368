import { Box } from "@mui/material";
import { Paper, RadioGroup, Onboarding } from "@components";
import { Header } from "../../components";

import { options, steps } from "@constants";
import { useFormContext } from "react-hook-form";
import { Description, Icon, Item, Title } from "../styles";

const Openned = () => {

  const { watch } = useFormContext();
  const checked = watch().api

  return (
    <>
      <Paper>
        <Box p={6}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Header title="Selecione a API utilizada na consulta" subtitle="Consulte suas consultas rápidas salvas ou crie novas." />
            <RadioGroup.Container name="api">
              <Box display="grid" gridTemplateColumns={`repeat(3, 1fr)`} gridAutoRows="1fr" width="100%" gap={4} id="api-options">
                {
                  options.pontualConsult.apis.map((option, index) => (
                    <RadioGroup.Item value={option.value}>
                      <Item variant="outlined" checked={checked === option.value} clickable>
                        <Box component="label" htmlFor={option.value}>
                          <Box display="flex" alignItems="flex-start" height="100%" gap={6} px={4} py={6}>
                            <Box display="flex" alignItems="center">
                              {
                                !!option.icon && (
                                  <Icon>
                                    <option.icon size={40} />
                                  </Icon>
                                )
                              }
                            </Box>
                            <Box display="flex" flexDirection="column" gap={4}>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Title>{option.title}</Title>
                              </Box>
                              <Description dangerouslySetInnerHTML={{ __html: option.description }} />
                            </Box>
                          </Box>
                        </Box>
                      </Item>
                    </RadioGroup.Item>
                  ))
                }
              </Box>
            </RadioGroup.Container>
          </Box>
        </Box>
      </Paper>
      <Onboarding
        steps={steps.PontualConsult.Api}
        controlPath='PontualConsult.Api'
      />
    </>
  )
}

export default Openned;