import Admin from "./Admin"
import Auth from "./Auth"
import Dashboard from "./Dashboard"
import * as BigId from "./BigId";
import FileTransferCheck from "./FileTransferCheck"

export {
  Admin,
  Auth,
  Dashboard,
  FileTransferCheck,
  BigId,
}