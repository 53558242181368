import { GeneralComponents } from '@components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { slices } from '@store';
import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

interface FileTransferDetailsProps {
    params: GridRenderCellParams<any>
}
const FileTransferDetails: React.FC<FileTransferDetailsProps> = ({ params }) => {

    const dispatch = useDispatch()

    const handleFileTransferDetailsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.fileTransferDetails.setParams(params.row))
        dispatch(slices.fileTransferDetails.setShowFileTransferDetailsSection(true))
    }

    return <GeneralComponents.StyledGridActionsCellItem
        icon={<AiOutlineFileSearch color="#0068ff" size={24} />}
        label="Detalhes da fatura"
        onClick={handleFileTransferDetailsClick}
    />
}

export default FileTransferDetails;