import styled from '@emotion/styled';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridCsvExportOptions } from '@mui/x-data-grid';

const useStyles = makeStyles(() => ({
  exportButton: {
    marginTop: '-16px',
    padding: '8px 12px',
    borderRadius: '8px',
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '140%',
    color: '#000066',
    textTransform: 'none',
    transition: 'background-color .5s ease',
    '&:hover': {
      backgroundColor: '#EDF2F4;',
      transition: 'background-color .5s ease'
    }
  },
}));

const csvExportOptions = {
  fileName: 'Data from Center',
  utf8WithBom: true, 
  skipHeader: false,
};

const printOptions = {
  disableToolbarButton: true
};

const ExportToolbar = () => {
  const classes = useStyles();

  return <GridToolbarContainer  sx={{position: 'relative', justifyContent: 'flex-end', alignItems: 'center' , height: 'fit-content', padding: 0}}>
    <GridToolbarExport className={classes.exportButton} csvOptions={csvExportOptions} printOptions={printOptions}/>
  </GridToolbarContainer>
}
const StyledDataGridWithPagination = styled(DataGrid)`
      
      && {
        border: none;
        font-family: 'Be Vietnam';
        width: 100%;
      
      }

      .MuiDataGrid-columnHeaders{
        background-color: #f0f8fa;
        min-height: 45px;
        max-height: 45px;
      }
  
      .MuiDataGrid-row{
        min-height: 40px;
        max-height: 60px;
        border-bottom: 1px solid #f0f8fa;
        transition: background-color .5s ease;
      }
  
      .MuiDataGrid-columnHeaderTitle{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 140%;
        color: #000066;
      }
  
      .MuiDataGrid-cell{
        border-bottom: none;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #555866;
      }
      
      .MuiDataGrid-cell:focus{
        outline: none;
      }
      
      .MuiDataGrid-row:hover{
        background-color: #f0f8fa;
        transition: background-color .5s ease;
      }

      .MuiDataGrid-toolbarContainer{
        padding: 8px;
        box-sizing: border-box;
      }
  
      .MuiDataGrid-selectedRowCount{
        display: none;
      }

      .MuiDataGrid-footerContainer{
        border: none;
      }

      .MuiDataGrid-menuIcon{
        display: none;
      }

      .MuiCheckbox-root.Mui-checked{
        color: #0068ff;
      }


      @media screen and (max-width: 500px){
        .MuiDataGrid-cell{
          border-bottom: none;
          font-family: 'Be Vietnam';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: #555866;
        }
      }
  `



export { StyledDataGridWithPagination, ExportToolbar };