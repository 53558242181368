import { GeneralComponents } from '@components';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FileTransferCenter } from '@services';
import { handleAxiosError } from '@utils';
import React from 'react';
import { BsDownload } from 'react-icons/bs';


interface FileDownloadProps {
    params: GridRenderCellParams<any>
}

const FileDownload: React.FC<FileDownloadProps> = ({ params }) => {

    const { Key: key } = params?.row

    const getFileByteArray = async () => FileTransferCenter.getFile({
        key
    })

    const saveByteArray = (fileName: string, base64File: any, type: string) => {

        const url = `data:${type};base64,${base64File}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const tryGetFileByteArray = async () => {
        try {
            const { data: apiResponse } = await getFileByteArray()
            const { fileName, base64File, fileType } = apiResponse
            saveByteArray(fileName, base64File, fileType)
        }
        catch (err: any) {
            handleAxiosError(err)
        }
    }

    return <GeneralComponents.StyledGridActionsCellItem
        label='Baixar arquivo'
        icon={<BsDownload size={20} color="#0068FF" />}
        onClick={tryGetFileByteArray}
    />;
}

export default FileDownload;