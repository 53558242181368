import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface seePdfInfo {
    pdfBytes: string,
    referenceMonth: string,
}

const initialState: seePdfInfo = {
    pdfBytes: '',
    referenceMonth: ''
}

const seePdfInfoReducer = createSlice({
    name: 'seePdf',
    initialState,
    reducers: {
        setSelectedPdfBytes: (state, action: PayloadAction<string>) => {
            state.pdfBytes = action.payload
        },
        setReferenceMonth: (state, action: PayloadAction<string>) => {
            state.referenceMonth = action.payload
        }
    }
});

export const { setSelectedPdfBytes, setReferenceMonth } = seePdfInfoReducer.actions;

export default seePdfInfoReducer.reducer;