import { styled } from "@mui/material/styles"

import { Box, Tab as TabMaterial, Tabs as TabsMaterial } from "@mui/material";

const DocumentContent = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutral?.[500]}`
}));

const TicketContent = styled(Box)(({ theme }) => ({
  fontFamily: "Be Vietnam",
  backgroundColor: theme.palette.neutral?.[500]
}));

const TicketTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue,
}));

const TicketValue = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}));

const Tabs = styled(TabsMaterial)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.blue,
    height: 2,
  },
  "& .MuiTab-root.Mui-selected": {
    color: theme.palette.primary.blue,
    fontWeight: 700
  },
}));

const Tab = styled(TabMaterial)(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: "#32343C",
  textTransform: "none"
}));

export {
  DocumentContent,
  TicketContent,
  TicketTitle,
  TicketValue,
  Tabs,
  Tab
}