// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.note-container{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.note-content{
    background-color: #E5EFFF;
    padding: 32px;
    max-width: -moz-fit-content;
    max-width: fit-content;
    display: flex;
    gap: 8px;
    border-radius: 12px;
}

.note-texts{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0;
}

.note-texts span{
    color: var(--blue-medium, #0048B2);
    font-family: 'Be Vietnam';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.note-texts p{
    color: var(--to-approval-neutral-low-low-medium, #555866);
    font-family: Be Vietnam;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

@media screen and (max-width: 500px){

    .note-container{
        max-width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    

    .note-content{
        background-color: #E5EFFF;
        padding: 32px 16px;
        width: 90%;
        display: flex;
        gap: 8px;
        border-radius: 12px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/InfoNote/noDataNote.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,2BAAsB;IAAtB,sBAAsB;IACtB,aAAa;IACb,QAAQ;IACR,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,SAAW;AACf;;AAEA;IACI,kCAAkC;IAClC,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yDAAyD;IACzD,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;;IAEI;QACI,eAAe;QACf,YAAY;QACZ,aAAa;QACb,uBAAuB;QACvB,mBAAmB;IACvB;;;IAGA;QACI,yBAAyB;QACzB,kBAAkB;QAClB,UAAU;QACV,aAAa;QACb,QAAQ;QACR,mBAAmB;IACvB;AACJ","sourcesContent":[".note-container{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.note-content{\n    background-color: #E5EFFF;\n    padding: 32px;\n    max-width: fit-content;\n    display: flex;\n    gap: 8px;\n    border-radius: 12px;\n}\n\n.note-texts{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 4px;\n    flex: 1 0 0;\n}\n\n.note-texts span{\n    color: var(--blue-medium, #0048B2);\n    font-family: 'Be Vietnam';\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 140%;\n}\n\n.note-texts p{\n    color: var(--to-approval-neutral-low-low-medium, #555866);\n    font-family: Be Vietnam;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 140%;\n}\n\n@media screen and (max-width: 500px){\n\n    .note-container{\n        max-width: 100%;\n        height: 100%;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n    }\n    \n\n    .note-content{\n        background-color: #E5EFFF;\n        padding: 32px 16px;\n        width: 90%;\n        display: flex;\n        gap: 8px;\n        border-radius: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
