import { GridColDef } from "@mui/x-data-grid";
import { BillingComponents, GeneralComponents } from "@components";
import { isDesktopWidth } from "./screenWidth";

const PartialColumns: GridColDef[] = isDesktopWidth ? [
  {
    field: "Product",
    headerName: "Produto",
    renderCell: (params) => <GeneralComponents.ProductTag params={params.value} />,
    width: 100,
  },
  {
    field: "FreeRequests",
    headerName: "Requisições gratuitas",
    flex: 22,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR"),
  },
  {
    field: "PaidRequests",
    headerName: "Requisições pagas",
    flex: 20,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR"),
  },
  {
    field: "TotalRequests",
    headerName: "Total de requisições",
    flex: 22,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR"),
  },
  {
    field: "AveragePrice",
    headerName: "Preço médio",
    flex: 14,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
  },
  {
    field: "VolumeDiscount",
    headerName: "Descontos",
    flex: 20,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
  },
  {
    field: "Price",
    headerName: "Valor",
    flex: 20,
    valueFormatter: (params) =>
      parseFloat(params.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      }),
  },
  {
    field: "details",
    type: "actions",
    headerName: "Ações",
    flex: 20,
    cellClassName: "actions",
    renderCell: (params) => (
      <BillingComponents.SeeDetailsCell params={params} />
    ),
  },
]
:
[
  {
    field: "Product",
    headerName: "Produto",
    align: 'left',
    headerAlign: 'left',
    renderCell: (params) => <GeneralComponents.ProductTag params={params.value} />,
    flex: 50,
  },
  {
    field: "details",
    type: "actions",
    headerName: "Detalhes",
    align: 'center',
    headerAlign: 'center',
    flex: 50,
    cellClassName: "actions",
    renderCell: (params) => (
      <BillingComponents.CurrentUsageDetailsCell params={params} />
    ),
  },
]
;

export default PartialColumns;
