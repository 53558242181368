// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.template-container{
    position: relative;
    width: 100vw;
    min-height: 100vh;
    display: grid;
    grid-template-areas:
      "nav header"
      "nav main "
      "nav footer";
    grid-template-columns: 94px calc(100% - 94px);
    grid-template-rows: 62px minmax(auto, 100%) 83px;
    background-color: #F3F8FA;
}

@media screen and (max-width: 500px){
    .template-container{
        position: relative;
        width: 100vw;
        min-height: calc(100vh - 46px);
        display: grid;
        grid-template-columns: 100vw;
        background-color: #F3F8FA;
    }
}

@media print{
    .print-template{
        position: relative;
        width: 100vw;
        min-height: calc(100vh - 46px);
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        background-color: #F3F8FA;
        overflow: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/templates/Dashboard/template.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb;;;kBAGc;IACd,6CAA6C;IAC7C,gDAAgD;IAChD,yBAAyB;AAC7B;;AAEA;IACI;QACI,kBAAkB;QAClB,YAAY;QACZ,8BAA8B;QAC9B,aAAa;QACb,4BAA4B;QAC5B,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,kBAAkB;QAClB,YAAY;QACZ,8BAA8B;QAC9B,aAAa;QACb,0BAA0B;QAC1B,6BAA6B;QAC7B,yBAAyB;QACzB,cAAc;IAClB;AACJ","sourcesContent":[".template-container{\n    position: relative;\n    width: 100vw;\n    min-height: 100vh;\n    display: grid;\n    grid-template-areas:\n      \"nav header\"\n      \"nav main \"\n      \"nav footer\";\n    grid-template-columns: 94px calc(100% - 94px);\n    grid-template-rows: 62px minmax(auto, 100%) 83px;\n    background-color: #F3F8FA;\n}\n\n@media screen and (max-width: 500px){\n    .template-container{\n        position: relative;\n        width: 100vw;\n        min-height: calc(100vh - 46px);\n        display: grid;\n        grid-template-columns: 100vw;\n        background-color: #F3F8FA;\n    }\n}\n\n@media print{\n    .print-template{\n        position: relative;\n        width: 100vw;\n        min-height: calc(100vh - 46px);\n        display: grid;\n        grid-template-columns: 1fr;\n        grid-template-rows: auto auto;\n        background-color: #F3F8FA;\n        overflow: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
