import { Box } from "@mui/material";
import { Paper } from "@components";
import { Title, Cost, Value } from "./styles";

import { pontualConsult } from "@utils";
import { Billing } from "@services";
import { useFetch } from "@hooks";

interface ComponentProps {
  viewName: string,
  api: string,
  datasets: Array<string>
}

const Component: React.FC<ComponentProps> = ({ viewName, api, datasets }) => {

  const datasetsToSend = datasets.map((dataset: string) => (
    {
      Entity: api,
      Dataset: dataset,
      Requests: 1
    }
  ));

  const { data, isLoading } = useFetch(
    Billing.calculateConsultPrice,
    {
      "Products": {
        "BigBoost": datasetsToSend
      }
    }
  );

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={6} id="price">
        <Title> {viewName} </Title>
        <Cost>
          Custo máximo estimado:
          <Value>{pontualConsult.formatMoneyBRL(data?.Price || 0)}</Value>
        </Cost>
      </Box>
    </Paper>
  )
}

export default Component;