import { styled } from "@mui/material/styles"
import {
  ButtonGroup as ButtonGroupMaterial,
  Popper as PopperMaterial,
  MenuList as MenuListMaterial,
  Paper as PaperMaterial
} from "@mui/material"

const ButtonGroup = styled(ButtonGroupMaterial)(({ theme }) => ({
  boxShadow: "none",
  "& button": {
    textTransform: "capitalize",
    padding: theme.spacing(2),
    fontWeight: 700,
    borderRadius: 8
  },
  "& .MuiButtonGroup-grouped:not(:last-of-type)": {
    borderColor: "transparent"
  },
  "& .MuiButtonGroup-grouped:not(:last-of-type):after":{
    content: '""',
    height: "50%",
    width: "1px",
    position: "absolute",
    right: "-1px",
    top: "25%",
    backgroundColor: theme.palette.neutral?.white
  },
}));

const Popper = styled(PopperMaterial)(({ theme }) => ({
  marginTop: `${theme.spacing(2)} !important`
}))

const MenuList = styled(MenuListMaterial)(({ theme }) => ({
  padding: theme.spacing(2),
  "& li": {
    borderRadius: 8,
    padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    color: theme.palette.lighterBigBlue?.medium
  },
  "& li:hover": {
    backgroundColor: theme.palette.neutral?.[500]
  }
}))

const Paper = styled(PaperMaterial)(({ theme }) => ({
  borderRadius: 8
}))

export {
  ButtonGroup,
  Popper,
  MenuList,
  Paper
};