import { BsArrowRightShort } from 'react-icons/bs';
import CustomDefaultTextButton from '../../GeneralComponents/CustomDefaultTextButton';

const BankSlipDownloadCell = ({ params }: any) => {

    const getBankSlip = () => {
            const url = params.row.url;
            window.open(url, '_blank');
    };

    return (
        <CustomDefaultTextButton variant='text' endIcon={<BsArrowRightShort />} onClick={getBankSlip}>Ver boleto bancário</CustomDefaultTextButton>
    );
}

export default BankSlipDownloadCell;