import { IconButton, Theme } from "@mui/material"
import { styled } from "@mui/styles"

const Container = styled(IconButton)(({ theme, active }: { theme: Theme, active: boolean }) => {

    const activeStyle = !!active ? {
        backgroundColor: '#D3D6F2'
    }
    : 
    ""

    return ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: theme.spacing(3),
        gap: theme.spacing(1),
        height: 'auto',
        borderRadius: '8px',
        transition: 'all .5s ease',
        ...activeStyle,
        '&:hover': {
            color: `theme.palette.primary.main !important`,
            backgroundColor: '#D3D6F2',
            transition: 'all .5s ease',
        },
    })
})

export {
    Container
}