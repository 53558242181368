import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#step-documents',
    title: 'Documentos da consulta',
    content: 'Complete os campos para realizar a consulta.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: false
},
{
    target: '#price',
    title: 'Custo estimado',
    content: 'Revise preço estimado considerando API e datasets.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
},
{
    target: '#mandatory-fields',
    title: 'Campos obrigatórios',
    content: 'Preencha os campo obrigatórios conforme requisitos da API e datasets.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
},
{
    target: '#optional-fields',
    title: 'Campos opcionais',
    content: 'Para uma busca mais precisa, preencha campos não obrigatórios.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
}
];

export default steps;