import { styled } from "@mui/material/styles";

import { Dialog as DialogMaterial, DialogTitle, DialogContent } from "@mui/material";

const Dialog = styled(DialogMaterial)(({ theme }) => ({
  "& .MuiPaper-root": {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(8),
    borderRadius: 8,
    padding: theme.spacing(8),
    fontFamily: "Be Vietnam",
    minWidth: 400
  }
}));

const Title = styled(DialogTitle)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  color: theme.palette.secondary.blue,
  padding: 0
}));

const Content = styled(DialogContent)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.neutral?.[100],
  padding: 0
}));

export {
  Dialog,
  Title,
  Content
}