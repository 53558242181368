import { styled } from '@mui/material/styles';
import { Box } from "@mui/material";

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: 8,
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 14,
  color: theme.palette.secondary.blue
}))

export {
  Container
}