import { Step } from "react-joyride";

const docUpdate: Step[] = [
    {
        target: '.doc-update-container',
        title: 'Atualize o status de documentos',
        content: 'Atualize o status de documentos CPF e CNPJ de forma prática com base no dataset da Receita Federal.',
        disableBeacon: true,
        floaterProps: { placement: 'center' },
    },
    {
        target: '.toggle-button-group',
        title: 'Selecione a categoria desejada',
        content: 'Utilize as abas para trocar a categoria de interesse.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
    {   
        target: '.doc-update-content',
        title: 'Preencha os campos necessários',  
        content: 'Informe o documento desejado e outras informações da sua solicitação.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
   
    {
        target: '.doc-update-info',
        title: 'Leia com atenção a nota informativa',
        content: 'Entenda como funciona o processo de atualização e como é calculada a previsão de conclusão.',
        disableBeacon: true,
        floaterProps: { placement: 'auto' }
    },
    {
        target: '.doc-update-container',
        title: 'Visualize o resultado da solicitação',
        content: 'Sua solicitação irá aparecer logo abaixo e pode levar até alguns minutos para ser concluída.',
        disableBeacon: true,
        showProgress: false,
        floaterProps: { placement: 'bottom' }
    },
    {
        target: '.doc-update-container',
        title: 'Tour finalizado',
        content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
        disableBeacon: true,
        showProgress: false,
        floaterProps: { placement: 'center' }
    },
];

export default docUpdate;