import { styled } from "@mui/material/styles"

const Title = styled("h1")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.neutral?.[200]
}));

export {
  Title
}