import { styled } from "@mui/material/styles"

type ProgressType = {
  percentage: number,
  thickness: number,
  size: number,
  color: string
}

const Progress = styled("div")<ProgressType>(({ size, thickness, percentage, color }) => ({
  position: "relative",
  width: size,
  height: size,
  borderRadius: "50%",
  background: `conic-gradient(${color} ${percentage * 360}deg, #ededed 0deg)`,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",

  "&:after": {
    position: "absolute",
    content: "''",
    width: size - thickness,
    height: size - thickness,
    borderRadius: "50%",
    background: "#fff"
  }
}));

const Percentage = styled("div")(({ color }: { color: string }) => ({
  zIndex: 9,
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: color
}));

export {
  Progress,
  Percentage
}