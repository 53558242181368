import DocUpdate from "./DocUpdate";
import * as PontualConsult from "./PontualConsult";
import UserManagement from "./UserManagement";
import TokenManagement from "./TokenManagement";
import * as Financial from "./Financial";
import Dashboard from "./Dashboard";

export {
    DocUpdate,
    PontualConsult,
    UserManagement,
    TokenManagement,
    Financial,
    Dashboard
}