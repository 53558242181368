import React from "react";

import { Box } from "@mui/material"
import { Button } from "@components";
import { Subtitle, Title } from "./styles"
import ExportButton from "./ExportButton";

import { Outlet, useLocation, useNavigate } from "react-router-dom"

import { useSelector } from "react-redux";
import { reducers } from "@store";

const Template = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isResult, setIsResult] = React.useState(false);

  const printRef = React.useRef(null);

  const { consult, qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfDocumentoscopia);

  React.useEffect(() => {

    if (location.pathname === "/dashboard/apps/bigid/documentoscopia/result") {
      setIsResult(true);
    } else {
      setIsResult(false);
    }
  }, [location.pathname])

  const handleNewConsult = () => {
    navigate("./");
  }

  const isAvailablePrint = consult.every((item: any) => !!item.isAvailable) && qualityImage.every((item: any) => !!item.isAvailable);

  return (
    <Box display="flex" flexDirection="column" px={6} py={8} gap={8} className="print" ref={printRef} width={{ xs: "100vw", md: "auto" }}>
      <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" gap={{ xs: 4, md: 0 }} displayPrint="none">
        <Box display="flex" flexDirection="column" gap={4} width="100%">
          <Title>Documentoscopia</Title>
          <Subtitle>
            Obtenha verificações precisas ao consultar dados de uma ampla variedade de documentos.
          </Subtitle>
        </Box>
        {
          isResult && isAvailablePrint && (
            <Box display="flex" justifyContent="flex-end" gap={4} width="100%">
              <Button
                color="secondary"
                size="small"
                onClick={handleNewConsult}
              >
                Nova consulta
              </Button>
              <ExportButton printRef={printRef} />
            </Box>
          )
        }
      </Box>
      <Outlet />
    </Box >
  )
}

export default Template;