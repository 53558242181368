import React from "react";

import { Box } from "@mui/material";
import { Chip, TicketContent, TicketTitle, TicketValue } from "./styles";
import { DataComparison, DataExtraction, DocumentVerification } from "..";

import ImageQuality from "../ImageQuality/components/View";

import { useSelector } from "react-redux";
import { reducers } from "@store";

interface ComponentProps {
  type: "link" | "file",
  documents: Array<any>
}

const Component: React.FC<ComponentProps> = ({ documents, type }) => {

  const { consult, qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfDocumentoscopia);

  return (
    <Box display="none" displayPrint="block">
      <Box display="flex" flexDirection="column" gap={10}>
        {
          documents.map((document, index) => {
            const consultData = consult.find((item: any) => item.index === index);

            return (
              <Box display="flex" flexDirection="column" gap={2} key={index}>
                <Chip>{`Documento ${index + 1}`}</Chip>
                <TicketContent p={4}>
                  <Box display="flex" gap={2}>
                    <TicketTitle>Ticket ID:</TicketTitle>
                    <TicketValue>{consultData?.data?.TicketId}</TicketValue>
                  </Box>
                </TicketContent>
                <Box display="flex" flexDirection="column" justifyContent="center" gap={6}>
                  {
                    document.files.map((file: any, index: number) => {
                      const qualityImageData = qualityImage.find((item: any) => (
                        item?.documentIndex === consultData?.index &&
                        item?.index === index
                      ));

                      return (
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap={3}>
                          <Box
                            component="img"
                            width="40%"
                            height="fit-content"
                            src={file[type === "link" ? "link" : "base64"]}
                          />
                          <ImageQuality data={qualityImageData?.data} />
                        </Box>
                      )
                    })
                  }
                </Box>
                <DataExtraction docData={consultData?.data?.DocInfo} />
                <DataComparison officialData={consultData?.data?.OfficialInfo} docData={consultData?.data?.DocInfo} />
                <DocumentVerification forensicValidations={consultData?.data?.ForensicValidations} />
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default Component