import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import {  GeneralComponents } from '@components';

const SeeDetailsSummarySection: React.FC = () => {
    
    const { open, params } = useSelector((state: reducers.RootState) => state.currentUsageSummaryDetailsReducer)

    const dispatch = useDispatch()
    const sideSectionRef = useRef(null);

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.currentUsageSummaryDetails.setShowCurrentUsageSummaryDetailsSection(false))
    }

    return <GeneralComponents.MobileSideSection
        sideSectionTitle='Ver detalhes'
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
         <div className='invoice-details-content'>
            <div className='invoice-details-data'>
                <div className='mobile-side-section-group'>
                    <label>Escopo: </label>
                    <span>{params?.Scope}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Dataset: </label>
                    <span>{params?.Dataset}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições gratuitas: </label>
                    <span>{params?.FreeRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições pagas: </label>
                    <span>{params?.PaidRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Total de requisições: </label>
                    <span>{params?.TotalRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço médio: </label>
                    <span>{parseFloat(params?.AveragePrice).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Descontos: </label>
                    <span>{parseFloat(params?.VolumeDiscount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço: </label>
                    <span>{parseFloat(params?.Price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </div>
        </div>
    </GeneralComponents.MobileSideSection>

}

export default SeeDetailsSummarySection;