import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup } from '@mui/material';

import { reducers, slices } from "@store";

import { showToastCustomEditUserSucessMessage, showToastCustomErrorMessage, handleAxiosError } from '@utils';

import { BigBrother, FileTransferCenter } from '@services';

import './editUser.css'

import { GeneralComponents } from "@components";

import selectedButtonStyle from './sxStyles';
import { useUnsavedChanges } from '@hooks';

interface EditUserSectionProps {
  className: string,
  closeEditUserSection: () => void

}

const EditUser: React.FC<EditUserSectionProps> = ({ className }: EditUserSectionProps) => {

  const outsideClickOcurred = useSelector((state: reducers.RootState) => state.editUserReducer.outsideClickOcurred)
  const editUserInfo = useSelector((state: reducers.RootState) => state.editUserReducer)
  const dispatch = useDispatch()

  const [localUserInfo, setLocalUserInfo] = useState(editUserInfo)
  const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false)
  const [unsavedDialogConfirmHandlers, setUnsavedDialogConfirmHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });
  const { hasUnsavedChanges, enableUnsavedChanges, disableUnsavedChanges } = useUnsavedChanges()

  const updateUser = async () => {
    const { login, email, name, isEnabled, isSystemAdmin, group, fileTransfer } = localUserInfo
    const twoFactorAuth = localUserInfo['2fa']
    return await BigBrother.updateUser(
      {
        login,
        email,
        name,
        isEnabled,
        isSystemAdmin,
        group,
        '2fa': twoFactorAuth,
        // fileTransfer
      })
  }

  const tryToUpdateUser = async () => {
    try {
      const { data: apiBigBrotherResponseData } = await updateUser()
      handleBigBrotherResponse(apiBigBrotherResponseData)
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const createFileTransferTemplate = async (userLogin: string) => await FileTransferCenter.generateFolderTemplate({
    login: userLogin
  })

  const tryCreateFileTransferTemplate = async (userLogin: string) => {
    try {
      await createFileTransferTemplate(userLogin)
    } catch (err: any) {
      handleAxiosError(err)
    }
  }

  const handleBigBrotherResponse = (apiBigBrotherResponseData: any) => {
    if (apiBigBrotherResponseData.message === 'Fail' || apiBigBrotherResponseData.result === false) {
      showToastCustomErrorMessage('Erro ao atualizar o usuário', 'Não foi possível atualizar o usuário.')
    } else {
      showToastCustomEditUserSucessMessage(localUserInfo.name)
      if (!!localUserInfo.fileTransfer) {
        tryCreateFileTransferTemplate(localUserInfo.login)
      }
      dispatch(slices.editUser.setShowEditUserSection(false))
      dispatch(slices.editUser.setWasUserEditionConfirmed(true))
    }
  }

  const handleLocalUserInfoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    enableUnsavedChanges();
    setLocalUserInfo({ ...localUserInfo, [event.target.name]: event.target.value })
  }

  const handleTwoFactorChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    enableUnsavedChanges();
    if (localUserInfo.isSystemAdmin) {
      setLocalUserInfo({ ...localUserInfo, "2fa": true })
    } else {
      event.currentTarget.name === 'enabled' ? setLocalUserInfo({ ...localUserInfo, "2fa": true }) : setLocalUserInfo({ ...localUserInfo, "2fa": false })
    }

  };

  const handleEnabledStatusChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    enableUnsavedChanges();
    event.currentTarget.name === 'enabled' ? setLocalUserInfo({ ...localUserInfo, isEnabled: true }) : setLocalUserInfo({ ...localUserInfo, isEnabled: false })
  }

  const handleAdminChange = (event: React.MouseEvent<HTMLButtonElement>) => {
    enableUnsavedChanges();
    if (event.currentTarget.name === 'admin') {
      setLocalUserInfo({ ...localUserInfo, isSystemAdmin: true, "2fa": true })
    } else {
      setLocalUserInfo({ ...localUserInfo, isSystemAdmin: false })
    }
  }

  // const handleFileTransferChange = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   enableUnsavedChanges()
  //   if (event.currentTarget.name === 'enabled') {
  //     setLocalUserInfo({ ...localUserInfo, fileTransfer: true })
  //   } else {
  //     setLocalUserInfo({ ...localUserInfo, fileTransfer: false })
  //   }
  // }

  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    tryToUpdateUser()
  }

  const unsavedDialogResponse = async () => {
    return new Promise((resolve) => {
      setShowUnsavedChangesDialog(true);

      const handleConfirm = () => {
        resolve(true);
        tryToUpdateUser()
        dispatch(slices.editUser.setOutsideClickOcurred(false))
        setShowUnsavedChangesDialog(false);
      };

      const handleCancel = () => {
        resolve(false);
        setShowUnsavedChangesDialog(false);
        dispatch(slices.editUser.setShowEditUserSection(false))
        dispatch(slices.editUser.setOutsideClickOcurred(false))
        disableUnsavedChanges();
      };

      setUnsavedDialogConfirmHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });
    });
  };

  const { confirmHandler, cancelHandler } = unsavedDialogConfirmHandlers;

  const handleCancelButtonClick = async () => {
    if (hasUnsavedChanges) {
      const wasUnsavedChangesConfirmed = await unsavedDialogResponse()
      if (wasUnsavedChangesConfirmed) {
        dispatch(slices.editUser.setShowEditUserSection(false))
        disableUnsavedChanges();
      }
    } else {
      dispatch(slices.editUser.setShowEditUserSection(false))
      dispatch(slices.editUser.setOutsideClickOcurred(false))
    }
  }

  useEffect(() => {
    if (outsideClickOcurred) {
      handleCancelButtonClick()
    } else {
      setLocalUserInfo(editUserInfo)
    }
  }, [editUserInfo, outsideClickOcurred])

  const handleEditUserContainerClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }


  return <div className={className} onMouseDown={handleEditUserContainerClick}>
    <main className='edit-user-main-container'>
      <div className='edit-user-main-header-container'>
        <h3 className='edit-user-title'>Editar usuário</h3>
      </div>
      <hr className='edit-user-divider'></hr>
      <form className='edit-user-form-container' onSubmit={handleFormSubmit}>
        <div className='edit-user-fields-container'>
          <h4 className='edit-user-form-title'>Dados</h4>
          <div className='edit-user-login-fields'>
            <span className='login-span'>Login: <b>{localUserInfo.login}</b></span>
          </div>
          <div className='edit-user-email-fields'>
            <label>E-mail</label>
            <GeneralComponents.CustomTextField id="filled-basic" variant="outlined" value={localUserInfo.email} onChange={handleLocalUserInfoChange} name='email' />
          </div>
          <div className='edit-user-name-fields'>
            <label>Nome</label>
            <GeneralComponents.CustomTextField id="filled-basic" variant="outlined" value={localUserInfo.name} onChange={handleLocalUserInfoChange} name='name' />
          </div>
        </div>
        <FormGroup className='edit-user-switch-container'>
          <h4 className='edit-user-switch-container-title'>Condições</h4>
          <div className='switch-container-row'>
            <div className='switch-container-status'>
              <label>Status</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.isEnabled && selectedButtonStyle }} onClick={handleEnabledStatusChange} name='enabled'>Ativo</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.isEnabled && selectedButtonStyle }} onClick={handleEnabledStatusChange} name='disabled'>Inativo</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div>
            <div className='switch-container-admin'>
              <label>Perfil de acesso</label>
              <GeneralComponents.CustomButtonGroup>
                <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.isSystemAdmin && selectedButtonStyle }} name='admin' onClick={handleAdminChange}>Administrador</GeneralComponents.CustomGroupedButton>
                <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.isSystemAdmin && selectedButtonStyle }} name='non-admin' onClick={handleAdminChange}>Colaborador</GeneralComponents.CustomGroupedButton>
              </GeneralComponents.CustomButtonGroup>
            </div>
          </div>

          <div className='switch-container-2fa'>
            <label>Autenticação em dois fatores</label>
            <GeneralComponents.CustomButtonGroup>
              <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo['2fa'] && selectedButtonStyle }} name='enabled' onClick={handleTwoFactorChange}>Ativada</GeneralComponents.CustomGroupedButton>
              <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo['2fa'] && selectedButtonStyle }} name='disabled' onClick={handleTwoFactorChange}>Desativada</GeneralComponents.CustomGroupedButton>
            </GeneralComponents.CustomButtonGroup>
          </div>
          {/* <div className='switch-container-file-transfer'>
            <label>Transferência de arquivos</label>
            <GeneralComponents.CustomButtonGroup>
              <GeneralComponents.CustomGroupedButton sx={{ ...localUserInfo.fileTransfer && selectedButtonStyle }} onClick={handleFileTransferChange} name='enabled'>Ativa</GeneralComponents.CustomGroupedButton>
              <GeneralComponents.CustomGroupedButton sx={{ ...!localUserInfo.fileTransfer && selectedButtonStyle }} onClick={handleFileTransferChange} name='disabled'>Inativa</GeneralComponents.CustomGroupedButton>
            </GeneralComponents.CustomButtonGroup>
          </div> */}
        </FormGroup>
        <div className='edit-user-button-container'>
          <GeneralComponents.CancelButton type='button' className='edit-user-cancel-button' size='small' variant='text' onClick={handleCancelButtonClick}>Cancelar</GeneralComponents.CancelButton>
          <GeneralComponents.CustomDefaultButton type='submit' variant='contained' onClick={tryToUpdateUser}>Salvar</GeneralComponents.CustomDefaultButton>
        </div>
      </form>
      <GeneralComponents.ConfirmationDialog open={showUnsavedChangesDialog} onConfirm={confirmHandler} onLeave={cancelHandler} />
    </main >
  </div >
}

export default EditUser;