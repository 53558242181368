import { Paper } from "@components"
import { Box, Grid } from "@mui/material"
import { Header } from "../../../../components"
import Loading from "../Loading";
import Field from "../Field";
import { masks } from "@utils";

interface Field {
  Description: string,
  Field: string,
  Values: Array<string>
}

interface ComponentProps {
  fields?: Array<Field>,
  availableStates?: Array<any>,
  isLoading?: boolean,
  isAlternativeKeys?: boolean
}

const Component: React.FC<ComponentProps> = ({ fields = [], isLoading = false, availableStates = [], isAlternativeKeys = false }) => {

  if ((!!!fields || !!!fields?.length) && !!!isLoading) return (<></>);

  return (
    <Paper>
      <Box p={6} id="mandatory-fields">
        <Box display="flex" flexDirection="column" gap={8}>
          <Header
            title="Documentos da consulta"
            subtitle={
              isAlternativeKeys ? (
                <>Preencha <b>no mínimo um campo</b> para continuar a consulta</>
              ) : (
                <>Preencha <b>todos os campos</b> para continuar a consulta</>
              )
            }
          />
          <Box display="flex" flexDirection="column" gap={4}>
            <Grid container spacing={4}>
              {
                !!isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {
                      fields.map((field) => {
                        const mask: any = Object.entries(masks || {}).find(([key, value]) => {
                          const maskUpperCase = key.toUpperCase();
                          const fieldNameUpperCase = field?.Field?.toUpperCase();

                          const maskFound = (
                            field.Values.find((fieldValue) => fieldValue.toUpperCase() === maskUpperCase)
                          ) || fieldNameUpperCase === maskUpperCase

                          return maskFound && value;
                        })?.[1];

                        if (field.Field === "dateformat") return (<></>)

                        return (
                          <Grid item xs={4}>
                            <Field
                              key={field.Field}
                              label={field.Field}
                              name={`documents.${field.Field}`}
                              description={field.Description}
                              mask={mask}
                              isSelect={field.Field === "uf"}
                              options={availableStates}
                              fieldValues={field.Values}
                              required
                            />
                          </Grid>
                        )
                      })
                    }
                  </>
                )
              }
            </Grid>
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}

export default Component;