import * as yup from "yup";

const validationSchema = (fields: any, oneOf: boolean = false) => {

  if (oneOf) {
    const requiredFields = fields.reduce((obj: any, item: any) => {
      return obj[item] = yup.string().test(
        "oneOfRequired",
        "One of fields is required",
        function () {
          const arrayValues = Object.values(this.parent);
          return arrayValues.some((value) => !!value)
        }
      ), obj
    }, {});

    return yup.object({
      documents: yup.object().shape({
        ...requiredFields
      }, [fields])
    });
  }

  const requiredFields = fields.reduce((obj: any, item: any) => (obj[item] = yup.string().required(), obj), {});

  return yup.object({
    documents: yup.object({
      ...requiredFields
    })
  });
}

export default validationSchema;