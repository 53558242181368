import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slices/user'
import lastUsedReducer from '../slices/lastUsed';
import quickConsultDialogReducer from "../slices/quickConsultDialog";
import qualityImageDialogReducer from "../slices/qualityImageDialog";

const persistConfig = {
    key: 'userInfo',
    storage,
};

const persistedReducer = combineReducers({
    user: userReducer,
    lastUsed: lastUsedReducer,
    quickConsultDialog: quickConsultDialogReducer,
    qualityImageDialog: qualityImageDialogReducer
});


const persistedUserInfoReducer = persistReducer(persistConfig, persistedReducer);

export default persistedUserInfoReducer;