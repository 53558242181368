import React from "react";

import { useTheme } from "@mui/material";

import { Progress, Percentage } from "./styles";

type ComponentType = {
  value?: number,
  thickness?: number,
  size?: number
}

const Component: React.FC<ComponentType> = ({
  value = 0,
  thickness = 5,
  size = 500
}) => {
  const theme = useTheme();

  const percentage = Number((value * 100).toFixed(2));

  const redHex = theme.palette.negative?.pure || "#000";
  const yellowHex = theme.palette.warning.pure;
  const greenHex = theme.palette.success.pure;

  const color = percentage <= 33 ? redHex : percentage <= 66 ? yellowHex : greenHex;

  return (
    <Progress
      percentage={value}
      thickness={thickness}
      size={size}
      color={color || "#000"}
    >
      <Percentage color={color || "#000"}>
        {percentage >= 0 ? `${percentage}%` : 'N/A'}
      </Percentage>
    </Progress>
  )
}

export default Component;