import React from "react";

import { Box } from "@mui/material";
import { Chart } from "../index";
import { Title, Chip } from "./styles"
import { Table, TextField, Notification } from "@components";

import { FormProvider, useForm, useWatch } from "react-hook-form";
import { handleString } from "@utils";

type ComponentProps = {
  officialData?: any,
  docData?: any
  hasError?: boolean
}

const Component: React.FC<ComponentProps> = ({ officialData, docData, hasError }) => {
  const formFilter = useForm();

  const rows = [
    {
      data: "Nome",
      docInformation: "NAME",
      officialInformation: "OFFICIALNAME",
      match: "NAMEMATCHPERCENTAGE"
    },
    {
      data: "Nome da mãe",
      docInformation: "MOTHERNAME",
      officialInformation: "OFFICIALMOTHERNAME",
      match: "MOTHERNAMEMATCHPERCENTAGE"
    },
    {
      data: "Nome do pai",
      docInformation: "FATHERNAME",
      officialInformation: "OFFICIALFATHERNAME",
      match: "FATHERNAMEMATCHPERCENTAGE"
    },
    {
      data: "Data de nascimento",
      docInformation: "BIRTHDATE",
      officialInformation: "OFFICIALBIRTHDATE",
      match: "BIRTHDATEMATCH",
      handleOfficialData: handleString.toBrazilianDate
    },
  ];

  const search: any = useWatch({
    control: formFilter.control,
    name: "search"
  });

  const [rowsFiltred, setRowsFiltred] = React.useState<Array<any>>(rows);

  React.useEffect(() => {

    handleSearch(search);
  }, [search])

  const handleSearch = (value: string) => {

    if (!!value?.length) {
      const searchSplitted = search.split(" ");

      setRowsFiltred(rows.filter((row: any) => {
        const dataNormalized = row.data.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        const hasKeyWord = !!searchSplitted.find((keyWord: string) => {
          const keyWordNormalized = keyWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

          return dataNormalized.includes(keyWordNormalized);
        });

        return hasKeyWord;
      }))

      return;
    }

    setRowsFiltred(rows)
  };

  const dataFound = rows.filter((row) => !!officialData?.[row.match]);

  const totalMatch = rows.reduce(
    (accumulator, row) => {
      const currentValue: number = parseInt(officialData?.[row.match]) || 0

      return accumulator + currentValue
    },
    0,
  ) / dataFound.length;

  if (hasError) {

    return (
      <>
        <Notification
          variant="danger"
          title="Ocorreu um problema com a extração de dados do(s) documento(s)."
          description=" Por favor, contate nossa equipe de suporte."
        />
      </>
    );
  }

  return (
    <>
      <FormProvider {...formFilter}>
        <form>
          <Box display="flex" flexDirection="column" gap={4} className="no-break">
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              alignItems={{ xs: "flex-start", md: "center" }}
              justifyContent="space-between"
              py={2}
              gap={{ xs: 3, md: 0 }}
              className="no-break"
            >
              <Box display="flex" flexDirection="column" gap={4} width={{ xs: "100%", md: "50%" }}>
                <Title>Comparativo de dados</Title>
                <Box displayPrint="none">
                  <TextField name="search" placeholder="Busque por tipo de dado (RG, nome, etc)" fullWidth />
                </Box>
              </Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end" width={{ xs: "100%", md: "50%" }}>
                <Chart
                  percentage={totalMatch || 0}
                  title="Afinidade total das informações"
                  description="Valor médio de afinidade entre as informações comparadas"
                />
              </Box>
            </Box>
            <Box>
              <Table.Container>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Dado
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Informação do documento
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Dado para comparação
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Similaridade
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {
                    rowsFiltred.map((row) => {

                      const dataName = row.data;
                      const docInfo = docData?.[row.docInformation];
                      const officialInfo = !!row.handleOfficialData ?
                        row.handleOfficialData(officialData?.[row.officialInformation]) :
                        officialData?.[row.officialInformation];
                      const match = officialData?.[row.match] * 100;

                      if (!!!docInfo || !!!officialInfo || !!!match) return;

                      return (
                        <Table.Row>
                          <Table.Cell>
                            {dataName}
                          </Table.Cell>
                          <Table.Cell>
                            {docInfo}
                          </Table.Cell>
                          <Table.Cell>
                            {officialInfo}
                          </Table.Cell>
                          <Table.Cell>
                            <Box display="flex" justifyContent="center">
                              <Chip percentage={match}>{match}%</Chip>
                            </Box>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table.Container>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}

export default Component;