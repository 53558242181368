import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { IoMdNotificationsOutline } from 'react-icons/io';


import { Badge, Box, IconButton, Typography } from '@mui/material';
import UserProfileButton from './UserProfileButton';
import RedoOnboardingButton from './RedoOnboardingButton';

import { isDesktopWidth } from '@constants'


import './header-user-info.css'
import { reducers, slices } from "@store";
import LogoutButton from './LogoutButton/LogoutButton';
import { useLocation } from 'react-router-dom';
import { useNotificationCenterContext } from '../../../NotificationCenterProvider';
import { BiUserCircle } from 'react-icons/bi';
import { AiOutlineMenu } from 'react-icons/ai';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { GeneralComponents } from '@components';


export default function HeaderUserInfo() {
    const userName = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.name)
    const showUserProfileSection = useSelector((state: reducers.RootState) => state.sideSection.open)
    const showNotificationCenter = useSelector((state: reducers.RootState) => state.notificationCenterReducer.showNotificationCenter)
    const isMobileNavbarOpen = useSelector((state: reducers.RootState) => state.navbarReducer.isOpenInMobile)
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false)
    const [showLogoutDialog, setShowLogoutDialog] = useState<boolean>(false)
    const [logoutDialogHandlers, setLogoutDialogHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });
    const dispatch = useDispatch()
    const location = useLocation()
    const { unreadCount } = useNotificationCenterContext()


    const handleUserProfileButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (isDesktopWidth) {
            setIsDropdownOpen(!isDropdownOpen)
        }
        else {
            dispatch(slices.sideSection.setOpen(!showUserProfileSection))
        }
    }

    const handleMyProfileClick = (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation()
        dispatch(slices.sideSection.setOpen(!showUserProfileSection))
        setIsDropdownOpen(false)
    }

    const handleRedoOnboarding = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.onboardingTooltip.setOnboardingTooltipInfo({ showOnboardingTooltip: true, onboardingLocation: location.pathname }))
    }

    const handleToggleNotificationCenter = (event: React.MouseEvent<HTMLSpanElement>) => {
        event.stopPropagation()
        dispatch(slices.notificationCenter.setShowNotificationCenter(!showNotificationCenter))
    }

    const toggleOpenMobileNavbar = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.navbar.setIsMobileNavbarOpen(!isMobileNavbarOpen))
    }

    const handleLogoutClick = async (event: React.MouseEvent<HTMLLIElement>) => {
        event.stopPropagation()
        setIsDropdownOpen(false)
        setShowLogoutDialog(true)
        const logoutConfirmed = await getLogoutDialogResponse()
        if (logoutConfirmed) {
            dispatch(slices.user.setUserInitialState())
            return
        }
    }


    const getLogoutDialogResponse = async () => {
        return new Promise((resolve) => {

            const handleConfirm = () => {
                resolve(true);
                setShowLogoutDialog(false)

            };

            const handleCancel = () => {
                resolve(false);
                setShowLogoutDialog(false);
            };

            setLogoutDialogHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });
        });
    };

    const { confirmHandler, cancelHandler } = logoutDialogHandlers;


    return (
        isDesktopWidth ? <div className='logged-user-info'>
            <div className='header-user-actions'>
                <RedoOnboardingButton handleRedoOnboarding={handleRedoOnboarding} />
                <Badge
                    onClick={handleToggleNotificationCenter}
                    sx={{ cursor: 'pointer' }}
                    badgeContent={unreadCount}
                    color='primary'
                    className='notification-icon'
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}

                >
                    <IoMdNotificationsOutline color={'#006'} size={24} />
                </Badge>
                <div className='user-profile-dropdown-container'>
                    <UserProfileButton
                        startIcon={<img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/user.svg' />}
                        endIcon={!isDropdownOpen ? <MdArrowDropDown color="#006" size={24} /> : <MdArrowDropUp color="#006" size={24} />}
                        className='user-profile-button'
                        disableFocusRipple
                        disableRipple
                        disableElevation
                        onClick={handleUserProfileButtonClick}>
                        {userName}
                    </UserProfileButton>
                    <div className={isDropdownOpen ? 'user-profile-dropdown-opened' : 'user-profile-dropdown-closed'}>
                        <ul className='user-profile-dropdown-list'>
                            <li onClick={handleMyProfileClick}>Meu perfil</li>
                            <li onClick={handleLogoutClick}>Sair</li>
                        </ul>
                    </div>
                </div>

                <GeneralComponents.ConfirmationDialog
                    open={showLogoutDialog}
                    onConfirm={confirmHandler}
                    onLeave={cancelHandler}
                    title={'Você está prestes a sair do Center'}
                    content={'Tem certeza de que deseja deslogar?'}
                    confirmText={'Sim, sair'}
                    cancelText={'Cancelar'}
                />
            </div>

        </div > :
            <div className='logged-user-info'>
                <div className='header-user-actions'>
                    <Box display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        gap={'2px'}
                    >
                        <IconButton
                            sx={{ padding: 0 }}
                            onClick={toggleOpenMobileNavbar}
                            id='navbar-menu'>
                            <AiOutlineMenu color="#FFF" size={24} />
                        </IconButton>
                        <Typography
                            variant='caption'
                            margin={0}
                            padding={0}
                            color={'#fff'}
                            fontSize={10}
                        >Menu</Typography>
                    </Box>
                    <RedoOnboardingButton handleRedoOnboarding={handleRedoOnboarding} />
                    <Box display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        gap={'2px'}
                    >
                        <Badge
                            onClick={handleToggleNotificationCenter}
                            sx={{ cursor: 'pointer', padding: 0 }}
                            badgeContent={unreadCount}
                            color='info'
                            className='notification-icon'
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <IoMdNotificationsOutline color="white" size={24} />
                        </Badge>
                        <Typography
                            variant='caption'
                            margin={0}
                            padding={0}
                            color={'#fff'}
                            fontSize={10}
                        >Notificações</Typography>
                    </Box>
                    <Box display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='center'
                        gap={'2px'}
                    >
                        <IconButton
                            sx={{ padding: 0 }}
                            className='user-profile-button'
                            onMouseDown={handleUserProfileButtonClick}>
                            <BiUserCircle color='#FFF' size={24}></BiUserCircle>
                        </IconButton>
                        <Typography
                            variant='caption'
                            margin={0}
                            padding={0}
                            color={'#fff'}
                            fontSize={10}
                        >Perfil</Typography>
                    </Box>
                    <LogoutButton />
                </div>
            </div >
    )
}