import React from 'react';

import {
    ImageCheckWrapper,
    SimilarityContent,
    SimilarityInfo,
    CodeInfo
} from './styles';
import { Box, Typography } from '@mui/material';
import { Progress, Tooltip } from '@components';

interface ComponentProps {
    value: number,
    ResultCode: number,
    ResultMessage: string,
    error?: any
}

const Component: React.FC<ComponentProps> = ({
    ResultMessage,
    ResultCode,
    value,
    error
}) => {

    const messages: any = {
        [-823]: (
            <Typography variant='body2'>
                <Typography variant='body2' component={'span'} color={'warning.medium'} fontWeight={700}>Não há faces</Typography> nas imagens
            </Typography>
        ),
        [-816]: (
            <Typography variant='body2'>
                As imagens enviadas <Typography variant='body2' component={'span'} color={'negative.pure'} fontWeight={700}>são repetidas</Typography>
            </Typography>
        ),
        [-800]: (
            <Typography variant='body2'>
                As fotos dos rostos{' '}
                <Typography variant='body2' component={'span'} color={'negative.pure'} fontWeight={700}>não correspondem</Typography>
            </Typography>
        ),
        [80]: (
            <Typography variant='body2'>
                As fotos dos rostos{' '}
                <Typography variant='body2' component={'span'} color={'success.pure'} fontWeight={700}>correspondem</Typography>
            </Typography>
        )
    }

    return <ImageCheckWrapper>
        <Typography variant='h2'>Similaridade entre os rostos</Typography>
        <SimilarityContent>
            <Progress value={value ?? -1} size={80} thickness={10} />
            <SimilarityInfo>
                {
                    messages?.[ResultCode] ||
                    <Typography variant='body2' color={'negative.pure'}>
                        Erro na verificação de similaridade. Contate o suporte.
                    </Typography>
                }
                <CodeInfo>
                    <Typography variant='body1' color={'secondary.main'} fontWeight={700}>Código:</Typography>
                    <Typography variant='body1' color={'secondary.main'}>{ResultCode || error?.response?.data?.ResultCode ||error?.code}</Typography>
                    <Box
                        display={'flex'}
                        alignItems={'flex-start'}
                        alignSelf={'center'}
                    >
                        {
                            !!!messages?.[ResultCode] && <Tooltip description={'Em caso de erros inesperados, entre em contato com o suporte e informe o código recebido.'} placement='right' />
                        }

                    </Box>
                </CodeInfo>
                {!!!messages?.[ResultCode] && !!ResultMessage || error?.message && (
                    <CodeInfo>
                        <Typography variant='body1' color={'secondary.main'} fontWeight={700}>Mensagem do erro:</Typography>
                        <Typography variant='body1' color={'secondary.main'}>{ResultMessage || error?.message}</Typography>
                    </CodeInfo>
                )
                }
            </SimilarityInfo>
        </SimilarityContent>
    </ImageCheckWrapper>
}

export default Component;