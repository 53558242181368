import React from "react";

import { Box, Typography } from "@mui/material";
import { Button, Paper, Table } from "@components";

import { handleString, pontualConsult } from "@utils";

import { BiCopy } from "react-icons/bi";
import { useSelector } from "react-redux";
import { reducers } from "@store";

type RowProps = {
  label: string,
  value: string | number | object
}

const Row: React.FC<RowProps> = ({ label, value }) => {

  const { isPrinting } = useSelector((state: reducers.RootState) => state.exportPdf)

  const handleCopyData = (data: any) => {
    navigator.clipboard.writeText(data)
  }

  if (Array.isArray(value)) {
    const content = (
      <Table.Row sx={{ borderTop: "none" }}>
        <Table.Cell colSpan={3}>
          <Table.Container elevation>
            <Table.Head>
              <Table.Row>
                <Table.Cell>
                  <Typography color="secondary" variant="body2" fontWeight={700}>
                    Atributo
                  </Typography>
                </Table.Cell>
                <Table.Cell colSpan={2}>
                  <Typography color="secondary" variant="body2" fontWeight={700}>
                    Resultado
                  </Typography>
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {
                value.map((item, index) => {
                  if (Array.isArray(item)) {
                    return (
                      <Table.RowCollapse
                        expanded={isPrinting}
                        content={
                          <Table.Row sx={{ borderTop: "none" }}>
                            <Table.Cell colSpan={3}>
                              <Table.Container elevation>
                                <Table.Head>
                                  <Table.Row>
                                    <Table.Cell>
                                      <Typography color="secondary" variant="body2" fontWeight={700}>
                                        Atributo
                                      </Typography>
                                    </Table.Cell>
                                    <Table.Cell colSpan={2}>
                                      <Typography color="secondary" variant="body2" fontWeight={700}>
                                        Resultado
                                      </Typography>
                                    </Table.Cell>
                                  </Table.Row>
                                </Table.Head>
                                <Table.Body>
                                  {
                                    item.map(i => (
                                      <Row key={index} label={i?.key} value={i?.value} />
                                    ))
                                  }
                                </Table.Body>
                              </Table.Container>
                            </Table.Cell>
                          </Table.Row>
                        }>
                        <Table.Cell colSpan={2}>
                          {`Atributo ${index + 1}`}
                        </Table.Cell>
                      </Table.RowCollapse>
                    )
                  };

                  return (
                    <Row label={item?.key} value={item?.value} />
                  )
                })
              }
            </Table.Body>
          </Table.Container>
        </Table.Cell>
      </Table.Row>
    )

    return (
      <>
        <Table.RowCollapse
          expanded={isPrinting}
          content={content}>
          <Table.Cell colSpan={2}>
            <Box display="flex" justifyContent="flex-start">
              {handleString.toHumanize(label)}
            </Box>
          </Table.Cell>
        </Table.RowCollapse>
      </>
    )
  }

  return (
    <Table.Row>
      <Table.Cell>
        <Box display="flex" justifyContent="flex-start">
          {handleString.toHumanize(label)}
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" justifyContent="flex-start">
          {pontualConsult.transformAttributeValue(value)}
        </Box>
      </Table.Cell>
      <Table.Cell>
        <Box display="flex" minWidth={'72px'} justifyContent="flex-end" >
          <Button
            variant="action"
            StartIcon={<BiCopy size={20} />}
            onClick={() => handleCopyData(value)}
            className="no-print"
            fullWidth
          >
            Copiar
          </Button>
        </Box>
      </Table.Cell>
    </Table.Row>
  )
}

export default Row;