import { BiInfoCircle } from 'react-icons/bi';
import './noDataNote.css';

interface InfoNoteProps {
    noteTitle: string;
    noteDescription: string;
    children?: React.ReactNode
}

const InfoNote: React.FC<InfoNoteProps> = ({noteTitle, noteDescription, children}) => {
    return <div className='note-container'>
        <div className='note-content'>
            <BiInfoCircle color='#0048B2' size={20} />
            <div className='note-texts'>
                <span>{noteTitle}</span>
                <p>{noteDescription}</p>
                {children}
            </div>
        </div>
    </div>;
}

export default InfoNote;