import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
    open: boolean
}

const initialState: initialStateProps = {
    open: false
}

const state = createSlice({
    name: 'sideSection',
    initialState,
    reducers: {
        setOpen: (state, action: PayloadAction<boolean>) => {
           state.open = action.payload
        }
    }
});

export const { setOpen } = state.actions;

export default state.reducer;