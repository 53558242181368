import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface CurrentUsageSummaryDetailsInfo {
    open: boolean;
    params: any;
}


const initialState: CurrentUsageSummaryDetailsInfo = {
   open: false,
   params: undefined
}

const CurrentUsageSummaryDetailsSlice = createSlice({
  name: 'currentUsageSummary',
  initialState: initialState,
  reducers: {
    setShowCurrentUsageSummaryDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowCurrentUsageSummaryDetailsSection, setParams } = CurrentUsageSummaryDetailsSlice.actions;

export default CurrentUsageSummaryDetailsSlice.reducer;