import { Paper } from "./styles";
import { PaperProps } from "@mui/material";

interface CustomPaperProps extends Omit<PaperProps, 'round'> {
  round?: string | number,
  clickable?: boolean
}

const Component = ({
  children,
  elevation = 0,
  round = 12,
  clickable = false,
  variant,
  ...props
} : CustomPaperProps) => {
  return (
    <Paper
      elevation={elevation}
      variant={variant}
      round={round}
      clickable={clickable}
      {...props}
    >
      {children}
    </Paper>
  )
}

export default Component;