import { ButtonGroup, Button } from '@mui/material';
import { styled } from '@mui/system';

const CustomButtonGroup = styled(ButtonGroup)`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background: #F3F8FA;
    border-radius: 8px;
    max-width:100%;
    height: 56px;

    .MuiButtonGroup-grouped:not(:last-of-type) {
        border-right: none;
    }
`;

const CustomGroupedButton = styled(Button)`

    font-family: 'Picadilly';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 170%;
    text-transform: none;
    color: #555866;
    border: none;
    border-radius: none;
    background-color: transparent;
    transition: all .5s ease;

    :hover{
        background-color: transparent;
        border: none;
        color: #0068ff;
        transition: all .5s ease;
    }

    @media screen and (max-width: 500px){
        font-size: 12px;
    }
`;

export {CustomButtonGroup, CustomGroupedButton};