import React from 'react';
import { GeneralComponents } from '@components';
import { BsArrowRightShort } from 'react-icons/bs';
import './onboardingBanner.css'
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';

const OnboardingBanner: React.FC = () => {

  const isSystemAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin)
  const dispatch = useDispatch()

  const handleOnboardingTourClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    dispatch(slices.onboardingTooltip.setOnboardingTooltipInfo({showOnboardingTooltip: true, onboardingLocation: '/dashboard'}))
  }

  return <section className={isSystemAdmin ? 'onboarding-banner' : 'common-onboarding-banner'}>
    <div className='onboarding-banner-header'>
      <h2>Conheça o novo Center</h2>
      <GeneralComponents.CustomDefaultTextButton endIcon={<BsArrowRightShort color='#CCE0FF' size={24} />} sx={{ color: '#CCE0FF' }} onClick={handleOnboardingTourClick}>Faça nosso tour</GeneralComponents.CustomDefaultTextButton>
    </div>
    <img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/center-admin.png' alt='New Center dashboard layout'></img>
  </section>;
}

export default OnboardingBanner;