import { GeneralComponents } from '@components';
import { SelectChangeEvent } from '@mui/material';
import React, { useRef } from 'react';

interface MobileFilterSectionProps {
    selectState: any,
    setSelectState: any,
    open: boolean,
    closeAction: any,
    clearFilters: any,
    requestFilters: any,
}

const MobileFilterSection: React.FC<MobileFilterSectionProps> = (
    {
        selectState,
        setSelectState,
        open,
        closeAction,
        clearFilters,
        requestFilters,
    }
) => {
    const sideSectionRef = useRef(null)

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const { name } = event.target
        setSelectState({ ...selectState, [name]: event.target.value as string })
    }


    return <GeneralComponents.MobileSideSection
        open={open}
        mainButtonText='Limpar filtros'
        mainButtonType='text'
        mainButtonAction={clearFilters}
        mainButtonColor='#0068FF'
        secondaryButtonText='Filtrar'
        secondaryButtonType='default'
        secondaryButtonAction={requestFilters}
        closeAction={closeAction}
        sideSectionRef={sideSectionRef}
        sideSectionTitle='Filtros'
    >
        <GeneralComponents.CustomSelect
            variant="outlined"
            name='folder'
            LabelText='Selecione a pasta'
            value={selectState?.folder}
            onChange={handleSelectChange}
            sx={{ minWidth: '100%', maxWidth: '100% !important' }}
        >
            <GeneralComponents.CustomMenuItem value='none'>Todas</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='dataplatform'>Plataforma de dados</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='bigid'>BigID</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='bigweb'>BigWeb</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='blabs'>B-LABS</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='parceria'>Parceria</GeneralComponents.CustomMenuItem>
        </GeneralComponents.CustomSelect>
        <GeneralComponents.CustomSelect
            variant="outlined"
            name='origin'
            LabelText='Tipo de arquivo'
            value={selectState?.origin}
            onChange={handleSelectChange}
            sx={{ width: '100%' }}
            fullWidth
        >
            <GeneralComponents.CustomMenuItem value='none'>Todos</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='in'>Entrada</GeneralComponents.CustomMenuItem>
            <GeneralComponents.CustomMenuItem value='out'>Saída</GeneralComponents.CustomMenuItem>
        </GeneralComponents.CustomSelect>
    </GeneralComponents.MobileSideSection>;
}

export default MobileFilterSection;