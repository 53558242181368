import { GeneralComponents } from '@components';
import { Box, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainLineContainer, MainLineLabel, ResultContainer, ResultLineContainer, ResultLineLabel } from './styles';
import { current } from 'immer';


interface ResultBoxProps {
    mainLineLabel?: string;
    mainLineContent?: string
    data: ResultLineProps[]
    isLoading: boolean
}

type ResultLineProps = {
    label: string;
    content: string | string[];
    variant?: string;
}

const ResultLine: React.FC<ResultLineProps> = (
    {
        label,
        content,
        variant = 'neutral',

    }) => {
    return <Box sx={ResultLineContainer}>
        <span style={ResultLineLabel}>{label}:</span>

        {Array.isArray(content) ?
            content.map((value) =>
                <GeneralComponents.Tag variant={variant}>{value}</GeneralComponents.Tag>)
            :
            <GeneralComponents.Tag variant={variant}>{content}</GeneralComponents.Tag>}
    </Box>
}

const ResultBox: React.FC<ResultBoxProps> = (
    {
        mainLineLabel = 'ID da consulta:',
        mainLineContent,
        data,
        isLoading
    }) => {

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const loadingTexts = ['Aguarde enquanto processamos sua solicitação...', 'Por favor, aguarde. O processo pode levar alguns minutos...', 'Sua solicitação ainda está sendo processada, aguarde...']
    const changeLoadingMessage = () => {
        setCurrentIndex(currentIndex + 1)
    }


    useEffect(() => {

        if (!!isLoading) {
            setTimeout(changeLoadingMessage, 20000)
        }


    }, [isLoading]);

    if (!!isLoading) {

        return <Box sx={ResultContainer}>
            <h2>Resultado</h2>
            <span style={MainLineLabel}>{loadingTexts[currentIndex]}</span>

            <Box sx={MainLineContainer}>
                <Skeleton variant="text" sx={{ width: '100%' }} />
            </Box>
            <Box sx={{ width: '100%' }}>
                <Skeleton variant="text" sx={{ width: '100%' }} />
                <Skeleton variant="text" sx={{ width: '100%' }} />
                <Skeleton variant="text" sx={{ width: '100%' }} />
            </Box>
        </Box>
    }


    if (!!!data.length || !!!mainLineContent) {
        return <></>
    }

    return <Box sx={ResultContainer}>
        <h2>Resultado</h2>
        <Box sx={MainLineContainer}>
            <span style={MainLineLabel}>{mainLineLabel}</span>
            <GeneralComponents.Tag
                variant='neutral'
                size='medium'
            >{mainLineContent ?? ''}</GeneralComponents.Tag>
        </Box>
        {data.map((line) => <ResultLine label={line.label} content={line.content} variant={line.variant} />)}
    </Box>;
}

export default ResultBox;