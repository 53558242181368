import { ReactNode } from 'react';
import { SelectProps, Select, FormControl, SelectChangeEvent, FormLabel, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

interface CustomSelectProps extends Omit<SelectProps, 'helperText'> {
  helperText?: string;
  onChange: (event: SelectChangeEvent<unknown>, child: ReactNode) => void;
  LabelText: string;
  pt?: number | string;
  pb?: number | string;
  pl?: number | string;
  pr?: number | string;
}

const CustomSelectRoot = styled(FormControl)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    min-width: 170px;
    max-width: 200px;
    box-sizing: border-box;


    .MuiOutlinedInput-notchedOutline{
      border: 1px solid #BED0D7;
      border-radius: 8px;
        
    }
`

const CustomSelectLabel = styled(FormLabel)`
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 140%;
    color: #000066;
    margin-left: 4px;
`

const StyledSelect = styled(Select)`
    && {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      padding: 2px 8px;
      font-family: 'Be Vietnam';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #555866;
      border-color: #BED0D7;
    }

    .MuiOutlinedInput-notchedOutline, .MuiInput-underline{
      border: 1px solid #BED0D7;
      border-radius: 8px;
    }

    .MuiSelect-icon{
        width: 24px;
        height: 24px;
        color: #BED0D7;
    }

    
`

const CustomMenuItem = styled(MenuItem)`
  

  && {
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #0448AB;
    width: 90%;
    padding: 4px 16px;
  }

  :hover{
    background-color: #F3F8FA;
    border-radius: 8px;
    padding: 4px 16px;
    color: #0448AB;
    padding: 4px 16px;
    
  } 

  &.Mui-selected{
    background-color: #0068FF;
    border-radius: 8px;
    padding: 4px 16px;
    color: white;
    width: 90%;
    margin: 0 auto;
  }

  &.Mui-selected:hover{
    background-color: #0068FF;
    border-radius: 8px;
    padding: 4px 16px;
    color: white;
    width: 90%;
    margin: 0 auto;
  } 
`


const CustomSelect = styled((
  {
    variant = 'outlined',
    helperText,
    onChange,
    value,
    name,
    LabelText,
    children,
    pt = '2px',
    pb = '2px',
    pr = '8px',
    pl = '8px',
    ...otherProps
  }: CustomSelectProps) => (
  <CustomSelectRoot
    variant={variant}
  >
    {LabelText !== ''
      ?
      <CustomSelectLabel>{LabelText}</CustomSelectLabel>
      :
      <></>
    }
    <StyledSelect
      name={name}
      onChange={onChange}
      value={value ?? ""}
      sx={{
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
      }}
    >
      {children}
    </StyledSelect>
  </CustomSelectRoot>
))`

`;

export { CustomSelect, CustomMenuItem };