import React, { useMemo } from 'react';
import Countdown, { CountdownRenderProps } from 'react-countdown';
import { showToastCustomWarningMessage } from '@utils';

interface CountdownTimerProps{
  handleReturnToLogin: () => void
}

const fiveMinutesInMiliseconds = 300000

function CountdownTimer({handleReturnToLogin}: CountdownTimerProps) {
    const renderer = ({ minutes, seconds, completed }: CountdownRenderProps) => {

        if (completed) {
            showToastCustomWarningMessage('Erro na autenticação.', 'O tempo limite para verificação do token foi atingido.')
            handleReturnToLogin()
        }

        const formatNumber = (value: number) => {
            return value < 10 ? `0${value}` : value.toString();
          };

        return (
            <span>
            {formatNumber(minutes)}:{formatNumber(seconds)}
          </span>
        );
    };
    
    const currentDate = useMemo(() => {
      return Date.now();
    }, []);
    

    return <Countdown date={currentDate + fiveMinutesInMiliseconds} autoStart renderer={renderer} />
}

export default CountdownTimer;