
import {
    GridPagination, gridPageCountSelector, useGridApiContext, useGridSelector
  } from '@mui/x-data-grid';
  import MuiPagination from '@mui/material/Pagination';
  import { TablePaginationProps } from '@mui/material/TablePagination';
  
  const paginationCustomStyle = {
    '& .Mui-selected': {
      color: 'white !important',
      backgroundColor: '#0069ff !important',
      transition: 'background-color 1s ease !important',
      '&:hover': {
        backgroundColor: '#006 !important',
        transition: 'background-color 1s ease !important'
      },
    },
    '& .MuiPaginationItem-root:not(.Mui-selected):hover': {
      transition: 'background-color .5s ease !important',
      backgroundColor: '#f0f8fa !important',
    },
    '& .MuiPaginationItem-page:not(.Mui-selected) !important': {
      transition: 'background-color .5s ease !important',
      color: '#006 !important',
      fontFamily: "'Be vietnam', sans-serif !important"
    },
  
  };
  const gridPaginationCustomStyle = {
    width: '100%',
    '& .MuiToolbar-root': {
      width: '100%',
      padding: '0px !important'
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 'auto !important'
    },
    '& .MuiInputBase-root': {
      border: '2px solid #B1B9BD',
      borderRadius: '8px',
      fontFamily: "'Be vietnam', sans-serif",
      fontSize: '14px'
    },
    '& .MuiTablePagination-spacer': {
      display: 'none !important'
    },
    '& .MuiTablePagination-displayedRows': {
      display: 'none !important'
    },
    '& .MuiTablePagination-selectLabel': {
      fontFamily: "'Be vietnam', sans-serif",
      fontSize: '12px'
    }
  }
  
  function Pagination({
    onPageChange,
    page,
    className
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className' | 'count'>) {

    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);


    const onPageNumberChange = (event: any, newPage: number) => {
      onPageChange(event, newPage);
    }
  
    return (
      <MuiPagination
        sx={paginationCustomStyle}
        variant='text'
        shape='rounded'
        className={className}
        count={pageCount}
        page={page + 1}
        onChange={(event, newPage) => {
          onPageNumberChange(event as any, newPage - 1);
        }}
  
      />
    );
  }
  
  
  function detailedUsageSummaryPagination (){
    return <GridPagination
      sx={gridPaginationCustomStyle}
      labelRowsPerPage='Resultados por página:'
      ActionsComponent={Pagination}
    />
  }
  
  export default detailedUsageSummaryPagination 