import React, { useRef } from 'react';
import { MobileSideSection } from '../../../../components/GeneralComponents';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import {  GeneralComponents } from '@components';
import { showToastCustomErrorMessage } from '@utils';
import dayjs from 'dayjs';



const ConsumptionDetails: React.FC = () => {

    const dispatch = useDispatch()
    const { open, params } = useSelector((state: reducers.RootState) => state.consumptionDetailsReducer)
    const sideSectionRef = useRef(null);

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.consumptionDetails.setShowConsumptionDetailsSection(false))
    }

    return <MobileSideSection
        sideSectionTitle='Detalhes do consumo'
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
        <div className='invoice-details-content'>
            <div className='invoice-details-data'>
                <div className='mobile-side-section-group'>
                    <label>Produto: </label>
                    <GeneralComponents.ProductTag params={params?.Product}/>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Mês de referência: </label>
                    <span>{dayjs(params?.Month, 'YYYY_MM').format('MMMM [de] YYYY')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições gratuitas: </label>
                    <span>{params?.FreeRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições pagas: </label>
                    <span>{params?.PaidRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Total de requisições: </label>
                    <span>{params?.TotalRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço médio: </label>
                    <span>{parseFloat(params?.AveragePrice).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Descontos: </label>
                    <span>{parseFloat(params?.VolumeDiscount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço: </label>
                    <span>{parseFloat(params?.Price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </div>
        </div>
    </MobileSideSection>

}

export default ConsumptionDetails;