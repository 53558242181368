import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';

import { navbarCategories } from "@constants";
import { reducers, slices } from "@store";

import { isDesktopWidth } from '@constants'
import { Box, Divider, Grid } from '@mui/material';
import { Category, Content, Link, Row } from './components';


export default function Navbar() {
    const [activeCategory, setActiveCategory] = useState<string>('')
    const categoryOptions = useRef<any>([]);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {
        isSystemAdmin: isAdmin,
        parameters: { FileTransfer: fileTransferEnabled }
    } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user);
    const {
        isOpenInMobile: isNavbarOpenInMobile,
        isOpen: isNavbarCategoryOptionsOpen
    } = useSelector((state: reducers.RootState) => state.navbarReducer);

    const toggleIsNavbarOpen = (event: React.MouseEvent<HTMLDivElement> | React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setActiveCategory('')
        navbarCategories.map((activeCategory) => activeCategory.active = false)
        dispatch(slices.navbar.setIsNavbarOpen(false))
    }

    const handleNavOnClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLLIElement>) => {
        event.preventDefault()

        const id = event.currentTarget.tagName === 'LI' ? event.currentTarget.children[0].id : event.currentTarget.id;

        const navLink = navbarCategories
            .flatMap((categoria) => categoria.links)
            .find((link) => link.id === id);

        if (navLink && !!!navLink.disabled) {
            dispatch(slices.lastUsed.addFeature({
                name: navLink.name,
                description: navLink.description,
                link: navLink.url,
                icon: navLink.urlIcon
            }));

            navigate(navLink.url)
            dispatch(slices.navbar.setIsNavbarOpen(false))
        }

        if (!!!isDesktopWidth) {
            dispatch(slices.navbar.setIsNavbarOpen(false))
            dispatch(slices.navbar.setIsMobileNavbarOpen(false))
        }
    }


    const handleCategoryClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()

        if (event.currentTarget.id === 'home') {
            setActiveCategory(event.currentTarget.id)
            navigate('/dashboard')

            if (!!!isDesktopWidth) {
                dispatch(slices.navbar.setIsNavbarOpen(false))
                dispatch(slices.navbar.setIsMobileNavbarOpen(false))
            }

            return
        }

        if (event.currentTarget.id === 'help') {
            setActiveCategory(event.currentTarget.id)
            navigate('/dashboard/help/center')

            if (!!!isDesktopWidth) {
                dispatch(slices.navbar.setIsNavbarOpen(false))
                dispatch(slices.navbar.setIsMobileNavbarOpen(false))
            }

            return
        }

        if (activeCategory === event.currentTarget.id) {
            setActiveCategory('')
            navbarCategories.map((category) => category.active = false)
            dispatch(slices.navbar.setIsNavbarOpen(false))
            return
        }

        navbarCategories.map((category) => {
            if (category.id !== event.currentTarget.id) {
                return category.active = false
            }

            return category.active = true
        })

        setActiveCategory(event.currentTarget.id)
        dispatch(slices.navbar.setIsNavbarOpen(true))

    }


    const isUserAllowedToSeeThisLink = (linkName: string) => {
        if (!!!isAdmin && linkName === 'userManagement') {
            return false
        }

        if ((fileTransferEnabled === "False" || !!!fileTransferEnabled) && linkName === "fileTransfer") {
            return false
        }

        return true
    }

    useEffect(() => {
        categoryOptions.current?.find((element: any) => element?.id.includes(activeCategory))?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });
    }, [activeCategory])


    return !!isDesktopWidth ? <Box
        position='relative'
        display='flex'
        gridArea='nav'
        flexDirection='column'
        alignItems='center'
        padding='24px 4px'
        gap={4}
        borderRight='1px solid #1D203A'
        bgcolor='#1D203A'
        height='100%'
        zIndex={1000}
        onMouseLeave={toggleIsNavbarOpen}
    >
        <Box mb={4}>
            <img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/navbar-logo.svg' alt='Center logo' />
        </Box>

        {
            navbarCategories.map(({ links, ...category }) =>
                <Category
                    key={category.name}
                    open={isNavbarCategoryOptionsOpen}
                    isAdmin={isAdmin}
                    category={category}
                    onClick={handleCategoryClick}
                >

                    {
                        links.map(({ id, name, url, urlIcon, disabled, target, rel }) => {

                            
                            return !!isUserAllowedToSeeThisLink(name) && (
                                <Box
                                    width={'100%'}
                                >
                                    <Link
                                        id={id}
                                        key={name}
                                        name={name}
                                        icon={urlIcon}
                                        to={url}
                                        disabled={disabled}
                                        target={target}
                                        rel={rel}
                                        onClick={handleNavOnClick}
                                    />
                                    <Divider
                                        variant='fullWidth'
                                        sx={{
                                            bgcolor: '#EDF2F4',
                                            borderColor: '#EDF2F4',
                                            filter: 'drop-shadow(1px 1px 1px #EDF2F4)'
                                        }}
                                    />
                                </Box>
                            )
                        }
                            
                        )
                    }
                </Category>
            )
        }
    </Box >

        :
        !!isNavbarOpenInMobile ? (
            <Box
                position='fixed'
                left={0}
                right={0}
                top={0}
                bottom={0}
                display='flex'
                flexWrap='wrap'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                bgcolor={'#F3F8FA'}
                width='100vw'
                height='calc(100% - 54px)'
                padding={6}
                gap={4}
                overflow={'scroll'}
                zIndex={1000}
                onMouseLeave={toggleIsNavbarOpen}
            >
                <Grid
                    container
                    rowGap={4}
                    height={'100vh'}
                    justifyContent={'center'}
                >
                    {navbarCategories.map(({ links, ...category }, index) => {
                        if (index % 2 === 0) {
                            const nextCategory = navbarCategories[index + 1];

                            return (
                                <Content key={index}>
                                    <Row>
                                        <Category
                                            key={category.name}
                                            open={isNavbarCategoryOptionsOpen}
                                            isAdmin={isAdmin}
                                            category={category}
                                            onClick={handleCategoryClick}
                                        />
                                        {
                                            nextCategory && (
                                                <Category
                                                    key={nextCategory?.name}
                                                    open={isNavbarCategoryOptionsOpen}
                                                    isAdmin={isAdmin}
                                                    category={nextCategory}
                                                    onClick={handleCategoryClick}
                                                />
                                            )
                                        }
                                    </Row>
                                    {!!isNavbarCategoryOptionsOpen && category.active && links.map(({ id, name, url, urlIcon, disabled, target, rel }) =>
                                        !!isUserAllowedToSeeThisLink(name) && (
                                            <Box
                                                width={'100%'}
                                            >
                                                <Link
                                                    id={id}
                                                    key={name}
                                                    name={name}
                                                    icon={urlIcon}
                                                    to={url}
                                                    disabled={disabled}
                                                    target={target}
                                                    rel={rel}
                                                    onClick={handleNavOnClick}
                                                />
                                                <Divider
                                                    variant='fullWidth'
                                                    sx={{
                                                        bgcolor: '#EDF2F4',
                                                        borderColor: '#EDF2F4',
                                                        filter: 'drop-shadow(1px 1px 1px #EDF2F4)'
                                                    }}
                                                />
                                            </Box>
                                        )
                                    )


                                    }
                                    {!!isNavbarCategoryOptionsOpen && nextCategory?.active && nextCategory?.links.map(({id, name, url, urlIcon, disabled, target, rel }) =>
                                        !!isUserAllowedToSeeThisLink(name) && (
                                            <Box
                                                width={'100%'}
                                            >
                                                <Link
                                                    id={id}
                                                    key={name}
                                                    name={name}
                                                    icon={urlIcon}
                                                    to={url}
                                                    disabled={disabled}
                                                    target={target}
                                                    rel={rel}
                                                    onClick={handleNavOnClick}
                                                />
                                                <Divider
                                                    variant='fullWidth'
                                                    sx={{
                                                        bgcolor: '#EDF2F4',
                                                        borderColor: '#EDF2F4',
                                                        filter: 'drop-shadow(1px 1px 1px #EDF2F4)'
                                                    }}
                                                />
                                            </Box>
                                        )
                                    )
                                    }
                                </Content>
                            )
                        }

                        return null;
                    })}
                </Grid>
            </Box>
        )
            :
            <></>
}

