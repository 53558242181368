import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface EditUserData {
    login: string;
    name: string;
    email: string;
    isSystemAdmin: boolean;
    isEnabled: boolean;
    group: string;
    "2fa": boolean;
    fileTransfer?: boolean;
    showEditUserSection?: boolean;
    outsideClickOcurred?: boolean;
    wasUserEditionConfirmed?: boolean;
}
  
const initialState: EditUserData = {
    login: '',
    name: '',
    email: '',
    isSystemAdmin: false,
    isEnabled: false,
    "2fa": false,
    group: '',
    fileTransfer: false,
    showEditUserSection: false,
    outsideClickOcurred: false,
    wasUserEditionConfirmed: false,
}

const editUserSlice = createSlice({
    name: 'editUser',
    initialState,
    reducers: {
      setEditUserInfo: (state, action: PayloadAction<EditUserData>) => {
        return {...state, ...action.payload}
      },
      setShowEditUserSection: (state, action: PayloadAction<boolean>) => {
        state.showEditUserSection = action.payload
      },
      setWasUserEditionConfirmed: (state, action: PayloadAction<boolean>) => {
        state.wasUserEditionConfirmed = action.payload
      },
      setOutsideClickOcurred: (state, action: PayloadAction<boolean>) => {
        state.outsideClickOcurred = action.payload
      },
    }
});

export const { setEditUserInfo,setShowEditUserSection, setWasUserEditionConfirmed, setOutsideClickOcurred } = editUserSlice.actions;

export default editUserSlice.reducer;