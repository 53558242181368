// import './wdyr';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { store } from "@store"
import router from './routes/routes';


import './App.css';
import { ToastContainer } from 'react-toastify';
import { NotificationCenterProvider } from './components/NotificationCenterProvider';
import { isDesktopWidth } from '@constants';
import { RouterProvider } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material/styles';
declare module '@mui/material/styles' {

  interface DefaultColorOptions {
    main: string,
    lightest: string,
    light: string,
    pure: string,
    medium: string,
    dark: string
  }

  interface TertiaryColorOptions {
    greenPunch: string;
    greenWater: string;
    yellow: string;
  }

  interface NeutralColorOptions {
    100: string,
    200: string,
    300: string,
    400: string,
    500: string,
    white: string
  }

  interface Palette {
    tertiary: TertiaryColorOptions;
    neutral: NeutralColorOptions;
    lighterBigBlue: DefaultColorOptions;
    darkerBigBlue: DefaultColorOptions;
    negative: DefaultColorOptions;
  }

  interface PaletteOptions {
    tertiary: TertiaryColorOptions;
    neutral: NeutralColorOptions;
    lighterBigBlue: DefaultColorOptions;
    darkerBigBlue: DefaultColorOptions;
    negative: DefaultColorOptions;
  }
  interface PaletteColor {
    cian?: string;
    blue?: string;
    gray?: string;
    marine?: string;
    red?: string;
    lightest?: string;
    pure?: string;
    medium?: string;
  }

  interface SimplePaletteColorOptions {
    cian?: string;
    blue?: string;
    gray?: string;
    marine?: string;
    red?: string;
    lightest?: string;
    pure?: string;
    medium?: string;
  }

  interface TypographyVariants {
    cardTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    cardTitle?: React.CSSProperties;
  }

}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    cardTitle: true;
  }
}

const theme = createTheme({
  spacing: 4,
  palette: {
    primary: {
      main: "#0068FF",
      cian: "#01e0ff",
      blue: "#0068FF",
      gray: "#DDE6EB"
    },
    secondary: {
      main: "#000066",
      blue: "#000066",
      marine: "#1D203A",
      red: "#FF003C"
    },
    tertiary: {
      greenPunch: "#3CFF82",
      greenWater: "#3CEDBD",
      yellow: "#FFD40C"
    },
    neutral: {
      100: "#555866",
      200: "#7D8285",
      300: "#B1B9BD",
      400: "#EDF2F4",
      500: "#F3F8FA",
      white: "#FFFFFF"
    },
    lighterBigBlue: {
      main: "#0068FF",
      lightest: "#E5EFFF",
      light: "#CCE0FF",
      pure: "#0068FF",
      medium: "#0048B2",
      dark: "#00347F"
    },
    darkerBigBlue: {
      main: "#0068FF",
      lightest: "#0056D2",
      light: "#0043A4",
      pure: "#003076",
      medium: "#001D48",
      dark: "#000A1A"
    },
    success: {
      main: "#28AD58",
      lightest: "#EAFBF0",
      light: "#99ECB7",
      pure: "#28AD58",
      medium: "#1E8242",
      dark: "#14562C"
    },
    warning: {
      main: "#FFD40C",
      lightest: "#FFFAE6",
      light: "#FFE985",
      pure: "#FFD40C",
      medium: "#B48304",
      dark: "#8F5A00"
    },
    negative: {
      main: "#CC0030",
      lightest: "#FFF6F8",
      light: "#FCE7EC",
      pure: "#CC0030",
      medium: "#990024",
      dark: "#660018"
    }
  },
  typography: {
    cardTitle: {
      fontFamily: 'Be Vietnam',
      fontWeight: 700,
      fontSize: 16,
      color: "#1D203A"
    },
    h1: {
      fontFamily: 'Picadilly',
      fontSize: '32px',
      color: '#006',
      fontWeight: 700
    },
    h2: {
      fontFamily: 'Picadilly',
      fontSize: '20px',
      color: '#006',
      fontWeight: 700
    },
    h3: {
      fontFamily: 'Picadilly',
      fontSize: '18px',
      color: '#555866',
      fontWeight: 700
    },
    body1: {
      fontFamily: 'Be Vietnam',
      fontSize: '12px',
      color: '#555866'
    },
    body2:
    {
      fontFamily: 'Be Vietnam',
      fontSize: '14px',
      color: '#555866'
    },
    subtitle1: {
      fontFamily: 'Be Vietnam',
      fontSize: '14px',
      color: '#555866',
      fontWeight: 400
    },
    subtitle2: {
      fontFamily: 'Be Vietnam',
      fontSize: '14px',
      color: '#555866',
      fontWeight: 700
    },
    caption: {
      fontFamily: 'Be Vietnam',
      fontSize: '12px',
      color: '#FF003C',
    },

  }
});


function App() {

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store.store}>
        <PersistGate loading={null} persistor={store.persistor}>
          <div className="App" id='App'>
            <ToastContainer style={{ width: isDesktopWidth ? 'auto' : '100%', padding: isDesktopWidth ? 0 : '5px 10px' }} limit={1} />
            <NotificationCenterProvider>
              <RouterProvider router={router} />
            </NotificationCenterProvider>
          </div>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;