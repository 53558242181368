import React from "react";

import { Box, Link } from "@mui/material";
import { MandatoryFields, OptionalFields, PriceSection } from "./components";
import { Onboarding, Notification } from "@components";

import { options, steps } from "@constants";

import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";

import { useFormContext } from "react-hook-form";
import { useFetch } from "@hooks";
import { Platform } from "@services";

const Openned = () => {
  const dispatch = useDispatch();
  const { reset, watch } = useFormContext();

  const { data: { api, datasets, typeConsult, savedConsult, condition } } = useSelector((state: reducers.RootState) => state.formWizard);
  const apiEnglishName = options.pontualConsult.apis.find(item => item.value === api)?.englishName || "";
  const datasetsAvailable = Object.fromEntries(
    Object.entries(datasets || {}).filter(([_, value]: any) => !!value.selected)
  );
  const stringDatasets = Object.keys(datasetsAvailable).join(",");
  const datasetsWithVersion = Object.entries(datasetsAvailable || {}).map(([key, value]: any) => `${key}_v${value?.properties?.version || 1}`);
  const currentWatch = watch();
  const [requiredFields, setRequiredFields] = React.useState<Array<any>>([]);
  const [optionalFields, setOptionalFields] = React.useState<Array<any>>([]);

  const { data, isLoading, isFetched } = useFetch(
    Platform.GetDatasetsDetails,
    {
      Api: apiEnglishName,
      Datasets: stringDatasets
    }
  );

  React.useEffect(() => {

    if (currentWatch?.documents?.uf) {
      const requiredFieldsByUF = data?.ConditionalMandatoryParameters?.UF?.[currentWatch?.documents?.uf];

      if (requiredFieldsByUF) {

        const prevRequiredFields: Array<any> = (
          condition === "alternative_keys" ?
            data?.MinimalQueryParameters?.reduce((accumulator: any, current: any) => (accumulator.concat(current?.Replacements || [])), []) :
            data?.MinimalQueryParameters
        ) || [];

        const newRequiredFields: Array<any> = data?.OptionalQueryParameters?.filter((optionalField: any) => requiredFieldsByUF.includes(optionalField?.Field));

        const newOptionalFields: Array<any> = data?.OptionalQueryParameters?.filter((optionalField: any) => !requiredFieldsByUF.includes(optionalField?.Field));

        setRequiredFields([...prevRequiredFields, ...newRequiredFields]);
        setOptionalFields(newOptionalFields);

        return;
      }

      resetRequiredFieldsValue();
      resetOptionalFieldsValue();
    }
  }, [currentWatch?.documents?.uf]);

  React.useEffect(() => {

    reset();
  }, []);

  React.useEffect(() => {

    resetRequiredFieldsValue();
    resetOptionalFieldsValue();
  }, [data]);

  React.useMemo(() => {

    const requiredDocuments = requiredFields?.map((field: any) => field.Field);
    dispatch(slices.formWizard.addData({ requiredDocuments }));
  }, [requiredFields])

  const resetRequiredFieldsValue = () => {

    setRequiredFields(() => (
      condition === "alternative_keys" ?
        (
          data?.MinimalQueryParameters?.reduce((accumulator: any, current: any) => accumulator.concat(current?.Replacements || []), [])
        ) : data?.MinimalQueryParameters
    ) || []);
  }

  const resetOptionalFieldsValue = () => {

    setOptionalFields(() => (
      condition === "alternative_keys" ?
        (
          data?.OptionalQueryParameters.filter((optionalQuery: any) => (
            !data?.MinimalQueryParameters.some((minimalQuery: any) => (
              minimalQuery?.Replacements.find((replacement: any) => (
                replacement?.Field === optionalQuery?.Field)
              )
            ))
          ))
        ) : data?.OptionalQueryParameters
    ) || []);
  }

  const availableStates = Object.keys(data?.ConditionalMandatoryParameters?.UF || {}).map((uf) => ({ value: uf, label: uf.toLocaleUpperCase() })) || [];

  const handleRedirect = () => {
    if (typeConsult === "saved") {
      dispatch(slices.formWizard.goStep(1));

      return;
    }

    dispatch(slices.formWizard.goStep(3));
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={6} id="step-documents">
        {
          typeConsult === "saved" && (
            <PriceSection viewName={savedConsult?.ViewName} api={apiEnglishName} datasets={datasetsWithVersion} />
          )
        }

        {
          (
            (!!!requiredFields || !!!requiredFields?.length)
            && isFetched
            && !!!isLoading
            && (!!!data?.MinimalQueryParameters || !!!data?.MinimalQueryParameters?.length)
          ) ?
            (
              <>
                <Notification
                  title="Sem parâmetros"
                  description={
                    <>
                      Não foi possível buscar os parâmetros disponíveis para este tipo de consulta, <Link component="button" onClick={handleRedirect}>selecione outro dataset</Link>.
                    </>
                  }
                  variant="danger"
                />
              </>
            ) : (
              condition === "default" ? (
                <>
                  <MandatoryFields
                    fields={requiredFields}
                    availableStates={availableStates}
                    isLoading={isLoading}
                  />
                  <OptionalFields
                    fields={optionalFields}
                    isLoading={isLoading}
                  />
                </>
              ) : (
                <>
                  <MandatoryFields
                    fields={requiredFields}
                    availableStates={availableStates}
                    isLoading={isLoading}
                    isAlternativeKeys={true}
                  />
                  <OptionalFields
                    fields={optionalFields}
                    isLoading={isLoading}
                  />
                </>
              )
            )
        }
      </Box>
      <Onboarding
        steps={steps.PontualConsult.Document}
        controlPath='PontualConsult.Document'
      />
    </>
  )
}

export default Openned;