import { AiOutlineArrowLeft } from "react-icons/ai";
import './returnLoginButton.css';


interface ReturnLoginButtonProps {
    handleReturnToLogin: () => void
}

const ReturnLoginButton = ({handleReturnToLogin}: ReturnLoginButtonProps) =>
    <div>
        <button type='button' onClick={() => handleReturnToLogin()} className='return-button'><AiOutlineArrowLeft color="#0068FF" size={24} style={{marginRight: 12}}/>Voltar</button>
    </div>


export default ReturnLoginButton