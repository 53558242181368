import React, { useEffect, useState } from 'react';
import PasswordChecklist, { RuleNames } from "react-password-checklist"
import { AiFillCheckCircle, AiOutlineCheckCircle } from 'react-icons/ai';

import { GeneralComponents, TextField, Button } from "@components";

import { BigBrother } from '@services';
import { useDispatch, useSelector } from 'react-redux';
import { handleAxiosError, showToastCustomErrorMessage, showToastCustomSuccessMessage } from '@utils';

import { reducers, slices } from "@store";
import { Box, Typography } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { passwordChecklistStyle as style } from './styles';


const messages = {
    capital: "Uma letra maiúscula",
    lowercase: "Uma letra minúscula",
    specialChar: "Um caractere especial",
    minLength: "Ao menos 8 caracteres",
    match: "Coincidirem entre si",
}

const icons = {
    ValidIcon: <AiFillCheckCircle color='#28AD58' size='16px' />,
    InvalidIcon: <AiOutlineCheckCircle color='#555866' size='16px' />
}

const rules: RuleNames[] = [
    "minLength",
    "specialChar",
    "capital",
    'lowercase',
    "match"
]

const ChangePasswordSection: React.FC = () => {

    const formProps = useForm({
        defaultValues: {
            newPassword: '',
            newPasswordConfirmation: ''
        },
    })

    const isDirty = Object.values(formProps.formState.dirtyFields).some((field) => !!field)
    
    const [isNewPasswordValid, setIsNewPasswordValid] = useState<boolean>(false)
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false)
    const [unsavedDialogConfirmHandlers, setUnsavedDialogConfirmHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });

    const outsideClickOccurred = useSelector((state: reducers.RootState) => state.userProfileReducer.outsideClickOccurred)

    const dispatch = useDispatch()

    const changePassword = async () => await BigBrother.changePassword({ newPassword: formProps?.watch()?.newPassword })

    const handleChangePasswordResponse = (apiChangePasswordResponse: any) => {
        if (!!!apiChangePasswordResponse.result) {
            showToastCustomErrorMessage('Erro na mudança de senha.', 'Por favor, verifique se a senha atende aos requisitos mínimos.')
            return
        }

        showToastCustomSuccessMessage('Senha alterada com sucesso', 'Por favor, faça login com a sua nova senha.')
        dispatch(slices.user.setUserInitialState())
    }

    const tryToChangePassword = async () => {
        try {
            const { data: apiChangePasswordResponse } = await changePassword()
            handleChangePasswordResponse(apiChangePasswordResponse)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleSaveButtonClick = async () => {
        await tryToChangePassword()
    }

    const unsavedChangesDialogResponse = async () => {
        return new Promise((resolve) => {
            setShowUnsavedChangesDialog(true);

            const handleConfirm = async () => {
                resolve(true);
                await tryToChangePassword()
                setShowUnsavedChangesDialog(false);
            };

            const handleCancel = () => {
                resolve(false);
                setShowUnsavedChangesDialog(false);
            };

            setUnsavedDialogConfirmHandlers({ confirmHandler: handleConfirm, cancelHandler: handleCancel });

        });
    }

    const handleCancelButtonClick = async () => {
        if (!!isDirty) {
            await unsavedChangesDialogResponse()
            formProps.reset({ newPassword: '', newPasswordConfirmation: '' })
        } 

        dispatch(slices.sideSection.setOpen(false))
        dispatch(slices.userProfile.setOutsideClickOccurred(false))
    }

    useEffect(() => {
        if (!!outsideClickOccurred) {
            handleCancelButtonClick()
        }
    }, [outsideClickOccurred])

    const { confirmHandler, cancelHandler } = unsavedDialogConfirmHandlers

    return <Box
        display={"flex"}
        flexDirection={"column"}
        width={"100%"}
    >   
        <Box>
            <Typography variant='h3'>Mudar senha</Typography>
            <Typography variant='subtitle1'>Sua nova senha deve conter:</Typography>
        </Box>
        <PasswordChecklist
            className='password-checklist'
            rules={rules}
            minLength={8}
            value={formProps?.watch()?.newPassword ?? ''}
            valueAgain={formProps?.watch()?.newPasswordConfirmation ?? ''}
            onChange={(isValid) => isValid ? setIsNewPasswordValid(true) : false}
            style={style}
            messages={messages}
            iconComponents={icons}
        />
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            width={'100%'}
            gap={4}
        >
            <FormProvider {...formProps}>
                <TextField
                    fullWidth
                    label={'Nova senha'}
                    name='newPassword'
                    type={'password'}
                />
                <TextField
                    fullWidth
                    label={'Confirme sua nova senha'}
                    name='newPasswordConfirmation'
                    type={'password'}
                />
            </FormProvider>
            <Box
                display={'flex'}
                alignSelf={'flex-end'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                gap={6}
            >
                <Button
                    color='negative'
                    variant='text'
                    size='small'
                    onClick={handleCancelButtonClick}
                >
                    Cancelar
                </Button>
                <Button
                    size='small'
                    disabled={!!!isNewPasswordValid}
                    onClick={handleSaveButtonClick}
                >
                    Salvar
                </Button>
            </Box>
        </Box>
        <GeneralComponents.ConfirmationDialog
            open={showUnsavedChangesDialog}
            onConfirm={confirmHandler}
            onLeave={cancelHandler}
        />
    </Box>
}

export default ChangePasswordSection;