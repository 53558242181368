import React, { useRef } from 'react';
import { MobileSideSection } from '../../../../components/GeneralComponents';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GeneralComponents } from '@components';
import { Dayjs } from 'dayjs';
import { SelectChangeEvent } from '@mui/material';

interface InvoicesMobileFilterProps {
    ReferenceMonthStart: string,
    ReferenceMonthEnd: string,
    handleReferenceStartChange: (referenceMonthStart: Dayjs | null) => void,
    handleReferenceEndChange: (referenceMonthEnd: Dayjs | null) => void,
    Status: string,
    handleStatusChange: (event: SelectChangeEvent<unknown>) => void
    tryListInvoices: () => void;
    resetInvoicesFilters: () => void;
    open: boolean,
    setIsMobileFiltersOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const InvoicesMobileFilters: React.FC<InvoicesMobileFilterProps> = (
    {
        ReferenceMonthStart,
        ReferenceMonthEnd,
        handleReferenceStartChange,
        handleReferenceEndChange,
        Status,
        handleStatusChange,
        tryListInvoices,
        resetInvoicesFilters,
        open,
        setIsMobileFiltersOpen
    }) => {

    const invoicesFilterRef = useRef(null)

    return <MobileSideSection
        sideSectionTitle='Filtros'
        sideSectionRef={invoicesFilterRef}
        mainButtonType='text'
        mainButtonText='Limpar filtros'
        mainButtonAction={resetInvoicesFilters}
        secondaryButtonType='default'
        secondaryButtonText='Filtrar'
        secondaryButtonAction={tryListInvoices}
        open={open}
        closeAction={(event: React.MouseEvent<HTMLButtonElement>) => setIsMobileFiltersOpen(false)}
    >
        <div className='mobile-side-section-group'>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <label>Mês de referência</label>
                <GeneralComponents.CustomDatePickers
                    disableFuture
                    LabelText=''
                    startValue={ReferenceMonthStart}
                    endValue={ReferenceMonthEnd}
                    views={['month', 'year']}
                    handleStartChange={handleReferenceStartChange}
                    handleEndChange={handleReferenceEndChange}
                    handleFieldErrors={() => { }}
                />
            </LocalizationProvider>
        </div>
        <div className='mobile-side-section-group'>
            <label>Status</label>
            <GeneralComponents.CustomSelect
                variant="outlined"
                LabelText=''
                value={Status}
                onChange={handleStatusChange}
                fullWidth
            >
                <GeneralComponents.CustomMenuItem value='all'>Todas as faturas</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='PAID'>Pago</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='PENDING'>Pendente</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='CANCELLED'>Cancelado</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='OVERDUE'>Atrasado</GeneralComponents.CustomMenuItem>
            </GeneralComponents.CustomSelect>
        </div>
    </MobileSideSection>;
}

export default InvoicesMobileFilters;