import React from "react";

import { Box, Skeleton } from "@mui/material"
import { Paper, Button, Notification, SideSection, TextField, Chip, Onboarding, ButtonGroup } from "@components";
import { Title, Subtitle, TitleCard, Line, Key, Value, TextPrice, ValuePrice, LabelSection } from "./styles";

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";

import { BiBookmark, BiPencil } from 'react-icons/bi';

import { pontualConsult, showToastCustomErrorMessage, showToastCustomSuccessMessage } from "@utils";
import { Billing, Platform } from "@services";
import { useFetch } from "@hooks";
import { steps } from "@constants";

import { reducers, slices } from "@store";
import { useSelector, useDispatch } from 'react-redux';

import Schema from "./schema";

const Review: React.FC = () => {
  const dispatch = useDispatch();
  const formSaveConsult = useForm({
    defaultValues: {
      consult_name: "",
      save_attributes: true
    },
    resolver: yupResolver(Schema)
  });
  const [openSideSection, setOpenSideSection] = React.useState<boolean>(false);
  const { data } = useSelector((state: reducers.RootState) => state.formWizard);
  const apiName = pontualConsult.getDataOption("apis", data.api)?.englishName || "";
  const datasetsWithVersion = Object.entries(data?.datasets || {})
    .map(([key, value]: any) => !!value?.selected ? `${key}_v${value?.properties?.version || 1}` : null)
    .filter(dataset => dataset);

  const datasetsToSend = datasetsWithVersion.map((dataset: any) => (
    {
      Entity: apiName,
      Dataset: dataset,
      Requests: 1
    }
  ));

  const { data: dataPrice, isLoading: isLoadingPrice } = useFetch(
    Billing.calculateConsultPrice,
    {
      "Products": {
        "BigBoost": datasetsToSend
      }
    }
  );

  const handleClickSaveConsult = (e: any) => {
    e.stopPropagation();

    setOpenSideSection(true);
  }

  const handleCloseSideSection = () => {
    setOpenSideSection(false);
  }

  const removeAttributes = (datasets: any) => {
    const newDatasets = Object.entries(datasets || {}).map(([key, value]: any) => (
      [
        key,
        {
          label: value?.label,
          properties: value?.properties,
          selected: value?.selected
        }
      ]
    ));

    return Object.fromEntries(newDatasets);
  }

  const handleSubmitSaveConsult = (values?: any, event?: any) => {
    event.preventDefault();
    const datasetsAvailable = Object.fromEntries(
      Object.entries(data?.datasets || {}).filter(([key, value]: any) => !!value.selected)
    );

    const datasets = !!values?.save_attributes ?
      JSON.stringify(datasetsAvailable) :
      JSON.stringify(removeAttributes(datasetsAvailable))

    Platform.SaveConsult({
      View: `${values?.consult_name}__${data?.condition}`,
      Datasets: datasets,
      Api: apiName || ""
    }).then((response) => {
      if (!!response.data.Status.api) {
        showToastCustomErrorMessage("Ocorreu um erro na sua solicitação.", response.data.Status.api[0]?.Message);

        return;
      }

      showToastCustomSuccessMessage("Consulta salva com sucesso!", "")
    });
  }

  const handleEditStep = (step: number) => {
    dispatch(slices.formWizard.goStep(step));
  }

  const handleCancelConsult = () => {
    dispatch(slices.formWizard.setFormWizardInitialState());
    dispatch(slices.formWizard.goStep(0));
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={8}>
        <Box gap={4}>
          <Title>Revisar consulta</Title>
          <Subtitle>Revise e confirme as configurações da consulta antes de prosseguir. Edite algo se necessário.</Subtitle>
        </Box>
        <Box display="flex" gap={6}>
          <Box width="75%">
            <Paper>
              <Box p={6} id="informations-consult">
                <Paper variant="outlined">
                  <Box display="flex" flexDirection="column" gap={8} p={6}>
                    <TitleCard>Configuração da consulta</TitleCard>
                    <Box display="flex" flexDirection="column" gap={4}>
                      <Line display="flex" justifyContent="space-between">
                        <Box display="flex" gap={4}>
                          <Key>API selecionada:</Key>
                          <Value>
                            {pontualConsult.getDataOption("apis", data?.api)?.title}
                          </Value>
                        </Box>
                        <Button onClick={() => handleEditStep(2)} variant="action" StartIcon={<BiPencil size={20} />}>Editar</Button>
                      </Line>
                      <Line display="flex" justifyContent="space-between">
                        <Box display="flex" gap={4}>
                          <Key>Datasets selecionados:</Key>
                          <Box display="flex" flexDirection="column" gap={1}>
                            {
                              Object.values(data?.datasets || {}).map((item: any, index) => {

                                const attributesLength = Object.values(item.content || {} as any).filter((content: any) => !!content.selected).length

                                return !!item.selected && (
                                  <Box display="flex" gap={2}>
                                    <Value key={index}>{item.label}</Value>
                                    <Chip>
                                      {
                                        !!attributesLength ? (
                                          `${attributesLength} atributos selecionados`
                                        ) : (
                                          `Todos os atributos selecionados`
                                        )
                                      }
                                    </Chip>
                                  </Box>
                                )
                              })
                            }
                          </Box>
                        </Box>
                        <Button onClick={() => handleEditStep(3)} variant="action" StartIcon={<BiPencil size={20} />}>Editar</Button>
                      </Line>
                      <Line display="flex" justifyContent="space-between">
                        <Box display="flex" gap={4}>
                          <Key>Tipo de consulta:</Key>
                          <Value>
                            {pontualConsult.getDataOption("conditions", data?.condition)?.title}
                          </Value>
                        </Box>
                        <Button onClick={() => handleEditStep(4)} variant="action" StartIcon={<BiPencil size={20} />}>Editar</Button>
                      </Line>
                      <Line display="flex" justifyContent="space-between">
                        <Box display="flex" gap={4}>
                          <Key>Chaves selecionadas:</Key>
                          <Box display="flex" flexDirection="column" gap={2}>
                            {
                              Object.entries(data?.documents || {})?.map((item: any) => {
                                if (!!!item[1].length) return

                                return (
                                  <Chip>
                                    {`${item[0]}: ${item[1]}`}
                                  </Chip>
                                )
                              })
                            }
                          </Box>
                        </Box>
                        <Button onClick={() => handleEditStep(5)} variant="action" StartIcon={<BiPencil size={20} />}>Editar</Button>
                      </Line>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Paper>
          </Box>
          <Box width="25%">
            <Box display="flex" flexDirection="column" gap={6}>
              <Paper>
                <Box display="flex" flexDirection="column" gap={4} p={6} id="save-consult">
                  <Box display="flex" flexDirection="column" gap={6}>
                    <TitleCard>Salve sua consulta</TitleCard>
                    <Subtitle>Deseja salvar as definições desta consulta para utilizar novamente? (Opcional)</Subtitle>
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Button onClick={handleClickSaveConsult} color="secondary" size="small" StartIcon={<BiBookmark size={20} />}>Salvar consulta</Button>
                  </Box>
                </Box>
              </Paper>
              <Paper>
                <Box display="flex" flexDirection="column" gap={4} p={6} id="cost-consult">
                  <Box display="flex" flexDirection="column">
                    <TextPrice>Custo máximo estimado da consulta:</TextPrice>
                    <ValuePrice>
                      {
                        isLoadingPrice ? (
                          <Skeleton variant="text" sx={{ width: "100%" }} />
                        ) : (
                          pontualConsult.formatMoneyBRL(dataPrice?.Price || 0)
                        )
                      }
                    </ValuePrice>
                  </Box>
                  <Notification
                    variant="warning"
                    title="A consulta pode gerar custos"
                    description="Se sua empresa já excedeu o limite de consultas gratuitas, essa consulta será cobrada."
                  />
                  <Box display="flex" justifyContent="flex-end" gap={6}>
                    <Button color="secondary" size="small" onClick={handleCancelConsult}>Cancelar consulta</Button>
                    <Button type="submit" color="primary" size="small">Consultar</Button>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </Box>
        </Box>
        <SideSection.Container open={openSideSection} onClose={handleCloseSideSection} title="Salvar consulta">
          <FormProvider {...formSaveConsult}>
            <form>
              <Box display="flex" flexDirection="column" gap={10} mt={5}>
                <TextField name="consult_name" label="Nome da consulta" placeholder="Insira um nome para identificá-la depois" />
                <Box>
                  <LabelSection>Deseja salvar os atributos utilizados na consulta?</LabelSection>
                  <ButtonGroup
                    name="save_attributes"
                    options={
                      [
                        {
                          label: "Sim",
                          value: true
                        },
                        {
                          label: "Não",
                          value: false
                        }
                      ]
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" gap={4}>
                  <Button
                    color="secondary"
                    size="small"
                    onClick={handleCloseSideSection}
                  >
                    Cancelar
                  </Button>
                  <Button
                    size="small"
                    onClick={formSaveConsult.handleSubmit(handleSubmitSaveConsult)}
                  >
                    Salvar consulta
                  </Button>
                </Box>
              </Box>
            </form>
          </FormProvider>
        </SideSection.Container>
      </Box>
      <Onboarding
        steps={steps.PontualConsult.Review}
        controlPath='PontualConsult.Review'
      />
    </>
  )
}

export default Review;
