import { styled } from '@mui/system';
import { Button } from '@mui/material';

const CustomDefaultTextButton = styled(Button)`
    width: fit-content;
    white-space: nowrap;
    background-color: transparent;
    padding: 0;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 140%;
    color: #0068FF;
    text-transform: none;
    transition: all .5s ease;
    border: none;
    box-shadow: none;

    :hover{
        color: #0448AB;
        background-color: transparent;
        transition: all .5s ease;
        box-shadow: none;

        .MuiButton-endIcon{
            color: #0448AB;
        }
    }
`;

export default CustomDefaultTextButton;