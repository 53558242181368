import { NavbarTypes } from "@types";
import {
    faUsersGear,
    faMagnifyingGlass,
    faLayerGroup,
    faStore,
    faTrophy,
    faChartLine,
    faPersonCircleQuestion,
    faBook,
    faKey,
    faFileInvoiceDollar,
    faFilePdf,
    faChartColumn,
    faArrowsRotate,
    faFingerprint
} from "@fortawesome/free-solid-svg-icons";
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { RiApps2Line, RiDashboardLine } from 'react-icons/ri';
import { BsDatabase } from 'react-icons/bs';
import { BiDollarCircle, BiWrench } from 'react-icons/bi';
import { isDesktopWidth } from "./screenWidth";
import { AiOutlineQuestionCircle } from "react-icons/ai";


const navbarCategories: NavbarTypes.LinkCategories[] = isDesktopWidth ? [
    {
        id: 'home',
        name: 'Início',
        categoryIcon: RiDashboardLine,
        active: false,
        links: []
    },

    {
        id: 'management',
        name: 'Gestão',
        categoryIcon: MdOutlineAdminPanelSettings,
        active: false,
        links: [
            {
                id: 'userManagement',
                name: 'Gestão de usuários',
                description: 'Gerencie usuários e tokens',
                url: '/dashboard/management/users',
                urlIcon: faUsersGear,
                disabled: false
            },
            {
                id: 'tokenManagement',
                name: 'Gestão de tokens',
                description: 'Gerencie tokens dos seus usuários',
                url: '/dashboard/management/tokens',
                urlIcon: faKey,
                disabled: false
            }
        ],
    },
    {
        id: 'financial',
        name: 'Financeiro',
        categoryIcon: BiDollarCircle,
        active: false,
        links: [
            {
                id: 'invoices',
                name: 'Faturas',
                description: 'Consulte e gerencie suas faturas',
                url: '/dashboard/financial/invoices',
                urlIcon: faFileInvoiceDollar,
                disabled: false
            },
            {
                id: 'usageSummary',
                name: 'Relatórios de consumo',
                description: 'Veja os relatórios de consumo',
                url: '/dashboard/financial/usage-summary',
                urlIcon: faFilePdf,
                disabled: false
            },
            {
                id: 'detailedUsageSummary',
                name: 'Consumo histórico',
                description: 'Veja o consumo histórico',
                url: '/dashboard/financial/detailed-usage-summary',
                urlIcon: faChartLine,
                disabled: false
            },
            {
                id: 'currentUsage',
                name: 'Consumo do mês',
                description: 'Visualize o consumo parcial do mês',
                url: '/dashboard/financial/current-usage',
                urlIcon: faChartColumn,
                disabled: false
            }
        ],
    },
    {
        id: 'platform',
        name: 'Plataforma',
        categoryIcon: BsDatabase,
        active: false,
        links: [
            {
                id: 'pontualConsult',
                name: 'Consulta pontual',
                description: 'Pesquise dados pontuais',
                url: '/dashboard/platform/pontual-consult/new',
                urlIcon: faMagnifyingGlass,
                disabled: false
            },
            {
                id: 'batchConsult',
                name: 'Consulta batch (Em breve)',
                description: 'Pesquise dados em lote',
                url: 'https://batch-portal.bigboost.bigdatacorp.com.br/login',
                target: '_blank',
                rel: "noopener noreferrer",
                urlIcon: faLayerGroup,
                disabled: true
            },
        ],
    },
    {
        id: 'apps',
        name: 'Apps',
        categoryIcon: RiApps2Line,
        active: false,
        links: [
            {
                id: 'ocr',
                name: 'Documentoscopia (Em breve)',
                description: 'Descrição BigId',
                url: 'https://bigid.bigdatacorp.com.br/login',
                target: '_blank', rel: "noopener noreferrer",
                urlIcon: faFingerprint,
                disabled: true
            },
            {
                id: 'bigMarket',
                name: 'BigMarket (Em breve)',
                description: 'Descrição BigMarket',
                url: '/dashboard/apps/bigMarket',
                urlIcon: faStore,
                disabled: true
            },
            {
                id: 'bigLead',
                name: 'BigLead (Em breve)',
                description: 'Descrição BigLead',
                url: '/dashboard/apps/bigLead',
                urlIcon: faTrophy,
                disabled: true
            }
        ],
    },
    {
        id: 'tools',
        name: 'Ferramentas',
        categoryIcon: BiWrench,
        active: false,
        links: [
            {
                id: 'statusRF',
                name: 'Atualização de status',
                description: 'Atualize seu status RF',
                url: '/dashboard/tools/document-update',
                urlIcon: faArrowsRotate,
                disabled: false,
            },
        ],
    },
    {
        id: 'help',
        name: 'Ajuda',
        categoryIcon: AiOutlineQuestionCircle,
        active: false,
        links: [
            {
                id: 'docs',
                name: 'Documentação',
                description: 'Resposta para dúvidas complexas',
                url: 'https://docs.bigdatacorp.com.br/',
                target: '_blank',
                rel: "noopener noreferrer",
                urlIcon: faBook,
                disabled: false
            },
            {
                id: 'faq',
                name: 'Dúvidas frequentes (FAQ)',
                description: 'Encontre respostas rápidas',
                url: 'https://bigdatacorp.zendesk.com/hc/pt-br',
                target: '_blank',
                rel: "noopener noreferrer",
                urlIcon: faPersonCircleQuestion,
                disabled: false
            },

        ],
    },
]
    :
    [
        {
            id: 'home',
            name: 'Início',
            categoryIcon: RiDashboardLine,
            active: false,
            links: []
        },
        {
            id: 'management',
            name: 'Gestão',
            categoryIcon: MdOutlineAdminPanelSettings,
            active: false,
            links: [
                {
                    id: 'userManagement',
                    name: 'Gestão de usuários',
                    description: 'Gerencie usuários e tokens',
                    url: '/dashboard/management/users',
                    urlIcon: faUsersGear,
                    disabled: false
                },
                {
                    id: 'tokenManagement',
                    name: 'Gestão de tokens',
                    description: 'Gerencie tokens dos seus usuários',
                    url: '/dashboard/management/tokens',
                    urlIcon: faKey,
                    disabled: false
                }
            ],
        },

        {
            id: 'apps',
            name: 'Apps',
            categoryIcon: RiApps2Line,
            active: false,
            links: [
                {
                    id: 'ocr',
                    name: 'Documentoscopia (Em breve)',
                    description: 'Descrição BigId',
                    url: 'https://bigid.bigdatacorp.com.br/login',
                    target: '_blank', rel: "noopener noreferrer",
                    urlIcon: faFingerprint,
                    disabled: true
                },
                {
                    id: 'bigMarket',
                    name: 'BigMarket (Em breve)',
                    description: 'Descrição BigMarket',
                    url: '/dashboard/apps/bigMarket',
                    urlIcon: faStore,
                    disabled: true
                },
                {
                    id: 'bigLead',
                    name: 'BigLead (Em breve)',
                    description: 'Descrição BigLead',
                    url: '/dashboard/apps/bigLead',
                    urlIcon: faTrophy,
                    disabled: true
                }
            ],
        },
        {
            id: 'platform',
            name: 'Plataforma',
            categoryIcon: BsDatabase,
            active: false,
            links: [
                {
                    id: 'pontualConsult',
                    name: 'Consulta pontual',
                    description: 'Pesquise dados pontuais',
                    url: '/dashboard/platform/pontual-consult/new',
                    urlIcon: faMagnifyingGlass,
                    disabled: false
                },
                {
                    id: 'batchConsult',
                    name: 'Consulta batch (Em breve)',
                    description: 'Pesquise dados em lote',
                    url: 'https://batch-portal.bigboost.bigdatacorp.com.br/login',
                    target: '_blank',
                    rel: "noopener noreferrer",
                    urlIcon: faLayerGroup,
                    disabled: true
                },
            ],
        },
        {
            id: 'financial',
            name: 'Financeiro',
            categoryIcon: BiDollarCircle,
            active: false,
            links: [
                {
                    id: 'invoices',
                    name: 'Faturas',
                    description: 'Consulte e gerencie suas faturas',
                    url: '/dashboard/financial/invoices',
                    urlIcon: faFileInvoiceDollar,
                    disabled: false
                },
                {
                    id: 'usageSummary',
                    name: 'Relatórios de consumo',
                    description: 'Veja os relatórios de consumo',
                    url: '/dashboard/financial/usage-summary',
                    urlIcon: faFilePdf,
                    disabled: false
                },
                {
                    id: 'detailedUsageSummary',
                    name: 'Consumo histórico',
                    description: 'Veja o consumo histórico',
                    url: '/dashboard/financial/detailed-usage-summary',
                    urlIcon: faChartLine,
                    disabled: false
                },
                {
                    id: 'currentUsage',
                    name: 'Consumo do mês',
                    description: 'Visualize o consumo parcial do mês',
                    url: '/dashboard/financial/current-usage',
                    urlIcon: faChartColumn,
                    disabled: false
                }
            ],
        },
        {
            id: 'tools',
            name: 'Ferramentas',
            categoryIcon: BiWrench,
            active: false,
            links: [
                {
                    id: 'statusRF',
                    name: 'Atualização de status',
                    description: 'Atualize seu status RF',
                    url: '/dashboard/tools/document-update',
                    urlIcon: faArrowsRotate,
                },
            ],
        },
        {
            id: 'help',
            name: 'Ajuda',
            categoryIcon: AiOutlineQuestionCircle,
            active: false,
            links: [
                {
                    id: 'docs',
                    name: 'Documentação',
                    description: 'Reposta para dúvidas complexas',
                    url: 'https://docs.bigdatacorp.com.br/',
                    target: '_blank',
                    rel: "noopener noreferrer",
                    urlIcon: faBook,
                    disabled: false
                },
                {
                    id: 'faq',
                    name: 'Dúvidas frequentes (FAQ)',
                    description: 'Encontre respostas rápidas',
                    url: 'https://bigdatacorp.zendesk.com/hc/pt-br',
                    target: '_blank',
                    rel: "noopener noreferrer",
                    urlIcon: faPersonCircleQuestion,
                    disabled: false
                },
            ],
        },
    ]
    ;

export default navbarCategories
