import dayjs, { Dayjs } from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { GridPaginationModel, GridValidRowModel } from '@mui/x-data-grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { SelectChangeEvent } from '@mui/material/Select';

import { GeneralComponents, Onboarding } from "@components"

import { handleAxiosError, isThereAnyOverdueInvoice, transformInvoiceToValidRows, formatToValidReferenceMonth, showToastCustomErrorMessage, showToastCustomInfoMessage, showToastCustomWarningMessage } from '@utils';

import { reducers, slices } from "@store";

import { isDesktopWidth, listInvoicesColumns, listInvoicesColumnsWithoutInstallments, steps } from "@constants";

import { Billing } from '@services';

import './invoices.css'
import '../financialManagement.css'
import InvoicesPagination from './InvoicesPagination';
import { IoMdArrowDropleft } from 'react-icons/io';
import { useNotificationCenterContext } from '../../../components/NotificationCenterProvider';

import InstallmentSection from './InstallmentSection';
import InvoicesOnboarding from './InvoicesOnboarding';
import InvoiceDetails from './InvoiceDetails';
import { BsFilter } from 'react-icons/bs';
import InvoicesMobileFilters from './InvoicesMobileFilters';

const datagridPageSizeOptions: number[] = []
const datagridSlots = {
    pagination: InvoicesPagination,
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}

const Invoices: React.FC = () => {

    const paginationInfo = useSelector((state: reducers.RootState) => state.invoicesPaginationReducer)
    const login = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.login)

    const { installmentsList, showInstallmentsSection } = useSelector((state: reducers.RootState) => state.installmentsReducer)

    const [groupIssuesBankSlips, setGroupIssuesBankSlips] = useState<boolean>(false)
    const [invoicesListData, setInvoiceListData] = useState<GridValidRowModel[]>([])
    const [ReferenceMonthStart, setReferenceMonthStart] = useState<string>('')
    const [ReferenceMonthEnd, setReferenceMonthEnd] = useState<string>('')
    const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: 0, pageSize: 12
    })
    const [Status, setStatus] = useState<string>('all')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const dispatch = useDispatch()
    const { notifications } = useNotificationCenterContext()

    const listInvoices = async () => await Billing.listInvoices({
        ReferenceMonthStart,
        ReferenceMonthEnd,
        Status: Status === 'all' ? '' : Status,
        Pagination: { Page: paginationInfo.Page }
    })

    const getGroupInfo = async () => await Billing.getGroupInfo()

    const resetInvoicesFilters = () => {
        if (!isDesktopWidth) {
            setIsMobileFiltersOpen(false)
        }

        setReferenceMonthStart('')
        setReferenceMonthEnd('')
        setStatus('all')
        dispatch(slices.invoicesPagination.setInvoicesPaginationInfo({ ...paginationInfo, Page: 0 }))
    }

    const handleGetGroupInfoResponse = (billingApiResponse: any) => {
        setGroupIssuesBankSlips(billingApiResponse?.EmitBankSlip)
    }

    const handleListInvoicesApiResponse = (billingApiResponse: any) => {

        if (billingApiResponse.Message === 'INVALID_PARAMETERS') {
            showToastCustomErrorMessage('Erro ao listar notas fiscais', 'Os parâmetros passados são inválidos.')
            return
        }

        handleListInvoicesSucessResponse(billingApiResponse)
        return
    }

    const handleListInvoicesSucessResponse = (billingApiResponse: any) => {


        if (billingApiResponse.Message === 'NO_INVOICES_FOUND') {
            showToastCustomInfoMessage('Nada encontrado.', 'Nenhuma nota fiscal foi encontrada')
            setInvoiceListData([])
            dispatch(slices.invoicesPagination.setInvoicesPaginationInfo({ Page: 1, Records: 0, TotalPages: 1, TotalRecords: 0 }))
            setIsLoading(false)
            return
        }

        const { Page, Records, TotalPages, TotalRecords } = billingApiResponse.Pagination
        const listInvoiceData = transformInvoiceToValidRows(billingApiResponse)
        handleOverdueInvoicesPossibility(listInvoiceData)
        setInvoiceListData(listInvoiceData)
        dispatch(slices.invoicesPagination.setInvoicesPaginationInfo({ Page, Records, TotalPages, TotalRecords }))
        setIsLoading(false)
        return
    }


    const isThereAnyUnreadOverdueInvoiceNotification = (invoice: any) => {
        if (!!notifications?.length) {
            return !!notifications.filter((notification) => notification?.id === `overdue-invoice-${invoice.InvoiceNumber}` && !!!notification.read)?.length
        }

        return false
    }

    const handleOverdueInvoicesPossibility = (invoiceRows: any) => {
        const overdueInvoices = isThereAnyOverdueInvoice(invoiceRows)

        if (!!overdueInvoices.length) {

            overdueInvoices.forEach((invoice: any) => {
                if (isThereAnyUnreadOverdueInvoiceNotification(invoice)) {
                    showToastCustomWarningMessage(
                        'Faturas em atraso!',
                        `Regularize a situação do pagamento da fatura de ${dayjs(invoice.ReferenceMonth, 'MM/YYYY', 'pt-br').format('MMMM [de] YYYY')}.`,
                        login,
                        `overdue-invoice-${invoice.InvoiceNumber}`
                    )
                    return
                }

                return
            }
            )
            return
        }

        return
    }

    const areFiltersValid = () => {
        if (dayjs(ReferenceMonthStart, 'YYYY_MM', 'pt-br').isAfter(dayjs(ReferenceMonthEnd, 'YYYY_MM', 'pt-br'))) {
            showToastCustomErrorMessage('Erro ao filtrar notas fiscais', 'A data inicial precisa ser anterior à data limite.')
            setIsLoading(false)
            return false
        }
        return true
    }

    const tryListInvoices = async () => {
        if (!isDesktopWidth) {
            setIsMobileFiltersOpen(false)
        }

        try {
            setIsLoading(true)
            if (areFiltersValid()) {
                const { data: apiBillingManagementApiResponse } = await listInvoices()
                handleListInvoicesApiResponse(apiBillingManagementApiResponse)
            }
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const tryGetGroupInfo = async () => {
        try {
            const { data: apiBillingManagementApiResponse } = await getGroupInfo()
            handleGetGroupInfoResponse(apiBillingManagementApiResponse)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleReferenceStartChange = (referenceMonthStart: Dayjs | null) => {

        if (referenceMonthStart) {
            setReferenceMonthStart(formatToValidReferenceMonth(referenceMonthStart))
        } else {
            setReferenceMonthStart('')
        }
    }

    const handleReferenceEndChange = (referenceMonthEnd: Dayjs | null) => {
        if (referenceMonthEnd) {
            setReferenceMonthEnd(formatToValidReferenceMonth(referenceMonthEnd))
        } else {
            setReferenceMonthEnd('')
        }
    }

    const handleStatusChange = (event: SelectChangeEvent<unknown>) => {
        setStatus(event.target.value as string)
    }

    const handlePaginationModelChange = useCallback((model: GridPaginationModel) => {
        setPaginationModel(model);
    }, [setPaginationModel]);

    const handleCloseBankSlipSection = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.bankSlips.setShowInstallmentsSection(false))
    }

    const toggleMobileFilterSection = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setIsMobileFiltersOpen(!isMobileFiltersOpen)
    }

    useEffect(() => {
        setIsLoading(true)
        tryListInvoices()
        tryGetGroupInfo()
    }, [paginationInfo.Page])

    return <main className='financial-management-main-container'>

        {!showInstallmentsSection ?
            <>
                <div className='financial-management-presentation-container'>
                    <h2 className='financial-management-title'>Faturas</h2>
                    <p className='financial-management-paragraph'>Consulte e gerencie suas faturas</p>
                </div>
                {isDesktopWidth ?
                    <div className='invoices-filter-container'>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                            <GeneralComponents.CustomDatePickers
                                disableFuture
                                LabelText='Mês de referência'
                                startValue={ReferenceMonthStart}
                                endValue={ReferenceMonthEnd}
                                views={['month', 'year']}
                                handleStartChange={handleReferenceStartChange}
                                handleEndChange={handleReferenceEndChange}
                                handleFieldErrors={() => { }}
                            />
                        </LocalizationProvider>
                        <GeneralComponents.CustomSelect
                            variant="outlined"
                            LabelText='Status'
                            value={Status}
                            onChange={handleStatusChange}
                            fullWidth
                        >
                            <GeneralComponents.CustomMenuItem value='all'>Todas as faturas</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='PAID'>Pago</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='PENDING'>Pendente</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='CANCELLED'>Cancelado</GeneralComponents.CustomMenuItem>
                            <GeneralComponents.CustomMenuItem value='OVERDUE'>Atrasado</GeneralComponents.CustomMenuItem>
                        </GeneralComponents.CustomSelect>
                        <div className='invoices-filter-options'>
                            <GeneralComponents.FilterButton variant='contained' onClick={tryListInvoices}>Filtrar</GeneralComponents.FilterButton>
                            <GeneralComponents.ClearFilterButton variant='text' onClick={resetInvoicesFilters}>Limpar Filtros</GeneralComponents.ClearFilterButton>
                        </div>
                    </div>
                    :
                    <div className='invoices-options'>
                        <GeneralComponents.CustomDefaultTextButton
                            id='invoices-filter-button'
                            onClick={toggleMobileFilterSection}
                            startIcon={<BsFilter color='#0068ff' size={24} />
                            }>Filtros</GeneralComponents.CustomDefaultTextButton>

                        <InvoicesMobileFilters
                            ReferenceMonthStart={ReferenceMonthStart}
                            ReferenceMonthEnd={ReferenceMonthEnd}
                            handleReferenceStartChange={handleReferenceStartChange}
                            handleReferenceEndChange={handleReferenceEndChange}
                            Status={Status}
                            handleStatusChange={handleStatusChange}
                            tryListInvoices={tryListInvoices}
                            resetInvoicesFilters={resetInvoicesFilters}
                            open={isMobileFiltersOpen}
                            setIsMobileFiltersOpen={setIsMobileFiltersOpen}
                        />
                    </div>
                }
                <section className='invoices-container'>
                    <div className='invoices-table-container'>
                        <GeneralComponents.StyledDataGridWithPagination
                            rows={invoicesListData ?? []}
                            columns={!groupIssuesBankSlips ? listInvoicesColumnsWithoutInstallments : listInvoicesColumns}
                            loading={isLoading}
                            paginationMode='server'
                            pageSizeOptions={datagridPageSizeOptions}
                            paginationModel={paginationModel}
                            onPaginationModelChange={handlePaginationModelChange}
                            rowCount={paginationInfo.TotalRecords}
                            slots={datagridSlots}
                            pagination
                        />
                    </div>
                </section>
                <Onboarding
                    steps={steps.Financial.Invoices}
                    controlPath="Invoices"
                />
                <InvoiceDetails groupIssuesBankSlips={groupIssuesBankSlips} />
            </>
            :
            <>
                <div className='financial-management-presentation-details-container'>
                    <GeneralComponents.DefaultWhiteButton sx={{ width: '32px !important', padding: '0px !important' }} variant='contained' onClick={handleCloseBankSlipSection}><IoMdArrowDropleft size={24} /></GeneralComponents.DefaultWhiteButton>
                    <h2 className='financial-management-title'>Boletos bancários</h2>
                </div>
                <InstallmentSection InstallmentSectionRows={installmentsList}></InstallmentSection>
            </>
        }
    </main>
}

export default Invoices;