import { IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { IconType } from 'react-icons';
import { BsQuestionCircle } from 'react-icons/bs';
import { TooltipIconButton } from './styles';

interface CustomTooltipProps {
    description: string,
    Icon?: IconType,
    placement?: 'top' | 'top-start' | 'top-end' | 'left' | 'left-start' | 'left-end' | 'bottom' | 'bottom-start' | 'bottom-end' | 'right' | 'right-start' | 'right-end'
    size?: number,
    color?: string
}

const Component: React.FC<CustomTooltipProps> = (
    {
        Icon = BsQuestionCircle,
        description,
        placement = 'right',
        size = 14,
        color = '#555866'
    }
) => {
    return <Tooltip
        title={description}
        placement={placement}
        sx={{ height: '100%' }}
    >

        <TooltipIconButton
            disableRipple
        >
            <Icon
                color={color}
                size={size}
            />
        </TooltipIconButton>

    </Tooltip>;
}

export default Component;