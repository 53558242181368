import { styled, Theme } from "@mui/material/styles"
import { StandardCSSProperties } from "@mui/system";

interface INotificationStyles {
  [key: string]: StandardCSSProperties | any
}

const Notification = styled('div')(({ theme, variant }: { theme?: Theme, variant: string }) => {
  const notificationStyles: INotificationStyles = {
    variant: {
      information: {
        backgroundColor: theme?.palette.lighterBigBlue?.lightest,
        color: theme?.palette.lighterBigBlue?.medium
      },
      warning: {
        backgroundColor: theme?.palette.warning?.lightest,
        color: theme?.palette.warning?.dark
      },
      danger: {
        backgroundColor: theme?.palette.negative?.lightest,
        color: theme?.palette.negative?.dark
      }
    }
  };

  return {
    display: "flex",
    gap: theme?.spacing(2),
    padding: theme?.spacing(4),
    borderRadius: 8,
    ...notificationStyles.variant[variant]
  }
})

const Title = styled('h3')(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 14
}))

const Description = styled('span')(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100],
  whiteSpace: 'pre-wrap'
}))

export {
  Notification,
  Title,
  Description
};