import { styled } from "@mui/material/styles"

const Title = styled('h2')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

const Subtitle = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}));

const Observation = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100],
  "&:before":{
    content: '"*"',
    color: theme.palette.negative?.pure
  },
}));

export {
  Title,
  Subtitle,
  Observation
};