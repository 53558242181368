import { GeneralComponents, TextField, Button, ButtonGroup } from '@components';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { BigBrother, FileTransferCenter } from '@services';
import { reducers, slices } from '@store';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { showToastCustomEditUserSucessMessage, showToastCustomErrorMessage, handleAxiosError } from '@utils';

const conditions = [
    {
        label: "Perfil de acesso",
        name: "isSystemAdmin",
        options: [
            {
                label: "Administrador",
                value: true,
            },
            {
                label: "Colaborador",
                value: false,
                disabled: true,
                tooltip: "Não é possível alterar este parâmetro. Apenas um outro administrador poderá realizar essa ação.",
            },
        ]
    },
    {
        label: "Autenticação em dois fatores",
        name: "parameters.TwoFactorAuthentication",
        options: [
            {
                label: "Ativa",
                value: true
            },
            {
                label: "Inativa",
                value: false,
                disabled: true,
                tooltip: "Não é possível alterar este parâmetro. Apenas um outro administrador poderá realizar essa ação.",
            },
        ]
    },
    // {
    //     label: "Transferência de arquivos",
    //     name: "parameters.FileTransfer",
    //     options: [
    //         {
    //             label: "Ativa",
    //             value: true
    //         },
    //         {
    //             label: "Inativa",
    //             value: false
    //         },
    //     ]
    // },
    {
        label: "Status do usuário",
        name: "isEnabled",
        options: [
            {
                label: "Ativo",
                value: true
            },
            {
                label: "Inativo",
                value: false,
                disabled: true,
                tooltip: "Não é possível alterar este parâmetro. Apenas um outro administrador poderá realizar essa ação.",
            },
        ]
    },
]

const EditUser: React.FC = () => {

    const mobile = useMediaQuery('(max-width: 500px)')

    const {
        sessionId,
        parameters: {
            TrocarSenha,
            ShowWelcomeBigCenter,
            ...relevantParameters
        },
        ...userInfo
    } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)

    const formProps = useForm({
        defaultValues: {
            ...userInfo,
            parameters: {
                TwoFactorAuthentication: relevantParameters.TwoFactorAuthentication === "True",
                // FileTransfer: relevantParameters.FileTransfer === "True"
            }
        }
    })

    const isDirty = Object.values(formProps.formState.dirtyFields).some((field) => !!field)

    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState<boolean>(false)
    const [unsavedDialogConfirmHandlers, setUnsavedDialogConfirmHandlers] = useState({ confirmHandler: () => { }, cancelHandler: () => { } });

    const outsideClickOccurred = useSelector((state: reducers.RootState) => state.userProfileReducer.outsideClickOccurred)

    const dispatch = useDispatch()

    const updateUser = async () => {
        const formData = formProps.watch()

        return await BigBrother.updateUser(
            {
                ...formData,
                '2fa': formData.parameters.TwoFactorAuthentication,
                // fileTransfer: formData.parameters.FileTransfer
            })
    }

    const refreshUserInfo = async () => {
        try {
            const { data: response } = await BigBrother.getUserInfo({
                login: userInfo.login
            })

            const {
                name,
                email,
                isSystemAdmin,
                isEnabled,
                parameters:{
                    TwoFactorAuthentication,
                    // FileTransfer
                }
            } = response?.userInfo
            
            dispatch(slices.user.setLoginUserInfo({
                ...userInfo,
                sessionId,
                name,
                email,
                isSystemAdmin,
                isEnabled,
                parameters: {
                    TwoFactorAuthentication,
                    // FileTransfer,
                    TrocarSenha,
                    ShowWelcomeBigCenter
                },
            }))

        } catch (err: any) {
            handleAxiosError(err)
        }

    }

    // const createFileTransferTemplate = async () => await FileTransferCenter.generateFolderTemplate({
    //     login: userInfo.login
    // })

    // const tryCreateFileTransferTemplate = async () => {
    //     try {
    //         await createFileTransferTemplate()
    //     } catch (err: any) {
    //         handleAxiosError(err)
    //     }
    // }

    const tryToUpdateUser = async () => {
        try {
            const { data: response } = await updateUser()
            handleResponse(response)
        } catch (err: any) {
            handleAxiosError(err)

        }

        dispatch(slices.sideSection.setOpen(false))
        dispatch(slices.userProfile.setOutsideClickOccurred(false))
    }

    const handleResponse = (response: any) => {
        if (response.message === 'Fail' || response.result === false) {
            showToastCustomErrorMessage('Erro ao atualizar o usuário', 'Não foi possível atualizar o usuário.')
            return
        }

        showToastCustomEditUserSucessMessage(formProps.watch().name)
        refreshUserInfo()

        // if (!!formProps.watch().parameters.FileTransfer) {
        //     tryCreateFileTransferTemplate()
        // }
    }

    const unsavedChangesDialogResponse = async () => {
        return new Promise((resolve) => {
            setShowUnsavedChangesDialog(true);

            const handleConfirm = async () => {
                resolve(true);
                await tryToUpdateUser()
                setShowUnsavedChangesDialog(false);
            };

            const handleCancel = () => {
                resolve(false);
                setShowUnsavedChangesDialog(false);
            };

            setUnsavedDialogConfirmHandlers({
                confirmHandler: handleConfirm,
                cancelHandler: handleCancel
            });

        });
    }

    const handleCancelButtonClick = async () => {

        if (!!isDirty) {
            await unsavedChangesDialogResponse()
            formProps.reset()
        }

        dispatch(slices.sideSection.setOpen(false))
        dispatch(slices.userProfile.setOutsideClickOccurred(false))
    }

    useEffect(() => {

        if (!!formProps.watch()?.isSystemAdmin && !!!formProps.watch()?.parameters.TwoFactorAuthentication) {
            formProps.setValue("parameters.TwoFactorAuthentication", true, {
                shouldDirty: true
            })
        }

        if (!!outsideClickOccurred) {
            handleCancelButtonClick()
        }

    }, [
        outsideClickOccurred,
        formProps.watch()?.isSystemAdmin,
        formProps.watch()?.parameters.TwoFactorAuthentication
    ])

    const { confirmHandler, cancelHandler } = unsavedDialogConfirmHandlers

    return <Box
        display={"flex"}
        flexDirection={"column"}
        gap={4}
        width={"100%"}
    >
        <Box>
            <Typography variant='h3'>Editar usuário</Typography>
            <Typography variant='subtitle1'>Altere seus dados e parâmetros</Typography>
        </Box>
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'flex-start'}
            width={'100%'}
            gap={4}
        >
            <FormProvider {...formProps}>
                <TextField
                    fullWidth
                    label={'E-mail'}
                    name='email'
                />
                <TextField
                    fullWidth
                    label={'Nome'}
                    name='name'
                />
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    width={'100%'}
                    gap={4}
                >
                    <Typography variant='h3'>Condições</Typography>
                    <Box
                        display="grid"
                        gridTemplateRows={!!!mobile ? 'repeat(2, 1fr)' : 'repeat(auto-fill, 1fr)'}
                        gridTemplateColumns={!!!mobile ? 'repeat(2, 1fr)' : 'repeat(auto-fill, 1fr)'}
                        flexWrap={'wrap'}
                        width="100%"
                        gap={4}
                    >
                        {
                            conditions.map(({ label, name, options }) => (<Box>
                                <ButtonGroup
                                    label={label}
                                    name={name}
                                    options={options}
                                    key={name}
                                />
                            </Box>
                            ))
                        }
                    </Box>
                </Box >
            </FormProvider>
            <Box
                display={'flex'}
                alignSelf={'flex-end'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                gap={6}
            >
                <Button
                    color='secondary'
                    size='small'
                    onClick={handleCancelButtonClick}
                >
                    Cancelar
                </Button>
                <Button
                    size='small'
                    onClick={tryToUpdateUser}
                >
                    Salvar
                </Button>
            </Box>
        </Box>
        <GeneralComponents.ConfirmationDialog
            open={showUnsavedChangesDialog}
            onConfirm={confirmHandler}
            onLeave={cancelHandler}
        />
    </Box>
}

export default EditUser;