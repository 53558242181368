import { GeneralComponents } from '@components';
import { slices } from '@store';
import React from 'react';
import { BiDetail } from 'react-icons/bi';
import { useDispatch } from 'react-redux';

const InstallmentsDetailsCell = ({ params }: any) => {
    const dispatch = useDispatch()

    const handleInstallmentDetailsButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.installmentDetails.setParams(params.row))
        dispatch(slices.installmentDetails.setShowInstallmentDetailsSection(true))
    }

    return <GeneralComponents.StyledGridActionsCellItem
        icon={<BiDetail color="#0068ff" size={24} />}
        label="Detalhes do boleto"
        onClick={handleInstallmentDetailsButtonClick}
    />;
}

export default InstallmentsDetailsCell;