import { Card, RadioGroup } from '@components';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { facematch } from '../../../../../../constants/options';




const Features: React.FC = () => {

    const { watch } = useFormContext();

    return <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
        gap={8}
    >
        <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant='h2'>Escolha a forma de verificação</Typography>
            <Typography variant='subtitle1'>
                Escolha <Typography variant='subtitle1' component={'span'} color={'#0068ff'} fontWeight={700}>um ou ambos os recursos</Typography> abaixo para iniciar sua verificação.
            </Typography>
        </Box>
        <RadioGroup.Container name='features'>
            <Box
                display="grid"
                gridAutoRows="1fr"
                gridTemplateColumns={`repeat(2, 2fr)`}
                width="100%"
                gap={4}
                id="features-options"
            >
                {
                    facematch?.features?.map((type) => {
                        return <RadioGroup.Item key={type.value} value={type.value}>
                            <Card
                                key={type.value}
                                title={type.title}
                                description={type.description}
                                IconContent={type.icon}
                                checked={watch('features') === type.value}
                                helpButton={true}
                                helpContent={'Saiba mais'}
                            />
                        </RadioGroup.Item>
                    })
                }
            </Box>
        </RadioGroup.Container>
    </Box>
}

export default Features;