import React from 'react';
import HeaderUserInfo from './HeaderUserInfo';
import './header.css';
import { isDesktopWidth } from '@constants'

export default function Header() {

    return (
        <header className='header-container'>
            {!isDesktopWidth ? <div className='header-logo-container'>
                <img src="https://bdcenter-files.bigdatacorp.com.br/Imagens/BDCCENTER_VF_NEG_OP2.svg" className='header-logo-image' alt="BDC Center logo. It contains BigDataCorp simbol."></img>
            </div>
            :
                <img src="https://bdcenter-files.bigdatacorp.com.br/Imagens/BDCCENTER_VF_NEG_OP2.svg" className='header-logo-image' alt="BDC Center logo. It contains BigDataCorp simbol."></img>
            }
            <HeaderUserInfo />
        </header>
    );
}