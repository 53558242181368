import React from 'react';

import { Box } from "@mui/material";
import { IButtonProps } from "@components";
import { Button } from "./styles";

interface FabProps extends IButtonProps {
  children: React.ReactElement,
  extended?: boolean
}

const Fab: React.FC<FabProps> = ({ children, extended = false, ...props }) => {
  return (
    <Box position="fixed" bottom={65} right={10}>
      <Button
        extended={extended}
        {...props}
      >
        {children}
      </Button>
    </Box>
  );
}

export default Fab;