import React from "react";

import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Button, Paper } from "@components";
import { Modal, Title, StepContent, StepTitle, StepDescription, StepImage, IconContent, Divider, MenuItem, Image } from "./styles";

import { BiX } from "react-icons/bi";
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { FaRegTimesCircle, FaRegCheckCircle } from "react-icons/fa";

import SwipeableViews from 'react-swipeable-views';
import AutoPlay from "./AutoPlay";

import Guide from "../../../guide.json";

type ComponentProps = {
  open: boolean,
  onClose?: () => void
}

const Component: React.FC<ComponentProps> = ({ open, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {

    if (activeStep < Guide.length - 1)
      setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
  };

  const handleBack = () => {

    if (activeStep >= 1)
      setActiveStep((prevActiveStep: any) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box width={{ xs: "90%", md: "75%" }}>
        <Paper>
          <Box display="flex" flexDirection="column" gap={6} p={8}>
            <Box>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Title>Guia de captura de imagens</Title>
                {
                  !!onClose && (
                    <Button
                      size="small"
                      color="secondary"
                      StartIcon={<BiX size={28} />}
                      onClick={onClose}
                    />
                  )
                }
              </Box>
              <Divider />
            </Box>
            <Box display="flex" flexDirection={{ xs: "column", md: "row" }} gap={6}>
              <Box display="flex" justifyContent="space-between" width={{ xs: "100%", md: "25%" }}>
                <Box
                  display={{ xs: "none", md: "flex" }}
                  flexDirection={{ xs: "row", md: "column" }}
                  gap={2}
                  width="100%"
                  overflow="auto"
                  whiteSpace="nowrap"
                  pb={{ xs: 2, md: 0 }}
                >
                  {
                    Guide.map((step, index) => (
                      <MenuItem
                        key={index}
                        active={index === activeStep}
                        onClick={() => handleStepChange(index)}>
                        {step.section}
                      </MenuItem>
                    ))
                  }
                </Box>
                <Divider direction="vertical" />
              </Box>
              <Box width={{ xs: "100%", md: "75%" }}>
                <SwipeableViews index={activeStep}>
                  {
                    Guide.map((step, index) => (
                      isMobile ? (
                        <StepContent key={index}>
                          <AutoPlay>
                            <StepImage>
                              <Image
                                src={step.wrongImage}
                              />
                              <IconContent>
                                <FaRegTimesCircle
                                  color={theme.palette.secondary.red}
                                  size={24}
                                />
                              </IconContent>
                            </StepImage>
                            <StepImage>
                              <Image
                                src={step.rightImage}
                              />
                              <IconContent>
                                <FaRegCheckCircle
                                  color={theme.palette.success.pure}
                                  size={24}
                                />
                              </IconContent>
                            </StepImage>
                          </AutoPlay>
                          <StepTitle>{step?.title}</StepTitle>
                          <StepDescription>{step?.description}</StepDescription>
                        </StepContent>
                      ) : (
                        <StepContent key={index}>
                          <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} gap={2}>
                            <StepImage>
                              <Image
                                src={step.wrongImage}
                              />
                              <IconContent>
                                <FaRegTimesCircle
                                  color={theme.palette.secondary.red}
                                  size={24}
                                />
                              </IconContent>
                            </StepImage>
                            <StepImage>
                              <Image
                                src={step.rightImage}
                              />
                              <IconContent>
                                <FaRegCheckCircle
                                  color={theme.palette.success.pure}
                                  size={24}
                                />
                              </IconContent>
                            </StepImage>
                          </Box>
                          <StepTitle>{step?.title}</StepTitle>
                          <StepDescription>{step?.description}</StepDescription>
                        </StepContent>
                      )
                    ))
                  }
                </SwipeableViews>
                <Box display={{ xs: "flex", md: "none" }} alignItems="center" justifyContent="space-between" mt={0}>
                  <Button
                    size="small"
                    StartIcon={<BiLeftArrowAlt size={20} />}
                    onClick={handleBack}
                  />
                  <Typography
                    variant="body2"
                    color="lighterBigBlue.pure"
                    fontWeight={700}
                  >
                    {activeStep + 1}/{Guide.length}
                  </Typography>
                  <Button
                    size="small"
                    StartIcon={<BiRightArrowAlt size={20} />}
                    onClick={handleNext}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </Modal>
  )
}

export default Component;