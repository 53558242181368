import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface UsageSummaryPaginationInfo {
    Page: number,
    Records: number,
    TotalPages: number,
    TotalRecords: number
}


const initialState = {
    Page: 1,
    Records: 12,
    TotalPages: 0,
    TotalRecords: 0
}

const usageSummaryPaginationSlice = createSlice({
  name: 'usageSummaryPagination',
  initialState: initialState,
  reducers: {
    setUsageSummaryPaginationInfo: (state, action: PayloadAction<UsageSummaryPaginationInfo>) => {
        return {...state, ...action.payload}
    }
  },
});

export const { setUsageSummaryPaginationInfo } = usageSummaryPaginationSlice.actions;

export default usageSummaryPaginationSlice.reducer;