import React from "react";

import { Typography, Box, Tooltip } from "@mui/material";
import { DatePicker } from "./styles";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import dayjs from "dayjs";
import 'dayjs/locale/pt-br';

import { Controller, useFormContext } from "react-hook-form";
import { BiHelpCircle } from "react-icons/bi";

interface ComponentProps {
  label?: string;
  name: string;
  format?: string;
  required?: boolean,
  description?: string
}

const Component: React.FC<ComponentProps> = ({ label, name, format = "DD/MM/YYYY", required, description }) => {
  const { control, getValues, setValue } = useFormContext();

  const currentValue = getValues(name);
  const value = currentValue ? dayjs(currentValue, format) : null;

  const handleChange = (value: any) => {
    if (!!!value) return;

    const formatValue = value.format(format);
    setValue(name, formatValue)
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box display="flex" flexDirection="column" gap={2}>
          {
            !!label?.length && (
              <Box display="flex" alignItems="center" justifyContent="space-between" color="secondary.blue">
                <Box>
                  <Typography variant="body1" color="secondary.blue" fontWeight={700}>{label}</Typography>
                  {required && (
                    <Typography color="negative.pure">
                      *
                    </Typography>
                  )
                  }
                </Box>
                <Box>
                  {
                    description && (
                      <Tooltip title={description}>
                        <Box>
                          <BiHelpCircle size={20} />
                        </Box>
                      </Tooltip>
                    )
                  }
                </Box>
              </Box>
            )
          }
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
            <DatePicker
              {...field}
              format={format}
              value={value}
              onChange={handleChange}
            />
          </LocalizationProvider>
        </Box>
      )}
    />
  )
}

export default Component;