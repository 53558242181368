import React from 'react';
import { MdOutlineClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import NotificationCard from './NotificationCard';
import CustomDefaultTextButton from '../GeneralComponents/CustomDefaultTextButton';

import { reducers, slices } from "@store";

import './notificationCenter.css'
import { useNotificationCenterContext } from '../NotificationCenterProvider';

const NotificationCenter: React.FC = () => {

    const showNotificationCenter = useSelector((state: reducers.RootState) => state.notificationCenterReducer.showNotificationCenter)
    const {markAllAsRead} = useNotificationCenterContext()
    const dispatch = useDispatch()
    const closeNotificationCenter = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.notificationCenter.setShowNotificationCenter(false))
    }

    return <section className={showNotificationCenter ? 'notification-center-container' : 'notification-center-container-closed'}>
        <div className='notification-center-title-and-options'>
            <CustomDefaultTextButton onClick={closeNotificationCenter} startIcon={<MdOutlineClose size={24} />} variant='text'>Fechar</CustomDefaultTextButton>
            <div className='notification-title-container'>
                <h5 className='notification-title'>Notificações</h5>
                <CustomDefaultTextButton variant='text' onClick={markAllAsRead}>Marcar todas como lidas</CustomDefaultTextButton>
            </div>
        </div>
        <div className='notification-cards-container'>
            <NotificationCard />
        </div>
    </section>;
}

export default NotificationCenter;