import { DashboardComponents } from '@components';

import './news.css'
import BannerNews from './BannerNews';
import { Typography } from '@mui/material';

const NewsCardContent = [
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news1.png',
    url: 'https://bigdatacorp.com.br/aplicativos/bigmarket/',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news2.png',
    url: 'https://bigdatacorp.com.br/aplicativos/bigid/',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news3.png',
    url: 'https://bigdatacorp.com.br/aplicativos/biglead/',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news4.png',
    url: 'https://www.solucoes.bigdatacorp.com.br/sandbox'
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news6.png',
    url: 'https://www.youtube.com/bigdatacorp',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news7.png',
    url: 'https://blog.bigdatacorp.com.br/',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news10.png',
    url: 'https://bigdatacorp.com.br/professional-services/',
  },
  {
    src: 'https://bdcenter-files.bigdatacorp.com.br/Novidades/news13.png',
    url: 'https://bigdatacorp.com.br/perfil-do-ecommerce-10-edicao/',
  }
]


const News = () => {
  return (
    <section className='news-container'>
      <div className='news-presentation-container'>
        <Typography variant='h2' component={'h2'}>Novidades</Typography>
      </div>
      <DashboardComponents.NewsSliderContainer>
        {NewsCardContent.map((card, index) => <BannerNews key={index} url={card.url} src={card.src} />)}
      </DashboardComponents.NewsSliderContainer>
    </section>
  );
}

export default News;
