import { Box, InputAdornment, TextFieldProps, Tooltip } from "@mui/material";
import { Label, RequiredSpan, TextField } from "./styles";
import { ComponentProps, ReactElement } from "react";
import { useFormContext } from "react-hook-form";
import { IMaskMixin } from "react-imask";
import { BiHelpCircle } from "react-icons/bi";

type InternalMaskTextFieldProps = {
  name: string,
  label?: string,
  required?: boolean,
  description?: string,
  startIcon?: ReactElement,
  endIcon?: ReactElement,
  fullWidth?: boolean
}

const InternalMaskTextField = IMaskMixin(({ name, startIcon, endIcon, label, required, description, fullWidth, ...props }: any) => {
  const { register } = useFormContext();

  return (
    <Box display="flex" flexDirection="column" gap={2} width={fullWidth ? "100%" : "auto"}>
      {
        !!label?.length && (
          <Label>
            <Box>
              {label}
              {required && (
                <RequiredSpan>
                  *
                </RequiredSpan>
              )
              }
            </Box>
            <Box>
              {
                description && (
                  <Tooltip title={description}>
                    <Box>
                      <BiHelpCircle size={20} />
                    </Box>
                  </Tooltip>
                )
              }
            </Box>
          </Label>
        )
      }
      <TextField
        InputProps={{
          startAdornment: startIcon && (
            <InputAdornment position="start">
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: endIcon && (
            <InputAdornment position="start">
              {endIcon}
            </InputAdornment>
          )
        }}
        {...register(name)}
        {...props as any}
      />
    </Box>
  )
})

type MaskProps = ComponentProps<typeof InternalMaskTextField>

const MaskTextField = (props: TextFieldProps & MaskProps & InternalMaskTextFieldProps) => {
  return <InternalMaskTextField {...props} />
}

export default MaskTextField;