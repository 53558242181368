interface MasksProps {
  [key: string]: string
}

const Masks: MasksProps = {
  CPF: "000.000.000-00",
  CNPJ: "00.000.000/0000-00",
  CEP: "00000-000",
  BIRTHDATE: "00/00/0000",
  REFERENCEDATE: "00/00/0000",
  ENDDATE: "00/00/0000",
  STARTDATE: "00/00/0000",
  RGEXPEDITIONDATE: "00/00/0000",
  "YYYY-MM-DD": "00/00/0000"
}

export default Masks;