import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserManagementFilter {
    login: string;
    name: string;
    email: string;
    isSystemAdmin: string;
    isEnabled: string;
}
  
const initialState: UserManagementFilter = {
    login: '',
    name: '',
    email: '',
    isSystemAdmin: '',
    isEnabled: ''
}

const userManagementFilterSlice = createSlice({
    name: 'userManagementFilter',
    initialState,
    reducers: {
      setFilterInfo: (state, action: PayloadAction<UserManagementFilter>) => {
        return {...state, ...action.payload}
      },
      resetFilterInfo: (state) =>{
        Object.assign(state, initialState)
      }
    }
});

export const { setFilterInfo, resetFilterInfo } = userManagementFilterSlice.actions;

export default userManagementFilterSlice.reducer;