import { styled } from '@mui/system';
import { Button } from '@mui/material';

const DefaultWhiteButton = styled(Button)`
    display: flex;
    height: 40px;
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'Be vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
    color: #32343C;
    transition: all .5s ease;
    :hover{
        background: #006;
        color: white;
        transition: all .5s ease;
        box-shadow: none;
    }
`;

export default DefaultWhiteButton;