import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GridActionsCellItem } from '@mui/x-data-grid/components/cell/GridActionsCellItem';
import { BigBrother } from '@services';
import { BigBrotherTypes } from '@types';
import { handleAxiosError, isFrom, showToastCustomErrorMessage } from '@utils';

import { reducers, slices } from "@store";

import { FaUserCircle } from 'react-icons/fa'
import { MdEdit } from 'react-icons/md'
import { styled } from '@mui/system';

import './actionsCell.css'
import { isDesktopWidth } from '../../../constants/screenWidth';



const StyledGridActionsCellItem = styled(GridActionsCellItem)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .5s ease;


  :hover{
    background-color: white;
    transition: background-color .5s ease;
  }
`;

const ActionsCell = ({ params }: any) => {

  const dispatch = useDispatch();

  const getUserData = async () => await BigBrother.getUserInfo(
    {
      login: params.row.login,
      isFrom: isFrom()
    })
  const checkIfisInvalidSession = (getUserData: BigBrotherTypes.GetUserApiResponse) => getUserData.message === "Sessão inválida."

  const handleBigBrotherApiResponse = (getUserData: BigBrotherTypes.GetUserApiResponse) => {
    if (checkIfisInvalidSession(getUserData)) {
      showToastCustomErrorMessage('Sessão expirada.', 'Sua sessão expirou. Por favor, faça login novamente.', 'session-timeout')
      dispatch(slices.user.setUserInitialState())
    } else {
      return getUserData.userInfo
    }
  }

  const tryGetUserData = async () => {
    try {
      const { data: apiBigBrotherResponse } = await getUserData()
      return handleBigBrotherApiResponse(apiBigBrotherResponse)
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const handleEditButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    slices.editUser.setOutsideClickOcurred(false)
    const userToBeEditedInfo = await tryGetUserData()
    if (userToBeEditedInfo) {
      dispatch(slices.editUser.setEditUserInfo(
        {
          ...userToBeEditedInfo,
          fileTransfer: userToBeEditedInfo.parameters.FileTransfer === "True",
          "2fa": userToBeEditedInfo.parameters.TwoFactorAuthentication === "True",
          showEditUserSection: true,
          wasUserEditionConfirmed: false
        }));
    }
  };

  const handleViewButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const viewUserInfo = await tryGetUserData()
    if (viewUserInfo) {
      dispatch(slices.viewUser.setViewUserInfo(
        { ...viewUserInfo,
          fileTransfer: viewUserInfo.parameters.FileTransfer === "True",
          "2fa": viewUserInfo.parameters.TwoFactorAuthentication === "True",
          showViewUserSection: true 
        }));
    }
  };

  return (
    <div className='actions-cell-container'>
      <StyledGridActionsCellItem
        className='view-user-cell'
        icon={<FaUserCircle color="#0068ff" size={24} />}
        label="Perfil"
        onClick={handleViewButtonClick}
      />
      {isDesktopWidth ?
        <StyledGridActionsCellItem
          icon={<MdEdit color="#0068ff" size={24} />}
          label="Editar"
          onClick={handleEditButtonClick}
        />
        :
        <></>
      }

    </div>
  );
};

export default ActionsCell;