import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface DeleteFileInfo {
    wasConfirmed: boolean
    lastDelete: string,
}

const initialState: DeleteFileInfo = {
    wasConfirmed: false,
    lastDelete: ''
}

const deleteFileInfo = createSlice({
    name: 'deleteFile',
    initialState,
    reducers: {
        setDeleteFileInfo: (state, action: PayloadAction<DeleteFileInfo>) => {
            return { ...state, ...action.payload }
        }
    }
});

export const { setDeleteFileInfo } = deleteFileInfo.actions;

export default deleteFileInfo.reducer;