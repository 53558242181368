import React from 'react';

import './statusTag.css'

const StatusTag = ({ value }: any) => {

    const getStatusValue = () => {
        switch (value) {
            case 'Em andamento':
                return <span className='neutral-tag-status'>Em andamento</span>
            case 'Cancelado':
                return <span className='negative-tag-status'>Cancelado</span>
            case 'Concluído':
                return <span className='positive-tag-status'>Concluído</span>
            case 'PAID':
                return <span className='positive-tag-status'>Pago</span>
            case 'CANCELLED':
                return <span className='neutral-tag-status'>Cancelado</span>
            case 'PENDING':
                return <span className='warning-tag-status'>Pendente</span>
            case 'OVERDUE':
                return <span className='negative-tag-status'>Atrasado</span>
            case true:
                return <span className='positive-tag-status'>Ativo</span>
            case false:
                return <span className='negative-tag-status'>Inativo</span>
            case 'True':
                return <span className='positive-tag-status'>Ativa</span>
            case 'False':
                return <span className='negative-tag-status'>Inativa</span>
            default:
                return <span className='neutral-tag-status'>{value}</span>
        }
    }

    return getStatusValue()
}

export {StatusTag};