import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"
import { isDesktopWidth } from "@constants";

const Container = styled(Box)(({ theme, open } : { theme?: any, open: boolean }) => ({
  display: "flex",
  position: "fixed",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: `${theme.spacing(6)} ${theme.spacing(7.5)}`,
  width: isDesktopWidth ? "530px" : '100%',
  right: "0px",
  top: '0',
  borderLeft: "1px solid #B1B9BD",
  backgroundColor: "white",
  transform: `translateX(${open ? "0" : "100"}%)`,
  transition: "transform .5s ease",
  zIndex: "1001",
  overflowY: "auto",
  height: !!!isDesktopWidth ? "calc(100vh - 54px)" : "100vh",
}));


export {
  Container
};