import { IconType } from "react-icons"
import { BsBuildingsFill, BsCarFrontFill, BsFillBagFill, BsPeopleFill, BsPinMapFill } from "react-icons/bs"
import { MdGavel } from "react-icons/md"
import { TbWorldWww } from "react-icons/tb"
import { FaHandshake } from "react-icons/fa"

interface Api {
  value: string,
  title: string,
  description: string,
  englishName: string,
  icon: IconType
}

interface Condition {
  value: string,
  title: string,
  description: string
}

const apis: Array<Api> = [
  {
    value: "pessoas",
    title: "Pessoas",
    englishName: "people",
    description: "Na API de Pessoas, você encontra dados relacionados com indivíduos, que podem ser acessados através de diferentes chaves de identificação. Dentro dela você pode consultar informações cadastrais básicas, contatos, exposição pública, processos e muito mais.",
    icon: BsPeopleFill
  },
  {
    value: "empresas",
    title: "Empresas",
    englishName: "companies",
    description: "Na API de Empresas, você encontra dados relacionados com empresas públicas e privadas de todos os tipos e setores. Você pode consultar informações cadastrais, indicadores de operação, processos judiciais, dados sobre a reputação da empresa, e muito mais.",
    icon: BsBuildingsFill
  },
  {
    value: "veiculos",
    title: "Veículos",
    englishName: "vehicles",
    description: "A API de Veículos permite que você consulte informações de diferentes tipos de veículos (carros, motos, caminhões) através da placa ou de outros dados identificadores. Essa API traz informações de categoria, marca e modelo do veículo, restrições relacionadas com o mesmo, e muito mais.",
    icon: BsCarFrontFill
  },
  {
    value: "enderecos",
    title: "Endereços",
    englishName: "addresses",
    description: "A API de Endereços traz dados relacionados à lugares e localidades, podendo ser consultada a partir de coordenadas geográficas ou de endereços escritos por extenso, sejam eles parciais ou completos. Ela traz informações como estatísticas de empresas e população por região, dados ambientais, e pode ainda ser utilizada para o geocoding ou geocoding reverso. A maioria dos datasets também suportam que seja informado um raio de pesquisa no entorno do ponto ou do endereço consultado, ampliando as possibilidades de uso.",
    icon: BsPinMapFill
  },
  {
    value: "processos",
    title: "Processos Judiciais",
    englishName: "lawsuits",
    description: "A API de Processos Judiciais permite a consulta de informações relacionadas com processos judiciais, e alguns processos administrativos, usando como chave de consulta o número do processo. São retornados dados básicos e metadados extraídos dos processos capturados.",
    icon: MdGavel
  },
  {
    value: "produtos",
    title: "Produtos",
    englishName: "products",
    description: "A API de Produtos reúne informações relevantes sobre os mais diferentes tipos de produtos e serviços que são ofertados por empresas na internet, seja em sites de comércio eletrônico, em marketplaces, ou mesmo em aplicativos. São apresentados dados relacionados com preços, avaliações, características técnicas e muitos outros, e os produtos podem ser consultados por EAN, nome e marca, e outras chaves de identificação, completas ou parciais.",
    icon: BsFillBagFill
  },
  {
    value: "ondemand",
    title: "Ondemand",
    englishName: "ondemand",
    description: "A API de Consultas On-Demand reúne todas as consultas que acessam fontes de dados externas <b>no momento em que a chamada é realizada</b>, em contraponto às outras APIs que acessam dados já capturados pela BigDataCorp. Por realizarem um acesso externo, as consultas desta API tem sua disponibilidade e tempo de resposta diretamente atrelados à fonte sendo acessada, e não temos como oferecer qualquer tipo de garantia para essas chamadas.",
    icon: TbWorldWww
  },
  {
    value: "marketplace",
    title: "Marketplace",
    englishName: "marketplace",
    description: "A API do Marketplace reúne todos os datasets elaborados em conjunto com os nossos diferentes parceiros. Dentro do Marketplace você encontra modelos de crédito e de fraude, dados relacionados com segurança pública, informações alternativas de renda, e muito mais. Atenção: não disponibilizamos 500 consultas gratuitas por mês para os datasets de parceiros, e o mecanismo de consulta com data de referência pode não estar disponível para essas chamadas.",
    icon: FaHandshake
  },
]

const conditions: Array<Condition> = [
  {
    value: "default",
    title: "Consulta padrão",
    description: "Obtenha um resultado mais assertivo utilizando a chave padrão de acordo com a API selecionada."
  },
  {
    value: "alternative_keys",
    title: "Chaves alternativas",
    description: "Realize uma consulta com outros tipos de chaves de acordo com a API selecionada."
  }
]

const datasets: any = [
  {
    value: "basic_data",
    label: "Dados básicos",
    childrenOptions: [
      {
        value: "name",
        label: "Nome"
      },
      {
        value: "birth_data",
        label: "Data de nascimento"
      }
    ]
  },
  {
    value: "addresses",
    label: "Endereços"
  },
  {
    value: "phones",
    label: "Telefones"
  },
  {
    value: "emails",
    label: "E-mails"
  },
  {
    value: "economic_relationship",
    label: "Relacionamentos Econômicos"
  },
  {
    value: "people_related",
    label: "Pessoas Relacionadas"
  },
  {
    value: "people_phones_related",
    label: "Telefone de Pessoas Relacionadas"
  },
]

export {
  apis,
  conditions,
  datasets
}