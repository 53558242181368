import { IconButton, styled } from "@mui/material";

const TooltipIconButton = styled(IconButton)((theme) => {
    return `
        padding: 0;
        display: flex;
        align-items: center;
    `
})

export {TooltipIconButton}