import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Feature {
  name: string;
  description: string;
  link: string;
  icon: IconProp;
}

interface lastUsedState {
  features: Feature[];
  user: string;
}

const initialState: lastUsedState = {
  features: [],
  user: ''
};

const lastUsedSlice = createSlice({
  name: 'lastUsed',
  initialState,
  reducers: {
    addFeature: (state, action: PayloadAction<Feature>) => {
      if(state.features.find((feature => feature.name === action.payload.name))){
        return
      }
      else {
        state.features.unshift(action.payload);
      }
      
    },
    clearFeatures: (state) => {
      state.features = []
    },
    setLastUsedFeaturesOwner: (state, action: PayloadAction<string>) => {
      state.user = action.payload
    }
  },
});

export const { addFeature, clearFeatures, setLastUsedFeaturesOwner } = lastUsedSlice.actions;

export default lastUsedSlice.reducer;