import { Box } from '@mui/material';
import { Notification } from '@components';
import React from 'react';
import { CheckboxContainer, CheckboxLabel, DialogCheckbox } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';

interface DialogContentProps {
    toggleCheckbox: any
}

const DialogContent: React.FC<DialogContentProps> = ({toggleCheckbox}) => {
    
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target
        toggleCheckbox(checked)
    }

    return <>

        <Box
            display='flex'
            flexDirection='column'
            gap='24px'
        >
            <Notification
                variant='warning'
                title='Esta consulta pode gerar custos'
                description='Se você utilizar datasets pagos ou se sua empresa já excedeu o limite de consultas gratuitas, essa consulta será cobrada.'
            ></Notification>
            <CheckboxContainer>
                <CheckboxLabel
                    control={
                        <DialogCheckbox
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            onChange={handleCheckboxChange}
                        />
                    }
                    label='Não mostrar isso novamente' />
            </CheckboxContainer>

        </Box>
    </>;
}

export default DialogContent;