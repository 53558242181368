import React from 'react';
import { Box, FormControl, Tooltip, Typography } from '@mui/material';
import { Container, Option } from './styles';
import { Controller, useFormContext } from 'react-hook-form';
import getValue from "lodash/get";

interface ComponentProps {
    label?: string,
    name: string,
    options: any,
}

const Component: React.FC<ComponentProps> = (
    {
        label,
        options,
        name
    }) => {

    const {
        control,
        watch,
        formState: { errors }
    } = useFormContext();

    return <>
        <Controller
            name={name}
            control={control}
            render={({ field }) =>
            (
                <FormControl fullWidth>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                    >
                        <Typography
                            variant='body2'
                            fontWeight={700}
                        >
                            {label}
                        </Typography>
                        <Container
                            {...field}
                            onChange={() => { }}
                        >
                            {
                                options.map((item: any) =>
                                    !!item?.tooltip ?
                                        <Tooltip
                                            title={item?.tooltip}
                                            placement='top'
                                        >
                                            <Box
                                                width={'auto'}
                                            >
                                                <Option
                                                    disableElevation
                                                    disableRipple
                                                    disableFocusRipple
                                                    disableTouchRipple
                                                    onClick={() => field.onChange(item?.value)}
                                                    active={getValue(watch(), field.name) === item?.value}
                                                    disabled={item?.disabled}
                                                >
                                                    {item?.label}
                                                </Option>
                                            </Box>
                                        </Tooltip>
                                        :
                                        <Option
                                            disableElevation
                                            disableRipple
                                            disableFocusRipple
                                            disableTouchRipple
                                            onClick={() => field.onChange(item?.value)}
                                            active={getValue(watch(), field.name) === item?.value}
                                            disabled={item?.disabled}
                                        >
                                            {item?.label}
                                        </Option>
                                )}
                        </Container>
                    </Box >
                </FormControl>
            )
            }
        />

    </>;
}

export default Component;