import React from "react";

import { Box } from "@mui/material";
import { Paper, Chip, Onboarding } from "@components";
import { Card } from "./components";
import { Title, Key, Value } from "./styles";

import ExportList from "./components/ExportList";
import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { steps } from "@constants";

const Result: React.FC<any> = ({ data }: any) => {

  const { open } = useSelector((state: reducers.RootState) => state.exportPdf);
  const selectedDatasets: any = useSelector((state: reducers.RootState) => state.exportPdf).datasets;

  const containerRef = useRef(null);
  const dispatch = useDispatch();

  const handlePrint = useReactToPrint({
    content: () => containerRef.current as any,
    bodyClass: 'print',
    documentTitle: 'PontualConsult',
    onAfterPrint: () => { dispatch(slices.exportPdf.setIsPrinting(false)) }
  });

  return (
    <>
      <Box display="flex" flexDirection="column" gap={4} ref={containerRef}>
        <Paper elevation={1}>
          <Box p={6} id="documents-consult">
            <Paper variant="outlined">
              <Box display="flex" flexDirection="column" gap={6} p={6}>
                <Title>Documentos inseridos nessa consulta:</Title>
                <Box display="flex" alignItems="center" gap={4}>
                  {
                    data?.documents && Object.entries(data.documents || {}).map((item: any) => {
                      if (!!!item[1]) return;

                      return (
                        <Chip key={item[0]}>
                          <Key>{item[0]}:</Key>
                          <Value>{item[1]}</Value>
                        </Chip>
                      )
                    })
                  }
                </Box>
              </Box>
            </Paper>
          </Box>
          <Box display="flex" flexDirection="column" gap={6} p={6}>
            {
              Object.entries(data?.datasets || {}).map(([key, value]: any) => {
                return value?.selected && (
                  <Card
                    key={key}
                    api={data?.api}
                    dataset={key}
                    attributes={value}
                    params={data?.documents}
                    print={selectedDatasets?.[key]}
                    datasetName={value?.label}
                  />
                )
              })
            }
            <ExportList open={open} data={data?.datasets} handlePrint={handlePrint} />
          </Box>
        </Paper>
      </Box>
      <Onboarding
        steps={steps.PontualConsult.Result}
        controlPath='PontualConsult.Result'
      />
    </>
  )
}

export default Result;