import { Tooltip } from '@components';
import { Box, Typography } from '@mui/material';
import React from 'react';

import {
    Container,
    Header,
    TicketInfo,
    Body,
    ImagesWrapper,
    Image,
} from './styles';

interface ImageResultProps {
    ticketId: string,
    data: any,
    handleSetActiveTab?: any,
    activeTab?: any
}

const ImageInfo: React.FC<ImageResultProps> = ({
    ticketId,
    data,
    handleSetActiveTab,
    activeTab
}) => {


    const {
        type,
        files
    } = data

    const handleTabSelection = (event: React.MouseEvent) => {
        const index = parseInt(event.currentTarget.id)
        if (index !== undefined) {
            handleSetActiveTab(event as any, index)
        }
    }

    return <Container>
        <Header>
            <TicketInfo>
                <Typography
                    variant='body2'
                    color={'#006'}
                    fontWeight={700}
                >
                    Ticket ID:
                </Typography>
                <Typography variant='body2'>
                    {ticketId}
                </Typography>
            </TicketInfo>
            <Box>
                <Tooltip description='É o identificador da sua consulta no BigID' placement='top' />
            </Box>
        </Header>
        <Body>
            <ImagesWrapper>
                <Image selected={!!!activeTab}>
                    <Typography variant='body2' color={'#006'}><b>Primeira</b> imagem</Typography>
                    <img
                        id='0'
                        src={type === 'file' ? files?.[0]?.firstImage?.base64 : files?.[0]?.firstImage?.link}
                        onClick={handleTabSelection}
                        alt='Primeira imagem'
                    />
                </Image>
                <Image selected={!!activeTab}>
                    <Typography variant='body2' color={'#006'}><b>Segunda</b> imagem</Typography>
                    <img
                        id='1'
                        src={type === 'file' ? files?.[0]?.secondImage?.base64 : files?.[0]?.secondImage?.link}
                        onClick={handleTabSelection}
                        alt='Segunda imagem'
                    />
                </Image>
            </ImagesWrapper>
        </Body>
    </Container>;
}

export default ImageInfo;