// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px 0px;
    gap: 24px;
}

.loading-title{
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #555866;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.loading-paragraph{
    width: 243px;
    height: 40px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #555866;
    flex: none;
    order: 1;
    flex-grow: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/TokenManagement/CreateTokenSection/LoadingSection/loadingSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;AACb;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,cAAc;IACd,UAAU;IACV,QAAQ;IACR,YAAY;AAChB","sourcesContent":[".loading-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    padding: 32px 0px;\n    gap: 24px;\n}\n\n.loading-title{\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 21px;\n    line-height: 140%;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    color: #555866;\n    flex: none;\n    order: 0;\n    flex-grow: 0;\n}\n\n.loading-paragraph{\n    width: 243px;\n    height: 40px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 140%;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    color: #555866;\n    flex: none;\n    order: 1;\n    flex-grow: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
