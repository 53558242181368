import { useNavigate } from "react-router-dom";

import { Button } from "@components";
import { BiLeftArrowAlt } from 'react-icons/bi';

import { useDispatch, useSelector } from "react-redux";
import { slices, reducers } from "@store";

const ButtonGoBack = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { step, data } = useSelector((state: reducers.RootState) => state.formWizard);

  const handleClickListConsult = () => {
    if (step === 5 && data.typeConsult === "saved") {
      dispatch(slices.formWizard.goStep(1));
      return
    }

    if (step === 2 && data.typeConsult === "new") {
      dispatch(slices.formWizard.goStep(0));
      return
    }

    dispatch(slices.formWizard.goStep(step - 1));
  }

  if (step <= 0) return (<></>);

  return (
    <Button
      onClick={handleClickListConsult}
      color="secondary"
      size="small"
      StartIcon={<BiLeftArrowAlt size={28} />}
    />
  )
}

export default ButtonGoBack;