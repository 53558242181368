import { slices, store } from "@store"
import { showToastCustomErrorMessage } from "./ToastMessages"

const BillingError: any = (apiResponse: any) => {
    if (apiResponse?.data?.Message === "INVALID_SESSION_ID") {
        showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
        store.store.dispatch(slices.user.setUserInitialState())
        return
    }
}


const BigBrotherError: any = (apiResponse: any) => {
    const invalidSessionIdStatus = 3

    if (apiResponse?.data?.status === invalidSessionIdStatus) {
        showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
        store.store.dispatch(slices.user.setUserInitialState())
        return
    }
}

const PlatformError: any = (apiResponse: any) => {
    const invalidSessionIdStatus = -159;
    const invalidAccessToken = -111;

    if (
        apiResponse?.data?.Status?.Code === invalidSessionIdStatus ||
        apiResponse?.data?.Status?.login?.[0]?.Code === invalidSessionIdStatus ||
        apiResponse?.data?.Status?.Code === invalidAccessToken ||
        apiResponse?.data?.Status?.login?.[0]?.Code === invalidAccessToken) {
        showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
        store.store.dispatch(slices.user.setUserInitialState())
        return true;
    }
}

const BigIdError: any = (apiResponse: any) => {
    const invalidSessionIdStatus = 401;

    if (apiResponse?.status === invalidSessionIdStatus) {
        showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
        store.store.dispatch(slices.user.setUserInitialState())
        return
    }

}

const PluginError: any = (apiResponse: any) => {
    const invalidSessionIdStatus = 403;

    if (apiResponse?.status === invalidSessionIdStatus) {
        showToastCustomErrorMessage("Sessão inválida", "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
        store.store.dispatch(slices.user.setUserInitialState())
        return
    }

}

export { BillingError, BigBrotherError, PlatformError, BigIdError, PluginError }
