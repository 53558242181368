import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

const Field = styled(Box)(({ theme } : { theme?: any }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  width: '100%',
}));

export {
  Field
};