import React from 'react';
import './bannerNews.css'

interface BannerNewsProps {
    src: string;
    url: string;
}

const BannerNews: React.FC<BannerNewsProps> = ({src, url}: BannerNewsProps) => {
  return <a href={url} target='_blank' className='news-link-container'><img className='news-link-image' src={src}></img></a>;
}

export default BannerNews;