import { styled } from "@mui/material";
import { Link as LinkRouter} from "react-router-dom";

const Component = styled(LinkRouter)`
    
    text-decoration: none;
    outline: none;

    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
        outline: none;
    }
`

export default Component