import { Step } from "react-joyride";
import { isDesktopWidth } from '@constants';

const steps: Step[] = [
  {
    target: '.financial-management-main-container',
    title: 'Acompanhe o consumo histórico de seu domínio',
    content: 'Inicie o tour para aprender como acompanhar o consumo histórico seu domínio no BDC Center',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
  },
  {
    target: '.usage-summary-filter-section',
    title: 'Filtre o período desejado',
    content: 'Você pode utilizar os filtros para ver períodos específicos.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-start' },

  },
  {
    target: '.detailed-usage-summary-filter-button',
    title: 'Filtre o período desejado',
    content: 'Você pode utilizar os filtros para ver períodos específicos.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-start' },

  },
  {
    target: '#historical-usage-chart-container',
    title: 'Veja o consumo por produto',
    content: 'Utilize o gráfico para acompanhar como cada produto está sendo consumido no seu domínio. Interaja com o gráfico para ver mais detalhes em períodos específicos.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'top-start' : 'top-end' }
  },
  {
    target: '#historical-usage-table-container',
    title: 'Detalhes do consumo',
    content: 'Use esta tabela para verificar em detalhes o consumo histórico dos produtos no seu domínio.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'top-start' : 'top-end' }
  },
  {
    target: '.financial-management-main-container',
    title: 'Tour finalizado',
    content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'center' }
  },
];

export default steps;