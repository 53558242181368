import React from 'react';
import './internalPages.css'
import { GeneralComponents } from '@components';
import { IoMdArrowDropleft } from 'react-icons/io';
import { Box } from '@mui/material';

interface InternalPagesProps {
    title: string;
    description: string;
    children: React.ReactNode
    subpage?: boolean
    open?: boolean
    handleClose?: any
}

const InternalPage: React.FC<InternalPagesProps> = ({ title, description, children, subpage = false, open = false, handleClose }) => {
    return <main className='internal-page-container'>

        {!!subpage ?
            (
                <Box
                    display='flex'
                    flexDirection='column'
                >
                    <Box
                        display='flex'
                        alignItems='center'
                        gap='16px'
                    >
                        <GeneralComponents.DefaultWhiteButton
                            sx={{ width: '32px !important', padding: '0px !important' }}
                            variant='contained'
                            onClick={handleClose}
                        >
                            <IoMdArrowDropleft size={24} />
                        </GeneralComponents.DefaultWhiteButton>
                        <h2 className='internal-page-title'>{title}</h2>
                    </Box>
                    <p className='internal-page-paragraph'>{description}</p>
                </Box>
            )
            :
            (
                <Box
                    display='flex'
                    flexDirection='column'
                >
                    <h2 className='internal-page-title'>{title}</h2>
                    <p className='internal-page-paragraph'>{description}</p>
                </Box>
            )
        }
        {children}
    </main>;
}

export default InternalPage;