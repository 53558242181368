import { GridColDef } from "@mui/x-data-grid";
import { TokenComponents, GeneralComponents } from "@components"
import dayjs from "dayjs";
import { returnIfTokenIsDisabled } from "@utils";
import { isDesktopWidth } from "./screenWidth";

const commonUserTokenListColumns: GridColDef[] = isDesktopWidth ? [
    { field: 'id', headerName: 'Token Id', flex: 20, headerAlign: 'center', align: 'center' },
    { field: 'domain', headerName: 'Domínio', flex: 20, headerAlign: 'center', align: 'center' },
    { field: 'issuedAt', headerName: 'Criado em', flex: 20, headerAlign: 'center', align: 'center' },
    { field: 'expirationDate', headerName: 'Data de expiração', flex: 20, headerAlign: 'center', align: 'center' },
    {
        field: 'disable',
        valueGetter: (params) => returnIfTokenIsDisabled(params), headerName: 'Status', flex: 20, headerAlign: 'center', align: 'center', renderCell: (params) => <GeneralComponents.StatusCell params={params} />
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Desabilitar',
        flex: 20,
        cellClassName: 'actions',
        getActions: (params) => [<TokenComponents.DisableCell params={params} />]
    },
]
:
[
    { field: 'expirationDate', headerName: 'Expira em', flex: 50, headerAlign: 'center', align: 'center', valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY - HH:mm') },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Detalhes',
        flex: 50,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'actions',
        getActions: (params) => [<TokenComponents.TokenDetailsCell params={params} />]
    },
]
;

const tokenListColumns: GridColDef[] = isDesktopWidth ? [
    { field: 'id', headerName: 'Token Id', flex: 20, headerAlign: 'center', align: 'center' },
    { field: 'login', headerName: 'Usuário', flex: 20, headerAlign: 'center', align: 'center'},
    { field: 'domain', headerName: 'Domínio', flex: 20, headerAlign: 'center', align: 'center' },
    { field: 'issuedAt', headerName: 'Criado em', flex: 20, headerAlign: 'center', align: 'center', valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY - HH:mm') },
    { field: 'expirationDate', headerName: 'Expira em', flex: 20, headerAlign: 'center', align: 'center', valueFormatter: (params) => dayjs(params.value).format('DD/MM/YYYY - HH:mm') },
    {
        field: 'disable',
        valueGetter: (params) => returnIfTokenIsDisabled(params), headerName: 'Status', flex: 20, headerAlign: 'center', align: 'center', renderCell: (params) => <GeneralComponents.StatusCell params={params} />
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Desabilitar',
        flex: 20,
        cellClassName: 'actions',
        getActions: (params) => [<TokenComponents.DisableCell params={params} />]
    },
]
:
[
    { field: 'expirationDate', headerName: 'Expira em', flex: 33, headerAlign: 'center', align: 'center', valueFormatter: (params) => dayjs(params.value).format('DD/MM/YY - HH:mm'),  },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Detalhes',
        flex: 33,
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'actions',
        getActions: (params) => [<TokenComponents.TokenDetailsCell params={params} />]
    },
    {
        field: 'actions2',
        type: 'actions',
        headerName: 'Desabilitar',
        flex: 33,
        cellClassName: 'actions',
        getActions: (params) => [<TokenComponents.DisableCell params={params} />]
    },
]
;


export { tokenListColumns, commonUserTokenListColumns }