import { Button, ButtonGroup } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';


interface CustomOptionProps {
    theme?: Theme,
    active: boolean
}

const Container = styled(ButtonGroup)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme?.spacing(2),
    background: theme?.palette.neutral?.[400],
    borderRadius: '8px',
    maxWidth: '100%',
    '&.MuiButtonGroup-grouped:not(:last-of-type)': {
        borderRight: 'none',
    }
}))

const Option = styled(Button)(({ theme, active }: CustomOptionProps) => {

    const optionStyle = !!!active ?
        {
            color: theme?.palette.neutral[100],
            borderRadius: 'none',
            backgroundColor: 'transparent',
            '&:hover': {
                backgroundColor: 'transparent',
                color: theme?.palette.primary.main,
                border: 'none',
                transition: 'all .3s ease',
            }
        }
        :
        {
            color: theme?.palette.primary.main,
            borderRadius: '8px',
            background: theme?.palette.neutral.white,

            '&:hover': {
                backgroundColor: 'white',
                color: theme?.palette.secondary.main,
                border: 'none',
                transition: 'all .3s ease',
            },
        }

    return {
        fontFamily: 'Picadilly',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        textTransform: 'none',
        border: 'none',
        transition: 'all .3s ease',
        padding: `${theme?.spacing(2)} ${theme?.spacing(3)}`,
        width: '100%',
        flex: 1,
        '&:disabled':{
            border: 'none'
        },
        ...optionStyle,
    }
})

export {
    Container,
    Option
};