import { styled } from '@mui/system';
import { Button } from '@mui/material';

const CustomDefaultButton = styled(Button)`
    border-radius: 8px;
    text-transform: none;
    height: 40px;
    max-width: 100%;
    white-space: nowrap;
    background-color: #0068ff;
    padding: 8px 16px;
    font-family: 'Be vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #FFFFFF;
    transition: all .5s ease;

    :hover{
        background-color: #0448AB;
        transition: all .5s ease;
        box-shadow: none;
    }

    :disabled{
        cursor: auto;
        background-color: #edf2f4;
        border: none;
        padding: 16px 24px;
        border-radius: 8px;
        font-family: "Be Vietnam";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        color: #b1b9bd;
    }
`;

export default CustomDefaultButton;