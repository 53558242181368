// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BigId-tag{
    display: flex;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 4px;
    background: #F5F9FF;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #0068FF !important;
}

#BigMarket-tag{
    display: flex;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 4px;
    background: #FFFAE5;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #A47F22;
    height: 24px;
}

#BigBoost-tag{
    display: flex;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 4px;
    background: #FFE5EB;
    border-radius: 3px;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    color: #FF003C;
    height: 24px;
}

#product-tag-container{
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/ProductTag/productTag.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,QAAQ;IACR,mBAAmB;IACnB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["#BigId-tag{\n    display: flex;\n    align-items: flex-start;\n    padding: 6px 8px;\n    gap: 4px;\n    background: #F5F9FF;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 100%;\n    color: #0068FF !important;\n}\n\n#BigMarket-tag{\n    display: flex;\n    align-items: flex-start;\n    padding: 6px 8px;\n    gap: 4px;\n    background: #FFFAE5;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 100%;\n    color: #A47F22;\n    height: 24px;\n}\n\n#BigBoost-tag{\n    display: flex;\n    align-items: flex-start;\n    padding: 6px 8px;\n    gap: 4px;\n    background: #FFE5EB;\n    border-radius: 3px;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 100%;\n    color: #FF003C;\n    height: 24px;\n}\n\n#product-tag-container{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
