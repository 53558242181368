import { styled, Theme } from "@mui/material/styles"
import { StandardCSSProperties } from "@mui/system";
import { Button as ButtonMaterial } from "@mui/material"

interface IButtonStyles {
  [key: string]: StandardCSSProperties | any
}

const Button = styled(ButtonMaterial)(({
  children,
  variant,
  color,
  size,
  theme,
  fullWidth
}: {
  children: any,
  variant: string,
  color: string,
  size: string,
  theme?: Theme,
  fullWidth?: boolean
}) => {
  const buttonStyles: IButtonStyles = {

    color: {
      primary: {
        backgroundColor: theme?.palette.lighterBigBlue?.pure,
        color: theme?.palette?.neutral?.white,
        "&:hover": {
          backgroundColor: theme?.palette.lighterBigBlue?.medium
        }
      },
      secondary: {
        backgroundColor: theme?.palette?.neutral?.white,
        color: theme?.palette.primary.blue,
        "&:hover": {
          backgroundColor: theme?.palette.neutral?.[400]
        }
      },
      success: {
        backgroundColor: theme?.palette?.success?.pure,
        color: theme?.palette.neutral?.white,
        "&:hover": {
          backgroundColor: theme?.palette.success?.medium
        }
      },
      negative: {
        backgroundColor: theme?.palette?.secondary?.red,
        color: theme?.palette.neutral?.white,
        "&:hover": {
          backgroundColor: theme?.palette.negative?.medium
        }
      },
    },
    size: {
      medium: {
        padding: !!children[1] ? `${theme?.spacing(4)} ${theme?.spacing(6)}` : `${theme?.spacing(4)}`,
        fontSize: 16
      },
      small: {
        padding: !!children[1] ? `${theme?.spacing(2)} ${theme?.spacing(3)}` : `${theme?.spacing(2)}`,
        fontSize: 14
      }
    },
    variant: {
      action: {
        borderRadius: 4,
        border: `1px solid ${theme?.palette.neutral?.[400]}`,
        padding: theme?.spacing(1),
        gap: theme?.spacing(.5),
        backgroundColor: theme?.palette.neutral?.white,
        color: theme?.palette.neutral?.[100],
        fontFamily: "Be Vietnam",
        fontWeight: 400,
        fontSize: 12,
        "&:hover": {
          backgroundColor: theme?.palette.neutral?.[500],
          color: theme?.palette.secondary.marine
        }
      },
      text: {
        gap: theme?.spacing(3),
        backgroundColor: 'transparent',
        color: {
          primary: {
            color: theme?.palette.primary?.main,
            "&:hover": {
              color: theme?.palette.secondary?.main,
              backgroundColor: 'transparent',
            }
          },
          success: {
            color: theme?.palette?.success?.main,
            "&:hover": {
              color: theme?.palette?.success?.dark,
              backgroundColor: 'transparent',
            }
          },
          negative: {
            color: theme?.palette?.negative?.main,
            "&:hover": {
              color: theme?.palette?.negative?.dark,
              backgroundColor: 'transparent',
            }
          },
        },
      }
    }
  };

  return {
    fontFamily: "Be Vietnam",
    textTransform: "none",
    fontWeight: 700,
    width: fullWidth ? "100%" : "fit-content",
    height: "fit-content",
    minWidth: 30,
    minHeight: 30,
    borderRadius: 8,
    display: "flex",
    gap: theme?.spacing(2),
    ...buttonStyles.color[color],
    ...buttonStyles.size[size],
    ...buttonStyles.variant[variant],
  }
});

export {
  Button
};