import Loading from "./Loading";
import Chart from "./Chart";
import * as Card from "./Card";
import ImageQuality from "./ImageQuality";
import DataComparison from "./DataComparison";
import DocumentVerification from "./DocumentVerification";
import DataExtraction from "./DataExtraction";
import Data from "./Data";
import Print from "./Print";

export {
  Loading,
  Chart,
  Card,
  ImageQuality,
  DataComparison,
  DocumentVerification,
  DataExtraction,
  Data,
  Print
}