import { IconButton } from '@mui/material';
import { styled } from '@mui/system';

const PDFControls = styled(IconButton)`
    border: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    transition: all .5s ease;

    :hover{
        background-color: #00006650;
        transition: all .5s ease;
    }
`;

export default PDFControls;