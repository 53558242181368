import { Box } from "@mui/material";
import { styled } from "@mui/material/styles"

const Title = styled('h2')(({ theme }) => ({
  fontFamily: 'Picadilly',
  fontWeight: 700,
  fontSize: 32,
  color: theme.palette.secondary.blue
}));

const Subtitle = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}));

const TitleCard = styled('h4')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

const Line = styled(Box)(({ theme }) => ({
  "&:not(:first-child)": {
    paddingTop: theme.spacing(4),
  },
  "&:not(:last-child)": {
    paddingBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.neutral?.[400]}`
  }
}));

const Key = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}));

const Value = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.secondary.blue
}));

const TextPrice = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.secondary.blue
}));

const ValuePrice = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 24,
  color: theme.palette.primary.blue
}));

const LabelSection = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue
}));

export {
  Title,
  Subtitle,
  TitleCard,
  Line,
  Key,
  Value,
  TextPrice,
  ValuePrice,
  LabelSection
}