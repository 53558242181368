import { styled } from "@mui/material/styles"
import { TextField as TextFieldMaterial } from "@mui/material"

const Label = styled("label")(({ theme }) => ({
  fontFamily: "'Be Vietnam'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '140%',
  color: '#000066',
  marginLeft: '2px',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end"
}))

const TextField = styled(TextFieldMaterial)(({ theme }) => ({
  "& input": {
    heigth: "57px",
    padding: "20px 10px 20px 16px",
    caretColor: "#0448AB",
    fontFamily: "Be Vietnam",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "140%",
    leadingTrim: "both",
    textEdge: "cap",
    color: "#555866",

    "::placeholder": {
      fontFamily: 'Be Vietnam',
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "140%",
      leadingTrim: "both",
      textEdge: "cap",
      color: "#555866",
      opacity: 1,
    }
  },
  "& fieldset": {
    border: "1px solid #BED0D7",
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0448AB"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#BED0D7"
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FF003C"
  },
  "& .MuiFormHelperText-root": {
    fontFamily: "Be Vietnam",
    fontSize: "12px",
    letterSpacing: "0.5px",
    fontWeight: 600,
    fontStyle: "normal",
    color: "#FF003C",
    margin: 0,
  },

}));

const RequiredSpan = styled("span")(({ theme }) => (
  {
    fontFamily: "'Be Vietnam'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '140%',
    color: '#CC0030',
    marginLeft: '2px'
  }))

export {
  TextField,
  Label,
  RequiredSpan
};