import Slider, { Settings } from "react-slick";

import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import './sliderContainer.css'


interface SliderContainerProps {
    children: React.ReactNode;
}

function SampleNextArrow(props : any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '24px', height: '24px', cursor: 'pointer'}}
      onClick={onClick}
    >
      <BsArrowRightShort size={24} color='#0068ff'/>
    </div>
  );
}

function SamplePrevArrow(props : any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '24px', height: '24px',  cursor: 'pointer'}}
      onClick={onClick}
    >
      <BsArrowLeftShort size={24} color='#0068ff'/>
    </div>
  );
}


const SliderContainer: React.FC<SliderContainerProps> = ({children}: SliderContainerProps) => {
  const settings: Settings = {
    infinite: false,
    speed: 500,
    adaptiveHeight: true,
    vertical: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    responsive: [
      {
        breakpoint: 1367,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      }
    ]
  };

  return (
    <Slider  {...settings}>
      {children}
    </Slider>
  );
};

export default SliderContainer;