// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 30px;
    gap: 32px;
    height: 62px;
    border-bottom: 1px solid var(--to-approval-neutral-high-high-dark, #BED0D7);
    background: var(--to-approval-neutral-high-high-lightest, #FFF);
    grid-area: header;
    z-index: 1000;
}


.header-logo-container {
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 16px;
}


.header-logo-image {
    width: 153.75px;
    height: 30px;
}

@media screen and (max-width: 500px) {
    .header-logo-image {
        margin: 0 auto;
    }

    .header-container {
        position: fixed;
        z-index: 10;
        background-color: #1d203a;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

@media print {
    .header-container {
        display: none;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MainPagesTemplateComponents/Header/header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,SAAS;IACT,YAAY;IACZ,2EAA2E;IAC3E,+DAA+D;IAC/D,iBAAiB;IACjB,aAAa;AACjB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,SAAS;AACb;;;AAGA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,eAAe;QACf,WAAW;QACX,yBAAyB;QACzB,OAAO;QACP,QAAQ;QACR,SAAS;IACb;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".header-container {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    padding: 8px 30px;\n    gap: 32px;\n    height: 62px;\n    border-bottom: 1px solid var(--to-approval-neutral-high-high-dark, #BED0D7);\n    background: var(--to-approval-neutral-high-high-lightest, #FFF);\n    grid-area: header;\n    z-index: 1000;\n}\n\n\n.header-logo-container {\n    display: flex;\n    align-items: center;\n    padding: 0px;\n    gap: 16px;\n}\n\n\n.header-logo-image {\n    width: 153.75px;\n    height: 30px;\n}\n\n@media screen and (max-width: 500px) {\n    .header-logo-image {\n        margin: 0 auto;\n    }\n\n    .header-container {\n        position: fixed;\n        z-index: 10;\n        background-color: #1d203a;\n        left: 0;\n        right: 0;\n        bottom: 0;\n    }\n}\n\n@media print {\n    .header-container {\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
