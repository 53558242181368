import { IconType } from "react-icons"
import { MdCompareArrows } from "react-icons/md";

interface Feature {
    value: string,
    title: string,
    description: string,
    icon: IconType,
}

const features: Feature[] = [
    {
        value: 'simpleFacematch',
        title: 'Comparativa simples',
        description: 'Compare dois rostos e saiba se são da mesma pessoa.',
        icon: MdCompareArrows
    },
    {
        value: 'otherFacematch',
        title: 'Busca por rostos',
        description: 'Compare dois rostos e saiba se são da mesma pessoa.',
        icon: MdCompareArrows
    }
]

export {
    features
}