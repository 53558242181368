import { Paper, ToggleButton, ToggleButtonGroup, styled } from "@mui/material";

const PaperContainer = styled(Paper)((theme) => {
    return `
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 24px;
    width: 100%;
    height: fit-content;
    padding: 16px;
    border-radius: 12px;
`
})

const CustomToggleButtonGroup = styled(ToggleButtonGroup)((theme) => {
    return `
        display: inline-flex;
        padding: 8px;
        justify-content: center;
        align-items: center;
        background: #F3F8FA;
        border-radius: 8px;


        && .Mui-selected {
            background-color: #FFF;
            border-radius: 8px;
            color: #0068FF;
            font-weight: 700;

        }

        && .Mui-selected:hover {
            background-color: #FFF;
            border-radius: 8px;
            color: #0068FF;
            font-weight: 700;

        }
    
    `
})

const CustomToggleButton = styled(ToggleButton)((theme) => {
    return `
        color: #555866;
        font-family: Be Vietnam;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        display: flex;
        padding: 8px 12px;
        align-items: center;
        text-transform: none;
        border: none;
        gap: 8px;

        :hover{
            background-color: transparent;
            color: #0068FF;
        }

        
    `
})

const Label = styled('label')((theme) => {
    return `
    
        font-family: "Be Vietnam";
        align-self: flex-start;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: 0.5px;
        color: #555866;

    `
})

export { PaperContainer, CustomToggleButtonGroup, CustomToggleButton, Label }