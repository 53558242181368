import React, { useCallback, useEffect } from "react";

import { Box } from "@mui/material";
import { Checkbox, Checked, Unchecked, Label, Content } from "./styles";
import { BiCheck } from 'react-icons/bi';

import getValueFromPath from "lodash/get";
import setValueFromPath from "lodash/set";
import mapValues from "lodash/mapValues";

import { useFormContext } from "react-hook-form";
import CheckboxContext from "../context";
import { pontualConsult } from "@utils";

interface ComponentProps {
  label: string,
  value: string,
  properties?: any,
  disabled?: boolean,
  checkAll?: boolean,
}

const Component: React.FC<ComponentProps> = ({ label, value, properties, disabled = false, checkAll = false }) => {
  const { field } = React.useContext(CheckboxContext);

  const [loadedInitialState, setLoadedInitialState] = React.useState<boolean>(false);

  const { watch, setValue } = useFormContext();
  const currentWatch = watch();

  const fieldSplitted = field.name.split(".");

  const currentData = getValueFromPath(currentWatch, field.name) || {};

  const id = `${field.name}.${value}`;

  const isChecked = pontualConsult.isObject(currentData) ? !!currentData[value]?.selected : currentData?.includes(value) || false;

  const pathParent = fieldSplitted.slice(0, -1).join(".");
  const currentParentData = getValueFromPath(currentWatch, pathParent) || {};

  const handleCheck = () => {
    if (!!checkAll) {
      const newValue = mapValues(currentData, (value) => ({
        ...value,
        selected: !isChecked
      }))

      field.onChange(newValue)
      return
    }

    const newValue = { ...currentData, [`${value}`]: { label, properties, selected: !isChecked } };
    field.onChange(newValue);

    return;
  }

  const verifyDisabled = () => {
    if (fieldSplitted.length <= 1) {
      return false;
    }

    return !currentParentData.selected || disabled;
  }

  const isDisabled: boolean = verifyDisabled();

  useEffect(() => {
    setValue(id, { label, properties, selected: currentParentData?.selected || isChecked || false });

    setLoadedInitialState(true);
  }, [currentParentData?.selected]);

  useEffect(() => {

    if (!!checkAll && loadedInitialState) {
      const hasChildUnchecked = !(
        Object.entries(currentData || {})
          .filter(([childKey]: any) => { return childKey !== value })
          .every(([, childValue]: any) => { return !!childValue.selected })
      );

      setValue(id, { label, properties, selected: !hasChildUnchecked });
    }

  }, [currentData]);

  return (
    <>
      <Content display="flex" alignItems="center" gap={2} p={3} disabled={isDisabled}>
        <Checkbox
          onChange={handleCheck}
          disableRipple
          color="default"
          checkedIcon={
            <Checked>
              <BiCheck size={16} />
            </Checked>
          }
          icon={<Unchecked />}
          id={id}
          value={value}
          checked={isChecked}
          disabled={isDisabled}
        />
        <Label htmlFor={id}>{label}</Label>
      </Content>
    </>
  )
}

export default Component