import { Theme, ToggleButton, styled, toggleButtonClasses } from "@mui/material";

const Item = styled(ToggleButton)(({ theme }: { theme?: Theme }) => ({
    fontFamily: 'Be Vietnam',
    fontSize: '14px',
    fontWeight: 400,
    padding: `${theme?.spacing(1)} ${theme?.spacing(2)}`,
    color: theme?.palette.neutral[100],
    border: `1px solid ${theme?.palette.neutral[400]}`,
    borderRadius: '4px',
    textTransform: 'none',
    transition: 'all .5s ease',
    '&:hover': {
        backgroundColor: theme?.palette.neutral[400],
        transition: 'all .5s ease'
    },
    '&.Mui-selected, &.Mui-selected:hover': {
        backgroundColor: theme?.palette.secondary.marine,
        color: theme?.palette.neutral.white,
        transition: 'all .5s ease'
    },
}))

export default Item