// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoice-details-content{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.invoice-details-content-title{
    color: var(--neutral-100, #555866);
    font-size: 18px;
    font-family: 'Be Vietnam';
    font-weight: 700;
    line-height: 140%;
}

.invoice-details-data{
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.invoice-details-data label, .invoice-details-data span{
    color: var(--neutral-100, #555866);
    font-size: 14px;
    font-family: 'Be Vietnam';
    line-height: 150%;
    letter-spacing: 0.5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/FinancialManagement/Invoices/InvoiceDetails/invoiceDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,kCAAkC;IAClC,eAAe;IACf,yBAAyB;IACzB,iBAAiB;IACjB,qBAAqB;AACzB","sourcesContent":[".invoice-details-content{\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.invoice-details-content-title{\n    color: var(--neutral-100, #555866);\n    font-size: 18px;\n    font-family: 'Be Vietnam';\n    font-weight: 700;\n    line-height: 140%;\n}\n\n.invoice-details-data{\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.invoice-details-data label, .invoice-details-data span{\n    color: var(--neutral-100, #555866);\n    font-size: 14px;\n    font-family: 'Be Vietnam';\n    line-height: 150%;\n    letter-spacing: 0.5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
