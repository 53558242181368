import { Box } from '@mui/material';
import React from 'react';
import Field from '../../components/Field';

const FileSelection: React.FC = () => {



    return <Box
        display={'flex'}
        justifyContent={'space-around'}
        gap={4}
        width={'100%'}
    >
        <Field
            type={'file'}
            name={'file-test'}
            identifier='Primeiro rosto'
        />
        <Field
            type={'file'}
            name={'file-test2'}
            identifier='Segundo rosto'
        />
    </Box>;
}

export default FileSelection;