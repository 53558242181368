import { Box } from "@mui/material";
import { Theme, styled } from "@mui/material/styles";

const Container = styled(Box)(({theme, breakpoint }: { theme?: Theme, breakpoint: boolean }) => ({
        display: "flex",
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: 2,
        width: !!!breakpoint ? '56%' : '100%',
    }))

export default Container