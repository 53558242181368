import Link from './styles'

interface LinkProps {
    to: string,
    target?: string,
    children: any
}

const Component: React.FC<LinkProps> = ({
    to,
    target,
    children
}) => {
    return <Link to={to} target={target}>
        {children}
    </Link>
};

export default Component