import {
    GridPagination,
  } from '@mui/x-data-grid';
  import MuiPagination from '@mui/material/Pagination';
  import { TablePaginationProps } from '@mui/material';
  import { useState } from 'react';

  
  const paginationCustomStyle = {
    '& .Mui-selected': {
      color: 'white !important',
      backgroundColor: '#0069ff !important',
      transition: 'background-color 1s ease !important',
      '&:hover': {
        backgroundColor: '#006 !important',
        transition: 'background-color 1s ease !important'
      },
    },
    '& .MuiPaginationItem-root:not(.Mui-selected):hover': {
      transition: 'background-color .5s ease !important',
      backgroundColor: '#f0f8fa !important',
    },
    '& .MuiPaginationItem-page:not(.Mui-selected) !important': {
      transition: 'background-color .5s ease !important',
      color: '#006 !important',
      fontFamily: "'Be vietnam', sans-serif !important"
    },
  
  };
  const gridPaginationCustomStyle = {
    width: '100%',
    marginLeft: 'auto',
    '& .MuiToolbar-root': {
      width: '100%'
    },
    '& .MuiTablePagination-actions': {
      marginLeft: 'auto',
    },
    '& .MuiInputBase-root': {
      border: '2px solid #B1B9BD',
      borderRadius: '8px',
      fontFamily: "'Be vietnam', sans-serif",
      fontSize: '14px'
    },
    '& .MuiTablePagination-spacer': {
      display: 'none !important'
    },
    '& .MuiTablePagination-displayedRows': {
      display: 'none !important'
    },
    '& .MuiTablePagination-selectLabel': {
      fontFamily: "'Be vietnam', sans-serif",
      fontSize: '12px'
    }
  }
  
  function Pagination({
    page,
    count,
    className,
  }: Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className' | 'count'>) {
  
    const [currentPage, setCurrentPage] = useState<number>(1);
  
    const onPageChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
      setCurrentPage(newPage);
    };
  
    return (
      <MuiPagination
        sx={paginationCustomStyle}
        variant="text"
        shape="rounded"
        className={className}
        count={count}
        page={currentPage}
        onChange={(event, newPage) => {
          onPageChange(event as any, newPage);
        }}
      />
    );
  }
  
  
  
  function TokenPagination(props: any) {
    return <GridPagination sx={gridPaginationCustomStyle} labelRowsPerPage='Resultados por página:' ActionsComponent={Pagination} {...props} />;
  }
  
  export { TokenPagination }