import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

const snakeToCamel = (str: string) => {
  return str.replace(
    /(?!^)_(.)/g,
    (_: any, char: string) => char.toUpperCase()
  );
}

const snakeToPascal = (str: string) => {
  const camelCase = snakeToCamel(str);
  const pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);

  return pascalCase;
}

const toHumanize = (str: string) => {
  return str.replace(/(^|[_-])([a-z])/g, (a, b, c) => c.toUpperCase())
    .replace(/([a-z])([A-Z])/g, (a, b, c) => `${b} ${c}`);
}

const toBrazilianDate = (str: string) => {
  const date = dayjs.utc(str).format('DD/MM/YYYY');

  return date;
}

const toValidFileName = (str: string) => {
  return str.replace(/( )+/g, "_")
}

export {
  snakeToCamel,
  snakeToPascal,
  toHumanize,
  toBrazilianDate,
  toValidFileName,
}