import { GeneralComponents } from '@components';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { to } from '@react-spring/shared/dist/declarations/src/globals';
import { Dayjs } from 'dayjs';
import React, { useRef } from 'react';

interface FilterSectionProps {
    localUsageSummaryFilterInfo: {
        referenceMonthStart: string;
        referenceMonthEnd: string;
    },
    handleReferenceMonthStartChange: (referenceMonthStart: Dayjs | null) => void;
    handleReferenceMonthEndChange: (referenceMonthEnd: Dayjs | null) => void;
    handleFilterRequest: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClearFilters: (event: React.MouseEvent<HTMLButtonElement>) => void;
    isMobile: boolean;
    open: boolean;
    toggleMobileFilterSection: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterSection: React.FC<FilterSectionProps> = (
    {
        localUsageSummaryFilterInfo,
        handleReferenceMonthStartChange,
        handleReferenceMonthEndChange,
        handleFilterRequest,
        handleClearFilters,
        isMobile,
        open,
        toggleMobileFilterSection
    }
) => {

    const mobileFiltersRef = useRef(null)

    return !isMobile ? <div className='usage-summary-filter-section'>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
            <GeneralComponents.CustomDatePickers
                views={['month', 'year']}
                LabelText='Período de referência'
                startValue={localUsageSummaryFilterInfo.referenceMonthStart}
                endValue={localUsageSummaryFilterInfo.referenceMonthEnd}
                handleStartChange={handleReferenceMonthStartChange}
                handleEndChange={handleReferenceMonthEndChange}
                handleFieldErrors={() => { }} />
        </LocalizationProvider>
        <div className='usage-summary-filter-options'>
            <GeneralComponents.FilterButton onClick={handleFilterRequest}>Filtrar</GeneralComponents.FilterButton>
            <GeneralComponents.ClearFilterButton onClick={handleClearFilters}>Limpar filtros</GeneralComponents.ClearFilterButton>
        </div>
    </div>
        :
        <GeneralComponents.MobileSideSection
            sideSectionTitle='Filtros'
            sideSectionRef={mobileFiltersRef}
            mainButtonType='text'
            mainButtonText='Limpar filtros'
            mainButtonAction={handleClearFilters}
            closeAction={toggleMobileFilterSection}
            open={open}
            secondaryButtonType='default'
            secondaryButtonText='Filtrar'
            secondaryButtonAction={handleFilterRequest}
        >
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
                <div className='mobile-side-section-group'>
                    <label>Período de referência</label>
                    <GeneralComponents.CustomDatePickers
                        views={['month', 'year']}
                        LabelText=''
                        startValue={localUsageSummaryFilterInfo.referenceMonthStart}
                        endValue={localUsageSummaryFilterInfo.referenceMonthEnd}
                        handleStartChange={handleReferenceMonthStartChange}
                        handleEndChange={handleReferenceMonthEndChange}
                        handleFieldErrors={() => { }} />
                </div>
            </LocalizationProvider>
        </GeneralComponents.MobileSideSection>
        ;
}

export default FilterSection;