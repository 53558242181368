import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: 'column',
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: theme.spacing(2),
    color: theme.palette.lighterBigBlue?.dark,
    padding: theme.spacing(6),
    borderWidth: 2,
    borderColor: theme.palette.primary?.gray,
    borderStyle: 'solid',
    borderRadius: 12
}))

export {
    Container
};