import { GridColDef } from "@mui/x-data-grid";
import { Button } from "@components";
import { useTheme } from "@mui/material";
import { apis } from "@constants";
import dayjs from "dayjs";
import { BiCheckCircle, BiXCircle } from "react-icons/bi";

const AlternativeKey = ({ exist }: { exist: Boolean }) => {
  const theme = useTheme();

  return (
    exist ? (
      <Button StartIcon={<BiCheckCircle size={24} />} color="secondary" size="small" />
    ) : (
      <Button StartIcon={<BiXCircle size={24} color={theme.palette.secondary.red} />} color="secondary" size="small" />
    )
  )
}

const list: GridColDef[] = [
  {
    field: "id",
    headerName: "ID da consulta",
    flex: 12.5
  },
  {
    field: "user",
    headerName: "Usuário/Domínio",
    flex: 12.5
  },
  {
    field: "api",
    headerName: "API/Datasets",
    flex: 12.5,
    // renderCell: (params) => apis[params.value]
  },
  {
    field: "date",
    headerName: "Data da consulta",
    flex: 12.5,
    valueFormatter: (params) => dayjs(params.value, "YYYY-MM-DD", "pt-BR").format("DD/MM/YYYY")
  },
  {
    field: "alternativeKey",
    headerName: "Chaves alternativas",
    headerAlign: "right",
    align: "right",
    flex: 12.5,
    renderCell: (params) => <AlternativeKey exist={!!params?.formattedValue && !!params?.formattedValue?.length} />
  },
];

const result: GridColDef[] = [
  {
    field: "attribute",
    headerName: "Atributo"
  },
  {
    field: "result",
    headerName: "Resultado"
  },
]

export {
  list,
  result
}