import { Box, ToggleButtonGroup, Typography } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import Container from './styles';

interface IComponent {
    children: any
    name: string,
    exclusive?: boolean,
    label?: string,
    shouldReset?: boolean,
}

const Component: React.FC<IComponent> = ({
    children,
    name,
    exclusive = true,
    label = '',
    shouldReset = false
}) => {

    const {
        control,
        setValue,
        reset,
        formState: { errors }
    } = useFormContext();

    return <Box
        display={'flex'}
        alignItems={'center'}
        gap={2}
    >
        {!!label && (
            <Typography
                variant='body2'
                color='#006'
                fontWeight={700}
            >
                {label}
            </Typography>)
        }
        <Controller
            name={name}
            control={control}
            render={({ field }) => (
                <Container
                    color='secondary'
                    exclusive={exclusive}
                    value={field.value}
                    onChange={(event, value) => {
                        if (!!shouldReset) {
                            reset()
                        }
                        setValue(field.name, value)
                    }}
                >
                    {children}
                </Container>
            )}
        />
        <Typography color="red">
            <>
                {errors?.[name]?.message && errors?.[name]?.message}
            </>
        </Typography>
    </Box>
}


export default Component;