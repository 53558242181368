import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InvoicesPaginationInfo {
    Page: number,
    Records: number,
    TotalPages: number,
    TotalRecords: number
}


const initialState = {
    Page: 1,
    Records: 12,
    TotalPages: 0,
    TotalRecords: 0
}

const invoicesPaginationSlice = createSlice({
  name: 'invoicesPagination',
  initialState: initialState,
  reducers: {
    setInvoicesPaginationInfo: (state, action: PayloadAction<InvoicesPaginationInfo>) => {
        return {...state, ...action.payload}
    }
  },
});

export const { setInvoicesPaginationInfo } = invoicesPaginationSlice.actions;

export default invoicesPaginationSlice.reducer;