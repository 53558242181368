import { Theme, styled } from '@mui/material/styles';
import { Box } from "@mui/material";
import { Paper } from "@components";

interface CustomPaperProps extends Omit<typeof Paper, 'checked'> {
  theme?: Theme,
  checked: boolean
}

const Card = styled(Paper)(({ theme, checked }: CustomPaperProps) => {
  const borderStyle = !!checked ? {
    borderWidth: "2px",
    borderColor: theme?.palette.lighterBigBlue?.pure
  } : ""

  return {
    flex: 1,
    borderWidth: "2px",
    transition: "border-color .4s ease",
    ...borderStyle
  }
})

const Title = styled('h3')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.marine
}))

const Description = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100]
}))

const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#0068FF1A",
  color: theme.palette.lighterBigBlue?.dark,
  padding: theme.spacing(4),
  borderRadius: 100
}))

export {
  Card,
  Title,
  Description,
  Icon
}