// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.news-link-container{
    height: 169px;
}

.news-link-image{
    height: 169px;
}

@media screen and (max-width: 500px) {
    .news-link-container{
        height: 100%;
        width: 100%;
    }
    
    .news-link-image{
        height: 100%;
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/News/BannerNews/bannerNews.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI;QACI,YAAY;QACZ,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;AACJ","sourcesContent":[".news-link-container{\n    height: 169px;\n}\n\n.news-link-image{\n    height: 169px;\n}\n\n@media screen and (max-width: 500px) {\n    .news-link-container{\n        height: 100%;\n        width: 100%;\n    }\n    \n    .news-link-image{\n        height: 100%;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
