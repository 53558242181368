import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CreateTokenInfo {
    reloadTokenList: boolean
}

const initialState: CreateTokenInfo = {
    reloadTokenList: false
}

const createTokenInfo = createSlice({
    name: 'createTokenInfo',
    initialState,
    reducers: {
        setReloadTokenList: (state, action: PayloadAction<boolean>) => {
           state.reloadTokenList = action.payload
        }
    }
});

export const { setReloadTokenList } = createTokenInfo.actions;

export default createTokenInfo.reducer;