import React from 'react';
import { Field } from './styles';
import { Typography } from '@mui/material';

interface ComponentProps {
  label: string,
  value: string | React.ReactNode | undefined,
}

const Component: React.FC<ComponentProps> = ({ label, value }) => {
  return <Field>
    <Typography variant='body2' fontWeight={700}>{label}</Typography>
    <Typography variant='body2'>{value}</Typography>
  </Field>;
}

export default Component;