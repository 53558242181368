import {GridColDef} from "@mui/x-data-grid";
import { GeneralComponents } from "@components";
import { isDesktopWidth } from "./screenWidth";

const jobHeaderName = isDesktopWidth ? "Nome do job" : "Job"
const creationHeaderName = isDesktopWidth ? "Data de criação" : "Criação"

const recentQueriesColumns: GridColDef[] = [
    { field: 'job', headerName: jobHeaderName, flex: 15, headerAlign:'left', align: 'left'},
    { field: 'creationDate', headerName: creationHeaderName, flex: 30, headerAlign:'left', align: 'left'},
    { field: 'status', headerName: 'Status', flex: 30, headerAlign:'left', align: 'left', renderCell: (params) => <GeneralComponents.StatusCell params={params}/>}
]


export default recentQueriesColumns