import { styled } from "@mui/material/styles";

const Td = styled("td")(({ theme }) => ({
  padding: theme.spacing(2),
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100],
  wordBreak: 'break-word',
  
}))

export {
  Td
}