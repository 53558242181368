import { ReactNode } from "react";

import { Table } from "./styles";

type ContainerProps = {
  children: ReactNode,
  elevation?: boolean
}

const Container: React.FC<ContainerProps> = ({ children, elevation = false }) => {
  return (
    <Table
      elevation={elevation}
    >
      {children}
    </Table>
  )
}

export default Container