import React from 'react';
import { Box, TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';

interface CustomTextFieldProps extends Omit<TextFieldProps, 'helperText'> {
    helperText?: string;
    labelText?: string;
}

const Label = styled('label')`
    height: 21px;
    font-family: "Be Vietnam";
    align-self: flex-start;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.5px;
    color: #555866;
    margin-left: 2px;
`

const CustomTextField = styled(({ helperText, labelText, ...otherProps }: CustomTextFieldProps) => (
    <Box
    display='flex'
    flexDirection={'column'}
    gap={'4px'}
    width={'100%'}
    >
        {labelText && <Label>{labelText}</Label>}
        <TextField {...otherProps} helperText={helperText} error={!!helperText} />
    </Box>
))` 
    input{
        color: #7D8285;
        padding: 20px 15px;
        font-family: 'Be vietnam', sans-serif;
        caret-color: #0448AB;
        letter-spacing: 0.5px;
    }

    input::placeholder{
        color: #7D8285;
        opacity: 1;
        font-family: 'Be vietnam', sans-serif;
        letter-spacing: 0.5px;
    }

    label {
        padding: 10px 0px;
        font-family: 'Be Vietnam', sans-serif;
        color: #555866;
        letter-spacing: 0.5px;
        margin-left: 4px;
    }

    fieldset{ 
        border-radius: 8px;
        border: 2px solid #B1B9BD;
        color: #B1B9BD;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #0448AB;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #B1B9BD;
    }

    & .MuiFormHelperText-root {
        font-family: 'BeVietnam-Medium', sans-serif;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-style: normal;
        color: #FF003C;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 0px 0px;
        gap: 10px;
        order: 1;
        align-self: stretch;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: #FF003C; 
    }

    .MuiOutlinedInput-root.Mui-error .MuiIconButton-root svg{
        color: #FF003C !important; 
    }

`;

export default CustomTextField;