import { styled } from "@mui/material/styles"

import { Box } from "@mui/material";
import { Chip as ChipComponent } from "@components";

const Chip = styled(ChipComponent)(({ theme }) => ({
  // width: "fit-content",
  fontWeight: 700,
  backgroundColor: theme.palette.neutral?.[200],
  color: theme.palette.neutral?.white
}));

const TicketContent = styled(Box)(({ theme }) => ({
  fontFamily: "Be Vietnam",
  backgroundColor: theme.palette.neutral?.[500]
}));

const TicketTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue,
}));

const TicketValue = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}));

export {
  Chip,
  TicketContent,
  TicketTitle,
  TicketValue
}