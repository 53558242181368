import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import Row from "../Row";
import Cell from "../Cell";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";

type RowCollapseProps = {
  children: ReactNode,
  content: ReactNode,
  expanded?: boolean
}

const RowCollapse: React.FC<RowCollapseProps> = ({ children, content, expanded = false }) => {
  const [open, setOpen] = React.useState(expanded)
  const CollapseIcon = (open || expanded) ? BiChevronUp : BiChevronDown

  const handleOpenCollapse = () => {
    setOpen(!open)
  }

  return (
    <>
      <Row
        onClick={handleOpenCollapse}
        sx={{ borderBottom: open || expanded ? "none" : "" }}
      >
        {children}
        <Cell>
          <Box display="flex" justifyContent="flex-end">
            <CollapseIcon size={20} />
          </Box>
        </Cell>
      </Row>
      {(open || expanded) && (content)}
    </>
  )
}

export default RowCollapse