// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoices-filter-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
 }
 
.invoices-filter-status{
     display: flex;
     flex-direction: column;
     grid-gap: 10px;
     width: 200px;
}

 .invoices-filter-options{
    align-self: flex-end;
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 8px;
    height: 55px;
}

.invoices-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 24px;
    isolation: isolate;
    width: 100%;
    height: 696px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

 }
 
.invoices-table-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
 }
 

 @media  screen and (max-width: 500px){
    .invoices-options{
        display: flex;
        justify-content: flex-end;
    }
 }`, "",{"version":3,"sources":["webpack://./src/pages/FinancialManagement/Invoices/invoices.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,SAAS;CACZ;;AAED;KACK,aAAa;KACb,sBAAsB;KACtB,cAAc;KACd,YAAY;AACjB;;CAEC;IACG,oBAAoB;IACpB,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,mBAAmB;IACnB,wCAAwC;;CAE3C;;AAED;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,kBAAkB;CACrB;;;CAGA;IACG;QACI,aAAa;QACb,yBAAyB;IAC7B;CACH","sourcesContent":[".invoices-filter-container{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0px;\n    gap: 16px;\n }\n \n.invoices-filter-status{\n     display: flex;\n     flex-direction: column;\n     grid-gap: 10px;\n     width: 200px;\n}\n\n .invoices-filter-options{\n    align-self: flex-end;\n    display: flex;\n    align-items: center;\n    padding: 0px;\n    gap: 8px;\n    height: 55px;\n}\n\n.invoices-container{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    padding: 16px;\n    gap: 24px;\n    isolation: isolate;\n    width: 100%;\n    height: 696px;\n    background: white;\n    border-radius: 16px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);\n\n }\n \n.invoices-table-container{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: flex-start;\n    padding: 0px;\n    width: 100%;\n    height: 100%;\n    border-radius: 8px;\n }\n \n\n @media  screen and (max-width: 500px){\n    .invoices-options{\n        display: flex;\n        justify-content: flex-end;\n    }\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
