import React from 'react';
import { Player } from "@lottiefiles/react-lottie-player";
import Animation from './animation.json'
import { Box, Typography } from '@mui/material';
import { GeneralComponents } from '@components';
import { BsArrowLeftShort } from 'react-icons/bs';
import {  useNavigate } from 'react-router';

const NotFound: React.FC = () => {


    const navigate = useNavigate()
    const handleReturn = () => {
        navigate('/login')
    }

    return <Box
        display='flex'
        justifyContent={'center'}
        alignItems={'center'}
        width={'100vw'}
        height={'100vh'}
        bgcolor={'#F3F8FA'}
        flexDirection={'column'}
        gap={'10px'}
    >
        <Box
            width={'100vw'}
            flexDirection={'row'}
            gap={4}>
            <Player
                autoplay
                loop
                src={Animation}
                style={{ minHeight: '200px', minWidth: '200px', maxHeight: '400px', maxWidth: '400px' }}
                direction={-1}
            >
            </Player>
        </Box>
        <Box
            display='flex'
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={2}
        >
            <Typography variant='h1' color={'#555866'} fontSize={'40px'} fontFamily={'Picadilly'}>Não encontramos essa página.</Typography>
            <Typography variant='subtitle1' color={'#555866'} fontFamily={'Picadilly'}>Por favor, verifique se o endereço desejado está correto.</Typography>
            <GeneralComponents.CustomDefaultButton onClick={handleReturn} startIcon={<BsArrowLeftShort color='#fff' size={24}></BsArrowLeftShort>}>Retornar para o site</GeneralComponents.CustomDefaultButton>
        </Box>
    </Box>

}

export default NotFound;