import React, { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { IconButton, InputAdornment } from "@mui/material";

import { GeneralComponents } from "@components";

import { reducers, slices } from "@store";

import { showToastCustomErrorMessage, handleAxiosError, isFrom } from '@utils';

import { AuthTypes, BigBrotherTypes } from "@types";

import { BigBrother } from "@services";

import Auth from "./Auth";
import PasswordRecover from "./RecoverPassword";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm, SubmitHandler, Controller } from "react-hook-form"

import "./login.css";
import "react-toastify/dist/ReactToastify.css";

const Login: React.FC = () => {

  const lastUsedFeaturesOwner = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.lastUsed.user)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [userNeedsTwoFactorAuth, setUserNeedsTwoFactorAuth] = useState<boolean>(false)
  const [userNeedsRecoverPassword, setUserNeedsRecoverPassword] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<AuthTypes.UserData>()

  const cancelTwoFaVerification = () => {
    setUserNeedsTwoFactorAuth(false)
  }

  const cancelRecoverPassword = () => {
    setUserNeedsRecoverPassword(false)
  }

  const login = async ({ login, password }: AuthTypes.UserData, tokenReCaptcha: string | undefined) => {
    const response = await BigBrother.signIn({
      login,
      password,
      tokenReCaptcha,
      isFrom: isFrom()
    });

    return response.data
  }

  const handleLoginApiResponse = (loginApiResponse: BigBrotherTypes.LoginApiResponse) => {

    const { result } = loginApiResponse

    if (result && loginApiResponse.userInfo !== null) {
      reset()
      handleClearLastUsedNeed(loginApiResponse.userInfo.login)
      saveUserInfoToStorage(loginApiResponse)

      if (loginApiResponse.userInfo.parameters.TwoFactorAuthentication === "True") {
        setUserNeedsTwoFactorAuth(true)
        return
      }

      if (loginApiResponse.userInfo.parameters.TrocarSenha === "True") {
        localStorage.setItem('loginForForcedPasswordChange', loginApiResponse.userInfo.login)
        navigate('/auth/RecoverPassword')
        return
      }

    }
    else {
      handleLoginError(loginApiResponse.message)
    }
  }

  const createOnboardingControl = () => {
    localStorage.setItem('onboardingControl', JSON.stringify(
      {
        Dashboard: true,
        UserManagement: true,
        TokenManagement: true,
        Invoices: true,
        UsageSummary: true,
        DetailedUsageSummary: true,
        CurrentUsage: true,
        DocUpdate: true,
        FileTransfer: true,
        PontualConsult: {
          Type: true,
          SavedConsult: true,
          Api: true,
          Datasets: true,
          Condition: true,
          Documents: true,
          Review: true,
          Result: true
        }
      }))
  }

  const saveUserInfoToStorage = (loginApiResponse: BigBrotherTypes.LoginApiResponse) => {
    const {
      login,
      email,
      name,
      group,
      sessionId,
      isEnabled,
      isSystemAdmin,
      parameters: {
        ShowWelcomeBigCenter,
        TrocarSenha,
        TwoFactorAuthentication,
        FileTransfer
      } } = loginApiResponse.userInfo

    dispatch(slices.user.setLoginUserInfo({
      login,
      email,
      name,
      group,
      sessionId,
      isEnabled,
      isSystemAdmin,
      parameters: {
        ShowWelcomeBigCenter,
        TrocarSenha,
        TwoFactorAuthentication,
        FileTransfer 
      } }))
    dispatch(slices.lastUsed.setLastUsedFeaturesOwner(login))
  }

  const handleLoginError = (message: string) => {

    if (message === "Login disabled. Please contact your domain administrator.") {
      showToastCustomErrorMessage('Usuário desabilitado', 'Por favor, entre em contato com o administrador do domínio.')
      return
    }

    if (message === "Invalid user/password!" || !!!watch().login || !!!watch().password) {
      showToastCustomErrorMessage('Login ou senha inválidos!', 'Verifique se as credenciais passadas estão corretas.')
      return
    }

    if (!!!message) {
      showToastCustomErrorMessage('Ocorreu um erro inesperado.', 'Por favor, entre em contato com o nosso suporte.')
      return
    }

  };

  const handleShowPasswordClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setShowPassword(!showPassword)
  }

  const handleRecoverPasswordClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserNeedsRecoverPassword(true)
    reset()
  }

  const handleClearLastUsedNeed = (login: string) => {
    if (login !== lastUsedFeaturesOwner) {
      dispatch(slices.lastUsed.clearFeatures())
    }
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    try {
      const token = await executeRecaptcha('login');

      if (token) {
        return token
      }
    } catch (err: any) {
      showToastCustomErrorMessage('Erro ao verificar o ReCaptcha', 'Verifique sua conexão com a internet.')
    }

  }, [executeRecaptcha]);

  const handleFormSubmit: SubmitHandler<AuthTypes.UserData> = async (data, event) => {
    event?.preventDefault()

    const tokenReCaptcha = await handleReCaptchaVerify()

    try {
      setIsLoading(true)
      const loginApiResponse = await login(data, tokenReCaptcha)
      handleLoginApiResponse(loginApiResponse)
      setIsLoading(false)
    }
    catch (error: any) {
      handleAxiosError(error)
      setIsLoading(false)
    }
  };

  return (
    <main className="login-main-container">
      <div className="pre-auth-image-container"></div>
      <div className="pre-auth-grouped-container">
        <img src="https://bdcenter-files.bigdatacorp.com.br/Imagens/Layer_1-2.svg" className="dotnetwork-image"></img>
        {!userNeedsTwoFactorAuth && !userNeedsRecoverPassword && <section className="pre-auth-container">
          <div className="pre-auth-presentation-content">
            <h1 className="pre-auth-presentation-title">Boas-vindas!</h1>
            <p className="pre-auth-presentation-paragraph">Insira seus dados para acessar a plataforma</p>
          </div>
          <form className="user-form-container" onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="login-fields-container">
              <div className="login-fields">
                <label className="pre-auth-label">Login</label>
                <Controller
                  name="login"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GeneralComponents.CustomTextField
                      {...field}
                      fullWidth
                      type="text"
                      placeholder="Insira seu login"
                    />

                  )}
                />
              </div>
              <div className="login-fields">
                <label className="pre-auth-label">Senha</label>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <GeneralComponents.CustomTextField
                      {...field}
                      fullWidth
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Insira sua senha"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">
                          <IconButton onClick={handleShowPasswordClick} size='small'>
                            {showPassword ? <AiOutlineEyeInvisible color="#7D8285" size={24} /> : <AiOutlineEye color="#7D8285" size={24} />}
                          </IconButton>
                        </InputAdornment>
                      }} />
                  )}
                />
              </div>
            </div>
            <div className="login-options-container">
              <button type="button" onClick={handleRecoverPasswordClick} className="recover-password-button">Esqueci minha senha</button>
              <button className="form-access-button" type="submit" disabled={!!isLoading}>{!!isLoading ? 'Acessando...' : 'Acessar'}</button>
            </div>
          </form>
        </section>
        }
        {userNeedsTwoFactorAuth && <div className="pre-auth-container"><Auth handleReturnToLogin={cancelTwoFaVerification} /></div>}
        {userNeedsRecoverPassword && <PasswordRecover handleReturnToLogin={cancelRecoverPassword} />}
      </div>
    </main >
  );
}

export default Login;

