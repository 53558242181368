import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

import { ReactComponent as SVGSpinner } from "./spinner.svg";

const Title = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

const Subtitle = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100],
  position: "absolute"
}));

const BigScan = styled(Box)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.up("md")]: {
    width: 400
  }
}));

const SmallScan = styled(Box)(({ theme }) => ({
  display: "none",
  width: 100,
  filter: "grayscale(100%)",
  opacity: 0.3,
  [theme.breakpoints.up("md")]: {
    display: "block",
    width: 200
  }
}));

const Spinner = styled(SVGSpinner)(({ rotate }: { rotate: number }) => ({
  transform: `rotate(${rotate}deg)`,
  transition: "transform 2s"
}));

export {
  Title,
  Subtitle,
  BigScan,
  SmallScan,
  Spinner
}