import React from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { Button, Chip, Dialog, Paper, TextField, InputFile } from "@components";
import {
  FileContent,
  FileImage,
  Error
} from "./styles";

import { useFormContext } from "react-hook-form";

import getValueFromPath from "lodash/get";
import { FaTrash } from "react-icons/fa";

type ComponentType = {
  type: "file" | "link",
  name: string | any,
  identifier?: string
}

const Component: React.FC<ComponentType> = ({ type, name, identifier }) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = React.useState(false);

  const {
    setValue,
    watch,
    setError,
    clearErrors,
    resetField,
    formState: { errors }
  } = useFormContext();
  const currentWatch = watch();
  const currentData = getValueFromPath(currentWatch, name) || {};
  const error: any = getValueFromPath(errors, name);

  const validateLink = (link: string) => {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(link);
  }

  const handleInsertLink = () => {
    const isValidLink = validateLink(currentData?.link);

    if (isValidLink) {

      setValue(
        name,
        {
          ...currentData,
          inserted: true
        }
      )

      clearErrors(name)
    } else {
      setError(name, { type: "custom", message: "Insira um link de imagem válido" })
    }
  }

  const handleDelete = () => {
    setOpenDialog(true);
  }

  const onCancelDialog = () => {
    setOpenDialog(false);
  }

  const onConfirmDialog = () => {
    resetField(name);
    onCancelDialog();
  }

  if (type === "file") {
    return (
      <InputFile
        name={name}
        identifier={identifier}
        maximumSize={10}
        formats={["jpg", "jpeg", "png", "pdf", "tiff"]}
        description={
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center" gap={2}>
            <Typography variant="body2" color="neutral.200">
              Enviar <b>o documento separadamente aumenta significativamente o tempo de resposta</b> da consulta.
            </Typography>
            <Typography variant="body2" color="neutral.200">
              Para um melhor resultado envie a imagem do documento aberto em pé.
            </Typography>
          </Box>
        }
      />
    )
  }
  return (
    <>
      <Dialog
        open={openDialog}
        title="Excluir documento"
        confirmText="Excluir"
        cancelText="Cancelar"
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
      >
        Deseja <b>realmente</b> excluir o documento?
      </Dialog>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          alignItems={'center'}
          display='flex'
          width="fit-content"
          fontWeight="bold"
          gap={4}
        >
          <Chip>{identifier}</Chip>
          <Button
            onClick={handleDelete}
            color="secondary"
            size="small"
            StartIcon={
              <FaTrash
                size={18}
                color={!!currentData?.inserted ? theme.palette.negative?.pure : theme.palette.neutral[100]}
              />
            }
            disabled={!!!currentData?.inserted}
          />
        </Box>
        {
          currentData?.inserted ? (
            <FileContent>
              <Box display="flex" alignItems="center" justifyContent="center" width="100%">
                <FileImage src={currentData?.link} />
              </Box>
            </FileContent>
          ) : (
            <Paper variant="outlined">
              <Box display="flex" flexDirection="column" gap={2} p={4}>
                <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center" gap={4}>
                  <TextField placeholder="https://RG.png" name={`${name}.link`} fullWidth />
                  <Button
                    color="secondary"
                    onClick={handleInsertLink}
                  >
                    Inserir
                  </Button>
                </Box>
                <Error>
                  <>
                    {error?.message || error?.inserted?.message}
                  </>
                </Error>
              </Box>
            </Paper>
          )
        }
      </Box>
    </>
  )
}

export default Component;