import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface UserPaginationInfo {
    page: number,
    limit: number;
    skip: number;
    totalRecords: number;

}
  
const initialState: UserPaginationInfo = {
    page: 1,
    limit: 12,
    skip: 0,
    totalRecords: 0
}

const userPaginationInfo = createSlice({
    name: 'userPaginationInfo',
    initialState,
    reducers: {
      setUserPaginationInfo: (state, action: PayloadAction<UserPaginationInfo>) => {
        return {...state, ...action.payload}
      }
    }
});

export const { setUserPaginationInfo } = userPaginationInfo.actions;

export default userPaginationInfo.reducer;