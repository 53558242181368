import { createContext, useContext } from "react";
import { useNotificationCenter } from "react-toastify/addons/use-notification-center";

interface Data {
    user: string,
    message: string,
    description: string
}
const NotificationCenterContext = createContext<ReturnType<typeof useNotificationCenter<Data>>>({} as any);

function useNotificationCenterContext() {
    return useContext(NotificationCenterContext);
}

function NotificationCenterProvider(props: any) {
    const notificationCenter = useNotificationCenter<Data>({
        data: JSON.parse(localStorage.getItem('notifications') || '[]'),
        filter: (notification) => notification.id.toString().includes('overdue-invoice')
    });

    return <NotificationCenterContext.Provider {...props} value={notificationCenter}>
        {props.children}
    </NotificationCenterContext.Provider>;
}

export { NotificationCenterProvider, useNotificationCenterContext}