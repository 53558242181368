import { Theme, styled } from "@mui/material/styles";
import { Table as TableMaterial } from "@mui/material";

const Table = styled(TableMaterial)(({ elevation }: { elevation: boolean }) => ({
  // borderRadius: 8,
  boxShadow: elevation ? "0 0 3px rgba(0, 0, 0, 0.3)" : "none",
  '& thead td:nth-child(1)': {
    width: '50%'
  }
}))

export {
  Table
}