import { IconButton, InputAdornment, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';

import './createTokenSection.css'
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showToastCustomErrorMessage, showToastCustomSuccessMessage, removeAllExceptNumbers, handleAxiosError } from '@utils';
import { BigBrother } from '@services';
import { BigBrotherTypes } from '@types';

import { useDispatch, useSelector } from 'react-redux';

import { reducers, slices } from "@store";

import { TokenHourButtonGroup, TokenHoursButton } from './TokenHoursOptions';

import { GeneralComponents, TokenComponents } from "@components";

import { BsPlus } from 'react-icons/bs';
import { AiOutlineCloseCircle, AiOutlineEye, AiOutlineArrowLeft, AiOutlineInfoCircle } from 'react-icons/ai';
import { MdOutlineContentCopy } from 'react-icons/md';
import LoadingSection from './LoadingSection';
import CreatedTokenNote from './CreatedTokenNote';
import { isDesktopWidth } from '@constants';

interface CreateTokenSectionProps {
  className: string;
  closeCreateTokenSection: () => void
}

const CreateTokenSection: React.FC<CreateTokenSectionProps> = ({ className, closeCreateTokenSection }: CreateTokenSectionProps) => {

  const [showToken, setShowToken] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isTokenCreated, setIsTokenCreated] = useState(false)


  const [inputExpirationHours, setInputExpirationHours] = useState<string>()
  const [tokenId, setTokenId] = useState<string>('')
  const [tokenKey, setTokenKey] = useState<string>('')

  const [isTokenExpirationHoursValid, setIsTokenExpirationHoursValid] = useState<boolean>(true)

  const tokenKeyRef = useRef(null)

  const hourDuration = 1
  const dayDuration = 24
  const weekDuration = 168
  const monthDuration = 720
  const yearDuration = 8760

  const dispatch = useDispatch()

  const resetSectionState = () => {
    setIsLoading(false)
    setIsTokenExpirationHoursValid(true)
  }

  const handleTokenSectionClick = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
  }

  const handleShowOrHideToken = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    setShowToken(!showToken)
  }

  const handleInputHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsTokenExpirationHoursValid(true)
    setInputExpirationHours(removeAllExceptNumbers(event.target.value))
  }
  
  const handleClearExpirationHoursInput = (event: React.MouseEvent<any>) => {
    event.stopPropagation()
    setInputExpirationHours('')
  }

  const toStringWithIsNaNCheck = (inputExpirationHoursAsNumber: number, durationInHours: number) => {
    if (isNaN(inputExpirationHoursAsNumber)) {
      showToastCustomErrorMessage('Erro ao inserir horas', 'Você precisa informar as horas em números válidos.')
      return ''
    } else {
      return (inputExpirationHoursAsNumber + durationInHours).toString()
    }
  }

  const handleCreatedTokenReturnButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setIsTokenCreated(false)
    closeCreateTokenSection()
    dispatch(slices.createToken.setReloadTokenList(false))

    if (!!!isDesktopWidth) {
      dispatch(slices.createToken.setReloadTokenList(true))
    }
  }

  const createToken = async () => await BigBrother.createToken({
    expires: parseInt(inputExpirationHours ?? '0'),
    products: ['BIGBOOST', 'BIGID']
  })

  const handleCopyTokenButtonClick = () => {
    showToastCustomSuccessMessage('Chave token copiada!', 'Seu token foi copiado para a área de transferência. Lembre-se de salvá-lo em um local seguro.')
    navigator.clipboard.writeText(tokenKey)
  }

  const tryToCreateToken = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    try {
      setIsLoading(true)
      const { data: apiBigBrotherResponseData } = await createToken()
      handleCreateTokenApiResponse(apiBigBrotherResponseData)
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  const handleCreateTokenApiResponse = (apiResponseData: BigBrotherTypes.GenerateTokenApiResponse) => {

    if (apiResponseData.message === "Invalid session.") {

      showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça login novamente.", 'session-timeout')
      setIsLoading(false)
      dispatch(slices.user.setUserInitialState())

    } else if (apiResponseData.message === 'The parameters sessionId and expires must be filled in') {

      checkFieldsValidity()
      showToastCustomErrorMessage('Erro na geração do token', 'Verifique se os campos foram preenchidos corretamente.')
      setIsLoading(false)

    } else if (apiResponseData.message === 'You must enter a valid number of hours until expiration') {

      showToastCustomErrorMessage('Erro na geração do token', 'Você precisa fornecer uma expiração válida para o token.')
      setIsLoading(false)

    }
    else if (!!!apiResponseData.success) {
      showToastCustomErrorMessage('Erro na geração do token', `Mensagem do erro: ${apiResponseData.message}`)
      setIsLoading(false)
    } else {

      resetSectionState()
      setTokenId(apiResponseData.tokenID)
      setTokenKey(apiResponseData.token)
      setIsTokenCreated(true)
      dispatch(slices.createToken.setReloadTokenList(true))

    }
  }

  const checkFieldsValidity = () => {
    if (inputExpirationHours === undefined || inputExpirationHours.length === 0 || parseInt(inputExpirationHours) === 0) {
      setIsTokenExpirationHoursValid(false)
    }
  }

  const handleInputHoursChangeFromButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const selectedButton = event.currentTarget.id
    const inputExpirationHoursAsNumber = inputExpirationHours ? parseInt(inputExpirationHours) : 0
    switch (selectedButton) {
      case 'hour':
        setInputExpirationHours(toStringWithIsNaNCheck(inputExpirationHoursAsNumber, hourDuration))
        break
      case 'day':
        setInputExpirationHours(toStringWithIsNaNCheck(inputExpirationHoursAsNumber, dayDuration))
        break
      case 'week':
        setInputExpirationHours(toStringWithIsNaNCheck(inputExpirationHoursAsNumber, weekDuration))
        break
      case 'month':
        setInputExpirationHours(toStringWithIsNaNCheck(inputExpirationHoursAsNumber, monthDuration))
        break
      case 'year':
        setInputExpirationHours(toStringWithIsNaNCheck(inputExpirationHoursAsNumber, yearDuration))
        break
      default:
        showToastCustomErrorMessage('Erro ao inserir horas', 'Não foi possível adicionar horas a partir do botão selecionado.')
    }
  }

  return <section className={className} onMouseDown={handleTokenSectionClick}>
    {!isTokenCreated ?
      <div className='create-token-content'>
        <div className='create-token-header-container'>
          <h2 className='create-token-title'>Gerar novo token</h2>
          <hr className='create-token-divider'></hr>
        </div>

        {!isLoading ? <div className='create-token-main-content'>
          <div className='expiration-hours-container'>

            <p className='create-token-expiration-paragraph'>Defina a duração do período de atividade do token, em horas. Você pode adicionar a quantidade desejada utilizando as opções abaixo, ou inserir um valor manualmente.</p>
            <TokenHourButtonGroup variant='text' sx={{ flexWrap: 'wrap' }}>
              <TokenHoursButton size='small' id='hour' startIcon={<BsPlus color='#555866' size={20} />} onClick={handleInputHoursChangeFromButton}>1 hora</TokenHoursButton>
              <TokenHoursButton size='small' id='day' startIcon={<BsPlus color='#555866' size={20} />} onClick={handleInputHoursChangeFromButton}>24h <span className='hour-reference'>(1 dia)</span></TokenHoursButton>
              <TokenHoursButton size='small' id='week' startIcon={<BsPlus color='#555866' size={20} />} onClick={handleInputHoursChangeFromButton}>168h <span className='hour-reference'>(1 sem.)</span></TokenHoursButton>
              <TokenHoursButton size='small' id='month' startIcon={<BsPlus color='#555866' size={20} />} onClick={handleInputHoursChangeFromButton}>720h <span className='hour-reference'>(1 mês)</span></TokenHoursButton>
              <TokenHoursButton size='small' id='year' startIcon={<BsPlus color='#555866' size={20} />} onClick={handleInputHoursChangeFromButton}>8.760h <span className='hour-reference'>(1 ano)</span></TokenHoursButton>
            </TokenHourButtonGroup>

            <div className='create-token-fields-container'>
              <label htmlFor='expiration-hours'>Tempo de expiração (horas)</label>
              <GeneralComponents.CustomTextField
                id='expiration-hours'
                fullWidth
                type='text'
                value={inputExpirationHours}
                onChange={handleInputHoursChange}
                variant='outlined'
                placeholder='Ex.: 24, 48...'
                helperText={isTokenExpirationHoursValid ? '' : 'Campo obrigatório'}
                InputProps={{
                  endAdornment: <span className='input-adornments'>
                    <InputAdornment className='text-adornment' position="start">
                      horas
                    </InputAdornment>
                    <InputAdornment position="end">
                      <IconButton onClick={handleClearExpirationHoursInput} size='small'>
                        <AiOutlineCloseCircle color='#7D8285' size={24} />
                      </IconButton>
                    </InputAdornment>
                  </span>
                }}>
              </GeneralComponents.CustomTextField>
            </div>

          </div>

          <div className='create-token-options-container'>
            <GeneralComponents.CancelButton
              variant='text'
              onClick={closeCreateTokenSection}
              disabled={isLoading}
              className='create-token-cancel-button'>
              Cancelar
            </GeneralComponents.CancelButton>
            <GeneralComponents.CustomDefaultButton
              variant='contained'
              onClick={tryToCreateToken}
              disabled={!!!isTokenExpirationHoursValid}
              startIcon={<FontAwesomeIcon icon={faPlus} />}
              className='create-token-button'>
              Gerar token
            </GeneralComponents.CustomDefaultButton>
          </div>

        </div>
          :
          <LoadingSection />
        }
      </div>
      :
      <div className='create-token-content'>
        <div className='create-token-header-container'>
          <h2 className='create-token-title'>Gerar novo token</h2>
          <hr className='create-token-divider'></hr>
        </div>
        <div className='created-token-container'>
          <div className='content-scroll'>
            <div className='created-token-content'>
              <div className='created-token-content-presentation'>
                <h3 className='created-token-title'>Seu token foi criado!</h3>
                <p>Confira o número de identificação e a chave do token abaixo:</p>
              </div>
              <CreatedTokenNote />
              <div className='created-token-card'>
                <div className='token-id-container'>
                  <div className='token-id-label-container'>
                    <label htmlFor='token-id' className='token-id-label'>ID</label>
                    <Tooltip placement='right' title="É a identificação do seu token. Você pode usá-lo para buscá-lo na tela de consultas.">
                      <IconButton size='small' sx={{ padding: 0, alignSelf: 'center' }}>
                        <AiOutlineInfoCircle color='#0048B2' size={16} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <span className='token-id-span'>{tokenId}</span>
                </div>
                <div className='token-key-container'>
                  <div className='token-key-title-and-options'>
                    <label htmlFor='token-key' className='token-key-label'>Chave token</label>
                    <div className='token-key-options'>
                      <TokenHoursButton size='small' onClick={handleShowOrHideToken} variant='text' startIcon={<AiOutlineEye color='#555866' size={16} />}>Visualizar token</TokenHoursButton>
                      <TokenHoursButton size='small' onClick={handleCopyTokenButtonClick} variant='text' startIcon={<MdOutlineContentCopy color='#555866' size={16} />}>Copiar token</TokenHoursButton>
                    </div>
                  </div>
                  <TokenComponents.CustomTokenKeyTextField
                    id='token-key'
                    variant='outlined'
                    defaultValue={tokenKey.slice(0, 44).concat('...')}
                    ref={tokenKeyRef}
                    type={showToken ? 'text' : 'password'}
                    InputProps={{
                      readOnly: true
                    }}
                  >
                  </TokenComponents.CustomTokenKeyTextField>
                </div>
              </div>
              <div className='create-token-options-container'>
                <GeneralComponents.CustomDefaultButton variant='contained' onClick={handleCreatedTokenReturnButtonClick} startIcon={<AiOutlineArrowLeft color='white' size={16} />} >Voltar para consultas</GeneralComponents.CustomDefaultButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    }
  </section>;
}

export default CreateTokenSection;