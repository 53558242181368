import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
    isOpen: boolean;
    isOpenInMobile?: boolean;
}

const initialState: NavbarState = {
    isOpen: false,
    isOpenInMobile: false
}
  

  const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {
      setIsNavbarOpen: (state, action: PayloadAction<boolean>) => {
        state.isOpen = action.payload;
      },
      setIsMobileNavbarOpen: (state, action: PayloadAction<boolean>) => {
        state.isOpenInMobile = action.payload;
      }
    }
  });

export const { setIsNavbarOpen, setIsMobileNavbarOpen} = navbarSlice.actions;

export default navbarSlice.reducer;