import React from 'react';
import { reducers } from '@store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

export default function FileTransferCheck() {
  const fileTransferEnabled = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.parameters.FileTransfer);

  if (fileTransferEnabled === "False" || !!!fileTransferEnabled) {
    return <Navigate to="/dashboard" />
  }

  return <Outlet />
}

