
import { showToastCustomErrorMessage, showToastCustomWarningMessage } from "./ToastMessages"


const handleAxiosError = (error: any) => {

  if (error?.code === 'ERR_NETWORK') {
    showToastCustomWarningMessage('Ocorreu um problema de conexão.', 'Verifique sua conexão com a internet e tente novamente.', 'err-network')
    return
  }

  if (!!error?.response?.status) {

    if(error?.response?.status === 504){
      showToastCustomErrorMessage('Erro na requisição', `Ocorreu um erro na requisição. Por favor, tente novamente.`)
      return
    }

    showToastCustomErrorMessage('Erro na requisição', `Status do erro: ${error.response.status}. Código do erro: ${error.code}`)
    return
  }

  if (!!error?.response?.data) {

    if (error?.response?.data?.message === 'Invalid session.') {
      showToastCustomErrorMessage('Sessão expirada', 'Sua sessão expirou. Por favor, faça o login novamente.', 'session-timeout')
      return 'Invalid session.'
    }
  }

  showToastCustomErrorMessage('Erro no axios', error?.response?.data?.message)

 }

export default handleAxiosError;