export default [
  {
    name: "blackAndWhite",
    title: "Preto e branco",
    description: "",
    handleValue: (value: any) => value ? "Sim" : "Não"
  },
  {
    name: "brightness",
    title: "Brilho",
    successDescription: "A imagem tem um ótimo brilho",
    warningDescription: "A imagem não tem um brilho adequado",
    handleStatus: (value: number) => value < 0.3 ? "warning" : "success"
  },
  {
    name: "contrast",
    title: "Contraste",
    successDescription: "A imagem tem um ótimo constraste",
    warningDescription: "A imagem não tem um contraste adequado",
    handleStatus: (value: number) => value < 0.5 ? "warning" : "success"
  },
  {
    name: "sharpness",
    title: "Nitidez da imagem",
    successDescription: "A imagem tem uma ótima Nitidez",
    warningDescription: "A imagem não tem uma nitidez adequada",
    handleStatus: (value: number) => value < 120 ? "warning" : "success"
  },
  {
    name: "imageDistanceOk",
    title: "Distância da imagem",
    successDescription: "A distância da imagem está ótima",
    warningDescription: "A distância da imagem não está adequada",
    handleStatus: (value: boolean) => !value ? "warning" : "success"
  },
  {
    name: "overSizedImage",
    title: "Limite de proporção da imagem",
    successDescription: "A imagem está dentro do limite de proporção",
    warningDescription: "A imagem está acima do limite de proporção",
    handleStatus: (value: boolean) => value ? "warning" : "success"
  },
  {
    name: "downSizedImage",
    title: "Proporção mínima da imagem",
    successDescription: "A imagem tem uma proporção mínima",
    warningDescription: "A imagem está abaixo da proporção mínima",
    handleStatus: (value: boolean) => value ? "warning" : "success"
  },
  {
    name: "imageFormat",
    title: "Formato da imagem",
    description: "",
    handleValue: (value: any) => value.toUpperCase()
  },
  {
    name: "imageIsBig",
    title: "Tamanho da imagem",
    successDescription: "A imagem está em um ótimo tamanho",
    warningDescription: "A imagem está acima do tamanho adequado",
    handleStatus: (value: boolean) => value ? "warning" : "success"
  },
  {
    name: "imageSizeInKB",
    title: "Tamanho da imagem",
    description: "",
    handleValue: (value: any) => `${value} KB`
  },
  {
    name: "poorImage",
    title: "Padrão de recomendação",
    successDescription: "A imagem está dentro dos padrões de recomendação",
    warningDescription: "A imagem não atende os padrões de recomendação",
    handleStatus: (value: boolean) => value ? "warning" : "success"
  },
  {
    name: "ratio",
    title: "Proporção de tela da imagem",
    description: "",
    handleValue: (value: any) => value
  },
  {
    name: "resolution",
    title: "Resolução",
    description: "",
    handleValue: ({ width, height }: any) => !!width && !!height ? `${width}px x ${height}px` : undefined,
    mergeValues: ["width", "height"]
  }
]
