import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import {  GeneralComponents } from '@components';
import handleAxiosError from '../../../utils/axiosError';
import { showToastCustomErrorMessage } from '@utils';
import { BillingTypes } from '@types';
import { Billing } from '@services';

const CurrentUsageDetails: React.FC = () => {
    
    const { open, params } = useSelector((state: reducers.RootState) => state.currentUsageDetailsReducer)

    const dispatch = useDispatch()
    const sideSectionRef = useRef(null);

  const handleSeeDetailsButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    tryToSeePartialDetails()
  };

    const getPartialDetail = async () => await Billing.getPartialDetail({
        Product: params.Product,
        PartialLastDay: parseInt(params.PartialLastDay)
    }) 

    const tryToSeePartialDetails = async () => {
        try{
            const {data: getPartialDetailResponse} = await getPartialDetail()
            handleGetPartialDetailResponse(getPartialDetailResponse)
        }catch(error: any){
            handleAxiosError(error)
        }
    }

    const handleGetPartialDetailResponse = (getPartialDetailResponse: BillingTypes.GetPartialDetailApiResponse) => {
        if(!getPartialDetailResponse.Sucess && getPartialDetailResponse.Message === "INVALID_SESSION_ID"){
        showToastCustomErrorMessage('Sessão expirada', 'Sua sessão expirou. Por favor, faça o login novamente.', 'session-timeout')
        dispatch(slices.user.setUserInitialState())
        }else{
        dispatch(slices.seeDetails.setSeeDetailsInfo({Consumption: getPartialDetailResponse.Consumption, showModal: true}))
        }
    }

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.currentUsageDetails.setShowCurrentUsageDetailsSection(false))
    }

    return <GeneralComponents.MobileSideSection
        sideSectionTitle='Resumo de uso'
        mainButtonType='default'
        mainButtonText='Ver detalhes'
        mainButtonAction={handleSeeDetailsButtonClick}
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
         <div className='invoice-details-content'>
            <div className='invoice-details-data'>
                <div className='mobile-side-section-group'>
                    <label>Produto: </label>
                    <GeneralComponents.ProductTag params={params?.Product}/>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições gratuitas: </label>
                    <span>{params?.FreeRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Requisições pagas: </label>
                    <span>{params?.PaidRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Total de requisições: </label>
                    <span>{params?.TotalRequests.toLocaleString('pt-BR')}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço médio: </label>
                    <span>{parseFloat(params?.AveragePrice).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Descontos: </label>
                    <span>{parseFloat(params?.VolumeDiscount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Preço: </label>
                    <span>{parseFloat(params?.Price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>
                </div>
            </div>
        </div>
    </GeneralComponents.MobileSideSection>

}

export default CurrentUsageDetails;