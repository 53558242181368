import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface userProfileData {
    showUserProfileSection: boolean;
    outsideClickOccurred: boolean;
}
  
const initialState: userProfileData = {
    showUserProfileSection: false,
    outsideClickOccurred: false,
}

const userProfile = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {
      setShowUserProfileSection: (state, action: PayloadAction<boolean>) => {
        state.showUserProfileSection = action.payload
      },
      setOutsideClickOccurred: (state, action: PayloadAction<boolean>) => {
        state.outsideClickOccurred = action.payload
      }
    }
});

export const { setShowUserProfileSection, setOutsideClickOccurred} = userProfile.actions;

export default userProfile.reducer;