import { Button } from "./styles";

import IButtonProps from "./interface";

const Component: React.FC<IButtonProps> = ({
  children,
  disableElevation = true,
  variant = "contained",
  color = "primary",
  size = "medium",
  StartIcon,
  EndIcon,
  ...props
}) => {
  return (
    <Button
      disableElevation
      disableRipple
      variant={variant}
      color={color}
      size={size}
      {...props}
    >
      {
        StartIcon && (StartIcon)
      }
      {children}
      {
        EndIcon && (EndIcon)
      }
    </Button>
  )
}

export default Component;