import React from "react";

import { Box, Tab, Tabs, Typography } from "@mui/material";
import { TextField, Button, Paper, CheckboxTest, Onboarding, Notification } from "@components";
import { Header } from "../../components";
import { HeaderDivider, Dataset, Loading } from "./components";
import { BiSearch } from 'react-icons/bi';

import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useSelector } from "react-redux";
import { reducers } from "@store";
import { options, steps } from "@constants";
import { Platform } from "@services";
import { useFetch } from "@hooks";
import { handleString } from "@utils";

import uniq from "lodash/uniq"
import DialogContainer from "../../../components/DialogContainer";
import datasetsMostUsedJson from "../datasetsMostUsed.json";

const Openned = () => {
  const { resetField, getValues, formState: { errors } } = useFormContext();
  const formFilter = useForm();
  const { data: { api } } = useSelector((state: reducers.RootState) => state.formWizard);
  const {
    showOnboardingTooltip
  } = useSelector((state: reducers.RootState) => state.onboardingTooltipReducer)
  const apiEnglishName = options.pontualConsult.apis.find(item => item.value === api)?.englishName || "";
  const [tabSelected, setTabSelected] = React.useState("");
  const [datasetsFiltered, setDatasetsFiltered] = React.useState([]);
  const [showChangeTabDialog, setShowChangeTabDialog] = React.useState<boolean>(false);
  const [nextTab, setNextTab] = React.useState("");

  const apiPascalCase = handleString.snakeToPascal(apiEnglishName);

  const listOfDatasetsMostUsed = datasetsMostUsedJson[apiPascalCase as keyof typeof datasetsMostUsedJson];

  const apisWithTabs = ["ondemand", "marketplace"];
  const hasTabs = apisWithTabs.includes(api);

  const { data, error, isLoading } = useFetch(
    Platform.GetDatasets,
    {
      Api: apiEnglishName
    }
  );

  const datasetsRequest: any = Object.values(data?.Datasets?.["PT-BR"]?.[apiPascalCase] || []);

  const datasets = datasetsRequest.map((dataset: any) => !!dataset?.IsEnabled && {
    value: dataset?.Name,
    label: dataset?.CommonName?.["pt-br"],
    properties: { version: dataset?.Version }
  }).filter((dataset: any) => dataset && !!!listOfDatasetsMostUsed?.includes(dataset.value))
    ?.sort((actual: any, prev: any) => (
      actual.label > prev.label ? 1 : actual.label < prev.label ? -1 : 0
    ));

  const datasetsMostUsed = !!listOfDatasetsMostUsed?.length ? (
    datasetsRequest.map((dataset: any) => (
      (!!dataset?.IsEnabled && !!listOfDatasetsMostUsed?.includes(dataset?.Name)) &&
      {
        value: dataset?.Name,
        label: dataset?.CommonName?.["pt-br"],
        properties: { version: dataset?.Version }
      }
    ))
      .filter((dataset: any) => dataset)
  ) : [];

  const typesOfDatasets: Array<string> | any = uniq(datasets.map((dataset: any) => dataset.value.split("_").pop()) || [])
    .sort((actual, prev) => {
      const countTypeOfDatasetActual = datasets.filter((dataset: any) => dataset.value.split("_").pop() === actual).length;
      const countTypeOfDatasetPrev = datasets.filter((dataset: any) => dataset.value.split("_").pop() === prev).length;

      return countTypeOfDatasetActual > countTypeOfDatasetPrev ? -1
        : countTypeOfDatasetActual < countTypeOfDatasetPrev ? 1
          : 0;
    });

  const hasError = !!error || (data?.Status?.Code === -110);

  React.useEffect(() => {
    const newDatasets = (hasTabs ? filterDatasetsByType(datasets) : datasets);

    setTabSelected(hasTabs && !!!tabSelected ? typesOfDatasets[0] : tabSelected);
    setDatasetsFiltered(newDatasets || [])
  }, [data, tabSelected])

  const filterDatasetsByType = (datasets: any) => {
    return datasets.filter((dataset: any) => {
      const datasetSplitted = dataset.value.split("_");
      return datasetSplitted[datasetSplitted.length - 1] === tabSelected
    }) || [];
  }

  const handleSearch = (values: any, e: any) => {
    e.preventDefault();

    const search = values?.search || "";

    if (!!search?.length) {
      const newDatasets = hasTabs ? filterDatasetsByType(datasets) : datasets;
      const searchSplitted = search.split(" ");

      setDatasetsFiltered(newDatasets.filter((item: any) => {
        const labelNormalized = item.label.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        const hasKeyWord = !!searchSplitted.find((keyWord: string) => {
          const keyWordNormalized = keyWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

          return labelNormalized.includes(keyWordNormalized);
        });

        return hasKeyWord;
      }))


      return;
    }

    setDatasetsFiltered(datasets)
  };

  const handleShowChangeTabDialog = (event: React.SyntheticEvent, newValue: string) => {
    const { datasets: selectedDatasets } = getValues();
    const hasDatasetsSelected = !!Object.keys(selectedDatasets || {})?.length || false;

    if (hasDatasetsSelected) {

      setNextTab(newValue);
      setShowChangeTabDialog(true);
      return;
    }

    setTabSelected(newValue);
  }

  const handleConfirmChangeTabDialog = () => {
    resetField("datasets");
    setTabSelected(nextTab);
    setShowChangeTabDialog(false)
  }

  const handleCancelChangeTab = () => {
    setShowChangeTabDialog(false)
  }

  return (
    <>
      <Paper>
        {hasTabs && (
          <Box sx={{ width: '100%' }}>
            <Tabs
              value={tabSelected}
              onChange={handleShowChangeTabDialog}
              aria-label="wrapped label tabs example"
            >
              {
                typesOfDatasets.map((type: any) => (
                  <Tab
                    value={type}
                    label={type}
                    wrapped
                  />
                ))
              }
            </Tabs>
          </Box>
        )
        }
        <Box display="flex" flexDirection="column" p={6} gap={6}>
          <Box display="flex" justifyContent="space-between" alignItems="flex-end">
            <Header
              title="O que deve ser consultado?"
              subtitle="Marque as caixas com as informações que você precisa na sua consulta."
            />
            <FormProvider {...formFilter}>
              <Box display="flex" alignItems="center" justifyContent="center" gap={2} width={500} id="search-dataset">
                <Box width="100%">
                  <TextField placeholder="Busque um dataset" name="search" />
                </Box>
                <Box>
                  <Button color="secondary" StartIcon={<BiSearch size={24} />} onClick={formFilter.handleSubmit(handleSearch)} />
                </Box>
              </Box>
            </FormProvider>
          </Box>

          <Box id="choose-dataset">
            <CheckboxTest.Container name="datasets">
              {
                !!datasetsMostUsed.length && (
                  <>
                    <HeaderDivider title="Datasets mais utilizados" helpText="Datasets mais utilizados" />
                    <Box>
                      {
                        (
                          !!isLoading
                        ) ? (
                          <Loading />
                        ) : (
                          !!!isLoading && !!datasets
                        ) ? (
                          <>
                            {
                              datasetsMostUsed.map((dataset: any, index: any) => (
                                <Box
                                  id={`dataset-${index}`}
                                  key={dataset?.value}
                                >
                                  <Dataset
                                    label={dataset?.label}
                                    value={dataset?.value}
                                    properties={dataset?.properties}
                                  />
                                </Box>
                              ))
                            }
                          </>
                        ) : null
                      }
                    </Box>
                  </>
                )
              }

              <HeaderDivider title="Datasets" helpText="Datasets" />
              <Box maxHeight={showOnboardingTooltip ? "auto" : 500} overflow={showOnboardingTooltip ? "hidden" : "auto"} sx={{ '&::-webkit-scrollbar': { width: '10px' } }}>
                {
                  (
                    !!isLoading
                  ) ? (
                    <Loading />
                  ) : (
                    hasError
                  ) ? (
                    <Notification title="Erro" description="Houve um problema ao consultar os datasets dessa api" variant="danger" />
                  ) : (
                    !!!datasets
                  ) ? (
                    <Notification title="Nenhum dataset" description="Não há dataset disponível para esta api" variant="information" />
                  ) : (
                    <>
                      {
                        datasetsFiltered.map((dataset: any, index) => (
                          <Box
                            id={`dataset-${index}`}
                            key={dataset?.value}
                          >
                            <Dataset
                              label={dataset?.label}
                              value={dataset?.value}
                              properties={dataset?.properties}
                            />
                          </Box>
                        ))
                      }
                    </>

                  )
                }
              </Box>
            </CheckboxTest.Container>
            <Typography color="red">
              <>
                {errors?.datasets?.message && errors?.datasets?.message}
              </>
            </Typography>
          </Box>
        </Box>
      </Paper>

      <DialogContainer
        open={showChangeTabDialog}
        title="Trocar de aba"
        description="Ao prosseguir, os datasets selecionados serão perdidos."
        handleConfirm={handleConfirmChangeTabDialog}
        handleCancel={handleCancelChangeTab}
      />

      <Onboarding
        steps={steps.PontualConsult.Datasets}
        controlPath='PontualConsult.Datasets'
      />
    </>
  )
}

export default Openned;