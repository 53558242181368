import React from "react";

import { Box, Skeleton } from "@mui/material";
import { Paper, TextField, Button, RadioGroup, Onboarding } from "@components";
import { Header, Title, Card, CardTitle, CardKey, CardValue } from "./styles";

import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { pontualConsult } from "@utils";

import { Platform } from "@services";
import { steps } from "@constants";
import { useFetch } from "@hooks";

const Openned = () => {
  const formFilter = useForm();
  const { watch } = useFormContext();

  const [list, setList] = React.useState([]);
  const [listFiltred, setListFiltred] = React.useState([]);

  const { data, error, isLoading } = useFetch(Platform.GetSavedConsults);

  React.useEffect(() => {

    if (data) {
      const views = data?.Views?.map((view: any) => {
        const viewNameSplitted = view?.ViewName.split("__");
        const viewName = viewNameSplitted?.[0];
        const condition = viewNameSplitted?.[1];

        return condition && { ...view, ViewName: viewName, ViewCondition: condition };
      }).filter((view: any) => view)

      setList(views || []);
      setListFiltred(views || [])
    }
  }, [data]);

  const handleSearch = (values: any, e: any) => {
    e.preventDefault();

    const search = values?.search || "";

    if (!!search?.length) {
      setListFiltred(list.filter((item: any) => item.ViewName
        .toLowerCase().includes(search.toLowerCase())))

      return;
    }

    setListFiltred(list)
  };

  const checked = watch().savedConsult;

  return (
    <>
      <Paper>
        <Box p={6}>
          <Box display="flex" flexDirection="column" gap={8} id="choose-consult">
            <Header>
              <Title>Inicie uma configuração de consulta já salva</Title>
              <FormProvider {...formFilter}>
                <Box display="flex" alignItems="center" justifyContent="center" gap={2} width={500} id="search-consult">
                  <Box width="100%">
                    <TextField placeholder="Busque o nome de uma consulta salva" name="search" />
                  </Box>
                  <Box>
                    <Button color="secondary" onClick={formFilter.handleSubmit(handleSearch)}>Filtrar</Button>
                  </Box>
                </Box>
              </FormProvider>
            </Header>
            <RadioGroup.Container name="savedConsult">
              <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} width="100%" gap={4}>
                {
                  !!isLoading ? (
                    <>
                      <Card variant="outlined" checked={false}>
                        <Box display="flex" flexDirection="column" p={4} gap={6}>
                          <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 14 }} />
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                          </Box>
                        </Box>
                      </Card>
                      <Card variant="outlined" checked={false}>
                        <Box display="flex" flexDirection="column" p={4} gap={6}>
                          <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 14 }} />
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                          </Box>
                        </Box>
                      </Card>
                      <Card variant="outlined" checked={false}>
                        <Box display="flex" flexDirection="column" p={4} gap={6}>
                          <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 14 }} />
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                          </Box>
                        </Box>
                      </Card>
                      <Card variant="outlined" checked={false}>
                        <Box display="flex" flexDirection="column" p={4} gap={6}>
                          <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 14 }} />
                          <Box display="flex" flexDirection="column" gap={2}>
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                            <Skeleton animation="wave" variant="text" width="50%" sx={{ fontSize: 12 }} />
                          </Box>
                        </Box>
                      </Card>
                    </>
                  ) : (
                    listFiltred.map((consult: any, index) => (
                      <RadioGroup.Item value={{ ...consult, index }} key={index} id={index.toString()}>
                        <Card variant="outlined" clickable checked={checked?.index === index}>
                          <Box display="flex" alignItems="center" p={4}>
                            <Box display="flex" flexDirection="column" gap={6}>
                              <Box display="flex" alignItems="center">
                                <CardTitle>{consult?.ViewName}</CardTitle>
                              </Box>
                              <Box display="flex" flexDirection="column" gap={2}>
                                <Box display="flex" gap={1}>
                                  <CardKey>
                                    API:
                                  </CardKey>
                                  <CardValue>
                                    {pontualConsult.getDataOption("apis", consult?.TargetApi, "englishName")?.title}
                                  </CardValue>
                                </Box>

                                <Box display="flex" gap={1}>
                                  <CardKey>
                                    Tipo da consulta:
                                  </CardKey>
                                  <CardValue>
                                    {pontualConsult.getDataOption("conditions", consult?.ViewCondition)?.title}
                                  </CardValue>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Card>
                      </RadioGroup.Item>
                    ))
                  )
                }
              </Box>
            </RadioGroup.Container>
          </Box>
        </Box>
      </Paper>
      <Onboarding
        steps={steps.PontualConsult.SavedConsult}
        controlPath='PontualConsult.SavedConsult'
      />
    </>
  )
}

export default Openned;