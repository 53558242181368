import { styled } from '@mui/material/styles';

const Title = styled("h3")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}))

const Key = styled("span")(({ theme }) => ({
  fontWeight: 700
}))

const Value = styled("span")(({ theme }) => ({
  fontWeight: 400
}))

export {
  Title,
  Key,
  Value
}