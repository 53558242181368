// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast .toast-close-button{
    align-self: center;
    margin-right: 20px;
}

.Toastify__toast svg{
    align-self: center;
    
}

.Toastify__toast-icon{
    width: 24px !important;
    height: 24px !important;
    justify-content: center !important;
    margin: 0px !important;
}

@media screen and (max-width: 500px) {
    .Toastify__toast .toast-close-button{
        align-self: center;
        margin-right: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/toastMessages.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;;AAEtB;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kCAAkC;IAClC,sBAAsB;AAC1B;;AAEA;IACI;QACI,kBAAkB;QAClB,eAAe;IACnB;AACJ","sourcesContent":[".Toastify__toast .toast-close-button{\n    align-self: center;\n    margin-right: 20px;\n}\n\n.Toastify__toast svg{\n    align-self: center;\n    \n}\n\n.Toastify__toast-icon{\n    width: 24px !important;\n    height: 24px !important;\n    justify-content: center !important;\n    margin: 0px !important;\n}\n\n@media screen and (max-width: 500px) {\n    .Toastify__toast .toast-close-button{\n        align-self: center;\n        margin-right: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
