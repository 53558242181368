import { BigBrotherTypes } from "@types";
import Papa from 'papaparse';

const transformUserListToCSV = (userListData: BigBrotherTypes.ListAllUsersApiResponse) => {
    const columns = ['login', 'name', 'email', 'isSystemAdmin', 'isEnabled']
    const headers = 'Login;Name;Email;Administrator;Enabled'
    const csv = Papa.unparse({data: userListData.users, fields: columns}, {delimiter: ';', header: true});
    return csv.replace('login;name;email;isSystemAdmin;isEnabled', headers)
}

const downloadCSV = (csv: string, filename: string) => {
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}


export { transformUserListToCSV, downloadCSV }