import { Box, Typography } from '@mui/material';
import React from 'react';
import { Card, Icon } from './styles';
import { IconType } from 'react-icons/lib';
import { Button } from '@components';
import { BsInfoCircle } from 'react-icons/bs';


interface ComponentProps {
    checked?: boolean,
    onClick?: any,
    IconContent: IconType,
    title: string,
    description: string,
    helpButton?: boolean,
    helpContent?: string,
    breakpoint?: boolean
}

const Component: React.FC<ComponentProps> = ({
    checked = false,
    IconContent,
    title,
    description,
    onClick = (event: MouseEvent) => console.log("CONSOLE", event.target),
    helpButton = false,
    helpContent = '',
    breakpoint = false
}) => {


    return <Card
        variant="outlined"
        clickable
        checked={checked}
        onClick={onClick}
    >
        <Box
            display="flex"
            alignItems="center"
            gap={6}
            px={4}
            py={6}

        >
            {!!!breakpoint ?
                <>
                    <Icon>
                        <IconContent size={40} />
                    </Icon>
                    <Box display="flex" flexDirection="column" gap={4}>
                        <Box display="flex" alignItems="center">
                            <Typography variant='cardTitle'>{title}</Typography>
                        </Box>
                        <Typography variant='body1'>{description}</Typography>
                    </Box>
                </>
                :

                <Box display="flex" flexDirection="column" gap={4}>
                    <Box display="flex" alignItems="center" justifyContent={'space-between'}>
                        <Typography variant='cardTitle'>{title}</Typography>
                        <Icon>
                            <IconContent size={30} />
                        </Icon>
                    </Box>
                    <Typography variant='body1'>{description}</Typography>
                </Box>
            }
        </Box>
    </Card >;
}

export default Component;