import { BsArrowRightShort } from 'react-icons/bs';
import CustomDefaultTextButton from '../../GeneralComponents/CustomDefaultTextButton';

const InvoiceDownloadCell = ({ params }: any) => {

    const getPDFInvoice = () => {
        const url = `https://notacarioca.rio.gov.br/contribuinte/notaprint.aspx?ccm=04062540&nf=${params.row.InvoiceNumber}&cod=${params.row.InvoiceVerificationCode.replace('-', '')}`;
        window.open(url, '_blank');
    };

    return (
        <CustomDefaultTextButton variant='text' endIcon={<BsArrowRightShort />} onClick={getPDFInvoice}>Ver nota fiscal</CustomDefaultTextButton>
    );
}

export default InvoiceDownloadCell;