import { GridColDef } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { isDesktopWidth } from "./screenWidth";
require('dayjs/locale/pt-br')


const platformReportGridColumns: GridColDef[] = [
    {
        field: 'reportName',
        headerName: 'Nome do relatório',
        flex: 20
    },
    {
        field: 'user',
        headerName: 'Usuário/Domínio',
        flex: 20
    },
    {
        field: 'creationDate',
        headerName: 'Data de criação',
        flex: 20
    },
    {
        field: 'reportInterval',
        headerName: 'Intervalo do relatório',
        flex: 20
    },
    {
        field: 'Download',
        headerName: 'Baixar',
        flex: 20
    },
]


export default platformReportGridColumns