import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BillingComponents } from "@components"

import { reducers, slices } from "@store";

import { showToastCustomErrorMessage, handleAxiosError, formatToLocaleString } from '@utils';

import { BillingTypes } from '@types';

import { Billing } from '@services';

import './billingSummary.css'
import { BillingSummaryChart } from '../../../components/BillingComponents';
import LoadingSkeleton from './components/LoadingSkeleton';

const BillingSummary: React.FC = () => {
  const isSystemAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin)

  const [listPartialsData, setListPartialsData] = useState<BillingTypes.ListPartialsApiResponse>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const dispatch = useDispatch()

  const getlistPartialsData = async () => await Billing.listPartials()
  
  const checkIfisInvalidSession = (listPartialsData: BillingTypes.ListPartialsApiResponse) => listPartialsData.Message === "INVALID_SESSION_ID"
  const handleBillingApiResponse = (listPartialsData: BillingTypes.ListPartialsApiResponse) => {
    if (checkIfisInvalidSession(listPartialsData)) {
      showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Faça login novamente.", 'session-timeout')
      dispatch(slices.user.setUserInitialState())
    } else {
      setListPartialsData(listPartialsData)
      setIsLoading(false)
    }
  }

  const tryGetlistPartialsData = async () => {
    try {
      const { data: apiBillingManagementApiResponse } = await getlistPartialsData()
      handleBillingApiResponse(apiBillingManagementApiResponse)
    } catch (error: any) {
      handleAxiosError(error)
    }
  }

  useEffect(() => {
    if (isSystemAdmin) {
      tryGetlistPartialsData()
    }
  }, [])

  const totalCurrentUsageValue = listPartialsData?.Partials?.reduce((total, product) => total + product.Price, 0) ?? 0
  const BigIdValue = listPartialsData?.Partials?.find((product) => product.Product === 'BigId')?.Price ?? 0
  const PlatformValue = listPartialsData?.Partials?.find((product) => product.Product === 'BigBoost')?.Price ?? 0

  const BigIdPercentage = (BigIdValue / totalCurrentUsageValue) * 100
  const PlatformPercentage = (PlatformValue / totalCurrentUsageValue) * 100

  return isSystemAdmin ? <section className='billing-summary-container'>
    <div className='billing-summary-content'>
      {isLoading ? <LoadingSkeleton/> : <>
        <div className='billing-summary-header-container'>
          <div className='billing-summary-total-container'>
            <span className='billing-summary-total-label'>Consumo total:</span>
            <span className='billing-summary-total-value'>{formatToLocaleString(totalCurrentUsageValue ?? 0)}</span>
          </div>
          <div className='billing-summary-view-selection'>

          </div>
        </div>
        <div className='billing-summary-values-container'>
          <div className='billing-summary-cards-container'>
            <BillingComponents.BillingCards product='Plataforma' productValue={PlatformValue} />
            <BillingComponents.BillingCards product='BigID' productValue={BigIdValue} />
          </div>
          <BillingSummaryChart platformPercentage={PlatformPercentage} bigIdPercentage={BigIdPercentage}></BillingSummaryChart>
        </div>
      </>
      }
    </div>
  </section> : null
}

export default BillingSummary