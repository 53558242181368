import { styled } from "@mui/material/styles"

import { Box } from "@mui/material";
import { SelectField } from "@components";

const Icon = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#0068FF1A",
  color: theme.palette.lighterBigBlue?.dark,
  padding: theme.spacing(2),
  borderRadius: 100
}));

const SelectContainer = styled(SelectField.Container)(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "fit-content",
    "& .MuiInputBase-input": {
      fontFamily: "Be Vietnam",
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.blue
    }
  }
}));

const SelectItem = styled(SelectField.Item)(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue,
  display: "flex",
  gap: theme.spacing(3)
}));

export {
  Icon,
  SelectContainer,
  SelectItem
}