import { Grid, Skeleton } from "@mui/material";

const Component: React.FC = () => {
  return (
    <>
      <Grid item xs={4}>
        <Skeleton animation="wave" width="50%" height={30} />
        <Skeleton animation="wave" width="100%" height={90} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton animation="wave" width="50%" height={30} />
        <Skeleton animation="wave" width="100%" height={90} />
      </Grid>
      <Grid item xs={4}>
        <Skeleton animation="wave" width="50%" height={30} />
        <Skeleton animation="wave" width="100%" height={90} />
      </Grid>
    </>
  )
}

export default Component;