import React from "react";

import { Box } from "@mui/material";
import { Title } from "./styles"
import { Table, TextField, Notification } from "@components";

import { FormProvider, useForm, useWatch } from "react-hook-form";

const rows = [
  {
    data: "Nome",
    docInformation: "NAME"
  },
  {
    data: "Nome da mãe",
    docInformation: "MOTHERNAME"
  },
  {
    data: "Nome do pai",
    docInformation: "FATHERNAME"
  },
  {
    data: "Data de nascimento",
    docInformation: "BIRTHDATE"
  },
  {
    data: "Documento",
    docInformation: "CPF"
  },
  {
    data: "Data de expedição",
    docInformation: "EXPEDITIONDATE"
  },
  {
    data: "Validade do documento",
    docInformation: "VALIDDATE"
  },
];

type ComponentProps = {
  docData?: any
  hasError?: boolean
}

const Component: React.FC<ComponentProps> = ({ docData, hasError }) => {
  const formFilter = useForm();

  const search: any = useWatch({
    control: formFilter.control,
    name: "search"
  });

  const [rowsFiltred, setRowsFiltred] = React.useState<Array<any>>(rows);

  React.useEffect(() => {

    handleSearch(search);
  }, [search])

  const handleSearch = (value: string) => {

    if (!!value?.length) {
      const searchSplitted = search.split(" ");

      setRowsFiltred(rows.filter((row: any) => {
        const dataNormalized = row.data.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

        const hasKeyWord = !!searchSplitted.find((keyWord: string) => {
          const keyWordNormalized = keyWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase();

          return dataNormalized.includes(keyWordNormalized);
        });

        return hasKeyWord;
      }))

      return;
    }

    setRowsFiltred(rows)
  };

  if (hasError) {

    return (
      <>
        <Notification
          variant="danger"
          title="Ocorreu um problema com a extração de dados do(s) documento(s)."
          description=" Por favor, contate nossa equipe de suporte."
        />
      </>
    );
  }

  return (
    <>
      <FormProvider {...formFilter}>
        <form>
          <Box display="flex" flexDirection="column" gap={4} className="no-break">
            <Box display="flex" alignItems="center" justifyContent="space-between" py={2} className="no-break">
              <Box display="flex" flexDirection="column" gap={4} width="100%">
                <Title>Extração de dados</Title>
                <Box displayPrint="none">
                  <TextField name="search" placeholder="Busque por tipo de dado (RG, nome, etc)" fullWidth />
                </Box>
              </Box>
            </Box>
            <Box>
              <Table.Container>
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Dado
                      </Box>
                    </Table.Cell>
                    <Table.Cell>
                      <Box display="flex" justifyContent="flex-start">
                        Valor
                      </Box>
                    </Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {
                    rowsFiltred.map(({ data, docInformation }) => {
                      if (!!!data || !!!docData?.[docInformation]) return;

                      return (
                        <Table.Row>
                          <Table.Cell>
                            {data}
                          </Table.Cell>
                          <Table.Cell>
                            {docData[docInformation]}
                          </Table.Cell>
                        </Table.Row>
                      )
                    })
                  }
                </Table.Body>
              </Table.Container>
            </Box>
          </Box>
        </form>
      </FormProvider>
    </>
  )
}

export default Component;