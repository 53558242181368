import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';

import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';

import { slices, reducers } from "@store";

import { showToastCustomSuccessMessage, showToastCustomErrorMessage, handleAxiosError } from '@utils';
import { BigBrotherTypes } from '@types';
import { BigBrother } from '@services';

import { styled } from '@mui/system'
import { GeneralComponents } from "@components";
import { RiErrorWarningLine } from 'react-icons/ri';
import { isDesktopWidth } from '@constants';


const DialogContainer = styled(Dialog)`
    
    .MuiPaper-root{
        padding: 16px 32px 32px 32px;
        box-sizing: content-box;
        width: 400px;
    }

    .MuiDialogTitle-root{
        color: #006;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        border-bottom: 1px solid #DDE7EA;
        padding-left: 0;
    }

    .MuiDialogContentText-root{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #555866;
        padding-top: 16px;
    }

    .MuiDialogActions-root{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0px;
        gap: 16px;
        margin-top: 40px;
    }

    .disable-token-alert{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 100%;
        height: 73px;
        background: #FFFAE6;
        border-radius: 8px;
        margin-top: 35px;
    }

    .disable-token-alert-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 4px;
    }

    .disable-token-alert-content span{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 140%;
        color: #8F5A00;
    }

    .disable-token-alert-content p{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        color: #555866;
    }
    
    .disable-token-password-field{
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px;
    }
`

export default function DisableTokenDialog() {

    const showModal = useSelector((state: reducers.RootState) => state.disableTokenDialogReducer.showDialog)
    const { login, tokenId } = useSelector((state: reducers.RootState) => state.disableTokenReducer)
    const dispatch = useDispatch()

    const [dialogPassword, setDialogPassword] = useState<string>()

    const handleClose = () => {
        dispatch(slices.disableTokenDialog.setShowDialog(false))
    }

    const disableTokenId = async () => await BigBrother.disableTokenById({
        login,
        tokenid: tokenId,
        password: dialogPassword
    })

    const checkIfIsInvalidSession = (disableTokenIdResponse: BigBrotherTypes.DisableTokenIdApiResponse) => disableTokenIdResponse.message === "Erro ao realizar a operação"

    const handleBigBrotherApiResponse = (disableTokenIdResponse: BigBrotherTypes.DisableTokenIdApiResponse) => {

        if (checkIfIsInvalidSession(disableTokenIdResponse)) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else if (!disableTokenIdResponse.success) {
            showToastCustomErrorMessage('Erro ao desabilitar', disableTokenIdResponse.message)
        } else {
            showToastCustomSuccessMessage("Token desabilitado com sucesso!", "Você não poderá mais utilizar esse token.")
            dispatch(slices.disableToken.setDisableTokenIdInfo({ login, tokenId, wasConfirmed: true }))
            if (!isDesktopWidth) {
                dispatch(slices.tokenDetails.setShowTokenDetailsSection(false))
            }
        }
    }

    const tryDisableTokenId = async () => {
        try {
            const { data: apiBigBrotherResponse } = await disableTokenId()
            handleBigBrotherApiResponse(apiBigBrotherResponse)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handlePasswordInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDialogPassword(event.target.value)
    }

    const handleDialogConfirmation = () => {
        dialogPassword ? tryDisableTokenId() : showToastCustomErrorMessage('Erro ao desabilitar', "A senha precisa ser informada.")
        dispatch(slices.disableTokenDialog.setShowDialog(false))
        
    }

    return (
        <DialogContainer open={showModal} onClose={handleClose} className='dialog-container'>
            <DialogTitle>Desabilitar token</DialogTitle>
            <div className='disable-token-password-field'>
                <DialogContentText>
                    Digite a <b>sua senha</b> para confirmar a ação
                </DialogContentText>
                <GeneralComponents.CustomTextField
                    autoFocus
                    fullWidth
                    id="password"
                    placeholder='Senha'
                    type="password"
                    variant="outlined"
                    sx={{ padding: '0px' }}
                    value={dialogPassword}
                    onChange={handlePasswordInputChange}
                />
            </div>
            <DialogContentText className='disable-token-alert'>
                <RiErrorWarningLine color='#8F5A00' size={20} />
                <div className='disable-token-alert-content'>
                    <span>Atenção</span>
                    <p>Esta ação é irreversível.</p>
                </div>
            </DialogContentText>

            <DialogActions>
                <GeneralComponents.CancelButton disableElevation disableRipple onClick={handleClose}>Cancelar</GeneralComponents.CancelButton>
                <GeneralComponents.CustomDefaultButton disableElevation disableRipple onClick={handleDialogConfirmation}>Desabilitar</GeneralComponents.CustomDefaultButton>
            </DialogActions>
        </DialogContainer>
    );
}