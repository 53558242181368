const enviromentsFrom: { [key: string]: string } = {
  DEVELOPMENT: "bdc_center_hml",
  HOMOLOGATION: "bdc_center_hml",
  PRODUCTION: "bdc_center"
}

const isFrom = () => {
  return enviromentsFrom[process.env.REACT_APP_ENVIROMENT || "PRODUCTION"];
}

export default isFrom;