import { showToastCustomEditUserSucessMessage, showToastCustomErrorMessage, handleAxiosError } from "@utils"
import { BigBrother } from "@services"
import { useDispatch, useSelector } from "react-redux"
import { reducers, slices } from "@store";
import { useEffect, useState } from "react"
import { GeneralComponents, UserComponents } from "@components";


interface PendingChangeProps {
    checked: boolean,
    field: string
}

const SwitchCell = ({ params }: any) => {

    const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(params.value);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [pendingChange, setPendingChange] = useState<PendingChangeProps>({
        checked: false,
        field: ''
    }
    );
    const sessionId = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.sessionId)
    const dispatch = useDispatch()

    const updateUserAdminState = async (switchValue: boolean, twoFaValue: boolean) => await BigBrother.updateUserAdminState(
        {
            sessionId,
            login: params.row.login,
            name: params.row.name,
            email: params.row.email,
            isEnabled: params.row.isEnabled,
            isSystemAdmin: switchValue.toString(),
            "2fa": twoFaValue.toString()
        })

    const updateUserAdminStateWithout2FA = async (switchValue: boolean) => await BigBrother.updateUserAdminState(
        {
            sessionId,
            login: params.row.login,
            name: params.row.name,
            email: params.row.email,
            isEnabled: params.row.isEnabled,
            isSystemAdmin: switchValue.toString()
        })

    const updateUserEnabledState = async (switchValue: boolean) => await BigBrother.updateUserEnabledState(
        {
            sessionId,
            login: params.row.login,
            enabled: switchValue.toString()
        })

    const handleOpenConfirmationDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPendingChange({ checked: event.target.checked, field: params.field });
        setIsConfirmationDialogOpen(true)
    }

    const handleConfirmAction = async () => {
        const { checked, field } = pendingChange

        setIsConfirmationDialogOpen(false);
        await handleAdminOrEnabledSwitchChange(checked, field)
    };

    const handleCancelAction = () => {
        setIsConfirmationDialogOpen(false);
    };

    const handleAdminOrEnabledSwitchChange = async (switchValue: boolean, isFrom: string) => await tryToUpdateUser(switchValue, isFrom)

    const chooseEndpoint = async (switchValue: boolean, isFrom: string) => {
        if (isFrom === 'isSystemAdmin' && switchValue) {
            const twoFaValue = true
            return await updateUserAdminState(switchValue, twoFaValue)
        }
        else if (isFrom === 'isSystemAdmin' && !switchValue) {
            return await updateUserAdminStateWithout2FA(switchValue)
        }
        else {
            return await updateUserEnabledState(switchValue)
        }
    }

    const tryToUpdateUser = async (switchValue: boolean, isFrom: string) => {
        try {
            const { data: apiBigBrotherResponseData } = await chooseEndpoint(switchValue, isFrom)
            handleBigBrotherResponse(apiBigBrotherResponseData, switchValue, isFrom)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleBigBrotherResponse = (apiBigBrotherResponseData: any, switchValue: boolean, isFrom: string) => {

        if (apiBigBrotherResponseData.message === 'Fail' || apiBigBrotherResponseData.result === false) {
            showToastCustomErrorMessage('Erro ao atualizar usuário', 'Por favor, tente novamente.')
        } else {
            isFrom === 'isSystemAdmin' ? dispatch(slices.editUser.setEditUserInfo({ ...params.row, isSystemAdmin: switchValue, wasUserEditionConfirmed: true })) : dispatch(slices.editUser.setEditUserInfo({ ...params.row, isEnabled: switchValue, wasUserEditionConfirmed: true }))
            showToastCustomEditUserSucessMessage(params.row.name)
            setIsSwitchChecked(!isSwitchChecked)
        }
    }

    useEffect(() => {
        setIsSwitchChecked(params.value)
    }, [params.value])

    return <>
        <GeneralComponents.CustomSwitch checked={isSwitchChecked} onChange={handleOpenConfirmationDialog} />
        <UserComponents.ConfirmationDialog open={isConfirmationDialogOpen} onCancel={handleCancelAction} onConfirm={handleConfirmAction} />
    </>
}

export default SwitchCell