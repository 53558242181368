import { GeneralComponents } from '@components';
import { CircularProgress, Paper } from '@mui/material';
import React, { useRef, useState } from 'react';
import { IoMdArrowDropleft } from 'react-icons/io';

import './batchEdit.css'
import { handleAxiosError, showToastCustomErrorMessage, showToastCustomSuccessMessage, showToastCustomWarningMessage } from '@utils';
import { BigBrother } from '@services';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';

interface BatchEditProps {
    toggleShowBatchEditSection: () => void
}

const BatchEdit: React.FC<BatchEditProps> = ({ toggleShowBatchEditSection }) => {

    const sessionId = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.sessionId)

    const [isFileLoading, setIsFileLoading] = useState<boolean>(false)
    const [isFileReady, setIsFileReady] = useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileName, setFileName] = useState<string | null>('Nenhum arquivo selecionado')
    const [base64Csv, setBase64Csv] = useState<string | ArrayBuffer | null>('')
    const dispatch = useDispatch()


    const batchEdit = async () => await BigBrother.batchEdit({ sessionId, base64Csv })

    const handleFileLoad = () => {
        setIsFileLoading(true)
    }


    const transformToBase64 = (file: any) => new Promise<string | ArrayBuffer | null>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });


    const tryTransformToBase64 = async (files: FileList) => {
        try {
            const transformedBase64Csv = await transformToBase64(files[0]);
            return transformedBase64Csv
        } catch (error: any) {
            return;
        }
    }

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        const allowedExtension = 'csv'


        if (files && files.length > 0) {
            if (files[0].name.split('.')[1] !== allowedExtension) {
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                showToastCustomWarningMessage('Extensão de arquivo errada', 'A edição de usuários em lote não suporta esse tipo de arquivo.')
                setFileName('Nenhum arquivo selecionado')
            } else {
                setFileName(files[0].name)
                const transformedBase64 = await tryTransformToBase64(files)
                if (transformedBase64) {
                    setBase64Csv(transformedBase64)
                    setIsFileReady(true)
                }
            }
        } else {
            setFileName('Nenhum arquivo selecionado')
        }
    }


    const tryBatchEditUsers = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        try {
            const { data: batchEditResponseData } = await batchEdit()
            handleBatchEditResponse(batchEditResponseData)
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleBatchEditResponse = (batchEditResponse: any) => {
        if (batchEditResponse.status === 3) {
            showToastCustomErrorMessage('Sessão expirada!', 'Sua sessão expirou. Por favor, faça o login novamente.', 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else if (batchEditResponse.status === 1) {
            showToastCustomErrorMessage('Erro na edição em lote!', 'Todos os campos precisam estar preenchidos. Revise seu arquivo e garanta que os separadores estejam corretos.')
        } else {
            showToastCustomSuccessMessage('Edição concluída!', 'A edição de usuários foi concluída com sucesso.')
            toggleShowBatchEditSection()
            dispatch(slices.editUser.setWasUserEditionConfirmed(true))
        }
    }

    return <main className='user-management-main-container'>
        <div className='financial-management-presentation-details-container'>
            <GeneralComponents.DefaultWhiteButton sx={{ width: '32px !important', padding: '0px !important' }} variant='contained' onClick={toggleShowBatchEditSection}><IoMdArrowDropleft size={24} /></GeneralComponents.DefaultWhiteButton>
            <h2 className='financial-management-title'>Edição de usuários em lote</h2>
        </div>
        <Paper sx={{ padding: '32px 24px', borderRadius: '12px' }}>
            <div className='batch-edit-content'>
                <div className='batch-edit-instructions-and-input-container'>
                    <h3 className='batch-edit-users-title'>Siga as instruções para editar os usuários com um arquivo CSV</h3>
                    <ol className='batch-edit-list-container'>
                        <li className='batch-edit-list-items'>A extensão do arquivo precisa ser um <b>CSV</b>.</li>
                        <li className='batch-edit-list-items'>O cabeçalho deve conter <b>Login</b>, <b>Name</b>, <b>Email</b>, <b>Administrator</b> e  <b>Enabled</b>. Eles precisam ser escritos exatamente dessa maneira e na primeira linha do arquivo.</li>
                        <li className='batch-edit-list-items'>O caracter separador precisa ser um <b>ponto e vírgula (;)</b></li>
                        <li className='batch-edit-list-items'>Todas as colunas precisam ser preenchidas.</li>
                    </ol>
                    <div className='input-file-container'>
                        <label htmlFor='batch-edit-csv' className='input-file-button'>Selecionar arquivo</label>
                        <input
                            name='batch-edit-csv'
                            id='batch-edit-csv'
                            type='file'
                            accept='.csv'
                            className='input-file-button'
                            onLoad={handleFileLoad}
                            onChange={handleFileChange}
                            ref={fileInputRef}
                        />
                        {isFileLoading ? <CircularProgress size={24} /> : <p>{fileName}</p>}
                    </div>
                    <div className='batch-edit-options'>
                        <GeneralComponents.CancelButton onClick={toggleShowBatchEditSection}>Cancelar</GeneralComponents.CancelButton>
                        <GeneralComponents.CustomDefaultButton disabled={!isFileReady} onClick={tryBatchEditUsers}>Processar arquivo e edição em lote</GeneralComponents.CustomDefaultButton>
                    </div>
                </div>
                <div className='batch-edit-example-container'>
                    <GeneralComponents.InfoNote
                        noteTitle='Seu arquivo deve ser semelhante ao exemplo abaixo'
                        noteDescription='Este é apenas um exemplo, seu arquivo deve conter as informações de pessoas reais do seu domínio.'
                    >
                        <img src='https://bdcenter-files.bigdatacorp.com.br/Imagens/batch_example2.png' className='batch-edit-example-image' alt=""></img>
                    </GeneralComponents.InfoNote>


                </div>
            </div>

        </Paper>
    </main>;
}

export default BatchEdit;