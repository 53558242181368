import { styled } from "@mui/system";
import { GridActionsCellItem } from "@mui/x-data-grid";

const StyledGridActionsCellItem = styled(GridActionsCellItem)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .5s ease;


  :hover{
    background-color: white;
    transition: background-color .5s ease;
  }
`;

export default StyledGridActionsCellItem