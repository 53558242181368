import { Box } from '@mui/material';
import React from 'react';

interface TagProps {
    variant?: string;
    size?: string;
    children: string;
}

const Tag: React.FC<TagProps> = (
    {
        variant = 'neutral',
        size = 'small',
        children
    }
) => {
    return <Box
        sx={[
            variant === 'positive' ? {
                padding: '6px 8px',
                gap: '4px',
                background: '#EAFBF0',
                borderRadius: '3px',
                fontFamily: 'Be Vietnam',
                fontWeight: 600,
                lineHeight: '100%',
                color: '#14562C',
                width: 'fit-content',
            }
                :
                variant === 'negative' ? {
                    padding: '6px 8px',
                    gap: '4px',
                    background: '#FFE5EB',
                    borderRadius: '3px',
                    fontFamily: 'Be Vietnam',
                    fontWeight: 600,
                    lineHeight: '100%',
                    color: '#FF003C',
                    width: 'fit-content',
                    height: 'fit-content',
                }
                    :
                    {
                        padding: '6px 8px',
                        gap: '4px',
                        background: '#F3F8FA',
                        borderRadius: '3px',
                        fontFamily: 'Be Vietnam',
                        fontWeight: 600,
                        lineHeight: '100%',
                        color: '#555866',
                        width: 'fit-content',
                        height: 'fit-content',
                    },
            size === 'small'
                ?
                {
                    fontSize: '12px'
                }
                :
                size === 'medium'
                    ?
                    {
                        fontSize: '14px'
                    }
                    :
                    {
                        fontSize: '16px'
                    }
        ]}
    >
        {children}
    </Box>
}

export default Tag;