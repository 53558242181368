import { Pie, ResponsivePie } from '@nivo/pie'
import { useEffect, useState } from 'react';
import { isDesktopWidth } from '../../../constants/screenWidth';

const customColors: { [key: string]: string[] } = {
  BigId: [
    '#B8EFFF',
    '#9DD6FF',
    '#83BDFF',
    '#66A4FF',
    '#3F8CFD',
    '#1376F0',
    '#0068FF',
    '#0862D9',
    '#084EC0',
    '#053BA7',
    '#00288F',
  ].reverse(),
  Platform: [
    '#FFB3CC',
    '#FF99B3',
    '#FF8099',
    '#FF6680',
    '#FF4D66',
    '#FF3350',
    '#FF003C',
    '#E60031',
    '#CC002A',
    '#B2001E',
    '#800014',
  ].reverse()
};

const ResponsivePieChart = ({ data, product, productValue }: any) => {

  


  const reduceFunction = (accumulator: any, curr: any) => {
    const existingScope = accumulator.find((product: any) => product.Scope === curr.Scope);


    if (existingScope) {
      
      existingScope.Value += curr[productValue];
    } else {
      accumulator.push({ Scope: curr.Scope, Value: curr[productValue]});
    }
    return accumulator;
  }

  const chartData = data?.reduce(reduceFunction, [])
  const mobileMargins = { top: -50, bottom: 50, left: 70, right: 70 }

  const sumOfAllProductsValue = chartData.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.Value, 0);
  const formattedValue = sumOfAllProductsValue.toLocaleString('pt-BR', productValue === 'Price' ? { style: 'currency', currency: 'BRL' } : {});

  const [isNotebookWidth, setIsNotebookWidth] = useState(false);
  const desktopMargins = { top: 30, bottom: 30, left: product === 'BigId' ? 100 : 100, right: 250 }
  const desktopCenterComponentX = product === 'BigId' ? "-172px" : '-172px'

  useEffect(() => {
    const handleResize = () => {
      setIsNotebookWidth(window.screen.availWidth >= 1366 && window.screen.availWidth <= 1780);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const CenterComponent = () => (

    <foreignObject x={sumOfAllProductsValue === 0 ? '50px' : desktopCenterComponentX }
    y={sumOfAllProductsValue !== 0 ?
          isDesktopWidth ?
              "calc(50% - 45px)"
              :
              'calc(25% + 40px)'
      :
      'calc(50% - 100px)'
      }
    width={sumOfAllProductsValue !== 0 ? '100%' : '50%'} 
    height={sumOfAllProductsValue !== 0 ? "25%" : '100%'}
    >
      <div
        style={sumOfAllProductsValue !== 0 ? {
          width: 'auto',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }
          :
          {
            width: '300',
            margin: 'auto',
            padding: '30px',
            height: 'auto',
            gap: '8px',
            display: 'flex',
            textAlign: 'center',
            border: '1px dashed #555866',
            borderRadius: '12px',
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
          }
        }
      >
        <span style={{ color: '#666', fontWeight: 700, fontSize: '12px' }}>{sumOfAllProductsValue !== 0 ? 'Valor total:' : 'Em breve você poderá visualizar o gráfico'}</span>
        <span style={{ color: '#006', fontSize: '14px', fontWeight: 700 }}>{sumOfAllProductsValue !== 0 ? formattedValue : 'Você ainda não consumiu esse produto'}</span>
      </div>
    </foreignObject>
  )


  const MobileCenterComponent = () => (
    sumOfAllProductsValue !== 0 ?
    <foreignObject 
    x={'30px'}
    y={'calc(50% - 52.5px)'}
    width={'100px'}
    height={'100px'}
    >
      <div
        style={ {
          width: '100%',
          height: '100%',
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '10px',
          fontWeight: '700'
          
        } 
      }
      >
        <span style={{ color: '#666', }}>Valor total:</span>
        <span style={{ color: '#006', fontSize: '10px' }}>{formattedValue}</span>
      </div>
    </foreignObject>

    :
    <foreignObject 
    x={'-50px'}
    y={'100px'}
    width={'260px'}
    height={'150px'}
    >
      <div
        style={ {
          width: '100%',
          textAlign: 'center',
          padding: '16px',
          height: 'auto',
          gap: '8px',
          display: 'flex',
          border: '1px dashed #555866',
          borderRadius: '12px',
          flexDirection: 'column-reverse',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '14px',
        }
      }
      >
        <span style={{ color: '#666', fontWeight: 700, fontSize: '10px' }}>Em breve você poderá visualizar o gráfico</span>
        <span style={{ color: '#006', fontWeight: 700, fontSize: '12px' }}>Você ainda não consumiu esse produto</span>
      </div>
    </foreignObject>
    
  )

  

  return isDesktopWidth ? <ResponsivePie
    data={chartData}
    id='Scope'
    key={'Scope'}
    value='Value'
    sortByValue={false}
    innerRadius={0.65}
    margin={desktopMargins}
    padAngle={1}
    colors={product === 'BigId' ? customColors.BigId : customColors.Platform}
    activeOuterRadiusOffset={8}
    enableArcLinkLabels={true}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#555866"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: 'color' }}
    enableArcLabels={false}
    theme={{ labels: { text: { fontSize: '14px', fontFamily: 'Be Vietnam' } } }}
    legends={[
      {
        anchor: 'right',
        direction: 'column',
        justify: false,
        translateX: 175,
        translateY: 0,
        itemsSpacing: 5,
        itemWidth: 50,
        itemHeight: 18,
        itemTextColor: '#555866',
        itemDirection: 'left-to-right',
        itemOpacity: 1,
        symbolSize: 18,
        symbolShape: 'circle',
        toggleSerie: true

      }
    ]}
    tooltip={(tooltipProps) => {

      const formattedValue = tooltipProps.datum.value.toLocaleString('pt-BR',  productValue === 'Price' ? {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
      :
      {}
      );

      return (
        <div style={{ backgroundColor: '#333', padding: '10px', borderRadius: '5px' }}>
          <span style={{ fontWeight: 'bold', color: 'white', fontSize: '14px' }}>{tooltipProps.datum.label}</span>
          <p style={{ color: 'white', fontSize: '12px' }}>{formattedValue}</p>
        </div>
      );
    }}
    layers={[CenterComponent, 'arcs', 'arcLabels', 'arcLinkLabels', 'legends']}

  />
    : <Pie
        data={chartData}
        width={300}
        height={300}
        id='Scope'
        key={'Scope'}
        value='Value'
        sortByValue={false}
        innerRadius={0.6}
        margin={mobileMargins}
        padAngle={2}
        colors={product === 'BigId' ? customColors.BigId : customColors.Platform}
        activeOuterRadiusOffset={2}
        enableArcLinkLabels={true}
        arcLinkLabelsDiagonalLength={5}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsStraightLength={5}
        arcLinkLabelsOffset={0}
        arcLinkLabelsTextColor="#555866"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        theme={{
          labels:
          {
            text:
            {
              fontSize: '10px',
              fontFamily: 'Be Vietnam',
            }
          },
          legends: {
            text: {
              fontSize: '10px',
              fontFamily: 'Be Vietnam'
            },
          },
        }}
        legends={
           data && data.length <= 3  ?
            [

              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: -115,
                itemWidth: 50,
                itemHeight: 30,
                itemTextColor: '#555866',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 8,
                itemsSpacing: -5,
                translateY: 50,
                symbolShape: 'circle',
                toggleSerie: true,
              }

            ]
            :
            [
              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: -115,
                itemWidth: 50,
                itemHeight: 30,
                itemTextColor: '#555866',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 8,
                itemsSpacing: -5,
                translateY: 50,
                data: chartData
                  .slice(0, Math.floor(chartData.length / 2))
                  .map((cur: {Scope: string, Price: number}, index: number) => ({
                    id: cur.Scope,
                    label: cur.Scope,
                    color: product === 'BigId' ?
                    customColors.BigId.slice(0, Math.floor(chartData.length / 2))[index]
                    :
                    customColors.Platform.slice(0, Math.floor(chartData.length / 2))[index],
                  })),
                  toggleSerie: true,
                  symbolShape: 'circle'
              },
              {
                anchor: 'bottom',
                direction: 'column',
                justify: false,
                translateX: 115,
                itemWidth: 50,
                itemHeight: 30,
                itemTextColor: '#555866',
                itemDirection: 'right-to-left',
                itemOpacity: 1,
                symbolSize: 8,
                itemsSpacing: -5,
                translateY: 50,
                data: chartData
                  .slice(Math.floor(chartData.length / 2))
                  .map((cur: {Scope: string, Price: number}, index: number) => ({
                    id: cur.Scope,
                    label: cur.Scope,
                    color: product === 'BigId' ? customColors.BigId.slice(0, Math.floor(chartData.length / 2))[index] : customColors.Platform.slice(0, Math.floor(chartData.length / 2))[index],
                  })),
                  toggleSerie: true,
                  symbolShape: 'circle'
              }
            ]
        }
        tooltip={(tooltipProps) => {

          const formattedValue = tooltipProps.datum.value.toLocaleString('pt-BR', productValue === 'Price' ? {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
          :
          {}
          );

          return (
            <div style={{ backgroundColor: '#333', padding: '10px', borderRadius: '5px' }}>
              <span style={{ fontWeight: 'bold', color: 'white', fontSize: '14px' }}>{tooltipProps.datum.label}</span>
              <p style={{ color: 'white', fontSize: '12px' }}>{formattedValue}</p>
            </div>
          );
        }}
        layers={[MobileCenterComponent,'arcs', 'arcLabels', 'arcLinkLabels', 'legends']}
      />
}


export default ResponsivePieChart
