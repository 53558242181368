import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface exportPdfFacematchInfo {
  open: boolean;
  consult: any;
  qualityImage: any;
}


const initialState: exportPdfFacematchInfo = {
  open: false,
  consult: {},
  qualityImage: {},
}

const exportPdfFacematch = createSlice({
  name: 'exportPdfFacematch',
  initialState: initialState,
  reducers: {
    setExportPdfFacematchInfo: (state, action: PayloadAction<exportPdfFacematchInfo>) => {
      return { ...state, ...action.payload }
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setConsult: (state, action: PayloadAction<any>) => {
      state.consult = action.payload
    },
    setQualityImage: (state, action: PayloadAction<any>) => {
      state.qualityImage = action.payload
    }
  },
});

export const { setExportPdfFacematchInfo, setOpen, setConsult, setQualityImage } = exportPdfFacematch.actions;

export default exportPdfFacematch.reducer;