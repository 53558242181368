import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#documents-consult',
    title: 'Dados da pesquisa',
    content: 'Visualize os dados consultados.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#id-consult',
    title: 'Identificação',
    content: 'Cada dataset consultado terá um identificador de consulta. É importante salvar esta informação para fins de suporte.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#export-consult',
    title: 'Exporte o necessário',
    content: 'Personalize o PDF selecionando os datasets desejados a serem exportados.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#btn-new-consult',
    title: 'Nova consulta',
    content: 'Realize uma nova consulta retornando a etapa inicial de configuração.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;