import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Tabs, Paper } from '@components';
import { FormProvider, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { Box, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import { Field, Guide } from './components';

import { SchemaFileSelection } from './schemas';

import { FaLink, FaRegFileImage } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import { BsArrowRight } from 'react-icons/bs';
import { useDispatch } from 'react-redux';

const New: React.FC = () => {

  const [isGuideOpen, setIsGuideOpen] = useState<boolean>(false)
  const navigate = useNavigate()

  const form = useForm({
    resolver: yupResolver(SchemaFileSelection),
    defaultValues: {
      type: 'file',
      checkbox: {
        qualityCheck: {
          selected: true
        }
      },
      files: [
        {
          firstImage: null,
          secondImage: null
        }
      ]
    }
  });

  const { control, handleSubmit } = form

  const { fields } = useFieldArray({
    control,
    name: 'files'
  })

  const type: any = useWatch({
    control,
    name: "type"
  });

  const handleSubmitForm = (data?: any, event?: any) => {
    event.preventDefault()

    navigate('result', { state: data })
  }

  const handleOpenGuide = () => {
    setIsGuideOpen(true)
  }

  const handleCloseGuide = () => {
    setIsGuideOpen(false)
  }

  return (
    <Paper>
      <Box
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
        gap={6}
        px={6}
        py={4}
      >
        <FormProvider {...form}>
          <Box width={'100%'}>
            <Tabs.Container
              name='type'
              value={type}
              variant="fullWidth"
              fieldToReset='files'
            >
              <Tabs.Item
                value={'file'}
                label={'Inserir arquivo'}
                icon={<FaRegFileImage size={20} />}
                iconPosition='start'
              />
              <Tabs.Item
                value={'link'}
                label={'Inserir link'}
                icon={<FaLink size={20} />}
                iconPosition='start'
              />
            </Tabs.Container>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={'100%'}
          >
            <Button
              color='secondary'
              size='small'
              StartIcon={<MdInfoOutline size={20} />}
              onClick={handleOpenGuide}
            >
              Confira nosso guia de captura de imagens
            </Button>

          </Box>
          {
            fields.map((field, index) => <Grid
              container
              spacing={4}
              wrap='wrap'
            >

              <Grid item xs={6}>
                <Field
                  key={`${field.id}-firstImage`}
                  type={type as 'file' | 'link'}
                  name={`files.${index}.firstImage`}
                  identifier={'Primeira imagem'}
                />
              </Grid>
              <Grid item xs={6}>
                <Field
                  key={`${field.id}-secondImage`}
                  type={type as 'file' | 'link'}
                  name={`files.${index}.secondImage`}
                  identifier={'Segunda imagem'}
                />
              </Grid>
            </Grid>)
          }
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            alignSelf={'flex-end'}
            gap={8}
          >
            <Button
              fullWidth
              color='primary'
              size='small'
              EndIcon={<BsArrowRight size={20} color='#FFF' />}
              onClick={handleSubmit(handleSubmitForm)}
            >
              Comparar rostos
            </Button>
          </Box>
        </FormProvider>
        <Guide open={isGuideOpen} onClose={handleCloseGuide} />
      </Box>
    </Paper>
  );
}

export default New;