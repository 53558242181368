import { Theme, styled } from '@mui/material/styles';
import { Checkbox as CheckboxMaterial, Box, BoxProps } from "@mui/material";

const Checkbox = styled(CheckboxMaterial)(() => ({
  padding: 0
}))

const Unchecked = styled('span')(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: 2,
  border: `1px solid ${theme.palette.neutral?.[200]}`,
}))

const Checked = styled('span')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 20,
  height: 20,
  borderRadius: 2,
  border: `1px solid ${theme.palette.primary.blue}`,
  backgroundColor: theme.palette.primary.blue,
  color: theme.palette.neutral?.white,
}))

const Label = styled('label')(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}))

const Divider = styled('hr')(({ theme }) => ({
  width: "100%",
  border: `1px solid ${theme.palette.neutral?.[400]}`
}))

const Content = styled(Box)<BoxProps & {disabled?: boolean}>(({ theme, disabled }) => ({
  opacity: disabled ? 0.5 : 1
}))

export {
  Checkbox,
  Unchecked,
  Checked,
  Label,
  Divider,
  Content
}