import { Theme, styled } from "@mui/material/styles"
import { StandardCSSProperties } from "@mui/system";

type HeaderProps = {
  theme?: Theme,
  status: "success" | "warning" | "danger" | "information"
}

interface IHeaderStyles {
  [key: string]: StandardCSSProperties | any
}

const Header = styled("div")<HeaderProps>(({ theme, status }) => {
  const headerStyles: IHeaderStyles = {
    color: {
      success: theme.palette.success.medium,
      warning: theme.palette.warning.medium,
      danger: theme.palette.negative.medium,
      information: theme.palette.lighterBigBlue?.medium
    },
    backgroundColor: {
      success: theme.palette.success.lightest,
      warning: theme.palette.warning.lightest,
      danger: theme.palette.negative.lightest,
      information: theme.palette.lighterBigBlue?.lightest
    }
  }

  return {
    fontFamily: "Be Vietnam",
    fontWeight: 700,
    fontSize: 14,
    padding: theme.spacing(4),
    borderRadius: "12px 12px 0px 0px",
    color: headerStyles.color[status],
    backgroundColor: headerStyles.backgroundColor[status],
    width: "100%"
  }
});

export {
  Header
}