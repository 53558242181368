import { GeneralComponents } from '@components';
import { BigBrotherTypes } from '@types';
import React from 'react';
import selectedButtonStyle from '../../EditUser/sxStyles';
import './mobileFilterSection.css'
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';

interface MobileFilterSectionProps {

    className: string;
    open: boolean;
    localFilterInfo: BigBrotherTypes.UserManagementFilterInfo;
    setLocalFilterInfo: React.Dispatch<React.SetStateAction<BigBrotherTypes.UserManagementFilterInfo>>
    toggleMobileFilterOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
    handleTextFilterChange: (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => void
}

const MobileFilterSection: React.FC<MobileFilterSectionProps> = ({
    className,
    localFilterInfo,
    open,
    toggleMobileFilterOpen,
    handleTextFilterChange,
    setLocalFilterInfo,
}) => {

    const dispatch = useDispatch()
    const userPaginationInfo = useSelector((state: reducers.RootState) => state.userPaginationReducer)

    const resetLocalFilterInfo = () => ({
        login: '',
        name: '',
        email: '',
        isSystemAdmin: 'all',
        isEnabled: 'all'
      });

    const handleFilterRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.userManagementFilterSection.setFilterInfo({ ...localFilterInfo, isSystemAdmin: localFilterInfo.isSystemAdmin === 'all' ? '' : localFilterInfo.isSystemAdmin, isEnabled: localFilterInfo.isEnabled === 'all' ? '' : localFilterInfo.isEnabled }))
        dispatch(slices.userPagination.setUserPaginationInfo({ ...userPaginationInfo, skip: 0 }))
        toggleMobileFilterOpen(event)
    }

    const handleClearFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        setLocalFilterInfo(resetLocalFilterInfo())
        dispatch(slices.userManagementFilterSection.resetFilterInfo())
        dispatch(slices.userPagination.setUserPaginationInfo({...userPaginationInfo, skip: 0}))
        toggleMobileFilterOpen(event)
      }

    const handleEnabledStatusChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.currentTarget.name === 'enabled' ?
            setLocalFilterInfo({ ...localFilterInfo, isEnabled: 'true' })
            :
            event.currentTarget.name === 'all' ?
                setLocalFilterInfo({ ...localFilterInfo, isEnabled: 'all' })
                :
                setLocalFilterInfo({ ...localFilterInfo, isEnabled: 'false' })
    }

    const handleAdminChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.currentTarget.name === 'admin' ?
            setLocalFilterInfo({ ...localFilterInfo, isSystemAdmin: 'true' })
            :
            event.currentTarget.name === 'all' ?
                setLocalFilterInfo({ ...localFilterInfo, isSystemAdmin: 'all' })
                :
                setLocalFilterInfo({ ...localFilterInfo, isSystemAdmin: 'false' })
    }

    return open ? <div className={className}>
        <div className='mobile-filter-section-main-header-container'>
            <h2 className='mobile-filter-section-title'>Filtrar usuários</h2>
            <GeneralComponents.CustomDefaultTextButton
                type='button'
                size='small'
                variant='text'
                disableElevation
                disableRipple
                onClick={handleClearFilters}
            >Limpar filtros</GeneralComponents.CustomDefaultTextButton>
        </div>
        <hr className='mobile-filter-section-divider'></hr>
        <form className='mobile-filter-section-form-container'>
            <div className='mobile-filter-section-data-container'>
                <h4 className='mobile-filter-section-data-title'>Dados</h4>
                <div className='mobile-filter-section-data-fields'>
                    <label>Login</label>
                    <GeneralComponents.CustomTextField
                        className='mobile-filter-section-form-login'
                        variant="outlined"
                        placeholder='Insira o login do usuário'
                        onChange={handleTextFilterChange}
                        value={localFilterInfo.login}
                        name='login' />
                </div>
                <div className='mobile-filter-section-data-fields'>
                    <label>Nome</label>
                    <GeneralComponents.CustomTextField
                        className='mobile-filter-section-form-name'
                        variant="outlined"
                        placeholder='Insira o nome do usuário'
                        onChange={handleTextFilterChange}
                        value={localFilterInfo.name}
                        name='name'
                    />
                </div>
                <div className='mobile-filter-section-data-fields'>
                    <label>E-mail</label>
                    <GeneralComponents.CustomTextField
                        className='mobile-filter-section-form-email'
                        variant="outlined"
                        placeholder='Insira o e-mail do usuário'
                        onChange={handleTextFilterChange}
                        value={localFilterInfo.email}
                        name='email'
                    />
                </div>
            </div>
            <div className='mobile-filter-section-switch-container'>
                <h4 className='mobile-filter-section-switch-container-title'>Condições</h4>
                <div className='mobile-filter-section-switch-content'>
                    <div className='mobile-filter-section-switch-container-admin'>
                        <label>Perfil de acesso</label>
                        <GeneralComponents.CustomButtonGroup>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isSystemAdmin === 'all' && selectedButtonStyle }}
                                onClick={handleAdminChange}
                                name='all'>Ambos</GeneralComponents.CustomGroupedButton>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isSystemAdmin === 'true' && selectedButtonStyle }}
                                onClick={handleAdminChange}
                                name='admin'>Administrador</GeneralComponents.CustomGroupedButton>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isSystemAdmin === 'false' && selectedButtonStyle }}
                                onClick={handleAdminChange}
                                name='non-admin'>Colaborador</GeneralComponents.CustomGroupedButton>
                        </GeneralComponents.CustomButtonGroup>
                    </div>
                    <div className='mobile-filter-section-switch-container-status'>
                        <label>Status</label>
                        <GeneralComponents.CustomButtonGroup>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isEnabled === 'all' && selectedButtonStyle }}
                                onClick={handleEnabledStatusChange}
                                name='all'>Ambos</GeneralComponents.CustomGroupedButton>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isEnabled === 'true' && selectedButtonStyle }}
                                onClick={handleEnabledStatusChange}
                                name='enabled'>Ativo</GeneralComponents.CustomGroupedButton>
                            <GeneralComponents.CustomGroupedButton
                                sx={{ ...localFilterInfo.isEnabled === 'false' && selectedButtonStyle }}
                                onClick={handleEnabledStatusChange}
                                name='disabled'>Inativo</GeneralComponents.CustomGroupedButton>
                        </GeneralComponents.CustomButtonGroup>
                    </div>
                </div>
            </div>
            <div className='mobile-filter-section-button-container'>
                <GeneralComponents.CancelButton
                    type='button'
                    size='small'
                    variant='text'
                    disableElevation
                    disableRipple
                    onClick={toggleMobileFilterOpen}
                >Fechar</GeneralComponents.CancelButton>
                <GeneralComponents.CustomDefaultButton
                    type='submit'
                    size='small'
                    variant='contained'
                    disableElevation
                    disableRipple
                    onClick={handleFilterRequest}
                >Filtrar</GeneralComponents.CustomDefaultButton>
            </div>
        </form>
    </div>
        :
        <></>;
}

export default MobileFilterSection;