import React from "react";

import CheckboxContext from "./context";

interface CheckboxProviderProps {
  children: React.ReactNode,
  field: any
}

const CheckboxProvider: React.FC<CheckboxProviderProps> = ({
  children,
  field
}) => (
  <CheckboxContext.Provider
    value={{
      field
    }}>
    {children}
  </CheckboxContext.Provider>
)

export default CheckboxProvider;

