import { styled } from "@mui/material/styles";

import { DatePicker as DatePickerMaterial } from "@mui/x-date-pickers";

const DatePicker = styled(DatePickerMaterial)(({ theme }) => ({
  "& .MuiInputBase-input": {
    fontFamily: "Be Vietnam",
    fontStyle: "normal",
    fontSize: "12px",
    padding: `${theme.spacing(5)} ${theme.spacing(4)}`
  },
  "& .MuiInputBase-root": {
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: "#BED0D7",
      borderRadius: 8
    }
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0448AB"
  },
  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#BED0D7"
  },
  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "#FF003C"
  },
}))

export {
  DatePicker
}