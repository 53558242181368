import { SendMessageCommand, SQSClient, SQSServiceException } from "@aws-sdk/client-sqs";

const client = new SQSClient({
    region: 'us-east-1',
});

export const sendToQueue = async (data: any) => {
    const command = new SendMessageCommand({
        QueueUrl: process.env.REACT_APP_CENTER_SQS_DATADOG,
        DelaySeconds: 10,
        MessageAttributes: {
            data: {
                DataType: "String",
                StringValue: JSON.stringify(data),
            }
        },
        MessageBody:
            JSON.stringify(data),
    });


    try {
        const response = await client.send(command);
        return response
    } catch (err: any) {
        if (err instanceof SQSServiceException) {
            console.log(err)
        }
        return err
    }

};

