import { styled } from "@mui/material/styles"

const Title = styled("h2")(({ theme }) => ({
  fontFamily: "Picadilly",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: 32,
  color: theme.palette.secondary.blue
}));

const Subtitle = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[100]
}));

export {
  Title,
  Subtitle
}