import { GridValidRowModel } from "@mui/x-data-grid"
import dayjs from "dayjs"

const getFolderDisplayName = (value: string) => {
    switch (value) {
        case 'dataplatform':
            return "Plataforma de Dados"
        case 'bigid':
            return "BigID"
        case 'bigweb':
            return "BigWeb"
        case 'blabs':
            return "B-LABS"
        case 'parceria':
            return "Parceria"
        default:
            return value
    }
}

const getOriginDisplayName = (value: string) => {
    switch (value) {
        case 'in':
            return "Entrada"
        case 'out':
            return "Saída"
        default:
            return value
    }
}


const formatFileSize = (bytes: number) => {
    if (bytes === 0) return '0 B';
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
}

const tranformFileListToValidRows = (filesData: any | undefined) => {
    const filesList: GridValidRowModel[] | undefined = filesData ?
        filesData?.filter((file: any) => {
            const [, , , , , FileName] = file?.Key.split('/');

            return !!FileName
        }
        ).map((file: any, index: any) => {
            if (!!file) {

                const [, , , Folder, Origin, FileName] = file?.Key.split('/')
                const FileExtension = FileName?.split('.').pop().toUpperCase()

                return ({
                    id: index,
                    Key: file?.Key,
                    LastModified: dayjs(file?.LastModified).format('DD/MM/YYYY'),
                    Size: formatFileSize(file?.Size),
                    FileName,
                    FileExtension,
                    Origin: getOriginDisplayName(Origin),
                    Folder: getFolderDisplayName(Folder)
                })
            }

            return null;
        })
        :
        []

    return filesList
}

export { tranformFileListToValidRows, formatFileSize }