import React from "react";

import { DialogActions, DialogProps, Box, Divider } from "@mui/material";
import { Dialog, Title, Content } from "./styles";
import { Button } from "@components";

interface ComponentProps extends DialogProps {
  title: string,
  children?: React.ReactNode,
  confirmText: string,
  cancelText: string,
  onConfirm: () => void
  onCancel: () => void
}

const Component: React.FC<ComponentProps> = ({
  open,
  onClose,
  title,
  children,
  cancelText,
  confirmText,
  onConfirm,
  onCancel
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column" gap={4}>
        <Title>{title}</Title>
        <Divider />
        <Content>
          {children}
        </Content>
      </Box>
      <DialogActions>
        <Button
          color="secondary"
          size="small"
          onClick={onCancel}
        >
          {cancelText}
        </Button>
        <Button
          color="negative"
          size="small"
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Component;