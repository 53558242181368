import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { SelectChangeEvent } from '@mui/material/Select';

import { reducers, slices } from "@store";

import { BigBrotherTypes } from '@types';

import './filterSection.css'

import { GeneralComponents } from "@components";
import { isDesktopWidth } from '../../../constants/screenWidth';
import MobileFilterSection from './MobileFilterSection';
import { setIsMobileNavbarOpen } from '../../../store/slices/navbar';

interface FilterSectionProps {
  isMobileFilterSectionOpen: boolean;
  mobileFilterSectionClassName: string;
  toggleMobileFilterOpen: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const FilterSection: React.FC<FilterSectionProps> = ({ isMobileFilterSectionOpen, mobileFilterSectionClassName, toggleMobileFilterOpen }) => {

  const userPaginationInfo = useSelector((state: reducers.RootState) => state.userPaginationReducer)

  const [filterLocalInfo, setLocalFilterInfo] = useState<BigBrotherTypes.UserManagementFilterInfo>({
    login: '',
    name: '',
    email: '',
    isSystemAdmin: 'all',
    isEnabled: 'all'
  })

  const resetLocalFilterInfo = () => ({
    login: '',
    name: '',
    email: '',
    isSystemAdmin: 'all',
    isEnabled: 'all'
  });

  const dispatch = useDispatch()

  const handleFilterRequest = () => {
    if (!isDesktopWidth) {
      setIsMobileNavbarOpen(false)
    }
    dispatch(slices.userManagementFilterSection.setFilterInfo({ ...filterLocalInfo, isSystemAdmin: filterLocalInfo.isSystemAdmin === 'all' ? '' : filterLocalInfo.isSystemAdmin, isEnabled: filterLocalInfo.isEnabled === 'all' ? '' : filterLocalInfo.isEnabled }))
    dispatch(slices.userPagination.setUserPaginationInfo({ ...userPaginationInfo, skip: 0 }))
  }

  const handleTextFilterChange = (event: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>) => {
    setLocalFilterInfo({ ...filterLocalInfo, [event.target.name as string]: event.target.value as string })
  }

  const handleSelectFilterChange = (event: SelectChangeEvent<unknown>) => {
    setLocalFilterInfo({ ...filterLocalInfo, [event.target.name as string]: event.target.value as string })
  }

  const handleClearFilters = () => {
    setLocalFilterInfo(resetLocalFilterInfo())
    dispatch(slices.userManagementFilterSection.resetFilterInfo())
    dispatch(slices.userPagination.setUserPaginationInfo({ ...userPaginationInfo, skip: 0 }))
  }

  return isDesktopWidth ? <div className='filter-section-container'>
    <div className='filters-container'>
      <div className='text-filters-container'>
        <GeneralComponents.CustomFilterTextField LabelText='Login' id="outlined" fullWidth placeholder='Buscar login' name='login' variant="outlined" onChange={handleTextFilterChange} value={filterLocalInfo.login} size='small' />
        <GeneralComponents.CustomFilterTextField LabelText='Nome' id="outlined" fullWidth placeholder='Buscar usuário' name='name' variant="outlined" onChange={handleTextFilterChange} value={filterLocalInfo.name} size='small' />
        <GeneralComponents.CustomFilterTextField LabelText='E-mail' id="outlined" fullWidth placeholder='Buscar e-mail' name='email' variant="outlined" onChange={handleTextFilterChange} value={filterLocalInfo.email} size='small' />
      </div>
      <div className='filter-fields'>
        <GeneralComponents.CustomSelect
          variant="outlined"
          name='isSystemAdmin'
          onChange={handleSelectFilterChange}
          LabelText='Nível de acesso'
          value={filterLocalInfo.isSystemAdmin ?? ""}
        >
          <GeneralComponents.CustomMenuItem value={'all'}>Todos os níveis</GeneralComponents.CustomMenuItem>
          <GeneralComponents.CustomMenuItem value={'true'}>Administrador</GeneralComponents.CustomMenuItem>
          <GeneralComponents.CustomMenuItem value={'false'}>Colaborador</GeneralComponents.CustomMenuItem>
        </GeneralComponents.CustomSelect>
      </div>
      <div className='filter-fields'>
        <GeneralComponents.CustomSelect
          variant="outlined"
          name='isEnabled'
          onChange={handleSelectFilterChange}
          LabelText='Status'
          value={filterLocalInfo.isEnabled ?? ""}
        >
          <GeneralComponents.CustomMenuItem value={'all'}>Todos os status</GeneralComponents.CustomMenuItem>
          <GeneralComponents.CustomMenuItem value={'true'}>Ativo</GeneralComponents.CustomMenuItem>
          <GeneralComponents.CustomMenuItem value={'false'}>Inativo</GeneralComponents.CustomMenuItem>
        </GeneralComponents.CustomSelect>
      </div>
      <div className='filter-actions-container'>
        <GeneralComponents.FilterButton variant="contained" onClick={handleFilterRequest}>Filtrar</GeneralComponents.FilterButton>
        <GeneralComponents.ClearFilterButton variant="text" disableFocusRipple disableElevation disableTouchRipple onClick={handleClearFilters}>Limpar filtros</GeneralComponents.ClearFilterButton>
      </div>
    </div>
  </div>
    :
    <MobileFilterSection
      setLocalFilterInfo={setLocalFilterInfo}
      handleTextFilterChange={handleTextFilterChange}
      toggleMobileFilterOpen={toggleMobileFilterOpen}
      className={mobileFilterSectionClassName}
      localFilterInfo={filterLocalInfo}
      open={isMobileFilterSectionOpen} />
    ;
}

export default FilterSection;