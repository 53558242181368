import { Checkbox, FormControlLabel, FormGroup, styled } from "@mui/material";


const CheckboxContainer = styled(FormGroup)(({ theme }) => ({
    width: '100%',

}))

const CheckboxLabel = styled(FormControlLabel)(({ theme }) => ({
    width: '100%',
    color: '#555866',
    fontFamily: 'Be vietnam',
    fontSize: '14px',
    fontWeight: 400,
    padding: 0,

    '& span:last-child': {
        color: '#555866',
        fontFamily: 'Be vietnam',
        fontSize: '14px',
        fontWeight: 400,
        padding: 0
    }
}))

const DialogCheckbox = styled(Checkbox)(({ theme }) => ({
    color: '#7D8285'
}))

export {
    CheckboxContainer,
    CheckboxLabel,
    DialogCheckbox
}