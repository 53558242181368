import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { Link } from './styles';

interface ComponentProps extends NavLinkProps {
    name: string;
    icon: IconProp;
    disabled?: boolean;
}

const Component: React.FC<ComponentProps> = ({
    name,
    icon,
    disabled = false,
    ...props
}) => {


    return <Link
        disabled={disabled}
        className={({ isActive }) => !!isActive ? "active" : ""}
        {...props}
    >
        <FontAwesomeIcon icon={icon} />
        {name}
    </Link>;
}

export default Component;