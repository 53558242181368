import { Grid } from '@mui/material';
import React from 'react';

// import { Container } from './styles';

interface ComponentProps {
    children: any
}

const Component: React.FC<ComponentProps> = ({
    children
}) => {
  return <Grid
    container
    item
    justifyContent={'center'}
    rowGap={4}
    
  >
    {children}
  </Grid>;
}

export default Component;