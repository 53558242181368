import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface initialStateProps {
  step: number,
  data: any,
  isLastStep: boolean
}


const initialState: initialStateProps = {
  step: 0,
  data: {},
  isLastStep: false
}

const formWizardSlice = createSlice({
  name: 'formWizard',
  initialState,
  reducers: {
    next: (state, action: PayloadAction<object>) => {
      const deepData = JSON.parse(JSON.stringify(state.data))
      const deepPayload = JSON.parse(JSON.stringify(action.payload))

      state.data = { ...deepData, ...deepPayload }
      state.step++
    },
    goStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload
    },
    addData: (state, action: PayloadAction<object>) => {
      const deepData = JSON.parse(JSON.stringify(state.data))
      const deepPayload = JSON.parse(JSON.stringify(action.payload))

      state.data = { ...deepData, ...deepPayload }
    },
    isLastStep: (state, action: PayloadAction<boolean>) => {
      state.isLastStep = action.payload
    },
    setFormWizardInitialState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const { next, goStep, addData, isLastStep, setFormWizardInitialState } = formWizardSlice.actions;

export default formWizardSlice.reducer;