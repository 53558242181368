import { StepClosed } from "../../components";

import { slices } from "@store";
import { useDispatch, useSelector } from 'react-redux';
import { reducers } from "@store";

import { pontualConsult } from "@utils";

const Closed: React.FC = () => {
  const dispatch = useDispatch();
  const { data } = useSelector((state: reducers.RootState) => state.formWizard);

  const handleEditStep = () => {
    dispatch(slices.formWizard.goStep(2));
  }

  if (!!data.api && !!!data.condition) {
    const value: any = pontualConsult.getDataOption("apis", data.api).title
  
    return (
      <StepClosed title="API selecionada" value={value} handleEdit={handleEditStep} />
    )
  }

  const value = `Api: ${pontualConsult.getDataOption("apis", data.api)?.title} - Tipo de consulta: ${pontualConsult.getDataOption("conditions", data.condition)?.title}`;

  return (
    <StepClosed title="Configuração selecionada" value={value} handleEdit={handleEditStep} />
  )

}

export default Closed;