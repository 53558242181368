import React from 'react';
import { CircularProgress } from '@mui/material';

import './loadingSection.css'

const LoadingSection = () => {
    return <div className='loading-container'>
        <h3 className='loading-title'>Gerando token!</h3>
        <p className='loading-paragraph'>Estamos preparando o seu token, isso pode levar alguns segundos.</p>
        <CircularProgress thickness={2} sx={{ color: '#0068ff', order: 2}}></CircularProgress>
    </div>;
}

export default LoadingSection;