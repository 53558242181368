import { Grid, ToggleButtonProps, } from '@mui/material';
import React from 'react';

import Item from './styles';
import { IconType } from 'react-icons';

interface IComponent extends ToggleButtonProps {
    label: any
    value: string,
    Icon?: IconType
}

const Component: React.FC<IComponent> = ({
    label,
    value,
    Icon,
    ...props
}) => {
    return <Item value={value} key={value} {...props} disableRipple>
        <Grid container gap={2} alignItems={'center'} justifyContent={'center'}>
            {!!Icon && (
                <Icon size={16} />
            )}
            <Grid item>
                {label}
            </Grid>
        </Grid>
    </Item>
}


export default Component;