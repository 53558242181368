import axios from "axios";

import APIS from "../apis";
import { interceptors } from "@utils";

axios.interceptors.request.use(
  config => {
    const sessionId = interceptors.getSession()

    config.headers["Content-Type"] = "application/json"
    config.headers["Accept"] = "application/json"
    config.data = { sessionId, ...config.data }

    return Promise.resolve(config)
  },
  error => {
    return Promise.reject(error)
  },
)

axios.interceptors.response.use(
  response => {
    const apiName = interceptors.getApiName(response.config.url)
    const API = APIS.find((API) => apiName?.includes(API.NAME))

    API?.HANDLE_ERRORS(response)
  
    return response
  },
  error => {
    const urlParts = interceptors.getUrlParts(error?.config?.url)
    const API = APIS.find((API) => urlParts?.find((item: any) => item.includes(API.NAME)))

    API?.HANDLE_ERRORS(error?.response)

    return Promise.reject(error)
  },
)



export default axios;