import { Box } from "@mui/material"
import { Theme, styled } from "@mui/material/styles"

const Category = styled(Box)(({
    active,
    theme,
}: { theme?: Theme, active: boolean }) => {

    const activeStyle = !!active ? {
        color: theme?.palette.secondary.main,
        backgroundColor: '#D3D6F2',
        borderRadius: '4px',
    }
        :
        ""

    return ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme?.spacing(1),
        padding: theme?.spacing(2),
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
        '&:hover': {
            color: theme?.palette.secondary.main,
            borderRadius: '4px',
            backgroundColor: '#D3D6F2',
        },
        '&:hover #firstChild *': {
            color: theme?.palette.secondary.main,
            // borderRadius: '4px',
            // backgroundColor: '#D3D6F2',
        },
        ...activeStyle
    })
})

const MobileCategory = styled(Box)(({
    active,
    theme,
}: { theme?: Theme, active: boolean }) => {

    const activeStyle = !!active ? {
        '& *': {
            color: theme?.palette.neutral?.white,
        },
        background: `radial-gradient(90% 50% at 60% 70%, ${theme?.palette.secondary.main} 98.5%, rgba(255, 255, 255, 0) 100%)`,
    } : ""

    return ({
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'calc(50% - 8px)',
        height: '100%',
        borderRadius: '8px',
        border: '1px solid #b1b9bd',
        background: 'radial-gradient(80% 43.5% at 50% 100%, #E5EFFF 98.5%, rgba(255, 255, 255, 0) 100%)',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        backgroundColor: 'white',
        ...activeStyle
    })
})

const Content = styled(Box)(({
    theme,
}: { theme?: Theme }) => {

    return ({
        display: 'flex',
        flexDirection: 'column',
        gap: theme?.spacing(4),
        position: 'absolute',
        boxSizing: 'border-box',
        top: 0,
        left: 0,
        height: '100%',
        width: '300px',
        padding: theme?.spacing(5),
        transform: 'translateX(94px)',
        backgroundColor: theme?.palette.neutral?.[500],
        zIndex: 110,
        borderRight: `1px solid ${theme?.palette.neutral?.[300]}`,
    })
})

const LinksContainer = styled('ul')(({ theme }: { theme?: Theme }) => ({
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
}))

export {
    Category,
    MobileCategory,
    Content,
    LinksContainer
}