import React from 'react';

import { Box, Tooltip } from "@mui/material";
import { Paper } from "@components";
import { Title, Description, Chip } from "./styles";

import { BiHelpCircle } from "react-icons/bi";

type ComponentProps = {
  children: React.ReactNode,
  title: React.ReactNode,
  value?: string,
  status?: "success" | "warning" | "danger" | "information",
  helpText?: string
}

const Component: React.FC<ComponentProps> = ({ children, title, value, status, helpText }) => {
  return (
    <Paper variant="outlined">
      <Box display="flex" flexDirection="column" gap={4} p={4}>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2}>
            <Title status={status}>{title}</Title>
            {
              !!helpText && (
                <Tooltip title={helpText}>
                  <BiHelpCircle size={20} />
                </Tooltip>
              )
            }
          </Box>
          <Description>{children}</Description>
        </Box>
        {
          value && (
            <Box width="fit-content">
              <Chip status={status}>{value}</Chip>
            </Box>
          )
        }
      </Box>
    </Paper>
  );
}

export default Component;