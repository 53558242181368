import { styled } from '@mui/material/styles';

const Title = styled("h3")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}))

const Text = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.primary.blue,
  marginBottom: theme.spacing(1)
}))

export {
  Text,
  Title
}