import api from "./interceptors/headerSession";
import APIS from "./apis";

import { PlatformTypes } from "@types";
import { AxiosResponse } from "axios";

const API = APIS.find(API => API.NAME === "PLATAFORMA");

const BASE_URL = API?.ENVIROMENTS;

type ConsultType = {
  api: PlatformTypes.Api
  Datasets: string,
  q: string
}

const Consult = async ({ api: apiName, Datasets, q }: ConsultType): Promise<AxiosResponse> => api.post(
  `${BASE_URL?.PRODUCTION}/${apiName}`,
  { Datasets, q },
  { headers: { "query-origin": "bdc-center" } }
);

const GetDatasets = async (data: any): Promise<AxiosResponse> => {
  return api.post(`${BASE_URL?.PRODUCTION}/metadados/datasets`, data)
};

const GetAttributes = async (data: any): Promise<AxiosResponse> => api.post(`${BASE_URL?.PRODUCTION}/metadados/datasets/atributos`, data);

const GetDatasetsDetails = async (data: any): Promise<AxiosResponse> => api.post(`${BASE_URL?.PRODUCTION}/metadados/datasets/detalhes`, data);

const GetSavedConsults = async (data: any): Promise<AxiosResponse> => api.post(
  `${BASE_URL?.PRODUCTION}/views/obterDetalhes`,
  data,
  { headers: { Scope: "DOMAIN" } }
);

const SaveConsult = async (data: any): Promise<AxiosResponse> => api.post(`${BASE_URL?.PRODUCTION}/views/adicionar`, data);

export {
  Consult,
  GetAttributes,
  GetDatasets,
  GetDatasetsDetails,
  GetSavedConsults,
  SaveConsult
}