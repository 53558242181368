import React from 'react';
import { styled } from '@mui/system';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

interface CustomSingleDatePickerProps extends DatePickerProps<Dayjs> {
    LabelText: string;
    handleChange: (value: Dayjs | null) => void;
    handleFieldErrors?: () => void;
    singleValue: string
}

const CustomDateTimePickerContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    padding: 0,
    height: 80.5,
}

const CustomDateTimePickerDivStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: 1
}

const CustomDateTimePickerLabelStyle: React.CSSProperties = {
    fontFamily: "'Be Vietnam'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '140%',
    color: '#000066',
    marginLeft: '4px'
}

const StyledDatePicker = styled(DatePicker<Dayjs>)`

        &&{
            position: relative;
        }

        
        & .MuiIconButton-root{
            margin: 0;
            width: 24px;
            height: 24px;
            border-radius: 50%;
        }

        & .MuiSvgIcon-root{
            margin-rigth: 1px;
            width: 16px;
            heigth: 16px;
            color: #BED0D7;
        }

        & .MuiInputBase-root{
            padding-right: 14px;
            font-family: 'Be Vietnam';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 180%;
            color: #555866;
            height: 55px;
            gap: 0px;

            
        }

        & .MuiInputBase-input{
            font-family: 'Be Vietnam';
            padding: 10px 10px 10px 16px;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 150%;
            color: #555866;
        }

        & .MuiInputBase-root:hover{
            .MuiOutlinedInput-notchedOutline{
                border: 1px solid #BED0D7;
                border-radius: 8px;
            }
        }

        & .MuiFormLabel-root{
            font-family: 'Be Vietnam';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 180%;
            color: #555866;
        }

        

        & .MuiFormLabel-filled, .MuiInputLabel-shrink{
            display: none;
        }

        & .MuiOutlinedInput-notchedOutline{
            border: 1px solid #BED0D7;
            border-radius: 8px;
            height: 55px;
            top: 0;
            margin: 0;
            
            & legend{
                display: none;
                visibility: hidden;
                opacity: 0;
                margin: 0;
                padding: 0;
            }
        }

        
    `;

const CustomSingleDatePicker = ({ LabelText, handleFieldErrors, handleChange, singleValue, views, disableFuture }: CustomSingleDatePickerProps) => {

    return <div style={CustomDateTimePickerContainerStyle}>
        <label style={CustomDateTimePickerLabelStyle}>{LabelText}</label>
        <div style={CustomDateTimePickerDivStyle}>
            <StyledDatePicker
                disableFuture={disableFuture}
                views={views}
                label="Selecione o mês desejado"
                onChange={handleChange}
                value={singleValue === 'null' ? null : dayjs(singleValue)}
                
            />
        </div>
    </div>
}
export default CustomSingleDatePicker;


