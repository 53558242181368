import React from "react";

import { Box } from "@mui/material";
import { Paper, RadioGroup, Onboarding } from "@components";
import { Header } from "../../components";
import { Description, Item, Title } from "../styles";

import { options, steps } from "@constants";

import { useFormContext } from "react-hook-form";

import { useSelector } from "react-redux";
import { useFetch } from "@hooks";
import { Platform } from "@services";
import { reducers } from "@store";

const Openned = () => {
  const { watch } = useFormContext();

  const { data: { api, datasets } } = useSelector((state: reducers.RootState) => state.formWizard);
  const [enableAlternativeKeys, setEnableAlternativeKeys] = React.useState(false);

  const checked = watch().condition;

  const apiEnglishName = options.pontualConsult.apis.find(item => item.value === api)?.englishName || "";

  const datasetsAvailable = Object.fromEntries(
    Object.entries(datasets || {}).filter(([key, value]: any) => !!value.selected)
  );
  const stringDatasets = Object.keys(datasetsAvailable).join(",");

  const { data, isLoading } = useFetch(
    Platform.GetDatasetsDetails,
    {
      Api: apiEnglishName,
      Datasets: stringDatasets
    }
  );

  const datasetsBlockedByAlternativeKeys = [
    "ondemand_pc_antecedente_by_state_person"
  ]

  React.useEffect(() => {
    const hasDatasetBlockedByAlternativeKeys = Object.keys(datasetsAvailable).some((datasetAvailabe) => datasetsBlockedByAlternativeKeys.includes(datasetAvailabe));

    if (!!data && !hasDatasetBlockedByAlternativeKeys) {
      const hasAlternativeKeys = !!(
        data?.MinimalQueryParameters?.reduce((accumulator: any, current: any) => accumulator.concat(current?.Replacements || []), [])
      ).length;

      setEnableAlternativeKeys(hasAlternativeKeys)
    }

    setEnableAlternativeKeys(!hasDatasetBlockedByAlternativeKeys);
  }, [data]);

  return (
    <>
      <Paper>
        <Box p={6}>
          <Box display="flex" flexDirection="column" gap={8}>
            <Header title="Selecione o tipo de consulta" subtitle="Selecione o tipo de consulta a ser utilizado." />

            <RadioGroup.Container name="condition">
              <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} gridAutoRows="1fr" width="100%" gap={4} id="condition-options">
                {
                  options.pontualConsult.conditions.map((option) => (
                    <RadioGroup.Item
                      key={option.value}
                      value={option.value}
                      disabled={
                        option.value === "alternative_keys" ?
                          !enableAlternativeKeys || isLoading :
                          isLoading
                      }
                    >
                      <Item
                        variant="outlined"
                        checked={checked === option.value}
                        clickable={
                          option.value === "alternative_keys" ?
                            enableAlternativeKeys || !isLoading :
                            !isLoading
                        }
                      >
                        <Box component="label" htmlFor={option.value}>
                          <Box display="flex" alignItems="flex-start" height="100%" gap={6} px={4} py={6}>
                            <Box display="flex" flexDirection="column" gap={4}>
                              <Box display="flex" alignItems="center" gap={2}>
                                <Title>{option.title}</Title>
                              </Box>
                              <Description dangerouslySetInnerHTML={{ __html: option.description }} />
                            </Box>
                          </Box>
                        </Box>
                      </Item>
                    </RadioGroup.Item>
                  ))
                }
              </Box>
            </RadioGroup.Container>
          </Box>
        </Box >
      </Paper >
      <Onboarding
        steps={steps.PontualConsult.Condition}
        controlPath='PontualConsult.Condition'
      />
    </>
  )
}

export default Openned;