import { GeneralComponents } from '@components';
import { isDesktopWidth } from '@constants';
import { Line, ResponsiveLine } from '@nivo/line'
import {TooltipWrapper} from '@nivo/tooltip'
import dayjs from 'dayjs';
dayjs.locale('pt-br')


const customColors: { [key: string]: string } = {
  BigId: '#0068ff',
  Plataforma: '#ff003c',
  BigBoost_V1: '#000066',
  BigMarket: '#ffd40c'
};


const tooltipContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  background: '#FFF',
  padding: '8px',
  fontSize: '10px',
  textAlign: 'center',
  zIndex: 10,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
}


const tooltipContainerStyleMobile: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  background: '#FFF',
  padding: '8px',
  fontSize: '10px',
  textAlign: 'center',
  zIndex: 10,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
}

const hiddenChartDivContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}

const ResponsiveLineChart = ({ data, showChart }: any) => {

  if(data === undefined || data.length === 0){
    return <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não consumiu nenhum produto. Em breve você poderá visualizar o seu consumo histórico.' />
  }

  return isDesktopWidth ?
      <ResponsiveLine
        data={data}
        enableArea
        margin={{ top: 50, right: 50, bottom: 70, left: 100 }}
        colors={({ id }) => customColors[id]}
        xScale={{ type: 'time', precision: 'month', useUTC: false }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',

        }}
        xFormat={(value) => dayjs(value.toString()).format('MMMM [de] YYYY')}
        yFormat={(value) => parseFloat(value.toString()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (value) => dayjs(value).format('MMM YYYY'),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          tickValues: 'every month'
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Preço',
          legendOffset: -90,
          legendPosition: 'middle',
          format: (value) => value.toLocaleString('pt-BR')
        }}
        enableGridX={false}
        lineWidth={3}
        enablePoints={true}
        pointSize={5}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        theme={{
          fontFamily: 'Be vietnam',
          fontSize: 12,
          textColor: '#7D8285',
          grid: {
            line: {
              stroke: '#DDE7EA',
              strokeWidth: 1,
              strokeDasharray: '6 6',
            }
          }
        }}
        tooltip={({ point }) =>
          <div style={tooltipContainerStyle}>
            <span><strong>Mês: </strong>{point.data.xFormatted}</span>
            <span><strong>Preço: </strong>{point.data.yFormatted}</span>
          </div>
        }
        tooltipFormat={(value) => dayjs(value).format('MMM YYYY')}
        useMesh={true}
        layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
        legends={[
          {
            anchor: 'top-right',
            direction: 'row',
            justify: false,
            translateX: 25,
            translateY: -40,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 28,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            toggleSerie: true
          }
        ]}
      />
    :
    <div style={hiddenChartDivContainer}>
      <Line
        data={data}
        enableArea
        height={400}
        width={1200}
        margin={{ top: 30, right: 40, bottom: 70, left: 40}}
        colors={({ id }) => customColors[id]}
        xScale={{ type: 'time', precision: 'month', useUTC: false }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',

        }}
        xFormat={(value) => dayjs(value.toString()).format('MMMM [de] YYYY')}
        yFormat={(value) => parseFloat(value.toString()).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        curve="natural"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: (value) => dayjs(value).format('MMM YYYY'),
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          tickValues: 'every month'
        }}
        axisLeft={null}
        enableGridX={false}
        lineWidth={3}
        enablePoints={true}
        pointSize={5}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        theme={{
          fontFamily: 'Be vietnam',
          fontSize: 12,
          textColor: '#7D8285',
          grid: {
            line: {
              stroke: '#DDE7EA',
              strokeWidth: 1,
              strokeDasharray: '6 6',
            }
          }
        }}
        tooltip={({ point }) =>
        <TooltipWrapper anchor='bottom' position={[0,0]}>
          <div style={tooltipContainerStyleMobile}>
            <span><strong>Produto: </strong><p>{point.serieId}</p></span>
            <span><strong>Mês: </strong><p>{point.data.xFormatted}</p></span>
            <span><strong>Preço: </strong><p>{point.data.yFormatted}</p></span>
          </div>
          </TooltipWrapper>
          }
        tooltipFormat={(value) => dayjs(value).format('MMM YYYY')}
        useMesh={true}
        layers={['grid', 'markers', 'axes', 'areas', 'crosshair', 'lines', 'points', 'slices', 'mesh', 'legends']}
        legends={[
          { 
            anchor: 'top-left',
            direction: 'column',
            justify: false,
            translateX: 0,
            translateY: 0,
            itemsSpacing: 10,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 28,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            toggleSerie: true,
          }
        ]}
      />
    </div>
}

export default ResponsiveLineChart