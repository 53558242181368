import { Step } from "react-joyride";
import { isDesktopWidth } from '@constants';

const steps: Step[] = [
  {
    target: '.financial-management-main-container',
    title: 'Acompanhe o relatório de consumo de seu domínio',
    content: 'Aprenda a visualizar e baixar seus relatórios de consumo neste tour',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
  },
  {
    target: isDesktopWidth ? '.usage-summary-filter-section' : '.usage-summary-filter-button',
    title: 'Busque rapidamente o que precisa',
    content: 'Utilize os filtros da tabela para encontrar relatórios pelo período de referência.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' }
  },
  {
    target: '.usage-summary-table-container',
    title: 'Selecione o mês desejado',
    content: 'Utilize a tabela para localizar e selecionar seus relatórios. Você pode clicar no mês desejado para ter uma visualização prévia do seu PDF.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'auto' : 'top-end' }
  },

  {
    target: '.pdf-container',
    title: 'Visualize o seu relatório',
    content: 'Veja uma prévia do seu arquivo PDF antes de baixá-lo.',
    disableBeacon: true,
    floaterProps: { placement: 'top-start' }
  },
  {
    target: '.financial-management-main-container',
    title: 'Tour finalizado',
    content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'center' }
  },
];

export default steps;