import React, { useRef } from 'react';

import './quickOptions.css'
import { navbarCategories } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';
import { useNavigate } from 'react-router-dom';
import { BsArrowRightShort, BsDatabase, BsQuestionCircle } from 'react-icons/bs';
import { MdAttachMoney, MdFingerprint, MdOutlineGeneratingTokens } from 'react-icons/md';
import { FaUsersCog } from 'react-icons/fa';
import { Box, Typography } from '@mui/material';
import { Button, GeneralComponents, Paper } from '@components';

const QuickOptions: React.FC = () => {

  const isSystemAdmin = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.isSystemAdmin)
  const dispatch = useDispatch()
  const navigate = useNavigate()


  const handleShortcutClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    const id = event.currentTarget.tagName === 'span' ? event.currentTarget.parentElement?.id : event.currentTarget.id;

    const navLink = navbarCategories
      .flatMap((categoria) => categoria.links)
      .find((link) => link.name === id);


    if (navLink && id !== 'BigID (Em breve)') {
      navigate(navLink.url)
      dispatch(slices.lastUsed.addFeature({ name: navLink.name, description: navLink.description, link: navLink.url, icon: navLink.urlIcon }));
    } else if (navLink) {
      window.open(navLink.url, '_blank')
    }

  }

  const handleClickPontualConsult = () => {
    navigate("/dashboard/platform/pontual-consult/new")
  }

  return <section className={isSystemAdmin ? 'quick-options-container' : 'common-quick-options-container'}>
    <div className='coming-soon-container'>
      <Box display="flex" width="100%" gap={5}>
        <Box width="50%" className="coming-soon-platform-container">
            <Typography variant="h3" color="secondary.main">Plataforma</Typography>
            <Button
              color="secondary"
              size="small"
              EndIcon={
                <BsArrowRightShort size={20} />
              }
              onClick={handleClickPontualConsult}
            >
              Ir para a consulta pontual
            </Button>
        </Box>
        <Box width="50%" className="coming-soon-big-id-container">
          <Typography variant="h3" color="secondary.main">BigID</Typography>
          <p className='coming-soon-product-description'>Em breve você poderá realizar <strong>validações de identidade</strong> através do Center</p>
        </Box>
      </Box>
    </div>
    <div className='shortcut-container'>
      <Typography variant='h2' component={'h2'}>Atalhos</Typography>
      {isSystemAdmin ?
        <div className='shortcut-content'>
          <div className='shortcut-cards' id='Gestão de usuários' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><FaUsersCog color='#006' size={24}></FaUsersCog></span>
            <span className='shortcut-cards-product'>Gest. de usuários</span>
          </div>
          <div className='shortcut-cards' id='Faturas' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><MdAttachMoney color='#006' size={24}></MdAttachMoney></span>
            <span className='shortcut-cards-product'>Faturas</span>
          </div>
          <div className='shortcut-cards' id='Consulta pontual' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><BsDatabase color='#006' size={24}></BsDatabase></span>
            <span className='shortcut-cards-product'>Plataforma</span>
          </div>
          <div className='shortcut-cards' id='BigID (Em breve)' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><MdFingerprint color='#006' size={24}></MdFingerprint></span>
            <span className='shortcut-cards-product' >BigID</span>
          </div>
        </div>
        :
        <div className='shortcut-content'>
          <div className='shortcut-cards' id='Gestão de tokens' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><MdOutlineGeneratingTokens color='#006' size={24}></MdOutlineGeneratingTokens></span>
            <span className='shortcut-cards-product'>Gestão de tokens</span>
          </div>
          <div className='shortcut-cards' id='Dúvidas frequentes (FAQ)' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><BsQuestionCircle color='#006' size={24}></BsQuestionCircle></span>
            <span className='shortcut-cards-product'>FAQ</span>
          </div>
          <div className='shortcut-cards' id='Consulta pontual (Em breve)' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><BsDatabase color='#006' size={24}></BsDatabase></span>
            <span className='shortcut-cards-product'>Plataforma</span>
          </div>
          <div className='shortcut-cards' id='BigID (Em breve)' onClick={handleShortcutClick}>
            <span className='shortcut-cards-icon-background'><MdFingerprint color='#006' size={24}></MdFingerprint></span>
            <span className='shortcut-cards-product' >BigID</span>
          </div>
        </div>
      }
    </div>
  </section>;
}

export default QuickOptions;