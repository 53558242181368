// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-container{
    height: 80vh;
    width: 98vw;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-content{
    border: 1px solid #000;
    border-radius: 15px;
    height: 400px;
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/FormBox/form.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,6BAA6B;IAC7B,mBAAmB;AACvB","sourcesContent":[".form-container{\n    height: 80vh;\n    width: 98vw;\n    margin: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.form-content{\n    border: 1px solid #000;\n    border-radius: 15px;\n    height: 400px;\n    width: 400px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-around;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
