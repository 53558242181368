import React from 'react';
import { Bar, ResponsiveBar } from '@nivo/bar';

const customColors: { [key: string]: string } = {
    Price: '#006',
    PriceProjection: '#FF003C',
};

const hiddenChartDivContainer: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
}

const valueContainer: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px'
}

const hiddenChartValueContainer: React.CSSProperties = {
    display: 'flex',
    gap: '32px',
}

const labelText: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '140%',
    display: 'flex',
    alignItems: 'center',
    color: '#555866'
}

const priceText: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '140%',
    color: '#32343C',
}

const variationText: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '140%',
    display: 'flex',
    alignItems: 'center',
    color: '#7D8285',
}

const percentageText: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '140%',
    display: 'flex',
    alignItems: 'center',
    color: '#28AD58',
}

const ResponsiveBillingBarChart: React.FC<any> = ({ data, showChart }) => {

    const sumOfAllProductsPrice = data.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.Price, 0);
    const formattedPrice = sumOfAllProductsPrice.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });


    const DefaultPriceContainer = () => (

        <foreignObject x="calc(100% - 350px)" y="40%" width='200px' height="310px">
            <div>
                <div style={valueContainer}>
                    <span style={labelText}>Valor total:</span>
                    <span style={priceText}>{formattedPrice}</span>
                </div>
            </div>
        </foreignObject>
    )

    const HiddenChartPriceContainer = () => (

        <div style={showChart ? {} : hiddenChartValueContainer}>
            <div style={valueContainer}>
                <span style={labelText}>Valor total:</span>
                <span style={priceText}>{formattedPrice}</span>
            </div>
        </div>
    )

    if (showChart) {
        return <ResponsiveBar
            data={data}
            keys={[
                'Price'
            ]}
            groupMode="grouped"
            indexBy="Product"
            margin={{ top: 0, bottom: 50, left: 100, right: 500 }}
            valueScale={{ type: 'linear'}}
            colors={({ id }) => customColors[id]}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32,

            }}
            theme={{
                fontFamily: 'Be vietnam',
                fontSize: 12,
                textColor: '#7D8285',
                grid: {
                    line: {
                        stroke: '#DDE7EA',
                        strokeWidth: 1,
                        strokeDasharray: '6 6',
                    }
                }
            }}
            axisLeft={{
                tickSize: 0,
                tickPadding: 8,
                tickRotation: 0,
                legend: 'Valor',
                legendPosition: 'middle',
                legendOffset: -85,
                format: (value) => value.toLocaleString('pt-BR')
            }}
            enableGridY={true}
            labelSkipWidth={12}
            labelSkipHeight={7}
            labelTextColor='#ffffff'
            valueFormat={(value) => value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1
                            }
                        }
                    ]
                }
            ]}
            role="products"
            layers={[DefaultPriceContainer, 'grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
        />
    } else {
        return <div style={hiddenChartDivContainer}>
            <Bar
                height={100}
                width={300}
                data={data}
                keys={[
                    'Price'
                ]}
                groupMode="grouped"
                indexBy="Product"
                margin={{ top: 0, bottom: 25, left: 150, right: 0 }}
                valueScale={{ type: 'symlog'}}
                colors={({ id }) => customColors[id]}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 0,
                    tickRotation: 0,
                    legendPosition: 'middle',
                    legendOffset: 0
                }}
                theme={{
                    fontFamily: 'Be vietnam',
                    fontSize: 12,
                    textColor: '#7D8285'
                }}
                axisLeft={null}
                enableGridY={false}
                enableLabel={false}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'left',
                        direction: 'column',
                        justify: false,
                        translateX: -120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="products"
                layers={['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']}
            ></Bar>
            <HiddenChartPriceContainer></HiddenChartPriceContainer>
        </div>
    }
}


export default ResponsiveBillingBarChart;