import React from "react";

import { Box, Tooltip } from "@mui/material";
import Attribute from "../Attribute";
import Loading from "../Loading";

import { useFetch } from "@hooks";
import { Platform } from "@services";
import { useSelector } from "react-redux";
import { reducers } from "@store";
import { options } from "@constants";
import { Notification } from "@components";
import { handleString } from "@utils";

interface ComponentProps {
  dataset: string
}

const Component: React.FC<ComponentProps> = ({
  dataset
}) => {
  const { data: { api } } = useSelector((state: reducers.RootState) => state.formWizard);
  const apiEnglishName = options.pontualConsult.apis.find(item => item.value === api)?.englishName || ""

  const { data, error, isLoading } = useFetch(
    Platform.GetAttributes,
    {
      Api: apiEnglishName,
      Datasets: dataset
    },
  );

  if (isLoading) {
    return (
      <Box pl={8}>
        <Loading />
      </Box>
    )
  }

  const attributes = Object.values(data?.["DatasetFieldsInfo"]?.[0]?.[dataset] || {})[0];
  const attributesAvailable = Object.entries(attributes || []).filter(([key, value]) => !!value.IsFieldSelectable);

  const hasError = !!error || (data?.Status?.Code === -110);

  if (!!!isLoading && hasError) {
    return (
      <Box p={2}>
        <Notification title="Erro" description="Houve um problema ao consultar os atributos deste dataset" variant="danger" />
      </Box>
    )
  }

  if (!!!isLoading && !!!attributesAvailable.length) {
    return (
      <Box p={2}>
        <Notification title="Nenhum atributo" description="Não há atributo disponível para este dataset" variant="information" />
      </Box>
    )
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1}>
        {
          attributesAvailable.map(([key, value]) => (
            <Tooltip title={value?.Name?.["pt-br"] || key} placement="right">
              <Box width="fit-content">
                <Attribute
                  key={key}
                  value={key}
                  label={handleString.toHumanize(key)}
                  name={`datasets.${dataset}.content.${key}.content`}
                  isSelectable={value?.IsFieldSelectable}
                  attributes={value?.InnerObjects}
                />
              </Box>
            </Tooltip>
          ))
        }
      </Box>
    </>
  )
}

export default Component