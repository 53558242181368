import api from "./interceptors/bodySession";
import APIS from "./apis";

const API = APIS.find(API => API.NAME === "BILLINGJEAN");

const BASE_URL = API?.ENVIROMENTS;

const calculateConsultPrice = async (data: any) => api.post(`${BASE_URL?.HML}/pricing/calculator`, data);

const listPartials = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/consumption/listpartials`, data)

const getPartialDetail = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/consumption/getpartialdetail`, data)

const listConsumptionsHml = async (data?: any) => await api.post(`${BASE_URL?.HML}/consumption/listconsumptions`, data)

const listConsumptions = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/consumption/listconsumptions`, data)

const listInvoices = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/invoice/listinvoices`, data)

const listInvoicesHml = async (data?: any) => await api.post(`${BASE_URL?.HML}/invoice/listinvoices`, data)

const getGroupInfo = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/group/getgroupinfo`, data)

const getGroupInfoHml = async (data?: any) => await api.post(`${BASE_URL?.HML}/group/getgroupinfo`, data)

const listConsolidatedReports = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/report/listconsolidatedreports`, data)

const getConsolidatedReport = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/report/getconsolidatedreport`, data)

const getConsolidatedCsvReport = async (data?: any) => await api.post(`${BASE_URL?.PRODUCTION}/report/getconsolidatedcsvreport`, data)

export {
  calculateConsultPrice,
  listPartials,
  getPartialDetail,
  listConsumptions,
  listConsumptionsHml,
  listInvoices,
  listInvoicesHml,
  getGroupInfo,
  getGroupInfoHml,
  listConsolidatedReports,
  getConsolidatedReport,
  getConsolidatedCsvReport
}