import { GeneralComponents } from '@components';
import { slices } from '@store';
import React from 'react';
import { AiOutlineFileSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';

const TokenDetailsCell = ({ params }: any) => {
    const dispatch = useDispatch()

    const handleTokenDetailsButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.tokenDetails.setParams(params.row))
        dispatch(slices.tokenDetails.setShowTokenDetailsSection(true))
    }

    return <GeneralComponents.StyledGridActionsCellItem
        icon={<AiOutlineFileSearch color="#0068ff" size={24} />}
        label="Detalhes do token"
        onClick={handleTokenDetailsButtonClick}
    />;
}

export default TokenDetailsCell;