import { Step } from "react-joyride";
import { isDesktopWidth } from '@constants';

const steps: Step[] = [
  {
    target: '.financial-management-main-container',
    title: 'Acompanhe suas faturas',
    content: 'Aprenda a visualizar e baixar suas faturas neste tour',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    disableScrolling: true
  },
  {
    target: isDesktopWidth ? '.invoices-container' : '.invoices-details-cell',
    title: 'Encontre suas faturas',
    content: 'Utilize a tabela para localizar e consultar suas faturas. Você também pode fazer o download delas quando necessário.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'top-start' : 'bottom-end' },
    disableScrolling: true
  },
  {
    target: isDesktopWidth ? '.invoices-filter-container' : '#invoices-filter-button',
    title: 'Busque rapidamente o que precisa',
    content: 'Utilize os filtros da tabela para encontrar uma fatura pelo seu mês de referência ou status.',
    disableBeacon: true,
    floaterProps: { placement: 'left-start' },

  },
  {
    target: '.notification-icon',
    title: 'Avisos de faturas em atraso',
    content: 'Se uma fatura estiver atrasada, enviaremos um aviso na sua central de notificações para lembrá-lo(a) de efetuar o pagamento.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' }
  },
  {
    target: '.financial-management-main-container',
    title: 'Tour finalizado',
    content: 'Você pode refazer o tour sempre que precisar relembrar algo, utilizando o botão na barra superior. Encontre respostas rápidas ou nossa documentação técnica em nossa central de ajuda.',
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'center' },
    disableScrolling: true
  },
];

export default steps;