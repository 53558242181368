import React from 'react';
import { GeneralComponents } from '@components';
import { installmentsGridColumns } from "@constants";
import { GridValidRowModel } from '@mui/x-data-grid/models/gridRows';
import InstallmentDetails from '../InstallmentDetails';


interface InstallmentSectionProps {
    InstallmentSectionRows: GridValidRowModel[],
}

const InstallmentSection: React.FC<InstallmentSectionProps> = ({ InstallmentSectionRows }: InstallmentSectionProps) => {

    return <section>
        <div className='product-consumption-container-list'>
            <div className='get-partial-detail-table-container'>
                <GeneralComponents.StyledDataGridWithoutPagination rows={InstallmentSectionRows} columns={installmentsGridColumns}></GeneralComponents.StyledDataGridWithoutPagination>
            </div>
        </div>
        <InstallmentDetails/>
    </section>
}

export default InstallmentSection;