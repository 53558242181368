import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BillingTypes } from "@types";

interface SeeDetailsInfo {
    Consumption: BillingTypes.ConsumptionInfoApiResponse[],
    showModal: boolean
}

const initialState: SeeDetailsInfo = {
    Consumption: [
        {
            Scope: '',
            Dataset: '',
            FreeRequests: 0,
            PaidRequests: 0,
            TotalRequests: 0,
            ListPrice: 0,
            AveragePrice: 0,
            Price: 0,
            VolumeDiscount: 0
        }
    ],
    showModal: false
}

const seeDetailsInfo = createSlice({
    name: 'seeDetails',
    initialState,
    reducers: {
        setSeeDetailsInfo: (state, action: PayloadAction<SeeDetailsInfo>) => {
            return { ...state, ...action.payload }
        }
    }
});

export const { setSeeDetailsInfo } = seeDetailsInfo.actions;

export default seeDetailsInfo.reducer;