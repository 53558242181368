import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Data, Print } from "./components";
import { Tab, Spinner } from "./styles";
import { CgSpinner } from "react-icons/cg";

import { useLocation, Navigate } from "react-router-dom";

import SwipeableViews from "react-swipeable-views";

import { useSelector } from "react-redux";
import { reducers } from "@store";

const Page = () => {
  const [tabActive, setTabActive] = React.useState<number>(0);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true
  });
  const { consult, qualityImage: qualityImageData } = useSelector((state: reducers.RootState) => state.exportPdfDocumentoscopia);
  const { state } = useLocation();

  const { type, documents, qualityImage } = state || { type: "", documents: [], qualityImage: false };

  const handleChangeTab = (value: any) => {
    setTabActive(value);
  }

  if (!!!documents || !!!documents?.length) {
    return <Navigate to="/dashboard/apps/bigid/documentoscopia" replace />
  }

  return (
    <>
      <Box display="flex" flexDirection="column" gap={2} displayPrint="none">
        <Box display="flex" gap={2}>
          {
            documents?.map((_: any, index: number) => {
              const sufix = (index + 1).toString().padStart(2, "0");
              const active = tabActive === index;
              const qualityImageIsLoading = (
                qualityImageData.filter((quality: any) => quality.documentIndex === index)
                  .every((quality: any) => !!quality.isLoading)
              );
              const consultIsLoading = (
                consult.filter((item: any) => item.index === index)
                  .every((item: any) => !!item.isLoading)
              )
              const isLoading = qualityImageIsLoading || consultIsLoading;

              return (
                <Tab
                  key={index}
                  active={active}
                  onClick={() => handleChangeTab(index)}
                >
                  {
                    !!!isMobile && "Documento "
                  }
                  {sufix}
                  {
                    isLoading && (
                      <Spinner>
                        <CgSpinner size={20} />
                      </Spinner>
                    )
                  }
                </Tab>
              )
            })
          }
        </Box>
        <SwipeableViews index={tabActive}>
          {
            documents.map((document: any, index: number) => {
              return (
                <Data
                  type={type}
                  document={document}
                  qualityImage={qualityImage?.active?.selected}
                  index={index}
                />
              )
            })
          }
        </SwipeableViews>
      </Box>
      <Print type={type} documents={documents} />
    </>
  )
}

export default Page;