import { reducers, slices } from '@store';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { MainPagesTemplateComponents, NotificationCenter } from '@components';
import UserProfile from './UserProfile';

import './template.css'
import Template from './styles'


const Dashboard: React.FC = () => {
  const dispatch = useDispatch()
  const sideSectionOpen = useSelector((state: reducers.RootState) => state.sideSection.open)
  const sessionId = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.sessionId);

  const location = useLocation();

  const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (sideSectionOpen) {
      dispatch(slices.sideSection.setOpen(false))
      dispatch(slices.userProfile.setOutsideClickOccurred(true))
    }
  }

  if (!!!sessionId) {
    return <Navigate to="/login" state={{ key: location.pathname }} />
  }

  return (
    <Template
      onMouseDown={handleOutsideClick}
      className='print-template'
    >
      <MainPagesTemplateComponents.Header />
      <MainPagesTemplateComponents.Navbar />
      <Outlet />
      <UserProfile />
      <NotificationCenter />
      <MainPagesTemplateComponents.Footer />
    </Template>
  )
}

export default Dashboard;