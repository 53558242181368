import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#condition-options',
    title: 'Selecione o tipo de consulta',
    content: 'Escolha entre consulta padrão ou consulta com chaves alternativas.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#option-default',
    title: 'Consulta padrão',
    content: 'Preencha campos obrigatórios para uma consulta mais assertiva.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-end' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#option-alternative_keys',
    title: 'Chaves alternativas',
    content: 'Realize uma consulta utilizando outros tipos de chaves. Quanto mais chaves preenchidas melhor a assertividade da busca.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom-end' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;