const selectedButtonStyle = {
  padding: '8px 12px',
  background: '#FFFFFF',
  borderRadius: '8px !important', 
  fontFamily: 'Picadilly',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '14px',
  lineHeight: '170%',
  color: '#0068FF',
  transition: 'all .5s ease',

  '&:hover': {
    backgroundColor: 'white',
    color: '#006',
  },
}

export default selectedButtonStyle;