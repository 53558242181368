import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface InstallmentDetailsInfo {
    open: boolean;
    params: any;
}


const initialState: InstallmentDetailsInfo = {
   open: false,
   params: undefined
}

const installmentDetailsSlice = createSlice({
  name: 'installmentDetails',
  initialState: initialState,
  reducers: {
    setShowInstallmentDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowInstallmentDetailsSection, setParams } = installmentDetailsSlice.actions;

export default installmentDetailsSlice.reducer;