import React, { useCallback, useState } from 'react';

import { GeneralComponents } from "@components";

import { showToastCustomErrorMessage, showToastCustomInfoMessage, handleAxiosError, isFrom } from '@utils';

import { BigBrother } from '@services';

import './recoverPassword.css';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';


interface RecoverPasswordProps {
    handleReturnToLogin: () => void
}

const PasswordRecover: React.FC<RecoverPasswordProps> = ({ handleReturnToLogin }: RecoverPasswordProps) => {

    const { handleSubmit, reset, control } = useForm<any>()
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        try {
            const token = await executeRecaptcha('recoverPassword');

            if (token) {
                return token
            }
        } catch (err: any) {
            showToastCustomErrorMessage('Erro ao verificar o ReCaptcha', 'Verifique sua conexão com a internet.')
        }

    }, [executeRecaptcha]);

    const handleGenerateTokenSubmit: SubmitHandler<any> = async (data, event) => {
        event?.preventDefault()
        const tokenReCaptcha = await handleReCaptchaVerify()

        if (!!!data.login) {
            showToastCustomErrorMessage('Erro ao enviar e-mail', 'Por favor, informe o seu login no campo abaixo.')
            return
        }

        try {
            const genPwsRecoveryTokenResponse = await BigBrother.generateRecoveryToken({
                login: data.login,
                tokenReCaptcha,
                isFrom: isFrom()
            }) 
            handleGenPwsRecoveryTokenResponse(genPwsRecoveryTokenResponse)
            setIsLoading(false)  
        } catch (error: any) {
            handleAxiosError(error)
            setIsLoading(false)  
        }
    }

    const handleGenPwsRecoveryTokenResponse = (pwsRecoveryTokenResponse: any) => {
        const { data: pwsRecoveryTokenResponseData } = pwsRecoveryTokenResponse



        if (!pwsRecoveryTokenResponseData.result) {
            showToastCustomErrorMessage('Erro na recuperação de senha', pwsRecoveryTokenResponseData.message)
        } else {
            showToastCustomInfoMessage('Email enviado', 'Siga as instruções para recuperar a sua senha.')
            reset()
            handleReturnToLogin()
        }
    }

    return (
        <main className='password-recover-main-container'>
            <section className="pre-auth-container">
                <div className="pre-auth-presentation-content">
                    <h1 className="password-recover-presentation-title">Recuperação de senha</h1>
                    <p className="password-recover-presentation-paragraph">Insira seu nome ou e-mail cadastrado. Siga as instruções enviadas para o seu e-mail para recuperar a senha.</p>
                </div>
                <form className='password-recover-form' onSubmit={handleSubmit(handleGenerateTokenSubmit)}>
                    <div className='email-container'>
                        <label className='pre-auth-label'>Login</label>
                        <Controller
                            name='login'
                            control={control}
                            render={({ field }) => (
                                <GeneralComponents.CustomTextField
                                    {...field}
                                    variant="outlined"
                                    fullWidth
                                    placeholder='Insira o seu login'
                                    className='email-input'
                                />
                            )}
                        />

                    </div>
                    <div className='password-recover-options-container'>
                        <GeneralComponents.ReturnLoginButton handleReturnToLogin={handleReturnToLogin} />
                        <button type='submit' className='send-email-button' disabled={!!isLoading}>{!!isLoading ? 'Enviando...' : 'Enviar e-mail'}</button>
                    </div>
                </form>
            </section>
        </main>
    );
}

export default PasswordRecover;