import { styled } from "@mui/styles";

const Label = styled('label')({
    height: '21px',
    fontFamily: "Be Vietnam",
    alignSelf: 'flex-start',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.5px',
    color: '#555866',
    marginLeft: '2px',
})

const selectedButtonStyle = {
    padding: '8px 12px',
    background: '#FFFFFF',
    borderRadius: '8px !important', 
    fontFamily: 'Picadilly',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '170%',
    color: '#0068FF',
    transition: 'bacground-color .5s ease, color .5s ease',
  
    '&:hover': {
      backgroundColor: 'white',
      color: '#006',
    },
  }

export {Label, selectedButtonStyle}