import { RiCloseCircleLine, RiCloseLine, RiErrorWarningLine, RiInformationLine } from 'react-icons/ri';
import { Id, toast, } from 'react-toastify';

import '../styles/toastMessages.css'
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { isDesktopWidth } from '@constants';


const showToastCustomCreateUserSucessMessage = (user: string) => {

    toast(() => <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
        <div>
            <h4 style={{ color: '#28AD58', fontFamily: "'BeVietnam-Bold', sans-serif", fontStyle: 'normal', fontSize: 14, height: 20 }}>Usuário criado com êxito!</h4>
            <p style={{ fontSize: '12px', fontFamily: "'BeVietnam-Light', sans-serif", color: '#555866', fontWeight: 300, height: 17 }}>O usuário <b>{user}</b> foi criado com sucesso.</p>
        </div>
    </div>,
        {
            type: 'error',
            icon: <AiOutlineCheckCircle color='#28AD58' size={24} />,
            style: { borderLeft: '4px solid #28AD58', color: '#28AD58' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            closeButton: <RiCloseLine color='#28AD58' className='toast-close-button' size={20} />,
            draggable: true,
            theme: "light",
        });
        toast.clearWaitingQueue();
}


const showToastCustomEditUserSucessMessage = (user: string) => {
    toast(() => <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
        <div>
            <h4 style={{ color: '#28AD58', fontFamily: "'BeVietnam-Bold', sans-serif", fontStyle: 'normal', fontSize: 14, height: 20 }}>Usuário atualizado com êxito!</h4>
            <p style={{ fontSize: '12px', fontFamily: "'BeVietnam-Light', sans-serif", color: '#555866', fontWeight: 300, height: 17 }}>O usuário <b>{user}</b> foi atualizado com sucesso.</p>
        </div>
    </div>,
        {
            type: 'error',
            icon: <AiOutlineCheckCircle color='#28AD58' size={24} />,
            style: { borderLeft: '4px solid #28AD58', color: '#28AD58' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            closeButton: <RiCloseLine color='#28AD58' className='toast-close-button' size={20} />,
            draggable: true,
            theme: "light",

        });
        toast.clearWaitingQueue();
}


const showToastCustomSuccessMessage = (message: string, description: string) => {
    toast(() => <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
        <div>
            <h4 style={{ color: '#28AD58', fontFamily: "'BeVietnam-Bold', sans-serif", fontStyle: 'normal', fontSize: 14, height: 20 }}>{message}</h4>
            <p style={{ fontSize: '12px', fontFamily: "'BeVietnam-Light', sans-serif", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
        </div>
    </div>,
        {
            type: 'error',
            icon: <AiOutlineCheckCircle color='#28AD58' size={24} />,
            style: { borderLeft: '4px solid #28AD58', color: '#28AD58' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            closeButton: <RiCloseLine color='#28AD58' className='toast-close-button' size={20} />,
            draggable: true,
            theme: "light",
            data: { message, description }
        });
        toast.clearWaitingQueue();
}

const showToastCustomErrorMessage = (message: string, description: string, toastId?: Id) => {
    toast(() => {
        if (toastId) {
            if (!toast.isActive(toastId)) {
                return <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
                    <div>
                        <h4 style={{ color: '#FF003C', fontFamily: "'BeVietnam-Bold', sans-serif", fontStyle: 'normal', fontSize: 14, height: 20 }}>{message}</h4>
                        <p style={{ fontSize: '12px', fontFamily: "'BeVietnam-Light', sans-serif", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
                    </div>
                </div>
            } else {
                return
            }
        }else{
            return <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
            <div>
                <h4 style={{ color: '#FF003C', fontFamily: "'BeVietnam-Bold', sans-serif", fontStyle: 'normal', fontSize: 14, height: 20 }}>{message}</h4>
                <p style={{ fontSize: '12px', fontFamily: "'BeVietnam-Light', sans-serif", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
            </div>
        </div>
        }
    },
        {
            type: 'error',
            icon: <RiCloseCircleLine color='#FF003C' size={24} />,
            style: { borderLeft: '4px solid #FF003C', color: 'FF003C' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            closeButton: <RiCloseLine color='#FF003C' className='toast-close-button' size={20} />,
            draggable: true,
            theme: "light",
            data: { message, description }
        });
    toast.clearWaitingQueue();
}


const showToastCustomWarningMessage = (message: string, description: string, user?: string, toastId?: Id) => {

    toast(() => {
        if (toastId) {
            if (!toast.isActive(toastId)) {

                return <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
                    <div>
                        <h4 style={{ color: '#B48304', fontFamily: "'Be Vietnam'", fontStyle: 'normal', fontSize: 14, height: 20, lineHeight: '140%', fontWeight: 700 }}>{message}</h4>
                        <p style={{ fontSize: '12px', fontFamily: "'Be Vietnam'", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
                    </div>
                </div>
            } else {
                return
            }
        }else{
            return <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
                    <div>
                        <h4 style={{ color: '#B48304', fontFamily: "'Be Vietnam'", fontStyle: 'normal', fontSize: 14, height: 20, lineHeight: '140%', fontWeight: 700 }}>{message}</h4>
                        <p style={{ fontSize: '12px', fontFamily: "'Be Vietnam'", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
                    </div>
            </div>
        }
    },
        {
            type: 'warning',
            icon: <RiErrorWarningLine color='#B48304' size={28.5} />,
            style: { borderLeft: '4px solid #B48304', color: '#B48304' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            closeButton: <RiCloseLine color='#B48304' className='toast-close-button' size={20} />,
            draggable: true,
            theme: "light",
            toastId: toastId,
            data: { message, description, user },
        });
    toast.clearWaitingQueue();
}



const showToastCustomInfoMessage = (message: string, description: string, toastId?: string) => {
    toast(() => <div style={{ width: isDesktopWidth ? 512 : '100%', display: 'flex', padding: '8px 24px 8px 16px' }}>
        <div>
            <h4 style={{ color: '#006', fontFamily: "'Be Vietnam'", fontStyle: 'normal', fontSize: 14, height: 20, lineHeight: '140%', fontWeight: 700 }}>{message}</h4>
            <p style={{ fontSize: '12px', fontFamily: "'Be Vietnam'", color: '#555866', fontWeight: 300, height: 17 }}>{description}</p>
        </div>
    </div>,
        {
            type: 'info',
            icon: <RiInformationLine color='#0068FF' size={28.5} />,
            style: { borderLeft: '4px solid #0068FF' },
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true,
            pauseOnHover: true,
            closeOnClick: true,
            draggable: true,
            theme: "light",
            toastId: toastId,
            data: { message, description }
        });
    toast.clearWaitingQueue();
}

export { showToastCustomInfoMessage, showToastCustomWarningMessage, showToastCustomErrorMessage, showToastCustomSuccessMessage, showToastCustomCreateUserSucessMessage, showToastCustomEditUserSucessMessage }