// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-slider{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 16px;
    width: 100%;
    height: 100% !important;
}

.slick-list{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    width: 100%;
    overflow: hidden;
    height: 100% !important;
}

.slick-track {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    height: 100% !important;
}

.slick-next{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
    z-index: 10;
}


.slick-prev{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 64.42px;
    z-index: 10;

}`, "",{"version":3,"sources":["webpack://./src/components/DashboardComponents/SliderContainer/sliderContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,SAAS;IACT,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,WAAW;IACX,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,cAAc;IACd,WAAW;;AAEf","sourcesContent":[".slick-slider{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0px;\n    gap: 16px;\n    width: 100%;\n    height: 100% !important;\n}\n\n.slick-list{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    padding: 0px;\n    width: 100%;\n    overflow: hidden;\n    height: 100% !important;\n}\n\n.slick-track {\n    display: flex;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 0px;\n    gap: 16px;\n    height: 100% !important;\n}\n\n.slick-next{\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    top: 24px;\n    right: 24px;\n    z-index: 10;\n}\n\n\n.slick-prev{\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    top: 24px;\n    right: 64.42px;\n    z-index: 10;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
