import { styled } from "@mui/material/styles"

const Title = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue,
}));

export {
  Title
}