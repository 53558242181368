import React from "react";

import { Box } from "@mui/material";
import { Card } from "../../../index";
import { Title } from "./styles"

import guide from "../../../../../guide.json";
import cards from "./cards";

type ComponentProps = {
  data: any
};

const Component: React.FC<ComponentProps> = ({ data }) => {

  const reports = cards.map((card) => {
    const hasValue = data?.hasOwnProperty(card.name);

    if (!!!hasValue && !!card.mergeValues) {
      const values = card.mergeValues.map((value: any) => (
        {
          key: value,
          value: data?.[value]
        }
      )).filter(card => card?.value)

      const parameters = values.reduce(
        (obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

      return {
        title: card.title,
        description: card.description,
        value: card.handleValue(parameters)
      }
    }

    if (!!!hasValue && !!!card.mergeValues) return;

    const value = data?.[card.name];
    const reportValue = card.handleValue ? card.handleValue(value) : null

    if (!!card.handleStatus) {
      const handleStatus: (value: any) => string = card.handleStatus;

      return {
        title: card.title,
        status: handleStatus(value),
        successDescription: card.successDescription,
        warningDescription: card.warningDescription,
        value: reportValue
      }
    }

    return {
      title: card.title,
      description: card.description,
      value: reportValue
    }
  }).filter(report => report)

  const successReports = reports.filter((report: any) => report?.status === "success");
  const warningReports = reports.filter((report: any) => report?.status === "warning");
  const informationReports = reports.filter((report: any) => !!!report?.status);

  const totalReports = successReports.length + warningReports.length;

  const ratioSuccess = `${successReports?.length}/${totalReports}`;
  const ratioWarning = `${warningReports?.length}/${totalReports}`;

  return (
    <Box display="flex" flexDirection="column" gap={2}>

      <Box className="no-break">
        <Box display="flex" alignItems="center" justifyContent="space-between" py={2} mb={4} className="no-break">
          <Title>Qualidade da imagem</Title>
        </Box>
        {
          !!successReports.length && (
            <Card.Container title="Está tudo certo!" ratio={ratioSuccess} status="success">
              {
                successReports.map((report: any) => (
                  <Card.Item
                    title={report.title}
                    status="success"
                  >
                    {report.successDescription}
                  </Card.Item>
                ))
              }
            </Card.Container>
          )
        }
      </Box>
      {
        !!warningReports.length && (
          <Card.Container title="Necessita atenção!" ratio={ratioWarning} status="warning">
            {
              warningReports.map((report: any) => {
                const helpText = guide.find((g: any) => g.key === report.key)?.description || "";

                return (
                  <Card.Item
                    title={report.title}
                    helpText={helpText}
                    value={report.value}
                    status="warning"
                  >
                    {report.warningDescription}
                  </Card.Item>
                )
              })
            }
          </Card.Container>
        )
      }

      {
        !!informationReports.length && (
          <Card.Container title="Saiba mais!" status="information">
            {
              informationReports.map((report: any) => {
                return (
                  <Card.Item
                    title={report.title}
                    status="information"
                    value={report.value}
                  >
                    {report.description}
                  </Card.Item>
                )
              })
            }
          </Card.Container>
        )
      }
    </Box>
  )
}

export default Component;