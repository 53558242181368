import * as store from "./store";
import * as slices from "./slices";
import * as reducers from "./reducers";
import * as persistConfig from "./persistConfig";

export {
  store,
  slices,
  reducers,
  persistConfig
}