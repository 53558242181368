import { styled } from "@mui/material/styles"

const Title = styled('h2')(({ theme }) => ({
  fontFamily: 'Picadilly',
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "31px",
  lineHeight: "54px",
  color: theme.palette.secondary.blue
}));

export {
  Title
}