import React from "react";

import { Box, useTheme, useMediaQuery } from "@mui/material";
import { Button, Chip, Dialog } from "@components";
import {
  InputFile,
  InputFileTitle,
  InputFileLink,
  InputFileSubtitle,
  InputFileExtension,
  FileContent,
  FileLabel,
  FileValue,
  FileImage,
  Error
} from "./styles";

import { useFormContext } from "react-hook-form";
import { RiFolderUploadLine } from "react-icons/ri";

import getValueFromPath from "lodash/get";
import { FaTrash } from "react-icons/fa";

type FormatsFile = "png" | "jpg" | "jpeg" | "pdf" | "tiff";

interface ComponentProps {
  name: string;
  identifier?: string;
  formats?: Array<FormatsFile>;
  maximumSize?: number;
  description?: React.ReactElement
}

const Component: React.FC<ComponentProps> = ({
  name,
  identifier,
  formats,
  maximumSize,
  description
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true
  });

  const inputFileRef = React.useRef<HTMLInputElement | null>(null);
  const [openDialog, setOpenDialog] = React.useState(false);

  const {
    register,
    setValue,
    watch,
    setError,
    clearErrors,
    resetField,
    formState: { errors }
  } = useFormContext();

  const { ref, ...inputProps } = register(name);
  const currentWatch = watch();
  const currentData = getValueFromPath(currentWatch, name) || {};
  const error: any = getValueFromPath(errors, name);

  const handleSelectFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    if (inputFileRef.current) {
      inputFileRef.current.click();
    }
  }

  const convertToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const validateFile = (file: any) => {
    if (maximumSize) {
      const limitExceeded = (file?.size / (1024 ** 2)) > maximumSize;

      if (limitExceeded) {
        setError(name, { type: "custom", message: `Tamanho do arquivo é superior a ${maximumSize}MB.` });
        return false;
      }
    }

    if (!!formats?.length) {
      const type = file?.type.split("/")[1];
      const formatInvalid = !!!formats.includes(type);

      if (formatInvalid) {
        setError(name, { type: "custom", message: `O formato do arquivo não é um formato válido.` });
        return false;
      }
    }

    return true;
  }

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;

    if (fileInput && fileInput?.files && fileInput?.files?.length > 0) {

      const file = fileInput.files[0];
      handleInsertFile(file);
    }
  }

  const handleDragEnter = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragLeave = () => { };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    if (!!file)
      handleInsertFile(file);
  };

  const handleInsertFile = async (file: any) => {
    const isValidFile = validateFile(file);

    if (isValidFile) {
      const base64 = await convertToBase64(file);

      const fileName = file.name;
      const fileType = file.type.split("/")[1].toLocaleUpperCase();

      setValue(
        name,
        {
          file,
          base64,
          fileName,
          fileType
        }
      );

      clearErrors(name);
    }
  }

  const handleDelete = () => {
    setOpenDialog(true);
  }

  const onCancelDialog = () => {
    setOpenDialog(false);
  }

  const onConfirmDialog = () => {
    resetField(name);
    onCancelDialog();
  }

  return (
    <>
      <Dialog
        open={openDialog}
        title="Excluir documento"
        confirmText="Excluir"
        cancelText="Cancelar"
        onCancel={onCancelDialog}
        onConfirm={onConfirmDialog}
      >
        Deseja <b>realmente</b> excluir o documento?
      </Dialog>
      <Box display="flex" flexDirection="column" gap={2}>
        <Box
          alignItems={'center'}
          display='flex'
          width="fit-content"
          fontWeight="bold"
          gap={4}
        >
          <Chip>{identifier}</Chip>
          <Button
            onClick={handleDelete}
            color="secondary"
            size="small"
            StartIcon={
              <FaTrash
                size={18}
                color={!!currentData?.base64 ? theme.palette.negative?.pure : theme.palette.neutral[100]}
              />
            }
            disabled={!!!currentData?.base64}
          />
        </Box>
        {
          currentData?.base64 ? (
            <FileContent flexDirection={{ xs: "column", md: "row" }}>
              <Box display="flex" flexDirection="column" gap={6} width={{ xs: "100%", md: "30%" }} textAlign={{ xs: "center", md: "unset" }}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <FileLabel>Nome do arquivo</FileLabel>
                  <FileValue>{currentData?.fileName}</FileValue>
                </Box>

                <Box display="flex" flexDirection="column" gap={3}>
                  <FileLabel>Formato</FileLabel>
                  <FileValue>{currentData?.fileType}</FileValue>
                </Box>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="center" width={{ xs: "100%", md: "70%" }} height={300}>
                <FileImage src={currentData?.base64} />
              </Box>
            </FileContent>
          ) : (
            <InputFile
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <RiFolderUploadLine size={48} />
              <Box
                display="flex"
                flexDirection={{ xs: "column", md: "row" }}
                alignItems="center"
                gap={2}
              >
                <InputFileTitle>Arraste as imagens para cá, ou </InputFileTitle>
                <InputFileLink onClick={handleSelectFileClick}>procure em seu dispositivo</InputFileLink>
              </Box>
              {
                !!formats?.length && (
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", md: "row" }}
                    alignItems="center"
                    gap={1}
                  >
                    <InputFileSubtitle>
                      Formatos suportados:
                    </InputFileSubtitle>
                    <Box display="flex" gap={1}>
                      {
                        formats?.map((format, index) => (
                          <InputFileExtension>
                            .{format}
                            {
                              index <= (formats?.length - 2) && `,`
                            }
                          </InputFileExtension>
                        ))
                      }
                    </Box>
                  </Box>
                )
              }
              <Box>
                <InputFileSubtitle>
                  Tamanho máximo:
                  <InputFileExtension>{maximumSize}MB</InputFileExtension>
                </InputFileSubtitle>
              </Box>
              {
                !!description && (
                  <Box display="flex" flexDirection="column" alignItems="center">
                    {description}
                  </Box>
                )
              }
              <input
                {...inputProps}
                type='file'
                ref={(e) => {
                  ref(e);
                  inputFileRef.current = e;
                }}
                onChange={handleFileChange}
                hidden
              />
            </InputFile>
          )
        }
        <Error>
          <>
            {error?.message}
          </>
        </Error>
      </Box>
    </>
  );
}

export default Component;