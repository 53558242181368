// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.onboarding-banner, .common-onboarding-banner{
    position: relative;
    display: flex;
    width: 336px;
    height: 326px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    flex-shrink: 0;
    border-radius: 12px;
    background: var(--secundary-marine-blue, #1D203A);
}

.onboarding-banner-header{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    position: relative;
    z-index: 50;
}

.onboarding-banner-header h2{
    color: var(--to-approval-neutral-high-high-lightest, #FFF);
    font-family: 'Be Vietnam';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.onboarding-banner img, .common-onboarding-banner img{
    position: absolute;
    width: 100%;
    right: -12px;
    bottom: -17px;
}

@media screen and (max-width: 867px){
    .onboarding-banner{
        align-self: center;
    }
}


@media screen and (max-width: 500px){
    .onboarding-banner{
        width: 100%;
    }

    .onboarding-banner img, .common-onboarding-banner img{
        position: absolute;
        right: -12px;
        bottom: -17px;
        width: 95%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/OnboardingBanner/onboardingBanner.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,SAAS;IACT,cAAc;IACd,mBAAmB;IACnB,iDAAiD;AACrD;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,0DAA0D;IAC1D,yBAAyB;IACzB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;;AAGA;IACI;QACI,WAAW;IACf;;IAEA;QACI,kBAAkB;QAClB,YAAY;QACZ,aAAa;QACb,UAAU;IACd;AACJ","sourcesContent":[".onboarding-banner, .common-onboarding-banner{\n    position: relative;\n    display: flex;\n    width: 336px;\n    height: 326px;\n    padding: 24px;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n    flex-shrink: 0;\n    border-radius: 12px;\n    background: var(--secundary-marine-blue, #1D203A);\n}\n\n.onboarding-banner-header{\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    gap: 8px;\n    position: relative;\n    z-index: 50;\n}\n\n.onboarding-banner-header h2{\n    color: var(--to-approval-neutral-high-high-lightest, #FFF);\n    font-family: 'Be Vietnam';\n    font-size: 24px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 140%;\n}\n\n.onboarding-banner img, .common-onboarding-banner img{\n    position: absolute;\n    width: 100%;\n    right: -12px;\n    bottom: -17px;\n}\n\n@media screen and (max-width: 867px){\n    .onboarding-banner{\n        align-self: center;\n    }\n}\n\n\n@media screen and (max-width: 500px){\n    .onboarding-banner{\n        width: 100%;\n    }\n\n    .onboarding-banner img, .common-onboarding-banner img{\n        position: absolute;\n        right: -12px;\n        bottom: -17px;\n        width: 95%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
