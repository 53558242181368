import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface TokenDetailsInfo {
    open: boolean;
    params: any;
}

const initialState: TokenDetailsInfo = {
   open: false,
   params: undefined
}

const tokenDetailsSlice = createSlice({
  name: 'tokenDetails',
  initialState: initialState,
  reducers: {
    setShowTokenDetailsSection: (state, action: PayloadAction<boolean>) => {
        state.open = action.payload
    },
    setParams: (state, action: PayloadAction<any>) => {
        state.params = action.payload
    }
  },
});

export const { setShowTokenDetailsSection, setParams } = tokenDetailsSlice.actions;

export default tokenDetailsSlice.reducer;