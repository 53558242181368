import { styled } from "@mui/material/styles";

import { Box } from "@mui/material";

const InputFile = styled("div")(({ theme }) => ({
  border: "3px dashed #BED0D7",
  borderRadius: "8px",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(6),
  padding: `${theme.spacing(14)} 0}`,
  transition: "all .5s ease"
}));

const InputFileTitle = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}));

const InputFileLink = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.secondary.blue
}));

const InputFileSubtitle = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 400,
  fontSize: 14,
  color: theme.palette.neutral?.[200],
  display: "flex",
  alignItems: "flex-end",
  gap: theme.spacing(1)
}));

const InputFileExtension = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue
}));

const FileContent = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.neutral?.[400]}`,
  borderRadius: "16px",
  width: "100%",
  padding: `${theme.spacing(11)} ${theme.spacing(4)}`,
  fontFamily: "Be Vietnam",
  display: "flex",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  wordWrap: "break-word"
}));

const FileLabel = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.secondary.main
}));

const FileValue = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[200]
}));

const FileImage = styled("img")(({ theme }) => ({
  maxWidth: "100%",
  maxHeight: "275px"
}));

const Error = styled("span")(({ theme }) => ({
  fontFamily: "Be Vietnam",
  fontSize: 14,
  fontWeight: 700,
  color: theme.palette.negative?.pure
}));

export {
  InputFile,
  InputFileTitle,
  InputFileLink,
  InputFileSubtitle,
  InputFileExtension,
  FileContent,
  FileLabel,
  FileValue,
  FileImage,
  Error
}