import { StepClosed } from "../../components";

import { slices } from "@store";
import { useDispatch } from 'react-redux';

interface ClosedProps {
  getValues: () => any
}

const Closed: React.FC<ClosedProps> = ({ getValues }) => {
  const dispatch = useDispatch();

  const conditionSelected = getValues()?.condition

  const handleEditStep = () => {
    dispatch(slices.formWizard.goStep(1));
  }

  return (
    <StepClosed title="Condição selecionada" value={conditionSelected} handleEdit={handleEditStep} />
  )
}

export default Closed;