import {
    Stepper as StepperMui,
    StepLabel as StepLabelMui,
    Theme,
    styled
} from "@mui/material"

const Stepper = styled(StepperMui)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    gap: theme?.spacing(2)
}))

const StepLabel = styled(StepLabelMui)(({ theme }: { theme?: Theme }) => ({
    '& span': {
        fontFamily: 'Be Vietnam',
        fontSize: '14px',
        color: theme?.palette.neutral?.[100],
        fontWeight: 600,
    },
    '& span:hover': {
        color: theme?.palette.primary.main
    },
    '& span .Mui-active, span .Mui-completed': {
        color: theme?.palette.primary.main,
        fontWeight: 600,
    },

}))

export {
    Stepper,
    StepLabel
}