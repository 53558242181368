import { Box } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { CheckboxTest, TextField } from '@components';
import DialogContainer from '../../../DialogContainer';
import { useDispatch, useSelector } from 'react-redux';
import { reducers, slices } from '@store';

interface ExportListProps {
  data: (
    {
      key: string;
      title: string;
      data: any;
    }
    | null)[],
  open: boolean,
  handlePrint: any
}

const ExportList: React.FC<ExportListProps> = (
  {
    data,
    open,
    handlePrint
  }
) => {

  const form = useForm({
    shouldUnregister: false
  })

  const dispatch = useDispatch()
  const exportPdfState = useSelector((state: reducers.RootState) => state.exportPdf)
  const ignoreList = exportPdfState.ignoreList.filter((item, index) => exportPdfState.ignoreList.indexOf(item) === index)

  const handleCheckboxSubmit = (data: any, event: any) => {
    event.preventDefault()

    const datasets = Object.fromEntries(
      Object.entries(data?.datasets || {}).filter(([key, value]: any) => !!value.selected)
    )

    dispatch(slices.exportPdf.setExportPdfInfo({ ...exportPdfState, open: false, datasets: datasets, isPrinting: true }))
    setTimeout(() => {
      handlePrint()
    }, 1000)
  }

  const handleCancel = () => {
    dispatch(slices.exportPdf.setOpen(false))
  }

  return <DialogContainer
    open={open}
    title="Exporte a sua consulta em PDF"
    description="O que você deseja exportar?"
    handleCancel={handleCancel}
    handleConfirm={form.handleSubmit(handleCheckboxSubmit)}
  >
    <FormProvider {...form}>
      <Box display='flex' flexDirection='column' gap={4}>
        <CheckboxTest.Container name='datasets'>
          <CheckboxTest.Item key={'all'} value={'all'} label={'Selecionar todos'} checkAll={true} />
          {
            Object.entries(data || {}).map(([key, value]: any) => {
              const isDatasetInIgnoreList = ignoreList.find((item) => key === item)
              if (!!!isDatasetInIgnoreList && !!value.selected) {
                return (
                  <>
                    <CheckboxTest.Item key={key} value={key} label={value.label} />
                  </>
                )
              }
            })
          }
        </CheckboxTest.Container>
      </Box>
    </FormProvider>
  </DialogContainer >;
}

export default ExportList;