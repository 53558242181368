import { Step } from "react-joyride";
import { isDesktopWidth } from '@constants';

const docUpdate: Step[] = [
  {
    target: '.dashboard-main-container',
    title: 'Boas vindas ao novo BDC Center!',
    content: 'Descubra todas as funcionalidades da plataforma em nosso tour de integração.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    disableScrolling: true
  },
  {
    target: isDesktopWidth ? '.navbar-container' : '#navbar-menu',
    title: 'Explore os módulos',
    content: isDesktopWidth ? 'Navegue pelos módulos da plataforma através da nova barra lateral.' : 'Navegue pelos módulos da plataforma através do menu.',
    spotlightPadding: 0,
    disableBeacon: true,
    floaterProps: { placement: 'right-start' },
    disableScrolling: true
  },
  {
    target: '.notification-icon',
    title: 'Mantenha-se informado',
    content: 'Agora você pode receber notificações da plataforma, e todas elas vão aparecer na sua central de notificações.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom' }
  },
  {
    target: '.user-profile-button',
    title: 'Acesse seu perfil',
    content: 'Consulte e edite suas informações pessoais no seu perfil.',
    disableBeacon: true,
    floaterProps: { placement: 'bottom' }
  },
  {
    target: '.billing-summary-content',
    title: 'Novo consumo do mês',
    content: 'Acompanhe o uso de seu domínio. Você também pode visualizar com mais detalhes na seção de gestão financeira.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'right' : 'center' },
    disableScrolling: true
  },
  {
    target: '.shortcut-container',
    title: 'Use os atalhos',
    content: 'Encontre rapidamente as ferramentas mais comuns.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'left' : 'center' },
    offset: 0,
  },
  {
    target: '.last-used-content',
    title: 'Acesse rapidamente o que importa',
    content: 'Encontre rapidamente os produtos que você utilizou recentemente.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'right' : 'center' }
  },
  {
    target: '.news-container',
    title: 'Acompanhe nossas novidades',
    content: 'Aqui você recebe todas as novidades da BigDataCorp e do BDC Center.',
    disableBeacon: true,
    floaterProps: { placement: isDesktopWidth ? 'top-start' : 'center' }
  },
  {
    target: '#redo-onboarding-button-dash',
    title: 'Tour finalizado',
    content: 'Clique nesse botão para acessar o tour sempre que precisar recordar alguma funcionalidade específica de cada página. Caso tenha alguma dúvida que não tenha sido respondida durante o tour, nossa documentação técnica na Central de Ajuda contém respostas rápidas e detalhadas.',
    disableBeacon: true,
    showProgress: false,
    floaterProps: { placement: 'auto' }
  }
];

export default docUpdate;