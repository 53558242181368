import { showToastCustomErrorMessage } from "@utils";
import { useCallback, useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

interface ReCaptchaProps {
    onVerify: (token: string | undefined) => void;
}

const ReCaptcha: React.FC<ReCaptchaProps> = ({ onVerify }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
          return;
        }

        try{
            const token = await executeRecaptcha?.();
            if(token){
                onVerify(token)
            }
        }catch(err: any){
            showToastCustomErrorMessage('Erro no reCaptcha!', "O reCaptcha não foi verificado. Tente recarregar a página.")
        }
      }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify()
    }, [handleReCaptchaVerify]);

    return null;
};

export default ReCaptcha;
