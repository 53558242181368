import { styled } from "@mui/material/styles";
import {
  FormControl as FormControlMaterial,
  Select as SelectMaterial
} from "@mui/material";

const FormControl = styled(FormControlMaterial)(({ theme }) => ({
  display: "flex",
  padding: "0px",
  gap: "8px",
  width: "100%",
  boxSizing: "border-box",

  ".MuiOutlinedInput-notchedOutline": {
    border: "1px solid #BED0D7",
    borderRadius: "8px"
  }
}));

const Select = styled(SelectMaterial)(({ theme }) => ({
  padding: theme.spacing(4.25),
  "& .MuiInputBase-input": { 
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "10px",
    fontFamily: "Be Vietnam",
    fontStyle: "normal",
    fontSize: "12px",
    lineHeight: "22px",
    color: "#555866",
    borderColor: "#BED0D7",
    padding: 0,
    paddingRight: theme.spacing(12),

    '&:focus': {
      borderColor: '#0448AB'
    },
  },

  "& .MuiSelect-icon": {
    marginRight: theme.spacing(2)
  }
}));

const Label = styled("label")(({ theme }) => ({
  fontFamily: "'Be Vietnam'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '140%',
  color: '#000066',
  marginLeft: '2px',
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end"
}));

const RequiredSpan = styled("span")(({ theme }) => ({
  fontFamily: "'Be Vietnam'",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '140%',
  color: '#CC0030',
  marginLeft: '2px'
}));

export {
  Label,
  RequiredSpan,
  FormControl,
  Select
};