import { showToastCustomErrorMessage } from "./ToastMessages"

const isReCaptchaVerified = (tokenReCaptcha: string | undefined) => {
    if (!tokenReCaptcha) {
      showToastCustomErrorMessage("O ReCaptcha não foi verificado.", 'Verifique a sua conexão com a internet.')
      return false
    }
    return true
  }

export default isReCaptchaVerified