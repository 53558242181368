import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { NotificationCenterItem } from "react-toastify/addons/use-notification-center"

import CustomDefaultTextButton from '../../GeneralComponents/CustomDefaultTextButton';

import { useNotificationCenterContext } from '../../NotificationCenterProvider';

import './notificationCard.css'
import { reducers, slices } from '@store';
import { useDispatch, useSelector } from 'react-redux';

interface Data {
    user: string,
    message: string,
    description: string
}

const NotificationCard: React.FC = () => {

    const {notifications, markAsRead, update} = useNotificationCenterContext()
    const login = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user.login)

    const navigate = useNavigate()
    const dispatch = useDispatch()


    const handleToastClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const notificationId = event.currentTarget.id
        navigate('financial/invoices')
        markAsRead(notificationId)
        dispatch(slices.notificationCenter.setShowNotificationCenter(false))
    }

    const isNotificationFromOtherUser = () => notifications.find((notification) => notification.data?.user !== login)

    useEffect(() => {
        if (notifications.length !== 0) {
            localStorage.setItem("notifications", JSON.stringify(notifications)) 
        }else{
            localStorage.setItem("notifications", JSON.stringify([])) 
        }
    }, [notifications])

    useEffect(() => {
        const notificationFromOtherUsers = isNotificationFromOtherUser()
        if(notificationFromOtherUsers && login !== ''){
            localStorage.removeItem("notifications")
        }
    }, [login])

    return <>
        {notifications.map((notification: NotificationCenterItem<Data>, index) => {
                return notification.status !== 'removed' ? <div className='notification-card-content' id={notification.id.toString()} key={notification.id} onClick={notification.id.toString().includes('overdue-invoice') ? handleToastClick : undefined}>
                    <div className={notification.read ? 'notification-card-information-read' : 'notification-card-information-unread'}>
                        <div className='notification-card-presentation'>
                            <div className='notification-card-presentation-title-and-date'>
                                <h6 className='notification-card-presentation-title'>{notification.data?.message}</h6>
                                <span className='notification-card-presentation-date'>{dayjs(notification.createdAt).format('DD/MM/YY')}</span>
                            </div>
                            <p className='notification-card-presentation-description'>{notification.data?.description}</p>
                        </div>
                    </div>
                    <div className={notification.read ? 'notification-card-options-read' : 'notification-card-options-unread'}>
                        {!notification.read ? <CustomDefaultTextButton variant='text' onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation()
                            markAsRead(notification.id)
                        }}>Marcar como lida</CustomDefaultTextButton> : <></>}
                        <CustomDefaultTextButton variant='text' onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            event.stopPropagation()
                            markAsRead(notification.id)
                            update(notification.id, {status: 'removed'})
                        }}>Excluir</CustomDefaultTextButton>
                    </div>
                </div>
                : 
                <></>
            }
        )}
    </>
}

export default NotificationCard;
