import { styled } from "@mui/material/styles"
import { StandardCSSProperties } from "@mui/system";
import { Paper as PaperMaterial } from "@mui/material"

interface PaperProps {
  round: string | number,
  clickable: boolean,
  elevation: number
}

interface IElevationStyles {
  [key: string]: StandardCSSProperties | any
}

const Paper = styled(PaperMaterial)<PaperProps>(({ round, clickable, elevation }) => {
  const elevations: IElevationStyles = {
    1: {
      boxShadow: "0 0px 4px rgba(0, 0, 0, 0.3)"
    }
  }

  return {
    width: "auto",
    borderRadius: round,
    cursor: clickable ? "pointer" : "inherit",
    "> *": {
      cursor: clickable ? "pointer" : "inherit"
    },
    ...elevations[elevation]
  }
})

export {
  Paper
};