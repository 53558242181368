// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.return-button{
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #0068ff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-family: 'Be Vietnam';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 140%;
}

.return-button:hover{
    color: #006;
    transition: color .5s ease;
}`, "",{"version":3,"sources":["webpack://./src/components/GeneralComponents/ReturnLoginButton/returnLoginButton.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,qBAAqB;IACrB,cAAc;IACd,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,0BAA0B;AAC9B","sourcesContent":[".return-button{\n    display: inline-flex;\n    align-items: center;\n    text-decoration: none;\n    color: #0068ff;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-family: 'Be Vietnam';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 16px;\n    line-height: 140%;\n}\n\n.return-button:hover{\n    color: #006;\n    transition: color .5s ease;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
