import * as yup from "yup";

export default yup.object().shape({
  type: yup.string(),
  qualityImage: yup.object().shape({
    active: yup.object().shape({
      selected: yup.boolean()
    })
  }),
  documents: yup.array().when(
    "type",
    {
      is: (type: string) => type === "file",
      then: (schema) => schema.of(
        yup.object().shape({
          type: yup.string(),
          files: yup.array().of(
            yup.object().shape({
              base64: yup.string().required()
            }).typeError("É necessário inserir um arquivo para prosseguir")
          )
        })
      ),
      otherwise: (schema) => schema.of(
        yup.object().shape({
          type: yup.string(),
          files: yup.array().of(
            yup.object().shape({
              inserted: yup.boolean().required("É necessário inserir um link para prosseguir").oneOf([true] , "É necessário inserir um link para prosseguir"),
              link: yup.string().required(),
            }).test("required", "É necessário inserir um link para prosseguir", (values) => { return !!values.link || !!values.inserted})
          )
        })
      )
    }
  )
})