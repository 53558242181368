import { SxProps } from "@mui/material";

const ResultContainer: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: '400px',
    gap: '16px',
    padding: '24px',
    flex: '1 0 0',
    borderRadius: '8px',
    border: '1px solid #EDF2F4',
    '& h2': {
        fontSize: '18px',
        fontWeight: 700,
        color: '#006'
    }
}

const MainLineContainer: SxProps = {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '16px',
    borderRadius: '8px',
    width: '100%',
    border: '1px solid var(--to-approval-neutral-high-high-light, #F3F8FA)',
    background: 'var(--to-approval-neutral-high-high-light, #F3F8FA)',

}

const MainLineLabel: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontSize: '14px',
    fontWeight: 700,
    color: '#006'
}

const ResultLineContainer: SxProps = {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    borderBottom: '1px solid #55586610',
    width: '100%',
    paddingBottom: '16px'
}

const ResultLineLabel: React.CSSProperties = {
    fontFamily: 'Be Vietnam',
    fontSize: '14px',
    fontWeight: 700,
    color: '#555866'
}

export {
    ResultContainer,
    MainLineContainer,
    MainLineLabel,
    ResultLineContainer,
    ResultLineLabel
}