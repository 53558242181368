import * as bankSlips from "./installments";
import * as createToken from "./createToken";
import * as consumptionDetails from "./consumptionDetails";
import * as currentUsageDetails from "./currentUsageDetails";
import * as currentUsageSummaryDetails from "./currentUsageSummaryDetails";
import * as deleteFile from "./deleteFile";
import * as disableToken from "./disableToken";
import * as disableTokenDialog from "./disableTokenDialog";
import * as editUser from "./editUser";
import * as exportPdf from "./exportPdf";
import * as installmentDetails from "./installmentsDetails";
import * as invoiceDetails from "./invoiceDetails";
import * as invoicesPagination from "./invoicesPagination";
import * as lastUsed from "./lastUsed";
import * as navbar from "./navbar";
import * as notificationCenter from "./notificationCenter";
import * as onboardingTooltip from "./onboardingTooltip";
import * as seeDetails from "./seeDetails";
import * as seePdf from "./seePdf";
import * as tokenDetails from "./tokenDetails";
import * as tokenManagementFilterSection from "./tokenManagementFilterSection";
import * as user from "./user";
import * as userManagementFilterSection from "./userManagementFilterSection";
import * as userPagination from "./userPagination";
import * as userProfile from "./userProfile";
import * as viewUser from "./viewUser";
import * as sideSection from "./sideSection";
import * as usageSummaryPagination from "./usageSummaryPagination"
import * as formWizard from "./formWizard";
import * as quickConsultDialog from "./quickConsultDialog";
import * as qualityImageDialog from "./qualityImageDialog";
import * as fileTransferDetails from "./fileTransferDetails";
import * as exportPdfDocumentoscopia from "./exportPdfDocumentoscopia";
import * as exportPdfFacematch from './exportPdfFacematch';

export {
  bankSlips,
  createToken,
  consumptionDetails,
  currentUsageDetails,
  currentUsageSummaryDetails,
  deleteFile,
  disableToken,
  disableTokenDialog,
  editUser,
  exportPdf,
  installmentDetails,
  invoiceDetails,
  invoicesPagination,
  lastUsed,
  navbar,
  notificationCenter,
  onboardingTooltip,
  seeDetails,
  seePdf,
  tokenDetails,
  tokenManagementFilterSection,
  user,
  userManagementFilterSection,
  userPagination,
  userProfile,
  viewUser,
  sideSection,
  usageSummaryPagination,
  formWizard,
  quickConsultDialog,
  fileTransferDetails,
  exportPdfFacematch,
  qualityImageDialog,
  exportPdfDocumentoscopia
}