import { Navigate, createBrowserRouter, Outlet } from "react-router-dom";

import {
    Auth,
    Admin,
    Dashboard,
    FileTransferCheck,
    BigId
} from '@templates';

import {
    Login,
    HealthCheck,
    Home,
    CreatePassword,
    TokenManagement,
    UserManagement,
    CurrentUsage,
    Invoices,
    UsageSummary,
    DetailedUsageSummary,
    PontualConsult,
    PlatformReports,
    NotFound,
    DocUpdate,
    FileTransfer,
    Facematch,
    Help,
    Documentoscopia,
} from "@pages";

const router = createBrowserRouter([
    {
        element: <NotFound />,
        path: "*"
    },
    {
        element: <HealthCheck />,
        path: "health"
    },
    {
        element: <Navigate to="login" />,
        path: "/"
    },
    {
        element: <CreatePassword />,
        path: "auth/RecoverPassword"
    },
    {
        element: <Auth />,
        path: "login",
        children: [
            {
                element: <Login />,
                path: ""
            }
        ]
    },
    {
        element: <Dashboard />,
        path: "dashboard",
        children: [
            {
                element: <Home />,
                path: ""
            },
            {
                element: <Outlet />,
                path: "tools",
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <DocUpdate />,
                        path: "document-update"
                    },
                ]
            },
            {
                element: <Outlet />,
                path: "management",
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <TokenManagement />,
                        path: "tokens"
                    },
                    {
                        element: <Admin />,
                        path: "users",
                        children: [
                            {
                                element: <UserManagement />,
                                path: ""
                            },
                        ]
                    },
                ]
            },
            {
                element: <Admin />,
                path: "financial",
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <Invoices />,
                        path: "invoices"
                    },
                    {
                        element: <UsageSummary />,
                        path: "usage-summary"
                    },
                    {
                        element: <DetailedUsageSummary />,
                        path: "detailed-usage-summary"
                    },
                    {
                        element: <CurrentUsage />,
                        path: "current-usage"
                    }
                ]
            },
            {
                element: <Outlet />,
                path: "platform",
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <PontualConsult.New />,
                        path: "pontual-consult/new"
                    },
                    {
                        element: <PlatformReports />,
                        path: "platform-reports"
                    }
                ]
            },
            {
                element: <Outlet />,
                path: "help",
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <Help />,
                        path: "center"
                    }
                ]
            },
            {
                element: <Outlet />,
                path: 'apps',
                children: [
                    {
                        element: <NotFound />,
                        path: ""
                    },
                    {
                        element: <Outlet/>,
                        path: "bigid",
                        children: [
                            {
                                element: <NotFound />,
                                path: ""
                            },
                            {
                                element: <BigId.Documentoscopia />,
                                path: "documentoscopia",
                                children: [
                                    {
                                        element: <Documentoscopia.Main />,
                                        path: "",
                                    },
                                    {
                                        element: <Documentoscopia.Result />,
                                        path: "result"
                                    }
                                ]
                            },
                            {
                                element: <BigId.Facematch />,
                                path: "facematch",
                                children: [
                                    {
                                        element: <Facematch.New />,
                                        path: ""
                                    },
                                    {
                                        element: <Facematch.Result />,
                                        path: "result"
                                    }
                                ]
                            },
                        ]
                    }
                ]

            },

        ]
    }
]);

export default router;
