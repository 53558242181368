import React from "react";

import { Box } from "@mui/material";
import { Title } from "./styles";
import { Button } from "@components";
import { Result, Form, ButtonGoBack } from "./components";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { reducers, slices } from "@store";

const Page: React.FC = () => {
  const [data, setData] = React.useState<any>(null);

  const dispatch = useDispatch();
  const exportPdfState = useSelector((state: reducers.RootState) => state.exportPdf)
  const ignoreList = exportPdfState.ignoreList.filter((item, index) => exportPdfState.ignoreList.indexOf(item) === index)
  const areAllDatasetsWithError = ignoreList.length >= Object.keys(data?.datasets || {}).length

  dispatch(slices.formWizard.setFormWizardInitialState());

  const handleExportPdfClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    dispatch(slices.exportPdf.setOpen(true));
  }

  const handleSubmit = (event: any, data: any) => {
    event?.preventDefault();

    setData(data);
  }

  const handleNewConsult = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    dispatch(slices.formWizard.setFormWizardInitialState());
    setData(null);
  }

  return (
    <Box display="flex" flexDirection="column" px={6} py={8} gap={10}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={4}>
          <ButtonGoBack />
          <Title>Consulta pontual</Title>
        </Box>
        <Box display="flex" gap={6}>
          {
            !!data && (
              <>
                <Button
                  color="secondary"
                  size="small"
                  onClick={handleNewConsult}
                  id="btn-new-consult"
                >
                  Nova consulta
                </Button>
                {
                  !!!areAllDatasetsWithError && (
                    <Button
                      size="small"
                      onClick={handleExportPdfClick}
                      id="export-consult"
                    >
                      Exportar PDF
                    </Button>
                  )
                }
              </>
            )}
        </Box>
      </Box>
      {
        (!!data) ? (
          <Result data={data} />
        ) : (
          <Form handleSubmit={handleSubmit} />
        )
      }
    </Box>
  )
}

export default Page;