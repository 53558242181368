import { GeneralComponents, Paper, SideSection } from '@components';
import React, { useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { BsFilter, BsPlus } from 'react-icons/bs';
import FilterSection from './FilterSection';
import dayjs, { Dayjs } from 'dayjs';
import { showToastCustomErrorMessage } from '@utils';
import { isDesktopWidth, platformReportGridColumns } from '@constants';
import { useDispatch } from 'react-redux';
import SideSectionContent from './SideSectionContent';

const datagridSlots = {
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}

const PlatformReports: React.FC = () => {
    const [openSideSection, setOpenSideSection] = React.useState<boolean>(false);

    const [localFilterInfo, setLocalFilterInfo] = useState<any>({
        ReferenceMonthStart: '',
        ReferenceMonthEnd: '',
        reportName: '',
        userName: '',
        filterBy: 'user',
        defaultRange: 'none',
    })
    const [pageState, setPageState] = useState({
        isLoading: false,
        isMobileFiltersOpen: false
    })
    const dispatch = useDispatch()

    const areFiltersValid = () => {
        if (dayjs(localFilterInfo.ReferenceMonthStart, 'DD/MM/YYYY', 'pt-br').isAfter(dayjs(localFilterInfo.ReferenceMonthEnd, 'DD/MM/YYYY', 'pt-br'))) {
            showToastCustomErrorMessage('Erro ao filtrar relatórios', 'A data inicial precisa ser anterior à data limite.')
            setPageState({ ...pageState, isLoading: false })
            return false
        }
        return true
    }

    const handleReferenceMonthStartChange = (ReferenceMonthStart: Dayjs | null) => {
        if (ReferenceMonthStart) {
            setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthStart: dayjs(ReferenceMonthStart).format('DD/MM/YYYY') })
        } else {
            setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthStart: '' })
        }
    }

    const handleReferenceMonthEndChange = (ReferenceMonthEnd: Dayjs | null) => {
        if (ReferenceMonthEnd) {
            setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthEnd: dayjs(ReferenceMonthEnd).format('DD/MM/YYYY') })
        } else {
            setLocalFilterInfo({ ...localFilterInfo, ReferenceMonthEnd: '' })
        }
    }

    const handleSelectChanges = (event: SelectChangeEvent<unknown>) => {
        const { value, name } = event.target

        setLocalFilterInfo({ ...localFilterInfo, [name]: value as string })
    }

    const handleTextfieldChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name } = event.target
        setLocalFilterInfo({ ...localFilterInfo, [name]: value })
    }

    const handleClearFilters = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()

        setLocalFilterInfo({
            ReferenceMonthStart: '',
            ReferenceMonthEnd: '',
            reportName: '',
            userName: '',
            filterBy: 'user',
            defaultRange: 'none',
        })
    }

    const handleNewReportClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        setOpenSideSection(true);
    }

    const handleCloseNewReport = () => {

        setOpenSideSection(false);
    }

    const toggleMobileFiltersOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setPageState({ ...pageState, isMobileFiltersOpen: !pageState.isMobileFiltersOpen })
    }

    const handleButtonGroupChanges = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { name } = event.currentTarget
        const parentId = event.currentTarget.parentElement?.id

        if (parentId) {
            setLocalFilterInfo({ ...localFilterInfo, [parentId]: name })
        }
    }

    return <GeneralComponents.InternalPages
        title={'Relatórios da Plataforma'}
        description={'Qualquer descrição aleatória'}
    >
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'flex-end'}
            flexWrap={'wrap'}
            width={'100%'}
        >

            <FilterSection
                localFilterInfo={localFilterInfo}
                open={pageState.isMobileFiltersOpen}
                isMobile={!isDesktopWidth}
                toggleMobileFilterSection={toggleMobileFiltersOpen}
                handleClearFilters={handleClearFilters}
                handleFilterRequest={() => { }}
                handleReferenceMonthEndChange={handleReferenceMonthEndChange}
                handleReferenceMonthStartChange={handleReferenceMonthStartChange}
                handleTextfieldChanges={handleTextfieldChanges}
                handleSelectChanges={handleSelectChanges}
                handleButtonGroupChanges={handleButtonGroupChanges}
            />
            <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                width={isDesktopWidth ? 'fit-content' : '100%'}
            >

                <GeneralComponents.CustomDefaultButton
                    startIcon={<BsPlus size={24} color="#FFF" />}
                    onClick={handleNewReportClick}
                >
                    Novo relatório
                </GeneralComponents.CustomDefaultButton>
                {!isDesktopWidth &&
                    <GeneralComponents.CustomDefaultTextButton

                        startIcon={<BsFilter size={24} color="#0068ff" />}
                        onClick={toggleMobileFiltersOpen}
                    >
                        Filtros
                    </GeneralComponents.CustomDefaultTextButton>
                }
            </Box>
        </Box>

        <Box
            display={'flex'}
            gap={'24px'}
            width={'100%'}
            height={'100%'}
        >
            <Paper
                sx={{
                    minHeight: '100%',
                    width: '100%',
                    padding: '16px'
                }}
            >
                <GeneralComponents.StyledDataGridWithoutPagination
                    columns={platformReportGridColumns}
                    rows={[]}
                    slots={datagridSlots}
                />
            </Paper>

        </Box>
        <SideSection.Container
            open={openSideSection}
            onClose={handleCloseNewReport}
            title='Novo relatório'
        >
            <SideSectionContent />
        </SideSection.Container>
    </GeneralComponents.InternalPages>
}

export default PlatformReports;