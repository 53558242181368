const passwordChecklistStyle: React.CSSProperties = {
    padding: '30px 0px',
    fontSize: '14px',
    color: '#555866',
    fontWeight: 400,
    fontFamily: "Be Vietnam"
}

export {
    passwordChecklistStyle
}