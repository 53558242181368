import { Theme, styled } from "@mui/material/styles"
import { Paper } from "@components";

import { Box } from "@mui/material";

const Header = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
}));

const Title = styled("h2")(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

interface CustomPaperProps extends Omit<typeof Paper, 'checked'> {
  theme?: Theme,
  checked: boolean
}

const Card = styled(Paper)(({ theme, checked }: CustomPaperProps) => {
  const borderStyle = !!checked ? {
    borderWidth: "2px",
    borderColor: theme?.palette.lighterBigBlue?.pure
  } : ""

  return {
    flex: 1,
    borderWidth: "2px",
    transition: "border-color .4s ease",
    ...borderStyle
  }
})

const CardTitle = styled('h3')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 14,
  color: theme.palette.secondary.blue
}))

const CardKey = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 12,
  color: theme.palette.neutral?.[100]
}))

const CardValue = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 12,
  color: theme.palette.neutral?.[100]
}))

export {
  Header,
  Title,
  Card,
  CardTitle,
  CardKey,
  CardValue
}