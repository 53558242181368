import { Box } from "@mui/material"
import { Theme, styled } from "@mui/material/styles"

const ImageCheckWrapper = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme?.spacing(4),
}))



const SimilarityContent = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    gap: theme?.spacing(6),
    alignItems: 'center',
}))

const SimilarityInfo = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme?.spacing(2),
}))

const CodeInfo = styled(Box)(({ theme }: { theme?: Theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme?.spacing(1.5),
}))

export {
    ImageCheckWrapper,
    SimilarityContent,
    SimilarityInfo,
    CodeInfo,
}