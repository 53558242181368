import Slider, { Settings } from "react-slick";

import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import './newsSliderContainer.css'


interface SliderContainerProps {
    children: React.ReactNode;
}

function SampleNextArrow(props : any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '24px', height: '24px', cursor: 'pointer'}}
      onClick={onClick}
    >
      <BsArrowRightShort size={24} color='#0068ff'/>
    </div>
  );
}

function SamplePrevArrow(props : any) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, width: '24px', height: '24px',  cursor: 'pointer'}}
      onClick={onClick}
    >
      <BsArrowLeftShort size={24} color='#0068ff'/>
    </div>
  );
}


const SliderContainer: React.FC<SliderContainerProps> = ({children}: SliderContainerProps) => {
  const settings: Settings = {
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    pauseOnFocus: true,
    speed: 500,
    adaptiveHeight: false,
    vertical: false,
    slidesToShow: 5,
    cssEase: "ease-in-out",
    autoplaySpeed: 8000,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow/>,
    prevArrow: <SamplePrevArrow/>,
    arrows: true,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          infinite: true,
          autoplay: true,
          pauseOnHover: true,
          pauseOnFocus: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          cssEase: "ease-in-out",
          autoplaySpeed: 8000,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 1656,
        settings: {
          infinite: true,
          autoplay: true,
          pauseOnHover: true,
          pauseOnFocus: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          cssEase: "ease-in-out",
          autoplaySpeed: 8000,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          pauseOnHover: true,
          pauseOnFocus: true,
          infinite: true,
          autoplay: true,
          cssEase: "ease-in-out",
          autoplaySpeed: 8000,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 1230,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          pauseOnHover: true,
          pauseOnFocus: true,
          infinite: true,
          autoplay: true,
          cssEase: "ease-in-out",
          autoplaySpeed: 8000,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      },
      {
        breakpoint: 925,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          pauseOnHover: true,
          pauseOnFocus: true,
          infinite: true,
          autoplay: true,
          cssEase: "ease-in-out",
          autoplaySpeed: 8000,
          speed: 500,
          vertical: false,
          nextArrow: <SampleNextArrow/>,
          prevArrow: <SamplePrevArrow/>,
        }
      }
    ]
  };

  return (
    <Slider  {...settings}>
      {children}
    </Slider>
  );
};

export default SliderContainer;