import React from "react";

import { Typography } from "@mui/material";
import { TextField, SelectField, Datepicker } from "@components";
import { useFormContext } from "react-hook-form";

interface OptionProps {
  value: string,
  label: string
}

interface ComponentProps {
  name: string,
  label: string,
  description?: string,
  mask?: string,
  isSelect?: boolean,
  options?: Array<OptionProps>,
  required?: boolean
  fieldValues?: any
}

const Component: React.FC<ComponentProps> = ({ name, label, description, mask, isSelect = false, options = [], required = false, fieldValues }) => {

  const {
    formState: { errors }
  } = useFormContext();

  const isDate = fieldValues.find((value: string) => value.toUpperCase() === "YYYY-MM-DD");

  if (isDate) {
    return (
      <Datepicker
        label={label}
        name={name}
        description={description}
        required={required}
      />
    )
  }

  if (isSelect) {

    return (
      <>
        <SelectField.Container
          label={label}
          name={name}
          description={description}
          required={required}
        >
          {
            options.map((option) => (
              <SelectField.Item key={option.value} value={option.value}>{option.label}</SelectField.Item>
            ))
          }
        </SelectField.Container>
      </>
    )
  }

  return (
    <>
      <TextField
        label={label}
        name={name}
        description={description}
        mask={mask}
        required={required}
      />
      <Typography color="red">
        <>
          {errors?.[name]?.message && "Preencha o campo obrigatório"}
        </>
      </Typography>
    </>
  )
}

export default Component;