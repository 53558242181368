import { styled } from "@mui/material/styles"

const Title = styled('h2')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 18,
  color: theme.palette.secondary.blue
}));

const Cost = styled('div')(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  fontFamily: 'Be Vietnam',
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}));

const Value = styled('span')(({ theme }) => ({
  fontFamily: 'Be Vietnam',
  fontWeight: 700,
  fontSize: 24,
  color: theme.palette.primary.blue
}));


export {
  Title,
  Cost,
  Value
};