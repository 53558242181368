import { Theme, styled, keyframes } from "@mui/material/styles"

import { Box, Tab as TabMaterial, Tabs as TabsMaterial } from "@mui/material";

const rotate = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const DocumentContent = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  border: `1px solid ${theme.palette.neutral?.[500]}`
}));

const TicketContent = styled(Box)(({ theme }) => ({
  fontFamily: "Be Vietnam",
  backgroundColor: theme.palette.neutral?.[500]
}));

const TicketTitle = styled("span")(({ theme }) => ({
  fontWeight: 700,
  fontSize: 16,
  color: theme.palette.secondary.blue,
}));

const TicketValue = styled("span")(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: theme.palette.neutral?.[100]
}));

const Tabs = styled(TabsMaterial)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.primary.blue,
    height: 2,
  },
  "& .MuiTab-root.Mui-selected": {
    color: theme.palette.primary.blue,
    fontWeight: 700
  },
}));

type TabProps = {
  theme?: Theme,
  active?: boolean
}

const Tab = styled("button")<TabProps>(({ theme, active = false }) => {
  const colors = {
    active: {
      background: theme.palette.secondary.marine,
      text: theme.palette.neutral?.white,
      border: theme.palette.secondary.marine
    },
    inactive: {
      background: theme.palette.neutral?.white,
      text: theme.palette.neutral?.[100],
      border: `1px solid ${theme.palette.neutral?.[400]}`
    }
  }

  const { background, text, border } = colors[active ? "active" : "inactive"]

  return ({
    display: "flex",
    gap: theme.spacing(2),
    alignItems: "center",
    backgroundColor: background,
    color: text,
    borderRadius: 4,
    border: border,
    padding: theme.spacing(2),
    fontFamily: "Be Vietnam",
    fontWeight: 700,
    fontSize: 14,
    cursor: "pointer"
  })
});

const Spinner = styled("div")(({ theme }) => ({
  animation: `${rotate} 2s linear infinite`
}));

export {
  DocumentContent,
  TicketContent,
  TicketTitle,
  TicketValue,
  Tabs,
  Tab,
  Spinner
}