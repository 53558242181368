import * as TypeConsult from "./TypeConsult";
import * as SavedConsult from "./SavedConsult";
import * as Api from "./Api";
import * as Condition from "./Condition";
import * as Document from "./Document";
import * as Datasets from "./Datasets";
import Review from "./Review";

export {
  TypeConsult,
  SavedConsult,
  Api,
  Condition,
  Document,
  Datasets,
  Review
}