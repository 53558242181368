import { Step } from "react-joyride";

const steps: Step[] = [
  {
    target: '#choose-dataset',
    title: 'Consulta personalizada e objetiva',
    content: 'Escolha os datasets e atributos relevantes para sua análise.',
    disableBeacon: true,
    floaterProps: { placement: 'center' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#edit-button',
    title: 'Editar',
    content: 'Selecione o botão de editar para voltar ao primeiro passo de configuração.',
    disableBeacon: true,
    floaterProps: { placement: 'right' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#dataset-0',
    title: 'Escolha um dataset',
    content: 'Selecione, pelo menos, um dataset para realizar a sua consulta.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: true
  },
  {
    target: '#icon-collapse',
    title: 'Seleção de atributos',
    content: 'Escolha os atributos desejados para resultados mais personalizados, ou mantenha apenas o dataset selecionado para retornar todos os atributos.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: true
  },
  {
    target: '#search-dataset',
    title: 'Busque datasets',
    content: 'Realize uma busca para achar o dataset desejado de maneira mais simples.',
    disableBeacon: true,
    floaterProps: { placement: 'auto' },
    spotlightPadding: 10,
    disableScrolling: false
  },
  {
    target: '#btn-next',
    title: 'Próxima etapa',
    content: 'Após a escolha dos datasets clique no botão para avançar para a próxima etapa.',
    disableBeacon: true,
    floaterProps: { placement: 'left' },
    spotlightPadding: 10,
    disableScrolling: false
  }
];

export default steps;