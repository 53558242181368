import { Button} from '@components';
import { Box, Typography } from '@mui/material';
import { reducers } from '@store';
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Export } from './components';

// import { Container } from './styles';

const Facematch: React.FC = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [isResult, setIsResult] = React.useState(false);

    React.useEffect(() => {

        if (location.pathname === "/dashboard/apps/bigid/facematch/result") {

            setIsResult(true);
            return
        }

        setIsResult(false);

    }, [location.pathname])

    const handleNewConsult = () => {
        navigate("./", {replace: true});
    }

    const printRef = React.useRef(null);
    const { qualityImage: { firstImage, secondImage } } = useSelector((state: reducers.RootState) => state.exportPdfFacematch)

    const isAvailableToPrint = !!firstImage?.isAvailable && !!secondImage?.isAvailable

    return <Box display="flex" flexDirection="column" px={6} py={8} gap={10} className='print' ref={printRef}>
        <Box display="flex" alignItems="center" justifyContent="space-between" displayPrint={'none'}>
            <Box display="flex" flexDirection="column" gap={1}>
                <Typography variant='h1'>Biometria facial</Typography>
                <Typography variant='subtitle1'>Consulte e analise rostos a partir de fotos de documentos, selfie, etc.</Typography>
            </Box>
            {
                isResult && (
                    <Box display="flex" gap={4} >
                        <Button
                            color="secondary"
                            size="small"
                            onClick={handleNewConsult}
                        >
                            Nova consulta
                        </Button>
                        {
                            isAvailableToPrint && (<Export printRef={printRef} />)
                        }
                    </Box>
                )
            }
        </Box>
        <Outlet />
    </Box >;
}

export default Facematch;