import { Box, Skeleton } from "@mui/material";

const Component: React.FC = () => {
  return (
    <Box display="flex" flexDirection="column">
      <Skeleton animation="wave" width="100%" height={50} />
      <Skeleton animation="wave" width="100%" height={50} />
      <Skeleton animation="wave" width="100%" height={50} />
      <Skeleton animation="wave" width="100%" height={50} />
      <Skeleton animation="wave" width="100%" height={50} />
    </Box>
  )
}

export default Component;