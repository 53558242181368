import React, { ReactNode } from "react"

import { Box } from "@mui/material";

import { reducers } from "@store";
import { useSelector } from 'react-redux';

interface StepProps {
  children: ReactNode,
  label?: String,
  renderStepClosed?: ReactNode,
  renderButton?: ReactNode,
  review?: boolean,
  index?: number,
  submit?: (data: any, event: any) => any
}

const Component: React.FC<StepProps> = ({ children, renderStepClosed, renderButton, index, review = false }) => {
  const { step, isLastStep } = useSelector((state: reducers.RootState) => state.formWizard);

  if (step === index && review) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <>
      {
        step === index ? (
          <>
            {children}
            <Box display="flex" justifyContent="flex-end">
              {renderButton}
            </Box>
          </>
        ) : (step > (index || 0) && (!isLastStep && !review)) ? (
          <>
            {renderStepClosed}
          </>
        ) : null
      }
    </>
  )
}

export default Component