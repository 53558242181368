import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { styled } from '@mui/system';

interface CustomFilterTextFieldProps extends Omit<TextFieldProps, 'helperText'> {
    helperText?: string;
    LabelText: string;
}

const CustomFilterTextFieldContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: 0,
    gap: 8,
    height: '100%',
    minWidth: 'fit-content'
}

const CustomFilterTextFieldLabelStyle: React.CSSProperties = {
    
    fontFamily: "'Be Vietnam'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '140%',
    color: '#000066',
    marginLeft: '4px'
}


const CustomFilterTextField = styled(({ helperText, LabelText, ...otherProps }: CustomFilterTextFieldProps) => (
    <div style={CustomFilterTextFieldContainerStyle}>
        <label style={CustomFilterTextFieldLabelStyle}>{LabelText}</label>
        <TextField {...otherProps} helperText={helperText} error={!!helperText} />
    </div>
  ))`

    input{
        color: #7D8285;
        heigth: 57px;
        padding: 19px 10px 19px 16px;
        caret-color: #0448AB;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        leading-trim: both;
        text-edge: cap;
        color: #555866;
    }

    input::placeholder{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
        leading-trim: both;
        text-edge: cap;
        color: #555866;
        opacity: 1;
    }

    fieldset{ 
        border: 1px solid #BED0D7;
        border-radius: 8px;
    }

    .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #0448AB;
    }

    .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #BED0D7;
    }

    .MuiFormHelperText-root {
        font-family: 'Be Vietnam';
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-style: normal;
        color: #FF003C;
        margin: 0;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: #FF003C; 
    }
`;

export default CustomFilterTextField;