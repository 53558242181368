import { GridColDef } from "@mui/x-data-grid";
import { UserComponents } from "@components"
import { isDesktopWidth } from "./screenWidth";
import { StatusCell } from "../components/GeneralComponents";


const desktopUserListColumns: GridColDef[] = [
    { field: 'login', headerName: 'Login', flex: 20, headerAlign: 'left', align: 'left' },
    { field: 'name', headerName: 'Nome', flex: 20, headerAlign: 'left', align: 'left' },
    { field: 'email', headerName: 'E-mail', flex: 14.28571428571429, headerAlign: 'left', align: 'left' },
    { field: 'group', headerName: 'Grupo', flex: 14.28571428571429, headerAlign: 'left', align: 'left' },
    {
        field: 'isSystemAdmin', headerName: 'Administrador', width: 150, headerAlign: 'left', align: 'left',
        renderCell: (params) => [<div className="switch-and-label-container">
            <UserComponents.SwitchCell params={params} />
            <label className="switch-label">{params.value ? 'Ativo' : 'Inativo'}</label>
        </div>]
    },
    {
        field: 'isEnabled', headerName: 'Ativo/Inativo', width: 150, headerAlign: 'left', align: 'left', 
        renderCell: (params) => {
            return [
                <div className="switch-and-label-container">
                    <UserComponents.SwitchCell params={params} />
                    <label className="switch-label">{params.value ? 'Ativo' : 'Inativo'}</label>
                </div>
            ]
        }
    },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        cellClassName: 'actions',
        headerAlign: 'left',
        align: 'left',
        getActions: (params) => [<UserComponents.ActionsCell params={params} />]
    },
]

const mobileUserListColumns: GridColDef[] = [
    { field: 'login', headerName: 'Login', width: 240, headerAlign: 'left', align: 'left' },
    {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        cellClassName: 'actions',
        headerAlign: 'left',
        align: 'left',
        width: 60,
        getActions: (params) => [<UserComponents.ActionsCell params={params} />]
    },
]

const userListColumns: GridColDef[] = isDesktopWidth ? desktopUserListColumns : mobileUserListColumns;


export default userListColumns