import { styled } from '@mui/material/styles';
import { Radio as RadioMaterial } from "@mui/material";

const Radio = styled(RadioMaterial)(() => ({
  padding: 0,
  display: "none"
}))

export {
  Radio
}