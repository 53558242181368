import { Container } from "./styles";

import { Button } from "@components";
import { Box, ClickAwayListener, Divider, Typography } from "@mui/material";
import { BiX } from "react-icons/bi"

import { useRef } from "react";

interface SideSectionProps {
  children: React.ReactNode,
  title: string | React.ReactNode,
  ActionButton?: React.ReactNode | undefined,
  hideCloseButton?: boolean,
  onClose: () => any,
  open: boolean
}

const Component: React.FC<SideSectionProps> = ({
  children,
  title,
  ActionButton,
  hideCloseButton = false,
  onClose,
  open
}) => {
  const ref = useRef<any>(null);

  const handleClose = (event: any) => {
    event.stopPropagation();

    onClose();
  }

  const handleMouseDown = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  const handleClickAway = () => {
    if (open)
      onClose();
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container
        open={open}
        onMouseDown={handleMouseDown}
        ref={ref}
      >
        <Box
          display="flex"
          width={'100%'}
          justifyContent='space-between'
          alignItems="center"
          gap={6}
          pb={6}
        >
          <Typography variant="h2">
            {title}
          </Typography>
          {
            !!ActionButton ?
              <>
                {ActionButton}
              </>
              :
              <Button
                color="secondary"
                size="small"
                StartIcon={<BiX size={24} />}
                onClick={handleClose}
                disableElevation
                disableFocusRipple
                disableRipple
                disableTouchRipple
              >
                Fechar
              </Button>
          }

        </Box>
        <Box
          width={'100%'}
        >
          <Divider variant="fullWidth" orientation="horizontal" />
        </Box>
        <Box
          width="100%"
          display={'flex'}
          flexDirection={'column'}
          gap={6}
        >
          {children}

          {
            !!ActionButton && !!!hideCloseButton && (

              <Box
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <Button
                  color="secondary"
                  size="small"
                  StartIcon={<BiX size={24} />}
                  onClick={handleClose}
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  Fechar
                </Button>
              </Box>

            )}

        </Box>

      </Container>
    </ClickAwayListener>
  )
}

export default Component;