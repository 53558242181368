import React from 'react';

import { Box, Typography } from "@mui/material";
import { Paper } from "@components";
import { Header } from "./styles";

type ComponentProps = {
  children: React.ReactNode,
  title: string,
  ratio?: string,
  status: "success" | "warning" | "danger" | "information",
  noBreak?: boolean
}

const Component: React.FC<ComponentProps> = ({ children, title, ratio, status, noBreak = true }) => {
  return (
    <Paper variant="outlined" className={noBreak ? "no-break" : ""}>
      <Header status={status}>
        <Box display="flex" justifyContent="space-between">
          {title}
          {
            ratio && (
              <Typography color="inherit" fontWeight="inherit" fontSize={16}>{ratio}</Typography>
            )
          }
        </Box>
      </Header>
      <Box display="grid" gridTemplateColumns={`repeat(2, 1fr)`} gridAutoRows="1fr" width="100%" p={4} gap={4}>
        {children}
      </Box>
    </Paper>
  );
}

export default Component;