import { ReactNode } from "react";

import { THead } from "./styles";

type HeadProps = {
  children: ReactNode
}

const Head: React.FC<HeadProps> = ({ children }) => {
  return (
    <THead>
      {children}
    </THead>
  )
}

export default Head