import { useDispatch } from 'react-redux';
import { GridActionsCellItem } from '@mui/x-data-grid/components/cell/GridActionsCellItem';

import { slices } from "@store";

import { FaPowerOff } from 'react-icons/fa';
import { styled } from '@mui/system';

const StyledDisableCell = styled(GridActionsCellItem)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color .5s ease;


  :hover{
    background-color: white;
    transition: background-color .5s ease;
  }
`;

const DisableCell = ({ params }: any) => {

  
  const dispatch = useDispatch();

  const handleDisableButtonClick = async () => {
    dispatch(slices.disableToken.setDisableTokenIdInfo({login: params.row.login, tokenId: params.row.id, wasConfirmed: false}))
    dispatch(slices.disableTokenDialog.setShowDialog(true))
  };

  return (
    <StyledDisableCell
      icon={<FaPowerOff color='#ff003c' size={16} />}
      label="Desabilitar"
      onClick={handleDisableButtonClick}
    />
  );
};

export default DisableCell;