import React, { useEffect, useState } from 'react';
import Joyride, { CallBackProps, Step } from 'react-joyride';
import * as onboardingStyle from './styles'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { reducers, slices } from "@store";
import { isDesktopWidth } from '@constants';

import getValueFromPath from "lodash/get";
import setValueFromPath from "lodash/set";

interface OnboardingProps {
    steps: Step[],
    controlPath: string,
    scrollToFirstStep?: boolean
}

const Component: React.FC<OnboardingProps> = ({
    steps,
    controlPath,
    scrollToFirstStep = false
}) => {
    const {
        showOnboardingTooltip,
        onboardingLocation
    } = useSelector((state: reducers.RootState) => state.onboardingTooltipReducer)

    const [runTour, setRunTour] = useState<boolean>(showOnboardingTooltip)

    const onboardingString = localStorage.getItem('onboardingControl')
    const onboarding = JSON.parse(onboardingString || '{}')
    const onboardingValue = getValueFromPath(onboarding, controlPath);

    const dispatch = useDispatch()
    const location = useLocation()

    const isUserFirstAccess = typeof onboardingValue === "boolean" ? onboardingValue : !!!onboardingValue;

    const handleFirstOnboardingTourEnd = () => {
        if (onboarding) {
            localStorage.setItem('onboardingControl', JSON.stringify(setValueFromPath(onboarding, controlPath, false)))
        }
    }

    const handleTourEnd = (data: CallBackProps) => {
        if (data.index === steps.length || data.action === 'reset') {
            handleFirstOnboardingTourEnd()
            setRunTour(false)
            dispatch(slices.onboardingTooltip.setOnboardingTooltipInfo({ showOnboardingTooltip: false, onboardingLocation: '' }))
        }
    };

    useEffect(() => {
        if (showOnboardingTooltip && onboardingLocation === location.pathname) {
            setRunTour(true)
        }
    }, [showOnboardingTooltip]);

    return <Joyride
        styles={{
            ...onboardingStyle,
            tooltip: isDesktopWidth ? onboardingStyle.tooltipBody : onboardingStyle.tooltipBodyMobile,
            options: { arrowColor: '#1D203A', zIndex: 5000 }
        }}
        scrollToFirstStep={scrollToFirstStep}
        showSkipButton
        steps={steps}
        floaterProps={{ placement: 'top-start' }}
        run={(runTour || isUserFirstAccess) ?? false}
        continuous={true}
        locale={{
            back: 'Voltar',
            close: 'Fechar',
            last: 'Finalizar tour',
            next: 'Continuar',
            skip: 'Pular tour'
        }}
        callback={handleTourEnd}
    />;
}

export default Component;

