import React from "react";

import { Box } from "@mui/material";
import { Notification } from "@components";
import { Card } from "../index";
import { Title } from "./styles"

import cards from "./cards.json";

type ComponentProps = {
  forensicValidations?: any
  hasError?: boolean
}

const Component: React.FC<ComponentProps> = ({ forensicValidations, hasError }) => {
  const successReports = cards.filter((card) => !!!Object.keys(forensicValidations || {}).includes(card.code));
  const warningReports = cards.filter((card) => !!Object.keys(forensicValidations || {}).includes(card.code));

  const totalReports = successReports.length + warningReports.length;
  const ratioSuccess = `${successReports.length}/${totalReports}`;
  const ratioWarning = `${warningReports.length}/${totalReports}`;

  if (hasError) {

    return (
      <>
        <Notification
          variant="danger"
          title="Ocorreu um problema com a extração de dados do(s) documento(s)."
          description=" Por favor, contate nossa equipe de suporte."
        />
      </>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>

      <Box className="no-break">
        <Box display="flex" alignItems="center" justifyContent="space-between" py={2} mb={4} className="no-break">
          <Title>Verificação do documento</Title>
        </Box>

        <Card.Container title="Está tudo certo!" ratio={ratioSuccess} status="success" noBreak={false}>
          {
            successReports.map((report) => (
              <Card.Item
                title={report.title}
                status="success"
              >
                {report.successDescription}
              </Card.Item>
            ))
          }
        </Card.Container>
      </Box>

      <Card.Container title="Necessita atenção!" ratio={ratioWarning} status="danger">
        {
          warningReports.map((report) => (
            <Card.Item
              title={report.title}
              status="danger"
            >
              {report.warningDescription}
            </Card.Item>
          ))
        }
      </Card.Container>

    </Box>
  )
}

export default Component;