import React from "react";

import { Box, SelectProps, Tooltip } from "@mui/material";
import { Label, RequiredSpan, FormControl, Select } from "./styles";

import { Controller, useFormContext } from "react-hook-form";
import { BiHelpCircle } from "react-icons/bi";

type ComponentProps = Omit<SelectProps, "helperText" | "variant"> & {
  name: string,
  label?: string,
  required?: boolean,
  description?: string,
  children: React.ReactNode,
  component?: React.ElementType
}

const Component: React.FC<ComponentProps> = ({ name, label, required, description, children, component = "div", ...props }) => {

  const { control, watch } = useFormContext();

  return (
    <Box display="flex" flexDirection="column" gap={2} component={component} {...props}>
      {
        !!label?.length && (
          <Label>
            <Box>
              {label}
              {required && (
                <RequiredSpan>
                  *
                </RequiredSpan>
              )
              }
            </Box>
            <Box>
              {
                description && (
                  <Tooltip title={description}>
                    <Box>
                      <BiHelpCircle size={20} />
                    </Box>
                  </Tooltip>
                )
              }
            </Box>
          </Label>
        )
      }
      <FormControl>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select onChange={(value) => field.onChange(value)} value={field.value}>
              {children}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  )
}

export default Component;