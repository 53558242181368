import { Card, Chip, GeneralComponents, InfoCard, Paper, SectionHeader } from '@components';
import { Box, Divider, useMediaQuery } from '@mui/material';
import React from 'react';
import { FaBook } from 'react-icons/fa';
import { FaQuestionCircle } from 'react-icons/fa';
import { Link, LinkContainer, ContactContainer } from './components'
import { useFetch } from '@hooks';
import { Billing } from '@services';
import { groupBySelectedKey } from '@utils';


const links = [
    {
        title: "Documentação",
        description: "Na documentação você encontrará:  listas de informações disponíveis em cada conjunto de dados, de onde essas informações vêm, tabela de preços para os diferentes conjuntos de informação e funcionalidades, até a forma de integrar-se às nossas APIs. A documentação possui todas as informações para que você possa utilizar o produto do seu interesse com autonomia.",
        icon: FaBook,
        url: "https://docs.bigdatacorp.com.br/"
    },
    {
        title: "Dúvidas frequentes (FAQ)",
        description: "FAQ, ou Perguntas Frequentes, é uma seção informativa que reúne as dúvidas mais comuns dos usuários e tutoriais sobre nossos portais,  oferecendo respostas claras e diretas. Ela serve como um recurso prático para que você possa obter informações essenciais de forma rápida e eficiente.",
        icon: FaQuestionCircle,
        url: 'https://bigdatacorp.zendesk.com/hc/pt-br'
    }
]

const contact = [
    {
        role: "Customer Success",
        description: "Auxilia na implementação de produtos, dúvidas, valores, etc. ",
    }
]

const Help: React.FC = () => {

    const breakpoint = useMediaQuery('(max-width: 1000px)')
    const { data, error, isLoading } = useFetch(Billing.getGroupInfo)

    const groupedList = groupBySelectedKey(
        data?.BdcRepresentatives ?? [],
        (representative: any) => representative?.Role === "Comercial"
    )

    const isRepresentativesListEmpty = groupedList.every((item: any) => !!!item.length)


    return <GeneralComponents.InternalPages
        title='Central de ajuda'
        description='Encontre rapidamente a ajuda necessária para resolver seu problema'
    >
        <Paper>
            <Box
                display={'flex'}
                flexWrap={'wrap'}
                gap={8}
                p={6}
            >
                <Box
                    display="flex"
                    flexDirection={'column'}
                    alignItems='flex-start'
                    gap={2}
                    width={!!isRepresentativesListEmpty ? '100%' : { xs: '100%', lg: '56%' }}
                >
                    <SectionHeader title='Encontre a resposta para seu problema' />
                    <Box
                        display="grid"
                        gridTemplateRows={`repeat(2, 1fr)`}
                        width="100%"
                        height={'100%'}
                        gap={4}
                    >

                        {
                            links.map(({ title, description, icon: IconContent, url }) =>
                                <Link
                                    to={url}
                                    target='_blank'
                                >
                                    <Card
                                        breakpoint={breakpoint}
                                        key={title}
                                        title={title}
                                        description={description}
                                        IconContent={IconContent}
                                    />
                                </Link>
                            )
                        }
                    </Box>
                </Box>

                {!!!isRepresentativesListEmpty && (
                    <ContactContainer>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={2}
                            width={'100%'}
                        >
                            <SectionHeader title='Não resolveu? Entre em contato conosco' />
                            <Box
                                display={"flex"}
                                flexDirection={'column'}
                                alignItems={'center'}
                                justifyContent={'flex-start'}
                                gap={4}
                                width={'100%'}
                            >
                                {
                                    groupedList.map((item: any) => {

                                        if (!!item.length) {
                                            const representative = contact.find((representative) => item[0]?.Role === representative.role)

                                            return (
                                                <InfoCard
                                                    key={item[0]?.Role}
                                                    title={item[0]?.Role}
                                                    description={representative?.description}
                                                >
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        gap={2}
                                                    >
                                                        <Chip><b>Nome:</b> {item[0]?.Name}</Chip>
                                                        <Chip><b>Email:</b> {item[0]?.Email}</Chip>
                                                    </Box>
                                                </InfoCard>
                                            )
                                        }
                                    }
                                    )
                                }

                            </Box>
                        </Box>
                    </ContactContainer>
                )
                }
            </Box>
        </Paper>
    </GeneralComponents.InternalPages >;
}

export default Help;