import { Button, Paper } from "@components";
import { Box } from "@mui/material";
import { Title, Text } from "./styles";

import { BiPencil } from 'react-icons/bi';

interface StepClosedProps {
  title: string,
  value: string | Array<string>,
  handleEdit: () => any
}

const StepClosed: React.FC<StepClosedProps> = ({ title, value, handleEdit }) => {
  return (
    <Paper>
      <Box display="flex" flexDirection="column" gap={6} p={6}>
        <Title>{title}</Title>
        <Box display="flex" alignItems="center" gap={4}>
          <Text>
            {value}
          </Text>
          <Button variant="action" onClick={handleEdit} StartIcon={<BiPencil size={20} />} id="edit-button">Editar</Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default StepClosed;