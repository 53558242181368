import React from "react";

const useFetch = (
  func: any,
  params?: any,
  options = {
    shouldAutoStart: true
  },
) => {
  const [data, setData] = React.useState<any>(null);
  const [error, setError] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isFetched, setIsFetched] = React.useState<boolean>(false);

  const fetch = () => {
    setData(null);
    setError(null);
    setIsFetched(false);
    setIsLoading(true);

    func(params)
      .then((response: any) => {
        setData(response.data);
        setIsLoading(false);
      })
      .catch((error: any) => {
        setError(error)
        setIsLoading(false);
      })
      .finally(() => {
        setIsFetched(true);
      })
  }


  React.useEffect(() => {
    if (!!options?.shouldAutoStart) {
      fetch()
    }
  }, [])

  return { data, error, isLoading, isFetched, fetch }
}

export default useFetch;
