import dayjs from "dayjs";
import { handleString } from "./index";
import { fields, options } from "@constants";


const isObject = (value: any) => {
  return typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null
}

const handleData: any = (list: Array<any>) => {
  if (!!!Array.isArray(list) && !!!isObject(list)) return;

  list = isObject(list) ? Object.entries(list) : list;

  return list.map(([key, value]) => {
    if (isObject(value)) {

      return { key, value: handleData(value) }
    } else if (Array.isArray(value)) {

      return { key, value: value.map(object => handleData(object)) }
    } else {

      return { key, value }
    }
  })
}

const transformResultToValidRows = (result: any, datasets: string[]) => {
  const datasetsCamelCase = datasets.map(dataset => handleString.snakeToPascal(dataset));

  const handledResult = Object.entries(result).map(([key, value]: [string, any]) => (
    datasetsCamelCase.includes(key) ?
      {
        key,
        title: handleString.toHumanize(key),
        data: handleData(value)
      }
      : null
  )).filter(result => result)

  return handledResult;
}


function isValidDate(str: string) {
  const splittedStr = str.slice(0, 10);

  return dayjs(splittedStr, 'YYYY-MM-DD', true).isValid();
}

interface typesProps {
  [key: string]: () => any
}

const transformAttributeValue = (value: any) => {
  const typesMapped: typesProps = {
    "string": () => {
      return isValidDate(value) ? handleString.toBrazilianDate(value) : value
    },
    "number": () => value.toString(),
    "boolean": () => value ? "SIM" : "NÃO"
  }

  const typeValue = typeof value;

  return typesMapped[typeValue]?.() || ""
}

const getDataOption = (item: "apis" | "conditions" | "datasets", value: string, by: string = "value") => {
  return options.pontualConsult[item].find((option: any) => option[by] === value)
}

const getLabelField = (item: "documents", fieldName: string) => {
  return Object.entries(fields[item]).map((field) => field[1])
    .find((field) => field.name === fieldName)?.label
}

const formatMoneyBRL = (value: number | string) => {

  if (!!!value) return 0;

  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

const transformDocumentsToValidApi = (documents: any) => {
  const documentsTransformed = Object.entries(documents).map(([key, value]) => !!value ? `${key}{${value}}` : null)
    .filter((document) => !!document);

  return documentsTransformed.join(",")
}

const cpfMask = (value: string) => {
  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const cnpjMask = (value: string) => {

  if (!value) return ''

  return value
    .replace(/\D+/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
}

const phoneMask = (value: string) => {

  if (!value) return ''

  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2").slice(0, 15)
}


const dateMask = (value: string) => {

  if (!value) return ''

  return value.replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2").slice(0, 10)

}

const zipCodeMask = (value: string) => {
  if (!value) return ""

  return value.replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2').slice(0, 9)
}

const transformDatasetsToValidData = (data: any = {}) => {
  const dataArray = Object.entries(data).filter(([, value]: any) => !!value?.selected);

  return dataArray.map(([key, value]: any) => {
    const attributes = getAttributesByDataset(value.content);
    return !!attributes ? `${key}{${attributes}}` : key;
  }).join(",");
}

const getAttributesByDataset: any = (attributes: any, prefix: string = "") => {
  const attributesArray = Object.entries(attributes || {}).filter(([, value]: any) => !!value?.selected);

  return attributesArray.map(([key, value]: any) => {
    const hasContent = !!Object.values(value.content || {}).filter((content: any) => !!content?.selected).length

    if (!hasContent) {
      return `${prefix}${key}`;
    }

    return getAttributesByDataset(value.content, `${prefix}${key}.`);
  }).join(",");
}

export {
  transformResultToValidRows,
  transformAttributeValue,
  transformDocumentsToValidApi,
  handleData,
  isObject,
  getDataOption,
  getLabelField,
  formatMoneyBRL,
  cpfMask,
  cnpjMask,
  phoneMask,
  dateMask,
  zipCodeMask,
  transformDatasetsToValidData
}