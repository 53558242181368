import React from "react";

import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Icon, SelectContainer, SelectItem } from "./styles";
import { Button, CheckboxTest, Paper, Tabs, Fab } from "@components";
import { DialogQualityImage, Field, Guide } from "./components";
import { MdOutlineAddPhotoAlternate, MdOutlineAddLink } from "react-icons/md";
import { BiInfoCircle, BiPlus } from "react-icons/bi";

import { FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { reducers, slices } from "@store";
import { yupResolver } from "@hookform/resolvers/yup";
import Schema from "./schema";

const Page: React.FC = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true
  });
  const navigate = useNavigate();
  const { dismissDialog, user } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.qualityImageDialog);
  const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user);

  const [openGuide, setOpenGuide] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  const { control, handleSubmit, register, watch, setValue, resetField, ...form } = useForm({
    defaultValues: {
      type: "file",
      qualityImage: {
        active: { selected: true }
      },
      documents: [
        {
          type: "complete",
          files: []
        }
      ]
    },
    resolver: yupResolver(Schema)
  });

  const { fields, append } = useFieldArray({
    control,
    name: "documents"
  });

  const type: any = useWatch({
    control,
    name: "type"
  });

  const documents = useWatch({
    control,
    name: "documents"
  }) || [];

  const qualityImage = useWatch({
    control,
    name: "qualityImage"
  });

  React.useEffect(() => {

    if (!!!qualityImage?.active?.selected && (!dismissDialog || user !== login)) {
      setOpenDialog(true);
    }
  }, [qualityImage]);


  const handleEmptyDocuments = () => {
    documents.forEach((document: any, index) => {

      if (document.type === "complete" && !!document.files[0])
        setValue(`documents.${index}.files`, [document.files[0]])
    })
  };

  const handleCancelDialog = () => {
    resetField("qualityImage");
    setOpenDialog(false);
  }

  const handleConfirmDialog = () => {
    setOpenDialog(false);
  }

  const beforeSubmitForm: any = (submit: any, event?: any) => {
    event.preventDefault();

    handleEmptyDocuments();
    submit();
  }

  const handleSubmitForm = (values?: any) => {
    navigate("result", { state: values });
  }

  const handleChangeTab = () => {
    const documentsValue = [{ type: "complete", files: [] }];

    setValue("documents", documentsValue);
    form.clearErrors("documents");
  }

  const addNewSection = () => {
    if (documents?.length < 5) {
      append({
        type: "complete",
        files: []
      })
    }
  }

  const handleOpenGuide = () => {
    setOpenGuide(true);
  }

  const handleCloseGuide = () => {
    setOpenGuide(false);
  }

  return (
    <>
      <Paper>
        <Box p={6}>
          <FormProvider
            setValue={setValue}
            control={control}
            register={register}
            watch={watch}
            resetField={resetField}
            handleSubmit={handleSubmit}
            {...form}
          >
            <>
              <Box sx={{ width: '100%' }}>
                <Tabs.Container
                  value={type}
                  name="type"
                  onChange={handleChangeTab}
                  variant="fullWidth"
                  orientation="horizontal"
                >
                  <Tabs.Item
                    value={"file"}
                    label={"Inserir imagem"}
                    wrapped
                  />
                  <Tabs.Item
                    value={"link"}
                    label={"Inserir link"}
                    wrapped
                  />
                </Tabs.Container>
              </Box>

              <Box display="flex" flexDirection="column" width="100%" gap={4} mt={5}>
                {
                  fields.map((item, i) => {
                    return (
                      <Box width="100%" display="flex" flexDirection="column" gap={6} key={item.id}>
                        <Box
                          display="flex"
                          alignItems="center"
                          flexDirection={
                            {
                              xs: "column-reverse",
                              md: "row"
                            }
                          }
                          gap={4}
                        >
                          <SelectContainer
                            name={`documents.${i}.type`}
                          >
                            <SelectItem value={"complete"}>
                              <Icon>
                                <MdOutlineAddPhotoAlternate size={22} />
                              </Icon>
                              {
                                isMobile ? "Documento completo" : "Inserir documento completo"
                              }
                            </SelectItem>
                            <SelectItem value={"frontAndBack"}>
                              <Icon>
                                <MdOutlineAddLink size={22} />
                              </Icon>
                              {
                                isMobile ? "Documento frente e verso" : "Inserir documento frente e verso"
                              }
                            </SelectItem>
                          </SelectContainer>
                          {
                            i === 0 && (
                              <Box
                                display="flex"
                                alignItems={{ xs: "flex-start", md: "center" }}
                                justifyContent="space-between"
                                width="100%"
                                flexDirection={
                                  {
                                    xs: "column",
                                    md: "row"
                                  }
                                }
                              >

                                <Button
                                  StartIcon={<BiInfoCircle size={20} />}
                                  color="secondary"
                                  size="small"
                                  onClick={handleOpenGuide}
                                >
                                  {
                                    isMobile ? (
                                      "Guia"
                                    ) : (
                                      "Confira nosso guia de captura de imagem"
                                    )
                                  }
                                </Button>
                                {
                                  isMobile ? (
                                    <Fab onClick={addNewSection}>
                                      <BiPlus size={28} />
                                    </Fab>
                                  ) : (
                                    <Button
                                      StartIcon={<BiPlus size={20} />}
                                      color="primary"
                                      size="medium"
                                      onClick={addNewSection}
                                    >
                                      {type === "file" ? "Adicionar mais documentos" : "Adicionar mais links"}
                                    </Button>
                                  )
                                }
                              </Box>
                            )
                          }

                        </Box>

                        <Box display="flex">
                          <Box display="grid" gridTemplateColumns={{ xs: `repeat(1, 1fr)`, md: `repeat(2, 1fr)` }} width="100%" gap={4}>
                            <Field
                              key={`${item.id}-front`}
                              type={type}
                              name={`documents.${i}.files.0`}
                              identifier={documents?.[i]?.type === "frontAndBack" ? "Frente" : "Completo"}
                            />
                            {
                              documents?.[i]?.type === "frontAndBack" && (
                                <Field
                                  key={`${item.id}-back`}
                                  type={type}
                                  name={`documents.${i}.files.1`}
                                  identifier="Verso"
                                />
                              )
                            }
                          </Box>
                        </Box>
                      </Box>
                    )
                  })
                }
              </Box>

              <Box display="flex" flexDirection={{ xs: "column", md: "row" }} justifyContent="flex-end" mt={{ xs: 10, md: 40 }}>
                <CheckboxTest.Container name={`qualityImage`} fullWidth={false}>
                  <CheckboxTest.Item value="active" label="Ativar qualidade de imagem" />
                </CheckboxTest.Container>

                <Box width={{ xs: "100%", md: "auto" }}>
                  <Button
                    size="small"
                    onClick={(e) => beforeSubmitForm(handleSubmit(handleSubmitForm), e)}
                    fullWidth={isMobile}
                  >
                    Ler documentos
                  </Button>
                </Box>
              </Box>
            </>
          </FormProvider>
        </Box>
      </Paper >
      <Guide
        open={openGuide}
        onClose={handleCloseGuide}
      />
      <DialogQualityImage
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onCancelDialog={handleCancelDialog}
        onConfirmDialog={handleConfirmDialog}
      />
    </>
  )
}

export default Page;