import { styled } from '@mui/system';
import ListItemText from '@mui/material/ListItemText';

const LastUsedText = styled(ListItemText)` 

    p{
        color: var(--to-approval-neutral-low-low-medium, #555866);
        font-family: Be Vietnam;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%; 
    }

    span{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: #000066;
    }
`;

export default LastUsedText;