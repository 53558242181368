import { IconButton } from "./styles";
import { IconButtonProps } from "@mui/material";

interface CustomIconButtonProps extends Omit<IconButtonProps, 'variant'> {
  color: "primary" | "secondary" | "success" | "error"
}

const Component = ({
  children,
  color,
  ...props
}: CustomIconButtonProps) => {
  return (
    <IconButton
      disableRipple
      color={color}
      {...props}
    >
      {children}
    </IconButton>
  )
}

export default Component;