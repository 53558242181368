import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import { GeneralComponents, CustomPagination, Onboarding } from "@components";

import FilterSection from './FilterSection';

import { reducers, slices } from "@store";

import { showToastCustomErrorMessage, transformUserListToValidRows, handleAxiosError, transformUserListToCSV, downloadCSV, isFrom } from '@utils';

import { BigBrotherTypes } from '@types';

import { userManagementGridColumns, isDesktopWidth, steps } from "@constants";

import { BigBrother } from '@services';

import './userManagement.css'
import EditUser from './EditUser';
import ViewUser from './ViewUser';
import CreateUser from './CreateUser';
import { FiEdit, FiPlus } from 'react-icons/fi';
import BatchEdit from './BatchEdit';
import { BiExport } from 'react-icons/bi';
import { BsFilter } from 'react-icons/bs';
import { Typography } from '@mui/material';


const datagridPageSizeOptions = [12]
const datagridSlots = {
    pagination: CustomPagination,
    noRowsOverlay: () => <GeneralComponents.InfoNote noteTitle='Nada encontrado' noteDescription='Você ainda não possui informações para ver aqui.' />
}

const UserManagement: React.FC = () => {
    const filterInfo = useSelector((state: reducers.RootState) => state.userManagementFilterReducer)
    const showViewUserSection = useSelector((state: reducers.RootState) => state.viewUserReducer.showViewUserSection)
    const twoFactorAuth = useSelector((state: reducers.RootState) => state.editUserReducer['2fa'])
    const {
        login,
        email,
        name,
        group,
        isEnabled,
        isSystemAdmin,
        showEditUserSection,
        wasUserEditionConfirmed
    } = useSelector((state: reducers.RootState) => state.editUserReducer)
    const {
        limit,
        skip
    } = useSelector((state: reducers.RootState) => state.userPaginationReducer)
    

    const [localOutsideClickOcurred, setLocalOutsideClickOcurred] = useState<boolean>(false)

    const [userListData, setUserListData] = useState<BigBrotherTypes.ListAllUsersApiResponse>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [showCreateUserSection, setShowCreateUserSection] = useState<boolean>(false)
    const [isMobileFilterSectionOpen, setIsMobileFilterSectionOpen] = useState<boolean>(false)
    const [showBatchEditSection, setShowBatchEditionSection] = useState<boolean>(false)

    const dispatch = useDispatch()

    const getUserList = async () => await BigBrother.getUserList({
        ...filterInfo,
        limit,
        skip,
        isFrom: isFrom()
    })

    const exportUserList = async () => await BigBrother.getUserList({ 
        isFrom: isFrom()
    })
    const checkIfisInvalidSession = (userListData: BigBrotherTypes.ListAllUsersApiResponse) => userListData.message === "Invalid session."

    const handleBigBrotherApiResponse = (userListData: BigBrotherTypes.ListAllUsersApiResponse, isFrom: string) => {

        if (checkIfisInvalidSession(userListData)) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça o login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
        } else if (isFrom === 'getUserList') {
            setUserListData(userListData)
            dispatch(slices.userPagination.setUserPaginationInfo({ limit, skip, page: userListData.page, totalRecords: userListData.totalRecords }))
            setIsLoading(false)
        } else if (isFrom === 'exportUserList') {
            const userListCsv = transformUserListToCSV(userListData)
            downloadCSV(userListCsv, 'ListUsers.csv')
        }
    }

    const tryGetUserListData = useCallback(async () => {
        try {
            const { data: apiBigBrotherResponse } = await getUserList()
            handleBigBrotherApiResponse(apiBigBrotherResponse, 'getUserList')
        } catch (error: any) {
            handleAxiosError(error)
        }
    }, [filterInfo, skip, login, email, name, group, isEnabled, isSystemAdmin, twoFactorAuth])


    const tryExportUserListData = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        try {
            const { data: apiBigBrotherResponse } = await exportUserList()
            handleBigBrotherApiResponse(apiBigBrotherResponse, 'exportUserList')
        } catch (error: any) {
            handleAxiosError(error)
        }
    }

    const handleCreateUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setShowCreateUserSection(true)
    }

    const handleCloseCreateUserSection = () => {
        setShowCreateUserSection(false)
    }

    const handleOutsideClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (showCreateUserSection) {
            setLocalOutsideClickOcurred(true)
        }
        if (showViewUserSection) {
            dispatch(slices.viewUser.setShowViewUserSection(false))
        }
        if (showEditUserSection) {
            handleCloseEditUserSection()
        }
    }

    const toggleMobileFilterOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsMobileFilterSectionOpen(!isMobileFilterSectionOpen)
    }

    const resetLocalOutsideClickStatus = () => {
        setLocalOutsideClickOcurred(false)
    }

    const handleCloseEditUserSection = () => {
        dispatch(slices.editUser.setOutsideClickOcurred(true))
    }

    const toggleShowBatchEditSection = () => {
        setShowBatchEditionSection(!showBatchEditSection)
    }

    useEffect(() => {
        setIsLoading(true)
        tryGetUserListData()
    }, [tryGetUserListData]
    )

    useEffect(() => {
        if (wasUserEditionConfirmed) {
            setIsLoading(true)
            tryGetUserListData()
            slices.editUser.setWasUserEditionConfirmed(false)
        }
    }, [tryGetUserListData, wasUserEditionConfirmed]
    )



    const userListRows = useMemo(() => transformUserListToValidRows(userListData), [userListData])

    return !showBatchEditSection ? <main className='user-management-main-container' onMouseDown={handleOutsideClick}>
        <div className='user-management-presentation-container'>
            <Typography
                variant='h1'
                component={'h1'}
                className='user-management-title'
            >
                Gestão de usuários
            </Typography>
            <Typography
                variant='subtitle1'
                component={'p'}
                className='user-management-paragraph'
            >
                Gerencie os usuários da sua corporação.
            </Typography>
        </div>
        <div className='user-management-options-container'>

            <FilterSection
                toggleMobileFilterOpen={toggleMobileFilterOpen}
                mobileFilterSectionClassName={isMobileFilterSectionOpen ? 'mobile-filter-section-opened' : 'mobile-filter-section-closed'} isMobileFilterSectionOpen={isMobileFilterSectionOpen} />
            {!!!isDesktopWidth && (
                <GeneralComponents.CustomDefaultTextButton
                    startIcon={<BsFilter color='#0068ff' size={24} />}
                    className='mobile-filter-button'
                    onClick={toggleMobileFilterOpen}>
                    Filtros
                </GeneralComponents.CustomDefaultTextButton>
            )}
            <div className='user-management-options-content'>
                <GeneralComponents.CustomDefaultButton
                    type='button'
                    className='create-user-button'
                    size='small'
                    variant='contained'
                    startIcon={<FiPlus color='white' size={18} />}
                    onClick={handleCreateUserClick}
                >
                    Novo usuário
                </GeneralComponents.CustomDefaultButton>
                {
                    !!isDesktopWidth && (
                        <GeneralComponents.CustomDefaultButton
                            type='button'
                            className='create-user-button'
                            size='small'
                            variant='contained'
                            startIcon={<FiEdit color='white' size={18} />}
                            onClick={toggleShowBatchEditSection}
                        >
                            Edição em lote
                        </GeneralComponents.CustomDefaultButton>
                    )
                }
            </div>
        </div>
        <div className='user-management-list-container'>
            {
                !!isDesktopWidth && (
                    <GeneralComponents.CustomDefaultTextButton
                        sx={{ color: '#006' }}
                        startIcon={<BiExport color='#006' />}
                        onClick={tryExportUserListData}
                    >
                        Exportar usuários
                    </GeneralComponents.CustomDefaultTextButton>
                )
            }
            <GeneralComponents.StyledDataGridWithPagination
                disableRowSelectionOnClick={!isDesktopWidth}
                rows={userListRows}
                columns={userManagementGridColumns}
                pagination
                pageSizeOptions={datagridPageSizeOptions}
                slots={datagridSlots}
                loading={isLoading}
                disableColumnFilter
            />
        </div>
        {showEditUserSection ?
            <EditUser
                className='edit-user-container-opened'
                closeEditUserSection={handleCloseEditUserSection} />
            :
            <EditUser className='edit-user-container-closed'
                closeEditUserSection={handleCloseEditUserSection} />
        }
        {showViewUserSection ?
            <ViewUser className='view-user-container-opened' />
            : <ViewUser className='view-user-container-closed' />
        }
        {showCreateUserSection ?
            <CreateUser className='create-user-container-opened'
                resetLocalOutsideClickStatus={resetLocalOutsideClickStatus}
                outsideClickOcurred={localOutsideClickOcurred}
                closeCreateUserSection={handleCloseCreateUserSection} />
            : <CreateUser
                className='create-user-container-closed'
                resetLocalOutsideClickStatus={resetLocalOutsideClickStatus}
                outsideClickOcurred={localOutsideClickOcurred}
                closeCreateUserSection={handleCloseCreateUserSection} />
        }
        <Onboarding
            steps={steps.UserManagement}
            controlPath={"UserManagement"}
        />
    </main>
        :
        <BatchEdit toggleShowBatchEditSection={toggleShowBatchEditSection} />


}

export default UserManagement;