import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const CustomTooltip = styled(Tooltip)`
    .MuiTooltip-tooltip{
        background-color: red;
        font-family: 'Be vietnam', sans-serif;
    }
`;

export default CustomTooltip;