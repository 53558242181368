// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slick-next{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 24px;
    z-index: 10;
}


.slick-prev{
    position: absolute;
    width: 24px;
    height: 24px;
    top: 24px;
    right: 64.42px;
    z-index: 10;

}`, "",{"version":3,"sources":["webpack://./src/components/DashboardComponents/NewsSliderContainer/newsSliderContainer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,WAAW;IACX,WAAW;AACf;;;AAGA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,SAAS;IACT,cAAc;IACd,WAAW;;AAEf","sourcesContent":[".slick-next{\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    top: 24px;\n    right: 24px;\n    z-index: 10;\n}\n\n\n.slick-prev{\n    position: absolute;\n    width: 24px;\n    height: 24px;\n    top: 24px;\n    right: 64.42px;\n    z-index: 10;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
