// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters-container{
    display: flex;
    width: 100%;
    max-width: 1200px;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    z-index: 100;
    margin-top: 10px;
    flex-wrap: wrap;
}

.text-filters-container{
    display: flex;
    gap: 16px;
}

.filter-actions-container{
    display: flex;
    align-self: flex-end;
    align-items: center;
    height: 55px;
    padding-bottom: 4px;
    gap: 8px;
}

@media screen and (max-width: 1370px){

    .filter-section-container{
       width: 100%;
    }

    .filter-actions-container{
        display: flex;
        align-self: flex-end;
        align-items: center !important;
        height: 55px;
        padding-bottom: 4px;
        gap: 8px;
    }

    .text-filters-container{
        display: flex;
        gap: 8px;
    }

    .filters-container{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        z-index: 100;
        margin-top: 10px;
        margin-left: 0;
        flex-wrap: wrap;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/pages/UserManagement/FilterSection/filterSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,SAAS;IACT,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,SAAS;AACb;;AAEA;IACI,aAAa;IACb,oBAAoB;IACpB,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;;IAEI;OACG,WAAW;IACd;;IAEA;QACI,aAAa;QACb,oBAAoB;QACpB,8BAA8B;QAC9B,YAAY;QACZ,mBAAmB;QACnB,QAAQ;IACZ;;IAEA;QACI,aAAa;QACb,QAAQ;IACZ;;IAEA;QACI,aAAa;QACb,WAAW;QACX,mBAAmB;QACnB,uBAAuB;QACvB,YAAY;QACZ,QAAQ;QACR,YAAY;QACZ,gBAAgB;QAChB,cAAc;QACd,eAAe;IACnB;;AAEJ","sourcesContent":[".filters-container{\n    display: flex;\n    width: 100%;\n    max-width: 1200px;\n    flex-direction: row;\n    align-items: flex-start;\n    padding: 0px;\n    gap: 16px;\n    z-index: 100;\n    margin-top: 10px;\n    flex-wrap: wrap;\n}\n\n.text-filters-container{\n    display: flex;\n    gap: 16px;\n}\n\n.filter-actions-container{\n    display: flex;\n    align-self: flex-end;\n    align-items: center;\n    height: 55px;\n    padding-bottom: 4px;\n    gap: 8px;\n}\n\n@media screen and (max-width: 1370px){\n\n    .filter-section-container{\n       width: 100%;\n    }\n\n    .filter-actions-container{\n        display: flex;\n        align-self: flex-end;\n        align-items: center !important;\n        height: 55px;\n        padding-bottom: 4px;\n        gap: 8px;\n    }\n\n    .text-filters-container{\n        display: flex;\n        gap: 8px;\n    }\n\n    .filters-container{\n        display: flex;\n        width: 100%;\n        flex-direction: row;\n        align-items: flex-start;\n        padding: 0px;\n        gap: 8px;\n        z-index: 100;\n        margin-top: 10px;\n        margin-left: 0;\n        flex-wrap: wrap;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
