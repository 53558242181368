import { GeneralComponents } from '@components';
import { FileTransferCenter } from '@services';
import { reducers, slices } from '@store';
import { handleAxiosError } from '@utils';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';


const FileTransferDetails: React.FC = () => {

    const dispatch = useDispatch()
    const { open, params } = useSelector((state: reducers.RootState) => state.fileTransferDetails)
    const sideSectionRef = useRef(null)
    const key = params?.Key

    const getFileByteArray = async () => FileTransferCenter.getFile({
        key
    })
    const saveByteArray = (fileName: string, base64File: any, type: string) => {

        const url = `data:${type};base64,${base64File}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const tryGetFileByteArray = async () => {
        try {
            const { data: apiResponse } = await getFileByteArray()
            const { fileName, base64File, fileType } = apiResponse
            saveByteArray(fileName, base64File, fileType)
        }
        catch (err: any) {
            handleAxiosError(err)
        }
    }

    const closeAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        dispatch(slices.fileTransferDetails.setShowFileTransferDetailsSection(false))
    }

    return <GeneralComponents.MobileSideSection
        sideSectionTitle='Detalhes do arquivo'
        mainButtonType='default'
        mainButtonText='Baixar arquivo'
        mainButtonAction={tryGetFileByteArray}
        closeAction={closeAction}
        open={open}
        sideSectionRef={sideSectionRef}
    >
        <div className='invoice-details-content'>
            <div className='invoice-details-data'>
                <div className='mobile-side-section-group'>
                    <label>Nome do arquivo:</label>
                    <span>{params?.FileName}</span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Extensão do arquivo:</label>
                    <span><GeneralComponents.Tag>{params?.FileExtension}</GeneralComponents.Tag></span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Pasta:</label>
                    <span><GeneralComponents.Tag>{params?.Folder}</GeneralComponents.Tag></span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Tamanho do arquivo:</label>
                    <span><GeneralComponents.Tag>{params?.Size}</GeneralComponents.Tag></span>
                </div>
                <div className='mobile-side-section-group'>
                    <label>Última mudança:</label>
                    <span>{params?.LastModified}</span>
                </div>
            </div>
        </div>
    </GeneralComponents.MobileSideSection>

}

export default FileTransferDetails;