import { Box, Grid } from '@mui/material';
import React from 'react';
import ImageInfo from '../ImageInfo';
import Similarity from '../Similarity';
import ImageQuality from '../ImageQuality/components/View';
import { useSelector } from 'react-redux';
import { reducers } from '@store';

interface PrintProps {
  state: any
}

const Print: React.FC<PrintProps> = ({
  state
}) => {

  const { consult, qualityImage } = useSelector((state: reducers.RootState) => state.exportPdfFacematch);
  const { firstImage = {}, secondImage = {} } = qualityImage

  const percentage = !!consult?.EstimatedInfo?.Similarity ? (consult?.EstimatedInfo?.Similarity / 100) : -1

  const consultHasError = !!!consult?.resultCode
  const firstImageHasError = !!!firstImage?.resultCode
  const secondImageHasError = !!!firstImage?.resultCode

  return <Box display={'none'} displayPrint={'block'}>
    <Grid container direction={'column'}>
      <Grid container item gap={8}>
        <Grid xs={12}>
          <ImageInfo
            ticketId={consult?.TicketId}
            data={state}
          />
        </Grid>
        <Grid>
          <Box p={5}>
            <Similarity
              value={percentage}
              ResultCode={consult?.ResultCode}
              ResultMessage={consult?.ResultMessage}
              error={!!consultHasError ? consult : false}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container direction={'column'} gap={8}>
        {
          !!state?.checkbox?.qualityCheck?.selected && (
            <Grid container item gap={3} direction={'column'}>
              <Grid item>
                <Box p={5} display="flex" flexDirection="column" gap={2}>
                  <ImageQuality data={qualityImage?.firstImage?.data} title="Qualidade da primeira imagem" error={!!firstImageHasError ? firstImage?.data : false}/>
                </Box>
              </Grid>

              <Grid item>
                <Box p={5} display="flex" flexDirection="column" gap={2}>
                  <ImageQuality data={qualityImage?.secondImage?.data} title="Qualidade da segunda imagem" error={!!secondImageHasError ? secondImage?.data : false}/>
                </Box>
              </Grid>
            </Grid>
          )
        }
      </Grid>
    </Grid>
  </Box>;
}

export default Print;