import { bigBrotherLogWhiteList } from "@constants";
import dayjs from "dayjs";
import { store } from '@store'

const getSession = () => store?.store?.getState()?.persistedUserInfoReducer?.user?.sessionId

const getApiName = (url: string | undefined) => url?.split(".")[0].replace("https://", "").toUpperCase()

const getUrlParts = (url: string | undefined) => url?.split('/').map((part) => part.toUpperCase())

const getEndpoint = (url: string | undefined) => url?.split('/api')[1]

const handleBigBrotherLogNeed = (API: any, response: any, endpoint: string) => {
  
  const isEndpointInLogWhiteList = bigBrotherLogWhiteList.includes(endpoint ?? '')

  if (!!isEndpointInLogWhiteList) {

    const { data: { result, message }, status } = response
    const { login, group: domain } = store.store.getState().persistedUserInfoReducer.user

    API?.SEND_LOGS({
      result,
      message,
      status,
      datetime: dayjs().toISOString(),
      endpoint,
      domain,
      login
    })

    return
  }

  return
}

export { getSession, getApiName, getEndpoint, handleBigBrotherLogNeed, getUrlParts };