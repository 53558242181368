import { ReactNode } from "react";

import { Td } from "./styles";

type HeadProps = {
  children: ReactNode,
  colSpan?: number
}

const Head: React.FC<HeadProps> = ({ children, colSpan }) => {
  return (
    <Td
      colSpan={colSpan}
    >
      {children}
    </Td>
  )
}

export default Head