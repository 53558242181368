import React from "react";

import { Box, Collapse } from "@mui/material";
import { CheckboxTest } from "@components";
import Attributes from "../Attributes";

import { BiCaretDown, BiCaretRight } from "react-icons/bi";

interface ComponentProps {
  label: string,
  value: string,
  properties: any
}

const Component: React.FC<ComponentProps> = ({
  label,
  value,
  properties
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const CollapseIcon = expanded ? BiCaretDown : BiCaretRight

  const handleExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <>
      <Box display="flex" alignItems="center" gap={1}>
        <CollapseIcon size={12} onClick={handleExpand} id="icon-collapse"/>
        <CheckboxTest.Item
          label={label || value}
          value={value}
          properties={properties}
        />
      </Box>
      <Collapse in={expanded} timeout="auto">
        {
          expanded && (
            <CheckboxTest.Container name={`datasets.${value}.content`}>
              <Attributes dataset={value} />
            </CheckboxTest.Container>
          )
        }
      </Collapse>
    </>
  )
}

export default Component