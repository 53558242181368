import React from 'react';
import { styled } from '@mui/system';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { isDesktopWidth } from '@constants';

interface CustomDatePickersProps extends DatePickerProps<Dayjs> {
    LabelText: string;
    handleStartChange: (dtStart: Dayjs | null) => void;
    handleEndChange: (dtEnd: Dayjs | null) => void;
    handleFieldErrors: () => void
    startValue: string
    endValue: string
    format?: string
    variant?: string
}

interface CustomSingleDatePickerProps extends DatePickerProps<Dayjs> {
    variant?: string
}


const CustomDateTimePickerContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    width: isDesktopWidth ? 428 : '100%',
    padding: 0,
    gap: 8,
}

const CustomDateTimePickerDivStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: 0,
    gap: 1
}

const FilterDateTimePickerLabelStyle: React.CSSProperties = {
    fontFamily: "'Be Vietnam'",
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '140%',
    color: '#000066',
    marginLeft: '4px'
}

const DefaultDateTimePickerLabelStyle: React.CSSProperties = {
    height: '21px',
    fontFamily: "Be Vietnam",
    alignSelf: 'flex-start',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.5px',
    color: '#555866',
    marginLeft: '2px',
}

const CustomDateTimePickerSpanlStyle: React.CSSProperties = {
    color: '#BED0D7',
    alignSelf: 'center'
}

const StyledDatePicker = styled(DatePicker<Dayjs>)<CustomSingleDatePickerProps>(({ variant }) => {
    return `

    &&{
        position: relative;
    }

    
    & .MuiIconButton-root{
        margin: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }

    & .MuiSvgIcon-root{
        margin-rigth: 1px;
        width: 16px;
        heigth: 16px;
        color: #BED0D7;
    }

    & .MuiInputBase-root{
        padding-right: 14px;
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        color: #555866;
        height: 55px;
        gap: 0px;

        
    }

    & .MuiInputBase-input{
        font-family: 'Be Vietnam';
        padding: 10px 10px 10px 16px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: #555866;
    }

    ${variant === 'filter' ? `
    & .MuiInputBase-root:hover{
        .MuiOutlinedInput-notchedOutline{
            border: 1px solid #BED0D7;
            border-radius: 8px;
        }
    }

    & .MuiFormLabel-root{
        font-family: 'Be Vietnam';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 180%;
        color: #555866;
    }


    & .MuiFormLabel-filled, .MuiInputLabel-shrink{
        display: none;
    }

    & .MuiOutlinedInput-notchedOutline{
        border: 1px solid #BED0D7;
        border-radius: 8px;
        height: 55px;
        top: 0;
        margin: 0;
        
        & legend{
            display: none;
            visibility: hidden;
            opacity: 0;
            margin: 0;
            padding: 0;
        }
    }
    `
            :

            `
    input{
        color: #7D8285;
        padding: 20px 15px;
        font-family: 'Be vietnam', sans-serif;
        caret-color: #0448AB;
        letter-spacing: 0.5px;
    }

    input::placeholder{
        color: #7D8285;
        opacity: 1;
        font-family: 'Be vietnam', sans-serif;
        letter-spacing: 0.5px;
    }

    label {
        font-family: 'Be Vietnam', sans-serif;
        color: #7D8285;
        letter-spacing: 0.5px;
        font-size: 14px;
        line-height: 160%;
    }

    fieldset{ 
        border-radius: 8px;
        border: 2px solid #B1B9BD;
        color: #B1B9BD;

        
    }

    & .MuiFormLabel-filled, .MuiInputLabel-shrink{
        display: none;
    }

    & .MuiOutlinedInput-notchedOutline{
        border: 1px solid #BED0D7;
        border-radius: 8px;
        height: 55px;
        top: 0;
        margin: 0;
        
        & legend{
            display: none;
            visibility: hidden;
            opacity: 0;
            margin: 0;
            padding: 0;
        }
    }

    & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #0448AB;
    }
    
    & .MuiFormHelperText-root {
        font-family: 'BeVietnam-Medium', sans-serif;
        font-size: 12px;
        letter-spacing: 0.5px;
        font-weight: 600;
        font-style: normal;
        color: #FF003C;
        margin: 0;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 4px 0px 0px;
        gap: 10px;
        order: 1;
        align-self: stretch;
    }

    & .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: #FF003C;
    }

    & .MuiOutlinedInput-root.Mui-error .MuiIconButton-root svg{
        color: #FF003C !important; 
    }
    `
        }
    
`
});

const CustomDatePickers = (
    {
        LabelText,
        handleStartChange,
        handleEndChange,
        handleFieldErrors,
        startValue,
        endValue,
        views,
        format = 'YYYY_MM',
        variant = 'filter',
        ...otherProps
    }: CustomDatePickersProps) => {

    return <div style={CustomDateTimePickerContainerStyle}>
        <label style={variant === 'filter' ? FilterDateTimePickerLabelStyle : DefaultDateTimePickerLabelStyle}>{LabelText}</label>
        <div style={CustomDateTimePickerDivStyle}>
            <StyledDatePicker
                onChange={handleStartChange}
                onError={handleFieldErrors}
                onClose={handleFieldErrors}
                label="Data de início"
                value={startValue === '' ? null : dayjs(startValue, format, 'pt-br')}
                views={views}
                variant={variant}
            />
            <span style={CustomDateTimePickerSpanlStyle}>-</span>
            <StyledDatePicker
                label="Data limite"
                onError={handleFieldErrors}
                onClose={handleFieldErrors}
                onChange={handleEndChange}
                value={endValue === '' ? null : dayjs(endValue, format, 'pt-br')}
                views={views}
                variant={variant}
            />
        </div>
    </div>
}
export default CustomDatePickers;


