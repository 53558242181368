import React, { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Divider, Paper, SelectChangeEvent } from '@mui/material';
import { BsFileEarmarkCheckFill } from 'react-icons/bs';
import { GeneralComponents } from '@components';
import { ContentContainer, DashedContainer, DraggingDashedContainer, FileInfoContainer, PaperContainer, ProgressBarContainer, UploadMessageContainer, UploadMessageDivider, UploadOptionsContainer, UploadedFileContainer } from './styles';
import { FileTransferCenter } from '@services';
import { reducers, slices } from '@store';
import { formatFileSize, handleAxiosError, handleString, showToastCustomErrorMessage } from '@utils';
import { isDesktopWidth } from '@constants';
import { AiFillFolderOpen } from 'react-icons/ai';
import { AxiosError } from 'axios';

interface UploadFilesProps {
    open: boolean
    handleClose: any
}

const UploadFiles: React.FC<UploadFilesProps> = ({ open, handleClose }) => {

    const inputFile = useRef<HTMLInputElement>(null)

    const { login } = useSelector((state: reducers.RootState) => state.persistedUserInfoReducer.user)

    const [fileInfo, setFileInfo] = useState<File>()
    const [fileContent, setFileContent] = useState<string | ArrayBuffer | null>()
    const [fileAlreadyExists, setFileAlreadyExists] = useState<boolean>(false)

    const [folder, setFolder] = useState<string>('dataplatform')

    const [isDragging, setIsDragging] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [resultInfo, setResultInfo] = useState<any>()

    const dispatch = useDispatch()

    const uploadFileToS3Bucket = async (forceUpload: boolean) => FileTransferCenter.uploadFile({
        login,
        fileName: fileInfo?.name,
        base64File: fileContent,
        folder,
        forceUpload
    })

    const handleDragEnter = (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDragOver = (event: React.DragEvent) => {
        event.preventDefault();
    };

    const handleSelectFolderChange = (event: SelectChangeEvent<unknown>) => {
        setFolder(event.target.value as string)
    }

    const isFileExtensionValid = (file: File) => {
        const allowedExtensions = [
            'pdf',
            'plain',
            'csv',
            'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'vnd.ms-excel',
            'png',
            'jpg',
            'jpeg',
            'x-gzip',
            'x-compressed',
            'x-zip-compressed',
            'zip',
            'x-zip',
            'x-7z-compressed',
            'pgp-encrypted',
            'pgp-signature',
            ""
        ]
        const fileExtension = file?.type?.split('/')[1] ?? ""

        if (!!!allowedExtensions.includes(fileExtension)) {
            showToastCustomErrorMessage('Extensão de arquivo não permitida.', 'Apenas arquivos de texto, planilhas, imagens ou compactados podem ser carregados.')
            return false
        } else {
            return true
        }
    }

    const loadFile = (file: File) => {

        if (isFileExtensionValid(file)) {

            setFileInfo({
                ...file,
                size: file?.size,
                name: handleString.toValidFileName(file?.name)
            })
            setLoadingProgress(0);

            const reader = new FileReader();

            reader.onprogress = (event) => {
                if (event.lengthComputable) {
                    const progress = (event.loaded / event.total) * 100;
                    setLoadingProgress(progress);
                }
            };

            reader.onloadend = () => {
                setFileContent(reader.result)
            }

            reader.readAsDataURL(file);
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileInput = event.target;
        if (fileInput && fileInput.files && fileInput.files.length > 0) {
            const file = fileInput.files[0];

            loadFile(file)
            setLoadingProgress(0)
        }
    }


    const handleDrop = (event: React.DragEvent) => {
        event.preventDefault()
        event.stopPropagation()
        setIsDragging(false);

        const file = event.dataTransfer.files[0];

        loadFile(file)
        setLoadingProgress(0)
    };

    const handleSelectFileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (inputFile.current) {
            inputFile.current.click()
        }
    }

    const handleComponentState = () => {
        setFileInfo(undefined)
        setIsUploading(false)
        setFileAlreadyExists(false)
    }

    const handleUploadRequest = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        tryToUploadFile()
    }

    const handleForceUploadRequest = () => {
        setFileAlreadyExists(false)
        tryToUploadFile(true)
    }

    const tryToUploadFile = async (forceUpload = false) => {
        try {
            setIsUploading(true)
            const { data: fileTransferApiResponse } = await uploadFileToS3Bucket(forceUpload)
            const { status, fileName, fileSize, requestId, errorMessage, errorName, folder } = fileTransferApiResponse
            setResultInfo({ status, fileName, fileSize, requestId, errorMessage, errorName, folder })
            handleComponentState()
        } catch (err: any) {
            handleUploadErrors(err)
        }
    }

    const handleUploadErrors = (err: any) => {

        if (err.response.data.status === -100) {
            setFileAlreadyExists(true)
            return
        }

        setFileInfo(undefined)
        setResultInfo(undefined)
        setIsUploading(false)

        if (err instanceof AxiosError && !!!err?.response?.data) {
            handleAxiosError(err)
            return
        }

        if (err.response.data.status === -1) {
            showToastCustomErrorMessage('Sessão expirada', "Sua sessão expirou. Por favor, faça login novamente.", 'session-timeout')
            dispatch(slices.user.setUserInitialState())
            return
        }

        if (err.response.data.status === -2) {
            showToastCustomErrorMessage('Erro ao fazer upload', 'Não foi possível obter todos os parâmetros necessários. Contate o suporte.')
            return
        }
        if (err.response.data.status === -3) {
            showToastCustomErrorMessage('Erro ao fazer upload', "Não foi possível validar sua sessão. Contate o suporte.")
            return
        }
        if (err.response.data.status === -4) {
            showToastCustomErrorMessage('Erro ao fazer upload', 'São aceitos apenas arquivos de texto, planilhas, imagens ou compactados.')
            return
        }
        if (err.response.data.status === -5) {
            showToastCustomErrorMessage('Erro ao fazer upload', "O tamanho do arquivo desejado excede o limite de 5 GB.")
            return
        }
        if (err.response.data.status === -6) {
            showToastCustomErrorMessage('Erro ao fazer upload', "Por favor, verifique se há caracteres especiais no nome do seu arquivo e renomeie para continuar.")
            return
        }

    }

    const resultData = useMemo(() => resultInfo ? [
        {
            label: 'Número da requisição',
            content: resultInfo.requestId
        },
        {
            label: 'Status',
            content: resultInfo.status === 200 ? 'Concluído' : 'Erro',
            variant: resultInfo.status === 200 ? 'positive' : 'negative'
        },
        {
            label: 'Pasta',
            content: resultInfo.folder
        },
        {
            label: 'Tamanho',
            content: formatFileSize(resultInfo.fileSize)
        },
        {
            label: 'Informações adicionais',
            content: resultInfo.errorMessage === '' ? 'N/A' : `Código do erro: ${resultInfo.errorName}. ${resultInfo.errorMessage}`,
            variant: resultInfo.errorMessage === '' ? 'neutral' : 'negative'
        },

    ]
        :
        []
        , [resultInfo])

    if (!!!open) {
        return <></>
    }

    return <GeneralComponents.InternalPages
        title='Upload de arquivos'
        description='Faça o upload do seu arquivo direto para o banco de dados'
        subpage={true}
        open={open}
        handleClose={handleClose}
    >
        <Box
            minWidth={'280px'}
            maxWidth={'400px'}
        >
            <GeneralComponents.CustomSelect
                variant="outlined"
                LabelText='Selecione a pasta de destino'
                value={folder}
                onChange={handleSelectFolderChange}
                sx={{ width: '100%' }}
                fullWidth
            >
                <GeneralComponents.CustomMenuItem value='dataplatform'>Plataforma de dados</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='bigid'>BigID</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='bigweb'>BigWeb</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='blabs'>B-LABS</GeneralComponents.CustomMenuItem>
                <GeneralComponents.CustomMenuItem value='parceria'>Parceria</GeneralComponents.CustomMenuItem>
            </GeneralComponents.CustomSelect>
        </Box>
        <Paper sx={PaperContainer} >
            <Box
                sx={isDragging ? DraggingDashedContainer : DashedContainer}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                <Box sx={ContentContainer}>
                    {!fileInfo ?
                        <Box>
                            {isDesktopWidth ?
                                <Box sx={UploadMessageContainer}>
                                    <AiFillFolderOpen color='#55586670' size={48} />
                                    <p>Arraste seu documento de texto, planilha ou imagem aqui.</p>
                                    <Divider sx={UploadMessageDivider}>OU</Divider>
                                    <GeneralComponents.CustomDefaultButton onClick={handleSelectFileClick}>Selecione o seu arquivo</GeneralComponents.CustomDefaultButton>
                                </Box>
                                :
                                <GeneralComponents.CustomDefaultButton onClick={handleSelectFileClick}>Selecione o arquivo</GeneralComponents.CustomDefaultButton>
                            }

                            <input type='file' hidden ref={inputFile} onChange={handleFileChange}></input>
                        </Box>

                        :

                        loadingProgress < 100 ?
                            <Box>
                                <p>{fileInfo.name}</p>
                                <Box sx={UploadedFileContainer}>
                                    <span>{formatFileSize(fileInfo.size)}</span>
                                </Box>
                                <Box sx={ProgressBarContainer}>
                                    <Box
                                        sx={{
                                            width: `${loadingProgress}%`,
                                            height: '100%',
                                            backgroundColor: '#0068ff'
                                        }}
                                    />
                                </Box>
                            </Box>
                            :

                            !isUploading ? <>

                                <Box sx={UploadedFileContainer}>
                                    <p>{fileInfo.name}</p>
                                    <Box sx={FileInfoContainer}>
                                        <GeneralComponents.Tag size={isDesktopWidth ? 'medium' : 'small'}>{formatFileSize(fileInfo.size)}</GeneralComponents.Tag>
                                        <BsFileEarmarkCheckFill color='#28AD58' size={24} />
                                    </Box>
                                </Box>
                                <Box sx={UploadOptionsContainer}>
                                    <GeneralComponents.CustomDefaultButton onClick={handleUploadRequest}>Enviar</GeneralComponents.CustomDefaultButton>
                                    <GeneralComponents.CustomDefaultTextButton onClick={handleSelectFileClick}>Selecionar outro arquivo</GeneralComponents.CustomDefaultTextButton>
                                    <input type='file' hidden ref={inputFile} onChange={handleFileChange}></input>
                                </Box>
                            </>
                                :
                                <>
                                    <p>Por favor, aguarde enquanto fazemos o upload do seu arquivo. Essa ação pode levar alguns minutos.</p>
                                    <CircularProgress />
                                </>
                    }

                </Box>
            </Box>
            <GeneralComponents.ResultBox mainLineLabel='Arquivo' mainLineContent={resultInfo?.fileName} data={resultData} isLoading={isUploading} />
            <GeneralComponents.ConfirmationDialog
                open={!!fileAlreadyExists}
                onConfirm={handleForceUploadRequest}
                onLeave={handleComponentState}
                title='Arquivo existente'
                content='Esse arquivo já existe, deseja substituí-lo?'
                confirmText='Sim, por favor'
                cancelText='Não, cancelar'
            />


        </Paper>
    </GeneralComponents.InternalPages>

}

export default UploadFiles;