import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface exportPdfDocumentoscopiaInfo {
  open: boolean;
  isPrinting?: boolean;
  consult: any;
  qualityImage: any;
}


const initialState: exportPdfDocumentoscopiaInfo = {
  open: false,
  isPrinting: false,
  consult: [],
  qualityImage: [],
}

const exportPdfDocumentoscopia = createSlice({
  name: 'exportPdfDocumentoscopia',
  initialState: initialState,
  reducers: {
    setExportPdfDocumentoscopiaInfo: (state, action: PayloadAction<exportPdfDocumentoscopiaInfo>) => {
      return { ...state, ...action.payload }
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setConsult: (state, action: PayloadAction<any>) => {
      state.consult = action.payload
    },
    setQualityImage: (state, action: PayloadAction<any>) => {
      state.qualityImage = action.payload
    }
  },
});

export const { setExportPdfDocumentoscopiaInfo, setOpen, setConsult, setQualityImage } = exportPdfDocumentoscopia.actions;

export default exportPdfDocumentoscopia.reducer;