import React from 'react';
import { Box, Step, StepButton } from '@mui/material';
import { Stepper, StepLabel } from './styles'

interface ComponentProps {
    activeStep: any,
    steps: any
    handleStep: any,
}

const Component: React.FC<ComponentProps> = ({
    steps,
    activeStep,
    handleStep,
}) => {
    return <Box
        height={'fit-content'}
        width={'100%'}
    >
        <Stepper
            nonLinear
            activeStep={activeStep}
        >
            {steps.map((step: any, index: number) => (
                <Step
                    key={step?.label}
                    completed={!!step?.completed}
                    
                >
                    <StepButton
                    onClick={() => handleStep(index)}
                    disableRipple
                    >
                        <StepLabel>{step?.label}</StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    </Box>;
}

export default Component;